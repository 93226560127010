import { Icon, IconButton } from '@mui/material'

import { toast, Bounce } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const CloseButton = () => {
  return (
    <IconButton sx={{ height: '100%' }}>
      <Icon>close</Icon>
    </IconButton>
  )
}

export const NotificacaoSucesso = (message: string) => {
  return toast.success(message, {
    transition: Bounce,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    delay: 0,
    autoClose: 2500,
    closeButton: <CloseButton />,
  })
}

export const NotificacaoError = (message: string) => {
  return toast.error(message, {
    transition: Bounce,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    delay: 0,
    autoClose: 2500,
    closeButton: <CloseButton />,
  })
}

export const NotificacaoPromise = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promise: Promise<any>,
  pending?: string,
  success?: string,
  error?: string,
) => {
  return toast.promise(
    promise,
    { pending, success, error },
    {
      transition: Bounce,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      delay: 0,
      autoClose: 2500,
      closeButton: <CloseButton />,
    },
  )
}

export const NotificacaoAlerta = (message: string) => {
  return toast.warning(message, {
    transition: Bounce,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    delay: 0,
    autoClose: 2500,
    closeButton: <CloseButton />,
  })
}

/* eslint-disable @typescript-eslint/ban-types */
import { Api, ExternalApi, TotalCount } from '../../axios-config'

interface LiteCustomerService {
  id: number
  slug: string
  status: boolean
  sac_title: string
}

interface LiteGrouping {
  id: number
  name: string
  status: boolean
}

export interface SacSector {
  id: number
  name: string
  status: boolean
  grouping: number
  created_at: string
  updated_at: string
  institution: number
  customer_service: number
}

export interface SacSectorList {
  id: number
  name: string
  status: boolean
  created_at: string
  updated_at: string
  institution: number
  grouping: LiteGrouping
  customer_service: LiteCustomerService
}

export const getAllSacSectors = async (
  busca = '',
  item = '0',
  total = '10',
  institution = '',
  customerService = '',
  grouping = '',
): Promise<TotalCount<SacSectorList> | Error> => {
  try {
    const relativeUrl = '/V1/customer_service/sectors/'

    const { data } = await Api.get(relativeUrl, {
      params: {
        item,
        total,
        search: busca,
        institution,
        customer_service: customerService,
        grouping,
      },
    })

    if (data) return data

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const createSacSector = async (formData: {}): Promise<
  SacSector | Error
> => {
  try {
    const relativeUrl = '/V1/customer_service/sectors/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao criar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const updateSacSector = async (
  id: number,
  formData: {},
): Promise<SacSector | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/sectors/${id}/`

    const { data } = await Api.patch(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao atualizar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getSacSectorById = async (
  id: number | string,
): Promise<SacSectorList | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/sectors/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getAllSacSectorsExternal = async (
  groupingId: number | string,
  busca = '',
): Promise<SacSector[] | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/externalusers/access/${groupingId}/sectors/`

    const { data } = await ExternalApi.get(relativeUrl, {
      params: {
        search: busca,
      },
      headers: {
        Authorization: `Token ${sessionStorage.getItem('EXTERNAL_TOKEN')}`,
      },
    })

    if (data) return data

    return new Error('Erro ao carregar setores')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

import { Typography, TypographyProps } from '@mui/material'
import { FC } from 'react'

type TTypographyDropProps = TypographyProps & {
  colorHover?: string
  active?: boolean
}

export const TypographyDrop: FC<TTypographyDropProps> = ({
  children,
  ...rest
}) => {
  return (
    <Typography color="white" {...rest}>
      {children}
    </Typography>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useRef, useState, useMemo, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Box,
  ButtonBase,
  useTheme,
  IconButton,
} from '@mui/material'

import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDaListagem,
  NotificacaoAlerta,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import { useDebounce } from '../../../shared/hooks'
import { useAuthContext } from '../../../shared/contexts'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import usePersistedState from '../../../shared/hooks/usePersistedState'
import {
  SacSubjectList,
  getAllSacSubjects,
} from '../../../shared/services/api/sac-services'
import { Footer } from '../../../shared/components/tabela/Footer'
import { changePage, FormatDateTime, FormatStatus } from '../../../shared/utils'

import { Environment } from '../../../shared/environment'
import { Formulario } from './formulario/Formulario'
import { InfoOutlined } from '@mui/icons-material'

export const AssuntoSAC: FC = () => {
  const update = useRef(true)
  const theme = useTheme()

  const { debounce } = useDebounce(1000)
  const { user } = useAuthContext()
  const { thisModule } = useModuleContext()

  const [isLoading, setIsLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [rows, setRows] = useState<SacSubjectList[]>([])

  const [viewForm, setViewForm] = useState(false)

  const [institution, setInstitution] = usePersistedState<string>(
    'ADM_SELECTED_INSTITUTION',
    '',
  )

  const [searchParams, setSearchParams] = useSearchParams()
  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])
  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])
  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])
  const customerService = useMemo(() => {
    return searchParams.get('sac') || ''
  }, [searchParams])

  useEffect(() => {
    if (thisModule?.profile !== 0 && thisModule?.id) {
      setSearchParams(
        {
          busca,
          item,
          total,
          sac: String(thisModule.id),
        },
        { replace: true },
      )
    }
  }, [thisModule])

  const [superUser, setSuperUser] = useState(false)
  useMemo(() => {
    setSuperUser(thisModule?.profile === 0)
  }, [thisModule?.profile])

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllSacSubjects(
        item,
        busca,
        '',
        total,
        superUser ? institution : String(user?.institution_id),
      ).then((result) => {
        setIsLoading(false)
        update.current = false
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setRows(result.results)
          setTotalCount(result.count)
        }
      })
    })
  }, [busca, item, total, institution, customerService, update.current])

  return (
    <LayoutBaseDePagina
      selectInstitution
      autoCompleteChange={(e) => setInstitution(e ? String(e) : '')}
      title="Assuntos"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputDaBusca
          mostrarBotaoNovo
          textoDaBusca={busca}
          aoClicarEmNovo={() => {
            if (thisModule?.profile === 0 && !institution) {
              NotificacaoAlerta('Selecione uma instituição')
            } else {
              setViewForm(true)
            }
          }}
          aoMudarTextoDaBusca={(texto) =>
            setSearchParams(
              {
                busca: texto,
                item: '0',
                total,
                sac: customerService,
              },
              { replace: true },
            )
          }
        />
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={50}></TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Setores</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center">Atulizado em</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <Link
                    to={`/customer-service/${thisModule?.title}/subjects/details/${row.id}`}
                  >
                    <IconButton color="primary">
                      <InfoOutlined />
                    </IconButton>
                  </Link>
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center" sx={{ maxWidth: '260px' }}>
                  {row.sectors.length > 0 ? (
                    <Box
                      flexDirection="row"
                      overflow="auto"
                      maxHeight="75px"
                      flexWrap="wrap"
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      gap={1}
                    >
                      {row.sectors.map((sector) => (
                        <Link
                          key={sector.value}
                          to={`/customer-service/${thisModule?.title}/sectors/details/${sector.value}`}
                        >
                          <Box
                            display="flex"
                            component={ButtonBase}
                            borderRadius="15px"
                            maxWidth="120px"
                            height={theme.spacing(3)}
                            justifyContent="center"
                            alignItems="center"
                            color="black"
                            bgcolor={'dodgerblue'}
                          >
                            <Typography
                              margin={1}
                              variant="subtitle2"
                              component="h6"
                              textOverflow="ellipsis"
                              noWrap
                            >
                              {sector.label}
                            </Typography>
                          </Box>
                        </Link>
                      ))}
                    </Box>
                  ) : (
                    <Typography variant="caption">
                      nenhum setor cadastrado
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.created_at)}
                </TableCell>
                <TableCell align="center">
                  {row.created_at !== row.updated_at
                    ? FormatDateTime(row.created_at)
                    : 'Não houve atualizações'}
                </TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <Footer
            colSpan={6}
            item={Number(item)}
            loading={isLoading}
            onChangePagination={(_, newValue) =>
              setSearchParams(
                {
                  busca,
                  total,
                  sac: customerService,
                  item: changePage(newValue, Number(total)),
                },
                { replace: true },
              )
            }
            onChangeTotalItems={(e) =>
              setSearchParams(
                {
                  busca,
                  item: '0',
                  total: e.target.value,
                  sac: customerService,
                },
                { replace: true },
              )
            }
            total={total}
            totalCount={totalCount}
          />
        </Table>
      </TableContainer>
      <Formulario
        create
        onClose={() => setViewForm(false)}
        show={viewForm}
        update={update}
        institution={
          thisModule?.profile === 0
            ? Number(institution)
            : Number(user?.institution_id)
        }
      />
    </LayoutBaseDePagina>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, MutableRefObject, useState } from 'react'
import {
  SacProtocol,
  changeSacProtocolCategory,
} from '../../../../../shared/services/api/sac-services'
import { VForm, VTextArea, useVForm } from '../../../../../shared/forms'
import {
  FormBoxStyle,
  ModalHeader,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../../shared/components'
import { AutocompleteCategoriaSAC } from '../autocomplete'
import { Cancel, Save } from '@mui/icons-material'

interface AlterarCatgoriaModalProps {
  show: boolean
  protocolId: number
  onClose: () => void
  updateReplies: MutableRefObject<boolean>
  setProtocolData: (data: SacProtocol) => void
}

export const AlterarCatgoriaModal: FC<AlterarCatgoriaModalProps> = ({
  show,
  onClose,
  protocolId,
  updateReplies,
  setProtocolData,
}) => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit(formData: any) {
    setIsLoading(true)

    try {
      const result = await changeSacProtocolCategory(protocolId, formData)

      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        NotificacaoSucesso('Categoria alterada com sucesso')
        updateReplies.current = true
        setProtocolData(result)
        handleClose()
      }
    } catch (error) {
      NotificacaoError('Erro ao alterar categoria')
    }
    setIsLoading(false)
  }

  function handleClose() {
    formRef.current?.reset()
    onClose()
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        overflow="auto"
        maxHeight="85%"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader
          title="Alterar categoria"
          showCloseButton
          onClose={handleClose}
        />

        <Divider />

        {isLoading && <LinearProgress sx={{ mt: 2 }} />}

        <VForm onSubmit={handleSubmit} ref={formRef}>
          <Grid container spacing={2} direction="column" mt={2}>
            <Grid item xs={12}>
              <AutocompleteCategoriaSAC name="category" />
            </Grid>
            <Grid item xs={12}>
              <VTextArea
                name="justify"
                minRows={4}
                maxRows={8}
                required
                placeholder=" Justifique o motivo da alteração da categoria
  * Essa justificativa é visível para o cidadão
                      "
                ariaLabel="Descrição"
                style={{
                  width: '95%',
                  borderRadius: '5px',
                  fontVariant: 'historical-forms',
                  font: 'message-box',
                }}
              />
            </Grid>
          </Grid>

          <Box
            display="flex"
            flex="1"
            mt={2}
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <Button
              disableElevation
              disabled={isLoading}
              type="submit"
              variant="contained"
              startIcon={<Save />}
            >
              salvar
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={handleClose}
              startIcon={<Cancel />}
            >
              cancelar
            </Button>
          </Box>
        </VForm>
      </Box>
    </Modal>
  )
}

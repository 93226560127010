import { useState, useEffect, MutableRefObject } from 'react'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { VForm, useVForm, VTextField } from '../../../../shared/forms'
import {
  IIcons,
  createIcon,
  updateIconById,
} from '../../../../shared/services/api'
import { VSwitch } from '../../../../shared/forms/VSwitch'
import { Delete, PhotoCamera } from '@mui/icons-material'
import { IFormData } from './validation'

export const CriarIcone = (
  onClose: () => void,
  show = false,
  update: MutableRefObject<boolean>,
  create: boolean,
  data?: IIcons,
) => {
  const [formTitle, setFormTitle] = useState('')
  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const [icon, setIcon] = useState<File | null>(null)
  const [iconUrl, setIconUrl] = useState('')

  const iconForm = new FormData()

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)
    iconForm.append('name', formData.name)
    if (create && icon) {
      iconForm.append('icon', icon, icon?.name)
    } else {
      try {
        if (icon) iconForm.append('icon', icon, icon?.name)
      } catch (error) {
        console.log('Sem imagens para adicionar')
      }
    }
    iconForm.append('status', formData.status.toString())

    create
      ? createIcon(iconForm).then((result) => {
          setIsLoading(false)

          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            update.current = true
            onClose()
            setIcon(null)
            setIconUrl('')
            NotificacaoSucesso('Ícone Criado com Sucesso')
          }
        })
      : updateIconById(Number(data?.id), iconForm).then((result) => {
          setIsLoading(false)

          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            update.current = true
            onClose()
            setIcon(null)
            setIconUrl('')
            NotificacaoSucesso('Ícone atualizado com Sucesso')
          }
        })
  }

  useEffect(() => {
    if (!create && data !== undefined) setFormTitle(`Editar Ícone ${data.name}`)
    else setFormTitle('Novo Ícone')
  }, [create, data, show])

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Box display="flex" justifyContent="center" alignContent="center">
          <Typography
            variant="h6"
            component="h2"
            id="modal-title"
            textOverflow="ellipsis"
            noWrap
          >
            {formTitle}
          </Typography>
        </Box>

        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{ height: '5px', borderRadius: '50px' }}
          />
        )}
        <Box margin={1}>
          <VForm
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={create ? undefined : data}
          >
            <Grid container direction="column" spacing={2} padding={2}>
              <Grid container item spacing={2}>
                <Grid item display="flex" xs={12} justifyContent="center">
                  <img
                    src={icon ? iconUrl : data?.icon}
                    height="150vh"
                    alt="icon"
                  />
                </Grid>
                <Grid item display="flex" xs={12} justifyContent="center">
                  <Button
                    variant="contained"
                    disabled={!!icon}
                    component="label"
                    endIcon={<PhotoCamera />}
                    disableElevation
                    sx={{ borderRadius: '50px' }}
                  >
                    Ícone
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        if (e.target.files?.length !== undefined) {
                          setIcon(e.target.files[0])
                          setIconUrl(URL.createObjectURL(e.target.files[0]))
                        } else {
                          setIcon(null)
                          URL.revokeObjectURL(iconUrl)
                        }
                      }}
                    />
                  </Button>
                  {!!icon && (
                    <IconButton
                      onClick={() => {
                        URL.revokeObjectURL(iconUrl)
                        setIcon(null)
                        setIconUrl('')
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="name"
                    label="Nome"
                    defaultValue={data?.name || ''}
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <FormControlLabel
                    control={
                      <VSwitch name="status" defaultChecked={!!data?.status} />
                    }
                    label="Status"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box display="flex" flex={1} justifyContent="center">
              <Button type="submit">
                <Icon>save</Icon>
                Salvar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

export interface ISectorUserList {
  id: number
  username: string
  email: string
  last_login: string
  created_at: string
  is_active: boolean
  ombudsman_profile: number
  sectors?: { id: number; label: string }[]
}

export interface IUserGetSelf {
  email: string
  username: string
  password: string
  re_password: string
}

export interface ISectorUserDetail {
  id?: number
  username: string
  email: string
  document_number: string
  document_type: string
  institution_id: number
  sectors?: {}[]
  ombudsman_profile: string | number
  data_manager: boolean
  is_active: boolean
  is_confirmed: boolean
  confirmed_at: string
  confirmation_token: string
  confirmation_token_sent_at: string
  updated_at: string
  created_at: string
  last_login: string
  sign_in_count: string
  profile_picture: string
  birth_date: string
  modules: []
  hr_manager: boolean
  esic_manager: boolean
  esic_auxiliary: boolean
}

export type ISectorUserTotalCount = {
  data: ISectorUserList[]
  count: number
  next: string
  previous: string
  results: ISectorUserList[]
}

const getAllInternals = async (
  item = '0',
  total = '10',
  filter = '',
  institutionId = '',
  online = '',
  status = '',
): Promise<ISectorUserTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/internalusers/`

    const { data } = await Api.get(relativeUrl, {
      params: {
        item,
        total,
        online,
        search: filter,
        is_active: status,
        institution_id: institutionId,
      },
    })

    if (data) {
      return {
        data,
        next: data.next,
        count: data.count,
        results: data.results,
        previous: data.previous,
      }
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    if (
      (error as { message: string }).message ===
      'Request failed with status code 403'
    ) {
      return new Error('Você Não Possui Acesso a Essa Página')
    }
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getInternalById = async (
  id: number,
): Promise<ISectorUserDetail | Error> => {
  try {
    const relativeUrl = `/V1/internalusers/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      data.password = ''
      return data
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao listar registro',
    )
  }
}

const createInternal = async (formData: {}): Promise<string | Error> => {
  try {
    const relativeUrl = '/V1/internalusers/'

    const { data } = await Api.post<ISectorUserDetail>(relativeUrl, formData)

    if (data) return data.id ? data.id.toString() : data.username

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const confirmUser = async (id: number): Promise<ISectorUserDetail | Error> => {
  try {
    const relativeUrl = `/V1/internalusers/${id}/`

    const { data } = await Api.patch<ISectorUserDetail>(
      relativeUrl,
      { is_confirmed: true },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

const updateInternalById = async (
  id: number,
  formData: {},
): Promise<ISectorUserDetail | Error> => {
  try {
    const relativeUrl = `/V1/internalusers/${id}/`

    const { data } = await Api.patch<ISectorUserDetail>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

const resendConfirmationMail = async (formData: {}): Promise<
  string | Error
> => {
  try {
    const relativeUrl = `/V1/users/resend_mail/`
    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao reenviar email de confirmação')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getMySelf = async (): Promise<IUserGetSelf | Error> => {
  try {
    const relativeUrl = '/V1/user/profile/'

    const { data } = await Api.get(relativeUrl)

    if (data) {
      data.password = ''
      data.re_password = ''
      return data
    }

    return new Error('Erro ao carregar dados')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const updateMySelf = async (formData: {}): Promise<IUserGetSelf | Error> => {
  try {
    const relativeUrl = '/V1/user/profile/'

    const { data } = await Api.patch(relativeUrl, formData)

    if (data) {
      data.password = ''
      return data
    }

    return new Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export {
  getMySelf,
  confirmUser,
  updateMySelf,
  createInternal,
  getAllInternals,
  getInternalById,
  updateInternalById,
  resendConfirmationMail,
}

import { useEffect, useState } from 'react'
import { useField } from '@unform/core'
import { SxProps, TextField, TextFieldProps, Theme } from '@mui/material'

type TVTextFieldProps = TextFieldProps & {
  name: string
  formatData?: (value: string) => void
  borderBottomLeft?: string
  borderTopLeft?: string
  borderBottomRight?: string
  borderTopRight?: string
  upercase?: boolean
  lowercase?: boolean
  textStyle?: SxProps<Theme>
}

export const VTextField: React.FC<TVTextFieldProps> = ({
  name,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatData = (value: any) => value,
  borderBottomLeft = '0px',
  borderBottomRight = '50px',
  borderTopLeft = '0px',
  borderTopRight = '50px',
  textStyle,
  upercase = false,
  lowercase = false,
  ...rest
}) => {
  const StyleTextField = {
    '& .MuiOutlinedInput-root': {
      borderBottomLeftRadius: borderBottomLeft,
      borderTopLeftRadius: borderTopLeft,
      borderBottomRightRadius: borderBottomRight,
      borderTopRightRadius: borderTopRight,
    },
    '& fieldset': {
      borderBottomLeftRadius: borderBottomLeft,
      borderTopLeftRadius: borderTopLeft,
      borderBottomRightRadius: borderBottomRight,
      borderTopRightRadius: borderTopRight,
    },
  }

  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(name)

  const [value, setValue] = useState<string>(
    defaultValue === null ? defaultValue : defaultValue || '',
  )

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [registerField, fieldName, value])

  return (
    <TextField
      {...rest}
      sx={textStyle || StyleTextField}
      error={!!error}
      helperText={error}
      value={formatData(value)}
      onChange={(e) => {
        setValue(
          upercase
            ? e.target.value.toLocaleUpperCase()
            : lowercase
            ? e.target.value.toLowerCase()
            : e.target.value,
        )
        rest.onChange?.(e)
      }}
      onKeyDown={() => (error ? clearError() : undefined)}
    />
  )
}

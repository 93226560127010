import { useNavigate, useParams } from 'react-router-dom'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDeDetalhes,
  LegendaSumario,
  NotificacaoError,
} from '../../../shared/components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import { FormAgrupamento } from './formulario/Formulário'
import {
  IGroupings,
  ISectors,
  getAllSectors,
  getGroupById,
} from '../../../shared/services/api'
import { ExpandMore } from '@mui/icons-material'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'
import {
  IGroupingHistory,
  getGroupingHistory,
} from '../../../shared/services/api/histories-services/GroupingHistoryService'
import { GroupingHistory } from './components/GroupingHistory'
import { useAuthContext } from '../../../shared/contexts'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const DetalhesAgrupamento: React.FC = () => {
  const navigate = useNavigate()

  const { thisModule } = useModuleContext()

  const { id = 'new' } = useParams<'id'>()
  const { user } = useAuthContext()

  const updated = useRef(false)

  const [data, setData] = useState<IGroupings>()
  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)

  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')

  const [history, setHistory] = useState<IGroupingHistory[]>([])
  const [historyLoading, setHistoryLoading] = useState(true)

  const [sectors, setSectors] = useState<ISectors[]>([])
  const [sectorsLoading, setSectorsLoading] = useState(true)

  useEffect(() => {
    if (data) {
      getAllSectors(
        '0',
        '999999999',
        '',
        '',
        String(data.institution_id),
        '',
        id,
      ).then((result) => {
        setSectorsLoading(false)
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar setores')
        } else {
          setSectors(result.results)
        }
      })
    }
  }, [data, id])

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true)

      Promise.all([
        getGroupById(Number(id)),
        getGroupingHistory(Number(id)),
      ]).then((values) => {
        setIsLoading(false)
        setHistoryLoading(false)

        if (values[0] instanceof Error) {
          NotificacaoError(values[0].message)
          navigate('/ombudsman/groupings')
        } else {
          setName(values[0].name)
          setData(values[0])
        }

        if (values[1] instanceof Error) {
          NotificacaoError(values[1].message)
        } else {
          setHistory(values[1])
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, updated.current])

  const { moduleTitle } = useModuleContext()

  return (
    <LayoutBaseDePagina
      title={`Agrupamento ${name}` || 'Detalhes Agrupamento'}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          textoBotaoNovo="Novo"
          mostrarBotaoNovo={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.hr_manager ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          mostrarBotaoEditar={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.hr_manager ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
          aoClicarEmVoltar={() => navigate(-1)}
          mostrarBotaoApagar={false}
        />
      }
    >
      {isLoading && <LinearProgress sx={{ mt: 2 }} />}
      <Box margin={1}>
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          alignItems="center"
          margin={1}
        >
          {data?.icon_url ? (
            <img src={data?.icon_url} width="150px" alt="Ícone" />
          ) : (
            <CircularProgress size="100px" />
          )}
        </Box>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario icon="info_outlined" name="Informações" />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Status: </Typography>
                </Grid>
                <Grid item width="75px">
                  {FormatStatus(Boolean(data?.status))}
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Nome: </Typography>
                </Grid>
                <Grid item>
                  <Typography>{data?.name}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Ícone: </Typography>
                </Grid>
                <Grid item>
                  <Typography>{data?.icon_name}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Criado em: </Typography>
                </Grid>
                <Grid item>
                  <Typography>{FormatDateTime(data?.created_at)}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Atualizado em: </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {data?.created_at === data?.updated_at
                      ? 'Não houve alterações'
                      : FormatDateTime(data?.updated_at)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario icon="safety_divider" name="Setores cadastrados" />
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer
              component={Paper}
              variant="outlined"
              sx={{
                m: 1,
                width: 'auto',
                color: 'dodgerblue',
                border: '1px solid',
                borderRadius: '15px',
              }}
            >
              <Table>
                <TableHead>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">Nome</TableCell>
                  <TableCell align="center">Criado em</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableHead>

                <TableBody>
                  {sectors.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        <IconButton
                          color="primary"
                          onClick={() =>
                            navigate(
                              `/ombudsman/${thisModule?.title}/sectors/detail/${row.id}`,
                            )
                          }
                        >
                          <Icon>info_outlined</Icon>
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">
                        {FormatDateTime(row.created_at)}
                      </TableCell>
                      <TableCell align="center">
                        {FormatStatus(row.status, 'Ativo', 'Inativo')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                {sectors.length === 0 && !sectorsLoading && (
                  <caption>Nenhum setor cadastrado neste agrupamento</caption>
                )}

                <TableFooter>
                  {sectorsLoading && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <LinearProgress
                          variant="indeterminate"
                          sx={{ borderRadius: '50px', height: '5px' }}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableFooter>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario icon="restore" name="Histórico" />
          </AccordionSummary>
          <AccordionDetails>
            <GroupingHistory
              history={history}
              historyLoading={historyLoading}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <FormAgrupamento
        create={create}
        onClose={() => setViewForm(false)}
        show={viewForm}
        update={updated}
        data={!create ? data : undefined}
      />
    </LayoutBaseDePagina>
  )
}

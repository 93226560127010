import { Schema } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'

export interface IFormdata {
  text: string
  title: string
  status: boolean
  institution: number
}

const formValidationSchema: Schema<IFormdata> = yup.object().shape({
  institution: yup.number().required().min(1),
  status: yup.boolean().default(true),
  title: yup.string().required(),
  text: yup.string().required(),
})

export default formValidationSchema

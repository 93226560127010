import { Box, Button, Grid } from '@mui/material'
import { FC, useState } from 'react'
import { VForm, VTextField, useVForm } from '../../../../../shared/forms'
import { IProtocols, viewAnonymous } from '../../../../../shared/services/api'
import {
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../../shared/components'
import { ModalView } from './ModalView'

interface IAcompanharAnonimoProps {
  handleClose: () => void
}

interface IFormdata {
  identifier: string
  password: string
}

export const AcompanharAnonimo: FC<IAcompanharAnonimoProps> = ({
  handleClose,
}) => {
  const { formRef } = useVForm()

  const [sending, setSendig] = useState(false)
  const [data, setData] = useState<IProtocols>()

  const [modalView, setModalView] = useState(false)

  const handleSubmit = (formData: IFormdata) => {
    setSendig(true)
    viewAnonymous(formData).then((result) => {
      setSendig(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        NotificacaoSucesso('Sucesso nisso aqui')
        setData(result)
        setModalView(true)
        formRef.current?.reset()
      }
    })
  }

  return (
    <Box margin={1}>
      <VForm onSubmit={handleSubmit} ref={formRef}>
        <Grid container spacing={2} direction="column">
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6}>
              <VTextField
                fullWidth
                required
                size="small"
                name="identifier"
                label="Protocolo"
                borderBottomLeft="50px"
                borderTopLeft="50px"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VTextField
                fullWidth
                required
                size="small"
                name="password"
                type="password"
                label="Senha"
                borderBottomLeft="50px"
                borderTopLeft="50px"
              />
            </Grid>
          </Grid>
        </Grid>
        <Box
          display="flex"
          flex="1"
          marginTop={2}
          justifyContent="right"
          gap={1}
        >
          <Button
            type="submit"
            variant="contained"
            disableElevation
            sx={{ borderRadius: '50px' }}
            disabled={sending}
          >
            consultar
          </Button>
          <Button onClick={handleClose} sx={{ borderRadius: '50px' }}>
            fechar
          </Button>
        </Box>
      </VForm>
      <ModalView
        show={modalView}
        protocolData={data}
        onClose={() => setModalView(false)}
      />
    </Box>
  )
}

/* eslint-disable @typescript-eslint/ban-types */
import { Api, ExternalApi, TotalCount } from '../../axios-config'
import { SacModuleList } from '../../modules-services/SacServices'

interface SectorInSubjectList {
  label: string
  value: number
}
export interface SacSubjectList {
  id: number
  name: string
  status: boolean
  created_at: string
  updated_at: string
  institution: number
  sectors: SectorInSubjectList[]
  customer_service: SacModuleList
}

export const getAllSacSubjects = async (
  item = '0',
  busca = '',
  sector = '',
  total = '10',
  institution = '',
  customerService = '',
): Promise<TotalCount<SacSubjectList> | Error> => {
  try {
    const relativeUrl = '/V1/customer_service/subjects/'

    const { data } = await Api.get(relativeUrl, {
      params: {
        search: busca,
        item,
        total,
        institution,
        customer_service: customerService,
        sector,
      },
    })

    if (data) return data

    return new Error('Erro ao listar instâncias')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getSacSubjectById = async (
  id: number | string,
): Promise<SacSubjectList | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/subjects/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const updateSacSubject = async (
  id: number | string,
  formData: {},
): Promise<SacSubjectList | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/subjects/${id}/`

    const { data } = await Api.patch(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao carregar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const createSacSubject = async (formData: {}): Promise<
  SacSubjectList | Error
> => {
  try {
    const relativeUrl = '/V1/customer_service/subjects/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data
    return new Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getAllSacSubjectsExternal = async (
  sectorId: number | string,
  busca = '',
): Promise<SacSubjectList[] | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/externalusers/access/${sectorId}/subjects/`

    const { data } = await ExternalApi.get(relativeUrl, {
      params: {
        search: busca,
      },
      headers: {
        Authorization: `Token ${sessionStorage.getItem('EXTERNAL_TOKEN')}`,
      },
    })

    if (data) return data

    return new Error('Erro ao carregar assuntos')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

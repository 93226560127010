import { Maybe, Schema } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'

export interface IFormData {
  sector: number
  secret: boolean
  subject: number
  category: number
  grouping: number
  latitude?: Maybe<string>
  longitude?: Maybe<string>
  institution: number
  description: string
  kind_respose: number
  external_user: number
  customer_service: number
  relative_protocol?: Maybe<string>
}

export const formValidationSchema: Schema<IFormData> = yup.object().shape({
  secret: yup.boolean().default(false),
  latitude: yup.string().notRequired(),
  longitude: yup.string().notRequired(),
  sector: yup.number().positive().required(),
  subject: yup.number().positive().required(),
  category: yup.number().positive().required(),
  grouping: yup.number().positive().required(),
  relative_protocol: yup.string().notRequired(),
  institution: yup.number().positive().required(),
  kind_respose: yup.number().positive().default(1),
  external_user: yup.number().required(),
  customer_service: yup.number().positive().required(),
  description: yup.string().min(6).max(6000).required(),
})

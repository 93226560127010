import { Maybe, Schema } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'

export interface IFormData {
  customer_service: number
  introduction?: Maybe<string>
  question: string
  status: boolean
}

export const formValidationSchema: Schema<IFormData> = yup.object().shape({
  customer_service: yup.number().required().positive(),
  introduction: yup.string().notRequired().max(1000),
  question: yup.string().required().min(3).max(255),
  status: yup.boolean().default(true),
})

/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useMemo } from 'react'
import { Api } from '../services/api/axios-config'
import { NotificacaoError } from '../components'
import usePersistedState from '../hooks/usePersistedState'
import jwtDecode from 'jwt-decode'

export interface IModule {
  id: number
  type: 'ombudsman' | 'ci' | 'customer-service'
  title: 'string'
  profile: number
  hr_manager: boolean
  logo: string
  deadline: number
}

export interface UserData {
  modules: Array<IModule>
  institution_id: number
  profile_picture?: string
  username: string
  gender: number
  id: number
}

interface IAuthContextData {
  logout(navigate: boolean): void
  setUser(data: UserData): void
  user: UserData | undefined
  isAuthenticated: boolean
}

const AuthContext = createContext({} as IAuthContextData)

const LOCAL_STORAGE_KEY__MODULES = 'APP_MODULES'
const LOCAL_STORAGE_KEY__INSTITUTION_ID = 'APP_INSTITUTION_ID'
const LOCAL_STORAGE_KEY__PROFILE_PICTURE = 'APP_USER_PROFILE_PICTURE'
const LOCAL_STORAGE_KEY__USERNAME = 'APP_USER_NAME'
const LOCAL_STORAGE_KEY__GENDER = 'APP_USER_GENDER'
const LOCAL_STORAGE_KEY__ID = 'APP_USER_ID'

interface IAuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [userId, setUserId] = usePersistedState<number | undefined>(
    LOCAL_STORAGE_KEY__ID,
    undefined,
    true,
  )
  const [userModules, setUserModules] = usePersistedState<Array<IModule>>(
    LOCAL_STORAGE_KEY__MODULES,
    [],
    true,
  )
  const [institutionId, setinstitutionId] = usePersistedState<
    number | undefined
  >(LOCAL_STORAGE_KEY__INSTITUTION_ID, undefined, true)
  const [profilePicture, setProfilePicture] = usePersistedState<
    string | undefined
  >(LOCAL_STORAGE_KEY__PROFILE_PICTURE, undefined, true)
  const [username, setUsername] = usePersistedState<string>(
    LOCAL_STORAGE_KEY__USERNAME,
    '',
    false,
  )
  const [gender, setGender] = usePersistedState<number | undefined>(
    LOCAL_STORAGE_KEY__GENDER,
    undefined,
    true,
  )

  const user = useMemo(() => {
    let data: UserData | undefined
    if (gender && institutionId && userModules && userId) {
      data = {
        id: userId,
        gender,
        username,
        modules: userModules,
        institution_id: institutionId,
        profile_picture: profilePicture,
      }
    } else {
      data = undefined
    }
    return data
  }, [gender, institutionId, userModules, userId, username, profilePicture])

  function logout(navigate = false) {
    setUsername('')
    setUserModules([])
    setUserId(undefined)
    setGender(undefined)
    setinstitutionId(undefined)
    setProfilePicture(undefined)

    localStorage.removeItem(LOCAL_STORAGE_KEY__ID)
    localStorage.removeItem(LOCAL_STORAGE_KEY__GENDER)
    localStorage.removeItem(LOCAL_STORAGE_KEY__MODULES)
    localStorage.removeItem(LOCAL_STORAGE_KEY__USERNAME)
    localStorage.removeItem(LOCAL_STORAGE_KEY__INSTITUTION_ID)
    localStorage.removeItem(LOCAL_STORAGE_KEY__PROFILE_PICTURE)
    localStorage.removeItem('ADM_SELECTED_INSTITUTION')

    if (navigate) {
      window.location.href = '/admin'
    }
  }

  function setUser(props: UserData) {
    setUserId(props.id)
    setGender(props.gender)
    setUsername(props.username)
    setUserModules(props.modules)
    setinstitutionId(props.institution_id)
    setProfilePicture(props.profile_picture)
  }

  const updateRefresh = async () => {
    Api.post('/V1/api/token/refresh/')
      .then((result) => {
        const token = result.data.access
        const decode: UserData = jwtDecode(token)

        setUser({
          id: decode.id,
          gender: decode.gender,
          modules: decode.modules,
          username: decode.username,
          institution_id: decode.institution_id,
          profile_picture: decode.profile_picture,
        })
      })
      .catch(() => {
        logout()
        NotificacaoError('Faça login')
      })
  }

  const isAuthenticated = useMemo(() => {
    return !!user
  }, [user])

  useEffect(() => {
    const paths =
      window.location.pathname.includes('/ombudsman/') ||
      window.location.pathname.includes('/ci/') ||
      window.location.pathname.includes('/customer-service/')
    if (paths) {
      updateRefresh()
    }
  }, [])

  const internalModules = ['ci', 'ombudsman', 'customer-service']

  useEffect(() => {
    const modulePath = window.location.pathname.split('/')[1]

    if (internalModules.includes(modulePath)) {
      updateRefresh()
    }
  }, [isAuthenticated])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        logout,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)

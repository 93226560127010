import { FC, useState } from 'react'
import {
  Box,
  Avatar,
  Typography,
  BoxProps,
  AvatarProps,
  ButtonBase,
  useTheme,
} from '@mui/material'
import { Edit } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useDrawerContext } from '../../contexts'
import { useModuleContext } from '../../contexts/ModulesContext'

type TProfilePicture = AvatarProps

type TEditView = BoxProps & {
  displayView: boolean
}

const EditView: FC<TEditView> = ({ displayView }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { moduleTitle, currentModule } = useModuleContext()

  return (
    <Box
      bgcolor="rgba(128, 128, 128, 0.8)"
      borderRadius="50%"
      sx={{
        marginTop: theme.spacing(2),
        height: theme.spacing(12),
        width: theme.spacing(12),
        border: '2px solid gray',
      }}
      justifyContent="center"
      width="96px"
      height="96px"
      alignItems="center"
      component={ButtonBase}
      display={displayView ? 'flex' : 'none'}
      position="absolute"
      onClick={() => {
        navigate(`/${currentModule}/${moduleTitle}/profile`)
      }}
    >
      <Edit sx={{ color: 'white' }} />
      <Typography variant="caption" color="white">
        editar perfil
      </Typography>
    </Box>
  )
}

export const ProfilePicture: FC<TProfilePicture> = ({ ...rest }) => {
  const navigate = useNavigate()
  const [view, setView] = useState(false)
  const [inButton, setInButton] = useState(false)

  const { toggleDrawerOpen, isDrawerOpen } = useDrawerContext()

  const { moduleTitle, currentModule } = useModuleContext()

  const avatar = document.getElementById('avatar')
  avatar?.addEventListener('mouseenter', function () {
    setView(true)
  })
  avatar?.addEventListener('mouseleave', function () {
    setView(false)
  })

  const editButton = document.getElementById('edit-button')
  editButton?.addEventListener('mouseenter', function () {
    setInButton(true)
  })
  editButton?.addEventListener('mouseleave', function () {
    setInButton(false)
  })

  return (
    <Box
      zIndex={1}
      id="avatar"
      width="96px"
      height="96px"
      display="flex"
      borderRadius="50%"
      alignItems="center"
      justifyContent="center"
    >
      <Avatar
        {...rest}
        onClick={() => {
          if (isDrawerOpen) toggleDrawerOpen()
          navigate(`/${currentModule}/${moduleTitle}/profile`)
        }}
      />
      <EditView displayView={view || inButton} />
    </Box>
  )
}

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from '@mui/material'
import { FC, useRef, useState } from 'react'
import { VSelect, VSwitch, VTextArea } from '../../../../../shared/forms'
import { Map } from '@mui/icons-material'
import { ModalMap } from '../../../protocolo/ModalMap'
import { AutocompleteSacSector, AutocompleteSacSubject } from './autocomplete'
import { AutocompleteSacCategory } from './autocomplete/AutocompleteSacCategory'

interface FormularioProps {
  groupId: number | undefined
  groupName: string | undefined
  customerServiceId: number
}

export const Formulario: FC<FormularioProps> = ({
  groupId,
  groupName,
  customerServiceId,
}) => {
  const [selectedSector, setSelectedSector] = useState<number>()

  const [mapOpen, setMapOpen] = useState(false)

  const mapClose = () => {
    setMapOpen(false)
  }

  const lat = useRef<number | undefined>(undefined)
  const lng = useRef<number | undefined>(undefined)

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {ModalMap(mapClose, mapOpen, lat, lng)}
      <Typography paddingTop={1}>
        {groupName !== undefined
          ? `Agrupamento Selecionado: ${groupName}`
          : 'Selecione um agrupamento'}
      </Typography>

      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <FormControlLabel
            label="Sigilo?"
            control={<VSwitch name="secret" />}
          />
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => setMapOpen(true)}
              startIcon={<Map />}
              sx={{ borderRadius: '50px' }}
            >
              Informar localidade
            </Button>
          </Grid>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={12} sm={6}>
            <AutocompleteSacSector
              setSector={setSelectedSector}
              grouping={groupId}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="kind-response-label">
                Forma de Resposta
              </InputLabel>
              <VSelect
                sx={{ borderRadius: '50px' }}
                size="small"
                name="kind_response"
                fullWidth
                labelId="kind-response-label"
                label="Forma de Resposta"
                useDefaultValue={false}
                placeholder="Selecione como deseja ser respondido"
              >
                <MenuItem value={1}>Email</MenuItem>
                <MenuItem value={2}>Telefone</MenuItem>
                <MenuItem value={3}>Pessoalmente</MenuItem>
                <MenuItem value={4}>Carta</MenuItem>
              </VSelect>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={12} sm={6}>
            <AutocompleteSacSubject sector={selectedSector} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutocompleteSacCategory customerService={customerServiceId} />
          </Grid>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <VTextArea
            name="description"
            minRows={4}
            maxRows={8}
            placeholder="Descreva aqui sua manifestação"
            ariaLabel="Descrição"
            style={{
              width: '95%',
              paddingInline: '8px',
              borderRadius: '10px',
              fontVariant: 'historical-forms',
              font: 'message-box',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

import { FC, useState } from 'react'
import { Box, Button, Modal, Paper, Typography } from '@mui/material'
import { Login, PersonAdd, KeyOff } from '@mui/icons-material'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { VForm, VTextField } from '../../../../shared/forms'
import { CadastreSeModal } from './CadastreSeModal'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { externalLogin } from '../../../../shared/services/api/login-services/LoginServices'
import { useNavigate, useParams } from 'react-router-dom'

interface LoginSacModalProps {
  customerServiceLogo: string
  show: boolean
  onClose: () => void
}

export const LoginSacModal: FC<LoginSacModalProps> = ({
  customerServiceLogo,
  onClose,
  show,
}) => {
  const { slug } = useParams<'slug'>()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [cadastreSeModal, setCadastreSeModal] = useState(false)

  const closeCadastreSeModal = () => {
    setCadastreSeModal(false)
  }

  const handleSubmit = (formData: object) => {
    setIsLoading(true)
    externalLogin(formData)
      .then((res) => {
        if (res instanceof Error) {
          NotificacaoError(res.message)
        } else {
          sessionStorage.setItem('EXTERNAL_ID', res.id.toString())
          sessionStorage.setItem('EXTERNAL_TOKEN', res.token)
          sessionStorage.setItem('EXTERNAL_NAME', res.name)

          NotificacaoSucesso('Logado com sucesso!')
          navigate(`/sac/${slug}/inicio`)
        }
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Modal onClose={onClose} open={show}>
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
      >
        <img
          width="300px"
          style={{ maxWidth: '100vw' }}
          src={customerServiceLogo}
          alt="sac logo"
        />
        <Typography variant="h2" fontSize={16} textAlign="center">
          Faça Log-In
        </Typography>

        <VForm onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gap={2}>
            <VTextField
              required
              name="document_number"
              label="CPF/CNPJ"
              size="small"
              fullWidth
              inputProps={{
                maxLength: 18,
              }}
              formatData={formatToCPFOrCNPJ}
              borderTopLeft="50px"
              borderBottomLeft="50px"
            />
            <VTextField
              required
              name="password"
              label="Senha"
              type="password"
              size="small"
              fullWidth
              borderTopLeft="50px"
              borderBottomLeft="50px"
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              gap={2}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <Button
                  type="submit"
                  disableElevation
                  variant="contained"
                  disabled={isLoading}
                  startIcon={<Login />}
                >
                  entrar
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<PersonAdd />}
                  onClick={() => setCadastreSeModal(true)}
                >
                  cadastre-se
                </Button>
              </Box>
              <Button startIcon={<KeyOff />} fullWidth>
                esqueci a senha
              </Button>
            </Box>
          </Box>
        </VForm>
        <CadastreSeModal
          customerServiceLogo={customerServiceLogo}
          onClose={closeCadastreSeModal}
          show={cadastreSeModal}
        />
      </Box>
    </Modal>
  )
}

// Global imports
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material'

// Local Imports
import { FormAssuntos } from './formulario/Formulário'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  LegendaSumario,
  NotificacaoError,
  FerramentasDeDetalhes,
} from '../../../shared/components'
import { ISubjects, getSubjectById } from '../../../shared/services/api'
import { ExpandMore } from '@mui/icons-material'
import {
  SectorsSubjectHistory,
  SubjectHistory,
} from './components/SubjectHistory'
import {
  ISubjectHistory,
  getSubjectHistory,
  getSectorsSubjectHistory,
  ISectorsInSubject,
} from '../../../shared/services/api/histories-services'
import { DisplayData } from './components/DisplayDetails'
import { useAuthContext } from '../../../shared/contexts'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const DetalhesAssuntos: React.FC = () => {
  const navigate = useNavigate()

  const updated = useRef(false)

  const { id = 'new' } = useParams<'id'>()
  const { user } = useAuthContext()

  const [data, setData] = useState<ISubjects>()
  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)

  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')

  const [history, setHistory] = useState<ISubjectHistory[]>([])
  const [historyLoading, setHistoryLoading] = useState(true)

  const [historySectors, setHistorySectors] = useState<ISectorsInSubject[]>([])
  const [historySectorsLoading, setHistorySectorsLoading] = useState(true)

  const handleViewForm = () => {
    setViewForm(!viewForm)
    setCreate(true)
  }

  useEffect(() => {
    if (id !== 'new') {
      setIsLoading(true)
      setHistoryLoading(true)
      setHistorySectorsLoading(true)

      Promise.all([
        getSubjectById(Number(id)),
        getSubjectHistory(Number(id)),
        getSectorsSubjectHistory(Number(id)),
      ]).then((values) => {
        setIsLoading(false)
        setHistoryLoading(false)
        setHistorySectorsLoading(false)
        updated.current = false

        // Subject Data
        if (values[0] instanceof Error) {
          NotificacaoError(values[0].message)
          navigate(-1)
        } else {
          setName(values[0].name)
          setData(values[0])
        }

        // Subject History Data
        if (values[1] instanceof Error) {
          NotificacaoError(values[1].message)
        } else {
          setHistory(values[1])
        }

        // Sectors Subject History Data
        if (values[2] instanceof Error) {
          NotificacaoError(values[2].message)
        } else {
          setHistorySectors(values[2])
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, updated.current])

  const { moduleTitle } = useModuleContext()

  return (
    <LayoutBaseDePagina
      title={`Assunto ${name}` || 'Detalhes Assunto'}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          textoBotaoNovo="Novo"
          mostrarBotaoNovo={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.hr_manager ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          mostrarBotaoEditar={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.hr_manager ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
          aoClicarEmVoltar={() => navigate(-1)}
          mostrarBotaoApagar={false}
        />
      }
    >
      <Box margin={1}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario icon="info_outlined" name="Informações" />
          </AccordionSummary>
          <AccordionDetails>
            <DisplayData data={data} isLoading={isLoading} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario name="Histórico" icon="restore" />
          </AccordionSummary>
          <AccordionDetails>
            <SubjectHistory history={history} historyLoading={historyLoading} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario name="Histórico de setores" icon="work_history" />
          </AccordionSummary>
          <AccordionDetails>
            <SectorsSubjectHistory
              history={historySectors}
              historyLoading={historySectorsLoading}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <FormAssuntos
        show={viewForm}
        onClose={handleViewForm}
        update={updated}
        create={create}
        data={!create ? data : undefined}
      />
    </LayoutBaseDePagina>
  )
}

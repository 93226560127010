import { createContext, useCallback, useContext, useMemo } from 'react'
import { ThemeProvider } from '@emotion/react'
import { Box } from '@mui/material'

import { LightTeme, DarkTheme } from '../themes'
import usePersistedState from '../hooks/usePersistedState'

interface IThemeContextData {
  themeName: 'light' | 'dark'
  toggleTheme: () => void
}

const ThemeContext = createContext({} as IThemeContextData)

interface IAppThemeProviderProps {
  children: React.ReactNode
}

export const useAppThemeContext = () => {
  return useContext(ThemeContext)
}

export const AppThemeProvider: React.FC<IAppThemeProviderProps> = ({
  children,
}) => {
  const [themeName, setThemeName] = usePersistedState<'light' | 'dark'>(
    'APP_THEME',
    'light',
  )

  const toggleTheme = useCallback(() => {
    setThemeName((oldThemeName) =>
      oldThemeName === 'light' ? 'dark' : 'light',
    )
  }, [setThemeName])

  const theme = useMemo(() => {
    if (themeName === 'light') {
      return LightTeme
    }
    return DarkTheme
  }, [themeName])

  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <Box
          width="100vw"
          height="100vh"
          bgcolor={theme.palette.background.default}
        >
          {children}
        </Box>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

import { Api } from '../axios-config'

export interface ISectorHistory {
  old: string
  new: string
  date: string
  user: string
  field: string
  type: '+' | '~'
}

const getSectorHistory = async (
  sectorId: number,
): Promise<ISectorHistory[] | Error> => {
  try {
    const relativeUrl = `/V1/sectors/${sectorId}/historical/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao listar histórico')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export { getSectorHistory }

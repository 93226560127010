import { BrowserRouter } from 'react-router-dom'

import { AppRoutes } from './routes'
import './shared/forms/TraducoesYup'
import { MenuLateral } from './shared/components'
import {
  AppThemeProvider,
  DrawerProvider,
  AuthProvider,
  ExternalAuthProvider,
} from './shared/contexts'
import './styled.css'
import { Login } from './shared/components/login/Login'
import { ModuleProvider } from './shared/contexts/ModulesContext'

export const App = () => {
  return (
    <AuthProvider>
      <ExternalAuthProvider>
        <ModuleProvider>
          <AppThemeProvider>
            <Login>
              <DrawerProvider>
                <BrowserRouter>
                  <MenuLateral>
                    <AppRoutes />
                  </MenuLateral>
                </BrowserRouter>
              </DrawerProvider>
            </Login>
          </AppThemeProvider>
        </ModuleProvider>
      </ExternalAuthProvider>
    </AuthProvider>
  )
}

import { useEffect, useState, useMemo } from 'react'
import {
  Autocomplete,
  CircularProgress,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'
import { getAllGroupings } from '../../../../shared/services/api'
import { NotificacaoError } from '../../../../shared/components'
import { useDebounce } from '../../../../shared/hooks'
import { useField } from '@unform/core'

type TAutoCompleteOptions = {
  id: number
  label: string
}

interface IAutoCompleteProps {
  isExternalLoading?: boolean
  textStyle?: SxProps<Theme>
}

export const AutoComplete: React.FC<IAutoCompleteProps> = ({
  isExternalLoading = false,
  textStyle = {},
}) => {
  const [firstTime, setFirstTime] = useState(true)
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField('grouping_id')

  const [selectedId, setSelectedId] = useState<number | null>(null)
  const { debounce } = useDebounce(1000)
  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newValue) => setSelectedId(newValue),
    })
  }, [registerField, fieldName, selectedId])

  useEffect(() => {
    setFirstTime(false)
    setSelectedId(defaultValue)
  }, [defaultValue, firstTime])

  useEffect(() => {
    setIsLoading(true)
    const institution = localStorage.getItem('ADM_SELECTED_INSTITUTION') || ''
    if (firstTime) {
      setFirstTime(false)
      setSelectedId(defaultValue)
      getAllGroupings(
        '0',
        '999999',
        search,
        defaultValue,
        institution,
        '1',
      ).then((result) => {
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError('Erro ao Carregar Agrupamentos')
        } else {
          setOptions(
            result.results.map((state) => ({
              id: state.id,
              label: state.name,
            })),
          )
        }
      })
    } else {
      debounce(() => {
        getAllGroupings('0', '500', search, '', institution, '1').then(
          (result) => {
            setIsLoading(false)

            if (result instanceof Error) {
              NotificacaoError('Erro ao Carregar Agrupamentos')
            } else {
              setOptions(
                result.results.map((state) => ({
                  id: state.id,
                  label: state.name,
                })),
              )
            }
          },
        )
      })
    }
  }, [debounce, defaultValue, firstTime, search])

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null

    const selectedOption = options.find((option) => option.id === selectedId)
    if (!selectedId) return null

    return selectedOption
  }, [selectedId, options])

  return (
    <Autocomplete
      value={autoCompleteSelectedOption}
      loading={isLoading}
      disabled={isExternalLoading}
      openText="Abrir"
      closeText="Fechar"
      noOptionsText="Sem Opções"
      disablePortal
      popupIcon={
        isExternalLoading || isLoading ? (
          <CircularProgress size={20} />
        ) : undefined
      }
      loadingText="Carregando..."
      onInputChange={(_, newValue) => setSearch(newValue)}
      inputValue={search}
      options={options}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.id || null)
        clearError()
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={textStyle}
          size="small"
          label="Agrupamento"
          error={!!error}
          helperText={error}
        />
      )}
    />
  )
}

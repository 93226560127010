import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useField } from '@unform/core'
import { FC, useEffect, useState } from 'react'
import { getAllSacCategoriesExternal } from '../../../../../../shared/services/api/sac-services'
import { NotificacaoError } from '../../../../../../shared/components'
import { useDebounce } from '../../../../../../shared/hooks'

interface AutocompleteSacCategoryProps {
  customerService: number | string | undefined
}

type AutoCompleteOption = {
  id: number
  label: string
}

export const AutocompleteSacCategory: FC<AutocompleteSacCategoryProps> = ({
  customerService,
}) => {
  const { debounce } = useDebounce(1000)
  const { fieldName, registerField, error, clearError } = useField('category')

  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<AutoCompleteOption[]>([])
  const [search, setSearch] = useState('')
  const [value, setValue] = useState<number>()
  const [selectedOption, setSelectedOption] =
    useState<AutoCompleteOption | null>(null)

  useEffect(() => {
    if (customerService) {
      setIsLoading(true)
      debounce(() => {
        getAllSacCategoriesExternal(customerService, search)
          .then((result) => {
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              setOptions(
                result.map((category) => ({
                  id: category.id,
                  label: category.name,
                })),
              )
            }
          })
          .finally(() => setIsLoading(false))
      })
    } else {
      setIsLoading(false)
    }
  }, [customerService, search])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [value, fieldName, registerField])

  return (
    <Autocomplete
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      loading={isLoading}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      noOptionsText={'Nenhuma categoria encontrado'}
      inputValue={search}
      value={selectedOption}
      options={options}
      onChange={(e, newValue) => {
        setSearch('')
        setSelectedOption(newValue || null)
        setValue(newValue?.id)
        clearError()
      }}
      onInputChange={(e, newValue) => {
        setSearch(newValue)
        clearError()
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          fullWidth
          size="small"
          label="Categoria"
          error={!!error}
          helperText={error}
          sx={{ fieldSet: { borderRadius: '50px' } }}
        />
      )}
    />
  )
}

// Global Imports
import { FC } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'

// Local imports
import defaultLogo from '../../images/default_logo.webp'

export const NotFoundPage: FC = () => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      width="100vw"
      display="flex"
      height="100vh"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <img
        src={defaultLogo}
        title="TickDoc Logo"
        alt="TickDoc Logo"
        width={xsDown ? '300px' : smDown ? '400px' : '500px'}
      />
      <Typography color="dodgerblue" variant="h6">
        Hm... Página não encontrada
      </Typography>
      <Typography variant="caption">
        verifique se a URL foi digitada corretamente
      </Typography>
    </Box>
  )
}

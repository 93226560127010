import { Api } from '../axios-config'

const clearAllRead = async (userId: number): Promise<string | Error> => {
  try {
    const relativeUrl = `/V1/notifications/clear_read/`

    const { data } = await Api.post(relativeUrl, { user_id: userId })

    if (data) return data

    return new Error('Erro ao limpar notificações')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const markAsRead = async (userId: number): Promise<string | Error> => {
  try {
    const relativeUrl = `/V1/notificarions/mark_as_read/`

    const { data } = await Api.post(relativeUrl, { user_id: userId })

    if (data) return data

    return new Error('Erro ao ler notificações')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export { clearAllRead, markAsRead }

import { Api } from '../axios-config'

export interface ISectorInUser {
  sector_name: string
  sector_id: number
  type: '+' | '-'
  user: string
  date: string
}

export interface IUserInSector {
  username: string
  user_id: number
  type: '+' | '-'
  date: string
  user: string
}

export interface IInternalUserHistory {
  type: '+' | '~'
  field: string
  user?: string
  date?: string
  old?: string
  new?: string
}

const getSectorsInUserHistory = async (
  userId: number,
): Promise<ISectorInUser[] | Error> => {
  try {
    const relativeUrl = `/V1/internalusers/sectors/${userId}/historical/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao listar histórico de setores')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getUsersInSectorHistory = async (
  sectorId: number,
): Promise<IUserInSector[] | Error> => {
  try {
    const relativeUrl = `/V1/sectors/users/${sectorId}/historical/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao listar histórico de usuários')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getInternaUserHistory = async (
  userId: number,
): Promise<IInternalUserHistory[] | Error> => {
  try {
    const relativeUrl = `/V1/internalusers/${userId}/historical/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao listar histórico')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export {
  getSectorsInUserHistory,
  getUsersInSectorHistory,
  getInternaUserHistory,
}

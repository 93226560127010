import { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Modal,
  Paper,
  Button,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
  LinearProgress,
  FormControlLabel,
  IconButton,
  Tooltip,
} from '@mui/material'
import { CameraAlt, Cancel, Delete, Launch, Save } from '@mui/icons-material'
import { ValidationError } from 'yup'

import {
  SacSlide,
  createSacSlide,
  updateSacSlide,
} from '../../../../shared/services/api/sac-services'
import {
  FormBoxStyle,
  ModalHeader,
  NotificacaoAlerta,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { VForm, VTextField, useVForm } from '../../../../shared/forms'
import { VColorPicker } from '../../../../shared/forms/VColorPicker'
import { useDebounce } from '../../../../shared/hooks'
import { IFormData, formValidationSchema } from './validate'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'
import { AutocompleteSac } from '../components/AutocompleteSacs'

interface FormularioProps {
  show: boolean
  data?: SacSlide
  create: boolean
  onClose: () => void
  institution: string | number
  setUpdate: () => void
}

export const Formulario = (props: FormularioProps) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { formRef } = useVForm()
  const { debounce } = useDebounce(1000, false)
  const { thisModule } = useModuleContext()

  const [isLoading, setIsLoading] = useState(false)

  const [buttonColor, setButtonColor] = useState('#1976D2')
  const [buttonText, setButtonText] = useState('Saiba mais')
  const [buttonTextColor, setButtonTextColor] = useState('#FFFFFF')

  const [file, setFile] = useState<Blob>()
  const [fileUrl, setFileUrl] = useState('')

  const handleClose = () => {
    formRef.current?.reset()
    setButtonColor('#1976D2')
    setButtonText('Saiba mais')
    setButtonTextColor('#FFFFFF')
    URL.revokeObjectURL(fileUrl)
    setFile(undefined)
    setFileUrl('')
    props.onClose()
  }

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)
    if (file) {
      formData.image = file
    }
    if (props.create && thisModule && thisModule?.profile !== 0) {
      if (!file) {
        setIsLoading(false)
        return NotificacaoAlerta('A imagem é obrigatória')
      }
      formData.customer_service = thisModule.id
    } else if (!props.create && props.data) {
      formData.customer_service = props.data.customer_service
    }

    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData: IFormData) => {
        if (props.create) {
          createSacSlide(validatedData).then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              NotificacaoSucesso('Slide cadastrado com sucesso!')
              props.setUpdate()
              handleClose()
            }
          })
        } else if (props.data) {
          updateSacSlide(props.data.id, validatedData).then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              NotificacaoSucesso('Slide atualizado com sucesso')
              props.setUpdate()
              handleClose()
            }
          })
        }
      })
      .catch((errors: ValidationError) => {
        const validationErrors: Record<string, string> = {}

        errors.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path] = error.message
          }
        })
        console.log(validationErrors)
        formRef.current?.setErrors(validationErrors)
        setIsLoading(false)
      })
    setIsLoading(false)
  }

  useEffect(() => {
    if (!props.create && props.data) {
      setFileUrl(props.data.image)
      setButtonColor(props.data.button_color)
      setButtonText(props.data.button_text)
      setButtonTextColor(props.data.button_text_color)
    }
  }, [props.create, props.data])

  return (
    <Modal open={props.show} onClose={handleClose}>
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <input
          hidden
          accept="image/*"
          type="file"
          id="image-input"
          onChange={(e) => {
            if (e.target.files) {
              setFile(e.target.files[0])
              setFileUrl(URL.createObjectURL(e.target.files[0]))
            } else {
              if (fileUrl) {
                URL.revokeObjectURL(fileUrl)
              }
              setFile(undefined)
              setFileUrl('')
            }
          }}
        />
        <ModalHeader
          showCloseButton
          title={props.create ? 'Novo slide' : 'Editar slide'}
          onClose={handleClose}
        />
        <Divider />

        {isLoading && <LinearProgress />}

        <Box margin={1}>
          <VForm
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={
              props.create
                ? {
                    button_text_color: '#FFFFFF',
                    button_color: '#1976D2',
                    button_text: 'Saiba mais',
                  }
                : props.data
            }
          >
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={2}>
                <Grid container item spacing={1}>
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <img
                      src={file ? fileUrl : props.data?.image}
                      width="300vw"
                      alt="slide_image"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    gap={1}
                  >
                    <Button
                      startIcon={<CameraAlt />}
                      variant="contained"
                      disabled={!!file}
                      onClick={() =>
                        document.getElementById('image-input')?.click()
                      }
                    >
                      Inserir imagem
                    </Button>
                    {!!file && (
                      <Tooltip title="remover imagem">
                        <IconButton
                          onClick={() => {
                            URL.revokeObjectURL(fileUrl)
                            setFileUrl('')
                            setFile(undefined)
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    required
                    fullWidth
                    size="small"
                    name="title"
                    label="Título"
                    borderTopLeft="50px"
                    placeholder="Slide 1"
                    borderBottomLeft="50px"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    fullWidth
                    size="small"
                    name="link"
                    label="Link"
                    borderTopLeft="50px"
                    placeholder="https://exemplo.com.br"
                    borderBottomLeft="50px"
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormControlLabel
                    label="Cor dos botões"
                    sx={{ gap: 1 }}
                    control={
                      <VColorPicker
                        name="button_color"
                        className="color-picker"
                        customOnChange={(e) =>
                          debounce(() => {
                            setButtonColor(e.target.value)
                          })
                        }
                      />
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormControlLabel
                    label="Cor do texto"
                    sx={{ gap: 1 }}
                    control={
                      <VColorPicker
                        name="button_text_color"
                        className="color-picker"
                        customOnChange={(e) =>
                          debounce(() => {
                            setButtonTextColor(e.target.value)
                          })
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                spacing={2}
                display="flex"
                alignItems="center"
              >
                <Grid item xs={12} sm={6}>
                  <VTextField
                    name="button_text"
                    required
                    size="small"
                    label="Texto do botão"
                    onChange={(e) =>
                      debounce(() => {
                        setButtonText(e.target.value)
                      })
                    }
                    inputProps={{
                      maxLength: 30,
                    }}
                    fullWidth
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={0.2}
                  flexDirection="column"
                >
                  <Typography>Prévia do botão</Typography>
                  <Button
                    variant="contained"
                    startIcon={<Launch />}
                    sx={{
                      color: buttonTextColor,
                      bgcolor: buttonColor,
                      ':hover': { bgcolor: buttonColor },
                    }}
                  >
                    {buttonText}
                  </Button>
                </Grid>
              </Grid>

              {thisModule?.profile === 0 && (
                <Grid item xs={12}>
                  <AutocompleteSac
                    name="customer_service"
                    institution={props.institution}
                  />
                </Grid>
              )}
            </Grid>
            <Box
              display="flex"
              flex="1"
              justifyContent="center"
              alignItems="center"
              mt={2}
              gap={1}
            >
              <Button
                disableElevation
                disabled={isLoading}
                variant="contained"
                type="submit"
                startIcon={<Save />}
              >
                salvar
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                startIcon={<Cancel />}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

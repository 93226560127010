import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
} from '@mui/material'
import { FC, useState } from 'react'
import { FormBoxStyle, ModalHeader } from '../../../../../shared/components'
import { useModuleContext } from '../../../../../shared/contexts/ModulesContext'
import { Cancel, Check } from '@mui/icons-material'
import { Environment } from '../../../../../shared/environment'

interface DownloadPdfModalProps {
  show: boolean
  protocolId: number
  onClose: () => void
  protocolSecret: boolean
}

export const DownloadPdfModal: FC<DownloadPdfModalProps> = ({
  show,
  onClose,
  protocolId,
  protocolSecret,
}) => {
  const { thisModule } = useModuleContext()

  const [disableDownload, setDisableDownload] = useState(false)
  const [secret, setSecret] = useState(protocolSecret)
  const [replies, setReplies] = useState<string>()

  function handleSubmit() {
    setDisableDownload(true)
    window.open(
      `${
        Environment.URL_BASE
      }/V1/customer_service/protocols/${protocolId}/pdf_view/${
        secret ? 1 : 0
      }/${replies}/`,
    )
    setDisableDownload(false)
  }

  function handleClose() {
    setDisableDownload(false)
    setReplies(undefined)
    onClose()
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        width="300px"
        maxHeight="85%"
        borderRadius="20px"
        flexDirection="column"
      >
        <ModalHeader
          showCloseButton
          title="Download PDF"
          onClose={handleClose}
        />

        <Divider />

        <Box
          mt={1}
          display="flex"
          gap={2}
          flex="1"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <FormControlLabel
            label="Protocolo anonimizado?"
            control={
              <Switch
                size="small"
                disabled={protocolSecret && thisModule?.profile !== 2}
                value={secret}
                defaultChecked={protocolSecret}
                onChange={(_, e) => setSecret(e)}
              />
            }
          />
          <FormControl size="small" fullWidth>
            <InputLabel id="replies-label">Respostas no PDF</InputLabel>
            <Select
              fullWidth
              required
              size="small"
              value={replies}
              label="Respostas no PDF"
              labelId="replies-label"
              sx={{
                borderRadius: '50px',
              }}
              onChange={(e) =>
                setReplies(
                  (e.target as { value: 'null' | 'external' | 'all' }).value,
                )
              }
            >
              <MenuItem value="null">Não incluir respostas</MenuItem>
              <MenuItem value="external">Apenas externas</MenuItem>
              <MenuItem value="all">Todas</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          display="flex"
          flex="1"
          mt={2}
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <Button
            type="button"
            disableElevation
            variant="contained"
            startIcon={<Check />}
            onClick={handleSubmit}
            disabled={disableDownload}
          >
            confirmar
          </Button>
          <Button
            variant="outlined"
            type="button"
            startIcon={<Cancel />}
            onClick={handleClose}
          >
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

import { Schema } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'

export interface IFormData {
  id?: number
  name: string
  initials: string
}

const formValidationSchema: Schema<IFormData> = yup.object().shape({
  id: yup.number(),
  name: yup.string().required().min(3),
  initials: yup.string().required().length(2),
})

export default formValidationSchema

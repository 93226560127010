/* eslint-disable @typescript-eslint/ban-types */
import { Api, TotalCount } from '../../axios-config'

export interface SacPergunta {
  id: number
  customer_service: number
  introduction?: string
  question: string
  created_at: string
  updated_at: string
  status: boolean
}

export const getAllSacQuestions = async (
  busca = '',
  item = '',
  total = '',
  sac = '',
  institution = '',
): Promise<TotalCount<SacPergunta> | Error> => {
  try {
    const relativeUrl = '/V1/customer_service/questions/'

    const { data } = await Api.get(relativeUrl, {
      params: {
        item,
        search: busca,
        total,
        customer_service: sac,
        customer_service__institution: institution,
      },
    })

    if (data) return data

    return new Error('Erro ao carregar perguntas')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const createSacQuestion = async (formData: {}): Promise<
  SacPergunta | Error
> => {
  try {
    const relativeUrl = '/V1/customer_service/questions/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao criar pergunta')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const updateSacQuestion = async (
  id: number,
  formData: {},
): Promise<SacPergunta | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/questions/${id}/`

    const { data } = await Api.patch(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao atualizar pergunta')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

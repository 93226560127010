// Global Imports
import { FC } from 'react'
import { Divider, Grid, LinearProgress, Typography } from '@mui/material'

// Local Imports
import {
  IInternalUserHistory,
  ISectorInUser,
} from '../../../../shared/services/api'
import { FormatDateTime } from '../../../../shared/utils'

interface IUserHistoryProps {
  createdAt: string
  historyLoading: boolean
  history: IInternalUserHistory[]
}

interface ISectorsUserHistoryProps {
  history: ISectorInUser[]
  historyLoading: boolean
}

export const UserHistory: FC<IUserHistoryProps> = ({
  history,
  createdAt,
  historyLoading,
}) => {
  return (
    <Grid container spacing={2} direction="column">
      {historyLoading && (
        <Grid item>
          <LinearProgress />
        </Grid>
      )}
      {history.map((query) => (
        <>
          <Divider variant="middle" />
          {query.type === '~' ? (
            <Grid container item>
              {query.field === 'username' && (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>
                      Nome alterado de ({query.old}) para ({query.new})
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Alterado por: {query.user}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Data: {FormatDateTime(query.date, 'Não definido')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {query.field === 'email' && (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>
                      Email alterado de ({query.old}) para ({query.new})
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Alterado por: {query.user || 'Não definido'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Data: {FormatDateTime(query.date, 'Não definido')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {query.field === 'document_type' && (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>
                      Tipo de documento alterado de{' '}
                      {query.old === '1' ? 'CPF para CNPJ' : 'CNPJ para CPF'}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {query.field === 'document_number' && (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>
                      Número de documento alterado de ({query.old}) para (
                      {query.new})
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Alterado por: {query.user}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Data: {FormatDateTime(query.date, 'Não definido')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {query.field === 'password' && (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>Alterou a senha</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Data: {FormatDateTime(query.date, 'Não definido')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {query.field === 'is_active' && (
                <Grid container item direction="column">
                  <Grid container item>
                    <Typography>
                      {query.new
                        ? 'Usuário foi ativado'
                        : 'Usuário foi desativdo'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {query.new
                        ? `Ativado por: ${query.user}`
                        : `Desativado por: ${query.user}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Data: {FormatDateTime(query.date, 'Não definido')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {query.field === 'is_confirmed' && (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>Confirmou a conta</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Data: {FormatDateTime(query.date, 'Não definido')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {query.field === 'confirmation_token' && (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>
                      Token de confirmação de conta gerado
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Data: {FormatDateTime(query.date, 'Não definido')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {query.field === 'confirmation_token_sent_at' && (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>Token de confirmação enviado</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Data: {FormatDateTime(query.date, 'Não definido')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {query.field === 'reset_password_token' && (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>
                      Token de alteração de senha gerado ou removido
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Data: {FormatDateTime(query.date, 'Não definido')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {query.field === 'reset_password_sent_at' && (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>Token de alteração de senha enviado</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Data: {FormatDateTime(query.date, 'Não definido')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {query.field === 'profile_picture' && (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>Foto de perfil alterada</Typography>
                  </Grid>
                  {query.user && (
                    <Grid item>
                      <Typography>Alterada por: {query.user}</Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <Typography>
                      Data: {FormatDateTime(query.date, 'Não definido')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {query.field === 'modules' && (
                <Grid container item direction="column">
                  <Grid item>
                    {String(query.old) === 'ci' ? (
                      <Typography>
                        {String(query.new).includes('ombudsman') &&
                        String(query.new).includes('ci')
                          ? 'Módulo OUVIDORIA adcionado'
                          : String(query.new).includes('ombudsman') &&
                            !String(query.new).includes('ci')
                          ? 'Módulo OUVIDORIA adcionado e CI removido'
                          : 'Módulo CI removido'}
                      </Typography>
                    ) : String(query.old) === 'ombudsman' ? (
                      <Typography>
                        {String(query.new).includes('ombudsman') &&
                        String(query.new).includes('ci')
                          ? 'Módulo CI adicionado'
                          : !String(query.new).includes('ombudsman') &&
                            String(query.new).includes('ci')
                          ? 'Módulo de CI adicionado e OUVIDORIA removido'
                          : 'Módulo OUVIDORIA removido'}
                      </Typography>
                    ) : query.old === '' ? (
                      <Typography>
                        {String(query.new).includes('ci') &&
                        !String(query.new).includes('ombudsman')
                          ? 'Módulo CI adicionado'
                          : !String(query.new).includes('ci') &&
                            String(query.new).includes('ombudsman')
                          ? 'Módulo OUVIDORIA adicionado'
                          : 'Módulo CI e OUVIDORIA adicionados'}
                      </Typography>
                    ) : (
                      <Typography>
                        {String(query.new).includes('ombudsman')
                          ? 'Módulo CI removido'
                          : String(query.new).includes('ci')
                          ? 'Módulo OUVIDORIA removido'
                          : 'Módulos CI e OUVIDORIA removidos'}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography>Alterado por: {query.user}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Data: {FormatDateTime(query.date)}</Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid container item direction="column">
              <Grid item>
                <Typography>
                  Usuário criado por {query.user || 'Não definido'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Data: {FormatDateTime(createdAt, 'Não definido')}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      ))}
    </Grid>
  )
}

export const SectorsUserHistory: FC<ISectorsUserHistoryProps> = ({
  history,
  historyLoading,
}) => {
  return (
    <Grid container direction="column" spacing={2}>
      {historyLoading && (
        <Grid item>
          <LinearProgress variant="indeterminate" />
        </Grid>
      )}

      {history.map((query, index) => (
        <Grid container item spacing={1} direction="column" key={index}>
          <Grid item>
            <Typography>
              {query.type === '+'
                ? `Adcionado ao setor ${query.sector_name}`
                : `Removido do setor ${query.sector_name}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {query.type === '+'
                ? `Adcionado por: ${query.user}`
                : `Removido por: ${query.user}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>Data: {FormatDateTime(query.date)}</Typography>
          </Grid>
          {history.length !== index + 1 && <Divider sx={{ marginTop: 2 }} />}
        </Grid>
      ))}
    </Grid>
  )
}

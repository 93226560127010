import { Schema } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'

export interface IFormData {
  name: string
  institution: number
  customer_service: number
  status: boolean
  grouping: number
}

export const formValidationSchema: Schema<IFormData> = yup.object().shape({
  customer_service: yup.number().positive().required(),
  grouping: yup.number().positive().required(),
  institution: yup.number().positive().required(),
  name: yup.string().required().min(3),
  status: yup.boolean().default(true),
})

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  CardContent,
  Grid,
  Icon,
  Paper,
  Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import { Card } from 'react-bootstrap'
import { VForm, VTextField } from '../../../shared/forms'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { Api } from '../../../shared/services/api/axios-config'
import {
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'
import { useParams } from 'react-router-dom'

import defaultLogo from '../../../images/default_logo.png'

export const RedefinirSenhaExterno: FC = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { slug } = useParams<'slug'>()

  const handleSubmit = (formData: any) => {
    setIsLoading(true)
    Api.post(`/V1/externalusers/resetpassword/${slug}/`, formData)
      .then(() => {
        setIsLoading(false)
        NotificacaoSucesso(
          'Em instantes, um email ou sms será enviado para você para redefinição de senha',
        )
      })
      .catch((error) => {
        setIsLoading(false)
        NotificacaoError(error.message)
      })
  }

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
    >
      <Card>
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            width={320}
            component={Paper}
            padding={2}
          >
            <img src={defaultLogo} alt="logo" />
            <Typography variant="h4" textAlign="center">
              Esqueceu Sua Senha?
            </Typography>
            <Typography textAlign="center">
              Insira seu CPF/CNPJ e enviaremos um email ou sms com instruções
              para redefinir sua senha
            </Typography>
            <VForm onSubmit={handleSubmit}>
              <Grid
                container
                display="flex"
                justifyContent="center"
                spacing={1}
                paddingX={2}
              >
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="document_number"
                    label="CPF/CNPJ"
                    placeholder="Digite seu CPF ou CNPJ"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    formatData={formatToCPFOrCNPJ}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 18 }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    endIcon={<Icon>send</Icon>}
                    disabled={isLoading}
                  >
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </VForm>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

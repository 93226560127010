import { useState, useEffect, MutableRefObject } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import {
  FormBoxStyle,
  NotificacaoPromise,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { VForm, useVForm, VTextField } from '../../../../shared/forms'
import {
  IStates,
  createState,
  updateStateById,
} from '../../../../shared/services/api'
import { Delete, PhotoCamera } from '@mui/icons-material'
import formValidationSchema, { IFormData } from './validation'
import { ValidationError } from 'yup'

export const CriarEstado = (
  onClose: () => void,
  show = false,
  update: MutableRefObject<boolean>,
  create: boolean,
  data?: IStates,
) => {
  const [formTitle, setFormTitle] = useState('')
  const { formRef } = useVForm()

  const [flag, setFlag] = useState<File>()
  const [flagUrl, setFlagUrl] = useState<string>()

  const [isLoading, setIsLoading] = useState(false)

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const formFlag = new FormData()

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)

    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData) => {
        if (create) {
          createState(validatedData).then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              update.current = true
              if (flag) {
                formFlag.append('flag', flag, flag.name)
                NotificacaoPromise(
                  updateStateById(Number(result), formFlag),
                  'Enviando Bandeira',
                  'Bandeira Adcionada com Sucesso',
                  'Erro ao Inserir Bandeira',
                )
                formRef.current?.reset()
                setFlagUrl('')
                setFlag(undefined)
                onClose()
              }
              NotificacaoSucesso('Estado Criado com Sucesso')
            }
          })
        } else {
          updateStateById(Number(data?.id), validatedData).then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              update.current = true
              NotificacaoSucesso('Estado Atualizado com Sucesso')
              if (flag) {
                formFlag.append('flag', flag, flag.name)
                NotificacaoPromise(
                  updateStateById(Number(data?.id), formFlag),
                  'Enviando Bandeira',
                  'Bandeira Atualizada com Sucesso',
                  'Erro ao Atualizar Bandeira',
                )
              }
              formRef.current?.reset()
              setFlagUrl('')
              setFlag(undefined)
              onClose()
            }
          })
        }
      })
      .catch((errors: ValidationError) => {
        setIsLoading(false)
        const validationErros: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validationErros[error.path] = error.message
        })
        formRef.current?.setErrors(validationErros)
      })
  }

  useEffect(() => {
    if (!create && data !== undefined)
      setFormTitle(`Editar Estado ${data.name}`)
    else setFormTitle('Novo Estado')
  }, [create, data, show])

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Box display="flex" justifyContent="center" alignContent="center">
          <Typography
            variant="h6"
            component="h2"
            id="modal-title"
            textOverflow="ellipsis"
            noWrap
          >
            {formTitle}
          </Typography>
        </Box>

        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{ height: '5px', borderRadius: '50px' }}
          />
        )}
        <Box margin={1}>
          <VForm
            ref={formRef}
            onSubmit={(formData: IFormData) => handleSubmit(formData)}
            initialData={create ? undefined : data}
          >
            <Grid
              container
              item
              direction="column"
              xs={12}
              justifyContent="center"
              display="flex"
            >
              <Grid item xs={12} display="flex" justifyContent="center">
                <img
                  src={flag ? flagUrl : data?.flag}
                  width="300vw"
                  alt="state_flag"
                />
              </Grid>
              <Grid
                item
                paddingX={2}
                xs={12}
                display="flex"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  disabled={!!flag}
                  component="label"
                  endIcon={<PhotoCamera />}
                  disableElevation
                  sx={{ borderRadius: '50px' }}
                >
                  Inserir Bandeira
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(e) => {
                      if (e.target.files) {
                        setFlag(e.target.files[0])
                        setFlagUrl(URL.createObjectURL(e.target.files[0]))
                      } else {
                        if (flagUrl) {
                          URL.revokeObjectURL(flagUrl)
                        }
                        setFlag(undefined)
                        setFlagUrl('')
                      }
                    }}
                  />
                </Button>
                {!!flag && (
                  <IconButton
                    onClick={() => {
                      if (flagUrl) {
                        URL.revokeObjectURL(flagUrl)
                      }
                      setFlag(undefined)
                      setFlagUrl('')
                    }}
                  >
                    <Delete />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" padding={2}>
              <Grid item xs={4}>
                <VTextField
                  fullWidth
                  required
                  size="small"
                  name="initials"
                  label="Sigla"
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                  borderBottomRight="0px"
                  borderTopRight="0px"
                  upercase={true}
                  inputProps={{ maxLength: 2 }}
                />
              </Grid>

              <Grid item xs={8}>
                <VTextField
                  fullWidth
                  required
                  size="small"
                  name="name"
                  label="Nome"
                  borderBottomLeft="0px"
                  borderTopLeft="0px"
                  borderBottomRight="50px"
                  borderTopRight="50px"
                />
              </Grid>
            </Grid>
            <Box display="flex" flex={1} justifyContent="center">
              <Button type="submit">
                <Icon>save</Icon>
                Salvar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

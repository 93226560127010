import { yup } from '../../../../shared/forms/TraducoesYup'

export interface IFormData {
  id?: number
  name: string
}

const formValidationSchema = yup.object().shape({
  id: yup.number(),
  name: yup.string().required().max(30).min(3),
})

export default formValidationSchema

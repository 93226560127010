/* eslint-disable @typescript-eslint/ban-types */
import { TotalCount, Api } from '../../axios-config'
import { SacModule } from '../../modules-services/SacServices'

export interface SacProfile {
  id: number
  hr_manager: boolean
  profile: number
  status: boolean
  created_at: string
  updated_at: string
  user: number
  customer_service: SacModule
}

export interface UserSacProfile {
  id: number
  email: string
  username: string
  document_type: 1 | 2
  document_number: string
  data_manager: boolean
  is_active: boolean
  is_confirmed: boolean
  confirmed_at: string
  updated_at: string
  created_at: string
  last_login?: string
  gender: number
  sign_in_count: number
  profile_picture?: string
  birth_date?: string
  hr_manager: boolean
  esic_manager: boolean
  esic_auxiliary: boolean
  online: boolean
  institution_id: number
  sac_modules: Array<SacProfile>
}

export const getAllSacProfiles = async (
  busca = '',
  item = '0',
  total = '10',
  institution = '',
): Promise<TotalCount<UserSacProfile> | Error> => {
  try {
    const relativeUlr = '/V1/customer_service/profiles/'

    const { data } = await Api.get(relativeUlr, {
      params: {
        item,
        total,
        search: busca,
        institution_id: institution,
      },
    })

    if (data) return data

    return new Error('Erro ao carregar instâncias')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getSacProfileById = async (
  id: number,
): Promise<UserSacProfile | Error> => {
  try {
    const relativeUlr = `/V1/customer_service/profiles/${id}/`

    const { data } = await Api.get(relativeUlr)

    if (data) return data

    return new Error('Erro ao carregar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { messsage: string }).messsage)
  }
}

export const updateSacProfile = async (
  id: number,
  formData: {},
): Promise<SacProfile | Error> => {
  try {
    const relativeUlr = `/V1/customer_service/profiles/${id}/`

    const { data } = await Api.patch(relativeUlr, formData)

    if (data) return data

    return new Error('Erro ao atualizar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { messsage: string }).messsage)
  }
}

export const createSacProfile = async (formData: {}): Promise<
  SacProfile | Error
> => {
  try {
    const relativeUlr = '/V1/customer_service/profiles/'

    const { data } = await Api.post(relativeUlr, formData)

    if (data) return data

    return new Error('Erro ao criar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { messsage: string }).messsage)
  }
}

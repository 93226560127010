/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, FC, MutableRefObject } from 'react'
import { Autocomplete, TextField, CircularProgress } from '@mui/material'
import { useField } from '@unform/core'
import { useDebounce } from '../../../../../shared/hooks'
import { Api } from '../../../../../shared/services/api/axios-config'
import { NotificacaoError } from '../../../../../shared/components'

interface AutocompleteDocumentProps {
  update: MutableRefObject<boolean>
  name?: string
  required?: boolean
}

type AutocompleteOption = {
  id: number
  label: string
  document: string
}

export const AutocompleteDocument: FC<AutocompleteDocumentProps> = ({
  update,
  name = 'external_user_id',
  required = false,
}) => {
  const { fieldName, registerField } = useField(name)
  const { debounce } = useDebounce()

  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [value, setValue] = useState<number | null>(null)
  const [options, setOptions] = useState<AutocompleteOption[]>([])
  const [selectedOption, setSelectedOption] =
    useState<AutocompleteOption | null>(null)

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      Api.get(
        `/V1/externalusers/documents/all/?total=20&item=0&search=${search}`,
      )
        .then((result) => {
          setOptions(
            result.data.results.map((user: any) => ({
              id: user.id,
              label: `${user.document_number} - ${user.name}`,
            })),
          )
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
          NotificacaoError('Erro ao carregar usuários')
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, update.current])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [fieldName, registerField, value])

  return (
    <Autocomplete
      options={options}
      loading={isLoading}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      noOptionsText="Nenhuma opção encontrada"
      value={selectedOption}
      onChange={(_, newValue) => {
        setSelectedOption(newValue)
        setValue(newValue?.id || null)
      }}
      onInputChange={(_, value) => {
        setSearch(value)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          fullWidth
          value={search}
          size="small"
          label="Documento - Cidadão"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

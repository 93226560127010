import { Schema } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'

export interface IFormData {
  name: string
  status: boolean
  institution: number
  sectors: number[]
  customer_service: number
}

const formValidationSchema: Schema<IFormData> = yup.object().shape({
  sectors: yup.array(yup.number().required()).default([]),
  customer_service: yup.number().positive().required(),
  institution: yup.number().positive().required(),
  name: yup.string().min(3).required(),
  status: yup.boolean().default(true),
})

export { formValidationSchema }

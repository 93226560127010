export const admin = (title: string) => {
  return [
    {
      label: 'Dashboard',
      icon: 'leaderboard',
      path: `/ci/${title}/dashboard`,
    },
    {
      label: 'Clientes',
      icon: 'apartment',
      path: `/ci/${title}/clients`,
    },
    {
      label: 'Com. Internas',
      icon: 'connect_without_contact',
      path: `/ci/${title}/modules`,
    },
  ]
}

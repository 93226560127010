/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import { useDebounce } from '../../../../../shared/hooks'
import { useField } from '@unform/core'
import { getAllSacSectors } from '../../../../../shared/services/api/sac-services'
import { NotificacaoError } from '../../../../../shared/components'
import { useModuleContext } from '../../../../../shared/contexts/ModulesContext'
import { useAuthContext } from '../../../../../shared/contexts'

type AutocompleteOption = {
  value: number
  label: string
}

interface AutocompleteSetorSACProps {
  name: string
  grouping: number | undefined
  customOnChange(e: any, newvalue: AutocompleteOption | null): void
}

export const AutocompleteSetorSAC: FC<AutocompleteSetorSACProps> = ({
  grouping,
  name,
  customOnChange,
}) => {
  const currentGrouping = useRef<number | undefined>(undefined)

  const { debounce } = useDebounce(1000)
  const { user } = useAuthContext()
  const { thisModule } = useModuleContext()
  const { fieldName, registerField, error, clearError } = useField(name)

  const [options, setOptions] = useState<AutocompleteOption[]>([])
  const [selectedOption, setSelectedOption] =
    useState<AutocompleteOption | null>(null)
  const [value, setValue] = useState<number | null>(null)

  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    if (grouping !== currentGrouping.current) {
      setValue(null)
      setSelectedOption(null)
      currentGrouping.current = grouping
    }
    if (grouping) {
      debounce(() => {
        getAllSacSectors(
          search,
          '0',
          '999999999',
          String(user!.institution_id),
          String(thisModule!.id),
          String(grouping),
        ).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError('Erro ao carregar setores')
          } else {
            setOptions(
              result.results.map((sector) => ({
                label: sector.name,
                value: sector.id,
              })),
            )
          }
        })
      })
    } else {
      setIsLoading(false)
      setValue(null)
      setSelectedOption(null)
      setOptions([])
    }
  }, [search, grouping, debounce, user, thisModule])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [value, registerField, fieldName])

  return (
    <Autocomplete
      fullWidth
      loading={isLoading}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      size="small"
      disablePortal
      options={options}
      value={selectedOption}
      noOptionsText={
        grouping ? 'Nenhum setor encontrado' : 'Selecione um agrupamento'
      }
      inputValue={search}
      onInputChange={(_, newValue) => {
        setSearch(newValue)
        clearError()
      }}
      onChange={(_, newValue) => {
        setSelectedOption(newValue)
        setValue(newValue?.value || null)
        customOnChange(_, newValue)
        setSearch('')
        clearError()
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          fullWidth
          error={!!error}
          helperText={error}
          size="small"
          label="Setor"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

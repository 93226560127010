import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Modal,
  Paper,
  Typography,
} from '@mui/material'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../../shared/components'
import { FC, useState } from 'react'
import { ModalHeader } from '../../../../../shared/components/modal/Header'
import { Cancel, Check } from '@mui/icons-material'
import {
  SacProtocol,
  setSacProtocolSecret,
} from '../../../../../shared/services/api/sac-services'

interface AnonimizarModalProps {
  show: boolean
  onClose: () => void
  setData: (data: SacProtocol) => void
  protocolId: number | string
}

export const AnonimizarModal: FC<AnonimizarModalProps> = ({
  onClose,
  show,
  protocolId,
  setData,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    const result = await setSacProtocolSecret(protocolId, {
      protocol_id: protocolId,
    })

    if (result instanceof Error) {
      NotificacaoError(result.message)
    } else {
      NotificacaoSucesso('Protocolo anonimizado com sucesso!')
      setData(result)
      onClose()
    }

    setIsLoading(false)
  }

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        width="300px"
        maxHeight="85%"
        borderRadius="20px"
      >
        <ModalHeader title="ATENÇÃO" showCloseButton onClose={onClose} />
        <Divider />
        {isLoading && <LinearProgress sx={{ mt: 2 }} />}
        <Box margin={1}>
          <Typography textAlign="justify">
            Essa é uma ação irreversível. Após a confirmação, o protocolo será
            tratado como sigiloso e apenas usuários com perfil MASTER poderão
            ver os dados relacionados ao cidadão.
          </Typography>
        </Box>
        <Box display="flex" flex="1" justifyContent="center" gap={1}>
          <Button
            variant="contained"
            disableElevation
            onClick={handleSubmit}
            startIcon={<Check />}
            disabled={isLoading}
          >
            confirmar
          </Button>
          <Button
            onClick={onClose}
            variant="outlined"
            startIcon={<Cancel />}
            disabled={isLoading}
          >
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useEffect } from 'react'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Switch,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { VForm, useVForm, VTextField, VSelect } from '../../../../shared/forms'
import {
  IExternalUsers,
  createExternal,
  updateExternalById,
} from '../../../../shared/services/api'

import { formatToCNPJ, formatToCPF } from 'brazilian-values'
import { formatCellPhone } from '../../../../shared/utils'
import { ModalHeader } from '../../../../shared/components/modal/Header'

interface IFormUsuarioExternoProps {
  create: boolean
  data?: IExternalUsers
  onClose: () => void
  show: boolean
  update: React.MutableRefObject<boolean>
}

export const FormUsuarioExterno: FC<IFormUsuarioExternoProps> = ({
  data,
  show,
  create,
  update,
  onClose,
}) => {
  const [formTitle, setFormTitle] = useState('')
  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const [documentType, setDocumentType] = useState(1)
  const [changePassword, setChangePassword] = useState(false)

  const handleSubmit = (formData: any) => {
    setIsLoading(true)

    create
      ? createExternal(formData).then((result) => {
          setIsLoading(false)

          if (result instanceof Error) {
            try {
              const errors = JSON.parse(result.message)
              Object.keys(errors).forEach((key) => {
                const firstError = errors[key][0]
                errors[key] = firstError
              })
              formRef.current?.setErrors(errors)
            } catch (error) {
              NotificacaoError(result.message)
            }
          } else {
            update.current = true
            onClose()
            NotificacaoSucesso('Usuário Criado com Sucesso')
          }
        })
      : updateExternalById(Number(data?.id), formData).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            update.current = true
            onClose()
            NotificacaoSucesso('Usuário Atualizado com Sucesso')
          }
        })
  }

  useEffect(() => {
    if (!create && data !== undefined)
      setFormTitle(`Editar Usuário ${data.name}`)
    else setFormTitle('Novo Usuário')
  }, [create, data, show])

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader onClose={onClose} showCloseButton title={formTitle} />

        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{ height: '5px', borderRadius: '50px', mt: 1 }}
          />
        )}
        <Box margin={1} paddingTop={2}>
          <VForm
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={
              create
                ? { document_type: 1, gender: 1, escolarity: 10 }
                : { ...data, password: undefined }
            }
          >
            <Grid container direction="column" spacing={2}>
              <Grid container item>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="name"
                    label="Nome"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    size="small"
                    name="email"
                    label="Email"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                display="flex"
                direction="row"
                alignItems="center"
              >
                <Grid item xs={6} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id="type-label">Tipo</InputLabel>
                    <VSelect
                      fullWidth
                      required
                      size="small"
                      label="Tipo"
                      labelId="type-label"
                      name="document_type"
                      myChange={(e) => setDocumentType(e)}
                      sx={{
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                      }}
                    >
                      <MenuItem value={1}>CPF</MenuItem>
                      <MenuItem value={2}>CNPJ</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3.5}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="document_number"
                    label="Documento"
                    borderTopLeft="0px"
                    borderBottomLeft="0px"
                    formatData={documentType === 1 ? formatToCPF : formatToCNPJ}
                    inputProps={{
                      maxLength: documentType === 1 ? 14 : 18,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6.5}
                  paddingLeft={!mdDown ? 2 : 0}
                  paddingTop={mdDown ? 2 : 0}
                >
                  <VTextField
                    fullWidth
                    size="small"
                    type="date"
                    name="birth_date"
                    label="Data de Nascimento"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="gender-label">Gênero</InputLabel>
                    <VSelect
                      fullWidth
                      required
                      size="small"
                      name="gender"
                      label="Gênero"
                      labelId="gender-label"
                      useDefaultValue
                      sx={{
                        borderRadius: '50px',
                      }}
                    >
                      <MenuItem value={1}>Masculino</MenuItem>
                      <MenuItem value={2}>Feminino</MenuItem>
                      <MenuItem value={4}>Prefiro Não Responder</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="school-label">Escolaridade</InputLabel>
                    <VSelect
                      fullWidth
                      size="small"
                      name="escolarity"
                      label="Escolaridade"
                      labelId="school-label"
                      sx={{
                        borderRadius: '50px',
                      }}
                    >
                      <MenuItem value={1}>Não Alfabetizado</MenuItem>
                      <MenuItem value={2}>
                        Ensino Fundamental Incompleto
                      </MenuItem>
                      <MenuItem value={3}>Ensino Fundamental Completo</MenuItem>
                      <MenuItem value={4}>Ensino Médio incompleto</MenuItem>
                      <MenuItem value={5}>Ensino Médio Completo</MenuItem>
                      <MenuItem value={6}>Técnico Incompleto</MenuItem>
                      <MenuItem value={7}>Técnico Completo</MenuItem>
                      <MenuItem value={8}>Superior Incompleto</MenuItem>
                      <MenuItem value={9}>Superior Completo</MenuItem>
                      <MenuItem value={10}>Prefiro Não Informar</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="number"
                    label="Celular"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                    placeholder="(DDD) 99999-99999"
                    formatData={formatCellPhone}
                    inputProps={{
                      maxLength: 15,
                    }}
                  />
                </Grid>
              </Grid>
              {!create && (
                <Grid container item spacing={2} direction="column">
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <FormControlLabel
                      control={
                        <Switch
                          value={changePassword}
                          onChange={(_, e) => setChangePassword(e)}
                        />
                      }
                      label="Alterar Senha?"
                    />
                  </Grid>
                  {changePassword && (
                    <Grid container item spacing={2}>
                      <Grid item xs={12}>
                        <VTextField
                          fullWidth
                          required
                          size="small"
                          type="password"
                          name="password"
                          label="Senha"
                          borderBottomLeft="50px"
                          borderTopLeft="50px"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <VTextField
                          fullWidth
                          required
                          size="small"
                          type="password"
                          name="re_password"
                          label="Repita a Senha"
                          borderBottomLeft="50px"
                          borderTopLeft="50px"
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
              {create && (
                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <VTextField
                      fullWidth
                      required
                      size="small"
                      type="password"
                      name="password"
                      label="Senha"
                      borderBottomLeft="50px"
                      borderTopLeft="50px"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <VTextField
                      fullWidth
                      required
                      size="small"
                      type="password"
                      name="re_password"
                      label="Repita a Senha"
                      borderBottomLeft="50px"
                      borderTopLeft="50px"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Box
              display="flex"
              flex={1}
              justifyContent="center"
              marginTop={2}
              gap={1}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{ borderRadius: '50px' }}
                disableElevation
              >
                <Icon>save</Icon>
                Salvar
              </Button>
              <Button onClick={onClose} sx={{ borderRadius: '50px' }}>
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

import { FC } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { IProtocols } from '../../../shared/services/api'
import { FormBoxStyle } from '../../../shared/components'
import { ModalHeader } from '../../../shared/components/modal/Header'
import { Cancel } from '@mui/icons-material'

interface IResultModalProps {
  onClose: () => void
  show: boolean
  result: IProtocols | undefined
  handleClose?: () => void
}

export const ResultModal: FC<IResultModalProps> = ({
  onClose,
  result,
  show,
  handleClose,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  return (
    <Modal
      onClose={() => {
        onClose()
        handleClose?.()
      }}
      open={show}
      aria-describedby="modal-title"
    >
      <Box
        overflow="auto"
        sx={{
          ...FormBoxStyle,
          overflowX: 'hidden',
        }}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader title="Manifestação aberta!" />
        <Divider />
        <Box margin={1}>
          <Typography variant="caption">dados da manifestação:</Typography>
          <Grid container spacing={1} direction="column">
            <Grid container item spacing={1} display="flex" alignItems="center">
              <Grid item>
                <Typography variant="h6">SENHA:</Typography>
              </Grid>
              <Grid item>
                <Typography>{result?.anonymous_password}</Typography>
              </Grid>
            </Grid>

            <Grid container item spacing={1} display="flex" alignItems="center">
              <Grid item>
                <Typography variant="h6">PROTOCOLO:</Typography>
              </Grid>
              <Grid item>
                <Typography>{result?.identifier}</Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>

            <Grid container item spacing={1} display="flex" alignItems="center">
              <Grid item>
                <Typography variant="h6">Agrupamento:</Typography>
              </Grid>
              <Grid item>
                <Typography>{result?.grouping_id?.name}</Typography>
              </Grid>
            </Grid>

            <Grid container item spacing={1} display="flex" alignItems="center">
              <Grid item>
                <Typography variant="h6">Setor:</Typography>
              </Grid>
              <Grid item>
                <Typography>{result?.sector_id?.name}</Typography>
              </Grid>
            </Grid>

            <Grid container item spacing={1} display="flex" alignItems="center">
              <Grid item>
                <Typography variant="h6">Assunto:</Typography>
              </Grid>
              <Grid item>
                <Typography>{result?.subject_id?.name}</Typography>
              </Grid>
            </Grid>

            <Grid container item spacing={1} display="flex" alignItems="center">
              <Grid item>
                <Typography variant="h6">Tipologia:</Typography>
              </Grid>
              <Grid item>
                <Typography>{result?.category_id?.name}</Typography>
              </Grid>
            </Grid>

            <Grid container item spacing={1}>
              <Grid item>
                <Typography variant="h6">Descrição:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flex="1">
                  <Typography paragraph textAlign="justify">
                    {result?.description}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="outlined"
            startIcon={<Cancel />}
            onClick={handleClose}
          >
            fechar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

export interface IIcons {
  id: number
  name: string
  icon: string
  status: boolean
  created_at: string
  updated_at: string
}

export type IIconsTotalCount = {
  data: IIcons[]
  count: number
  next: string
  previous: string
  results: IIcons[]
}

const getAllIcons = async (
  item = '0',
  total = '10',
  filter = '',
  iconId = '',
): Promise<IIconsTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/icons/?total=${total}&item=${item}&search=${filter}&id=${iconId}`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return {
        data,
        results: data.results,
        count: data.count,
        next: data.next,
        previous: data.previous,
      }
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getIconById = async (id: number): Promise<IIcons | Error> => {
  try {
    const relativeUrl = `/V1/icons/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return data
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao listar registro',
    )
  }
}

const createIcon = async (formData: {}): Promise<string | Error> => {
  try {
    const relativeUrl = '/V1/icons/'

    const { data } = await Api.post<IIcons>(relativeUrl, formData)

    if (data) return data.id ? data.id.toString() : data.name

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const updateIconById = async (
  id: number,
  formData: {},
): Promise<any | Error> => {
  try {
    const relativeUrl = `/V1/icons/${id}/`

    const { data } = await Api.patch(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

export { getAllIcons, getIconById, createIcon, updateIconById }

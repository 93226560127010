import { IconButton, Tooltip } from '@mui/material'
import { FC, ReactNode } from 'react'

interface ActionButtonProps {
  icon: JSX.Element | ReactNode
  title: string
  onClick(): void
  disabled?: boolean
}

export const ActionButton: FC<ActionButtonProps> = ({
  title,
  onClick,
  disabled = false,
  icon,
}) => {
  return (
    <Tooltip title={title}>
      <IconButton
        onClick={onClick}
        type="button"
        disabled={disabled}
        sx={{
          p: 1,
          border: '1px solid dodgerblue',
          width: '45px',
          height: '45px',
        }}
      >
        {icon}
      </IconButton>
    </Tooltip>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Button,
  Icon,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  FormControl,
} from '@mui/material'

import { useDebounce } from '../../../shared/hooks'
import { FormUsuarioInterno } from './formulario/Formulário'
import { Environment } from '../../../shared/environment'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import { getAllInternals, ISectorUserList } from '../../../shared/services/api'
import { changePage, FormatDateTime, FormatStatus } from '../../../shared/utils'
import {
  FerramentasDaListagem,
  LegendaSumario,
  NotificacaoError,
} from '../../../shared/components'
import { useAuthContext } from '../../../shared/contexts'
import usePersistedState from '../../../shared/hooks/usePersistedState'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import { ExpandMore, FilterAlt } from '@mui/icons-material'
import { Footer } from '../../../shared/components/tabela/Footer'
import { handleSetSearchParams } from '../../../shared/utils/search-params/setSearchParams'

export const UsuariosInternos: React.FC = () => {
  const { user } = useAuthContext()
  const isAdmin = () => {
    return user?.modules[0].profile === 0
  }

  const navigate = useNavigate()

  const { moduleTitle } = useModuleContext()

  const saved = useRef(false)
  const [institution, setInstitution] = usePersistedState<string>(
    'ADM_SELECTED_INSTITUTION',
    '',
  )

  const toggleSaved = (e: number | undefined) => {
    setInstitution(e ? String(e) : '')
  }

  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState<ISectorUserList[]>([])
  const [totalCount, setTotalCount] = useState(0)

  const [searchParams, setSearchParams] = useSearchParams()

  const { debounce } = useDebounce(1200)

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])

  const [viewForm, setViewForm] = useState(false)

  const handleClose = () => {
    if (viewForm) setViewForm(false)
    else setViewForm(true)
  }

  const online = useMemo(() => {
    return searchParams.get('online') || ''
  }, [searchParams])

  const status = useMemo(() => {
    return searchParams.get('status') || ''
  }, [searchParams])

  useEffect(() => {
    setIsLoading(true)
    if (isAdmin())
      setInstitution(localStorage.getItem('ADM_SELECTED_INSTITUTION') || '')
    else setInstitution('')

    debounce(() => {
      getAllInternals(
        item,
        total,
        busca,
        user?.modules[0].profile === 0 ? institution : '',
        online,
        status,
      ).then((result) => {
        saved.current = false
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setRows(result.results)
          setTotalCount(result.count)
        }
      })
    })
  }, [item, total, busca, institution, online, status, saved.current])

  return (
    <LayoutBaseDePagina
      title="Usuários Internos"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputDaBusca
          textoBotaoNovo="Novo"
          aoClicarEmNovo={handleClose}
          mostrarBotaoNovo={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.hr_manager ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          textoDaBusca={busca}
          aoMudarTextoDaBusca={(texto) =>
            setSearchParams(
              { busca: texto, item: '0', total, online },
              { replace: true },
            )
          }
        />
      }
      selectInstitution
      autoCompleteChange={toggleSaved}
    >
      {FormUsuarioInterno(handleClose, viewForm, saved, true)}
      <Box display="flex" padding={1} flex={1}>
        <Accordion
          sx={{ width: '100%', border: '1px solid dodgerblue' }}
          style={{ borderRadius: '15px' }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario icon="filter_alt" name="Filtros" />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} direction="column">
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="online-label">Online</InputLabel>
                    <Select
                      size="small"
                      label="Online"
                      sx={{
                        borderRadius: '50px',
                      }}
                      labelId="online-label"
                      value={online}
                      onChange={(e) =>
                        setSearchParams(
                          {
                            online: e.target.value,
                            busca,
                            total,
                            item: '0',
                          },
                          { replace: true },
                        )
                      }
                    >
                      <MenuItem value={''}>Todos</MenuItem>
                      <MenuItem value={'true'}>Sim</MenuItem>
                      <MenuItem value={'false'}>Não</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      size="small"
                      label="Status"
                      sx={{
                        borderRadius: '50px',
                      }}
                      labelId="status-label"
                      value={status}
                      onChange={(e) =>
                        setSearchParams(
                          {
                            online,
                            status: e.target.value,
                            busca,
                            total,
                            item: '0',
                          },
                          { replace: true },
                        )
                      }
                    >
                      <MenuItem value={''}>Todos</MenuItem>
                      <MenuItem value={'true'}>Ativo</MenuItem>
                      <MenuItem value={'false'}>Inativo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} display="flex" justifyContent="right">
                  <Button
                    variant="outlined"
                    startIcon={<FilterAlt />}
                    onClick={() =>
                      setSearchParams(
                        {
                          busca: '',
                          item: '0',
                          total,
                          online: '',
                        },
                        { replace: true },
                      )
                    }
                  >
                    limpar filtro
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{
          m: 1,
          width: 'auto',
          color: 'dodgerblue',
          border: '1px solid',
          borderRadius: '15px',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Perfil</TableCell>
              <TableCell align="center">Último Login</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      navigate(
                        `/ombudsman/${moduleTitle}/sectorsusers/detail/${row.id}`,
                      )
                    }
                  >
                    <Icon>info_outlined</Icon>
                  </IconButton>
                </TableCell>
                <TableCell align="center">{row.username}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">
                  {row.ombudsman_profile === 1
                    ? 'Auxiliar'
                    : row.ombudsman_profile === 2
                    ? 'Ouvidor(a)'
                    : 'Auditor(a)'}
                </TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.last_login, 'Ainda Não Logou')}
                </TableCell>
                <TableCell align="center">
                  {FormatStatus(row.is_active)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <Footer
            colSpan={6}
            item={Number(item)}
            loading={isLoading}
            total={total}
            totalCount={totalCount}
            onChangeTotalItems={(e) => {
              handleSetSearchParams(
                searchParams,
                ['item', 'total'],
                ['0', e.target.value],
                setSearchParams,
                debounce,
              )
            }}
            onChangePagination={(_, newPage) =>
              handleSetSearchParams(
                searchParams,
                ['item'],
                [changePage(newPage, Number(total))],
                setSearchParams,
                debounce,
              )
            }
          />
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  )
}

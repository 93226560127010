import { Typography } from '@mui/material'
import { FC } from 'react'
import { ModalHeader, Modal } from '../../../../shared/components'

interface EstatisticasModalProps {
  show: boolean
  onClose: () => void
}

export const EstatisticasModal: FC<EstatisticasModalProps> = ({
  onClose,
  show,
}) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <Modal onClose={handleClose} show={show}>
      <ModalHeader
        title="Dados Estatísticos"
        onClose={handleClose}
        showCloseButton
      />
      <Typography>Gráficos em breve</Typography>
    </Modal>
  )
}

import { useState, useEffect } from 'react'
import { useField } from '@unform/core'
import { Select, SelectProps } from '@mui/material'

type TVSelectProps = SelectProps & {
  name: string
  myChange?: (e: number) => void
  useDefaultValue?: boolean
}

export const VSelect: React.FC<TVSelectProps> = ({
  name,
  myChange,
  useDefaultValue = true,
  ...rest
}) => {
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField(name)

  const [value, setValue] = useState<any>(1)

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [registerField, fieldName, value])

  return (
    <Select
      {...rest}
      placeholder="Selecione como deseja ser respondido"
      error={!!error}
      value={value}
      onChange={(e) => {
        setValue(e.target.value)
        myChange?.(e.target.value)
      }}
      onKeyDown={error ? clearError : undefined}
    />
  )
}

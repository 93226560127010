/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createRef, useEffect, useState } from 'react'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  LinearProgress,
  Icon,
  Divider,
  Link,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextareaAutosize,
} from '@mui/material'
import {
  AttachFile,
  Close,
  Delete,
  Message,
  Send,
  UploadFile,
} from '@mui/icons-material'
import { getAllSacReplies } from '../../../../../shared/services/api/sac-services'
import { IModule, useAuthContext } from '../../../../../shared/contexts'
import {
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../../shared/components'
import { FormatDateTime } from '../../../../../shared/utils'
import { useModuleContext } from '../../../../../shared/contexts/ModulesContext'
import {
  SacReply,
  createSacReply,
} from '../../../../../shared/services/api/sac-services/respostas-services'
import { RespostasPreDefinidasModal } from '../modal/RespostasPreDefinidasModal'

interface IRequests {
  protocolId?: number
  secret?: boolean
  sendDisabled: boolean
  secondUpdate?: any
}

export const RespostasProtocolo: React.FC<IRequests> = ({
  protocolId,
  secondUpdate,
  sendDisabled,
  secret = true,
}) => {
  const chatRef = createRef<HTMLDivElement>()
  const { user } = useAuthContext()
  const { currentModule, moduleTitle } = useModuleContext()

  const [isLoading, setIsLoading] = useState(true)

  const [kindResponse, setKindResponse] = useState<number>(1)
  const [message, setMessage] = useState('')
  const [file, setFile] = useState<any>()
  const [update, setUpdate] = useState(false)
  const [replies, setReplies] = useState<SacReply[]>([])

  const [thisModule, setThisModule] = useState<IModule>()
  const [addPredefinedReply, setAddPredefinedReply] = useState(false)

  useEffect(() => {
    setThisModule(
      user?.modules.find(
        (mdl) => mdl.type === currentModule && mdl.title === moduleTitle,
      ),
    )
  }, [user?.modules, currentModule, moduleTitle])

  useEffect(() => {
    setIsLoading(true)
    if (protocolId !== undefined) {
      getAllSacReplies(protocolId).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          setUpdate(false)
          NotificacaoError('Erro ao carregar respostas')
        } else {
          setUpdate(false)
          secondUpdate.current = false
          setReplies(result)
        }
      })
    }
  }, [protocolId, update, secondUpdate?.current])

  const formData = new FormData()
  const [viewAction, setViewAction] = useState(false)

  const handleSubmit = () => {
    setIsLoading(true)
    formData.append('protocol', String(protocolId))
    formData.append('user', String(user!.id))
    formData.append('reply', message)
    formData.append('reply_type', '1')
    formData.append('customer_service', String(thisModule!.id))
    formData.append('internal', String(kindResponse === 1))
    if (file) {
      formData.append('file', file, file.name)
    }

    createSacReply(protocolId!, formData).then((result) => {
      setIsLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        setUpdate(true)
        setMessage('')
        secondUpdate.current = true
        formData.delete('protocol')
        formData.delete('user')
        formData.delete('reply')
        formData.delete('reply_type')
        formData.delete('customer_service')
        formData.delete('internal')
        if (file) {
          formData.delete('file')
          setFile(undefined)
        }
        NotificacaoSucesso('Mensagem criada com sucesso')
      }
    })
  }

  return (
    <Box
      maxWidth="100%"
      overflow="hidden"
      gap={2}
      display="flex"
      flexDirection="column"
      paddingBottom={1}
    >
      <Box display="flex" flex="1" flexDirection="column" gap={1} marginTop={1}>
        <FormControl fullWidth>
          <InputLabel id="kind_response">Tipo de Resposta</InputLabel>
          <Select
            size="small"
            label="Tipo de Resposta"
            labelId="kind_response"
            defaultValue={0}
            value={kindResponse}
            onChange={(e) => setKindResponse(Number(e.target.value))}
          >
            <MenuItem value={1}>Interna</MenuItem>
            <MenuItem value={2} disabled={thisModule?.profile !== 2}>
              Externa
            </MenuItem>
          </Select>
        </FormControl>
        <Box display="flex" gap={1} flexDirection="column">
          {!!file && (
            <Box
              display="flex"
              flex="1"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Box maxWidth="90%">
                <Typography textOverflow="ellipsis" noWrap>
                  {file.name}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    setFile(undefined)
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          )}
          <Box display="flex" gap={1}>
            <Tooltip title={'Mais opções'}>
              <IconButton
                component="label"
                disabled={sendDisabled}
                onClick={() => setViewAction(true)}
                sx={{ transform: 'rotate(-45deg)' }}
              >
                <AttachFile />
              </IconButton>
            </Tooltip>
            <TextareaAutosize
              minRows={2}
              maxRows={8}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              aria-label="Resposta"
              placeholder="Escreva aqui uma resposta"
              style={{
                width: '90%',
                maxWidth: '90%',
                font: 'message-box',
                padding: 2,
                display: 'flex',
                alignItems: 'center',
                borderRadius: '5px',
                fontVariant: 'historical-forms',
              }}
            />
            <Tooltip title={isLoading ? 'Carregando' : 'Enviar'}>
              <IconButton
                onClick={handleSubmit}
                disabled={sendDisabled || isLoading}
              >
                <Send />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box
        maxHeight="85%"
        overflow="auto"
        ref={chatRef}
        borderRadius="10px"
        border="2px solid black"
        padding={1}
      >
        {protocolId ? (
          <Box display="flex" flexDirection="column" gap={1}>
            {replies.map((reply, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                bgcolor={!reply.internal ? 'grey' : 'white'}
                border="2px solid black"
                borderRadius="10px"
              >
                {reply.file && (
                  <Link
                    sx={{
                      cursor: 'pointer',
                      color: reply.internal ? 'black' : 'white',
                    }}
                    onClick={() => {
                      window.open(reply.file, '_blank', 'noopener')
                    }}
                    variant="h6"
                    underline="hover"
                    paddingX={1}
                    target="_blank"
                    rel="noopener"
                  >
                    Anexo da resposta
                  </Link>
                )}
                <Box display="flex" flexDirection="column">
                  {reply.reply_type === 2 && (
                    <Typography marginLeft={2} variant="h6" color="white">
                      TRANSFERÊNCIA
                    </Typography>
                  )}
                  {reply.reply_type === 3 && (
                    <Typography marginLeft={2} variant="h6" color="white">
                      MUDANÇA DE CATEGORIA
                    </Typography>
                  )}
                  <Typography
                    marginLeft={2}
                    color={reply.internal ? 'black' : 'white'}
                  >
                    -{' '}
                    {reply.user
                      ? reply.user.username
                      : reply.external_user
                      ? secret
                        ? 'Manifestante | Sigilo'
                        : `${reply.external_user.name} | Manifestante`
                      : 'Manifestante'}
                  </Typography>
                  <Box
                    display="flex"
                    gap={1}
                    flexDirection="row"
                    alignItems="center"
                    paddingX={1}
                  >
                    <Tooltip
                      title={
                        reply.internal ? 'Resposta Interna' : 'Resposta Externa'
                      }
                    >
                      <Icon sx={{ color: reply.internal ? 'black' : 'white' }}>
                        {reply.internal ? 'https' : 'insert_comment'}
                      </Icon>
                    </Tooltip>
                    <Divider orientation="vertical" variant="fullWidth" />
                    <Box display="flex" flexDirection="column" gap={1}>
                      {reply.reply.split('\n').map((text) => (
                        <Typography
                          key={index}
                          textAlign="justify"
                          sx={{ color: reply.internal ? 'black' : 'white' }}
                        >
                          {text}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                  <Box display="flex" width="99%" justifyContent="right">
                    <Typography
                      variant="caption"
                      color={reply.internal ? 'black' : 'white'}
                    >
                      {FormatDateTime(reply.created_at, 'Não definido')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <LinearProgress variant="indeterminate" />
        )}
      </Box>
      <Dialog
        open={viewAction}
        onClose={() => setViewAction(false)}
        PaperProps={{ sx: { borderRadius: '20px' } }}
      >
        <DialogTitle>
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Selecione uma ação</Typography>
            <IconButton onClick={() => setViewAction(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItem>
            <ListItemButton
              onClick={() => {
                document.getElementById('upload-file')?.click()
                setViewAction(false)
              }}
            >
              <ListItemIcon>
                <UploadFile />
              </ListItemIcon>
              <ListItemText primary="Anexar arquivo" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                setViewAction(false)
                setAddPredefinedReply(true)
              }}
            >
              <ListItemIcon>
                <Message />
              </ListItemIcon>
              <ListItemText primary="Respostas Pré-definidas" />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
      <input
        hidden
        id="upload-file"
        type="file"
        onChange={(e) => {
          if (e.target.files) {
            setFile(e.target.files[0])
          } else setFile(undefined)
        }}
      />
      {(thisModule?.profile === 2 || thisModule?.profile === 1) && (
        <RespostasPreDefinidasModal
          onClose={() => setAddPredefinedReply(false)}
          setText={(text) => setMessage(text)}
          show={addPredefinedReply}
        />
      )}
    </Box>
  )
}

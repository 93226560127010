import { Box, Typography } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import defaultLogo from '../../../images/default_logo.webp'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { Environment } from '../../../shared/environment'
import {
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'

export const ConfirmarUsuario: FC = () => {
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const token = useMemo(() => {
    return searchParams.get('token')
  }, [searchParams])

  useEffect(() => {
    setIsLoading(true)
    if (token) {
      axios
        .get(`${Environment.URL_BASE}/V1/external_users/confirmation/${token}/`)
        .then(() => NotificacaoSucesso('E-mail confirmado com sucesso'))
        .catch(() => {
          setError(true)
          NotificacaoError('Erro ao confirmar e-mail')
        })
        .finally(() => setIsLoading(false))
    }
  }, [token])

  return (
    <Box
      display="flex"
      flex="1 1"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap={2}
    >
      <img width="300px" src={defaultLogo} alt="TickDoc Logo" />
      <Typography variant="caption">
        {!isLoading && !token
          ? 'Identificando token...'
          : isLoading
          ? 'Confirmando e-mail...'
          : error
          ? 'Erro ao confirmar e-mail'
          : 'E-mail confirmado com sucesso'}
      </Typography>
    </Box>
  )
}

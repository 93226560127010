// Global Imports
import { Navigate, Route } from 'react-router-dom'

// Local Imports
import {
  Home,
  LoginPage,
  ResetSenha,
  AlterarSenha,
  NotFoundPage,
  ExternalLogin,
  ConfirmarUsuario,
  AlterarSenhaExterno,
  ConfirmarEmailTroca,
  RedefinirSenhaExterno,
  SelecionarInstituicao,
  DetalhesProtocoloExterno,
} from '../pages'
import { routes } from './PrivateRoutes'
import { LoginSAC } from '../pages/usuario-externo/sac'
import { Acesso } from '../pages/usuario-externo/sac/acesso/Acesso'
import { DetalhesProtocoloSACEterno } from '../pages/usuario-externo/sac/acesso/components/DetalhesProtocolo'

export const PublicRoutes = [
  <Route path="/admin" element={<LoginPage />} key={1} />,
  <Route path="/pagina_404" element={<NotFoundPage />} key={2} />,
  <Route path="/reset_password" element={<ResetSenha />} key={3} />,
  <Route path="/reset_password/:token" element={<AlterarSenha />} key={4} />,
  <Route path="/users/confirmation" element={<ConfirmarUsuario />} key={5} />,
  <Route path="/ouvidoria/:slug/login" element={<ExternalLogin />} key={6} />,
  <Route path="/ouvidoria/:slug/novoprotocolo" element={<Home />} key={7} />,
  <Route
    path="/ouvidoria/:slug/protocolos/detalhes/:id"
    element={<DetalhesProtocoloExterno />}
    key={8}
  />,
  <Route
    path="/ouvidoria/instituicao"
    element={<SelecionarInstituicao />}
    key={9}
  />,
  <Route
    path="/ouvidoria/:slug"
    element={<Navigate to="login" replace />}
    key={10}
  />,
  <Route
    path="/ouvidoria/:slug/redefinirsenha"
    element={<RedefinirSenhaExterno />}
    key={12}
  />,
  <Route
    path="/ouvidoria/:slug/cidadao/alterar_senha"
    element={<AlterarSenhaExterno />}
    key={13}
  />,

  // SAC Routes
  <Route
    path="/sac/:slug"
    element={<Navigate to="login" replace />}
    key={14}
  />,
  <Route path="/sac/:slug/login" element={<LoginSAC />} key={15} />,
  <Route path="/sac/:slug/inicio" element={<Acesso />} key={16} />,
  <Route
    path="/sac/:slug/inicio/protocolos/detalhes/:id"
    element={<DetalhesProtocoloSACEterno />}
    key={17}
  />,

  <Route path="/confirm_email" element={<ConfirmarEmailTroca />} key={18} />,
  <Route path="/" element={<Navigate to="/admin" replace />} key={19} />,
  <Route
    path="*"
    element={
      routes.filter((route) => route.path === self.location.pathname).length >
      0 ? (
        <LoginPage />
      ) : (
        <Navigate to="/pagina_404" replace />
      )
    }
    key={16}
  />,
]

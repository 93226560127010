/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

export interface IInstitutionsMigrated {
  id: number
  old_id: number
  new_id: number
  related_app: 1 | 2
  created_at: string
  updated_at: string
}

const getAllInstitutionsMigrated = async (): Promise<
  IInstitutionsMigrated[] | Error
> => {
  try {
    const relativeUrl = '/V1/migrations/institution_migration/'

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar instituições')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const createInstitutionMigration = async (formData: {}): Promise<
  IInstitutionsMigrated[] | Error
> => {
  try {
    const relativeUrl = '/V1/migrations/institution_migration/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export { getAllInstitutionsMigrated, createInstitutionMigration }

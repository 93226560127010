import {
  Box,
  Paper,
  useMediaQuery,
  useTheme,
  Button,
  Icon,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material'
import { Fragment, ReactFragment } from 'react'
import { useNavigate } from 'react-router-dom'

interface IFerramentasDeDetalhesProps {
  textoBotaoNovo?: string

  mostrarBotaoNovo?: boolean
  mostrarBotaoVoltar?: boolean
  mostrarBotaoApagar?: boolean
  mostrarBotaoEditar?: boolean
  mostrarBotaoSalvarEFechar?: boolean

  mostrarBotaoNovoCarregando?: boolean
  mostrarBotaoVoltarCarregando?: boolean
  mostrarBotaoApagarCarregando?: boolean
  mostrarBotaoEditarCarregando?: boolean
  mostrarBotaoSalvarEFecharCarregando?: boolean

  aoClicarEmNovo?: () => void
  aoClicarEmVoltar?: () => void
  aoClicarEmApagar?: () => void
  aoClicarEmEditar?: () => void
  aoClicarEmSalvarEFechar?: () => void

  otherButtons?: ReactFragment
}

export const FerramentasDeDetalhes: React.FC<IFerramentasDeDetalhesProps> = ({
  textoBotaoNovo = 'Novo',
  mostrarBotaoNovo = true,
  mostrarBotaoApagar = false,
  mostrarBotaoEditar = true,
  mostrarBotaoVoltar = true,
  mostrarBotaoSalvarEFechar = false,

  aoClicarEmNovo,
  aoClicarEmApagar,
  aoClicarEmEditar,
  aoClicarEmSalvarEFechar,

  mostrarBotaoNovoCarregando = false,
  mostrarBotaoApagarCarregando = false,
  mostrarBotaoEditarCarregando = false,
  mostrarBotaoVoltarCarregando = false,
  mostrarBotaoSalvarEFecharCarregando = false,

  otherButtons,
}) => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smdDown = useMediaQuery(theme.breakpoints.down(745))

  const navigate = useNavigate()

  return (
    <Box
      gap={1}
      marginX={1}
      padding={1}
      paddingX={1}
      display="flex"
      alignItems="center"
      height={theme.spacing(5)}
      component={Paper}
      borderRadius="15px"
      border="1px solid dodgerblue"
    >
      {mostrarBotaoEditar && !mostrarBotaoEditarCarregando && (
        <Button
          id="saveButton"
          sx={{ borderRadius: '50px' }}
          variant="contained"
          color="primary"
          disableElevation
          startIcon={<Icon>edit</Icon>}
          onClick={aoClicarEmEditar}
        >
          <Typography
            variant="button"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            Editar
          </Typography>
        </Button>
      )}
      {mostrarBotaoEditarCarregando && <Skeleton width={110} height={60} />}

      {mostrarBotaoSalvarEFechar &&
        !mostrarBotaoSalvarEFecharCarregando &&
        !smDown &&
        !mdDown && (
          <Button
            variant="outlined"
            sx={{ borderRadius: '50px' }}
            color="primary"
            disableElevation
            startIcon={<Icon>save</Icon>}
            onClick={aoClicarEmSalvarEFechar}
          >
            <Typography
              variant="button"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              Salvar e Fechar
            </Typography>
          </Button>
        )}
      {mostrarBotaoSalvarEFecharCarregando && !smDown && !mdDown && (
        <Skeleton width={180} height={60} />
      )}

      {mostrarBotaoApagar && !mostrarBotaoApagarCarregando && !smdDown && (
        <Button
          variant="outlined"
          sx={{ borderRadius: '50px' }}
          color="primary"
          disableElevation
          startIcon={<Icon>delete</Icon>}
          onClick={aoClicarEmApagar}
        >
          <Typography
            variant="button"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            Apagar
          </Typography>
        </Button>
      )}
      {mostrarBotaoApagarCarregando && !smdDown && (
        <Skeleton width={112} height={60} />
      )}

      {mostrarBotaoNovo && !mostrarBotaoNovoCarregando && (
        <Button
          variant="outlined"
          sx={{ borderRadius: '50px' }}
          color="primary"
          disableElevation
          startIcon={<Icon>add</Icon>}
          onClick={aoClicarEmNovo}
        >
          <Typography
            variant="button"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {textoBotaoNovo}
          </Typography>
        </Button>
      )}
      {mostrarBotaoNovoCarregando && <Skeleton width={95} height={60} />}

      {mostrarBotaoVoltar &&
        (mostrarBotaoApagar ||
          mostrarBotaoNovo ||
          mostrarBotaoEditar ||
          mostrarBotaoSalvarEFechar) && (
          <Divider variant="middle" orientation="vertical" />
        )}

      {mostrarBotaoVoltar && !mostrarBotaoVoltarCarregando && (
        <Button
          variant="outlined"
          sx={{ borderRadius: '50px' }}
          color="primary"
          disableElevation
          startIcon={<Icon>arrow_back</Icon>}
          onClick={() => navigate(-1)}
        >
          <Typography
            variant="button"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            Voltar
          </Typography>
        </Button>
      )}
      {mostrarBotaoVoltarCarregando && <Skeleton width={108} height={60} />}

      {!!otherButtons && <Fragment>{otherButtons}</Fragment>}
    </Box>
  )
}

import { useState } from 'react'
import { Box, Fab, Grid, Step, StepLabel, Stepper } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { LayoutusuarioExterno } from '../../../shared/layouts'
import { useNavigate } from 'react-router-dom'
import { SelecionarEstado } from './components/SelecionarEstado'
import { StepInstituicao } from './components/SelecionarInstituicao'

export const SelecionarInstituicao = () => {
  const navigate = useNavigate()
  const [slug, setSlug] = useState<string>()
  const [activeStep, setActiveStep] = useState(0)
  const [selectedState, setSelectedState] = useState<number>()
  const steps = ['Selecione Um Estado', 'Selecione uma Instituição']
  const [selectedInstitution, setSelectedInstitution] = useState<number>()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <LayoutusuarioExterno title="Selecione a Instituição">
      <Box width="100%" height="100%">
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: {
              optional?: React.ReactNode
            } = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>
                  {activeStep === index ? label : ''}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Grid
          container
          direction="column"
          spacing={2}
          display="flex"
          justifyContent="center"
          paddingX={3}
          paddingTop={3}
        >
          {activeStep === 0 ? (
            <SelecionarEstado
              selectedState={selectedState}
              setSelectedState={setSelectedState}
            />
          ) : (
            <StepInstituicao
              slug={slug}
              setSlug={setSlug}
              selectedState={selectedState}
              selectedInstitution={selectedInstitution}
              setSelectedInstitution={setSelectedInstitution}
            />
          )}
        </Grid>
        <Fab
          draggable={true}
          color="primary"
          aria-label="add"
          size="large"
          style={{
            position: 'absolute',
            right: '5%',
            top: '85%',
            zIndex: 1,
          }}
          disabled={
            activeStep === steps.length ||
            (!selectedState && activeStep === 0) ||
            (!slug && activeStep === 1)
          }
          onClick={() => {
            activeStep === 0
              ? handleNext()
              : navigate(`/ouvidoria/${slug}/login`)
          }}
        >
          {activeStep === 1 ? <CheckIcon /> : <ArrowRight />}
        </Fab>
        {activeStep > 0 && activeStep < steps.length && (
          <Fab
            draggable={true}
            color="primary"
            aria-label="add"
            size="large"
            style={{
              position: 'absolute',
              left: '5%',
              top: '85%',
              zIndex: 1,
            }}
            disabled={activeStep === steps.length}
            onClick={handleBack}
          >
            <ArrowLeft />
          </Fab>
        )}
      </Box>
    </LayoutusuarioExterno>
  )
}

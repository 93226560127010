const nivoColors = [
  {
    color: '#E8C1A0',
    border: '#D8B495',
  },
  {
    color: '#F47560',
    border: '#E36D59',
  },
  {
    color: '#F1E15B',
    border: '#E0D255',
  },
  {
    color: '#E8A838',
    border: '#D89C34',
  },
  {
    color: '#61CDBB',
    border: '#5ABFAE',
  },
  {
    color: '#97E3D5',
    border: '#8DD3C6',
  },
]

const pastel1Colors = [
  {
    color: '#FBB4AE',
    border: '#EAA8A2',
  },
  {
    color: '#B3CDE3',
    border: '#A7BFD3',
  },
  {
    color: '#CCEBC5',
    border: '#BEDBB7',
  },
]

const pairedColors = [
  {
    color: '#A6CEE3',
    border: '#9BC0D3',
  },
  {
    color: '#1F78B4',
    border: '#1D70A8',
  },
  {
    color: '#B2DF8A',
    border: '#A6D080',
  },
  {
    color: '#33A02C',
    border: '#2F9529',
  },
  {
    color: '#FB9A99',
    border: '#EA8F8E',
  },
  {
    color: '#E31A1C',
    border: '#D3181A',
  },
  {
    color: '#FDBF6F',
    border: '#ECB267',
  },
  {
    color: '#FF7F00',
    border: '#ED7600',
  },
  {
    color: '#CAB2D6',
    border: '#BCA6C7',
  },
  {
    color: '#6A3D9A',
    border: '#63398F',
  },
]

export { nivoColors, pairedColors, pastel1Colors }

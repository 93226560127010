/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { FormBoxStyle, NotificacaoError } from '../../../shared/components'
import {
  IClientDetail,
  IGraphic,
  getEscolarity,
  getGender,
  getSituation,
} from '../../../shared/services/api'
import { PieSituacaoManifestacoes } from '../../reports/PieSituacaoManifestacoes'
import { Download, ExpandMore } from '@mui/icons-material'
import {
  nivoColors,
  pairedColors,
  pastel1Colors,
} from '../../reports/GraphicColors'
import { PieGenero } from '../../reports/PieGenero'
import { PieEscolaridade } from '../../reports/PieEscolaridade'
import { DownloadModal } from '../components/DownloadModal'

interface IDadosEstatisticosProps {
  show: boolean
  onClose: () => void
  institutionData: IClientDetail
}

export const DadosEstatisticos: FC<IDadosEstatisticosProps> = ({
  show,
  onClose,
  institutionData,
}) => {
  const [isLoading, setIsLoading] = useState(true)

  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const [openLegend, setOpenLegend] = useState(false)
  const [openGender, setOpenGender] = useState(false)
  const [openEscolarity, setOpenEscolarity] = useState(false)

  const [situationData, setSituationData] = useState<IGraphic[]>([])

  const [genderData, setGenderData] = useState<any>([])
  const [genderLoading, setGenderLoading] = useState(true)

  const [escolarityData, setEscolarityData] = useState<IGraphic[]>([])
  const [escolarityLoading, setEscolarityLoading] = useState(true)

  const currentYear = new Date().getFullYear()
  const [formYear, setYear] = useState(currentYear)

  const [downloadModal, setDownloadModal] = useState(false)
  const [graphic, setGraphic] = useState(1)

  const years = []

  for (let index = 2016; index <= currentYear; index++) {
    years.push(index)
  }

  const possibleYears = years

  useEffect(() => {
    setIsLoading(true)
    getSituation(Number(institutionData.id), formYear).then((result) => {
      setIsLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        setSituationData(result)
      }
    })
  }, [institutionData, formYear])

  useEffect(() => {
    setGenderLoading(true)
    getGender(Number(institutionData.id), formYear).then((result) => {
      setGenderLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        setGenderData(result)
      }
    })
  }, [institutionData, formYear])

  useEffect(() => {
    setEscolarityLoading(true)
    getEscolarity(Number(institutionData.id), formYear).then((result) => {
      setEscolarityLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        setEscolarityData(result)
      }
    })
  }, [institutionData, formYear])

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={{ ...FormBoxStyle, overflowX: 'hidden' }}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        overflow="auto"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Typography variant="h6" textAlign="center">
          Dados Estatísticos
        </Typography>
        <Box
          display="flex"
          flex="1"
          gap={1}
          marginBottom={2}
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
        >
          {possibleYears.map((year, index) => (
            <Tooltip
              key={index}
              title={
                isLoading || escolarityLoading || genderLoading
                  ? 'Carregando'
                  : `Filtrar pelo ano ${year}`
              }
            >
              <Button
                disabled={isLoading || escolarityLoading || genderLoading}
                variant={formYear === year ? 'contained' : 'outlined'}
                onClick={() => setYear(year)}
                sx={{
                  borderRadius: '50%',
                  height: '65px',
                  width: '65px',
                  fontSize: '15px',
                }}
              >
                {year}
              </Button>
            </Tooltip>
          ))}
        </Box>
        <Divider />
        <Box margin={1}>
          {isLoading && (
            <LinearProgress
              variant="indeterminate"
              sx={{ borderRadius: '50px', height: '5px' }}
            />
          )}
          <Box
            display="flex"
            flex="1 1"
            height={mdDown ? (openLegend ? '440px' : '280px') : '350px'}
            sx={{
              transition: 'ease-in-out 0.5s',
            }}
            flexDirection="column"
            component={Paper}
            elevation={5}
          >
            <Typography variant="h6" textAlign="center" marginTop="8px">
              Situação das Manifestações
            </Typography>
            <Tooltip title="download">
              <IconButton
                onClick={() => {
                  setGraphic(1)
                  setDownloadModal(true)
                }}
                disabled={isLoading || genderLoading || escolarityLoading}
                sx={{
                  position: 'fixed',
                  right: xsDown
                    ? '14%'
                    : smDown
                    ? '11.5%'
                    : mdDown
                    ? '10%'
                    : lgDown
                    ? '7%'
                    : '5.5%',
                  color: 'dodgerblue',
                  border: '1px solid black',
                  marginTop: smDown ? '45px' : '8px',
                }}
              >
                <Download />
              </IconButton>
            </Tooltip>
            {mdDown && (
              <Box display="flex" flex="1" flexDirection="column">
                <Box
                  display="flex"
                  flex="1"
                  justifyContent="center"
                  flexWrap="wrap"
                >
                  <Accordion
                    elevation={0}
                    style={{
                      borderRadius: '30px',
                      margin: smDown ? undefined : '10px',
                    }}
                    expanded={openLegend}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      onClick={() => setOpenLegend(!openLegend)}
                    >
                      <Typography>Descrição do Gráfico</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        display={smDown ? undefined : 'flex'}
                        flexWrap={smDown ? undefined : 'wrap'}
                        justifyContent={smDown ? undefined : 'center'}
                        alignItems={smDown ? undefined : 'center'}
                        gap={1}
                      >
                        {situationData.map(
                          (
                            data: { id: string; label: string; value: number },
                            index: number,
                          ) => (
                            <Box
                              display="flex"
                              alignItems="center"
                              gap={1}
                              key={index}
                            >
                              <Box
                                width="15px"
                                height="15px"
                                borderRadius="50%"
                                bgcolor={nivoColors[index].color}
                                border={`2px solid ${nivoColors[index].border}`}
                              />
                              <Typography>
                                {data.label} ({data.value})
                              </Typography>
                            </Box>
                          ),
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            )}

            <PieSituacaoManifestacoes data={situationData} open={openLegend} />
          </Box>
        </Box>

        <Divider variant="middle" />

        <Box margin={1}>
          <Box
            display="flex"
            flex="1 1"
            height={mdDown ? (openGender ? '350px' : '260px') : '350px'}
            flexDirection="column"
            component={Paper}
            elevation={5}
            sx={{
              transition: 'ease-in-out 0.5s',
            }}
          >
            <Typography variant="h6" textAlign="center">
              Gênero
            </Typography>
            <Tooltip title="download">
              <IconButton
                onClick={() => {
                  setGraphic(2)
                  setDownloadModal(true)
                }}
                disabled={isLoading || genderLoading || escolarityLoading}
                sx={{
                  position: 'fixed',
                  right: xsDown
                    ? '14%'
                    : smDown
                    ? '11.5%'
                    : mdDown
                    ? '10%'
                    : lgDown
                    ? '7%'
                    : '5.5%',
                  color: 'dodgerblue',
                  border: '1px solid black',
                  marginTop: smDown ? '45px' : '8px',
                }}
              >
                <Download />
              </IconButton>
            </Tooltip>
            {mdDown && (
              <Box display="flex" flex="1" flexDirection="column">
                <Box
                  display="flex"
                  flex="1"
                  justifyContent="center"
                  flexWrap="wrap"
                >
                  <Accordion
                    elevation={0}
                    expanded={openGender}
                    style={{
                      borderRadius: '30px',
                      margin: smDown ? undefined : '10px',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      onClick={() => setOpenGender(!openGender)}
                    >
                      <Typography>Descrição do Gráfico</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        display={smDown ? undefined : 'flex'}
                        flexWrap={smDown ? undefined : 'wrap'}
                        justifyContent={smDown ? undefined : 'center'}
                        alignItems={smDown ? undefined : 'center'}
                        gap={1}
                      >
                        {genderData.map(
                          (
                            data: { id: string; label: string; value: number },
                            index: number,
                          ) => (
                            <Box
                              display="flex"
                              alignItems="center"
                              gap={1}
                              key={index}
                            >
                              <Box
                                width="15px"
                                height="15px"
                                borderRadius="50%"
                                bgcolor={pastel1Colors[index].color}
                                border={`2px solid ${pastel1Colors[index].border}`}
                              />
                              <Typography>
                                {data.label} ({data.value})
                              </Typography>
                            </Box>
                          ),
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            )}
            <PieGenero data={genderData} open={openGender} />
          </Box>
        </Box>

        <Divider />

        <Box margin={1}>
          <Box
            display="flex"
            flex="1 1"
            height={
              mdDown
                ? openEscolarity
                  ? '750px'
                  : '260px'
                : !mdDown
                ? openEscolarity
                  ? '465px'
                  : '350px'
                : '350px'
            }
            flexDirection={'column'}
            component={Paper}
            elevation={5}
            sx={{
              transition: 'ease-in-out 0.5s',
            }}
          >
            <Typography variant="h6" textAlign="center">
              Escolaridade
            </Typography>
            <Tooltip title="download">
              <IconButton
                onClick={() => {
                  setGraphic(3)
                  setDownloadModal(true)
                }}
                disabled={isLoading || genderLoading || escolarityLoading}
                sx={{
                  position: 'fixed',
                  right: xsDown
                    ? '14%'
                    : smDown
                    ? '11.5%'
                    : mdDown
                    ? '10%'
                    : lgDown
                    ? '7%'
                    : '5.5%',
                  color: 'dodgerblue',
                  border: '1px solid black',
                  marginTop: smDown ? '45px' : '8px',
                }}
              >
                <Download />
              </IconButton>
            </Tooltip>
            <Box
              display="flex"
              flex="1"
              height="100%"
              flexDirection={mdDown ? 'column' : 'row'}
            >
              <Box
                display="flex"
                flex="1"
                justifyContent={mdDown ? 'center' : undefined}
                flexWrap="wrap"
              >
                <Accordion
                  elevation={0}
                  expanded={openEscolarity}
                  style={{
                    borderRadius: '30px',
                    margin: '10px',
                    height: mdDown
                      ? undefined
                      : openEscolarity
                      ? '85%'
                      : '50px',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    onClick={() => setOpenEscolarity(!openEscolarity)}
                  >
                    <Typography>Descrição do Gráfico</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      display={smDown ? undefined : 'flex'}
                      flexWrap={smDown ? undefined : 'wrap'}
                      justifyContent={smDown ? undefined : 'center'}
                      alignItems={smDown ? undefined : 'center'}
                      gap={1}
                    >
                      {escolarityData.map(
                        (
                          data: { id: string; label: string; value: number },
                          index: number,
                        ) => (
                          <Box
                            display="flex"
                            alignItems="center"
                            gap={1}
                            key={index}
                          >
                            <Box
                              width="15px"
                              height="15px"
                              borderRadius="50%"
                              bgcolor={pairedColors[index].color}
                              border={`2px solid ${pairedColors[index].border}`}
                            />
                            <Typography>
                              {data.label} ({data.value})
                            </Typography>
                          </Box>
                        ),
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {mdDown ? (
                <PieEscolaridade data={escolarityData} open={openEscolarity} />
              ) : (
                <Box display="flex" height="100%" width="50%">
                  <PieEscolaridade
                    data={escolarityData}
                    open={openEscolarity}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <DownloadModal
          onClose={() => setDownloadModal(false)}
          show={downloadModal}
          graphic={graphic}
          institutionId={Number(institutionData.id)}
          year={formYear}
        />
      </Box>
    </Modal>
  )
}

import { FC, useEffect, useState } from 'react'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'

import {
  FerramentasDaListagem,
  NotificacaoAlerta,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import usePersistedState from '../../../shared/hooks/usePersistedState'
import { useSearchParams } from 'react-router-dom'
import { useDebounce } from '../../../shared/hooks'
import { handleSetSearchParams } from '../../../shared/utils/search-params/setSearchParams'
import { FormatDateTime, FormatStatus, changePage } from '../../../shared/utils'
import { Footer } from '../../../shared/components/tabela/Footer'
import {
  SacPergunta,
  getAllSacQuestions,
} from '../../../shared/services/api/sac-services/perguntas-services'
import { Environment } from '../../../shared/environment'
import { Formulario } from './formulario/Formulaio'
import { InfoOutlined } from '@mui/icons-material'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const PerguntaSAC: FC = () => {
  const { debounce } = useDebounce(1000)
  const { thisModule } = useModuleContext()

  const [rows, setRows] = useState<SacPergunta[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  const [searchParams, setSearchParams] = useSearchParams()
  const [institution, setInstitution] = usePersistedState<string>(
    'ADM_SELECTED_INSTITUTION',
    '',
  )
  const [update, setUpdate] = useState(true)
  const [busca, setBusca] = useState(searchParams.get('busca') || '')
  const [item, setItem] = useState(searchParams.get('item') || '0')
  const [total, setTotal] = useState(searchParams.get('total') || '10')

  const [viewForm, setViewForm] = useState(false)
  const [data, setData] = useState<SacPergunta>()
  const [create, setCreate] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    getAllSacQuestions(
      busca,
      item,
      total,
      thisModule?.profile !== 0 && thisModule ? String(thisModule?.id) : '',
      thisModule?.profile === 0 ? institution : '',
    ).then((result) => {
      setIsLoading(false)
      setUpdate(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        setRows(result.results)
        setTotalCount(result.count)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, institution, update, thisModule?.profile, thisModule?.id])

  useEffect(() => {
    setBusca(searchParams.get('busca') || '')
    setItem(searchParams.get('item') || '0')
    setTotal(searchParams.get('total') || '10')
  }, [searchParams])

  return (
    <LayoutBaseDePagina
      title="Perguntas"
      selectInstitution
      autoCompleteChange={(e) => setInstitution(e ? String(e) : '')}
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarBotaoNovo
          mostrarInputDaBusca
          textoDaBusca={busca}
          aoClicarEmNovo={() => {
            setCreate(true)
            setData(undefined)
            setViewForm(true)
          }}
          aoMudarTextoDaBusca={(newValue) => {
            setBusca(newValue)
            setItem('0')
            handleSetSearchParams(
              searchParams,
              ['busca', 'item'],
              [newValue, '0'],
              setSearchParams,
              debounce,
            )
          }}
        />
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Introdução</TableCell>
              <TableCell align="center">Pergunta</TableCell>
              <TableCell align="center">Criada em</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (institution || thisModule?.profile !== 0) {
                        setData(row)
                        setCreate(false)
                        setViewForm(true)
                      } else {
                        NotificacaoAlerta('Selecione uma instituição')
                      }
                    }}
                  >
                    <InfoOutlined />
                  </IconButton>
                </TableCell>
                <TableCell align="center">{row.introduction}</TableCell>
                <TableCell align="center">{row.question}</TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.created_at)}
                </TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <Footer
            total={total}
            totalCount={totalCount}
            colSpan={5}
            item={Number(item)}
            loading={isLoading}
            onChangePagination={(_, newPage) => {
              setItem(changePage(newPage, Number(total)))
              handleSetSearchParams(
                searchParams,
                ['item'],
                [changePage(newPage, Number(total))],
                setSearchParams,
                debounce,
              )
            }}
            onChangeTotalItems={(e) => {
              setTotal(e.target.value)
              handleSetSearchParams(
                searchParams,
                ['total'],
                [e.target.value],
                setSearchParams,
                debounce,
              )
            }}
          />
        </Table>
      </TableContainer>
      <Formulario
        create={create}
        onClose={() => setViewForm(false)}
        setUpdate={() => setUpdate(true)}
        institution={Number(institution)}
        show={viewForm}
        data={create ? undefined : data}
      />
    </LayoutBaseDePagina>
  )
}

import { Box, Button, Modal, Paper, Typography } from '@mui/material'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'
import { Api } from '../../../shared/services/api/axios-config'
import { useParams } from 'react-router-dom'
import { MutableRefObject } from 'react'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const AnaliseModal = (
  show: boolean,
  onClose: () => void,
  update: MutableRefObject<boolean>,
) => {
  const { id } = useParams<'id'>()
  const { thisModule } = useModuleContext()

  const handleSubmit = () => {
    Api.get(`/V1/protocols/${id}/start_analysis/`)
      .then(() => {
        NotificacaoSucesso('Análise Iniciada')
        update.current = true
        onClose()
      })
      .catch((error) => {
        console.error(error)
        NotificacaoError('Erro ao Alterar Parâmetro')
      })
  }

  return (
    <Modal
      onClose={() => onClose()}
      open={show && thisModule?.profile !== 3}
      aria-describedby="modal-title"
    >
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        width="280px"
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Box display="flex" flex="1" justifyContent="center">
          <Typography variant="h6" textAlign="center">
            Deseja Iniciar a Análise?
          </Typography>
        </Box>
        <Box
          display="flex"
          flex="1"
          justifyContent="end"
          alignItems="end"
          gap={1}
        >
          <Button
            onClick={handleSubmit}
            size="small"
            disableElevation
            variant="contained"
            sx={{
              borderRadius: '50px',
            }}
          >
            iniciar
          </Button>
          <Button
            size="small"
            disableElevation
            onClick={onClose}
            sx={{
              borderRadius: '50px',
            }}
          >
            fechar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

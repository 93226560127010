import { FC, MutableRefObject, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
  useTheme,
} from '@mui/material'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'
import { Api } from '../../../shared/services/api/axios-config'

interface IStatusModalProps {
  show: boolean
  onClose: () => void
  update: MutableRefObject<boolean>
  secondClose: () => void
  id?: number
}

export const StatusModal: FC<IStatusModalProps> = ({
  show,
  id,
  update,
  onClose,
  secondClose,
}) => {
  const theme = useTheme()

  const [status, setStatus] = useState(5)

  const handleFinish = () => {
    Api.post(`/V1/protocols/${id}/finish/`, {
      protocol_id: id,
      status,
    })
      .then(() => {
        NotificacaoSucesso('Protocolo finalizado com sucesso')
        update.current = true
        onClose()
        secondClose()
      })
      .catch((error) => NotificacaoError(error.message))
  }

  return (
    <Modal
      open={show}
      onClose={() => {
        onClose()
        secondClose()
      }}
    >
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        flexDirection="column"
        width={theme.spacing(40)}
      >
        <Box
          display="flex"
          gap={2}
          flex="1"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" textAlign="center">
            Finalizar com status:
          </Typography>
          <FormControl fullWidth>
            <Select
              fullWidth
              size="small"
              sx={{ borderRadius: '50px' }}
              value={status}
              defaultValue={5}
              onChange={(e) => setStatus(Number(e.target.value))}
            >
              <MenuItem value={5}>Concluído</MenuItem>
              <MenuItem value={6}>Indeferido</MenuItem>
            </Select>
          </FormControl>
          <Box
            display="flex"
            flex="1"
            alignItems="center"
            justifyContent="center"
            gap={1}
            paddingTop={2}
          >
            <Button
              onClick={handleFinish}
              variant="contained"
              disableElevation
              sx={{ borderRadius: '50px' }}
            >
              Confirmar
            </Button>
            <Button
              sx={{ borderRadius: '50px' }}
              onClick={() => {
                onClose()
                secondClose()
              }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react'
import {
  Box,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableContainer,
  Divider,
} from '@mui/material'
import { FormatReportDate } from '../../../../shared/utils'
import { BarChart } from '../../../reports/BarChart'

interface GenericTableProps {
  columns: string[]
  groupings: any[]
  dates: string[]
  total: any
}

export const GenericTable: FC<GenericTableProps> = ({
  groupings,
  columns,
  dates,
  total,
}) => {
  /**
   * 
    [
      column_1: {
        data_1: 123,
        data_2: 123,
        data_3: 123
      },
      column_2: {
        data_1: 123,
        data_2: 123,
        data_3: 123
      },
      column_3: {
        data_1: 123,
        data_2: 123,
        data_3: 123
      }
    ]
   * 
  **/
  const [graphicData, setGraphicData] = useState<any[]>([])
  useEffect(() => {
    const gData: { [key: string]: string | number }[] = []
    groupings.map((gpr, gIndex) => {
      const currDate = String(FormatReportDate(dates[gIndex])).split(' à ')[0]
      const currData: any = {}
      gpr.map((data: any, lIndex: number) => {
        if (data[columns[lIndex]]) {
          currData[columns[lIndex]] = data[columns[lIndex]]
        }
      })
      gData.push({
        period: currDate,
        ...currData,
      })
    })
    setGraphicData(gData)
  }, [groupings, columns, dates, total])

  return (
    <TableContainer
      id="table-container"
      component={Paper}
      variant="outlined"
      sx={{
        m: 1,
        width: '100%',
        color: 'dodgerblue',
        border: '1px solid',
        borderRadius: '15px',
      }}
    >
      <Box
        width={window.screen.width}
        display="flex"
        maxWidth="98%"
        alignItems="center"
        id="barchart-graphic"
        justifyContent="center"
        height="500px"
      >
        <BarChart
          mt={0}
          mb={40}
          ml={100}
          mr={200}
          ltx={185}
          lAnchor="right"
          legend="Gráfico"
          indexBy="period"
          lItemWidth={150}
          data={graphicData}
          layout="horizontal"
          lDirection="column"
          keys={columns.filter((col) => col !== 'Total no Período')}
        />
      </Box>
      <Divider />
      <Table id="table" sx={{ mt: 2 }}>
        <TableHead id="table-head">
          <TableRow id="table-row">
            <TableCell align="center" width="90px" id="table-cell-90px">
              Período
            </TableCell>
            {columns.map((column, index) => (
              <TableCell key={index} align="center" id="table-cell-center">
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody id="table-body">
          {groupings.map((group, index) => (
            <TableRow key={index}>
              <TableCell align="center">
                {FormatReportDate(dates[index])}
              </TableCell>
              {group.map((data: any, index: number) => (
                <TableCell key={index} align="center">
                  {data[columns[index]]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter id="table-footer">
          <TableRow>
            <TableCell id="table-cell">Total</TableCell>
            {columns.map((column, index) => (
              <TableCell align="center" key={index}>
                {total[column]}
              </TableCell>
            ))}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

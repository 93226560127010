import { Schema } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'

export interface IFormData {
  id?: number
  name: string
  state_id: number
}

const formValidationSchema: Schema<IFormData> = yup.object().shape({
  id: yup.number(),
  name: yup.string().required().min(3),
  state_id: yup.number().required().min(1),
})

export default formValidationSchema

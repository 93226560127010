/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import { useNavigate, useParams } from 'react-router-dom'
import {
  UserSacProfile,
  getSacProfileById,
} from '../../../shared/services/api/sac-services'
import {
  FerramentasDeDetalhes,
  NotificacaoError,
} from '../../../shared/components'
import { Formulario } from './formulario/Formulario'

export const DetalhesPerfil: React.FC = () => {
  const update = React.useRef(true)
  const navigate = useNavigate()

  const { id } = useParams<'id'>()

  const [create, setCreate] = React.useState(true)
  const [viewForm, setViewForm] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [data, setData] = React.useState<UserSacProfile>()

  React.useEffect(() => {
    if (id) {
      setIsLoading(true)
      getSacProfileById(Number(id)).then((result) => {
        update.current = false
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
          navigate(-1)
        } else {
          setData(result)
        }
      })
    }
  }, [id, update.current])

  return (
    <LayoutBaseDePagina
      title={isLoading ? 'carregando...' : data?.username}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          mostrarBotaoNovo
          mostrarBotaoVoltar
          mostrarBotaoEditar
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
        />
      }
    >
      {data && (
        <Formulario
          show={viewForm}
          create={create}
          update={update}
          data={create ? undefined : data}
          institution={data.institution_id}
          onClose={() => setViewForm(false)}
        />
      )}
    </LayoutBaseDePagina>
  )
}

import { Grid, Typography, TypographyProps, GridProps } from '@mui/material'
import { FC, ReactNode } from 'react'

interface IShowDataProps {
  field: string
  useTypography?: boolean
  showSeparator?: boolean
  customSeparator?: string
  value?: string | ReactNode
  undefinedValue?: string
  containerProps?: GridProps
  fieldGridProps?: GridProps
  valueGridProps?: GridProps
  fieldProps?: TypographyProps
  valueProps?: TypographyProps
}

export const ShowData: FC<IShowDataProps> = ({
  field,
  value,
  fieldProps,
  valueProps,
  containerProps,
  fieldGridProps,
  valueGridProps,
  showSeparator = true,
  useTypography = true,
  customSeparator = ':',
  undefinedValue = 'Não definido',
}) => {
  return (
    <Grid container item spacing={1} {...containerProps}>
      <Grid item {...fieldGridProps}>
        <Typography {...fieldProps}>
          {showSeparator ? `${field}${customSeparator}` : field}
        </Typography>
      </Grid>
      <Grid item {...valueGridProps}>
        {useTypography ? (
          <Typography {...valueProps}>{value || undefinedValue}</Typography>
        ) : (
          value
        )}
      </Grid>
    </Grid>
  )
}

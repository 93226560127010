import { useState, useMemo, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  Icon,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDaListagem,
  NotificacaoError,
} from '../../../shared/components'
import { useDebounce } from '../../../shared/hooks'
import { ILegislation, getAllLegislations } from '../../../shared/services/api'
import { FormatStatus } from '../../../shared/utils'
import { Formulario } from './Formulario'
import { useAuthContext } from '../../../shared/contexts'
import { Environment } from '../../../shared/environment'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const Legislacao: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [institution, setInstitution] = useState('')
  const [viewForm, setViewForm] = useState(false)
  const [rows, setRows] = useState<ILegislation[]>([])
  const [create, setCreate] = useState(true)
  const [edit, setEdit] = useState<ILegislation>()

  const [searchParams, setSearchParams] = useSearchParams()

  const { moduleTitle } = useModuleContext()

  const { debounce } = useDebounce(1200)
  const { user } = useAuthContext()
  const isAdmin = () => {
    return user?.modules[0].profile === 0
  }

  const update = useRef(false)

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const toggleSaved = (e: number | undefined) => {
    setInstitution(e ? String(e) : '')
  }

  const handleForm = (create: boolean) => {
    setCreate(create)
    if (viewForm) setViewForm(false)
    else setViewForm(true)
  }

  useEffect(() => {
    setIsLoading(true)
    if (isAdmin())
      setInstitution(localStorage.getItem('ADM_SELECTED_INSTITUTION') || '')
    else setInstitution('')

    debounce(() => {
      getAllLegislations(institution, busca).then((result) => {
        update.current = false
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setRows(result)
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busca, update.current, institution])

  return (
    <LayoutBaseDePagina
      selectInstitution
      title="Legislações"
      autoCompleteChange={toggleSaved}
      filter={
        <FerramentasDaListagem
          mostrarInputDaBusca
          textoBotaoNovo="Nova"
          aoClicarEmNovo={() => handleForm(true)}
          mostrarBotaoNovo={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.hr_manager ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          textoDaBusca={busca}
          aoMudarTextoDaBusca={(texto) =>
            setSearchParams({ busca: texto }, { replace: true })
          }
        />
      }
    >
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{
          m: 1,
          width: 'auto',
          color: 'dodgerblue',
          border: '1px solid',
          borderRadius: '15px',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell align="center">Link</TableCell>
              <TableCell align="center" width={100}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setEdit(row)
                      handleForm(false)
                    }}
                  >
                    <Icon>info_outlined</Icon>
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  {row.type === 1 ? 'Estadual' : 'Municipal'}
                </TableCell>
                <TableCell align="center">{row.link}</TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {rows.length === 0 && <caption>{Environment.LISTAGEM_VAZIA}</caption>}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={5}>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{ height: '5px', borderRadius: '50px' }}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      {edit && (
        <Formulario
          onClose={() => setViewForm(false)}
          show={viewForm}
          create={create}
          data={edit}
          update={update}
        />
      )}
    </LayoutBaseDePagina>
  )
}

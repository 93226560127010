/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

export interface ISlide {
  id?: number
  name: string
  link?: string
  image: string
  status: boolean
  created_at?: string
  updated_at?: string
  text_color: string
  button_color: string
  institution_id: number
  text_color_hover: string
  button_color_hover: string
}

const getAllSlides = async (
  institutionId = '',
  filter = '',
): Promise<ISlide[] | Error> => {
  try {
    const relativeUrl = `/V1/slides/?search=${filter}&institution_id=${institutionId}`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getSlideById = async (id: number): Promise<ISlide | Error> => {
  try {
    const relativeUrl = `/V1/slides/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return data
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao listar registro',
    )
  }
}

const createSlide = async (formData: {}): Promise<string | Error> => {
  try {
    const relativeUrl = '/V1/slides/'

    const { data } = await Api.post<ISlide>(relativeUrl, formData)

    if (data) return data.id ? data.id.toString() : data.name

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const updateSlideById = async (
  id: number,
  formData: {},
): Promise<ISlide | Error> => {
  try {
    const relativeUrl = `/V1/slides/${id}/`

    const { data } = await Api.patch<ISlide>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

export { getAllSlides, getSlideById, createSlide, updateSlideById }

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { ResponsiveBar } from '@nivo/bar'
import { useAppThemeContext } from '../../shared/contexts'

interface BarChartProps {
  data: any
  mr?: number
  keys: string[]
  legend: string
  indexBy: string
  layout?: 'horizontal' | 'vertical'
  lDirection?: 'column' | 'row'
  ml?: number
  lItemWidth?: number
  ltx?: number
  mb?: number
  mt?: number
  lAnchor?:
    | 'top-left'
    | 'left'
    | 'bottom-left'
    | 'top'
    | 'center'
    | 'bottom'
    | 'top-right'
    | 'right'
    | 'bottom-right'
}

export const BarChart: FC<BarChartProps> = ({
  data,
  keys,
  indexBy,
  legend,
  mr = 100,
  mb = 60,
  lItemWidth = 100,
  ml = 100,
  layout = 'vertical',
  lDirection = 'column',
  lAnchor = 'bottom-right',
  ltx = 120,
}) => {
  const { themeName } = useAppThemeContext()

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      layout={layout}
      indexBy={indexBy}
      valueScale={{ type: 'linear' }}
      colors={{ scheme: 'red_yellow_green' }}
      margin={{ bottom: mb, left: ml, right: mr, top: 60 }}
      isFocusable={true}
      borderWidth={3}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      axisLeft={{
        format: (e) => {
          if (layout === 'vertical') {
            return Math.floor(e) === e && e
          } else {
            if (String(e).length > 27) {
              const initial = String(e).substring(0, 10)
              const final = String(e).substring(
                String(e).length,
                String(e).length - 11,
              )
              return initial + '... ' + final
            } else return e
          }
        },
      }}
      labelTextColor="black"
      axisBottom={{
        tickSize: 15,
        tickPadding: 5,
        legend,
        format: (e) => {
          if (layout === 'vertical') {
            if (String(e).length > 27) {
              const initial = String(e).substring(0, 10)
              const final = String(e).substring(
                String(e).length,
                String(e).length - 11,
              )
              return initial + '... ' + final
            } else return e
          } else {
            return Math.floor(e) === e && e
          }
        },
        tickRotation: 0,
        legendOffset: -315,
        legendPosition: 'middle',
      }}
      tooltip={(e) => {
        let label = ''
        if (e.label.length > 39) {
          const initial = e.label.substring(0, 24)
          const final = e.label.substring(e.label.length, e.label.length - 11)
          label = initial + '...' + final
        } else label = e.label
        return (
          <Box
            paddingX={1}
            padding={0.5}
            bgcolor="white"
            left={e.index < Math.ceil(data.length / 2) ? '100px' : undefined}
            right={
              e.index > Math.ceil(data.length / 2) ||
              e.index === Math.ceil(data.length / 2)
                ? '100px'
                : undefined
            }
            position="relative"
            borderRadius="20px"
            border="1px solid dodgerblue"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              color="black"
              display="flex"
              fontWeight={700}
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              gap={0.5}
            >
              <Box
                bgcolor={e.color}
                width="20px"
                height="20px"
                borderRadius={20}
              />
              {label} - {e.formattedValue}
            </Typography>
          </Box>
        )
      }}
      theme={{
        tooltip: {
          container: {
            color: 'black',
          },
        },
        axis: {
          legend: {
            text: {
              fontWeight: 700,
              fontSize: 18,
              textTransform: 'uppercase',
              fill: themeName === 'dark' ? 'white' : 'black',
            },
          },
          ticks: {
            text: {
              fill: themeName === 'dark' ? 'white' : 'black',
            },
          },
        },
      }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: lAnchor,
          direction: lDirection,
          justify: false,
          translateX: ltx,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: lItemWidth,
          itemTextColor: themeName === 'dark' ? 'white' : 'black',
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  )
}

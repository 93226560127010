import {
  ArrowLeft,
  ArrowRight,
  DeleteForever,
  DeleteOutline,
  FileCopy,
  Send,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { VForm, useVForm } from '../../../../../shared/forms'
import {
  SacGroupingExternal,
  createSacProtocolExternal,
  getAllSacgroupingsExternal,
} from '../../../../../shared/services/api/sac-services'
import { useNavigate, useParams } from 'react-router-dom'
import {
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../../shared/components'
import { useDebounce } from '../../../../../shared/hooks'
import { SelecionarAgrupamento } from './SelecionarAgrupamento'
import { Formulario } from './Formulario'
import { formValidationSchema } from './validate'
import { ValidationError } from 'yup'

interface NovoProtocoloSACProps {
  customerServiceId: number
  institutionId: number
}

export const NovoProtocoloSAC: FC<NovoProtocoloSACProps> = ({
  customerServiceId,
  institutionId,
}) => {
  const navigate = useNavigate()

  const [attachments, setAttachments] = useState<File[] | Blob[]>([])
  const [totalFiles, setTotalFiles] = useState(0)

  let testFile: File[] | Blob[] = []

  const { slug } = useParams<'slug'>()
  const { formRef } = useVForm()
  const { debounce } = useDebounce(1000)
  const [activeStep, setActiveStep] = useState(0)
  const steps = ['Selecione Um Agrupamento', 'Protocolo']

  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [groupings, setGroupings] = useState<SacGroupingExternal[]>([])

  const [selectedGroup, setSelectedGroup] = useState<number>()

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleNext = () => {
    setIsLoading(true)
    setSearch('')

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (formData: any) => {
    setIsLoading(true)
    if (selectedGroup) {
      formData.grouping = selectedGroup
      formData.external_user = Number(sessionStorage.getItem('EXTERNAL_ID'))
      formData.customer_service = customerServiceId
      formData.institution = institutionId

      for (let index = 0; index < attachments.length; index++) {
        const attachment = attachments[index]
        formData[`file${index + 1}`] = attachment
      }

      formValidationSchema
        .validate(formData, { abortEarly: false })
        .then((validatedData) => {
          createSacProtocolExternal(validatedData)
            .then((result) => {
              if (result instanceof Error) {
                NotificacaoError(result.message)
              } else {
                NotificacaoSucesso('Protocolo criado com sucesso')
                setSelectedGroup(undefined)
                formRef.current?.reset()
                navigate(`/sac/${slug}/inicio/protocolos/detalhes/${result.id}`)
              }
            })
            .finally(() => setIsLoading(false))
        })
        .catch((errors: ValidationError) => {
          const validationErrors: { [key: string]: string } = {}

          errors.inner.forEach((error) => {
            if (error.path) {
              validationErrors[error.path] = error.message
            }
          })

          console.log(validationErrors)
          formRef.current?.setErrors(validationErrors)
        })
        .finally(() => setIsLoading(false))
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if (slug) {
      debounce(() => {
        getAllSacgroupingsExternal(slug, search)
          .then((res) => {
            if (res instanceof Error) {
              NotificacaoError(res.message)
            } else {
              setGroupings(res)
            }
          })
          .finally(() => setIsLoading(false))
      })
    }
    setIsLoading(false)
  }, [search, slug])

  return (
    <Box>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                {activeStep === index ? label : ''}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <VForm onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          spacing={2}
          display="flex"
          justifyContent="center"
          paddingX={3}
          paddingTop={3}
        >
          {activeStep === 0 ? (
            <SelecionarAgrupamento
              groupings={groupings}
              handleNext={handleNext}
              isLoading={isLoading}
              search={search}
              selectedGroup={selectedGroup}
              setSearch={setSearch}
              setSelectedGroup={setSelectedGroup}
            />
          ) : (
            <>
              <Formulario
                customerServiceId={customerServiceId}
                groupId={groupings.find((grp) => grp.id === selectedGroup)?.id}
                groupName={
                  groupings.find((grp) => grp.id === selectedGroup)?.name
                }
              />
              <Grid
                container
                item
                xs={12}
                display="flex"
                justifyContent="center"
                direction="column"
              >
                <Grid container direction="column" spacing={2}>
                  {attachments.map((file, index) => (
                    <Grid
                      key={index}
                      item
                      direction="row"
                      gap={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography>{file.name}</Typography>
                      <Tooltip title="Remover este arquivo">
                        <IconButton
                          onClick={() => {
                            setAttachments(
                              attachments.filter(
                                (_, thisIndex) => thisIndex !== index,
                              ),
                            )
                            setTotalFiles(totalFiles - 1)
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ))}
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    gap={1}
                  >
                    <Button
                      variant="outlined"
                      onClick={() =>
                        document.getElementById('input-file')?.click()
                      }
                      disableElevation
                      sx={{ borderRadius: '50px' }}
                      startIcon={<FileCopy />}
                      disabled={attachments.length === 5}
                    >
                      Anexar Arquivo
                      <input
                        id="input-file"
                        hidden
                        multiple
                        max={5}
                        type="file"
                        onChange={(e) => {
                          if (e.target.files) {
                            if (
                              e.target.files.length + attachments.length >
                              5
                            ) {
                              return NotificacaoError(
                                'Número máximo de arquivos permitidos: 5',
                              )
                            }
                            for (
                              let index = 0;
                              index < e.target.files.length;
                              index++
                            ) {
                              const element = e.target.files[index]
                              testFile.push(element)
                              setAttachments([...attachments, ...testFile])
                              setTotalFiles(totalFiles + 1)
                            }
                          } else {
                            setTotalFiles(0)
                            testFile = []
                            setAttachments(testFile)
                          }
                        }}
                      />
                    </Button>
                    {attachments.length > 0 && (
                      <Tooltip title="Remover tudo">
                        <IconButton
                          onClick={() => {
                            setTotalFiles(0)
                            setAttachments([])
                            testFile = []
                          }}
                        >
                          <DeleteForever />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        {activeStep === 1 && (
          <Box display="flex" flex="1" justifyContent="center" gap={1} mt={2}>
            <Button
              type="submit"
              disableElevation
              variant="contained"
              startIcon={<Send />}
            >
              enviar
            </Button>
          </Box>
        )}
      </VForm>
      {activeStep === 0 && (
        <Fab
          color="primary"
          aria-label="add"
          size="large"
          style={{
            position: 'absolute',
            right: '5%',
            top: '85%',
            zIndex: 1,
          }}
          disabled={!selectedGroup && activeStep === 0}
          onClick={handleNext}
        >
          <ArrowRight />
        </Fab>
      )}
      {activeStep === 1 && (
        <Fab
          color="primary"
          aria-label="add"
          size="large"
          style={{
            position: 'absolute',
            left: '5%',
            top: '85%',
            zIndex: 1,
          }}
          disabled={activeStep === steps.length}
          onClick={handleBack}
        >
          <ArrowLeft />
        </Fab>
      )}
    </Box>
  )
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, MutableRefObject, useState, useRef } from 'react'
import {
  Modal,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
  Paper,
  Grid,
  Tooltip,
  IconButton,
  Button,
  Typography,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  LinearProgress,
} from '@mui/material'

import { createSacProtocol } from '../../../../shared/services/api/sac-services'
import { IFormData, formValidationSchema } from './validate'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { ModalHeader } from '../../../../shared/components/modal/Header'
import {
  VForm,
  VSelect,
  VSwitch,
  VTextArea,
  VTextField,
  useVForm,
} from '../../../../shared/forms'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'
import { AutocompleteDocument } from '../../../ombudsman/protocolos/novo-protocolo/components/AutocompleteDocument'
import {
  AddCircle,
  Badge,
  Cancel,
  Map,
  People,
  Save,
} from '@mui/icons-material'
import { AutoCompleteUsuario } from '../../../ombudsman/protocolos/novo-protocolo/components/AutoComplete'
import { useAuthContext } from '../../../../shared/contexts'
import { FormUsuarioExterno } from '../../../ombudsman/usuarios-externos/formulario/Formulario'
import { ModalMap } from '../../../usuario-externo/protocolo/ModalMap'
import { AutocompleteAgrupamentoSAC } from '../components/autocomplete/AutocompleteAgrupamento'
import {
  AutocompleteCategoriaSAC,
  AutocompleteSetorSAC,
  AutoCompleteAssuntoSAC,
} from '../components/autocomplete'
import { ValidationError } from 'yup'
import { useNavigate } from 'react-router-dom'

interface FormularioProps {
  show: boolean
  onClose(): void
  externalUpdate: MutableRefObject<boolean>
}

export const Formulario: FC<FormularioProps> = ({
  onClose,
  show,
  externalUpdate,
}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const update = useRef(true)

  const { formRef } = useVForm()
  const { thisModule } = useModuleContext()
  const { user } = useAuthContext()

  const [searchType, setSearchType] = useState(1)
  const [group, setGroup] = useState<number>()
  const [sector, setSector] = useState<number>()
  const [newUser, setNewUser] = useState(false)

  const [map, setMap] = useState(false)
  const lat = useRef<number | undefined>()
  const lng = useRef<number | undefined>()

  const resetLat = () => {
    lat.current = undefined
  }

  const resetLng = () => {
    lng.current = undefined
  }

  const [isLoading, setIsLoading] = useState(false)

  function handleClose() {
    formRef.current?.reset()
    update.current = false
    setSector(undefined)
    setIsLoading(false)
    setGroup(undefined)
    setNewUser(false)
    setSearchType(1)
    setMap(false)

    resetLat()
    resetLng()

    onClose()
  }

  function handleSubmit(formData: IFormData) {
    setIsLoading(true)
    formData.institution = user!.institution_id
    formData.customer_service = thisModule!.id
    if (lat && lng) {
      formData.latitude = String(lat)
      formData.longitude = String(lng)
    }

    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData) => {
        createSacProtocol(validatedData).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            externalUpdate.current = true
            NotificacaoSucesso('Protocolo aberto com sucesso')
            navigate(
              `/customer-service/${thisModule!.title}/protocols/details/${
                result.id
              }`,
            )
          }
        })
      })
      .catch((errors: ValidationError) => {
        const validationErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validationErrors[error.path] = error.message
        })
        setIsLoading(false)
        formRef.current?.setErrors(validationErrors)
      })
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        overflow="auto"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(35)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader
          showCloseButton
          onClose={handleClose}
          title="Nova manifestação"
        />
        <Divider />
        {isLoading && <LinearProgress sx={{ mt: 2 }} />}
        <Box margin={1} paddingTop={2}>
          <VForm
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={{ kind_response: 1 }}
          >
            <Grid container spacing={2} direction="column">
              <Grid container item xs={12} direction="row">
                <Grid item xs={12}>
                  <Typography textAlign="center">Pesquisa por:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    startIcon={<People />}
                    onClick={() => setSearchType(1)}
                    variant={searchType === 1 ? 'contained' : 'outlined'}
                    sx={{
                      borderBottomRightRadius: '0px',
                      borderTopRightRadius: '0px',

                      borderBottomLeftRadius: '50px',
                      borderTopLeftRadius: '50px',
                    }}
                  >
                    Nome
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    startIcon={<Badge />}
                    onClick={() => setSearchType(2)}
                    fullWidth
                    variant={searchType === 1 ? 'outlined' : 'contained'}
                    sx={{
                      borderBottomRightRadius: '50px',
                      borderTopRightRadius: '50px',

                      borderBottomLeftRadius: '0px',
                      borderTopLeftRadius: '0px',
                    }}
                  >
                    documento
                  </Button>
                </Grid>
              </Grid>
              <Grid container item spacing={0.5}>
                <Grid item xs={10}>
                  {searchType === 1 ? (
                    <AutoCompleteUsuario
                      required
                      institutionId={String(user!.institution_id)}
                      name="external_user"
                      update={update}
                      textStyle={{
                        fieldSet: {
                          borderRadius: '50px',
                        },
                      }}
                    />
                  ) : (
                    <AutocompleteDocument
                      required
                      update={update}
                      name="external_user"
                    />
                  )}
                </Grid>
                <Grid item display="flex" justifyContent="center" xs={2}>
                  <Tooltip title="Cadastrar novo usuário">
                    <IconButton onClick={() => setNewUser(true)}>
                      <AddCircle />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={8}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    fullWidth
                    onClick={() => setMap(true)}
                    startIcon={<Map />}
                    variant="outlined"
                    sx={{ borderRadius: '50px' }}
                  >
                    Inserir Localização
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FormControlLabel
                    control={<VSwitch name="secret" />}
                    label="Sigilo?"
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <AutocompleteAgrupamentoSAC
                    name="grouping"
                    customOnChange={(_, newValue) => {
                      if (newValue) setGroup(newValue.value)
                      else setGroup(undefined)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutocompleteSetorSAC
                    name="sector"
                    grouping={group}
                    customOnChange={(_, newValue) => {
                      if (newValue) setSector(newValue.value)
                      else setSector(undefined)
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <AutoCompleteAssuntoSAC name="subject" sector={sector} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutocompleteCategoriaSAC name="category" />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    fullWidth
                    size="small"
                    name="relative_protocol"
                    label="Protocolo anterior relacionado"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="response-label" shrink>
                      Tipo de Resposta
                    </InputLabel>
                    <VSelect
                      name="kind_response"
                      label="Tipo de Resposta"
                      labelId="response-label"
                      size="small"
                      sx={{
                        borderRadius: '50px',
                      }}
                    >
                      <MenuItem value={1}>E-Mail</MenuItem>
                      <MenuItem value={2}>Telefone</MenuItem>
                      <MenuItem value={3}>Presencialmente</MenuItem>
                      <MenuItem value={4}>Carta</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <VTextArea
                    required
                    name="description"
                    minRows={4}
                    maxRows={8}
                    placeholder="Descreva aqui a manifestação"
                    ariaLabel="Descrição"
                    style={{
                      width: '90%',
                      borderRadius: '5px',
                      fontVariant: 'historical-forms',
                      font: 'message-box',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box
              mt={2}
              display="flex"
              flex="1"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              <Button
                type="submit"
                disableElevation
                variant="contained"
                startIcon={<Save />}
              >
                salvar
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                startIcon={<Cancel />}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
        <FormUsuarioExterno
          onClose={() => setNewUser(false)}
          show={newUser}
          update={update}
          create
        />
        {ModalMap(() => setMap(false), map, lat, lng)}
      </Box>
    </Modal>
  )
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useState } from 'react'
import {
  Box,
  Modal,
  Paper,
  useTheme,
  useMediaQuery,
  Grid,
  Divider,
  Button,
  LinearProgress,
  FormControlLabel,
} from '@mui/material'

import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { VForm, VSwitch, VTextField, useVForm } from '../../../../shared/forms'
import { useAuthContext } from '../../../../shared/contexts'
import {
  SacSectorList,
  createSacSector,
  updateSacSector,
} from '../../../../shared/services/api/sac-services/setores-services'
import { ModalHeader } from '../../../../shared/components/modal/Header'
import { AutocompleteAgrupamentos } from '../components/AutocompleteAgrupamentos'
import { AutocompleteSac } from '../components/AutocompleteSacs'
import { Cancel, Save } from '@mui/icons-material'
import { IFormData, formValidationSchema } from './validate'
import { ValidationError } from 'yup'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'

interface FormularioProps {
  show: boolean
  onClose(): void
  institution: string | number
  create: boolean
  update: React.MutableRefObject<boolean>
  data?: SacSectorList
}

export const Formulario: FC<FormularioProps> = ({
  onClose,
  show,
  create,
  update,
  institution,
  data,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { formRef } = useVForm()
  const { user } = useAuthContext()
  const { thisModule } = useModuleContext()

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    formRef.current?.reset()
    setIsLoading(false)
    onClose()
  }

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)
    if (create && user?.modules[0].profile === 0) {
      formData.institution = Number(institution)
    } else if (create) {
      formData.institution = Number(user!.institution_id)
      formData.customer_service = thisModule!.id
    } else {
      formData.institution = data!.institution
      formData.customer_service = data!.customer_service.id
    }
    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData) => {
        if (create) {
          createSacSector(validatedData).then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              NotificacaoSucesso('Setor criado com sucesso!')
              update.current = true
              handleClose()
            }
          })
        } else {
          updateSacSector(data!.id, validatedData).then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              NotificacaoSucesso('Setor atualizado com sucesso!')
              update.current = true
              handleClose()
            }
          })
        }
      })
      .catch((errors: ValidationError) => {
        const validationErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validationErrors[error.path] = error.message
        })
        setIsLoading(false)
        formRef.current?.setErrors(validationErrors)
      })
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader
          showCloseButton
          onClose={handleClose}
          title={create ? 'Criar setor' : 'Editar setor'}
        />
        <Divider />
        {isLoading && <LinearProgress sx={{ mt: 2 }} />}
        <Box margin={1}>
          <VForm
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={
              create
                ? undefined
                : {
                    ...data,
                    grouping: data?.grouping.id,
                    institution: data?.institution,
                    customer_service: data?.customer_service.id,
                  }
            }
          >
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    required
                    fullWidth
                    name="name"
                    size="small"
                    label="Nome"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
                <Grid container item xs={12} sm={6}>
                  <AutocompleteAgrupamentos
                    customerService=""
                    institution={String(institution)}
                  />
                </Grid>
              </Grid>
              {user?.modules[0].profile === 0 && (
                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <AutocompleteSac
                      institution={institution}
                      name="customer_service"
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container item spacing={2}>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormControlLabel
                    label="Status"
                    control={<VSwitch name="status" size="small" />}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box
              justifyContent="center"
              alignItems="center"
              display="flex"
              flex="1"
              gap={1}
              mt={2}
            >
              <Button
                disableElevation
                startIcon={<Save />}
                type="submit"
                variant="contained"
                disabled={isLoading}
              >
                salvar
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                startIcon={<Cancel />}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

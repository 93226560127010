import { FC, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
  useTheme,
} from '@mui/material'
import { Cancel, Check } from '@mui/icons-material'

import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../../shared/components'
import {
  SacProtocol,
  finishSacProtocol,
} from '../../../../../shared/services/api/sac-services'

interface StatusModalProps {
  show: boolean
  onClose(): void
  secondClose(): void
  id: string | number | undefined
  setData(data: SacProtocol): void
}

export const StatusModal: FC<StatusModalProps> = ({
  onClose,
  show,
  secondClose,
  id,
  setData,
}) => {
  const theme = useTheme()

  const [isLoading, setIsLoading] = useState(false)

  const [status, setStatus] = useState(5)

  const handleClose = () => {
    setStatus(5)
    onClose()
    secondClose()
  }

  const handleFinish = () => {
    if (id) {
      setIsLoading(true)
      finishSacProtocol(id, { status }).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setData(result)
          NotificacaoSucesso('Protocolo finalizado com sucesso')
          handleClose()
        }
      })
    }
  }

  return (
    <Modal
      open={show}
      onClose={() => {
        if (!isLoading) {
          handleClose()
        }
      }}
    >
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        flexDirection="column"
        width={theme.spacing(40)}
      >
        {isLoading && <LinearProgress />}
        <Box
          display="flex"
          gap={2}
          flex="1"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" textAlign="center">
            Finalizar com status:
          </Typography>
          <FormControl fullWidth size="small">
            <Select
              fullWidth
              size="small"
              sx={{ borderRadius: '50px' }}
              value={status}
              defaultValue={5}
              onChange={(e) => setStatus(Number(e.target.value))}
            >
              <MenuItem value={5}>Concluído</MenuItem>
              <MenuItem value={6}>Indeferido</MenuItem>
            </Select>
          </FormControl>
          <Box
            display="flex"
            flex="1"
            alignItems="center"
            justifyContent="center"
            gap={1}
            paddingTop={2}
          >
            <Button
              startIcon={<Check />}
              onClick={handleFinish}
              variant="contained"
              disableElevation
              sx={{ borderRadius: '50px' }}
              disabled={isLoading}
            >
              Confirmar
            </Button>
            <Button
              startIcon={<Cancel />}
              variant="outlined"
              sx={{ borderRadius: '50px' }}
              disabled={isLoading}
              onClick={() => {
                if (!isLoading) {
                  handleClose()
                }
              }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo, useRef } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Paper,
  LinearProgress,
  IconButton,
  Icon,
  Box,
  Pagination,
  Select,
  MenuItem,
  Tooltip,
  Typography,
  Grid,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  Button,
} from '@mui/material'

import { useDebounce } from '../../../shared/hooks'
import { Environment } from '../../../shared/environment'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import { colors, FormatProtocolStatus } from '../../../shared/utils'
import { getAllProtocols, IProtocolsList } from '../../../shared/services/api'
import {
  FerramentasDaListagem,
  NotificacaoError,
} from '../../../shared/components'
import { useAuthContext } from '../../../shared/contexts'
import { FormatPriority } from '../../../shared/utils/format/FormatPriority'
import { ExpandMore, FilterAlt, FilterAltOff } from '@mui/icons-material'
import {
  AutoCompleteAssunto,
  AutoCompleteResponsavel,
  AutoCompleteSetor,
  AutoCompleteTipologia,
} from './components/Filter'
import { NovoProtocolo } from './novo-protocolo/NovoProtocolo'
import { AutocompleteCidadao } from './components/AutocompleteCidadao'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import usePersistedState from '../../../shared/hooks/usePersistedState'

interface ISearchParams {
  item: string
  busca: string
  total: string
  setor: string
  status: string
  assunto: string
  tipologia: string
  prioridade: string
  responsavel: string
}

export const Protocolos: React.FC = () => {
  const { user } = useAuthContext()

  const saved = useRef(false)

  const [institution, setInstitution] = usePersistedState<number | string>(
    'ADM_SELECTED_INSTITUTION',
    '',
  )
  const toggleSaved = (e: number | undefined) => {
    setInstitution(e ? String(e) : '')
  }

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState<IProtocolsList[]>([])
  const [totalCount, setTotalCount] = useState(0)

  const [externalId, setExternalId] = useState('')

  const [currentParams, setCurrentParams] = useState<ISearchParams>({
    item: '',
    busca: '',
    setor: '',
    total: '',
    status: '',
    assunto: '',
    tipologia: '',
    prioridade: '',
    responsavel: '',
  })
  const [searchParams, setSearchParams] = useSearchParams()

  const { debounce } = useDebounce(1200)

  const { thisModule } = useModuleContext()

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])

  const status = useMemo(() => {
    return searchParams.get('status') || ''
  }, [searchParams])

  const priority = useMemo(() => {
    return searchParams.get('prioridade') || ''
  }, [searchParams])

  const assunto = useMemo(() => {
    return searchParams.get('assunto') || ''
  }, [searchParams])

  const setor = useMemo(() => {
    return searchParams.get('setor') || ''
  }, [searchParams])

  const tipologia = useMemo(() => {
    return searchParams.get('tipologia') || ''
  }, [searchParams])

  const responsavel = useMemo(() => {
    return searchParams.get('responsavel') || ''
  }, [searchParams])

  const selected = useMemo(() => {
    return searchParams.get('selecionado') || ''
  }, [searchParams])

  const pagina = Number(item) / Number(total) + 1

  const changePage = (value1: number, value2: number) => {
    const result = value1 * value2 - value2
    return result.toString()
  }

  const [viewForm, setViewForm] = useState(false)

  const handleClose = () => {
    if (viewForm) setViewForm(false)
    else setViewForm(true)
  }

  // useEffect(() => {
  //   if (thisModule) {
  //     setIsLoading(true)
  //     debounce(() => {
  //       getAllSacProtocols(busca, item, total, String(thisModule.id)).then(
  //         (result) => {
  //           update.current = false
  //           setIsLoading(false)
  //           if (result instanceof Error) {
  //             NotificacaoError(result.message)
  //           } else {
  //             setRows(result.results)
  //             setTotalCount(result.count)
  //           }
  //         },
  //       )
  //     })
  //   }
  // }, [busca, item, total, saved.current, thisModule?.id])

  useEffect(() => {
    if (thisModule) {
      setIsLoading(true)
      if (thisModule.profile === 0) {
        setInstitution(localStorage.getItem('ADM_SELECTED_INSTITUTION') || '')
      } else setInstitution('')
    }

    debounce(() => {
      getAllProtocols(
        item,
        total,
        busca,
        status,
        priority,
        setor,
        assunto,
        tipologia,
        responsavel,
        externalId,
      )
        .then((result) => {
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            setRows(result.results)
            setTotalCount(result.count)
          }
        })
        .catch((err) => {
          console.error(err)
          NotificacaoError('Erro desconhecido. Tente novamente')
        })
        .finally(() => {
          saved.current = false
          setIsLoading(false)
        })
    })
  }, [
    item,
    total,
    busca,
    status,
    priority,
    institution,
    saved.current,
    responsavel,
    setor,
    externalId,
    assunto,
    tipologia,
  ])

  const [clear, setClear] = useState(false)

  const clearFilter = () => {
    setClear(true)
    setExternalId('')
    const editParam = currentParams
    editParam.total = total
    editParam.busca = busca
    setCurrentParams(editParam)
    setCurrentParams(() => ({ ...editParam }))
    setSearchParams({ ...editParam }, { replace: true })
  }

  const setColor = (deadline: number) => {
    if (deadline < 0) return 6
    if (deadline < 6) return 5

    const result = Math.ceil(Number(thisModule?.deadline) / deadline)

    if (result <= 1) return 1
    if (result >= 5) return 4
    if (result === 2) return 2
    if (result === 3) return 3
    return 4
  }

  return (
    <LayoutBaseDePagina
      title="Protocolos"
      selectInstitution
      autoCompleteChange={toggleSaved}
      filter={
        <FerramentasDaListagem
          mostrarInputDaBusca
          mostrarBotaoNovo={thisModule?.profile === 2}
          textoBotaoNovo="Nova"
          aoClicarEmNovo={handleClose}
          textoDaBusca={busca}
          aoMudarTextoDaBusca={(texto) => {
            const editParam = currentParams
            editParam.item = '0'
            editParam.busca = texto
            setCurrentParams(editParam)
            setSearchParams(
              { ...editParam },
              {
                replace: true,
              },
            )
          }}
        />
      }
    >
      {thisModule && (
        <Box display="flex" padding={1} flex={1}>
          <Accordion
            sx={{ width: '100%', border: '1px solid dodgerblue' }}
            style={{ borderRadius: '15px' }}
          >
            <AccordionSummary
              sx={{ display: 'flex', alignItems: 'center' }}
              expandIcon={<ExpandMore />}
            >
              <Grid container>
                <Grid container item spacing={1} alignItems="center">
                  <Grid item alignItems="center">
                    <Typography textAlign="center">
                      <FilterAlt />
                    </Typography>
                  </Grid>
                  <Grid item alignItems="center">
                    <Typography textAlign="center">Filtros</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} direction="column">
                <Grid container item spacing={2}>
                  {/* Status */}
                  <Grid item xs={6} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="status-label">Status</InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        onChange={(e) => {
                          const editParam = currentParams
                          editParam.item = '0'

                          setSearchParams(
                            {
                              busca,
                              item: '0',
                              total,
                              status: String(
                                e.target.value !== 0 ? e.target.value : '',
                              ),
                              assunto,
                              tipologia,
                              prioridade: priority,
                              responsavel,
                            },
                            { replace: true },
                          )
                        }}
                        defaultValue={0}
                        value={Number(status)}
                        label="Status"
                        sx={{ borderRadius: '50px' }}
                        labelId="status-label"
                      >
                        <MenuItem value={0}>Todos</MenuItem>
                        <MenuItem value={1}>Atrasadas</MenuItem>
                        <MenuItem value={2}>Pendentes</MenuItem>
                        <MenuItem value={3}>Trâmites</MenuItem>
                        <MenuItem value={4}>Prorrogadas</MenuItem>
                        <MenuItem value={5}>Concluídas</MenuItem>
                        <MenuItem value={6}>Indeferidas</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Prioridade */}
                  <Grid item xs={6} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="priority-label">Prioridade</InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        onChange={(e) =>
                          setSearchParams(
                            {
                              busca,
                              item: '0',
                              total,
                              status,
                              assunto,
                              tipologia,
                              prioridade:
                                e.target.value === 0
                                  ? ''
                                  : String(Number(e.target.value) - 1),
                              responsavel,
                            },
                            { replace: true },
                          )
                        }
                        defaultValue={0}
                        value={priority === '' ? 0 : Number(priority) + 1}
                        label="Prioridade"
                        labelId="priority-label"
                        sx={{
                          borderRadius: '50px',
                        }}
                      >
                        <MenuItem value={0}>Todas</MenuItem>
                        <MenuItem value={1}>Sem Prioridade</MenuItem>
                        <MenuItem value={2}>Baixa</MenuItem>
                        <MenuItem value={3}>Média</MenuItem>
                        <MenuItem value={4}>Alta</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Responsável */}
                  <Grid item xs={6} sm={4}>
                    <AutoCompleteResponsavel
                      institutionId={String(user?.institution_id)}
                      initialValue={responsavel}
                      onChange={(e) =>
                        setSearchParams(
                          {
                            busca,
                            item: '0',
                            total,
                            setor,
                            status,
                            assunto,
                            tipologia,
                            prioridade: priority,
                            responsavel: String(e || ''),
                          },
                          { replace: true },
                        )
                      }
                      textStyle={{
                        fieldSet: {
                          borderRadius: '50px',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={6} sm={4}>
                    <AutoCompleteSetor
                      institutionId={String(user?.institution_id)}
                      onChange={(e) =>
                        setSearchParams(
                          {
                            busca,
                            item: '0',
                            total,
                            status,
                            setor: String(e || ''),
                            assunto,
                            tipologia,
                            prioridade: priority,
                            responsavel,
                          },
                          { replace: true },
                        )
                      }
                      initialValue={setor}
                      textStyle={{
                        fieldSet: {
                          borderRadius: '50px',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <AutoCompleteAssunto
                      institutionId={String(user?.institution_id)}
                      onChange={(e) =>
                        setSearchParams(
                          {
                            busca,
                            item: '0',
                            total,
                            status,
                            setor,
                            assunto: String(e || ''),
                            tipologia,
                            prioridade: priority,
                            responsavel,
                          },
                          { replace: true },
                        )
                      }
                      initialValue={assunto}
                      textStyle={{
                        fieldSet: {
                          borderRadius: '50px',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <AutoCompleteTipologia
                      institutionId={String(user?.institution_id)}
                      onChange={(e) =>
                        setSearchParams(
                          {
                            busca,
                            item: '0',
                            total,
                            status,
                            setor,
                            assunto,
                            tipologia: String(e || ''),
                            prioridade: priority,
                            responsavel,
                          },
                          { replace: true },
                        )
                      }
                      initialValue={tipologia}
                      textStyle={{
                        fieldSet: {
                          borderRadius: '50px',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item xs={12}>
                    <AutocompleteCidadao
                      clear={clear}
                      setClear={setClear}
                      onChange={(e) => {
                        if (e !== undefined) {
                          setExternalId(String(e))
                        } else {
                          setExternalId('')
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item xs={12} display="flex" justifyContent="right">
                    <Button
                      startIcon={<FilterAltOff />}
                      variant="outlined"
                      sx={{ borderRadius: '50px' }}
                      onClick={clearFilter}
                    >
                      limpar filtro
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{
          m: 1,
          width: 'auto',
          color: 'dodgerblue',
          border: '1px solid',
          borderRadius: '15px',
        }}
      >
        <Table>
          <TableHead>
            {totalCount > 0 && (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <Typography variant="caption">
                    Total: {totalCount} manifestações
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Identificador</TableCell>
              <TableCell align="center">Prazo</TableCell>
              <TableCell align="center">Agrupamento</TableCell>
              <TableCell align="center">Setor</TableCell>
              <TableCell align="center">Assunto</TableCell>
              <TableCell align="center">Tipologia</TableCell>
              <TableCell align="center">Prioridade</TableCell>
              <TableCell align="center">Setor Notificado</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                selected={selected === String(row.id)}
                key={row.id}
                sx={{
                  borderLeft: `5px solid ${
                    row.status === 1
                      ? '#EB3324'
                      : row.status === 2
                      ? '#FFFF00'
                      : row.status === 3
                      ? '#CBAACB'
                      : row.status === 4
                      ? '#55CBCD'
                      : row.status === 5
                      ? '#8bdaa7'
                      : '#999999'
                  }`,
                }}
              >
                <TableCell align="center">
                  <Link to={`detail/${row.id}`}>
                    <IconButton color="primary">
                      <Icon>info_outlined</Icon>
                    </IconButton>
                  </Link>
                </TableCell>
                <TableCell align="center">{row.identifier}</TableCell>
                <TableCell align="center">
                  <Typography
                    variant="h6"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    sx={{
                      backgroundColor:
                        row.status < 5
                          ? colors[setColor(row.deadline)]
                          : undefined,
                      borderRadius: '50%',
                      width: '38px',
                      height: '38px',
                    }}
                  >
                    {row.status > 4 ? '-' : row.deadline}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {row.grouping_id ? row.grouping_id.name : 'Indefinido'}
                </TableCell>
                <TableCell align="center">
                  {row.sector_id ? row.sector_id.name : 'Indefinido'}
                </TableCell>
                <TableCell align="center">
                  {row.subject_id ? row.subject_id.name : 'Indefinido'}
                </TableCell>
                <TableCell align="center">
                  {row.category_id ? row.category_id.name : 'Indefinido'}
                </TableCell>
                <TableCell align="center">
                  {FormatPriority(Number(row.priority))}
                </TableCell>
                <TableCell align="center">
                  {row.notified_sector ? 'Sim' : 'Não'}
                </TableCell>
                <TableCell align="center">
                  {FormatProtocolStatus(row.status)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={10}>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{ height: '5px', borderRadius: '50px' }}
                  />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 10 || totalCount > Number(total)) && (
              <TableRow>
                <TableCell colSpan={10}>
                  <Box display="flex" justifyContent="center">
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / Number(total))}
                      onChange={(_, newPage) => {
                        setSearchParams(
                          {
                            busca,
                            setor,
                            total,
                            status,
                            assunto,
                            tipologia,
                            prioridade: priority,
                            responsavel,
                            item: changePage(newPage, Number(total)),
                          },
                          {
                            replace: true,
                          },
                        )
                      }}
                    />
                    <Tooltip
                      title="Itens por Página"
                      open={open}
                      onMouseEnter={() => setOpen(true)}
                      onMouseDown={() => setOpen(false)}
                      onMouseLeave={() => setOpen(false)}
                    >
                      <Select
                        variant="outlined"
                        sx={{ borderRadius: '15px', height: '30px' }}
                        value={total}
                        onChange={(e) => {
                          setSearchParams(
                            {
                              busca,
                              item: '0',
                              setor,
                              status,
                              assunto,
                              tipologia,
                              prioridade: priority,
                              total: e.target.value,
                              responsavel,
                            },
                            {
                              replace: true,
                            },
                          )
                        }}
                      >
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="40">40</MenuItem>
                        <MenuItem value="50">50</MenuItem>
                      </Select>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {totalCount > 0 && (
              <TableRow>
                <TableCell align="center" colSpan={10}>
                  <Typography variant="caption">
                    Total: {totalCount} manifestações
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <NovoProtocolo onClose={handleClose} show={viewForm} />
    </LayoutBaseDePagina>
  )
}

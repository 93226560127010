import { FC, useCallback, useEffect, useState, useRef } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Modal,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
  SurveyButtonsWraper,
} from '../../../shared/components'
import {
  IProtocols,
  IQuestion,
  getSurvey,
  replySurvey,
} from '../../../shared/services/api'
import { Send } from '@mui/icons-material'

interface IValues {
  question_id: number
  value: number
}

interface IPesquisaModalProps {
  show: boolean
  onClose: () => void
  update: React.MutableRefObject<boolean>
  protocolData: IProtocols | undefined
}

export const PesquisaModal: FC<IPesquisaModalProps> = ({
  show,
  update,
  onClose,
  protocolData,
}) => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const [comments, setComments] = useState('')

  const [questions, setQuestions] = useState<IQuestion[]>([])
  const [questionsLoading, setQuestionsLoading] = useState(true)

  const [isLoading, setIsLoading] = useState(false)

  const { ButtonFragment } = SurveyButtonsWraper()

  const values = useRef<IValues[]>([])

  const handleClick = useCallback((questionId: number, value: number) => {
    values.current = [
      ...values.current.filter((value) => value.question_id !== questionId),
      {
        question_id: questionId,
        value,
      },
    ]
  }, [])

  const handleSumbmit = () => {
    setIsLoading(true)
    const formData = {
      answers: values.current,
      protocol_id: protocolData?.id,
      comments,
    }
    replySurvey(formData).then((result) => {
      setIsLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        update.current = true
        NotificacaoSucesso('Pesquisa de satisfação respondida com sucesso')
        onClose()
      }
    })
  }

  useEffect(() => {
    setQuestionsLoading(true)
    if (protocolData) {
      getSurvey(protocolData?.identifier).then((result) => {
        setQuestionsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setQuestions(result)
        }
      })
    }
  }, [protocolData])

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        overflow="auto"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(35)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Typography variant="h6" textAlign="center">
          PESQUISA DE SATISFAÇÃO
        </Typography>
        <Typography fontSize="13px" textAlign="center" color="dodgerblue">
          {`
        responda as perguntas com uma nota de 0 a 10, onde a nota mínima
        significa "discordo totalmente" e a nota máxima "concordo totalmente"
        `}
        </Typography>
        <Divider />
        <Box margin={1} display="flex" flex="1" flexDirection="column">
          {questionsLoading && <LinearProgress variant="indeterminate" />}

          <Grid container direction="column" spacing={2.5}>
            {questions.map((question, index) => (
              <Grid
                container
                item
                direction="column"
                spacing={1}
                xs={12}
                key={index}
              >
                {!!question.introduction && (
                  <Grid item xs={12}>
                    <Typography
                      textAlign="justify"
                      fontSize="18px"
                      variant="h6"
                    >
                      {question.introduction}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography textAlign="center" color="dodgerblue">
                    - {question.title}
                  </Typography>
                </Grid>
                <Grid
                  container
                  marginTop={1}
                  item
                  spacing={1}
                  display="flex"
                  justifyContent="space-evenly"
                >
                  <ButtonFragment
                    questionId={question.id}
                    externalOnClick={handleClick}
                  />
                </Grid>
                <Grid item xs={12} marginY={1}>
                  <Divider variant="middle" />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} sm={6} marginBottom={2}>
            <TextField
              fullWidth
              label="Comentários"
              variant="outlined"
              multiline
              rows={4}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Grid>
        </Box>
        <Box
          id="buttons"
          display="flex"
          flex="1"
          gap={1}
          justifyContent="center"
        >
          <Button
            startIcon={<Send />}
            variant="contained"
            disableElevation
            disabled={questionsLoading || isLoading}
            onClick={() => handleSumbmit()}
          >
            enviar
          </Button>
          <Button variant="outlined" onClick={onClose}>
            fechar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

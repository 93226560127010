import { Box, Modal, Paper, useMediaQuery, useTheme } from '@mui/material'
import {
  FormBoxStyle,
  TabPanel,
  VTab,
  VTabs,
  a11yProps,
} from '../../../../shared/components'
import { IClientDetail } from '../../../../shared/services/api'
import { useState } from 'react'
import { NovoAnonimo } from './components/NovoAnonimo'
import { AcompanharAnonimo } from './components/AcompanharAnonimo'

interface AnonimoModalProps {
  show: boolean
  onClose: () => void
  institutionData: IClientDetail
}

export const AnonimoModal: React.FC<AnonimoModalProps> = ({
  show,
  onClose,
  institutionData,
}) => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const [tab, setTab] = useState(0)

  const handleChange = (newValue: number) => {
    setTab(newValue)
  }

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        overflow="auto"
        borderRadius="20px"
        maxHeight="85vh"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <VTabs
            value={tab}
            onChange={(_, newValue) => handleChange(newValue)}
            aria-label="tabs-anonymous"
          >
            <VTab
              label={'Abrir Manifestação Anônima'}
              {...a11yProps(0)}
              sx={{ display: 'flex', flex: '1' }}
            />
            <VTab
              label={'Acompanhar Manifestação Anônima'}
              {...a11yProps(0)}
              sx={{ display: 'flex', flex: '1' }}
            />
          </VTabs>
        </Box>
        <TabPanel value={Number(tab)} index={0}>
          <NovoAnonimo institutionData={institutionData} onClose={onClose} />
        </TabPanel>
        <TabPanel value={Number(tab)} index={1}>
          <AcompanharAnonimo handleClose={onClose} />
        </TabPanel>
      </Box>
    </Modal>
  )
}

/* eslint-disable @typescript-eslint/ban-types */
import { IUserList } from '../../admin-users-services/AdminusersServices'
import { Api, TotalCount } from '../../axios-config'
import { SacModule } from '../../modules-services/SacServices'
import { IExternalUsers } from '../../usuario-externo-services/UsuarioExternoServices'
import { SacGrouping } from '../agrupamentos-services'
import { SacSubjectList } from '../assuntos-services'
import { SacCategory } from '../categorias-services'
import { SacSector } from '../setores-services'

export interface SacProtocol {
  id: number
  institution: number
  secret: boolean
  kind_response: number
  customer_service: SacModule
  grouping: SacGrouping
  sector: SacSector
  subject: SacSubjectList
  category: SacCategory
  external_user: IExternalUsers
  responsible: IUserList
  identifier: string
  ip_address: string
  analysis_started: boolean
  sector_notified: boolean
  description: string
  status: number
  file1?: string
  file2?: string
  file3?: string
  file4?: string
  file5?: string
  priority: number
  relative_protocol?: string
  pending_days: number
  analyzing_days: number
  extended_days: number
  delayed_days: number
  expires_at: string
  deadline: number
  view_count: number
  finished_at?: string
  created_at: string
  updated_at: string
  latitude: string
  longitude: string
  replied_survey: boolean
}

export interface SacProtocolViews {
  user?: string
  date: string
}

export interface SacForwards {
  email_address: string
  subject: string
  comment?: string
  created_at: string
  protocol: SacProtocol
  user: IUserList
}

export const getAllSacProtocols = async (
  busca = '',
  item = '0',
  total = '10',
  sac = '',
): Promise<TotalCount<SacProtocol> | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/protocols/${sac}/list/`

    const { data } = await Api.get(relativeUrl, {
      params: {
        search: busca,
        item,
        total,
        customer_service: sac,
      },
    })

    if (data) return data

    return new Error('Erro ao listar protocolos')
  } catch (error) {
    console.error((error as { message: string }).message)
    return new Error((error as { message: string }).message)
  }
}

export const createSacProtocol = async (formData: {}): Promise<
  SacProtocol | Error
> => {
  try {
    const relativeUrl = '/V1/customer_service/protocols/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao criar protocolo')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getSacProtocolById = async (
  id: number | string,
): Promise<SacProtocol | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/protocols/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const startSacProtocolAnalysis = async (
  id: number | string,
): Promise<SacProtocol | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/protocols/${id}/start_analysis/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao iniciar análise')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getSacProtocolViews = async (
  id: number | string,
): Promise<SacProtocolViews[] | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/protocols/${id}/views/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar visualizações')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getAllSacForwards = async (
  id: number | string,
): Promise<SacForwards[] | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/protocols/${id}/forwards/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar encaminhamentos')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const createSacForward = async (
  id: number | string,
  formData: {},
): Promise<SacForwards | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/protocols/${id}/forwards/`

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao criar encaminhamento')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const finishSacProtocol = async (
  id: number | string,
  formData: {},
): Promise<SacProtocol | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/protocols/${id}/finish/`

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data
    return new Error('Erro ao finalizar protocolo')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const setSacProtocolSecret = async (
  id: number | string,
  formData: {},
): Promise<SacProtocol | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/protocols/${id}/secret/`

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data
    return new Error('Erro ao finalizar protocolo')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const transferSacProtocol = async (
  id: number | string,
  formData: {},
): Promise<SacProtocol | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/protocols/${id}/transfer/`

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao transferir protocolo')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const changeSacProtocolCategory = async (
  id: number | string,
  formData: {},
): Promise<SacProtocol | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/protocols/${id}/category/`

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao alterar categoria')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getAllProtocolsPerUser = async (
  customerService: string | number,
  userId: string | number,
): Promise<TotalCount<SacProtocol> | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/${customerService}/external_users/${userId}/protocols/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar protocolos')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const extendSacProtocol = async (
  protocolId: string | number,
  formData: object,
): Promise<SacProtocol | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/protocols/${protocolId}/extend/`

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao prorrogar protocolo')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

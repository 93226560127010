import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Button,
} from '@mui/material'
import { FC, useState } from 'react'
import {
  FormBoxStyle,
  ModalHeader,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { VForm, VSelect, VTextField, useVForm } from '../../../../shared/forms'
import { formatCellPhone } from '../../../../shared/utils'
import { formatToCNPJ, formatToCPF } from 'brazilian-values'
import { PrivacyPolicyModal } from '../../components/PrivacyPolicyModal'
import {
  Cancel,
  CheckCircleRounded,
  RadioButtonUncheckedRounded,
  Save,
} from '@mui/icons-material'
import { createExternal } from '../../../../shared/services/api'
import { toast } from 'react-toastify'

interface CadastreSeModalProps {
  show: boolean
  onClose: () => void
  customerServiceLogo: string
}

export const CadastreSeModal: FC<CadastreSeModalProps> = ({
  customerServiceLogo,
  onClose,
  show,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)
  const [documentType, setDocumentType] = useState(1)
  const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false)
  const [accept, setAccept] = useState(false)

  const handleSubmit = (formData: object) => {
    setIsLoading(true)
    createExternal(formData, false)
      .then((result) => {
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          NotificacaoSucesso('Cadastro realizado com sucesso!')
          toast.info('Faça login com documento e senha cadastrados!')
          handleClose()
        }
      })
      .finally(() => setIsLoading(false))
  }

  const handleClose = () => {
    formRef.current?.reset()
    setDocumentType(1)
    setAccept(false)
    setPrivacyPolicyModal(false)
    onClose()
  }

  return (
    <Modal onClose={handleClose} open={show}>
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Box
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            width="300px"
            style={{ maxWidth: '100vw' }}
            src={customerServiceLogo}
            alt="sac logo"
          />
        </Box>
        <ModalHeader
          showCloseButton
          title="Cadastre-se"
          onClose={handleClose}
        />

        <Divider />

        <Box margin={1}>
          <VForm
            autoComplete="off"
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{ document_type: 1, gender: 4, escolarity: 10 }}
          >
            <Grid container spacing={2} direction="column">
              {isLoading && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    required
                    fullWidth
                    name="name"
                    size="small"
                    label="Nome"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    required
                    fullWidth
                    name="number"
                    size="small"
                    label="Celular"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                    formatData={formatCellPhone}
                    placeholder="(DDD) 99999-9999"
                    inputProps={{
                      maxLength: 15,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <VTextField
                  name="email"
                  fullWidth
                  autoComplete="off"
                  type="email"
                  borderTopLeft="50px"
                  borderBottomLeft="50px"
                  size="small"
                  label="E-mail"
                  placeholder="email@example.com"
                />
              </Grid>

              <Grid container item spacing={2}>
                <Grid container item spacing={0} xs={12} sm={6}>
                  <Grid item xs={6} sm={4} md={3}>
                    <VSelect
                      required
                      fullWidth
                      name="document_type"
                      size="small"
                      myChange={(e) => setDocumentType(e)}
                      sx={{
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                      }}
                    >
                      <MenuItem value={1}>CPF</MenuItem>
                      <MenuItem value={2}>CNPJ</MenuItem>
                    </VSelect>
                  </Grid>
                  <Grid item xs={6} sm={8} md={9}>
                    <VTextField
                      formatData={
                        documentType === 1 ? formatToCPF : formatToCNPJ
                      }
                      name="document_number"
                      fullWidth
                      required
                      label="Documento"
                      size="small"
                      inputProps={{
                        maxLength: documentType === 1 ? 14 : 18,
                      }}
                      placeholder={
                        documentType === 1
                          ? '000.000.000-00'
                          : '00.000.000-0000/00'
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="gender-label">Gênero</InputLabel>
                    <VSelect
                      name="gender"
                      label="Gênero"
                      labelId="gender-label"
                      disabled={documentType === 2}
                      sx={{
                        borderRadius: '50px',
                      }}
                    >
                      <MenuItem value={1}>Masculino</MenuItem>
                      <MenuItem value={2}>Feminino</MenuItem>
                      <MenuItem value={3}>Não binário</MenuItem>
                      <MenuItem value={4}>Prefiro não responder</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    size="small"
                    name="address"
                    label="Endereço"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    fullWidth
                    size="small"
                    type="date"
                    name="birth_date"
                    label="Data de nascimento"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="escolarity-label">Escolaridade</InputLabel>
                    <VSelect
                      fullWidth
                      size="small"
                      name="escolarity"
                      label="Escolaridade"
                      labelId="escolarity-label"
                      sx={{
                        borderRadius: '50px',
                      }}
                    >
                      <MenuItem value={1}>Não Alfabetizado</MenuItem>
                      <MenuItem value={2}>
                        Ensino Fundamental Incompleto
                      </MenuItem>
                      <MenuItem value={3}>Ensino Fundamental Completo</MenuItem>
                      <MenuItem value={4}>Ensino Médio Incompleto</MenuItem>
                      <MenuItem value={5}>Ensino Médio Completo</MenuItem>
                      <MenuItem value={6}>Técnico incompleto</MenuItem>
                      <MenuItem value={7}>Técnino Completo</MenuItem>
                      <MenuItem value={8}>Superior Incompleto</MenuItem>
                      <MenuItem value={9}>Superior Completo</MenuItem>
                      <MenuItem value={10}>Prefiro não responder</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <VTextField
                    required
                    autoComplete="new-password"
                    fullWidth
                    size="small"
                    name="password"
                    type="password"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                    label="Senha"
                  />
                </Grid>
                <Grid item xs={12}>
                  <VTextField
                    required
                    autoComplete="new-password"
                    fullWidth
                    size="small"
                    name="re_password"
                    type="password"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                    label="Repita a senha"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box display="flex" flex="1" flexDirection="column" gap={2} mt={2}>
              <Box
                display="flex"
                flex="1"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  type="button"
                  component="button"
                  onClick={() => setPrivacyPolicyModal(true)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'transparent',
                    gap: 1,
                    border: 'none',
                    width: 'fit-content',
                    cursor: 'pointer',
                  }}
                >
                  {accept ? (
                    <CheckCircleRounded color="primary" />
                  ) : (
                    <RadioButtonUncheckedRounded color="primary" />
                  )}
                  Declaro que li e concordo com os termos de uso e política de
                  privacidade
                </Typography>
              </Box>
              <Box
                display="flex"
                flex="1"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <Button
                  disableElevation
                  disabled={isLoading}
                  variant="contained"
                  type={accept ? 'submit' : 'button'}
                  onClick={
                    !accept ? () => setPrivacyPolicyModal(true) : undefined
                  }
                  startIcon={<Save />}
                >
                  Salvar
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  startIcon={<Cancel />}
                >
                  cancelar
                </Button>
              </Box>
            </Box>
          </VForm>
        </Box>
        <PrivacyPolicyModal
          onClose={() => setPrivacyPolicyModal(false)}
          show={privacyPolicyModal}
          setAccept={setAccept}
        />
      </Box>
    </Modal>
  )
}

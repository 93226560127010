/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useRef, useEffect } from 'react'

import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDeDetalhes,
  NotificacaoError,
} from '../../../shared/components'
import { Formulario } from './formulario/Formulario'
import usePersistedState from '../../../shared/hooks/usePersistedState'
import {
  SacModule,
  getSacById,
} from '../../../shared/services/api/modules-services/SacServices'
import { useParams } from 'react-router-dom'

export const DetalhesModuloSac: FC = () => {
  const update = useRef(false)
  const { id } = useParams<'id'>()
  const [institution] = usePersistedState<string>(
    'ADM_SELECTED_INSTITUTION',
    '',
  )

  const [viewForm, setViewForm] = useState(false)
  const [data, setData] = useState<SacModule>()
  const [create, setCreate] = useState(false)

  useEffect(() => {
    if (id) {
      getSacById(id).then((result) => {
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setData(result)
        }
      })
    }
  }, [id, update.current])

  return (
    <LayoutBaseDePagina
      title="Detalhes de S.A.C"
      barraDeFerramentas={
        <FerramentasDeDetalhes
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
        />
      }
    >
      <Formulario
        onClose={() => setViewForm(false)}
        institution={Number(institution)}
        update={update}
        create={create}
        show={viewForm}
        data={data}
      />
    </LayoutBaseDePagina>
  )
}

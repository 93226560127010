/* eslint-disable @typescript-eslint/no-explicit-any */
// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { useState, useEffect } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { ResponsivePie } from '@nivo/pie'
import { useAppThemeContext } from '../../shared/contexts'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

interface IResponsiveBarProps {
  data: any
  open?: boolean
}

export const PieEscolaridade: React.FC<IResponsiveBarProps> = ({
  data /* see data tab */,
  open,
}) => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const { themeName } = useAppThemeContext()

  const [currentThemeName, setCurrentThemeName] = useState(themeName)

  useEffect(() => {
    setCurrentThemeName((old) => (old === 'light' ? 'light' : 'dark'))
  }, [themeName])

  return (
    <ResponsivePie
      data={data}
      margin={
        smDown
          ? { bottom: open ? 600 : 100, right: 15, left: 15 }
          : mdDown
          ? { bottom: open ? 468 : 115, top: 12 }
          : { top: 10, bottom: 50, left: 10, right: 10 }
      }
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={5}
      activeOuterRadiusOffset={10}
      borderWidth={3}
      startAngle={smDown ? -90 : 0}
      endAngle={smDown ? 90 : 360}
      colors={{ scheme: 'paired' }}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      arcLinkLabel={function (e: any) {
        return e.id + ' (' + e.value + ')'
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={
        currentThemeName === 'dark' ? '#ffffff' : '#000000'
      }
      arcLinkLabelsThickness={3}
      arcLinkLabelsDiagonalLength={20}
      enableArcLinkLabels={!smDown}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      theme={{
        tooltip: {
          container: {
            color: 'black',
          },
          chip: {
            borderRadius: '50%',
          },
        },
      }}
      layers={['arcs', 'arcLabels']}
    />
  )
}

export const formatBRLCustom = (currentNumber: string | number) => {
  let zero = true
  let total = 0
  currentNumber = String(currentNumber)
  currentNumber = currentNumber
    .replace(',', '')
    .replace('R$', '')
    .replace(' ', '')
    .replace('.', '')
  if (Number(currentNumber) === 0) currentNumber = ''

  for (let index = 0; index < currentNumber.length; index++) {
    const element = currentNumber[index]

    if (Number(element) !== 0) {
      zero = false
    } else {
      total += 1
    }

    if (zero === false) break
  }

  for (let index = 0; index < total; index++) {
    currentNumber = currentNumber.slice(1)
  }

  if (!currentNumber.length) {
    if (currentNumber.includes('R$')) currentNumber = '0,00'
    else currentNumber = 'R$ 0,00'
    return currentNumber
  }

  if (currentNumber.length === 1) {
    if (currentNumber.includes('R$')) currentNumber = `0,0${currentNumber}`
    else currentNumber = `R$ 0,0${currentNumber}`
    return currentNumber
  } else if (currentNumber.length === 2) {
    if (currentNumber.includes('R$')) currentNumber = `0,${currentNumber}`
    else currentNumber = `R$ 0,${currentNumber}`
    return currentNumber
  } else if (currentNumber.length > 2) {
    const last = currentNumber.slice(-2)
    let hNumber = currentNumber.substring(0, currentNumber.length - 2)
    const points = Math.floor((hNumber.length - 1) / 3)
    const parts = []

    if (hNumber.length > 3) {
      let second = hNumber
      for (let index = 0; index < points; index++) {
        if (index === 0) {
          if (hNumber.length % 3 === 0) {
            parts.push(hNumber.slice(0, 3))
            second = hNumber.slice(3, hNumber.length)
          } else if (hNumber.length % 3 === 2) {
            second = hNumber.slice(2, hNumber.length)
            parts.push(hNumber.slice(0, 2))
          } else if (hNumber.length % 3 === 1) {
            second = hNumber.slice(1, hNumber.length)
            parts.push(hNumber.slice(0, 1))
          }

          if (points === 1) parts.push(second)
        } else {
          parts.push(second.slice(index * 3 - 3, index * 3))
        }
      }

      hNumber = parts.join('.')
    }

    if (currentNumber.includes('R$')) currentNumber = `${hNumber},${last}`
    else currentNumber = `R$ ${hNumber},${last}`
  }

  return currentNumber
}

import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from '@mui/material'
import { FC, MutableRefObject, useState } from 'react'
import { FormBoxStyle } from '../../../../shared/components'
import {
  AutoCompleteAgrupamento,
  AutoCompleteAssunto,
  AutoCompleteSetor,
  AutoCompleteTipologia,
} from './AutoComplete'

interface ISelecionarSetorProps {
  show: boolean
  onClose: () => void
  sectorRef: MutableRefObject<{ id?: number; name?: string } | undefined>
}

interface ISelecionarAgrupamentoProps {
  show: boolean
  onClose: () => void
  groupRef: MutableRefObject<{ id?: number; name?: string } | undefined>
}

interface ISelecionarAssuntoProps {
  show: boolean
  onClose: () => void
  subjectRef: MutableRefObject<{ id?: number; name?: string } | undefined>
}

interface ISelecionarTipologiaProps {
  show: boolean
  onClose: () => void
  typologyRef: MutableRefObject<{ id?: number; name?: string } | undefined>
}

interface ISelecionarRespostaProps {
  show: boolean
  onClose: () => void
  responseRef: MutableRefObject<number | undefined>
}

export const SelecionarSetor: FC<ISelecionarSetorProps> = ({
  onClose,
  show,
  sectorRef,
}) => {
  const [currentSector, setCurrentSector] = useState<
    { id: number | undefined; name: string | undefined } | undefined
  >(undefined)

  const confirm = () => {
    sectorRef.current = currentSector
    setCurrentSector(undefined)
    onClose()
  }

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        gap={2}
        width="280px"
        display="flex"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        flexDirection="column"
      >
        <Typography variant="h6" textAlign="center">
          Selecione um setor
        </Typography>
        <Divider />
        <Box margin={1}>
          <AutoCompleteSetor
            onChange={(e) => setCurrentSector({ id: e?.id, name: e?.label })}
          />
        </Box>
        <Box display="flex" margin={2} gap={1} flex={1} justifyContent="center">
          <Button
            onClick={confirm}
            disabled={currentSector === undefined}
            disableElevation
            variant="contained"
            sx={{ borderRadius: '50px' }}
          >
            confirmar
          </Button>
          <Button onClick={onClose} sx={{ borderRadius: '50px' }}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export const SelecionarAgrupamento: FC<ISelecionarAgrupamentoProps> = ({
  onClose,
  show,
  groupRef,
}) => {
  const [currentGroup, setCurrentGroup] = useState<
    { id: number | undefined; name: string | undefined } | undefined
  >(undefined)

  const confirm = () => {
    groupRef.current = currentGroup
    setCurrentGroup(undefined)
    onClose()
  }

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        gap={2}
        width="280px"
        display="flex"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        flexDirection="column"
      >
        <Typography variant="h6" textAlign="center">
          Selecione um agrupamento
        </Typography>
        <Divider />
        <Box margin={1}>
          <AutoCompleteAgrupamento
            onChange={(e) => setCurrentGroup({ id: e?.id, name: e?.label })}
          />
        </Box>
        <Box display="flex" margin={2} gap={1} flex={1} justifyContent="center">
          <Button
            onClick={confirm}
            disabled={currentGroup === undefined}
            disableElevation
            variant="contained"
            sx={{ borderRadius: '50px' }}
          >
            confirmar
          </Button>
          <Button onClick={onClose} sx={{ borderRadius: '50px' }}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export const SelecionarAssunto: FC<ISelecionarAssuntoProps> = ({
  onClose,
  show,
  subjectRef,
}) => {
  const [currentSubject, setCurrentSubject] = useState<
    { id: number | undefined; name: string | undefined } | undefined
  >(undefined)

  const confirm = () => {
    subjectRef.current = currentSubject
    setCurrentSubject(undefined)
    onClose()
  }

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        gap={2}
        width="280px"
        display="flex"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        flexDirection="column"
      >
        <Typography variant="h6" textAlign="center">
          Selecione um assunto
        </Typography>
        <Divider />
        <Box margin={1}>
          <AutoCompleteAssunto
            onChange={(e) => setCurrentSubject({ id: e?.id, name: e?.label })}
          />
        </Box>
        <Box display="flex" margin={2} gap={1} flex={1} justifyContent="center">
          <Button
            onClick={confirm}
            disabled={currentSubject === undefined}
            disableElevation
            variant="contained"
            sx={{ borderRadius: '50px' }}
          >
            confirmar
          </Button>
          <Button onClick={onClose} sx={{ borderRadius: '50px' }}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export const SelecionarTipologia: FC<ISelecionarTipologiaProps> = ({
  onClose,
  show,
  typologyRef,
}) => {
  const [currentTypology, setCurrentTypology] = useState<
    { id: number | undefined; name: string | undefined } | undefined
  >(undefined)

  const confirm = () => {
    typologyRef.current = currentTypology
    setCurrentTypology(undefined)
    onClose()
  }

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        gap={2}
        width="280px"
        display="flex"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        flexDirection="column"
      >
        <Typography variant="h6" textAlign="center">
          Selecione uma tipologia
        </Typography>
        <Divider />
        <Box margin={1}>
          <AutoCompleteTipologia
            onChange={(e) => setCurrentTypology({ id: e?.id, name: e?.label })}
          />
        </Box>
        <Box display="flex" margin={2} gap={1} flex={1} justifyContent="center">
          <Button
            onClick={confirm}
            disabled={currentTypology === undefined}
            disableElevation
            variant="contained"
            sx={{ borderRadius: '50px' }}
          >
            confirmar
          </Button>
          <Button onClick={onClose} sx={{ borderRadius: '50px' }}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export const SelecionarResposta: FC<ISelecionarRespostaProps> = ({
  onClose,
  show,
  responseRef,
}) => {
  const [currentResponse, setCurrentResponse] = useState<number | undefined>(
    undefined,
  )

  const confirm = () => {
    responseRef.current = currentResponse
    setCurrentResponse(undefined)
    onClose()
  }

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        gap={2}
        width="280px"
        display="flex"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        flexDirection="column"
      >
        <Typography variant="h6" textAlign="center">
          Selecione um tipo de resposta
        </Typography>
        <Divider />
        <Box margin={1}>
          <FormControl fullWidth size="small">
            <InputLabel id="response-label">Tipo de Resposta</InputLabel>
            <Select
              fullWidth
              size="small"
              required
              value={currentResponse}
              label="Tipo de Resposta"
              labelId="response-label"
              onChange={(e) => setCurrentResponse(Number(e.target.value))}
              sx={{
                borderRadius: '50px',
              }}
            >
              <MenuItem value={1}>E-mail</MenuItem>
              <MenuItem value={2}>Telefone</MenuItem>
              <MenuItem value={3}>Presencialmente</MenuItem>
              <MenuItem value={4}>Carta</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" margin={2} gap={1} flex={1} justifyContent="center">
          <Button
            onClick={confirm}
            disabled={currentResponse === undefined}
            disableElevation
            variant="contained"
            sx={{ borderRadius: '50px' }}
          >
            confirmar
          </Button>
          <Button onClick={onClose} sx={{ borderRadius: '50px' }}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

import { useNavigate, useParams } from 'react-router-dom'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDeDetalhes,
  LegendaSumario,
  NotificacaoError,
} from '../../../shared/components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import { CriarIcone } from './formulario/Formulário'
import { IIcons, getIconById } from '../../../shared/services/api'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'
import { ExpandMore } from '@mui/icons-material'
import {
  IIconHistory,
  getIconHistory,
} from '../../../shared/services/api/histories-services/IconHistoryService'

export const DetalhesIcone: React.FC = () => {
  const navigate = useNavigate()

  const { id = 'new' } = useParams<'id'>()
  const updated = useRef(false)

  const [isLoading, setIsLoading] = useState(true)

  const [data, setData] = useState<IIcons>()
  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)

  const [historyLoading, setHistoryLoading] = useState(true)
  const [history, setHistory] = useState<IIconHistory[]>([])

  const [name, setName] = useState('')

  const handleViewForm = () => {
    setViewForm(!viewForm)
    setCreate(true)
  }

  useEffect(() => {
    if (id !== 'nova' && updated.current) {
      setIsLoading(true)
      setHistoryLoading(true)
      Promise.all([getIconById(Number(id)), getIconHistory(Number(id))])
        .then((values) => {
          updated.current = false
          setIsLoading(false)
          setHistoryLoading(false)
          if (values[0] instanceof Error) {
            NotificacaoError(values[0].message)
          } else {
            setName(values[0].name)
            setData(values[0])
          }

          if (values[1] instanceof Error) {
            NotificacaoError(values[1].message)
          } else {
            setHistory(values[1])
          }
        })
        .catch((error) => {
          NotificacaoError('Erro ao tentar carregar dados')
          console.error(error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, updated.current])

  return (
    <LayoutBaseDePagina
      title={`Ícone ${name}` || 'Detalhes Ícone'}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          textoBotaoNovo="Novo"
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
          aoClicarEmVoltar={() => navigate('/ombudsman/icons')}
          mostrarBotaoApagar={false}
        />
      }
    >
      <Box
        display="flex"
        flex="1"
        margin={2}
        justifyContent="center"
        alignItems="center"
      >
        {data?.icon ? (
          <img src={data?.icon} width="150px" alt="Ícone" />
        ) : (
          <CircularProgress size="100px" />
        )}
      </Box>
      <Box margin={1}>
        {CriarIcone(
          handleViewForm,
          viewForm,
          updated,
          create,
          !create ? data : undefined,
        )}

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario name="Informações" icon="info_outlined" />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} direction="column">
              {isLoading && (
                <Grid item>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{ height: '5px', borderRadius: '50' }}
                  />
                </Grid>
              )}
              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Status: </Typography>
                </Grid>
                <Grid item width="75px">
                  {FormatStatus(Boolean(data?.status), 'Ativo', 'Inativo')}
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Nome: </Typography>
                </Grid>
                <Grid item>
                  <Typography>{data?.name}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Criado em: </Typography>
                </Grid>
                <Grid item>
                  <Typography>{FormatDateTime(data?.created_at)}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Última atualização em: </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {data?.created_at === data?.updated_at
                      ? 'Não houve alterações'
                      : FormatDateTime(data?.updated_at)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario name="Histórico" icon="restore" />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item spacing={2} direction="column">
              {historyLoading && (
                <Grid item>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{ height: '5px', borderRadius: '50px' }}
                  />
                </Grid>
              )}

              {!!history && (
                <Grid container item spacing={2} direction="column">
                  {history.map((query) => (
                    <>
                      <Divider variant="middle" />
                      {query.type === '~' ? (
                        <Grid container item>
                          {query.field === 'name' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Nome alterado de ({query.old}) para (
                                  {query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data: {FormatDateTime(query.date)}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'icon' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>Imagem alterada</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterada por: {query.user}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data: {FormatDateTime(query.date)}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'status' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  {query.old
                                    ? 'Ícone desativado'
                                    : 'Ícone ativado'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  {query.old
                                    ? `Desativado por ${query.user}`
                                    : `Ativado por ${query.user}`}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data: {FormatDateTime(query.date)}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      ) : (
                        <Grid container item direction="column">
                          <Grid item>
                            <Typography>Ícone criado</Typography>
                          </Grid>
                          <Grid item>
                            <Typography>Criado por: {query.user}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography>
                              Criado em: {FormatDateTime(query.date)}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </LayoutBaseDePagina>
  )
}

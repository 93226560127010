import { Schema } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'

export interface IFormData {
  text: string
  title: string
  status: boolean
  customer_service: number
}

export const formValidationSchema: Schema<IFormData> = yup.object().shape({
  customer_service: yup.number().positive().required(),
  text: yup.string().min(6).max(50000).required(),
  title: yup.string().min(3).max(255).required(),
  status: yup.boolean().default(true),
})

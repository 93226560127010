import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import { Environment } from '../../../../../shared/environment'
import { ExpandMore, PowerSettingsNew } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { Api } from '../../../../../shared/services/api/axios-config'
import {
  FerramentasDeDetalhes,
  LegendaSumario,
  NotificacaoError,
} from '../../../../../shared/components'
import { SplashScreen } from '../../components/TelaLoading'
import {
  SacProtocol,
  getSacProtocolByIdExternal,
} from '../../../../../shared/services/api/sac-services'
import {
  FormatDate,
  FormatDateTime,
  FormatProtocolStatus,
} from '../../../../../shared/utils'
import { ConteudoSolicitacao } from '../../../../ombudsman/protocolos/components/ConteudoManifestacao'
import { DadosSolicitacao } from '../../../../sac/protocolos/components/display-data'
import { Respostas } from './Respostas'

interface SacData {
  id: number
  status: boolean
  sac_slug: string
  sac_logo: string
  sac_title: string
  institution: number
}

export const DetalhesProtocoloSACEterno: FC = () => {
  const navigate = useNavigate()
  const repliesUpdate = useRef(true)

  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const { slug } = useParams<'slug'>()
  const { id } = useParams<'id'>()

  const [customerServiceData, setCustomerServiceData] = useState<SacData>()
  const [protocolData, setProtocolData] = useState<SacProtocol>()
  const [protocolLoading, setProtocolLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [userId] = useState(sessionStorage.getItem('EXTERNAL_ID'))
  const [name] = useState(sessionStorage.getItem('EXTERNAL_NAME'))
  const [token] = useState(sessionStorage.getItem('EXTERNAL_TOKEN'))

  const handleLogout = () => {
    sessionStorage.clear()
    navigate(`/sac/${slug}/login`)
  }

  useEffect(() => {
    setIsLoading(true)
    if (slug) {
      if (!(id && name && token)) {
        sessionStorage.clear()
        NotificacaoError('Faça login')
        navigate(`/sac/${slug}/login`)
      } else {
        Api.get(`/V1/customer_service/externalusers/access/${slug}/`)
          .then((res) => {
            setCustomerServiceData(res.data)
          })
          .catch(handleLogout)
          .finally(() => setIsLoading(false))
      }
    }
  }, [userId, name, token, slug])

  useEffect(() => {
    if (id) {
      setProtocolLoading(true)
      getSacProtocolByIdExternal(id)
        .then((result) => {
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            setProtocolData(result)
          }
        })
        .finally(() => setProtocolLoading(false))
    }
  }, [id, userId, name, token])

  return isLoading ? (
    <SplashScreen errorMessage="" loading={isLoading || !customerServiceData} />
  ) : (
    <Box
      height="100%"
      width="100%"
      display={'flex'}
      flexDirection="column"
      gap={1}
      overflow="hidden"
    >
      <Box
        boxSizing="border-box"
        alignItems="center"
        display="flex"
        width="100vw"
        height="85px"
        component={Paper}
        borderRadius={0}
        px={1}
        gap={1}
      >
        <img
          src={
            process.env.NODE_ENV === 'production'
              ? customerServiceData?.sac_logo
              : `${Environment.URL_BASE}${`${customerServiceData?.sac_logo}`}`
          }
          height="80px"
          alt={customerServiceData?.sac_title}
        />
        <Typography
          textAlign="center"
          variant="h1"
          fontSize={24}
          fontWeight="500"
        >
          {customerServiceData?.sac_title}
        </Typography>
        <Box display="flex" flex="1" justifyContent="end">
          <Button
            variant="outlined"
            startIcon={<PowerSettingsNew />}
            onClick={handleLogout}
          >
            sair
          </Button>
        </Box>
      </Box>

      <Box overflow="auto">
        <Typography
          marginLeft={1}
          variant="h2"
          fontWeight="400"
          fontSize={smDown ? '20px' : mdDown ? '24px' : '34px'}
        >
          {protocolData?.identifier}
        </Typography>

        <FerramentasDeDetalhes
          mostrarBotaoEditar={false}
          aoClicarEmNovo={() => navigate(`/sac/${slug}/inicio/?tab=0`)}
        />

        <Box margin={1}>
          {protocolData ? (
            <Tooltip title="status">
              <Typography width="100px">
                {FormatProtocolStatus(
                  Number(protocolData?.status),
                  false,
                  theme.spacing(6),
                  '10px',
                  18,
                )}
              </Typography>
            </Tooltip>
          ) : (
            <Skeleton width={100} height={48} />
          )}

          {protocolLoading && <LinearProgress sx={{ marginY: 2 }} />}

          <div
            style={{
              display: 'grid',
              marginRight: '8px',
              gridTemplateColumns: xsDown
                ? '100%'
                : smDown
                ? '50% 50%'
                : '66.6% 33.4%',
              gridTemplateRows: 'fit-content',
              columnGap: '8px',
            }}
          >
            {/* Primeira Coluna */}
            <Grid container direction="column" mt={1}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  {/* Informações */}
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Grid container spacing={1} direction="column">
                        <Grid container item spacing={1}>
                          <Grid item xs={12}>
                            <LegendaSumario
                              icon="toc"
                              name="Informações do protocolo"
                            />
                          </Grid>
                          <Grid container item spacing={1} direction="row">
                            <Grid item>
                              <Typography>Criado: </Typography>
                            </Grid>
                            <Grid item>
                              <Typography>
                                {FormatDateTime(
                                  protocolData?.created_at,
                                  'Indefinido',
                                )}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Divider orientation="vertical" />
                            </Grid>
                            <Grid item>
                              <Typography>Prazo: </Typography>
                            </Grid>
                            <Grid item>
                              <Typography>
                                {FormatDate(
                                  protocolData?.expires_at,
                                  'Indefinido',
                                )}
                              </Typography>
                            </Grid>
                            {Number(protocolData?.status) > 4 && (
                              <Grid item>
                                <Divider orientation="vertical" />
                              </Grid>
                            )}
                            {Number(protocolData?.status) > 4 && (
                              <Grid container item spacing={1}>
                                <Grid item>
                                  <Typography>Finalizado:</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    {FormatDateTime(
                                      protocolData?.finished_at,
                                      'Indefinido',
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ConteudoSolicitacao protocolData={protocolData} />
                    </AccordionDetails>
                  </Accordion>

                  {/* Respostas */}
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <LegendaSumario
                        icon="message_outlined"
                        name="Respostas"
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Respostas
                        sendDisabled={isLoading}
                        moduleId={customerServiceData?.id}
                        secondUpdate={repliesUpdate}
                        protocolId={id ? Number(id) : undefined}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>

            {/* Segunda Coluna */}
            <Grid container direction="column" mt={1}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  {/* Dados */}
                  <Accordion defaultExpanded sx={{ marginBottom: 2 }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Grid container spacing={1} direction="column">
                        <Grid container item spacing={1}>
                          <Grid item xs={12}>
                            <LegendaSumario icon="toc" name="Dados" />
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DadosSolicitacao
                        external
                        protocolData={protocolData}
                        setProtocolData={setProtocolData}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Box>
    </Box>
  )
}

import { FC, useState } from 'react'
import {
  Modal,
  ModalHeader,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../../shared/components'
import { Box, Button, LinearProgress } from '@mui/material'
import { VForm, VTextArea, useVForm } from '../../../../../shared/forms'
import { Cancel, Save } from '@mui/icons-material'
import {
  SacProtocol,
  extendSacProtocol,
} from '../../../../../shared/services/api/sac-services'

interface ProrrogarModalProps {
  setprotocolData: (data: SacProtocol) => void
  onClose: () => void
  protocolId: number
  show: boolean
}

export const ProrrogarModal: FC<ProrrogarModalProps> = ({
  setprotocolData,
  protocolId,
  onClose,
  show,
}) => {
  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (formData: { justify: string }) => {
    setIsLoading(true)
    extendSacProtocol(protocolId, formData)
      .then((result) => {
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          NotificacaoSucesso('Protocolo prorrogado com sucesso!')
          setprotocolData(result)
          handleClose()
        }
      })
      .finally(() => setIsLoading(false))
  }

  const handleClose = () => {
    formRef.current?.reset()
    onClose()
  }

  return (
    <Modal onClose={handleClose} show={show}>
      <ModalHeader title="Prorrogar" onClose={handleClose} showCloseButton />
      <Box margin={1}>
        {isLoading && <LinearProgress sx={{ my: 2 }} />}
        <VForm onSubmit={handleSubmit}>
          <VTextArea
            ariaLabel="Justificativa"
            maxRows={20}
            minRows={8}
            name="justify"
            placeholder="Justifique o motivo da prorrogação de prazo * Essa justificativa é visível para o manifestante *"
            style={{
              width: '95%',
              borderRadius: '5px',
              fontVariant: 'historical-forms',
              font: 'message-box',
              paddingInline: '8px',
            }}
          />
          <Box
            mt={2}
            display="flex"
            flex="1"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <Button
              type="submit"
              disableElevation
              variant="contained"
              disabled={isLoading}
              startIcon={<Save />}
            >
              salvar
            </Button>
            <Button
              startIcon={<Cancel />}
              variant="outlined"
              onClick={handleClose}
            >
              cancelar
            </Button>
          </Box>
        </VForm>
      </Box>
    </Modal>
  )
}

import { useEffect, useState, useMemo } from 'react'
import {
  Box,
  CardContent,
  Card,
  LinearProgress,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  Divider,
  Button,
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { Api } from '../../shared/services/api/axios-config'
import { NotificacaoError, NotificacaoSucesso } from '../../shared/components'
import { VForm, VTextField, useVForm } from '../../shared/forms'
import { formatToCPFOrCNPJ, isCPFOrCNPJ } from 'brazilian-values'
import * as yup from 'yup'

import logo from '../../images/default_logo.png'
import { HelpTwoTone } from '@mui/icons-material'

interface IFormData {
  document_number: string
  password: string
  re_password: string
}

const fomrValidationSchema: yup.Schema<IFormData> = yup.object({
  document_number: yup
    .string()
    .required()
    .test('cpf/cnpj-tests', 'CPF/CNPJ Inválido', (value) =>
      isCPFOrCNPJ(String(value)),
    ),
  password: yup.string().required().min(6),
  re_password: yup.string().required().min(6),
})

export const ConfirmarUsuario: React.FC = () => {
  const { formRef } = useVForm()

  const [documentOpen, setDocumentOpen] = useState(false)
  const [passwordOpen, setPasswordOpen] = useState(false)
  const [repasswordOpen, setRepasswordOpen] = useState(false)

  const [backLogin, setBackLogin] = useState(false)

  const [status, setStatus] = useState<number>()
  const [isLoading, setIsLoading] = useState(true)

  const [searchParams, setSearchParams] = useSearchParams()

  const token = useMemo(() => {
    return searchParams.get('token') || ''
  }, [searchParams])

  const getUser = async () => {
    const response = await Api.get(`/V1/api/users/confirmation/${token}`)
    setStatus(response.status)
  }

  useEffect(() => {
    setSearchParams({ token }, { replace: true })
    getUser()
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const handleSumit = (formData: IFormData) => {
    setIsLoading(false)
    fomrValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData) => {
        if (!(validatedData.password === validatedData.re_password)) {
          formRef.current?.setErrors({ password: 'As senhas não coincidem' })
        } else {
          Api.post(`/V1/api/users/confirmation/${token}`, validatedData)
            .then((result) => {
              setIsLoading(false)
              NotificacaoSucesso((result.data as { message: string }).message)
              setBackLogin(true)
            })
            .catch((error) => {
              setIsLoading(false)
              console.log(error)
              NotificacaoError(error.message)
            })
        }
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false)
        const validationErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validationErrors[error.path] = error.message
        })
        formRef.current?.setErrors(validationErrors)
      })
  }

  return (
    <Box
      display="flex"
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Card>
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            width={320}
            overflow="hidden"
          >
            {isLoading && (
              <Box padding={2} gap={2}>
                <LinearProgress
                  variant="indeterminate"
                  sx={{ borderRadius: '50px', height: '5px' }}
                />
              </Box>
            )}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box paddingBottom={1}>
                <img src={logo} width={280} alt="logo" />
              </Box>
              <VForm ref={formRef} onSubmit={handleSumit}>
                <Divider />
                <Grid
                  container
                  padding={2}
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography>
                      {backLogin
                        ? 'Usuário Confirmado'
                        : status !== 200 && !isLoading
                        ? 'Nenhum usuário encontado'
                        : 'Confirmar Usuário'}
                    </Typography>
                  </Grid>
                  <Grid container item display="flex" spacing={1} xs={12}>
                    <Grid item>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="document_number"
                        label="CPF / CNPJ"
                        formatData={formatToCPFOrCNPJ}
                        disabled={
                          isLoading ||
                          (status !== 200 && !isLoading) ||
                          backLogin
                        }
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                        inputProps={{
                          maxLength: 18,
                        }}
                      />
                    </Grid>
                    <Grid item display="flex">
                      <Tooltip
                        title="Insira o número de documento do usuário cadastrado"
                        open={documentOpen}
                      >
                        <IconButton
                          onMouseEnter={() => setDocumentOpen(true)}
                          onMouseLeave={() => setDocumentOpen(false)}
                          onClick={() => setDocumentOpen(!documentOpen)}
                        >
                          <HelpTwoTone />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container item display="flex" spacing={1} xs={12}>
                    <Grid item>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="password"
                        type="password"
                        label="senha"
                        disabled={
                          isLoading ||
                          (status !== 200 && !isLoading) ||
                          backLogin
                        }
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                      />
                    </Grid>
                    <Grid item display="flex">
                      <Tooltip title="Insira a nova senha" open={passwordOpen}>
                        <IconButton
                          onMouseEnter={() => setPasswordOpen(true)}
                          onMouseLeave={() => setPasswordOpen(false)}
                          onClick={() => setPasswordOpen(!documentOpen)}
                        >
                          <HelpTwoTone />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container item display="flex" spacing={1} xs={12}>
                    <Grid item>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="re_password"
                        type="password"
                        placeholder="repita a senha"
                        disabled={
                          isLoading ||
                          (status !== 200 && !isLoading) ||
                          backLogin
                        }
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                      />
                    </Grid>
                    <Grid item display="flex">
                      <Tooltip
                        title="Digite novamente a senha"
                        open={repasswordOpen}
                      >
                        <IconButton
                          onMouseEnter={() => setRepasswordOpen(true)}
                          onMouseLeave={() => setRepasswordOpen(false)}
                          onClick={() => setRepasswordOpen(!documentOpen)}
                        >
                          <HelpTwoTone />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Box flex="1" display="flex" justifyContent="center" gap={1}>
                  <Button
                    type="submit"
                    variant="outlined"
                    sx={{ borderRadius: '50px' }}
                    disabled={
                      isLoading || (status !== 200 && !isLoading) || backLogin
                    }
                  >
                    Confirmar
                  </Button>
                  {backLogin && (
                    <Button onClick={() => window.location.replace('/admin')}>
                      Ir para login
                    </Button>
                  )}
                </Box>
              </VForm>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

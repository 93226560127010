import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Divider,
  FormControl,
  Box,
  useTheme,
  useMediaQuery,
  Icon,
  IconButton,
  Select,
  MenuItem,
  Typography,
  Tooltip,
} from '@mui/material'

import {
  useAppThemeContext,
  useAuthContext,
  useDrawerContext,
} from '../contexts'
import { AutoComplete } from './AutoComplete'
import {
  ConnectWithoutContact,
  MapsUgc,
  Notifications,
  NotificationsActive,
  NotificationsOff,
  SupportAgent,
} from '@mui/icons-material'
import { MenuBadge, NotifiesBadge } from '../components'
import NotifiesDropdown from './components/Dropdown'
import { useModuleContext } from '../contexts/ModulesContext'

interface ILayoutBaseDePaginaProps {
  children: React.ReactNode
  title: string | React.ReactNode
  selectInstitution?: boolean
  barraDeFerramentas?: React.ReactNode
  filter?: React.ReactNode
  id?: string
  autoCompleteChange?: (e: number | undefined) => void
}

export const LayoutBaseDePagina: React.FC<ILayoutBaseDePaginaProps> = ({
  children,
  title: pageTitle,
  id,
  filter,
  barraDeFerramentas,
  selectInstitution = false,
  autoCompleteChange,
}) => {
  const { user } = useAuthContext()

  const navigate = useNavigate()
  const { setModule, setTitle, currentModule, moduleTitle } = useModuleContext()
  const { themeName } = useAppThemeContext()
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const { toggleDrawerOpen, unreadNotifies, openNotifies, toggleOpenNotifies } =
    useDrawerContext()

  const [alertBell, setAlertBell] = useState(false)
  const [total, setTotal] = useState(0)

  const { module } = useParams<'module'>()
  const { title } = useParams<'title'>()
  const path = useLocation()

  useEffect(() => {
    setModule(path.pathname.split('/')[1] || '')
    setTitle(title || '')
  }, [module, path, setModule, setTitle, title])

  useEffect(() => {
    if (unreadNotifies === 0) {
      setAlertBell(false)
    } else if (unreadNotifies > total) {
      setAlertBell(true)
      setTotal(unreadNotifies)
    } else {
      setTotal(unreadNotifies)
    }
  }, [total, unreadNotifies])

  return (
    <Box
      id={id || 'layout-box'}
      height="100%"
      width="100%"
      display={'flex'}
      flexDirection="column"
      gap={1}
      overflow="hidden"
    >
      <Box
        padding={1}
        display="flex"
        alignItems="center"
        height={theme.spacing(smDown ? 6 : mdDown ? 8 : 12)}
      >
        {smDown && !xsDown ? (
          <IconButton onClick={toggleDrawerOpen}>
            <Icon>menu</Icon>
          </IconButton>
        ) : xsDown ? (
          <MenuBadge badgeContent={unreadNotifies} color="primary" max={99}>
            <IconButton onClick={toggleDrawerOpen}>
              <Icon>menu</Icon>
            </IconButton>
          </MenuBadge>
        ) : (
          <></>
        )}
        <Box marginLeft={2} width="100%" flexDirection="row" display="flex">
          <img
            width={
              smDown
                ? theme.spacing(20)
                : mdDown
                ? theme.spacing(25)
                : theme.spacing(30)
            }
            src={
              user?.modules.find(
                (module) =>
                  module.type === currentModule && module.title === moduleTitle,
              )?.logo
            }
            alt="logo"
          />
          {!smDown && (
            <Box
              display="flex"
              flex="1"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Typography
                fontWeight="bold"
                textTransform="uppercase"
                color="dodgerblue"
                variant="h6"
                fontSize={mdDown ? 25 : lgDown ? 30 : 35}
              >
                {currentModule === 'ombudsman'
                  ? 'Ouvidoria'
                  : currentModule === 'ci'
                  ? 'Com. Interna'
                  : 'S.A.C'}{' '}
                - {moduleTitle}
              </Typography>
            </Box>
          )}
          <Box
            display="flex"
            alignItems="center"
            alignContent="right"
            flex={1}
            paddingLeft={theme.spacing(1)}
          >
            <FormControl sx={{ width: '100%' }}>
              <Box display="flex" width="100%" justifyContent="right" gap={1}>
                {!xsDown && (
                  <>
                    <Tooltip
                      title={
                        openNotifies
                          ? 'Fechar notificações'
                          : 'Abrir notificações'
                      }
                    >
                      <IconButton
                        size="large"
                        onClick={() => toggleOpenNotifies()}
                      >
                        <NotifiesBadge
                          badgeContent={unreadNotifies}
                          color="primary"
                          max={99}
                        >
                          {openNotifies ? (
                            <NotificationsOff color="action" />
                          ) : alertBell ? (
                            <NotificationsActive color="action" />
                          ) : (
                            <Notifications color="action" />
                          )}
                        </NotifiesBadge>
                      </IconButton>
                    </Tooltip>

                    <NotifiesDropdown open={openNotifies} />
                  </>
                )}
                <Select
                  sx={{
                    maxWidth: xsDown ? '115px' : undefined,
                    textOverflow: 'ellipsis',
                    borderRadius: theme.spacing(4),
                  }}
                  size="small"
                  id="module-select"
                  value={`${currentModule} | ${moduleTitle}` || 'ombudsman'}
                  onChange={(e) => {
                    navigate(
                      `/${e.target.value.split(' | ')[0]}/${
                        e.target.value.split(' | ')[1]
                      }/dashboard`,
                    )
                  }}
                >
                  {user?.modules.map((module, index) => (
                    <MenuItem
                      key={index}
                      value={`${module.type} | ${module.title}`}
                    >
                      <Tooltip
                        placement="left"
                        draggable
                        title={
                          module.type === 'ci'
                            ? 'Com. Interna'
                            : module.type === 'ombudsman'
                            ? 'Ouvidoria'
                            : 'S.A.C'
                        }
                      >
                        <Box display="flex" gap={1} alignItems="center">
                          {module.type === 'ci' ? (
                            <ConnectWithoutContact />
                          ) : module.type === 'ombudsman' ? (
                            <SupportAgent />
                          ) : (
                            <MapsUgc />
                          )}
                          <Typography fontWeight="bold">
                            {module.title}
                          </Typography>
                        </Box>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Divider />

      {selectInstitution && user?.modules[0].profile === 0 && (
        <Box margin={1.5}>
          <AutoComplete
            textStyle={{ fieldSet: { borderRadius: '50px' } }}
            onChange={autoCompleteChange}
          />
        </Box>
      )}

      <Typography
        marginLeft={2}
        variant={smDown ? 'h6' : mdDown ? 'h5' : 'h4'}
        overflow="hidden"
        component="strong"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        color={themeName === 'dark' ? '#f7f6f3' : '#202124'}
      >
        {pageTitle}
      </Typography>
      {barraDeFerramentas && <Box>{barraDeFerramentas}</Box>}

      {filter && <Box>{filter}</Box>}

      <Box flex={1} overflow="auto">
        {children}
      </Box>
    </Box>
  )
}

import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { LayoutusuarioExterno } from '../../shared/layouts'
import { toast } from 'react-toastify'
import { publicIpv4 } from 'public-ip'
import { useExternalAuthContext } from '../../shared/contexts'
import { AcompanharManifestacoes } from './protocolo/Manifestacoes'
import { Perfil } from './protocolo/formularios/Perfil'
import { FormProtocolo } from './protocolo/formularios/Fomulario'
import { TabPanel, VTab, VTabs, a11yProps } from '../../shared/components'

export const Home = () => {
  const { slug } = useParams<'slug'>()
  const navigate = useNavigate()
  const [clientIp, setClientIp] = useState('')

  const [tab, setTab] = useState(0)

  const [searchParams, setSearchParams] = useSearchParams()

  const tabParam = useMemo(() => {
    return searchParams.get('tab') || '0'
  }, [searchParams])

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || ''
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || ''
  }, [searchParams])

  const status = useMemo(() => {
    return searchParams.get('status') || ''
  }, [searchParams])

  const { isAuthenticated } = useExternalAuthContext()

  const handleChange = (newValue: number) => {
    setSearchParams(
      {
        tab: String(newValue),
        busca,
        status,
        item,
        total,
      },
      { replace: true },
    )
  }

  useEffect(() => {
    publicIpv4()
      .then((result) => {
        setClientIp(result)
      })
      .catch(() =>
        toast.warning(
          'Atenção! Não foi possível identificar o IP Externo que você está conectado',
        ),
      )
  }, [])

  useEffect(() => {
    const token = sessionStorage.getItem('EXTERNAL_TOKEN')
    if (!token) {
      navigate(`/ouvidoria/${slug}/login`)
    }
  }, [isAuthenticated, navigate, slug])

  useEffect(() => {
    setTab(Number(tabParam || 0))
  }, [searchParams, tabParam])

  return (
    <LayoutusuarioExterno>
      <Box width="100%" height="100%">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <VTabs
            value={tab}
            onChange={(_, newValue) => handleChange(newValue)}
            aria-label="tabs-home"
          >
            <VTab
              label="Nova Manifestação"
              {...a11yProps(0)}
              sx={{ width: '33.3%' }}
            />
            <VTab
              wrapped
              label="Acompanhar Manifestações"
              {...a11yProps(1)}
              sx={{ width: '33.3%', marginLeft: 'auto', marginRight: 'auto' }}
            />
            <VTab label="Perfil" {...a11yProps(2)} sx={{ width: '33.3%' }} />
          </VTabs>
        </Box>
        <TabPanel value={Number(tab)} index={0}>
          <FormProtocolo clientIp={clientIp} />
        </TabPanel>
        <TabPanel value={Number(tab)} index={1}>
          <AcompanharManifestacoes />
        </TabPanel>
        <TabPanel value={Number(tab)} index={2}>
          <Perfil />
        </TabPanel>
      </Box>
    </LayoutusuarioExterno>
  )
}

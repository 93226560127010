import { useField } from '@unform/core'
import { FC, useEffect } from 'react'

interface VHidenInputProps {
  name: string
  value: string
  setValue?(e: string): void
}

export const VHiddenInput: FC<VHidenInputProps> = ({
  name,
  value,
  setValue,
}) => {
  const { fieldName, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newvalue) => setValue?.(newvalue),
    })
  }, [value, fieldName, registerField, setValue])

  return (
    <input
      hidden
      type="text"
      value={value}
      onChange={(e) => setValue?.(e.target.value)}
    />
  )
}

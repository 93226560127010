export function FormatDateTime(
  value: string | undefined,
  nullMessage?: string,
) {
  let date = ''
  let time = ''
  let day = ''
  let month = ''
  let year = ''
  let hour = ''
  let minute = ''

  if (value) {
    date = value.split('T')[0]
    day = date.split('-')[2]
    month = date.split('-')[1]
    year = date.split('-')[0]

    time = value.split('T')[1]
    hour = time.split(':')[0]
    minute = time.split(':')[1]

    return String(`${day}/${month}/${year} às ${hour}:${minute}`)
  } else if (nullMessage !== undefined) return nullMessage
  else return 'Não definido'
}

import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useField } from '@unform/core'
import { FC, useEffect, useState } from 'react'
import { getAllSacSubjectsExternal } from '../../../../../../shared/services/api/sac-services'
import { NotificacaoError } from '../../../../../../shared/components'
import { useDebounce } from '../../../../../../shared/hooks'

interface AutocompleteSacSubjectProps {
  sector: number | undefined
}

type AutoCompleteOption = {
  id: number
  label: string
}

export const AutocompleteSacSubject: FC<AutocompleteSacSubjectProps> = ({
  sector,
}) => {
  const { debounce } = useDebounce(1000)
  const { fieldName, registerField, error, clearError } = useField('subject')

  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<AutoCompleteOption[]>([])
  const [search, setSearch] = useState('')
  const [value, setValue] = useState<number>()
  const [selectedOption, setSelectedOption] =
    useState<AutoCompleteOption | null>(null)

  useEffect(() => {
    setIsLoading(true)
    if (sector) {
      debounce(() => {
        getAllSacSubjectsExternal(sector, search)
          .then((result) => {
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              setOptions(
                result.map((sector) => ({ id: sector.id, label: sector.name })),
              )
            }
          })
          .finally(() => setIsLoading(false))
      })
    } else {
      setIsLoading(false)
    }
  }, [sector, search])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [value, fieldName, registerField])

  return (
    <Autocomplete
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      loading={isLoading}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      noOptionsText={
        sector ? 'Nenhum assunto encontrado' : 'Selecione um setor'
      }
      inputValue={search}
      value={selectedOption}
      options={sector ? options : []}
      onChange={(e, newValue) => {
        setSearch('')
        setSelectedOption(newValue || null)
        setValue(newValue?.id)
        clearError()
      }}
      onInputChange={(e, newValue) => {
        setSearch(newValue)
        clearError()
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          fullWidth
          size="small"
          label="Assunto"
          error={!!error}
          helperText={error}
          sx={{ fieldSet: { borderRadius: '50px' } }}
        />
      )}
    />
  )
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC } from 'react'
import {
  Modal,
  Box,
  Paper,
  Button,
  useTheme,
  Grid,
  FormControlLabel,
  useMediaQuery,
} from '@mui/material'

import {
  SacCategory,
  createSacCategory,
  updateSacCategory,
} from '../../../../shared/services/api/sac-services'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { ModalHeader } from '../../../../shared/components/modal/Header'
import { useAuthContext } from '../../../../shared/contexts'
import { VForm, VSwitch, VTextField, useVForm } from '../../../../shared/forms'
import { Cancel, Save } from '@mui/icons-material'
import { IFormData, formValidationSchema } from './validate'
import { AutocompleteSac } from '../components/AutocompleteSacs'
import { ValidationError } from 'yup'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'

interface FormualrioProps {
  show: boolean
  create: boolean
  onClose(): void
  data?: SacCategory
  institution: number
  update: React.MutableRefObject<boolean>
}

export const Formulario: FC<FormualrioProps> = ({
  create,
  onClose,
  show,
  update,
  data,
  institution,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { thisModule } = useModuleContext()
  const { user } = useAuthContext()
  const { formRef } = useVForm()

  function handleClose() {
    formRef.current?.reset()
    onClose()
  }

  function handleSubmit(formData: IFormData) {
    if (create && user?.modules[0].profile === 0) {
      formData.institution = institution
    } else if (create) {
      formData.institution = Number(user!.institution_id)
      formData.customer_service = thisModule!.id
    } else {
      formData.institution = data!.institution
      formData.customer_service = data!.customer_service.id
    }

    formValidationSchema
      .validate(formData, { abortEarly: true })
      .then((validatedData) => {
        if (create) {
          createSacCategory(validatedData).then((result) => {
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              NotificacaoSucesso('Categoria criada com sucess')
              update.current = true
              handleClose()
            }
          })
        } else {
          updateSacCategory(data!.id, validatedData).then((result) => {
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              NotificacaoSucesso('Categoria atualizada com sucesso')
              update.current = true
              handleClose()
            }
          })
        }
      })
      .catch((errors: ValidationError) => {
        const validationErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validationErrors[error.path] = error.message
        })
        formRef.current?.setErrors(validationErrors)
      })
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader
          showCloseButton
          title={create ? 'Nova categoria' : 'Editar categoria'}
        />

        <Box margin={1}>
          <VForm
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={
              create
                ? {
                    customer_service: data?.customer_service.id,
                  }
                : {
                    ...data,
                    customer_service: data?.customer_service.id,
                  }
            }
          >
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <VTextField
                    required
                    fullWidth
                    name="name"
                    size="small"
                    label="Nome"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
                {user?.modules[0].profile === 0 && (
                  <Grid item xs={12}>
                    <AutocompleteSac
                      name="customer_service"
                      institution={
                        user?.modules[0].profile === 0
                          ? create
                            ? institution
                            : data!.institution
                          : create
                          ? user!.institution_id
                          : data!.institution
                      }
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormControlLabel
                    label="Status"
                    control={<VSwitch name="status" size="small" />}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
              mt={2}
            >
              <Button
                disableElevation
                variant="contained"
                type="submit"
                startIcon={<Save />}
              >
                salvar
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                startIcon={<Cancel />}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

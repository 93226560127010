import { FC, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material'

import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  SacSectorList,
  getSacSectorById,
} from '../../../shared/services/api/sac-services/setores-services'
import {
  FerramentasDeDetalhes,
  LegendaSumario,
  NotificacaoError,
} from '../../../shared/components'
import { ExpandMore } from '@mui/icons-material'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'
import { Formulario } from './formulario/Formulario'

export const DetalhesSetoresSAC: FC = () => {
  const update = useRef(true)
  const navigate = useNavigate()
  const { id } = useParams<'id'>()

  const [create, setCreate] = useState(true)
  const [viewForm, setViewForm] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<SacSectorList>()

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getSacSectorById(id).then((result) => {
        setIsLoading(false)
        update.current = false
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setData(result)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, update.current])

  return (
    <LayoutBaseDePagina
      title={data ? data.name : 'carregando dados...'}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          mostrarBotaoVoltar
          mostrarBotaoEditar
          mostrarBotaoNovo
          aoClicarEmVoltar={() => navigate(-1)}
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
        />
      }
    >
      <Box margin={1}>
        {isLoading && <LinearProgress />}

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario icon="info_outlined" name="Informações" />
          </AccordionSummary>
          <AccordionDetails>
            <Box flex="1">
              <Grid container direction="column" spacing={2}>
                <Grid container item spacing={1}>
                  <Grid item>
                    <Typography>Status: </Typography>
                  </Grid>
                  <Grid item width="70px">
                    {FormatStatus(data?.status || false)}
                  </Grid>
                </Grid>
                <Grid container item spacing={1}>
                  <Grid item>
                    <Typography>Nome: </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{data?.name}</Typography>
                  </Grid>
                </Grid>

                <Grid container item spacing={1}>
                  <Grid item>
                    <Typography>Agrupamento: </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {data?.grouping.name || 'indefinido'}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item spacing={1}>
                  <Grid item>
                    <Typography>Criado em: </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{FormatDateTime(data?.created_at)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      {data && (
        <Formulario
          show={viewForm}
          create={create}
          update={update}
          institution={data.institution}
          data={create ? undefined : data}
          onClose={() => setViewForm(false)}
        />
      )}
    </LayoutBaseDePagina>
  )
}

import { MutableRefObject, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'
import { IProtocols } from '../../../shared/services/api'
import { useAuthContext } from '../../../shared/contexts'
import { VForm, VTextArea } from '../../../shared/forms'
import {
  AutoCompleteAgrupamento,
  AutoCompleteSetor,
} from '../../usuario-externo/components/AutoComplete'
import { Api } from '../../../shared/services/api/axios-config'

interface ITransferirModalProps {
  show: boolean
  onClose: () => void
  protocolData: IProtocols | undefined
  update: MutableRefObject<boolean>
}

interface IFormdata {
  protocol_id: number
  grouping_id: number
  sector_id: number
}

export const TransferirModal: React.FC<ITransferirModalProps> = ({
  onClose,
  show,
  protocolData,
  update,
}) => {
  const { user } = useAuthContext()

  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const [group, setGroup] = useState<number>()

  const handleTransfer = (formData: IFormdata) => {
    formData.protocol_id = Number(protocolData?.id)
    if (formData.sector_id === protocolData?.sector_id?.id) {
      return NotificacaoError('Não é possível transferir para o mesmo setor')
    }
    Api.post(`/V1/protocols/${protocolData?.id}/transfersector/`, formData)
      .then(() => {
        NotificacaoSucesso('Agrupamento e setor alterados com sucesso')
        update.current = true
        onClose()
      })
      .catch((error) => NotificacaoError(error.message))
  }

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Box margin={2} display="flex" width="100%" flexDirection="column">
          <Typography variant="h6" textAlign="center">
            Transferência de setor
          </Typography>
          <Divider />
          <Box margin={2}>
            <VForm onSubmit={handleTransfer}>
              <Grid container direction="column" spacing={2}>
                <Grid container item spacing={1}>
                  <Grid item xs={12}>
                    <AutoCompleteAgrupamento
                      institutionId={String(protocolData?.institution_id)}
                      textStyle={{
                        fieldSet: {
                          borderRadius: '50px',
                        },
                      }}
                      onChange={(e) => setGroup(e)}
                    />
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item xs={12}>
                    <AutoCompleteSetor
                      external={false}
                      institutionId={Number(user?.institution_id)}
                      groupingId={group}
                    />
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item display="flex" xs={12} justifyContent="center">
                    <VTextArea
                      name="justify"
                      minRows={4}
                      maxRows={8}
                      required
                      placeholder=" Justifique o motivo da transferência
  * Essa justificativa é visível para o manifestante
                      "
                      ariaLabel="Descrição"
                      style={{
                        width: '95%',
                        borderRadius: '5px',
                        fontVariant: 'historical-forms',
                        font: 'message-box',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Box
                display="flex"
                width="100%"
                justifyContent="right"
                marginTop={2}
                gap={1}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  sx={{
                    borderRadius: '50px',
                  }}
                >
                  alterar
                </Button>
                <Button
                  onClick={() => {
                    onClose()
                    setGroup(undefined)
                  }}
                  sx={{
                    borderRadius: '50px',
                  }}
                >
                  cancelar
                </Button>
              </Box>
            </VForm>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

import { FC, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Card,
  Button,
  useTheme,
  TextField,
  Typography,
  CardContent,
  useMediaQuery,
  LinearProgress,
} from '@mui/material'
import { useDebounce } from '../../../../shared/hooks'
import { IClientView, selectClient } from '../../../../shared/services/api'
import { NotificacaoError } from '../../../../shared/components'

interface ISelecionarInstituicaoProps {
  slug: string | undefined
  setSlug(slug: string): void
  selectedState: number | undefined
  selectedInstitution: number | undefined
  setSelectedInstitution(id: number): void
}

export const StepInstituicao: FC<ISelecionarInstituicaoProps> = ({
  selectedState,
  selectedInstitution,
  setSelectedInstitution,
  setSlug,
}) => {
  const { debounce } = useDebounce(1000)

  const theme = useTheme()
  const ssDown = useMediaQuery(theme.breakpoints.down(480))

  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [institutions, setInstitutions] = useState<IClientView[]>()

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      selectClient(Number(selectedState), search).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setInstitutions(result)
        }
      })
    })
  }, [debounce, search, selectedState])

  return (
    <Box>
      <TextField
        margin="dense"
        fullWidth
        label="Buscar Instituição..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Typography>
        Instituição Selecionada:{' '}
        {institutions?.find((element) => element.id === selectedInstitution)
          ?.ombudsman_title || 'Selecione Uma Instituição'}
      </Typography>
      {isLoading && (
        <Grid item>
          <LinearProgress
            variant="indeterminate"
            sx={{ height: '5px', borderRadius: '50px' }}
          />
        </Grid>
      )}
      <Grid container item spacing={3} marginTop={1}>
        {institutions?.map((institution) => (
          <Grid
            key={institution.id}
            item
            xs={ssDown ? 6 : 4}
            sm={4}
            md={4}
            lg={3}
            xl={2}
          >
            <Card
              sx={{
                minHeight: '200px',
                width: '100%',
                ':hover': {
                  border: '1px solid dodgerblue',
                  position: 'relative',
                  top: '-20px',
                  animation: 'go-back 1s infinite alternate',
                },
              }}
              component={Button}
              elevation={10}
              onClick={() => {
                setSelectedInstitution(institution.id)
                setSlug(institution.slug)
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img width="100%" src={institution.logo} alt="logo" />
                  <Typography variant="h6">
                    {institution.ombudsman_title}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

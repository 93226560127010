import { Api } from '../axios-config'

export interface IClientHistory {
  field: string
  type: '+' | '~'
  date: string
  user?: string
  old?: string
  new?: string
}

const getClientHistory = async (
  institutionId = '',
): Promise<IClientHistory[] | Error> => {
  try {
    const relativeUrl = `/V1/institutions/${institutionId}/historical/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message)
  }
}

export { getClientHistory }

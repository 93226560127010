import { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  LinearProgress,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { VForm, useVForm, VTextField } from '../../../../shared/forms'
import {
  IGroupings,
  createGroup,
  updateGroupById,
} from '../../../../shared/services/api'
import { VSwitch } from '../../../../shared/forms/VSwitch'
import { AutoCompleteIcons } from '../components/AutoComplete'
import { useAuthContext } from '../../../../shared/contexts'
import { ModalHeader } from '../../../../shared/components/modal/Header'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'

interface IFormData {
  id?: string
  name: string
  icon: number
  status: boolean
  module_kind: number
  institution_id: number
}

// const formValiidationSchema: yup.SchemaOf<IFormData> = yup.object({
//   id: yup.string(),
//   name: yup.string().required().min(3).max(10),
//   icon: yup.number().required(),
//   status: yup.boolean().required().default(false),
//   module_kind: yup.number().required().min(1).max(2),
//   institution_id: yup.number().required().default(1),
// })

interface IFormAgrupamentoProps {
  show: boolean
  create: boolean
  onClose(): void
  data?: IGroupings
  update: React.MutableRefObject<boolean>
}

export const FormAgrupamento: React.FC<IFormAgrupamentoProps> = ({
  create,
  onClose,
  show,
  update,
  data,
}) => {
  const [formTitle, setFormTitle] = useState('')
  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { user } = useAuthContext()
  const { moduleTitle } = useModuleContext()

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)

    formData.module_kind = 1

    if (create) {
      if (
        user?.modules.find(
          (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
        )?.profile === 0
      ) {
        if (!localStorage.getItem('ADM_SELECTED_INSTITUTION')) {
          NotificacaoError(
            'Selecione Uma Instituição Antes de Criar uma Nova instância',
          )
          setIsLoading(false)
          return onClose()
        }
        formData.institution_id = Number(
          localStorage.getItem('ADM_SELECTED_INSTITUTION'),
        )
      } else {
        formData.institution_id = Number(user?.institution_id)
      }
    } else {
      formData.institution_id = Number(data?.institution_id)
    }

    create
      ? createGroup(formData).then((result) => {
          setIsLoading(false)

          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            update.current = true
            onClose()
            NotificacaoSucesso('Agrupamento Criado com Sucesso')
          }
        })
      : updateGroupById(Number(data?.id), formData).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            update.current = true
            onClose()
            NotificacaoSucesso('Agrupamento Atualizado com Sucesso')
          }
        })
  }

  useEffect(() => {
    if (!create && data !== undefined)
      setFormTitle(`Editar Agrupamento ${data.name}`)
    else setFormTitle('Novo Agrupamento')
  }, [create, data, show])

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader onClose={onClose} showCloseButton title={formTitle} />

        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{
              height: '5px',
              borderRadius: '50px',
              mt: 1,
            }}
          />
        )}
        <Box margin={1}>
          <VForm
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={create ? undefined : data}
          >
            <Grid container direction="column" spacing={2} padding={2}>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="name"
                    label="Nome"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutoCompleteIcons
                    textStyle={{ fieldSet: { borderRadius: '50px' } }}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item>
                  <FormControlLabel
                    control={<VSwitch name="status" />}
                    label="Status"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box display="flex" flex={1} justifyContent="center">
              <Button type="submit">
                <Icon>save</Icon>
                Salvar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

export interface IGroupings {
  id: number
  icon: string
  name: string
  status: boolean
  icon_url: string
  icon_name: string
  created_at: string
  updated_at: string
  module_kind: number
  institution_id: number
}

export type IGroupingsTotalCount = {
  data: IGroupings[]
  count: number
  next: string
  previous: string
  results: IGroupings[]
}

const getAllGroupings = async (
  item = '0',
  total = '10',
  filter = '',
  groupId = '',
  institutionId = '',
  moduleKind = '',
): Promise<IGroupingsTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/groupings/?total=${total}&item=${item}&search=${filter}&id=${groupId}&institution_id=${institutionId}&module_kind=${moduleKind}`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return {
        data,
        results: data.results,
        count: data.count,
        next: data.next,
        previous: data.previous,
      }
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getGroupById = async (id: number): Promise<IGroupings | Error> => {
  try {
    const relativeUrl = `/V1/groupings/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return data
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao listar registro',
    )
  }
}

const createGroup = async (formData: {}): Promise<string | Error> => {
  try {
    const relativeUrl = '/V1/groupings/'

    const { data } = await Api.post<IGroupings>(relativeUrl, formData)

    if (data) return data.id ? data.id.toString() : data.name

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const updateGroupById = async (
  id: number,
  formData: {},
): Promise<IGroupings | Error> => {
  try {
    const relativeUrl = `/V1/groupings/${id}/`

    const { data } = await Api.patch<IGroupings>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

export { getAllGroupings, getGroupById, createGroup, updateGroupById }

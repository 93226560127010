import { Api } from '../axios-config'

export interface IView {
  user: string
  date: string
}

export interface IProtocolHistory {
  field: string
  type: '+' | '~'
  date: string
  user?: string
  old?: string
  new?: string
}

const getViews = async (protocolId: number): Promise<IView[] | Error> => {
  try {
    const relativeUrl = `/V1/protocols/${protocolId}/history_views/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar views')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getForwarding = async (protocolId: number): Promise<IView[] | Error> => {
  try {
    const relativeUrl = `/V1/protocols/${protocolId}/history_forwardings/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar encaminhamentos')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getProtocolHistorical = async (
  protocolId: number,
): Promise<IProtocolHistory[] | Error> => {
  try {
    const relativeUrl = `/V1/protocols/${protocolId}/history/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar histórico')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export { getViews, getForwarding, getProtocolHistorical }

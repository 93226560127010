import { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { VForm, VSelect, VTextField, useVForm } from '../../../shared/forms'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'
import {
  IClientDetail,
  IExternalUsers,
  createExternal,
} from '../../../shared/services/api'
import { formatToCNPJ, formatToCPF } from 'brazilian-values'
import { toast } from 'react-toastify'
import { formatCellPhone } from '../../../shared/utils'
import {
  CheckCircleRounded,
  RadioButtonUncheckedRounded,
} from '@mui/icons-material'
import { PrivacyPolicyModal } from '../components/PrivacyPolicyModal'

interface ICadastreSeModalProps {
  show: boolean
  onClose: () => void
  institutionData: IClientDetail
}

export const CadastreSeModal: React.FC<ICadastreSeModalProps> = ({
  show,
  institutionData,
  onClose,
}) => {
  const { formRef } = useVForm()

  const [documentType, setDocumentType] = useState(1)

  const [acceptTerms, setAcceptTerms] = useState(false)
  const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false)

  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const handleSubmit = (formData: IExternalUsers) => {
    console.log(formData)
    createExternal(formData).then((result) => {
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        NotificacaoSucesso('Usuário criado com sucesso!')
        toast.info('Faça Login com Documento e Senha Informados')
        onClose()
      }
    })
  }

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={{ ...FormBoxStyle, pt: 0 }}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        overflow="auto"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(35)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Box display="flex" flex="1" paddingTop={2} justifyContent="center">
          <img
            src={institutionData.logo}
            width="100%"
            style={{ maxWidth: '300px' }}
            alt="logo"
          />
        </Box>
        <Box
          display="flex"
          top="0px"
          flexDirection="column"
          sx={{ zIndex: 1500 }}
        >
          <Box display="flex" width="100%" justifyContent="center">
            <Typography variant="h5">Cadastre-se</Typography>
          </Box>
          <Box paddingBottom={0} width="100%">
            <Divider />
          </Box>
        </Box>
        <VForm
          ref={formRef}
          initialData={{
            document_type: 1,
            gender: 1,
            escolarity: 1,
            birth_date: null,
          }}
          onSubmit={(e: IExternalUsers) => handleSubmit(e)}
        >
          <Grid container direction="column" spacing={2} pt={1}>
            <Grid container item>
              <Grid item xs={12}>
                <VTextField
                  fullWidth
                  required
                  size="small"
                  name="name"
                  label="Nome"
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                />
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={12}>
                <VTextField
                  fullWidth
                  size="small"
                  name="email"
                  label="Email"
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                />
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="type-label">Tipo</InputLabel>
                  <VSelect
                    fullWidth
                    required
                    size="small"
                    label="Tipo"
                    labelId="type-label"
                    name="document_type"
                    myChange={(e) => setDocumentType(e)}
                    sx={{
                      borderTopLeftRadius: '50px',
                      borderBottomLeftRadius: '50px',
                      borderTopRightRadius: '0px',
                      borderBottomRightRadius: '0px',
                    }}
                  >
                    <MenuItem value={1}>CPF</MenuItem>
                    <MenuItem value={2}>CNPJ</MenuItem>
                  </VSelect>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <VTextField
                  fullWidth
                  required
                  size="small"
                  name="document_number"
                  label="Documento"
                  formatData={documentType === 1 ? formatToCPF : formatToCNPJ}
                  inputProps={{
                    maxLength: documentType === 1 ? 14 : 18,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={12}>
                <VTextField
                  fullWidth
                  required
                  size="small"
                  type="password"
                  name="password"
                  label="Senha"
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                />
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={12}>
                <VTextField
                  fullWidth
                  required
                  size="small"
                  type="password"
                  name="re_password"
                  label="Repita a Senha"
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                />
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="gender-label">Gênero</InputLabel>
                  <VSelect
                    fullWidth
                    required
                    size="small"
                    name="gender"
                    label="Gênero"
                    labelId="gender-label"
                    useDefaultValue
                    sx={{
                      borderRadius: '50px',
                    }}
                  >
                    <MenuItem value={1}>Masculino</MenuItem>
                    <MenuItem value={2}>Feminino</MenuItem>
                    <MenuItem value={4}>Prefiro Não Responder</MenuItem>
                  </VSelect>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={12}>
                <VTextField
                  fullWidth
                  size="small"
                  type="date"
                  name="birth_date"
                  label="Data"
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="school-label">Escolaridade</InputLabel>
                  <VSelect
                    fullWidth
                    size="small"
                    name="escolarity"
                    label="Escolaridade"
                    labelId="school-label"
                    sx={{
                      borderRadius: '50px',
                    }}
                  >
                    <MenuItem value={1}>Não Alfabetizado</MenuItem>
                    <MenuItem value={2}>Ensino Fundamental Incompleto</MenuItem>
                    <MenuItem value={3}>Ensino Fundamental Completo</MenuItem>
                    <MenuItem value={4}>Ensino Médio incompleto</MenuItem>
                    <MenuItem value={5}>Ensino Médio Completo</MenuItem>
                    <MenuItem value={6}>Técnico Incompleto</MenuItem>
                    <MenuItem value={7}>Técnico Completo</MenuItem>
                    <MenuItem value={8}>Superior Incompleto</MenuItem>
                    <MenuItem value={9}>Superior Completo</MenuItem>
                    <MenuItem value={10}>Prefiro Não Informar</MenuItem>
                  </VSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <VTextField
                  fullWidth
                  required
                  size="small"
                  name="number"
                  label="Celular"
                  borderTopLeft="50px"
                  borderBottomLeft="50px"
                  placeholder="(DDD) 99999-99999"
                  formatData={formatCellPhone}
                  inputProps={{
                    maxLength: 15,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box
            display="flex"
            width="100%"
            justifyContent="end"
            alignItems="center"
            paddingTop={2}
            gap={1}
            flexDirection="column"
          >
            <Box display="flex" flex="1" alignItems="center">
              <Checkbox
                sx={{ borderRadius: '50px' }}
                icon={<RadioButtonUncheckedRounded />}
                checkedIcon={<CheckCircleRounded />}
                value={acceptTerms}
                checked={acceptTerms}
                onChange={() => setAcceptTerms((oldValue) => !oldValue)}
              />
              <Tooltip title="clique para visualizar os termos da política de privacidade">
                <Typography
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setPrivacyPolicyModal(true)}
                >
                  Declaro que li e concordo com os termos de privacidade
                </Typography>
              </Tooltip>
            </Box>
            <Box display="flex">
              <Button
                type="submit"
                variant="contained"
                size="small"
                disableElevation
                disabled={!acceptTerms}
                sx={{
                  borderRadius: '50px',
                }}
              >
                salvar
              </Button>
              <Button sx={{ borderRadius: '50px' }} onClick={onClose}>
                cancelar
              </Button>
            </Box>
          </Box>
        </VForm>
        <PrivacyPolicyModal
          show={privacyPolicyModal}
          setAccept={setAcceptTerms}
          onClose={() => setPrivacyPolicyModal(false)}
        />
      </Box>
    </Modal>
  )
}

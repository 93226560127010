import { useState, useEffect, useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Paper,
  LinearProgress,
  IconButton,
  Icon,
  Box,
  Pagination,
  Select,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'

import { useDebounce } from '../../../shared/hooks'
import { Environment } from '../../../shared/environment'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import { FormPerguntas } from './formulario/Formulário'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'
import { getAllQuestions, IQuestion } from '../../../shared/services/api'
import {
  FerramentasDaListagem,
  NotificacaoError,
} from '../../../shared/components'
import { useAuthContext } from '../../../shared/contexts'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const Perguntas: React.FC = () => {
  const { user } = useAuthContext()
  const isAdmin = () => {
    return user?.modules[0].profile === 0
  }

  const saved = useRef(false)

  const [institution, setInstitution] = useState('')
  const toggleSaved = (e: number | undefined) => {
    setInstitution(e ? String(e) : '')
  }

  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState<IQuestion[]>([])
  const [totalCount, setTotalCount] = useState(0)

  const [currentData, setCurrentData] = useState<IQuestion>()

  const [searchParams, setSearchParams] = useSearchParams()

  const { debounce } = useDebounce(1200)

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])

  const pagina = Number(item) / Number(total) + 1

  const changePage = (value1: number, value2: number) => {
    const result = value1 * value2 - value2
    return result.toString()
  }

  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)

  const handleClose = () => {
    setCurrentData(undefined)
    setViewForm(false)
    setCreate(false)
  }

  useEffect(() => {
    setIsLoading(true)
    if (isAdmin())
      setInstitution(localStorage.getItem('ADM_SELECTED_INSTITUTION') || '')
    else setInstitution('')

    debounce(() => {
      getAllQuestions(
        item,
        '5000',
        busca,
        '',
        isAdmin() ? institution : '',
      ).then((result) => {
        saved.current = false
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setRows(result.results)
          setTotalCount(result.count)
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, total, busca, saved.current, institution])

  const { moduleTitle } = useModuleContext()

  return (
    <LayoutBaseDePagina
      title="Perguntas"
      selectInstitution
      autoCompleteChange={toggleSaved}
      filter={
        <FerramentasDaListagem
          mostrarInputDaBusca
          textoBotaoNovo="Nova"
          mostrarBotaoNovo={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.hr_manager ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
            setCurrentData(undefined)
          }}
          textoDaBusca={busca}
          aoMudarTextoDaBusca={(texto) =>
            setSearchParams(
              { busca: texto, item: '0', total },
              { replace: true },
            )
          }
        />
      }
    >
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{
          m: 1,
          width: 'auto',
          color: 'dodgerblue',
          border: '1px solid',
          borderRadius: '15px',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center" sx={{ maxWidth: '500px' }}>
                Introdução
              </TableCell>
              <TableCell align="center">Pergunta</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setCurrentData(row)
                      setCreate(false)
                      setViewForm(true)
                    }}
                  >
                    <Icon>info_outlined</Icon>
                  </IconButton>
                </TableCell>
                <TableCell align="center" sx={{ maxWidth: '350px' }}>
                  {row.introduction ? (
                    <Typography
                      textAlign="justify"
                      variant="body2"
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                      }}
                    >
                      {row.introduction}
                    </Typography>
                  ) : (
                    <Typography variant="caption">
                      não possui introdução
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="center">{row.title}</TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.created_at, 'Não Definido')}
                </TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={4}>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{ height: '5px', borderRadius: '50px' }}
                  />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 10 || totalCount > Number(total)) && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box display="flex" justifyContent="center">
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / Number(total))}
                      onChange={(_, newPage) => {
                        setSearchParams(
                          {
                            busca,
                            total,
                            item: changePage(newPage, Number(total)),
                          },
                          { replace: true },
                        )
                      }}
                    />
                    <Tooltip title="Itens por Página">
                      <Select
                        variant="outlined"
                        sx={{ borderRadius: '15px', height: '30px' }}
                        value={total}
                        onChange={(e) => {
                          setSearchParams(
                            { busca, total: e.target.value, item: '0' },
                            { replace: true },
                          )
                        }}
                      >
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="40">40</MenuItem>
                        <MenuItem value="50">50</MenuItem>
                      </Select>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <FormPerguntas
        onClose={handleClose}
        show={viewForm}
        update={saved}
        create={create}
        data={currentData}
      />
    </LayoutBaseDePagina>
  )
}

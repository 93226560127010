/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react'
import Box from '@mui/material/Box'
import { Button, Modal, Paper } from '@mui/material'
import { FormBoxStyle, NotificacaoError } from '../../../shared/components'
import { toast } from 'react-toastify'
import { Mapbox } from '../../../shared/components/maps/Mapbox'

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.

export const ModalMap = (
  onClose: () => void,
  show: boolean,
  lat: any,
  lng: any,
) => {
  const latRef = useRef<number | undefined>(undefined)
  const lngRef = useRef<number | undefined>(undefined)

  const onClick = async () => {
    if (latRef.current === undefined || lngRef.current === undefined) {
      NotificacaoError('Selecione um endereço!')
      toast.info(
        'Você pode fazer isso clicando no mapa ou digitando algo no local indicado',
      )
    } else {
      lat.current = latRef.current
      lng.current = lngRef.current
      onClose()
    }
  }

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        display="flex"
        flexDirection="column"
        borderRadius="20px"
        minWidth="280px"
        height="80%"
        width="80%"
        gap={2}
      >
        <Mapbox lat={latRef} lng={lngRef} />
        <Box display="flex" justifyContent="end" alignItems="center">
          <Button
            disableElevation
            onClick={onClick}
            variant="contained"
            sx={{ borderRadius: '50px' }}
          >
            confirmar
          </Button>
          <Button
            onClick={onClose}
            disableElevation
            sx={{ borderRadius: '50px' }}
          >
            fechar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, useRef, useState } from 'react'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import {
  VForm,
  VSelect,
  VSwitch,
  VTextArea,
  VTextField,
  useVForm,
} from '../../../../shared/forms'
import {
  AutoCompleteAgrupamento,
  AutoCompleteAssunto,
  AutoCompleteSetor,
  AutoCompleteTipologia,
  AutoCompleteUsuario,
} from './components/AutoComplete'
import { useAuthContext } from '../../../../shared/contexts'
import { AddCircle, Badge, Map, People } from '@mui/icons-material'
import { ModalMap } from '../../../usuario-externo/protocolo/ModalMap'
import { createProtocol } from '../../../../shared/services/api'
import { useNavigate } from 'react-router-dom'
import { FormUsuarioExterno } from '../../usuarios-externos/formulario/Formulario'
import { ModalHeader } from '../../../../shared/components/modal/Header'
import { AutocompleteDocument } from './components/AutocompleteDocument'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'

interface INovoProtocoloProps {
  show: boolean
  onClose: () => void
}

export const NovoProtocolo: FC<INovoProtocoloProps> = ({ show, onClose }) => {
  const { formRef } = useVForm()
  const { user } = useAuthContext()
  const navigate = useNavigate()

  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const [searchType, setSearchType] = useState(1)

  const [group, setGroup] = useState<number>()
  const [sector, setSector] = useState<number>()
  const [newUser, setNewUser] = useState(false)

  const [map, setMap] = useState(true)
  const [anonymous, setAnonymous] = useState(true)
  const [sigilo, setSigilo] = useState(true)

  const update = useRef(false)

  const lat = useRef<number | undefined>()
  const lng = useRef<number | undefined>()

  const resetLat = () => {
    lat.current = undefined
  }

  const resetLng = () => {
    lng.current = undefined
  }

  const { moduleTitle } = useModuleContext()

  const handleSubmit = (formData: any) => {
    formData.institution_id = user?.institution_id
    formData.user_history_type = 1
    createProtocol(formData).then((result) => {
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        NotificacaoSucesso('Manifestação aberta com sucesso')
        formRef.current?.reset()
        setMap(false)
        setGroup(undefined)
        setSector(undefined)
        resetLat()
        resetLng()
        navigate(`/ombudsman/${moduleTitle}/protocols/detail/${result.id}`)
      }
    })
  }

  const handleClose = () => {
    formRef.current?.reset()
    setMap(false)
    setGroup(undefined)
    setSector(undefined)
    resetLat()
    resetLng()
    onClose()
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        overflow="auto"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(35)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader
          showCloseButton
          onClose={handleClose}
          title="Nova manifestação"
        />
        <Divider />
        <Box margin={1} paddingTop={2}>
          <VForm
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={{ kind_response: 1 }}
          >
            <Grid container spacing={2} direction="column">
              <Grid container item xs={12} direction="row">
                <Grid item xs={12}>
                  <Typography textAlign="center">Pesquisa por:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    startIcon={<People />}
                    onClick={() => setSearchType(1)}
                    variant={searchType === 1 ? 'contained' : 'outlined'}
                    sx={{
                      borderBottomRightRadius: '0px',
                      borderTopRightRadius: '0px',

                      borderBottomLeftRadius: '50px',
                      borderTopLeftRadius: '50px',
                    }}
                  >
                    Nome
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    startIcon={<Badge />}
                    onClick={() => setSearchType(2)}
                    fullWidth
                    variant={searchType === 1 ? 'outlined' : 'contained'}
                    sx={{
                      borderBottomRightRadius: '50px',
                      borderTopRightRadius: '50px',

                      borderBottomLeftRadius: '0px',
                      borderTopLeftRadius: '0px',
                    }}
                  >
                    documento
                  </Button>
                </Grid>
              </Grid>
              <Grid container item spacing={0.5}>
                <Grid item xs={10}>
                  {searchType === 1 ? (
                    <AutoCompleteUsuario
                      required={!anonymous && searchType === 1}
                      institutionId={String(user?.institution_id)}
                      update={update}
                      textStyle={{
                        fieldSet: {
                          borderRadius: '50px',
                        },
                      }}
                    />
                  ) : (
                    <AutocompleteDocument
                      update={update}
                      required={!anonymous && searchType === 2}
                    />
                  )}
                </Grid>
                <Grid item display="flex" justifyContent="center" xs={2}>
                  <Tooltip title="Cadastrar novo usuário">
                    <IconButton onClick={() => setNewUser(true)}>
                      <AddCircle />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid
                  item
                  xs={12}
                  md={4}
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    fullWidth={!smDown}
                    onClick={() => setMap(true)}
                    startIcon={<Map />}
                    variant="outlined"
                    sx={{ borderRadius: '50px' }}
                  >
                    Inserir Localização
                  </Button>
                </Grid>
                <Grid item xs={6} md={4} display="flex" justifyContent="center">
                  <FormControlLabel
                    control={
                      <VSwitch
                        name="secret"
                        checked={sigilo}
                        onChange={(_, checked) => setSigilo(checked)}
                      />
                    }
                    label="Sigilo?"
                  />
                </Grid>
                <Grid item xs={6} md={4} display="flex" justifyContent="center">
                  <FormControlLabel
                    control={
                      <VSwitch
                        name="anonymous"
                        checked={anonymous}
                        onChange={(_, checked) => setAnonymous(checked)}
                      />
                    }
                    label="Anônimo?"
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <AutoCompleteAgrupamento
                    onChange={(groupId) => setGroup(groupId)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutoCompleteSetor
                    groupingId={group}
                    onChange={(e) => setSector(e)}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <AutoCompleteAssunto
                    sectorId={sector}
                    institutionId={Number(user?.institution_id)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutoCompleteTipologia
                    institutionId={Number(user?.institution_id)}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    fullWidth
                    size="small"
                    name="relative_protocol"
                    label="Manifestação anterior relacionada"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="response-label" shrink>
                      Tipo de Resposta
                    </InputLabel>
                    <VSelect
                      name="kind_response"
                      label="Tipo de Resposta"
                      labelId="response-label"
                      size="small"
                      sx={{
                        borderRadius: '50px',
                      }}
                    >
                      <MenuItem value={1}>E-Mail</MenuItem>
                      <MenuItem value={2}>Telefone</MenuItem>
                      <MenuItem value={3}>Presencialmente</MenuItem>
                      <MenuItem value={4}>Carta</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <VTextArea
                    name="description"
                    minRows={4}
                    maxRows={8}
                    placeholder="Descreva aqui a manifestação"
                    ariaLabel="Descrição"
                    style={{
                      width: '90%',
                      borderRadius: '5px',
                      fontVariant: 'historical-forms',
                      font: 'message-box',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box
              paddingTop={1}
              display="flex"
              flex="1"
              gap={1}
              justifyContent="right"
            >
              <Button
                type="submit"
                variant="contained"
                sx={{ borderRadius: '50px' }}
                disableElevation
              >
                Enviar
              </Button>
              <Button
                sx={{ borderRadius: '50px' }}
                onClick={() => {
                  formRef.current?.reset()
                  setMap(false)
                  setGroup(undefined)
                  setSector(undefined)
                  resetLat()
                  resetLng()
                  onClose()
                }}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
        <FormUsuarioExterno
          onClose={() => setNewUser(false)}
          show={newUser}
          update={update}
          create
        />
        {ModalMap(() => setMap(false), map, lat, lng)}
      </Box>
    </Modal>
  )
}

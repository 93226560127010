/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LayoutBaseDePagina } from '../../shared/layouts'
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material'
import {
  FerramentasDeDetalhes,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../shared/components'
import { useAuthContext } from '../../shared/contexts'
import { CameraAlt, Delete, Save } from '@mui/icons-material'
import { Form } from '@unform/web'
import { VTextField, useVForm } from '../../shared/forms'
import {
  IUserGetSelf,
  getMySelf,
  updateMySelf,
} from '../../shared/services/api'

export const Perfil: FC = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const inputUpdateAvatarPhoto = useRef<HTMLInputElement>(null)

  const { user } = useAuthContext()
  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<IUserGetSelf>()

  const [picture, setPicture] = useState<Blob | undefined>(undefined)
  const [URLPicture, setURLPicture] = useState('')

  const handleInputValue = () => {
    if (inputUpdateAvatarPhoto.current?.value)
      inputUpdateAvatarPhoto.current.value = ''
  }

  useEffect(() => {
    setIsLoading(true)
    getMySelf().then((result) => {
      setIsLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        formRef.current?.setData(result)
        setData(result)
      }
    })
  }, [formRef])

  const form = new FormData()

  const handleSubmit = (formData: any) => {
    setIsLoading(true)
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key])
      if (picture !== undefined) {
        form.append('profile_picture', picture, 'profile_picture.png')
      }
    })
    updateMySelf(form).then((result) => {
      setIsLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        NotificacaoSucesso('Perfil atualizado com sucesso')
      }
    })
  }

  return (
    <LayoutBaseDePagina
      title="Perfil"
      barraDeFerramentas={
        <FerramentasDeDetalhes
          mostrarBotaoEditar={false}
          mostrarBotaoApagar={false}
          mostrarBotaoNovo={false}
          mostrarBotaoVoltar={true}
          aoClicarEmVoltar={() => navigate(-1)}
        />
      }
    >
      <Box margin={1}>
        <Box
          id="avatar-container"
          gap={0.5}
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Avatar
            src={picture !== undefined ? URLPicture : user?.profile_picture}
            sx={{
              height: theme.spacing(15),
              width: theme.spacing(15),
            }}
          />
          <Box display="flex" flex="1" gap={1}>
            <Button
              disableElevation
              component="label"
              variant="contained"
              startIcon={<CameraAlt />}
            >
              alterar foto
              <input
                hidden
                ref={inputUpdateAvatarPhoto}
                type="file"
                accept="image/*"
                onInput={(e) => {
                  if (e.currentTarget.files?.length) {
                    const currentFile = e.currentTarget.files[0]
                    if (currentFile.type.includes('image')) {
                      setPicture(currentFile)
                      const oldLink = URLPicture
                      URL.revokeObjectURL(oldLink)
                      setURLPicture(() => URL.createObjectURL(currentFile))
                    } else {
                      NotificacaoError('Insira apenas arquivos de imagens')
                    }
                  }
                }}
              />
            </Button>
            {picture !== undefined && (
              <Tooltip title="remover foto">
                <IconButton
                  onClick={() => {
                    setPicture(undefined)
                    URL.revokeObjectURL(URLPicture)
                    setURLPicture('')
                    handleInputValue()
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Form
          ref={formRef}
          onSubmit={(formData) => handleSubmit(formData)}
          onReset={() => formRef.current?.setData(data || {})}
        >
          <Grid container direction="column" marginTop={2} spacing={2}>
            <Grid container item spacing={2}>
              <Grid item sm={6} xs={12}>
                <VTextField
                  fullWidth
                  size="small"
                  name="username"
                  label="Nome de usuário"
                  borderTopLeft="50px"
                  borderBottomLeft="50px"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <VTextField
                  fullWidth
                  size="small"
                  name="email"
                  label="Email"
                  borderTopLeft="50px"
                  borderBottomLeft="50px"
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <VTextField
                  fullWidth
                  size="small"
                  label="Senha"
                  type="password"
                  name="password"
                  borderTopLeft="50px"
                  borderBottomLeft="50px"
                  placeholder="Preencha se quiser redefinir sua senha"
                />
              </Grid>
              <Grid item xs={12}>
                <VTextField
                  fullWidth
                  size="small"
                  name="re_password"
                  borderTopLeft="50px"
                  label="Repita a senha"
                  borderBottomLeft="50px"
                />
              </Grid>
            </Grid>
          </Grid>
          <Box
            display="flex"
            flex="1"
            justifyContent="center"
            gap={1}
            padding={1}
          >
            <Button
              type="submit"
              disableElevation
              variant="contained"
              startIcon={<Save />}
              disabled={isLoading}
            >
              salvar
            </Button>
            <Button variant="outlined" type="reset">
              cancelar
            </Button>
          </Box>
        </Form>
      </Box>
    </LayoutBaseDePagina>
  )
}

import { FC, useState, useEffect } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { FormBoxStyle } from '../../../../shared/components'
import { Environment } from '../../../../shared/environment'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'
import { IModule, useAuthContext } from '../../../../shared/contexts'

interface IPDFModalProps {
  show: boolean
  anonynmous: boolean
  onClose: () => void
  defaultSecret: boolean
}

export const PDFModal: FC<IPDFModalProps> = ({
  onClose,
  show,
  anonynmous,
  defaultSecret,
}) => {
  const { id } = useParams<'id'>()

  const { user } = useAuthContext()
  const { currentModule, moduleTitle } = useModuleContext()

  const [secret, setSecret] = useState<1 | 0>(0)
  const [replies, setReplies] = useState<'null' | 'all' | 'external'>('null')

  const [disable, setDisable] = useState(false)

  const handleSubmit = () => {
    setDisable(true)
    window.open(
      `${Environment.URL_BASE}/V1/protocols/${id}/pdf_view/${secret}/${replies}/`,
    )
    setDisable(false)
  }

  const [thisModule, setThisModule] = useState<IModule>()

  useEffect(() => {
    setThisModule(
      user?.modules.find(
        (mdl) => mdl.type === currentModule && mdl.title === moduleTitle,
      ),
    )
  }, [user?.modules, currentModule, moduleTitle])

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        width="300px"
        maxHeight="85%"
        borderRadius="20px"
        flexDirection="column"
      >
        <Box margin={1} gap={2} display="flex" flexDirection="column">
          <Box display="flex" flex="1" justifyContent="center">
            <FormControlLabel
              control={<Switch />}
              disabled={
                anonynmous || (defaultSecret && thisModule?.profile !== 2)
              }
              value={secret === 1}
              onChange={() => setSecret((old) => (old === 1 ? 0 : 1))}
              checked={secret === 1}
              label="Protocolo Anonimizado?"
            />
          </Box>
          <FormControl fullWidth>
            <InputLabel id="replies-label">Respostas no PDF</InputLabel>
            <Select
              size="small"
              labelId="replies-label"
              label="Respostas no PDF"
              value={replies}
              defaultValue="null"
              onChange={(e) =>
                setReplies(
                  (e.target as { value: 'null' | 'external' | 'all' }).value,
                )
              }
              sx={{
                borderRadius: '50px',
              }}
            >
              <MenuItem value="null">Não incluir respostas</MenuItem>
              <MenuItem value="external">Apenas externas</MenuItem>
              <MenuItem value="all">Todas</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          gap={1}
          marginTop={2}
        >
          <Button
            onClick={() => {
              handleSubmit()
            }}
            disabled={disable}
            variant="contained"
            sx={{ borderRadius: '50px' }}
            disableElevation
          >
            confirmar
          </Button>
          <Button sx={{ borderRadius: '50px' }} onClick={onClose}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

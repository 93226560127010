/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import { LayoutBaseDePagina } from '../../../shared/layouts'
import { Dados } from './Dados'
import {
  SelecionarAgrupamento,
  SelecionarAssunto,
  SelecionarResposta,
  SelecionarSetor,
  SelecionarTipologia,
} from './components/Selectors'
import { Download, Help } from '@mui/icons-material'
import { Api } from '../../../shared/services/api/axios-config'
import { NotificacaoError } from '../../../shared/components'

export const SacRealatorios = () => {
  const [initial, setInitial] = useState('')
  const [final, setFinal] = useState('')
  const [type, setType] = useState<number>(0)
  const [typeLabel, setTypeLabel] = useState<string>()
  const [period, setPeriod] = useState(1)
  const [generate, setGenerate] = useState(false)

  const sectorId = useRef<{ id: number; name: string } | undefined>(undefined)
  const groupingId = useRef<{ id: number; name: string } | undefined>(undefined)
  const subjectId = useRef<{ id: number; name: string } | undefined>(undefined)
  const typologyId = useRef<{ id: number; name: string } | undefined>(undefined)
  const kindResponse = useRef<number | undefined>(undefined)
  const [selectSector, setSelectSector] = useState(false)
  const [selectGroup, setSelectGroup] = useState(false)
  const [selectSubject, setSelectSubject] = useState(false)
  const [selectTypology, setSelectTypology] = useState(false)
  const [selectResponse, setSelectResponse] = useState(false)

  const viewGroup =
    type === 7 || type === 8 || type === 9 || type === 10 || type === 11
  const viewSector = type === 12 || type === 13 || type === 14 || type === 15
  const viewSubject = type === 16 || type === 17 || type === 18
  const viewTypology = type === 19 || type === 20
  const viewResponse = type === 21

  const [help, setHelp] = useState(false)
  const [helperText, setHelperText] = useState(
    'Selecione um tipo de relatório. Sempre que alterar os parâmetros de "Data" e/ou "Tipo de Relatório" é necessário clicar no botão "Gerar" para que os dados sejam carregados',
  )

  interface IOptions {
    label: string
    value: number
  }

  const dataOptions: IOptions[] = [
    {
      label: 'Manifestações finalizadas',
      value: 23,
    },
    {
      label: 'Agrupamento',
      value: 1,
    },
    {
      label: 'Assunto',
      value: 2,
    },
    {
      label: 'Pesquisa de Satisfação',
      value: 22,
    },
    {
      label: 'Setor',
      value: 3,
    },
    {
      label: 'Status',
      value: 4,
    },
    {
      label: 'Tipologia',
      value: 5,
    },
    {
      label: 'Tipo de Resposta',
      value: 6,
    },
    {
      label: 'Agrupamento x Assunto',
      value: 7,
    },
    {
      label: 'Agrupamento x Resposta',
      value: 8,
    },
    {
      label: 'Agrupamento x Setor',
      value: 9,
    },
    {
      label: 'Agrupamento x Status',
      value: 10,
    },
    {
      label: 'Agrupamento x Tipologia',
      value: 11,
    },
    {
      label: 'Setor x Assunto',
      value: 12,
    },
    {
      label: 'Setor x Resposta',
      value: 13,
    },
    {
      label: 'Setor x Status',
      value: 14,
    },
    {
      label: 'Setor x Tipologia',
      value: 15,
    },
    {
      label: 'Assunto x Resposta',
      value: 16,
    },
    {
      label: 'Assunto x Status',
      value: 17,
    },
    {
      label: 'Assunto x Tipologia',
      value: 18,
    },
    {
      label: 'Tipologia x Resposta',
      value: 19,
    },
    {
      label: 'Tipologia x Status',
      value: 20,
    },
    {
      label: 'Resposta x Status',
      value: 21,
    },
  ]

  const handleSubmit = () => {
    setGenerate(true)
  }

  useEffect(() => {
    if (type === 1) {
      setHelperText('Manifestações em cada agrupamento')
    } else if (type === 2) {
      setHelperText('Manifestações em cada assunto')
    } else if (type === 3) {
      setHelperText('Manifestações em cada setor')
    } else if (type === 4) {
      setHelperText('Status das manifestações')
    } else if (type === 5) {
      setHelperText('Tipologia das manifestações')
    } else if (type === 6) {
      setHelperText('Tipo de resposta da manifestações')
    } else if (type === 7) {
      setHelperText(
        'Assunto das manifestações no agrupamento selecionado. OBSERVAÇÃO: Ao alterar o agrupamento selcionado, é neccesário clicar no botão "Gerar" para que os dados sejam atualizados!',
      )
    } else if (type === 8) {
      setHelperText('')
    } else if (type === 12) {
      setHelperText(
        'Assunto das manifestações no setor selecionado. OBSERVAÇÃO: Ao alterar o setor selcionado, é neccesário clicar no botão "Gerar" para que os dados sejam atualizados!',
      )
    } else if (type === 13) {
      setHelperText(
        'Tipo de Resposta das manifestações no setor selecionado. OBSERVAÇÃO: Ao alterar o setor selcionado, é neccesário clicar no botão "Gerar" para que os dados sejam atualizados!',
      )
    } else if (type === 14) {
      setHelperText(
        'Status das manifestações no setor selecionado. OBSERVAÇÃO: Ao alterar o setor selcionado, é neccesário clicar no botão "Gerar" para que os dados sejam atualizados!',
      )
    } else if (type === 15) {
      setHelperText(
        'Tipologia das manifestações no setor selecionado. OBSERVAÇÃO: Ao alterar o setor selcionado, é neccesário clicar no botão "Gerar" para que os dados sejam atualizados!',
      )
    } else if (type === 16) {
      setHelperText(
        'Tipo de resposta das manifestações no assunto selecionado. OBSERVAÇÃO: Ao alterar o assunto selcionado, é neccesário clicar no botão "Gerar" para que os dados sejam atualizados!',
      )
    } else if (type === 17) {
      setHelperText(
        'Status das manifestações no assunto selecionado. OBSERVAÇÃO: Ao alterar o assunto selcionado, é neccesário clicar no botão "Gerar" para que os dados sejam atualizados!',
      )
    } else if (type === 18) {
      setHelperText(
        'Tipologia das manifestações no assunto selecionado. OBSERVAÇÃO: Ao alterar o assunto selcionado, é neccesário clicar no botão "Gerar" para que os dados sejam atualizados!',
      )
    } else if (type === 19) {
      setHelperText(
        'Tipo de resposta das manifestações na tipologia selecionada. OBSERVAÇÃO: Ao alterar a tipologia selcionada, é neccesário clicar no botão "Gerar" para que os dados sejam atualizados!',
      )
    } else if (type === 20) {
      setHelperText(
        'Status das manifestações na tipologia selecionada. OBSERVAÇÃO: Ao alterar a tipologia selcionada, é neccesário clicar no botão "Gerar" para que os dados sejam atualizados!',
      )
    } else if (type === 21) {
      setHelperText(
        'Status das manifestações no tipo de resposta selecionado. OBSERVAÇÃO: Ao alterar o tipo de resposta selcionado, é neccesário clicar no botão "Gerar" para que os dados sejam atualizados!',
      )
    } else if (type === 22) {
      setHelperText(
        'Resultado das respostas à pesquisa de satisfação referente as manifestações abertas no período selecionado e que já foram finalizadas.',
      )
    }
  }, [type])

  const dates = useRef<any>([])

  const download = useRef(false)

  const [isDownloading, setIsDownloading] = useState(false)

  const getPDF = async () => {
    setIsDownloading(true)
    Api.post(
      '/V1/testee/',
      {
        html_content: document.querySelector('#meu-container')?.innerHTML,
        report_type: typeLabel,
        initial_date: initial,
        final_date: final,
      },
      { responseType: 'blob' },
    )
      .then((result) => {
        const url = URL.createObjectURL(result.data)
        const a = document.createElement('a')
        a.href = url
        a.download = `report-${typeLabel}.pdf`
        a.click()
        a.remove()
        setIsDownloading(false)
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
      })
      .catch((error) => {
        console.error(error)
        NotificacaoError(error.message)
      })
  }

  return (
    <LayoutBaseDePagina
      title={
        <Box display="flex" flex="1" gap={1}>
          Relatórios
          <Tooltip title={helperText} open={help}>
            <IconButton
              onClick={() => setHelp(!help)}
              onMouseEnter={() => setHelp(true)}
              onMouseLeave={() => setHelp(false)}
            >
              <Help />
            </IconButton>
          </Tooltip>
          <Box display="flex" flex="1" justifyContent="right">
            <Tooltip title="Download em PDF">
              <IconButton
                onClick={() => getPDF()}
                disabled={!download.current || isDownloading}
                type="button"
                sx={{
                  p: 1,
                  border: '1px solid dodgerblue',
                  width: '45px',
                  height: '45px',
                  marginRight: 2,
                }}
              >
                {isDownloading ? <CircularProgress size={25} /> : <Download />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      }
    >
      <Grid container spacing={2} direction="column" padding={2}>
        <Grid container item spacing={2}>
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              required
              value={initial}
              onChange={(e) => setInitial(e.target.value)}
              size="small"
              type="date"
              label="Data Inicial"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                fieldSet: {
                  borderRadius: '50px',
                },
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              required
              value={final}
              onChange={(e) => setFinal(e.target.value)}
              size="small"
              type="date"
              label="Data Final"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                fieldSet: {
                  borderRadius: '50px',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth sx={{ zIndex: 0 }}>
              <InputLabel id="period-label">Período</InputLabel>
              <Select
                fullWidth
                required
                size="small"
                disabled={type === 22}
                label="Período"
                labelId="priod-label"
                value={period}
                onChange={(e) => setPeriod(Number(e.target.value))}
                sx={{
                  borderRadius: '50px',
                }}
              >
                <MenuItem value={1}>Semana</MenuItem>
                <MenuItem value={2}>Mês</MenuItem>
                <MenuItem value={3}>Ano</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={8}>
              <FormControl fullWidth size="small">
                <InputLabel id="type-label">Tipo de Relatório</InputLabel>
                <Select
                  fullWidth
                  required
                  size="small"
                  label="Tipo de Relatório"
                  labelId="type-label"
                  value={type}
                  onChange={(e) => {
                    setType(Number(e.target.value))
                    setTypeLabel(
                      dataOptions.filter(
                        (option) => option.value === e.target.value,
                      )[0].label,
                    )
                  }}
                  sx={{ borderRadius: '50px' }}
                >
                  <MenuItem value={0} disabled>
                    Selecione uma opção
                  </MenuItem>

                  {dataOptions.map((option, index) => (
                    <MenuItem value={option.value} key={index}>
                      {option.label}
                    </MenuItem>
                  ))}

                  {/**
                   * Pesquisa de Satisfação
                   * Gênero / Sexualidade
                   * Idade
                   */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                sx={{ borderRadius: '50px' }}
                disableElevation
                startIcon={
                  generate ? <CircularProgress size={20} /> : undefined
                }
                disabled={
                  generate ||
                  !initial ||
                  !final ||
                  type === 0 ||
                  (type >= 7 &&
                    type <= 11 &&
                    groupingId.current === undefined) ||
                  (type >= 12 &&
                    type <= 15 &&
                    sectorId.current === undefined) ||
                  (type >= 16 &&
                    type <= 18 &&
                    subjectId.current === undefined) ||
                  (type >= 19 &&
                    type <= 20 &&
                    typologyId.current === undefined) ||
                  (type === 21 && kindResponse.current === undefined)
                }
                onClick={handleSubmit}
              >
                gerar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          {viewSector && (
            <Box
              marginLeft={2}
              gap={1}
              marginTop={2}
              display="flex"
              alignItems="center"
            >
              <Typography textAlign="center">
                {sectorId.current
                  ? `Setor selecionado: ${sectorId.current?.name}`
                  : 'Selecione um setor'}
              </Typography>
              <Divider orientation="vertical" />
              <Button
                variant="outlined"
                onClick={() => setSelectSector(true)}
                sx={{ borderRadius: '50px' }}
              >
                {!sectorId.current ? 'selecionar' : 'alterar setor'}
              </Button>
            </Box>
          )}
          {viewGroup && (
            <Box
              marginLeft={2}
              gap={1}
              marginTop={2}
              display="flex"
              alignItems="center"
            >
              <Typography textAlign="center">
                {groupingId.current
                  ? `Agrupamento selecionado: ${groupingId.current?.name}`
                  : 'Selecione um agrupamento'}
              </Typography>
              <Divider orientation="vertical" />
              <Button
                variant="outlined"
                onClick={() => setSelectGroup(true)}
                sx={{ borderRadius: '50px' }}
              >
                {!groupingId.current ? 'selecionar' : 'alterar agrupamento'}
              </Button>
            </Box>
          )}
          {viewSubject && (
            <Box
              marginLeft={2}
              gap={1}
              marginTop={2}
              display="flex"
              alignItems="center"
            >
              <Typography textAlign="center">
                {subjectId.current
                  ? `Assunto selecionado: ${subjectId.current?.name}`
                  : 'Selecione um assunto'}
              </Typography>
              <Divider orientation="vertical" />
              <Button
                variant="outlined"
                onClick={() => setSelectSubject(true)}
                sx={{ borderRadius: '50px' }}
              >
                {!subjectId.current ? 'selecionar' : 'alterar assunto'}
              </Button>
            </Box>
          )}
          {viewTypology && (
            <Box
              marginLeft={2}
              gap={1}
              marginTop={2}
              display="flex"
              alignItems="center"
            >
              <Typography textAlign="center">
                {typologyId.current
                  ? `Tipologia selecionada: ${typologyId.current?.name}`
                  : 'Selecione uma tipologia'}
              </Typography>
              <Divider orientation="vertical" />
              <Button
                variant="outlined"
                onClick={() => setSelectTypology(true)}
                sx={{ borderRadius: '50px' }}
              >
                {!typologyId.current ? 'selecionar' : 'alterar tipologia'}
              </Button>
            </Box>
          )}
          {viewResponse && (
            <Box
              marginLeft={2}
              gap={1}
              marginTop={2}
              display="flex"
              alignItems="center"
            >
              <Typography textAlign="center">
                {kindResponse.current
                  ? `Tipo de resposta selecionada: ${
                      kindResponse.current === 1
                        ? 'E-mail'
                        : kindResponse.current === 2
                        ? 'Telefone'
                        : kindResponse.current === 3
                        ? 'Presencialmente'
                        : 'Carta'
                    }`
                  : 'Selecione um tipo de resposta'}
              </Typography>
              <Divider orientation="vertical" />
              <Button
                variant="outlined"
                onClick={() => setSelectResponse(true)}
                sx={{ borderRadius: '50px' }}
              >
                {!kindResponse.current
                  ? 'selecionar'
                  : 'alterar tipo de resposta'}
              </Button>
            </Box>
          )}
          <Grid item xs={12} display="flex" justifyContent="center">
            <Dados
              generate={generate}
              datesRef={dates}
              type={type || 0}
              typeLabel={typeLabel}
              download={download}
              data={{
                final,
                period,
                type: type || 0,
                initial,
                sector_id: sectorId.current?.id,
                grouping_id: groupingId.current?.id,
                subject_id: subjectId.current?.id,
                category_id: typologyId.current?.id,
                kind_response: kindResponse.current,
              }}
              setGenerate={() => setGenerate(false)}
            />
          </Grid>
        </Grid>
      </Grid>
      <SelecionarSetor
        onClose={() => setSelectSector(false)}
        show={selectSector}
        sectorRef={sectorId}
      />
      <SelecionarAgrupamento
        onClose={() => setSelectGroup(false)}
        show={selectGroup}
        groupRef={groupingId}
      />
      <SelecionarAssunto
        onClose={() => setSelectSubject(false)}
        show={selectSubject}
        subjectRef={subjectId}
      />
      <SelecionarTipologia
        onClose={() => setSelectTypology(false)}
        show={selectTypology}
        typologyRef={typologyId}
      />
      <SelecionarResposta
        onClose={() => setSelectResponse(false)}
        show={selectResponse}
        responseRef={kindResponse}
      />
    </LayoutBaseDePagina>
  )
}

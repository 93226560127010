import { useState, useEffect } from 'react'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, IconButton } from '@mui/material'
import { useDebounce } from '../../../shared/hooks'
import { ISlide } from '../../../shared/services/api'
import { useExternalAuthContext } from '../../../shared/contexts'

interface ISlideMediaProps {
  slides: ISlide[]
}

export const SlideMedia: React.FC<ISlideMediaProps> = ({ slides }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [autoPlay, setAutoPlay] = useState(true)

  const { menuOpen } = useExternalAuthContext()

  const { debounce, debouncing } = useDebounce(3500, false)
  const [size, setSize] = useState('calc((100vw - 64px) * -1)')

  const nextItem = (click: boolean) => {
    if (selectedIndex + 1 < slides.length) {
      setSelectedIndex((oldValue) => oldValue + 1)
    } else setSelectedIndex(0)
    if (click) setAutoPlay(false)
  }

  const backItem = (click = false) => {
    if (click) setAutoPlay(false)
    if (selectedIndex === 0) {
      setSelectedIndex(slides.length - 1)
    } else {
      setSelectedIndex((oldValue) => oldValue - 1)
    }
  }

  useEffect(() => {
    if (autoPlay) {
      debounce(() => {
        nextItem(false)
      })
    } else {
      clearTimeout(debouncing.current)
    }
  })

  useEffect(() => {
    if (menuOpen && selectedIndex > 0) {
      setSize(
        `calc((${selectedIndex * 100}vw - ${selectedIndex * 239.2}px) * -1)`,
      )
    } else {
      setSize(
        `calc((${selectedIndex * 100}vw - ${selectedIndex * 64.2}px) * -1)`,
      )
    }
  }, [menuOpen, selectedIndex])

  return (
    <Box
      left={selectedIndex === 0 ? '0' : size}
      height="calc(100vh - 64px)"
      position="relative"
      display="flex"
      width={
        menuOpen
          ? `calc(${slides.length * 100}vw - ${slides.length * 239.2}px)`
          : `calc(${slides.length * 100}vw - ${slides.length * 64.2}px)`
      }
      sx={{
        transition: 'left .5s linear, width .25s linear',
      }}
    >
      {slides.map((slide) => (
        <Box
          key={slide.id}
          height="calc(100vh - 64px)"
          justifyContent="center"
          position="relative"
          display="flex"
          width={menuOpen ? 'calc(100vw - 239.2px)' : 'calc(100vw - 64.2px)'}
        >
          {!!slide.link && (
            <Button
              variant="contained"
              onClick={() => window.open(slide.link, '_blank', 'noopener')}
              sx={{
                bgcolor: slide.button_color,
                color: slide.text_color,
                position: 'absolute',
                display: 'flex',
                bottom: '20px',
                ':hover': {
                  bgcolor: slide.button_color_hover,
                  color: slide.text_color_hover,
                },
              }}
            >
              saiba mais
            </Button>
          )}

          <IconButton
            size="large"
            onClick={() => backItem(true)}
            sx={{
              left: menuOpen ? '255px' : '80px',
              top: '50%',
              transition: 'all .2s linear',
              color: slide.button_color,
              position: 'fixed',
              display: 'flex',
              ':hover': {
                color: slide.button_color_hover,
              },
            }}
          >
            <ArrowBackIos fontSize="large" />
          </IconButton>

          <IconButton
            size="large"
            onClick={() => nextItem(true)}
            sx={{
              right: '15px',
              top: '50%',
              transition: 'all .2s linear',
              color: slide.button_color,
              position: 'fixed',
              display: 'flex',
              ':hover': {
                color: slide.button_color_hover,
              },
            }}
          >
            <ArrowForwardIos fontSize="large" />
          </IconButton>

          <img
            src={slide.image}
            alt={slide.name}
            onClick={(e) => e.preventDefault()}
            style={{
              maxWidth: menuOpen
                ? 'calc(100vw - 239.2px)'
                : 'calc(100vw - 64.2px)',
              maxHeight: 'calc(100vh - 64.2px)',
            }}
          />
        </Box>
      ))}
    </Box>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { useState, useEffect } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { ResponsivePie } from '@nivo/pie'
import { useAppThemeContext } from '../../shared/contexts'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

interface IResponsiveBarProps {
  data: any
  open?: boolean
}

export const PieSituacaoManifestacoes: React.FC<IResponsiveBarProps> = ({
  data /* see data tab */,
  open,
}) => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const { themeName } = useAppThemeContext()

  const [currentThemeName, setCurrentThemeName] = useState(themeName)

  useEffect(() => {
    setCurrentThemeName((old) => (old === 'light' ? 'light' : 'dark'))
  }, [themeName])

  return (
    <ResponsivePie
      data={data}
      margin={
        smDown
          ? { bottom: open ? 300 : 130, right: 15, left: 15 }
          : mdDown
          ? { bottom: open ? 245 : 120, top: 15 }
          : { top: 40, right: 80, bottom: 100, left: 80 }
      }
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={5}
      activeOuterRadiusOffset={10}
      borderWidth={3}
      startAngle={smDown ? -90 : 0}
      endAngle={smDown ? 90 : 360}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true,
        },
      ]}
      fill={[
        {
          match: {
            id: 'Concluídas',
          },
          id: 'dots',
        },
      ]}
      arcLinkLabel={function (e: any) {
        return e.id + ' (' + e.value + ')'
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={
        currentThemeName === 'dark' ? '#ffffff' : '#000000'
      }
      arcLinkLabelsThickness={3}
      enableArcLinkLabels={!smDown}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      theme={{
        tooltip: {
          container: {
            color: 'black',
          },
          chip: {
            borderRadius: '50%',
            opacity: '1',
          },
        },
      }}
      legends={[
        {
          anchor: smDown ? 'top-right' : 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: smDown ? -20 : 55,
          itemsSpacing: smDown ? 20 : 0,
          itemWidth: 100,
          toggleSerie: true,
          itemHeight: 18,
          itemTextColor: currentThemeName === 'dark' ? '#ffffff' : '#000000',
          itemDirection: smDown ? 'top-to-bottom' : 'left-to-right',
          itemOpacity: smDown ? 0 : 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: 'dodgerblue',
              },
            },
          ],
        },
      ]}
      layers={
        mdDown
          ? ['arcs', 'arcLabels']
          : ['arcs', 'arcLabels', 'arcLinkLabels', 'legends']
      }
    />
  )
}

import { Api } from '../axios-config'
import { IProtocols } from '../protocols-services/ProtocolServices'

export interface FinishedProtocolsReport extends IProtocols {
  migrated: boolean
}

export interface FinishedProtocolData {
  protocols: FinishedProtocolsReport[]
  total: number
  completed: number
  rejected: number
  average_response_time: number
  average_time_as_pending: number
  average_processing_time: number
  average_time_as_extended: number
  average_delay_time: number
}

export const getFinishedProtocolReportData = async (
  formData: object,
): Promise<FinishedProtocolData | Error> => {
  try {
    const relativeUrl = '/V1/reports/protocols/finished_protocols/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao carregar relatório')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getSacFinishedProtocolReportData = async (
  formData: object,
): Promise<FinishedProtocolData | Error> => {
  try {
    const relativeUrl =
      '/V1/customer_service/reports/protocols/finished_protocols/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao carregar relatório')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, LinearProgress, Typography } from '@mui/material'
import { FC, MutableRefObject, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  SacForwards,
  getAllSacForwards,
} from '../../../../../shared/services/api/sac-services'
import { NotificacaoError } from '../../../../../shared/components'
import { FormatDateTime } from '../../../../../shared/utils'

interface EncaminhamentosProps {
  update: MutableRefObject<boolean>
}

export const Encaminhamentos: FC<EncaminhamentosProps> = ({ update }) => {
  const { id } = useParams<'id'>()
  const [data, setData] = useState<SacForwards[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getAllSacForwards(id).then((result) => {
        update.current = false
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setData(result)
        }
      })
    }
  }, [id, update.current])

  return (
    <Grid container spacing={2} direction="column">
      {isLoading && (
        <Grid item>
          <LinearProgress />
        </Grid>
      )}
      {data.length === 0 && !isLoading && (
        <Box display="flex" mx={2}>
          <Typography>nenhum encaminhamento feito</Typography>
        </Box>
      )}
      {data.map((forward, index) => (
        <Grid container item spacing={1} direction="column" key={index}>
          <Divider />
          <Grid item>
            <Typography>{`${forward.user.username} encaminhou`}</Typography>
          </Grid>
          <Grid item>
            <Typography>{`Email de destino: ${forward.email_address}`}</Typography>
          </Grid>
          <Grid item>
            <Typography>{`Assunto: ${forward.subject}`}</Typography>
          </Grid>
          <Grid item>
            <Typography>{`Comentário: ${forward.comment}`}</Typography>
          </Grid>
          <Grid item>
            <Typography>{`Data: ${FormatDateTime(
              forward.created_at,
            )}`}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

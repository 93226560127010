import { useNavigate, useParams } from 'react-router-dom'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDeDetalhes,
  LegendaSumario,
  NotificacaoError,
} from '../../../shared/components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import { FormularioAdmin } from './formulario/Formulário'
import {
  IADminHistory,
  ISectorUserDetail,
  getAdminById,
  getAdminHistory,
} from '../../../shared/services/api'
import { ExpandMore } from '@mui/icons-material'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'

export const DetalhesAdministrador: React.FC = () => {
  const navigate = useNavigate()
  const { id = 'new' } = useParams<'id'>()

  const updated = useRef(false)

  const [userData, setUserData] = useState<ISectorUserDetail>()
  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)
  const [historyLoading, setHistoryLoading] = useState(true)
  const [historyData, setHistoryData] = useState<IADminHistory[]>([])

  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')

  const handleViewForm = () => {
    setViewForm(!viewForm)
    setCreate(true)
  }

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true)
      getAdminHistory(id).then((result) => {
        setHistoryLoading(false)
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar histórico')
        } else {
          setHistoryData(result)
        }
      })

      getAdminById(Number(id)).then((result) => {
        updated.current = false
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError(result.message)
          navigate(-1)
        } else {
          setName(result.username)
          setUserData(result)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, updated.current])

  return (
    <LayoutBaseDePagina
      title={`Detalhes do Usuário ${name}` || 'Detalhes Usuário Administrador'}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          textoBotaoNovo="Novo"
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
          aoClicarEmVoltar={() => navigate('/ombudsman/managers')}
          mostrarBotaoApagar={false}
        />
      }
    >
      <Box
        gap={1}
        marginX={1}
        padding={1}
        paddingX={1}
        display="flex"
        flexDirection="column"
      >
        {FormularioAdmin(
          handleViewForm,
          viewForm,
          updated,
          create,
          !create ? userData : undefined,
        )}
        {isLoading && (
          <Box flex="1">
            <LinearProgress
              variant="indeterminate"
              sx={{ borderRadius: '50px', height: '5px' }}
            />
          </Box>
        )}
        <Box
          gap={1}
          marginX={1}
          padding={1}
          paddingX={1}
          display="flex"
          flexDirection="column"
        >
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <LegendaSumario icon="info_outlined" name="Informações" />
            </AccordionSummary>
            <AccordionDetails>
              <Box flex="1">
                <Grid container direction="column" spacing={2}>
                  <Grid container item spacing={1}>
                    <Grid item>
                      <Typography>Nome:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{userData?.username}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1}>
                    <Grid item>
                      <Typography>Status:</Typography>
                    </Grid>
                    <Grid item width="70px">
                      <Typography>
                        {FormatStatus(Boolean(userData?.is_active))}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1}>
                    <Grid item>
                      <Typography>Confirmado:</Typography>
                    </Grid>
                    <Grid item width="50px">
                      <Typography>
                        {FormatStatus(
                          Boolean(userData?.is_confirmed),
                          'Sim',
                          'Não',
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1}>
                    <Grid item>
                      <Typography>Email:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{userData?.email}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item>
                      <Typography>Último Login:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {FormatDateTime(
                          userData?.last_login,
                          'Ainda não logou',
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1}>
                    <Grid item>
                      <Typography>Documento:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{userData?.document_number}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item>
                      <Typography>Quantidade de logins:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{userData?.sign_in_count}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <LegendaSumario icon="restore" name="Histórico" />
            </AccordionSummary>
            <AccordionDetails>
              {historyLoading && (
                <Box flex="1" padding={1}>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{ borderRadius: '50px', height: '5px' }}
                  />
                </Box>
              )}
              {!!historyData && (
                <Grid container spacing={2} direction="column">
                  {historyData.map((query) => (
                    <>
                      <Divider variant="middle" />
                      <Typography>{query.field}</Typography>
                      {query.type === '~' ? (
                        <Grid container item>
                          {query.field === 'username' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Nome alterado de ({query.old}) para (
                                  {query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'email' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Email alterado de ({query.old}) para (
                                  {query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'document_type' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Tipo de documento alterado de{' '}
                                  {query.old === '1'
                                    ? 'CPF para CNPJ'
                                    : 'CNPJ para CPF'}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'document_number' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Número de documento alterado de ({query.old})
                                  para ({query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'password' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>Alterou a senha</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'is_active' && (
                            <Grid container item direction="column">
                              <Grid container item>
                                <Typography>
                                  {query.new
                                    ? 'Usuário foi ativado'
                                    : 'Usuário foi desativdo'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  {query.new
                                    ? `Ativado por: ${query.user}`
                                    : `Desativado por: ${query.user}`}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'is_confirmed' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>Confirmou a conta</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'confirmation_token' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Token de confirmação de conta gerado
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'confirmation_token_sent_at' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Token de confirmação enviado
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'reset_password_token' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Token de alteração de senha gerado ou removido
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'reset_password_sent_at' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Token de alteração de senha enviado
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'profile_picture' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>Foto de perfil alterada</Typography>
                              </Grid>
                              {query.user && (
                                <Grid item>
                                  <Typography>
                                    Alterada por: {query.user}
                                  </Typography>
                                </Grid>
                              )}
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      ) : (
                        <Grid container item direction="column">
                          <Grid item>
                            <Typography>
                              Usuário criado por {query.user || 'Não definido'}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography>
                              Data: {FormatDateTime(query.date, 'Não definido')}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </LayoutBaseDePagina>
  )
}

import { useState, useEffect, FC } from 'react'
import { Autocomplete, TextField, CircularProgress } from '@mui/material'
import { useDebounce } from '../../../../shared/hooks'
import { getAllExternals } from '../../../../shared/services/api'
import { NotificacaoError } from '../../../../shared/components'

interface AutocompleteCidadaoProps {
  onChange?: (e: number | undefined) => void
  setClear(e: boolean): void
  clear: boolean
}

type AutoCompleteOptions = {
  id: number
  label: string
}

export const AutocompleteCidadao: FC<AutocompleteCidadaoProps> = ({
  onChange,
  setClear,
  clear,
}) => {
  const { debounce } = useDebounce(1000)

  const [options, setOptions] = useState<AutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [value, setValue] = useState<AutoCompleteOptions | null>(null)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (clear) {
      setSearch('')
      setValue(null)
      setClear(false)
    }
  }, [clear, setClear])

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllExternals('0', '999999999', search).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar cidadãos')
        } else {
          setOptions(
            result.results.map((user) => ({
              id: Number(user.id),
              label: String(user.name),
            })),
          )
        }
      })
    })
  }, [debounce, search])

  return (
    <Autocomplete
      disablePortal
      value={value}
      options={options}
      loading={isLoading}
      openText="abrir"
      clearText="limpar"
      closeText="fechar"
      loadingText="Carregando..."
      noOptionsText="Nenhuma opção encontrada"
      onInputChange={(_, newValue) => setSearch(newValue)}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      onChange={(_, newValue) => {
        setValue(newValue)
        onChange?.(newValue?.id)
        setSearch('')
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          label="Cidadão"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

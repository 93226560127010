import { useEffect, useState, FC } from 'react'
import { Box, Divider, Icon, Link, Tooltip, Typography } from '@mui/material'

import {
  IProtocols,
  IReplie,
  getAnonymousReplies,
} from '../../../../../shared/services/api'
import { FormatDateTime } from '../../../../../shared/utils'
import { NotificacaoError } from '../../../../../shared/components'

interface AnonymousRepliesProps {
  protocolData: IProtocols | undefined
}

export const AnonymousReplies: FC<AnonymousRepliesProps> = ({
  protocolData,
}) => {
  const [replies, setReplies] = useState<IReplie[]>([])

  useEffect(() => {
    if (protocolData) {
      getAnonymousReplies({
        password: protocolData.anonymous_password,
        identifier: protocolData.identifier,
      }).then((result) => {
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setReplies(result)
        }
      })
    }
  }, [protocolData])
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {replies.map((reply, index) => (
        <Box
          key={index}
          display="flex"
          flexDirection="column"
          bgcolor={reply.type === 2 ? 'grey' : 'white'}
          border="2px solid black"
          borderRadius="10px"
        >
          {reply.file && (
            <Link
              sx={{
                cursor: 'pointer',
                color: reply.type === 1 ? 'black' : 'white',
              }}
              onClick={() => {
                window.open(reply.file, '_blank', 'noopener')
              }}
              variant="h6"
              underline="hover"
              paddingX={1}
              target="_blank"
              rel="noopener"
            >
              Anexo da resposta
            </Link>
          )}
          {reply.file_link.map((lnk, lnkIndex) => (
            <Link
              key={lnkIndex}
              sx={{
                cursor: 'pointer',
                color: reply.type === 1 ? 'black' : 'white',
              }}
              onClick={() => {
                window.open(lnk, '_blank', 'noopener')
              }}
              variant="h6"
              underline="hover"
              paddingX={1}
              target="_blank"
              rel="noopener"
            >
              Anexo da resposta
            </Link>
          ))}
          <Box display="flex" flexDirection="column">
            {reply.transfer && (
              <Typography marginLeft={2} variant="h6" color="white">
                TRANSFERÊNCIA DE SETOR
              </Typography>
            )}
            {reply.change_typology && (
              <Typography marginLeft={2} variant="h6" color="white">
                MUDANÇA DE TIPOLOGIA
              </Typography>
            )}
            <Box display="flex" flex="1" flexDirection="column">
              <Typography marginLeft={2}>
                {reply.user_id ? `- ${reply.user_id.username}` : ''}
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={1}
              flexDirection="row"
              alignItems="center"
              paddingX={1}
            >
              <Tooltip
                title={
                  reply.type === 1 ? 'Resposta Interna' : 'Resposta Externa'
                }
              >
                <Icon sx={{ color: reply.type === 1 ? 'black' : 'white' }}>
                  {reply.type === 1 ? 'https' : 'insert_comment'}
                </Icon>
              </Tooltip>
              <Divider orientation="vertical" variant="fullWidth" />
              <Box display="flex" flexDirection="column" gap={1}>
                {reply.reply.split('\n').map((text) => (
                  <Typography
                    key={index}
                    textAlign="justify"
                    sx={{ color: reply.type === 1 ? 'black' : 'white' }}
                  >
                    {text}
                  </Typography>
                ))}
              </Box>
            </Box>
            <Box display="flex" width="99%" justifyContent="right">
              <Typography variant="caption">
                {FormatDateTime(reply.created_at, 'Não definido')}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

import { Box } from '@mui/system'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import { Typography } from '@mui/material'

export const CIDashboard = () => {
  return (
    <LayoutBaseDePagina title="Dashboard">
      <Box>
        <Typography variant="h6" component="text">
          Isso Aqui Não é um Título
        </Typography>
      </Box>
    </LayoutBaseDePagina>
  )
}

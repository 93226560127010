import { useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import defaultLogo from '../../../images/default_logo.png'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Api } from '../../../shared/services/api/axios-config'
import {
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'

export const ConfirmarEmailTroca = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  const token = useMemo(() => {
    return searchParams.get('token') || ''
  }, [searchParams])

  useEffect(() => {
    if (token !== '') {
      setIsLoading(true)
      Api.post(`/V1/users/change_mail/`, { token })
        .then(() => {
          setIsLoading(false)
          NotificacaoSucesso('Email alterado com sucesso')
          setTimeout(function () {
            navigate('/admin')
          }, 2500)
        })
        .catch((error) => {
          setIsLoading(false)
          setError(true)
          NotificacaoError((error as { message: string }).message)
        })
    }
  }, [navigate, token])

  return (
    <Box
      display="flex"
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap={1}
    >
      <img src={defaultLogo} style={{ maxWidth: '300px' }} alt="logo" />
      <Box flexDirection="column" display="flex" gap={2}>
        <Typography textAlign="center">
          {isLoading
            ? 'Alterando email...'
            : !error
            ? 'Email alterado com sucesso'
            : 'Erro ao alterar email'}
        </Typography>
        {!isLoading && !error && (
          <caption>redirecionando para página de login...</caption>
        )}
      </Box>
    </Box>
  )
}

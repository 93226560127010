interface MenuOption {
  label: string
  icon: string
  path: string
}

export const master = (title: string): MenuOption[] => {
  return [
    {
      icon: 'leaderboard',
      label: 'Dashboard',
      path: `/customer-service/${title}/dashboard`,
    },
    {
      icon: 'confirmation_number',
      label: 'Protocolos',
      path: `/customer-service/${title}/protocols`,
    },
    {
      label: 'Agrupamentos',
      icon: 'apps',
      path: `/customer-service/${title}/groupings`,
    },
    {
      label: 'Setores',
      icon: 'safety_divider',
      path: `/customer-service/${title}/sectors`,
    },
    {
      label: 'Assuntos',
      icon: 'subject',
      path: `/customer-service/${title}/subjects`,
    },
    {
      label: 'Categorias',
      icon: 'sell',
      path: `/customer-service/${title}/categories`,
    },
    {
      label: 'Perfis S.A.C',
      icon: 'group',
      path: `/customer-service/${title}/users`,
    },
    {
      label: 'Usuários Externos',
      icon: 'contacts',
      path: `/customer-service/${title}/externalusers`,
    },
    {
      label: 'Perguntas',
      icon: 'question_answer',
      path: `/customer-service/${title}/questions`,
    },
    {
      label: 'Respostas Pré Definidas',
      icon: 'wrap_text',
      path: `/customer-service/${title}/default_replies`,
    },
    {
      label: 'Slides',
      icon: 'ondemand_video',
      path: `/customer-service/${title}/slides`,
    },
  ]
}

export const masterHR = (title: string): MenuOption[] => {
  return [
    {
      icon: 'leaderboard',
      label: 'Dashboard',
      path: `/customer-service/${title}/dashboard`,
    },
    {
      icon: 'confirmation_number',
      label: 'Protocolos',
      path: `/customer-service/${title}/protocols`,
    },
    {
      label: 'Relatórios',
      icon: 'assessment',
      path: `/customer-service/${title}/reports`,
    },
    {
      label: 'Agrupamentos',
      icon: 'apps',
      path: `/customer-service/${title}/groupings`,
    },
    {
      label: 'Setores',
      icon: 'safety_divider',
      path: `/customer-service/${title}/sectors`,
    },
    {
      label: 'Assuntos',
      icon: 'subject',
      path: `/customer-service/${title}/subjects`,
    },
    {
      label: 'Categorias',
      icon: 'sell',
      path: `/customer-service/${title}/categories`,
    },
    {
      label: 'Perfis S.A.C',
      icon: 'group',
      path: `/customer-service/${title}/users`,
    },
    {
      label: 'Usuários Externos',
      icon: 'contacts',
      path: `/customer-service/${title}/externalusers`,
    },
    {
      label: 'Perguntas',
      icon: 'question_answer',
      path: `/customer-service/${title}/questions`,
    },
    {
      label: 'Respostas Pré Definidas',
      icon: 'wrap_text',
      path: `/customer-service/${title}/default_replies`,
    },
    {
      label: 'Slides',
      icon: 'ondemand_video',
      path: `/customer-service/${title}/slides`,
    },
  ]
}

import { Box } from '@mui/material'
import { LoginLayout } from '../components/LoginLayout'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Api } from '../../../shared/services/api/axios-config'
import { IClientDetail, getSlides, ISlide } from '../../../shared/services/api'
import { NotificacaoError } from '../../../shared/components'
import { SlideMedia } from './SlideMedia'
import { useExternalAuthContext } from '../../../shared/contexts'
import { Loading } from '../components/Loading'
import { InstitutionError } from '../../outros/InstitutionNotFound'

export const ExternalLogin = () => {
  const { isAuthenticated } = useExternalAuthContext()

  const { slug } = useParams<'slug'>()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [slideLoading, setSlideLoading] = useState(true)
  const [institutionData, setInstitutionData] = useState<IClientDetail>()
  const [error, setError] = useState<string>()
  const [slides, setSlides] = useState<ISlide[]>([])

  useEffect(() => {
    const token = sessionStorage.getItem('EXTERNAL_TOKEN')
    if (token) {
      navigate(`/ouvidoria/${slug}/novoprotocolo`)
    }
  }, [isAuthenticated, navigate, slug])

  useEffect(() => {
    Api.get(`/V1/institution/${slug}/`)
      .then((result) => {
        setIsLoading(false)
        setInstitutionData(result.data)
      })
      .catch((error) => {
        setIsLoading(false)
        NotificacaoError(error.message)
        setError(error.message)
      })
  }, [slug])

  function getMetaName(metaName: string) {
    const metas = document.getElementsByTagName('meta')

    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') === metaName) {
        return metas[i]
      }
    }
    return null
  }

  useEffect(() => {
    if (institutionData) {
      const meta = getMetaName('description')
      if (meta !== null) {
        meta.setAttribute(
          'content',
          `${institutionData.name} - ${institutionData.ombudsman_title}`,
        )
      }
    }
  }, [institutionData])

  /**
   * 
  <meta property="og:title" content="The Rock"/>
  <meta property="og:type" content="movie"/>
  <meta property="og:url" content="http://www.imdb.com/title/tt0117500/"/>
  <meta property="og:image" content="http://ia.media-imdb.com/rock.jpg"/>
  <meta property="og:site_name" content="IMDb"/>
  <meta property="fb:admins" content="USER_ID"/>
  <meta property="og:description"
        content="A group of U.S. Marines, under command of
        a renegade general, take over Alcatraz and
        threaten San Francisco Bay with biological
        weapons."/>
   */

  useEffect(() => {
    if (institutionData) {
      getSlides(Number(institutionData.id))
        .then((result) => {
          setSlideLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            console.log(result)
            setSlides(result)
          }
        })
        .catch((error) => {
          setSlideLoading(false)
          NotificacaoError(error.message)
        })
    }
  }, [institutionData])

  return (
    <Box sx={{ display: 'flex' }} overflow="hidden">
      {isLoading ? (
        <Loading />
      ) : institutionData ? (
        <LoginLayout institutionData={institutionData}>
          {!slideLoading && <SlideMedia slides={slides} />}
        </LoginLayout>
      ) : (
        <InstitutionError status={error === 'Instituição Inativa'} />
      )}
    </Box>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react'
import {
  Modal,
  Box,
  Paper,
  Button,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
  LinearProgress,
} from '@mui/material'
import {
  ConfirmationNumber,
  Contacts,
  GroupAdd,
  SafetyDivider,
  Send,
} from '@mui/icons-material'

import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { Api } from '../../../../shared/services/api/axios-config'

interface ParticiparFormularioProps {
  show: boolean
  onClose(): void
  institution: number | undefined
}

export const ParticiparFormulario: FC<ParticiparFormularioProps> = ({
  onClose,
  show,
  institution,
}) => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const [isLoading, setIsLoading] = useState(false)

  const [users, setUsers] = useState<any>()
  const [sectors, setSectors] = useState<any>()
  const [protocols, setProtocols] = useState<any>()
  const [externalUsers, setExternalUsers] = useState<any>()

  const handleSendExternalUsers = () => {
    setIsLoading(true)
    const form = new FormData()
    form.append('backup_file', externalUsers, externalUsers.name)
    Api.post('/V1/migrations/participar/externalusers/', form)
      .then(() => {
        setIsLoading(false)
        NotificacaoSucesso('Backup de usuários externos inserido com sucesso')
      })
      .catch(() => {
        setIsLoading(false)
        NotificacaoError('Erro ao enviar usuários externos')
      })
  }

  const handleSendSectors = () => {
    setIsLoading(true)
    const form = new FormData()
    form.append('backup_file', sectors, sectors.name)
    form.append('institution_id', String(institution))
    Api.post('/V1/migrations/participar/sectors/', form)
      .then(() => {
        setIsLoading(false)
        NotificacaoSucesso('Backup de setores inserido com sucesso')
      })
      .catch(() => {
        setIsLoading(false)
        NotificacaoError('Erro ao enviar setores')
      })
  }

  const handleSendProtocols = () => {
    setIsLoading(true)
    const form = new FormData()
    form.append('backup_file', protocols, protocols.name)
    form.append('institution_id', String(institution))
    Api.post('/V1/migrations/participar/protocols/', form)
      .then(() => {
        setIsLoading(false)
        NotificacaoSucesso('Backup de manifestações inserido com sucesso')
      })
      .catch(() => {
        setIsLoading(false)
        NotificacaoError('Erro ao enviar manifestações')
      })
  }

  const handleSendUsers = () => {
    setIsLoading(true)
    const form = new FormData()
    form.append('backup_file', users, users.name)
    form.append('institution_id', String(institution))
    Api.post('/V1/migrations/participar/users/', form)
      .then(() => {
        setIsLoading(false)
        NotificacaoSucesso('Backup de usuários internos inserido com sucesso')
      })
      .catch(() => {
        setIsLoading(false)
        NotificacaoError('Erro ao enviar usuários internos')
      })
  }

  return (
    <Modal open={show} onClose={() => onClose()}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        overflow="auto"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(35)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Typography variant="h6" textAlign="center">
          Migrações Participar
        </Typography>
        <Divider />
        <Box margin={2}>
          {isLoading && (
            <Box marginBottom={2}>
              <LinearProgress variant="indeterminate" />
            </Box>
          )}
          <Box display="flex" flex="1" p={2} gap={2} flexDirection="column">
            <Typography textAlign="center" variant="caption">
              {externalUsers?.name}
            </Typography>
            <Box
              display="flex"
              flex="1"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                startIcon={<Contacts />}
                variant="contained"
                onClick={() =>
                  document.getElementById('external-users')?.click()
                }
              >
                Usuários Externos
              </Button>
              <Button
                startIcon={<Send />}
                variant="outlined"
                onClick={handleSendExternalUsers}
                disabled={isLoading || !externalUsers}
              >
                Enviar
              </Button>
            </Box>
            <Divider />
            <Typography textAlign="center" variant="caption">
              {sectors?.name}
            </Typography>
            <Box
              display="flex"
              flex="1"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                startIcon={<SafetyDivider />}
                variant="contained"
                onClick={() => document.getElementById('sectors')?.click()}
              >
                Setores
              </Button>
              <Button
                startIcon={<Send />}
                variant="outlined"
                onClick={handleSendSectors}
                disabled={isLoading || !sectors}
              >
                Enviar
              </Button>
            </Box>
            <Divider />
            <Typography textAlign="center" variant="caption">
              {users?.name}
            </Typography>
            <Box
              display="flex"
              flex="1"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                startIcon={<GroupAdd />}
                variant="contained"
                onClick={() => document.getElementById('users')?.click()}
              >
                Usuários Internos
              </Button>
              <Button
                startIcon={<Send />}
                variant="outlined"
                onClick={handleSendUsers}
                disabled={isLoading || !users}
              >
                Enviar
              </Button>
            </Box>
            <Divider />
            <Typography textAlign="center" variant="caption">
              {protocols?.name}
            </Typography>
            <Box
              display="flex"
              flex="1"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                startIcon={<ConfirmationNumber />}
                variant="contained"
                onClick={() => document.getElementById('protocols')?.click()}
              >
                Manifestações
              </Button>
              <Button
                startIcon={<Send />}
                variant="outlined"
                onClick={handleSendProtocols}
                disabled={isLoading || !protocols}
              >
                Enviar
              </Button>
            </Box>
          </Box>
        </Box>
        <input
          hidden
          type="file"
          id="external-users"
          onChange={(e) => {
            if (e.target.files) {
              setExternalUsers(e.target.files[0])
            }
          }}
        />
        <input
          hidden
          type="file"
          id="sectors"
          onChange={(e) => {
            if (e.target.files) {
              setSectors(e.target.files[0])
            }
          }}
        />
        <input
          hidden
          type="file"
          id="users"
          onChange={(e) => {
            if (e.target.files) {
              setUsers(e.target.files[0])
            }
          }}
        />
        <input
          hidden
          type="file"
          id="protocols"
          onChange={(e) => {
            if (e.target.files) {
              setProtocols(e.target.files[0])
            }
          }}
        />
      </Box>
    </Modal>
  )
}

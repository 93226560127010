/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Modal, Paper } from '@mui/material'
import { ColorPicker, useColor } from 'react-color-palette'

import { FormBoxStyle } from '../../../../shared/components'
import { ISlide } from '../../../../shared/services/api'

interface ICustomSketchPickerPropos {
  show: boolean
  onClose: () => void
  setColorRef: any
  slideData: ISlide | undefined
  type: string
}

export const CustomSketchPicker: React.FC<ICustomSketchPickerPropos> = ({
  show,
  onClose,
  slideData,
  setColorRef,
  type,
}) => {
  console.log(slideData)
  const [color] = useColor(
    type === 'button'
      ? slideData !== undefined
        ? slideData.button_color
        : '#1e90ff'
      : type === 'buttonHover'
      ? slideData !== undefined
        ? slideData.button_color_hover
        : '#005596'
      : type === 'text'
      ? slideData !== undefined
        ? slideData.text_color
        : '#000000'
      : slideData !== undefined
      ? slideData.text_color_hover
      : '#000000',
  )

  const handleSubmit = () => {
    onClose()
  }

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        display="flex"
        sx={FormBoxStyle}
        overflow="auto"
        component={Paper}
        justifyContent="center"
        borderRadius="20px"
        maxHeight="85%"
        flexDirection="column"
        width={280}
      >
        <ColorPicker
          height={260}
          color={color}
          onChange={(e: any) => setColorRef(e)}
        />
        <Box
          display="flex"
          width="100%"
          gap={1}
          justifyContent="right"
          paddingTop={1}
        >
          <Button
            variant="contained"
            sx={{ borderRadius: '50px' }}
            disableElevation
            onClick={handleSubmit}
          >
            confirmar
          </Button>
          <Button sx={{ borderRadius: '50px' }} onClick={() => onClose()}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

import { Api } from '../axios-config'

export interface IIconHistory {
  field: string
  type: '+' | '~'
  date: string
  user?: string
  old?: string
  new?: string
}

const getIconHistory = async (
  iconId: number,
): Promise<IIconHistory[] | Error> => {
  try {
    const relativeUrl = `/V1/icons/${iconId}/historical/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar histórico')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export { getIconHistory }

import { Autocomplete, TextField } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import { useDebounce } from '../../../../../shared/hooks'
import { useField } from '@unform/core'
import { getAllSacSubjects } from '../../../../../shared/services/api/sac-services'
import { NotificacaoError } from '../../../../../shared/components'
import { useAuthContext } from '../../../../../shared/contexts'
import { useModuleContext } from '../../../../../shared/contexts/ModulesContext'

type AutocompleteOption = {
  value: number
  label: string
}

interface AutoCompleteAssuntoSACProps {
  name: string
  sector: number | undefined
}

export const AutoCompleteAssuntoSAC: FC<AutoCompleteAssuntoSACProps> = ({
  name,
  sector,
}) => {
  const currentSector = useRef<number | undefined>(undefined)

  const { user } = useAuthContext()
  const { debounce } = useDebounce(1000)
  const { thisModule } = useModuleContext()
  const { fieldName, registerField, error, clearError } = useField(name)

  const [options, setOptions] = useState<AutocompleteOption[]>([])
  const [selectedOption, setSelectedOption] =
    useState<AutocompleteOption | null>(null)
  const [value, setValue] = useState<number | null>(null)

  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  useEffect(() => {
    setIsLoading(true)
    if (sector !== currentSector.current) {
      setValue(null)
      setSelectedOption(null)
      currentSector.current = sector
    }
    if (sector) {
      debounce(() => {
        getAllSacSubjects(
          '0',
          search,
          sector.toString(),
          '999999999',
          String(user?.institution_id),
          String(thisModule?.id),
        ).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError('Erro ao carregar setores')
          } else {
            setOptions(
              result.results.map((sector) => ({
                label: sector.name,
                value: sector.id,
              })),
            )
          }
        })
      })
    } else {
      setIsLoading(false)
      setValue(null)
      setSelectedOption(null)
      setOptions([])
    }
  }, [debounce, search, sector, thisModule?.id, user?.institution_id])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [value, fieldName, registerField])

  return (
    <Autocomplete
      fullWidth
      openText="Abrir"
      options={options}
      clearText="Limpar"
      closeText="Fechar"
      loading={isLoading}
      inputValue={search}
      value={selectedOption}
      loadingText="Carregando..."
      noOptionsText={sector ? 'Nenhum assunto econtrado' : 'Selecione um setor'}
      onInputChange={(_, newValue) => {
        setSearch(newValue)
        clearError()
      }}
      onChange={(_, newValue) => {
        setSelectedOption(newValue)
        setValue(newValue?.value || null)
        setSearch('')
        clearError()
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          fullWidth
          label="Assunto"
          size="small"
          error={!!error}
          helperText={error}
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

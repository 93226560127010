import { FC, useState } from 'react'
import {
  SacPergunta,
  createSacQuestion,
  updateSacQuestion,
} from '../../../../shared/services/api/sac-services/perguntas-services'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  FormBoxStyle,
  ModalHeader,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import {
  VForm,
  VSwitch,
  VTextArea,
  VTextField,
  useVForm,
} from '../../../../shared/forms'
import { Cancel, Save } from '@mui/icons-material'
import { IFormData, formValidationSchema } from './validate'
import { ValidationError } from 'yup'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'
import { AutocompleteSac } from '../../agrupamentos/components/AutocompleteSacs'

interface FormularioProps {
  show: boolean
  create: boolean
  data?: SacPergunta
  onClose: () => void
  institution: number
  setUpdate: () => void
}

export const Formulario: FC<FormularioProps> = ({ ...props }) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { formRef } = useVForm()
  const { thisModule } = useModuleContext()

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    formRef.current?.reset()
    props.onClose()
  }

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)
    if (!props.create && props.data) {
      formData.customer_service = props.data.customer_service
    } else if (thisModule?.profile !== 0 && thisModule) {
      formData.customer_service = thisModule.id
    }

    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData) => {
        if (props.create) {
          createSacQuestion(validatedData).then((result) => {
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              NotificacaoSucesso('Pergunta criada com sucesso!')
              props.setUpdate()
              handleClose()
            }
          })
        } else if (props.data) {
          updateSacQuestion(props.data.id, validatedData).then((result) => {
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              NotificacaoSucesso('Pergunta atualizada com sucesso!')
              props.setUpdate()
              handleClose()
            }
          })
        }
      })
      .catch((errors: ValidationError) => {
        const validationErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path] = error.message
          }
        })

        formRef.current?.setErrors(validationErrors)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Modal open={props.show} onClose={props.onClose}>
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader
          showCloseButton
          title={props.create ? 'Nova pergunta' : 'Editar pergunta'}
          onClose={handleClose}
        />

        <Divider />

        {isLoading && <LinearProgress sx={{ mt: 2 }} />}

        <Box margin={1}>
          <VForm
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={props.create ? undefined : props.data}
          >
            <Grid container spacing={2} direction="column" mt={1}>
              <Grid item xs={12}>
                <VTextArea
                  name="introduction"
                  minRows={4}
                  maxRows={8}
                  placeholder="Escreva aqui uma introdução *opcional*"
                  ariaLabel="Introdução"
                  style={{
                    width: '100%',
                    borderRadius: '5px',
                    fontVariant: 'historical-forms',
                    font: 'message-box',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <VTextField
                  required
                  fullWidth
                  size="small"
                  name="question"
                  label="Pergunta"
                  borderTopLeft="50px"
                  borderBottomLeft="50px"
                />
              </Grid>

              {thisModule?.profile === 0 && (
                <Grid item xs={12}>
                  <AutocompleteSac
                    name="customer_service"
                    institution={props.institution}
                  />
                </Grid>
              )}

              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <FormControlLabel
                  label="Status"
                  control={<VSwitch name="status" size="small" />}
                />
              </Grid>
            </Grid>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex="1"
              gap={1}
              mt={1}
            >
              <Button
                disableElevation
                disabled={isLoading}
                variant="contained"
                type="submit"
                startIcon={<Save />}
              >
                salvar
              </Button>
              <Button
                variant="outlined"
                type="button"
                startIcon={<Cancel />}
                onClick={handleClose}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

import { useState, useEffect, MutableRefObject } from 'react'
import * as yup from 'yup'
import { formatToCPF, formatToCNPJ, isCPF, isCNPJ } from 'brazilian-values'
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import {
  VForm,
  useVForm,
  VTextField,
  VSelect,
  VSwitch,
} from '../../../../shared/forms'
import {
  createAdmin,
  ISectorUserDetail,
  updateAdminById,
} from '../../../../shared/services/api'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
  NotificacaoPromise,
} from '../../../../shared/components'
import { Delete, PhotoCamera } from '@mui/icons-material'
import { useAuthContext } from '../../../../shared/contexts'

interface IFormData {
  id?: string
  email: string
  password?: string
  username: string
  is_active?: boolean
  document_type: number /* 1: CPF; 2: CNPJ */
  institution_id?: number
  document_number: string /* <xxx.xxx.xxx-xx | xx.xxx.xxx/xxxx-xx> */
}

const formValidationSchema: yup.Schema<IFormData> = yup.object({
  id: yup.string(),
  email: yup.string().required().email(),
  password: yup.string().min(6),
  username: yup.string().required().min(3),
  is_active: yup.boolean().default(false),
  document_type: yup.number().required().min(1).max(2),
  institution_id: yup.number(),
  document_number: yup
    .string()
    .required()
    .when('document_type', {
      is: 1,
      then: (schema) =>
        schema.test('cpf-test', 'CPF Inválido', (value) => isCPF(value)),
    })
    .when('document_type', {
      is: 2,
      then: (schema) =>
        schema.test('cnpj-test', 'CNPJ Inválido', (value) => isCNPJ(value)),
    }),
})

export const FormularioAdmin = (
  onClose: () => void,
  show = false,
  update: MutableRefObject<boolean>,
  create: boolean,
  userData?: ISectorUserDetail,
) => {
  const [formTitle, setFormTitle] = useState('')
  const { formRef } = useVForm()

  const { user } = useAuthContext()

  const [isLoading, setIsLoading] = useState(false)

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const [documentType, setDocumentType] = useState<1 | 2 | number>(
    Number(userData?.document_type) || 1,
  )
  const [profilePicture, setProfilePicture] = useState<File>()
  const [URLProfilePicture, setURLProfilePicture] = useState('')

  const formProfilePicture = new FormData()

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)
    if (create) {
      formData.institution_id = user?.institution_id
    } else {
      formData.institution_id = userData?.institution_id
    }
    console.log(formData)
    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData) => {
        create
          ? createAdmin(validatedData).then((result) => {
              setIsLoading(false)
              if (result instanceof Error) {
                NotificacaoError(result.message)
              } else {
                update.current = true
                onClose()
                NotificacaoSucesso('Usuário Criado com Sucesso')
                if (profilePicture) {
                  formProfilePicture.append(
                    'profile_picture',
                    profilePicture,
                    profilePicture.name,
                  )
                  NotificacaoPromise(
                    updateAdminById(Number(result), formProfilePicture),
                    'Enviando Foto de Perfil',
                    'Foto de Perfil Adcionada',
                    'Erro Ao Inserir Foto de Perfil',
                  )
                }
                formRef.current?.reset()
              }
            })
          : updateAdminById(Number(userData?.id), validatedData).then(
              (result) => {
                setIsLoading(false)
                if (result instanceof Error) {
                  NotificacaoError(result.message)
                } else {
                  update.current = true
                  onClose()
                  NotificacaoSucesso('Usuário Atualizado com Sucesso')
                  if (profilePicture) {
                    formProfilePicture.append(
                      'profile_picture',
                      profilePicture,
                      profilePicture.name,
                    )
                    NotificacaoPromise(
                      updateAdminById(Number(userData?.id), formProfilePicture),
                      'Enviando Foto de Perfil',
                      'Foto de Perfil Adcionada',
                      'Erro Ao Inserir Foto de Perfil',
                    )
                  }
                  formRef.current?.reset()
                }
              },
            )
      })
      .catch((errors: yup.ValidationError) => {
        const validationErros: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          setIsLoading(false)
          if (!error.path) return

          validationErros[error.path] = error.message
        })

        formRef.current?.setErrors(validationErros)
      })
  }

  useEffect(() => {
    setDocumentType(Number(userData?.document_type) || 1)
    if (!create && userData !== undefined)
      setFormTitle(`Editar Usuário ${userData.username}`)
    else setFormTitle('Novo Usuário Administrador')
  }, [create, show, userData])

  return (
    <Modal
      onClose={() => {
        onClose()
        URL.revokeObjectURL(URLProfilePicture)
        setProfilePicture(undefined)
      }}
      open={show}
      aria-describedby="modal-title"
    >
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Box display="flex" justifyContent="center" alignContent="center">
          <Typography
            variant="h6"
            component="h2"
            id="modal-title"
            textOverflow="ellipsis"
            noWrap
          >
            {formTitle}
          </Typography>
        </Box>

        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{ height: '5px', borderRadius: '50px' }}
          />
        )}
        <Box margin={1}>
          <VForm
            ref={formRef}
            onSubmit={(formData: IFormData) => handleSubmit(formData)}
            initialData={create ? { document_type: 1 } : userData}
          >
            <Grid container direction="column" spacing={2} padding={2}>
              <Grid container item spacing={2}>
                <Grid item display="flex" xs={12} justifyContent="center">
                  <Avatar
                    src={
                      profilePicture
                        ? URLProfilePicture
                        : userData?.profile_picture
                    }
                    sx={{
                      width: theme.spacing(12),
                      height: theme.spacing(12),
                      border: '2px solid grey',
                    }}
                  />
                </Grid>
                <Grid item display="flex" xs={12} justifyContent="center">
                  <Button
                    variant="contained"
                    disabled={!!profilePicture}
                    component="label"
                    endIcon={<PhotoCamera />}
                    disableElevation
                    sx={{ borderRadius: '50px' }}
                  >
                    Foto de Perfil
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        if (e.target.files?.length !== undefined) {
                          setProfilePicture(e.target.files[0])
                          setURLProfilePicture(
                            URL.createObjectURL(e.target.files[0]),
                          )
                        } else {
                          setProfilePicture(undefined)
                          URL.revokeObjectURL(URLProfilePicture)
                          setURLProfilePicture(userData?.profile_picture || '')
                        }
                      }}
                    />
                  </Button>
                  {!!profilePicture && (
                    <IconButton
                      onClick={() => {
                        URL.revokeObjectURL(URLProfilePicture)
                        setProfilePicture(undefined)
                        setURLProfilePicture('')
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item display="flex" xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="username"
                    label="Nome"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
                <Grid
                  container
                  item
                  display="flex"
                  spacing={2}
                  xs={12}
                  direction="row"
                >
                  <Grid
                    container
                    item
                    xs={12}
                    display="flex"
                    alignItems="start"
                  >
                    <Grid item xs={6} md={2}>
                      <VSelect
                        required
                        fullWidth
                        disabled={!create}
                        name="document_type"
                        size="small"
                        type="number"
                        sx={{
                          borderTopRightRadius: '0px',
                          borderTopLeftRadius: '50px',
                          borderBottomLeftRadius: '50px',
                          borderBottomRightRadius: '0px',
                        }}
                        myChange={(e) => setDocumentType(e)}
                      >
                        <MenuItem value={1}>CPF</MenuItem>
                        <MenuItem value={2}>CNPJ</MenuItem>
                      </VSelect>
                    </Grid>
                    <Grid item xs={6} md={3.5}>
                      <VTextField
                        fullWidth
                        required
                        disabled={!create}
                        formatData={
                          documentType === 1 ? formatToCPF : formatToCNPJ
                        }
                        name="document_number"
                        size="small"
                        label="Documento"
                        inputProps={{ maxLength: documentType === 1 ? 14 : 18 }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6.5}
                      paddingLeft={!mdDown ? 2 : 0}
                      paddingTop={mdDown ? 2 : 0}
                    >
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="email"
                        type="email"
                        label="Email"
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={<VSwitch name="is_active" />}
                      label="Status"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box display="flex" flex={1} justifyContent="center">
              <Button type="submit">
                <Icon>save</Icon>
                Salvar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

import { FC } from 'react'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import { Box, Typography } from '@mui/material'

export const AdmDashboard: FC = () => {
  return (
    <LayoutBaseDePagina title="Dashboard">
      <Box>
        <Typography>Dashboard ADM</Typography>
      </Box>
    </LayoutBaseDePagina>
  )
}

import { useState, useEffect, MutableRefObject } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  LinearProgress,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { VForm, useVForm, VTextField } from '../../../../shared/forms'
import {
  IWorkfield,
  createWorkfield,
  updateWorkfieldById,
} from '../../../../shared/services/api'

import formValidationSchema from './validation'
import { ValidationError } from 'yup'

interface IFormData {
  id?: string
  name: string
}

export const CriarRamoDeAtividade = (
  onClose: () => void,
  show = false,
  update: MutableRefObject<boolean>,
  create: boolean,
  data?: IWorkfield,
) => {
  const [formTitle, setFormTitle] = useState('')
  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)

    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData) => {
        if (create) {
          createWorkfield(validatedData).then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              update.current = true
              onClose()
              window.location.reload()
              NotificacaoSucesso('Ramo de Atividade Criado com Sucesso')
            }
          })
        } else {
          updateWorkfieldById(Number(data?.id), validatedData).then(
            (result) => {
              setIsLoading(false)
              if (result instanceof Error) {
                NotificacaoError(result.message)
              } else {
                update.current = true
                onClose()
                NotificacaoSucesso('Ramo de Atividade Atualizado com Sucesso')
              }
            },
          )
        }
      })
      .catch((errors: ValidationError) => {
        const validationErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          setIsLoading(false)

          if (!error.path) return

          validationErrors[error.path] = error.message
        })

        formRef.current?.setErrors(validationErrors)
      })
  }

  useEffect(() => {
    if (!create && data !== undefined)
      setFormTitle(`Editar Ramo de Atividade ${data.name}`)
    else setFormTitle('Novo Ramo de Atvidade')
  }, [create, data, show])

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Box display="flex" justifyContent="center" alignContent="center">
          <Typography
            variant="h6"
            component="h2"
            id="modal-title"
            textOverflow="ellipsis"
            noWrap
          >
            {formTitle}
          </Typography>
        </Box>

        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{ height: '5px', borderRadius: '50px' }}
          />
        )}
        <Box margin={1}>
          <VForm
            ref={formRef}
            onSubmit={(formData: IFormData) => handleSubmit(formData)}
            initialData={create ? undefined : data}
          >
            <Grid container direction="column" spacing={2} padding={2}>
              <Grid item>
                <VTextField
                  fullWidth
                  required
                  size="small"
                  name="name"
                  label="Nome"
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </Grid>
            </Grid>
            <Box display="flex" flex={1} justifyContent="center">
              <Button type="submit">
                <Icon>save</Icon>
                Salvar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

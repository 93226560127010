import { FC, useEffect } from 'react'
import { TableContainer } from '../../../../shared/components'
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { InfoOutlined } from '@mui/icons-material'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'
import { Environment } from '../../../../shared/environment'
import { FormatDate, FormatDateTime } from '../../../../shared/utils'
import { FinishedProtocolsReport } from '../../../../shared/services/api/relatorios-services/finished-protocols'

interface FinishedProtocolsTableProps {
  protocols?: FinishedProtocolsReport[]
  total?: number
  completed?: number
  rejected?: number
  averageResponseTime?: number
  averageTimeAsPending?: number
  averageProcessingTime?: number
  averageTimeAsExtended?: number
  averageDelayTime?: number
}

export const FinishedProtocolsTable: FC<FinishedProtocolsTableProps> = ({
  protocols = [],
  total = 0,
  completed = 0,
  rejected = 0,
  averageResponseTime = 0,
  averageTimeAsPending = 0,
  averageProcessingTime = 0,
  averageTimeAsExtended = 0,
  averageDelayTime = 0,
}) => {
  const { thisModule } = useModuleContext()

  useEffect(() => {
    console.log('Opa oipa')
  }, [])

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <TableContainer>
        <Typography textAlign="center">Dados gerais</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Manifestações finalizadas</TableCell>
              <TableCell align="center">Finalizadas como concluídas</TableCell>
              <TableCell align="center">Finalizadas como indeferidas</TableCell>
              <TableCell align="center">Tempo médio de resposta</TableCell>
              <TableCell align="center">Tempo médio como pendente</TableCell>
              <TableCell align="center">Tempo médio em trâmite</TableCell>
              <TableCell align="center">Tempo médio como prorrogada</TableCell>
              <TableCell align="center">Tempo médio em atraso</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell align="center">{total}</TableCell>
              <TableCell align="center">{completed}</TableCell>
              <TableCell align="center">{rejected}</TableCell>
              <TableCell align="center">{`${averageResponseTime} dias`}</TableCell>
              <TableCell align="center">{`${averageTimeAsPending} dias`}</TableCell>
              <TableCell align="center">{`${averageProcessingTime} dias`}</TableCell>
              <TableCell align="center">{`${averageTimeAsExtended} dias`}</TableCell>
              <TableCell align="center">{`${averageDelayTime} dias`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer>
        <Typography textAlign="center">Manifestações no período</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Identificador</TableCell>
              <TableCell align="center">Dias para finalização</TableCell>
              <TableCell align="center">Aberta em</TableCell>
              <TableCell align="center">Finalizada em</TableCell>
              <TableCell align="center">Dias como pendente</TableCell>
              <TableCell align="center">Dias em trâmite</TableCell>
              <TableCell align="center">Dias como prorrogada</TableCell>
              <TableCell align="center">Dias em atraso</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {protocols.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  borderLeft: `5px solid ${
                    row.status === 1
                      ? '#EB3324'
                      : row.status === 2
                      ? '#FFFF00'
                      : row.status === 3
                      ? '#CBAACB'
                      : row.status === 4
                      ? '#55CBCD'
                      : row.status === 5
                      ? '#8bdaa7'
                      : '#999999'
                  }`,
                }}
              >
                <TableCell align="center">
                  <Link
                    to={`/ombudsman/${thisModule?.title}/protocols/detail/${row.id}`}
                  >
                    <IconButton color="primary">
                      <InfoOutlined />
                    </IconButton>
                  </Link>
                </TableCell>

                <TableCell align="center">{row.identifier}</TableCell>
                <TableCell align="center">
                  {(row.pending_days || 0) +
                    (row.delayed_days || 0) +
                    (row.extended_days || 0) +
                    (row.analyzing_days || 0)}{' '}
                  dias
                </TableCell>
                <TableCell align="center">
                  {row.migrated
                    ? FormatDate(row.created_at, 'Não definido')
                    : FormatDateTime(row.created_at)}
                </TableCell>
                <TableCell align="center">
                  {row.migrated
                    ? FormatDate(row.finished_at, 'Não definido')
                    : FormatDateTime(row.finished_at)}
                </TableCell>
                <TableCell align="center">
                  {row.pending_days || 0} dias
                </TableCell>
                <TableCell align="center">
                  {row.analyzing_days || 0} dias
                </TableCell>
                <TableCell align="center">
                  {row.extended_days || 0} dias
                </TableCell>
                <TableCell align="center">
                  {row.delayed_days || 0} dias
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {protocols.length === 0 && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
        </Table>
      </TableContainer>
    </Box>
  )
}

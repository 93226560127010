import { FC, useState, useEffect } from 'react'
import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material'
import { useField } from '@unform/core'
import { useDebounce } from '../../../../shared/hooks'
import { getAllSacs } from '../../../../shared/services/api/modules-services/SacServices'
import { NotificacaoError } from '../../../../shared/components'

type AutocompleteOption = {
  label: string
  value: number
  logo: string
}

interface AutocompleteSacProps {
  institution: number | string
  setModules(modules: AutocompleteOption[]): void
}

export const AutocompleteSac: FC<AutocompleteSacProps> = ({
  institution,
  setModules,
}) => {
  const { debounce } = useDebounce(1000)

  const { fieldName, registerField, defaultValue } = useField('sacs')

  const [search, setSearch] = useState('')
  const [firstTime, setFirstTime] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<AutocompleteOption[]>([])
  const [selectedOption, setSelectedOption] = useState<AutocompleteOption[]>([])

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllSacs(search, '999999999', '0', String(institution)).then(
        (result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            setOptions(
              result.results.map((option) => ({
                label: option.sac_slug,
                logo: option.sac_logo,
                value: option.id,
              })),
            )
          }
        },
      )
    })
  }, [debounce, institution, search])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedOption,
      setValue: (_, newValue) => setSelectedOption(newValue),
    })
  }, [fieldName, registerField, selectedOption])

  useEffect(() => {
    if (defaultValue && firstTime && options.length > 0) {
      setSelectedOption(
        defaultValue.map((mdlLabel: string) =>
          options.find((opt) => opt.label === mdlLabel),
        ),
      )
      setFirstTime(false)
    }
  }, [defaultValue, firstTime, options])

  return (
    <Autocomplete
      multiple
      openText="Abrir"
      options={options}
      clearText="Limpar"
      closeText="Fechar"
      loading={isLoading}
      value={selectedOption}
      loadingText="Carregando..."
      noOptionsText="Nenhuma opção encontrada"
      getOptionDisabled={(opt) =>
        !!selectedOption.find((selected) => selected.label === opt.label)
      }
      onChange={(_, newValue) => {
        setSelectedOption(newValue)
        setModules(newValue)
      }}
      onInputChange={(_, newValue) => {
        setSearch(newValue)
      }}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img width={60} loading="lazy" src={option.logo} alt="logo" />
          {option.label}
        </Box>
      )}
      limitTags={1}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Módulo(s) S.A.C"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

export const admin = (title: string) => {
  return [
    {
      label: 'Dashboard',
      icon: 'leaderboard',
      path: `/ombudsman/${title}/dashboard`,
    },
    {
      label: 'Clientes',
      icon: 'apartment',
      path: `/ombudsman/${title}/clients`,
    },
    {
      label: 'Administradores',
      icon: 'manage_accounts',
      path: `/ombudsman/${title}/managers`,
    },
    {
      label: 'Ícones',
      icon: 'category',
      path: `/ombudsman/${title}/icons`,
    },
    {
      label: 'Agrupamentos',
      icon: 'apps',
      path: `/ombudsman/${title}/groupings`,
    },
    {
      label: 'Setores',
      icon: 'safety_divider',
      path: `/ombudsman/${title}/sectors`,
    },
    {
      label: 'Tipologia',
      icon: 'sell',
      path: `/ombudsman/${title}/typologies`,
    },
    {
      label: 'Assuntos',
      icon: 'subject',
      path: `/ombudsman/${title}/subjects`,
    },
    {
      label: 'Usuários Internos',
      icon: 'group_add',
      path: `/ombudsman/${title}/sectorsusers`,
    },
    {
      label: 'Usuários Externos',
      icon: 'contacts',
      path: `/ombudsman/${title}/externalusers`,
    },
    {
      label: 'Perguntas',
      icon: 'question_answer',
      path: `/ombudsman/${title}/questions`,
    },
    {
      label: 'Estados',
      icon: 'add_location_alt',
      path: `/ombudsman/${title}/states`,
    },
    {
      label: 'Cidades',
      icon: 'location_city',
      path: `/ombudsman/${title}/cities`,
    },
    {
      label: 'Ramo de Atividades',
      icon: 'lan',
      path: `/ombudsman/${title}/workfields`,
    },
    {
      label: 'Slides',
      icon: 'ondemand_video',
      path: `/ombudsman/${title}/slides`,
    },
    {
      label: 'Legislações',
      icon: 'gavel',
      path: `/ombudsman/${title}/legilsations`,
    },
    {
      label: 'Respostas Pré Definidas',
      icon: 'wrap_text',
      path: `/ombudsman/${title}/default_replies`,
    },
  ]
}

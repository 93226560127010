import { Api } from '../axios-config'

export interface IADminHistory {
  field: string
  type: '+' | '~'
  date: string
  user?: string
  old?: string
  new?: string
}

const getAdminHistory = async (
  userId = '',
): Promise<IADminHistory[] | Error> => {
  try {
    const relativeUrl = `/V1/adminusers/${userId}/historical/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message)
  }
}

export { getAdminHistory }

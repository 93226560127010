import { Box, Typography, LinearProgress } from '@mui/material'
import Logo from '../../../../images/default_logo.png'

export const LoadingMap = () => {
  return (
    <Box
      display="flex"
      gap={2}
      alignItems="center"
      flex="1 1"
      justifyContent="center"
      flexDirection="column"
    >
      <img src={Logo} alt="logo" width="280px" />
      <Typography>Carregando mapa...</Typography>
      <LinearProgress />
    </Box>
  )
}

import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useField } from '@unform/core'
import { FC, useEffect, useState } from 'react'
import { getAllSacSectorsExternal } from '../../../../../../shared/services/api/sac-services'
import { NotificacaoError } from '../../../../../../shared/components'
import { useDebounce } from '../../../../../../shared/hooks'

interface AutocompleteSacSectorProps {
  grouping: number | undefined
  setSector: (id: number | undefined) => void
}

type AutoCompleteOption = {
  id: number
  label: string
}

export const AutocompleteSacSector: FC<AutocompleteSacSectorProps> = ({
  grouping,
  setSector,
}) => {
  const { debounce } = useDebounce(1000)
  const { fieldName, registerField, error, clearError } = useField('sector')

  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<AutoCompleteOption[]>([])
  const [search, setSearch] = useState('')
  const [value, setValue] = useState<number>()
  const [selectedOption, setSelectedOption] =
    useState<AutoCompleteOption | null>(null)

  useEffect(() => {
    if (grouping) {
      setIsLoading(true)
      debounce(() => {
        getAllSacSectorsExternal(grouping, search)
          .then((result) => {
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              setOptions(
                result.map((sector) => ({ id: sector.id, label: sector.name })),
              )
            }
          })
          .finally(() => setIsLoading(false))
      })
    } else {
      setIsLoading(false)
    }
  }, [grouping, search])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [value, fieldName, registerField])

  return (
    <Autocomplete
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      loading={isLoading}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      noOptionsText={
        grouping ? 'Nenhum setor encontrado' : 'Selecione um agrupamento'
      }
      inputValue={search}
      value={selectedOption}
      options={grouping ? options : []}
      onChange={(_, newValue) => {
        setSearch('')
        setSelectedOption(newValue || null)
        setValue(newValue?.id)
        setSector(newValue?.id)
        clearError()
      }}
      onInputChange={(e, newValue) => {
        setSearch(newValue)
        clearError()
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          fullWidth
          size="small"
          label="Setor"
          error={!!error}
          helperText={error}
          sx={{ fieldSet: { borderRadius: '50px' } }}
        />
      )}
    />
  )
}

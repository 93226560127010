import { FC, useState, useEffect } from 'react'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import PlaceIcon from '@mui/icons-material/Place'
import { Autocomplete, Box, TextField, CircularProgress } from '@mui/material'

interface ILatLng {
  lat: number
  lng: number
}

interface PlacesAutocompleteProps {
  setSelected(coords: ILatLng): void
}

export const PlacesAutocomplete: FC<PlacesAutocompleteProps> = ({
  setSelected,
}) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete()

  const [firstTime, setFirstTime] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [selectedOption, setSelectedOption] =
    useState<google.maps.places.AutocompletePrediction | null>(null)

  const handleSelect = async (address: string) => {
    setValue(address, false)
    clearSuggestions()

    const results = await getGeocode({
      address,
      componentRestrictions: { country: 'br' },
    })
    const { lat, lng } = await getLatLng(results[0])

    setSelected({
      lat,
      lng,
    })
  }

  useEffect(() => {
    setIsLoading(false)
  }, [data])

  return (
    <Autocomplete
      fullWidth
      sx={{
        bgcolor: 'white',
        borderRadius: '50px',
      }}
      loading={!ready || isLoading}
      noOptionsText={
        ready && firstTime ? 'Pesquise um endereço' : 'Nenhum lugar encontrado'
      }
      openText="Abrir"
      disabled={!ready}
      clearText="Limpar"
      value={selectedOption}
      onChange={(_, newValue) => {
        handleSelect(newValue?.description || '')
        setSelectedOption(newValue)
      }}
      closeText="Fechar"
      popupIcon={
        isLoading || !ready ? <CircularProgress size={20} /> : undefined
      }
      loadingText="Carregando..."
      filterOptions={(options) => options}
      getOptionLabel={(opt) => opt.description}
      options={data.filter((opt) => opt.description.includes('Brasil'))}
      renderOption={(props, opt) => (
        <Box
          component="li"
          {...props}
          key={opt.place_id}
          display="flex"
          gap={2}
          alignItems="center"
          flexDirection="row"
        >
          <PlaceIcon sx={{ color: 'grey' }} />
          {opt.description}
        </Box>
      )}
      inputValue={value}
      onInputChange={(_, newValue) => {
        setValue(newValue)
        setFirstTime(false)
        setIsLoading(true)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Pesquise um endereço"
          size="small"
          InputLabelProps={{
            style: {
              color: 'black',
            },
          }}
          sx={{
            input: {
              color: 'black',
            },
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

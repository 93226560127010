import { InferType } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'

export const formValidationSchema = yup.object({
  customer_service: yup.number().required().positive(),
  title: yup
    .string()
    .required('O título é obrigatório')
    .max(255, 'Tamanho máximo de 255 carcateres')
    .min(3, 'Tamanho mínimo de 3 caracteres'),
  link: yup.string(),
  button_color: yup.string().default('#1976D2'),
  button_text: yup.string().default('Saiba mais'),
  button_text_color: yup.string().default('#FFFFFF'),
  status: yup.boolean().default(true),
  image: yup
    .mixed({
      check: (value): value is Blob => value instanceof Blob,
    })
    .test(
      'image-size-test',
      'Arquivo muito grande. Tamanho máximo permitido: 5MB',
      (file) => file === undefined || file.size <= 500000,
    ),
})

export type IFormData = InferType<typeof formValidationSchema>

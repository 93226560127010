import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material'
import { useField } from '@unform/core'
import { FC, useEffect, useState } from 'react'
import { getAllIcons } from '../../../../shared/services/api'
import { useDebounce } from '../../../../shared/hooks'
import { NotificacaoError } from '../../../../shared/components'

interface AutocompleteIconsProps {
  name: string
}

type AutocompleteOption = {
  label: string
  value: number
  icon: string
}

export const AutocompleteIcons: FC<AutocompleteIconsProps> = ({ name }) => {
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField(name)
  const { debounce } = useDebounce()

  const [firstTime, setFirstTime] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<AutocompleteOption[]>([])
  const [search, setSearch] = useState('')
  const [selectedOption, setSelectedOption] =
    useState<AutocompleteOption | null>(null)
  const [value, setValue] = useState<number | null>(defaultValue || null)

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllIcons('0', '999999999', search).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar ícones')
        } else {
          setOptions(
            result.results.map((icon) => ({
              icon: icon.icon,
              label: icon.name,
              value: icon.id,
            })),
          )
        }
      })
    })
  }, [debounce, search])

  useEffect(() => {
    if (firstTime && options.length > 0) {
      setValue(defaultValue)
      setSelectedOption(
        options.find((opt) => opt.value === defaultValue) || null,
      )
      setFirstTime(false)
    }
  }, [defaultValue, firstTime, options])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [fieldName, registerField, value])

  return (
    <Autocomplete
      disablePortal
      openText="Abrir"
      options={options}
      clearText="Limpar"
      closeText="Fechar"
      loading={isLoading}
      inputValue={search}
      loadingText="Carregando..."
      noOptionsText="Nenhum ícone encontrado"
      value={selectedOption}
      onChange={(_, newValue) => {
        clearError()
        setSelectedOption(newValue)
        setValue(newValue?.value || null)
      }}
      onInputChange={(_, newValue) => {
        setSearch(newValue)
        clearError()
      }}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img width={35} loading="lazy" src={option.icon} alt="icon" />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Ícone"
          error={!!error}
          helperText={error}
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  Box,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material'
import { useExternalAuthContext } from '../../../shared/contexts'
import { IProtocolsList, getUserProtocols } from '../../../shared/services/api'
import { NotificacaoError } from '../../../shared/components'
import { FormatProtocolStatus } from '../../../shared/utils'
import { Environment } from '../../../shared/environment'
import { useDebounce } from '../../../shared/hooks'

export const AcompanharManifestacoes: FC = () => {
  const { user } = useExternalAuthContext()
  const { slug } = useParams<'slug'>()

  const navigate = useNavigate()

  const { debounce } = useDebounce(1000)

  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const status = useMemo(() => {
    return searchParams.get('status') || ''
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])

  const tab = useMemo(() => {
    return searchParams.get('tab') || '1'
  }, [searchParams])

  const [rows, setRows] = useState<IProtocolsList[]>([])
  const [totalCount, setTotalCount] = useState(0)

  const pagina = Number(item) / Number(total) + 1

  const changePage = (value1: number, value2: number) => {
    const result = value1 * value2 - value2
    return result.toString()
  }

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getUserProtocols(item, total, user.id, user.token, status, busca).then(
        (result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            setRows(result.results)
            setTotalCount(result.count)
          }
        },
      )
    })
  }, [item, total, busca, status, debounce, user.id, user.token])

  return (
    <Box
      display="flex"
      flex="1"
      justifyContent="center"
      gap={1}
      flexDirection="column"
    >
      <Box
        component={Paper}
        display="flex"
        flex="1"
        marginX={1}
        border="1px solid dodgerblue"
        minHeight="80px"
        alignItems="center"
        paddingX={1}
        sx={{
          borderRadius: '15px',
        }}
      >
        <Grid container>
          <Grid item xs={8}>
            <TextField
              fullWidth
              size="small"
              label="Protocolo"
              sx={{
                fieldSet: {
                  borderTopLeftRadius: '50px',
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                  borderBottomLeftRadius: '50px',
                },
              }}
              value={busca}
              onChange={(e) =>
                setSearchParams(
                  {
                    tab,
                    busca: e.target.value || '',
                    status,
                    item,
                    total,
                  },
                  { replace: true },
                )
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                fullWidth
                size="small"
                label="Status"
                labelId="status-label"
                sx={{
                  borderTopLeftRadius: '0px',
                  borderTopRightRadius: '50px',
                  borderBottomLeftRadius: '0px',
                  borderBottomRightRadius: '50px',
                }}
                defaultValue={0}
                value={status === '' ? 0 : Number(status)}
                onChange={(e) => {
                  setSearchParams(
                    {
                      tab,
                      busca,
                      status: String(
                        e.target.value !== 0 ? e.target.value : '',
                      ),
                      item,
                      total,
                    },
                    { replace: true },
                  )
                }}
              >
                <MenuItem value={0}>Todos</MenuItem>
                <MenuItem value={1}>Atrasadas</MenuItem>
                <MenuItem value={2}>Pendentes</MenuItem>
                <MenuItem value={3}>Trâmites</MenuItem>
                <MenuItem value={4}>Prorrogadas</MenuItem>
                <MenuItem value={5}>Concluídas</MenuItem>
                <MenuItem value={6}>Indeferidas</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{
          m: 1,
          width: 'auto',
          color: 'dodgerblue',
          border: '1px solid',
          borderRadius: '15px',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Protocolo</TableCell>
              <TableCell align="center">Prazo</TableCell>
              <TableCell align="center">Agrupamento</TableCell>
              <TableCell align="center">Setor</TableCell>
              <TableCell align="center">Assunto</TableCell>
              <TableCell align="center">Categoria</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  borderLeft: `5px solid ${
                    row.status === 1
                      ? '#EB3324'
                      : row.status === 2
                      ? '#FFFF00'
                      : row.status === 3
                      ? '#CBAACB'
                      : row.status === 4
                      ? '#55CBCD'
                      : row.status === 5
                      ? '#8bdaa7'
                      : '#999999'
                  }`,
                }}
              >
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      navigate(
                        `/ouvidoria/${slug}/protocolos/detalhes/${row.id}`,
                      )
                    }
                  >
                    <Icon>info_outlined</Icon>
                  </IconButton>
                </TableCell>
                <TableCell align="center">{row.identifier}</TableCell>
                <TableCell align="center">{row.deadline}</TableCell>
                <TableCell align="center">
                  {row.grouping_id ? row.grouping_id.name : 'não definido'}
                </TableCell>
                <TableCell align="center">
                  {row.sector_id ? row.sector_id.name : 'não definido'}
                </TableCell>
                <TableCell align="center">
                  {row.subject_id ? row.subject_id.name : 'não definido'}
                </TableCell>
                <TableCell align="center">
                  {row.category_id ? row.category_id.name : 'não definido'}
                </TableCell>
                <TableCell align="center">
                  {FormatProtocolStatus(row.status)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={8}>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{ borderRadius: '50px', height: '5px' }}
                  />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 10 || totalCount > Number(total)) && (
              <TableRow>
                <TableCell colSpan={9}>
                  <Box display="flex" justifyContent="center">
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / Number(total))}
                      onChange={(_, newPage) => {
                        setSearchParams(
                          {
                            tab,
                            busca,
                            total,
                            status,
                            item: changePage(newPage, Number(total)),
                          },
                          {
                            replace: true,
                          },
                        )
                      }}
                    />
                    <Tooltip
                      title="Itens por Página"
                      open={open}
                      onMouseEnter={() => setOpen(true)}
                      onMouseDown={() => setOpen(false)}
                      onMouseLeave={() => setOpen(false)}
                    >
                      <Select
                        variant="outlined"
                        sx={{ borderRadius: '15px', height: '30px' }}
                        value={total}
                        onChange={(e) => {
                          setSearchParams(
                            {
                              tab,
                              busca,
                              item: '0',
                              status,
                              total: e.target.value,
                            },
                            {
                              replace: true,
                            },
                          )
                        }}
                      >
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="40">40</MenuItem>
                        <MenuItem value="50">50</MenuItem>
                      </Select>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  )
}

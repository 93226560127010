import { FC, useEffect, useMemo, useState, useRef } from 'react'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  Icon,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  IInstitutionsMigrated,
  getAllInstitutionsMigrated,
} from '../../../shared/services/api'
import {
  FerramentasDaListagem,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import { FormatDateTime } from '../../../shared/utils'
import { Environment } from '../../../shared/environment'
import { FormularioModal } from './formularios/Formularios'
import { ParticiparFormulario } from './formularios/ParticiparMigracao'
import { Button } from 'react-bootstrap'
import usePersistedState from '../../../shared/hooks/usePersistedState'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const Migracoes: FC = () => {
  const { moduleTitle } = useModuleContext()
  const navigate = useNavigate()
  const update = useRef(false)

  const [viewForm, setViewForm] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [institutions, setInstitutions] = useState<IInstitutionsMigrated[]>([])
  const [participar, setParticipar] = useState(false)

  const [searchParams] = useSearchParams()
  const search = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const [institution, setInstitution] = usePersistedState<number | undefined>(
    'ADM_SELECTED_INSTITUTION',
    undefined,
  )

  useEffect(() => {
    setIsLoading(true)
    getAllInstitutionsMigrated().then((result) => {
      setIsLoading(false)
      update.current = false
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        setInstitutions(result)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, searchParams, update.current])

  return (
    <LayoutBaseDePagina
      selectInstitution
      autoCompleteChange={(e) => setInstitution(e)}
      title={
        <Tooltip title="Ainda em fase de teste. Uso não liberado">
          <Typography variant="h6" textAlign="center">
            Migração de Dados
          </Typography>
        </Tooltip>
      }
      barraDeFerramentas={
        <FerramentasDaListagem aoClicarEmNovo={() => setViewForm(true)} />
      }
    >
      <TableContainer>
        <Button onClick={() => setParticipar(true)}>Participar</Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50} />
              <TableCell align="center">Antigo ID</TableCell>
              <TableCell align="center">Novo ID</TableCell>
              <TableCell align="center">App Relacionado</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center">Atualizado em</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {institutions.map((institution, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      navigate(
                        `/ombudsman/${moduleTitle}/clients/detail/${institution.id}`,
                      )
                    }
                  >
                    <Icon>info_outlined</Icon>
                  </IconButton>
                </TableCell>
                <TableCell align="center">{institution.old_id}</TableCell>
                <TableCell align="center">{institution.new_id}</TableCell>
                <TableCell align="center">
                  {institution.related_app === 1 ? 'Participar' : 'CI Digital'}
                </TableCell>
                <TableCell align="center">
                  {FormatDateTime(institution.created_at)}
                </TableCell>
                <TableCell align="center">
                  {FormatDateTime(institution.updated_at)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {institutions.length === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <FormularioModal
        create
        show={viewForm}
        update={update}
        onClose={() => setViewForm(false)}
      />
      <ParticiparFormulario
        onClose={() => setParticipar(false)}
        show={participar}
        institution={institution}
      />
    </LayoutBaseDePagina>
  )
}

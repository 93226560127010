/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Global Imports
import { FC, useEffect, useState } from 'react'
import { Box } from '@mui/material'

// Local Imports
import { getReport } from '../../../shared/services/api'
import { NotificacaoError } from '../../../shared/components'
import { SurveyTable } from './components/SurveyTable'
import { GenericTable } from './components/GenericTable'
import { FinishedProtocolsTable } from './components/FinishedProtocolsTable'
import {
  FinishedProtocolData,
  getFinishedProtocolReportData,
} from '../../../shared/services/api/relatorios-services/finished-protocols'

interface IDadosProps {
  type: number
  download: React.MutableRefObject<boolean>
  datesRef: any
  data?: any
  typeLabel: string | undefined
  generate: boolean
  setGenerate: () => void
}

export const Dados: FC<IDadosProps> = ({
  data,
  type,
  datesRef,
  generate,
  download,
  setGenerate,
}) => {
  const [view, setView] = useState(false)
  const [total, setTotal] = useState<any>()
  const [dates, setDates] = useState<string[]>([])
  const [result, setResult] = useState<any>({})
  const [columns, setColumns] = useState<string[]>([])
  const [groupings, setGroupings] = useState<any[]>([])

  const [finishedProtocolsData, setFinishedProtocolsData] =
    useState<FinishedProtocolData>()

  useEffect(() => {
    datesRef.current = dates
  }, [dates, datesRef])

  useEffect(() => {
    if (generate && !!data) {
      if (type !== 23) {
        getReport(data).then((result) => {
          setGenerate()
          if (result instanceof Error) {
            download.current = false
            NotificacaoError(result.message)
          } else {
            setView(true)
            setResult(result)
            download.current = true
          }
        })
      } else {
        console.log(data)
        getFinishedProtocolReportData({
          initial: data.initial,
          final: data.final,
        })
          .then((res) => {
            if (res instanceof Error) {
              NotificacaoError(res.message)
            } else {
              setFinishedProtocolsData({
                average_delay_time: res.average_delay_time,
                average_processing_time: res.average_processing_time,
                average_response_time: res.average_response_time,
                average_time_as_extended: res.average_time_as_extended,
                average_time_as_pending: res.average_time_as_pending,
                completed: res.completed,
                protocols: res.protocols,
                rejected: res.rejected,
                total: res.total,
              })
              setView(true)
            }
          })
          .finally(() => setGenerate())
      }
    }
  }, [data, download, generate, setGenerate, type])

  useEffect(() => {
    if (type !== 22 && type !== 23) {
      const periods: any[] = []
      const groups: any[] = []
      Object.keys(result).forEach((date: string) => {
        periods.push(date)
        groups.push(result[date])
      })
      setDates(periods)
      setGroupings(groups)
    }
  }, [result, type])

  useEffect(() => {
    if (type !== 22 && type !== 23) {
      const col: string[] = []
      const total: any = {}
      groupings.map((periodData) => {
        periodData.map((data: { [key: string]: number }) => {
          Object.keys(data).forEach((column: string) => {
            if (!col.includes(column)) {
              col.push(column)
              total[column] = data[column]
            } else {
              total[column] = total[column] + data[column]
            }
          })
          setColumns(col)
        })
      })
      setTotal(total)
    }
  }, [groupings, type])

  return (
    <Box
      display="flex"
      padding={2}
      overflow="auto"
      flexDirection="column"
      id="meu-container"
      sx={{
        overflowX: 'hidden',
      }}
    >
      {view && type < 22 ? (
        <GenericTable
          columns={columns}
          dates={dates}
          groupings={groupings}
          total={total}
        />
      ) : view && type === 22 ? (
        <SurveyTable result={result} type={type} />
      ) : view && type === 23 ? (
        <FinishedProtocolsTable
          protocols={finishedProtocolsData?.protocols}
          averageDelayTime={finishedProtocolsData?.average_delay_time}
          averageProcessingTime={finishedProtocolsData?.average_processing_time}
          averageResponseTime={finishedProtocolsData?.average_response_time}
          averageTimeAsExtended={
            finishedProtocolsData?.average_time_as_extended
          }
          averageTimeAsPending={finishedProtocolsData?.average_time_as_pending}
          completed={finishedProtocolsData?.completed}
          rejected={finishedProtocolsData?.rejected}
          total={finishedProtocolsData?.total}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}

import { Grid, LinearProgress, Typography } from '@mui/material'
import { FC } from 'react'
import { IExternalUsers } from '../../../../shared/services/api'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { FormatDate } from '../../../../shared/utils'

interface IDisplayDataProps {
  data: IExternalUsers | undefined
  isLoading: boolean
}

export const DisplayData: FC<IDisplayDataProps> = ({ data, isLoading }) => {
  return (
    <Grid container spacing={2} direction="column">
      {isLoading && (
        <Grid item>
          <LinearProgress />
        </Grid>
      )}

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Nome: </Typography>
        </Grid>
        <Grid item>
          <Typography>{data?.name}</Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Email: </Typography>
        </Grid>
        <Grid item>
          <Typography>{data?.email || 'Não cadastrado'}</Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Celular: </Typography>
        </Grid>
        <Grid item>
          <Typography>{data?.number}</Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Escolaridade: </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {data?.escolarity === 1
              ? 'Não alfabetizado'
              : data?.escolarity === 2
              ? 'Ensino fundamental incompleto'
              : data?.escolarity === 3
              ? 'Ensino fundamental completo'
              : data?.escolarity === 4
              ? 'Ensino médio incompleto'
              : data?.escolarity === 5
              ? 'Ensino médio completo'
              : data?.escolarity === 6
              ? 'Técnico incompleto'
              : data?.escolarity === 7
              ? 'Técnico completo'
              : data?.escolarity === 8
              ? 'Superior incompleto'
              : data?.escolarity === 9
              ? 'Superior completo'
              : 'Não respondeu'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Documento: </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {formatToCPFOrCNPJ(String(data?.document_number))}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Data de nascimento: </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {FormatDate(data?.birth_date, 'Não definido')}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Quantidade de manifestações: </Typography>
        </Grid>
        <Grid item>
          <Typography>{data?.protocols}</Typography>
        </Grid>
        <Grid item></Grid>
      </Grid>
    </Grid>
  )
}

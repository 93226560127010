/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

export interface IWorkfield {
  id: number
  name: string
  created_at: string
}

export type IWorkfieldTotalCount = {
  data: IWorkfield[]
  count: number
  next: string
  previous: string
  results: IWorkfield[]
}

const getAllWorkfields = async (
  item = '0',
  total = '10',
  filter = '',
  workfieldId = '',
): Promise<IWorkfieldTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/workfields/?total=${total}&item=${item}&search=${filter}&id=${workfieldId}`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return {
        data,
        results: data.results,
        count: data.count,
        next: data.next,
        previous: data.previous,
      }
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getWorkfieldById = async (id: number): Promise<IWorkfield | Error> => {
  try {
    const relativeUrl = `/V1/workfields/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return data
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao listar registro',
    )
  }
}

const createWorkfield = async (formData: {}): Promise<string | Error> => {
  try {
    const relativeUrl = '/V1/workfields/'

    const { data } = await Api.post<IWorkfield>(relativeUrl, formData)

    if (data) return data.id ? data.id.toString() : data.name

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const updateWorkfieldById = async (
  id: number,
  formData: {},
): Promise<IWorkfield | Error> => {
  try {
    const relativeUrl = `/V1/workfields/${id}/`

    const { data } = await Api.patch<IWorkfield>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

export {
  getAllWorkfields,
  getWorkfieldById,
  createWorkfield,
  updateWorkfieldById,
}

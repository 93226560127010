import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  Typography,
} from '@mui/material'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'
import { IClientDetail } from '../../../shared/services/api'
import { VForm, VTextField, useVForm } from '../../../shared/forms'
import { useState } from 'react'
import { formatToCPFOrCNPJ, isCPFOrCNPJ } from 'brazilian-values'
import { LoginOutlined } from '@mui/icons-material'
import * as yup from 'yup'
import { Api } from '../../../shared/services/api/axios-config'
import { setCookie } from 'typescript-cookie'
import { useParams } from 'react-router-dom'

interface ILoginModalProps {
  show: boolean
  onClose: () => void
  institutionData: IClientDetail
}

interface ILoginData {
  document_number: string
  password: string
}

const formValidationSchema: yup.Schema<ILoginData> = yup.object({
  document_number: yup
    .string()
    .required()
    .test('valid-document', 'Número de Documento Inválido', (value) =>
      isCPFOrCNPJ(value),
    ),
  password: yup.string().required().min(6),
})

export const LoginModal: React.FC<ILoginModalProps> = ({
  show,
  onClose,
  institutionData,
}) => {
  const { slug } = useParams<'slug'>()

  const { formRef } = useVForm()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (loginData: ILoginData) => {
    setIsLoading(true)
    formValidationSchema
      .validate(loginData, { abortEarly: false })
      .then((validatedData) => {
        Api.post('/V1/external_auth/', validatedData)
          .then((result) => {
            NotificacaoSucesso('Logado com sucesso')
            sessionStorage.setItem('EXTERNAL_TOKEN', result.data.token)
            sessionStorage.setItem('EXTERNAL_NAME', result.data.name)
            sessionStorage.setItem('EXTERNAL_ID', result.data.id)
            const cDate = new Date(new Date().getTime() + 60 * 60 * 1000)
            setCookie('external', result.data.token, {
              expires: cDate,
              path: '',
            })
            setIsLoading(false)
            window.location.replace(`/ouvidoria/${slug}/novoprotocolo`)
          })
          .catch((error) => {
            NotificacaoError(error.message)
            setIsLoading(false)
          })
      })
      .catch((errors: yup.ValidationError) => {
        const validatedErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validatedErrors[error.path] = error.message
        })
        formRef.current?.setErrors(validatedErrors)
        setIsLoading(false)
      })
  }

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        display="flex"
        overflow="auto"
        maxHeight="85%"
        minWidth="280px"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        flexDirection="column"
        justifyContent="center"
      >
        <img src={institutionData.logo} width="300px" alt="logo" />
        <Box display="flex" width="100%" justifyContent="center">
          <Typography variant="caption">Faça Log-in</Typography>
        </Box>
        <Box paddingBottom={2} width="100%">
          <Divider />
        </Box>
        <VForm ref={formRef} onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            spacing={2}
            padding={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid container item>
              <Grid item xs={12}>
                <VTextField
                  fullWidth
                  required
                  size="small"
                  name="document_number"
                  label="CPF / CNPJ"
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                  formatData={formatToCPFOrCNPJ}
                  inputProps={{
                    maxLength: 18,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={12}>
                <VTextField
                  fullWidth
                  required
                  size="small"
                  name="password"
                  type="password"
                  label="Senha"
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                />
              </Grid>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" gap={1}>
            <Button
              sx={{ borderRadius: '50px' }}
              type="submit"
              variant="contained"
              startIcon={<LoginOutlined />}
              disabled={isLoading}
            >
              entrar
            </Button>
            <Button
              sx={{ borderRadius: '50px' }}
              onClick={() =>
                window.open(
                  `/ouvidoria/${slug}/redefinirsenha`,
                  '_blank',
                  'noopener',
                )
              }
            >
              esqueci a senha
            </Button>
          </Box>
        </VForm>
      </Box>
    </Modal>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import axios from 'axios'
import { Api } from '../axios-config'
import {
  IProtocols,
  IProtocolsTotalCount,
} from '../protocols-services/ProtocolServices'
import { ISlide } from '../slides-services/SlidesServices'
import { Environment } from '../../../environment'
import { IClientDetail } from '../clients-services/ClientsServices'

export interface IExternalUsers {
  id?: number
  name?: string
  email?: string
  number: string
  gender?: number
  password?: string
  escolarity: number
  protocols?: number
  birth_date?: string
  document_type?: string
  sign_in_count?: number
  last_sign_in_at: string
  document_number?: string
}

export type IExternalsTotalCount = {
  next: string
  count: number
  previous: string
  data: IExternalUsers[]
  results: IExternalUsers[]
}

const getAllExternals = async (
  item = '0',
  total = '10',
  filter = '',
): Promise<IExternalsTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/external_users/?item=${item}&total=${total}&search=${filter}`

    const { data } = await Api.get(relativeUrl)

    if (data)
      return {
        data,
        results: data.results,
        count: data.count,
        next: data.next,
        previous: data.previous,
      }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getExternalById = async (id: number): Promise<IExternalUsers | Error> => {
  try {
    const relativeUrl = `/V1/external_users/${id}/`

    const { data } = await Api.get<IExternalUsers>(relativeUrl)

    if (data) return data

    return new Error('Erro ao consultar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const updateExternalById = async (
  id: number,
  formData: {},
): Promise<IExternalUsers | Error> => {
  try {
    const relativeUrl = `/V1/external_users/${id}/`

    const { data } = await Api.patch<IExternalUsers>(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao retornar dados')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const createExternal = async (
  formData: {},
  internal = true,
): Promise<IExternalUsers | Error | { fieldErrors: string }> => {
  const cookies = document.cookie.split(';')
  const cookie = String(
    cookies.filter((element) => element.includes('csrftoken')),
  )
  const csrftoken = cookie.split('=')[1]

  try {
    const relativeUrl = '/V1/external_users/'

    let resdata: any

    if (internal) {
      const { data } = await Api.post<IExternalUsers>(relativeUrl, formData)
      resdata = data
    } else {
      const { data } = await axios.post(
        `${Environment.URL_BASE}${relativeUrl}`,
        formData,
        {
          headers: {
            'X-CSRFToken': csrftoken,
          },
        },
      )
      resdata = data
    }

    if (resdata) return resdata

    return Error('Erro ao retornar registro')
  } catch (error) {
    if (error instanceof Error) {
      return new Error((error as { message: string }).message)
    }
    return new Error(JSON.stringify(error))
  }
}

const getSlides = async (institutionId: number): Promise<ISlide[] | Error> => {
  try {
    const relativeUrl = `/V1/externalusers/slides/institution/${institutionId}/`

    const { data } = await Api.get<ISlide[]>(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar slides')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getUserProtocols = async (
  item = '0',
  total = '10',
  userId: number | string,
  token?: string,
  status = '',
  search = '',
): Promise<IProtocolsTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/externalusers/${userId}/protocols/?item=${item}&total=${total}&status=${status}&search=${search}`

    const headers = token ? { Authorization: `Token ${token}` } : {}

    const { data } = await Api.get(relativeUrl, {
      headers,
    })

    if (data) return data
    return new Error('Erro ao listar registros')
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message)
  }
}

const getUserProtocolById = async (
  id: string,
  userId: number,
  token: string,
): Promise<IProtocols | Error> => {
  try {
    const relativeUrl = `/V1/externalusers/${userId}/protocols/${id}/`

    const headers = token ? { Authorization: `Token ${token}` } : {}

    const { data } = await Api.get(relativeUrl, { headers })

    if (data) return data

    return new Error('Erro ao carregar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getSelf = async (token: string): Promise<IExternalUsers | Error> => {
  try {
    const relativeUrl = '/V1/external/get_self/profile/'

    const headers = token ? { Authorization: `Token ${token}` } : {}

    const { data } = await Api.get(relativeUrl, {
      headers,
    })

    if (data) return data
    return new Error('Erro ao carregar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const updateSelf = async (
  token: string,
  formData: any,
): Promise<IExternalUsers | Error> => {
  try {
    const relativeUrl = '/V1/external/get_self/profile/'

    const headers = token ? { Authorization: `Token ${token}` } : {}
    const cookies = document.cookie.split(';')
    const cookie = String(
      cookies.filter((element) => element.includes('csrftoken')),
    )
    const csrftoken = cookie.split('=')[1]
    axios.defaults.headers.common['X-CSRFToken'] = csrftoken
    const { data } = await axios.patch(
      `${Environment.URL_BASE}${relativeUrl}`,
      formData,
      {
        headers,
      },
    )

    if (data) return data
    return new Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getInstitutions = async (
  slug: string,
): Promise<IClientDetail | Error> => {
  try {
    const relativeUrl = `/V1/institution/${slug}/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar dados da instituição')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export {
  getSelf,
  getSlides,
  updateSelf,
  createExternal,
  getAllExternals,
  getExternalById,
  getInstitutions,
  getUserProtocols,
  updateExternalById,
  getUserProtocolById,
}

// Global Imports
import { FC } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'

// Local imports
import defaultLogo from '../../images/default_logo.webp'

interface IInstitutionErrorProps {
  status: boolean
}

export const InstitutionError: FC<IInstitutionErrorProps> = ({ status }) => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      width="100vw"
      display="flex"
      height="100vh"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <img
        src={defaultLogo}
        title="TickDoc Logo"
        alt="TickDoc Logo"
        width={xsDown ? '300px' : smDown ? '400px' : '500px'}
      />
      {status ? (
        <>
          <Typography variant="h6" color="dodgerblue">
            Instituição desativada
          </Typography>
          <Typography variant="caption">
            não é possível acessar essa ouvidoria no momento
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h6" color="dodgerblue">
            Hm... Essa ouvidoria não faz parte da Rede Participar
          </Typography>
          <Typography variant="caption">
            verifique se a URL foi digitada corretamente
          </Typography>
        </>
      )}
    </Box>
  )
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useMemo, useEffect, useState, useCallback } from 'react'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import { Environment } from '../../environment'
import { LoadingMap } from './components/Loading'
import { PlacesAutocomplete } from './components/Autocomplete'

import './styles.css'

interface MapboxProps {
  lat: React.MutableRefObject<number | undefined>
  lng: React.MutableRefObject<number | undefined>
}

interface ILatLng {
  lat: number
  lng: number
}

export const Mapbox: FC<MapboxProps> = ({ lat, lng }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: String(Environment.GOOGLE_MAPS_API_KEY),
    libraries: ['places'],
  })

  const [map, setMap] = useState<google.maps.Map>()
  const [selected, setSelected] = useState<ILatLng>()
  const center = useMemo(() => {
    return {
      lat: -3.736401,
      lng: -38.4880102,
    }
  }, [])

  const handleMapClick = useCallback((e: google.maps.MapMouseEvent) => {
    setSelected({
      lat: e.latLng!.lat(),
      lng: e.latLng!.lng(),
    })
  }, [])

  useEffect(() => {
    if ('geolocation' in navigator && map) {
      navigator.geolocation.getCurrentPosition((currentPosition) => {
        setSelected({
          lat: currentPosition.coords.latitude,
          lng: currentPosition.coords.longitude,
        })
      })
    }
  }, [map])

  useEffect(() => {
    lat.current = selected?.lat
    lng.current = selected?.lng
  }, [lat, lng, selected])

  if (!isLoaded) return <LoadingMap />
  return (
    <div className="map-container">
      <div className="places-container">
        <PlacesAutocomplete setSelected={setSelected} />
      </div>
      <GoogleMap
        zoom={15}
        onLoad={setMap}
        onClick={handleMapClick}
        center={selected || center}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
      >
        {selected && <Marker position={selected} />}
      </GoogleMap>
    </div>
  )
}

export const Environment = {
  /**
   * Define a quantidade de linhas a ser carregado por padrão nas listagens
   */
  LIMITE_DE_LINHAS: 10,
  /** .
   * Placeholder exibido nas inputs
   */
  INPUT_DE_BUSCA: 'Pesquisar...',
  /**
   * Texto exibido quando nenhum elemento é encontrado
   */
  LISTAGEM_VAZIA: 'Nenhum Registro Encontrado',
  /**
   * URL Base de consulta dos dados dessa aplicação
   */
  URL_BASE: process.env.REACT_APP_URL_BASE,
  /**
   * URL Base de conexão do WebSocket
   */
  URL_WEBSOCKET: process.env.REACT_APP_WEBSOCKET_BASE,
  /**
   * API KEY Para consulta ao Google Maps
   */
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_MAPS_API_KEY,
  /**
   * URL do FrontEnd
   */
  FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
}

import { useNavigate, useParams } from 'react-router-dom'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDeDetalhes,
  NotificacaoError,
} from '../../../shared/components'
import { Box, LinearProgress, Paper, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { CriarRamoDeAtividade } from './formulario/Formulário'
import { IWorkfield, getWorkfieldById } from '../../../shared/services/api'

export const DetalhesRamoDeAtividades: React.FC = () => {
  const navigate = useNavigate()
  const { id = 'new' } = useParams<'id'>()

  const updated = useRef(false)

  const [data, setData] = useState<IWorkfield>()
  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)

  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')

  const handleViewForm = () => {
    setViewForm(!viewForm)
    setCreate(true)
  }

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true)

      getWorkfieldById(Number(id)).then((result) => {
        updated.current = false
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError(result.message)
          navigate(-1)
        } else {
          setName(result.name)
          setData(result)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, updated.current])

  return (
    <LayoutBaseDePagina
      title={`Ramo de Atividade ${name}` || 'Detalhes Ramo de Atividades'}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          textoBotaoNovo="Novo"
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
          aoClicarEmVoltar={() => navigate('/ombudsman/workfields')}
          mostrarBotaoApagar={false}
        />
      }
    >
      <Box
        gap={1}
        marginX={1}
        padding={1}
        paddingX={1}
        display="flex"
        alignItems="center"
        component={Paper}
        borderRadius="15px"
        border="1px solid dodgerblue"
      >
        {CriarRamoDeAtividade(
          handleViewForm,
          viewForm,
          updated,
          create,
          !create ? data : undefined,
        )}
        <Typography>Ramo de Atividade {id}</Typography>
      </Box>
      {isLoading && (
        <LinearProgress
          variant="indeterminate"
          sx={{ borderRadius: '50px', height: '5px' }}
        />
      )}
    </LayoutBaseDePagina>
  )
}

import { Box, Tab, Tabs, Typography, BoxProps } from '@mui/material'
import { FC } from 'react'

export interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export type TTabBoxProps = BoxProps & {
  borderBottom: number | string
  borderColor: string
}

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const VTab = Tab

export const VTabs = Tabs

export const TabPanel: FC<TabPanelProps> = ({
  index,
  value,
  children,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export const TabBox: FC<TTabBoxProps> = ({
  borderBottom,
  borderColor,
  ...rest
}) => {
  return <Box sx={{ borderBottom, borderColor }} {...rest} />
}

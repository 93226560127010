/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, MutableRefObject } from 'react'
import * as yup from 'yup'
import { formatToCPF, formatToCNPJ, isCPF, isCNPJ } from 'brazilian-values'
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  FormControl,
  InputLabel,
  Switch,
  TextField,
} from '@mui/material'

import {
  VForm,
  useVForm,
  VTextField,
  VSelect,
  VSwitch,
} from '../../../../shared/forms'
import {
  createInternal,
  ISectorUserDetail,
  updateInternalById,
} from '../../../../shared/services/api'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoPromise,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { Delete, PhotoCamera } from '@mui/icons-material'
import { AutoComplete } from '../components/AutoComplete'
import { useAuthContext } from '../../../../shared/contexts'
import { MultiSelect } from '../components/MultiSelect'
import { ModalHeader } from '../../../../shared/components/modal/Header'

interface IFormData {
  id?: string
  email: string
  password?: yup.Maybe<string>
  username: string
  is_active?: boolean
  document_type?: number /* 1: CPF; 2: CNPJ */
  document_number?: string /* <xxx.xxx.xxx-xx | xx.xxx.xxx/xxxx-xx> */
  profile_picture?: any
  institution_id?: number
}

const formValidationSchema: yup.Schema<IFormData> = yup.object({
  id: yup.string(),
  institution_id: yup.number(),
  email: yup.string().required().email(),
  password: yup.string().notRequired(),
  username: yup.string().required().min(3),
  is_active: yup.boolean().default(false),
  document_type: yup.number().min(1).max(2),
  profile_picture: yup
    .mixed()
    .test(
      'image-size-test',
      'Arquivo muito grande. Tamanho máximo permitido: 5MB',
      (file: unknown) =>
        file === undefined || (file as { size: number }).size <= 500000,
    ),
  document_number: yup
    .string()
    .when('document_type', {
      is: 1,
      then: (schema) =>
        schema.test('cpf-test', 'CPF Inválido', (value) => {
          if (value) return isCPF(value)
          return Boolean(value === '' || value === null || value === undefined)
        }),
    })
    .when('document_type', {
      is: 2,
      then: (schema) =>
        schema.test('cnpj-test', 'CNPJ Inválido', (value) => {
          if (value) {
            return isCNPJ(value)
          }
          return false
        }),
    }),
})

export const FormUsuarioInterno = (
  onClose: () => void,
  show = false,
  update: MutableRefObject<boolean>,
  create: boolean,
  userData?: ISectorUserDetail,
) => {
  const [formTitle, setFormTitle] = useState('')
  const { formRef } = useVForm()

  const { user } = useAuthContext()

  const [isLoading, setIsLoading] = useState(false)
  const [changePassword, setChangePassword] = useState(false)
  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const [documentType, setDocumentType] = useState<any>(
    Number(userData?.document_type) || 1,
  )
  const [profilePicture, setProfilePicture] = useState<any>()
  const [URLProfilePicture, setURLProfilePicture] = useState('')
  const [modules, setModules] = useState<string[]>([])

  const formProfilePicture = new FormData()

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)

    if (create) {
      if (user?.modules[0].profile === 0) {
        if (!localStorage.getItem('ADM_SELECTED_INSTITUTION')) {
          NotificacaoError(
            'Selecione Uma Instituição Antes de Criar uma Nova instância',
          )
          setIsLoading(false)
          return onClose()
        }
        formData.institution_id = Number(
          localStorage.getItem('ADM_SELECTED_INSTITUTION'),
        )
      } else {
        formData.institution_id = Number(user?.institution_id)
      }
    } else {
      formData.institution_id = Number(userData?.institution_id)
    }

    if (changePassword && password !== rePassword) {
      return NotificacaoError('As senhas não coincidem')
    } else if (changePassword && password === rePassword) {
      formData.password = password
    }

    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData) => {
        create
          ? createInternal(validatedData).then((result) => {
              setIsLoading(false)
              if (result instanceof Error) {
                NotificacaoError(result.message)
              } else {
                update.current = true
                NotificacaoSucesso('Usuário Criado com Sucesso')
                if (profilePicture) {
                  formProfilePicture.append(
                    'profile_picture',
                    profilePicture,
                    profilePicture.name,
                  )
                  NotificacaoPromise(
                    updateInternalById(Number(result), formProfilePicture),
                    'Enviando Foto de Perfil',
                    'Foto de Perfil Adcionada',
                    'Erro Ao Inserir Foto de Perfil',
                  )
                }
                setProfilePicture(undefined)
                onClose()
                formRef.current?.reset()
              }
            })
          : updateInternalById(Number(userData?.id), validatedData).then(
              (result) => {
                setIsLoading(false)
                if (result instanceof Error) {
                  NotificacaoError(result.message)
                } else {
                  update.current = true
                  NotificacaoSucesso('Usuário Atualizado com Sucesso')
                  if (profilePicture) {
                    formProfilePicture.append(
                      'profile_picture',
                      profilePicture,
                      profilePicture.name,
                    )
                    NotificacaoPromise(
                      updateInternalById(
                        Number(userData?.id),
                        formProfilePicture,
                      ),
                      'Enviando Foto de Perfil',
                      'Foto de Perfil Adcionada',
                      'Erro Ao Inserir Foto de Perfil',
                    )
                  }
                  setProfilePicture(undefined)
                  onClose()
                  formRef.current?.reset()
                }
              },
            )
      })
      .catch((errors: yup.ValidationError) => {
        const validationErros: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          setIsLoading(false)
          if (!error.path) return

          validationErros[error.path] = error.message
        })
        console.log(errors)
        formRef.current?.setErrors(validationErros)
      })
  }

  useEffect(() => {
    setDocumentType(Number(userData?.document_type) || 1)
    if (!create && userData !== undefined) {
      setFormTitle(`Editar Usuário ${userData.username}`)
      setModules(userData.modules)
    } else setFormTitle('Novo Usuário')
  }, [create, show, userData])

  const handleClose = () => {
    URL.revokeObjectURL(profilePicture)
    setChangePassword(false)
    setPassword('')
    setRePassword('')
    setProfilePicture(null)
    onClose()
  }

  return (
    <Modal
      onClose={() => {
        onClose()
        URL.revokeObjectURL(profilePicture)
        setProfilePicture(null)
      }}
      open={show}
      aria-describedby="modal-title"
    >
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader onClose={handleClose} showCloseButton title={formTitle} />

        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{
              height: '5px',
              borderRadius: '50px',
              mt: 1,
            }}
          />
        )}
        <Box margin={1}>
          <VForm
            ref={formRef}
            onSubmit={(formData: IFormData) => handleSubmit(formData)}
            initialData={
              create ? { document_type: 1, ombudsman_profile: 1 } : userData
            }
          >
            <Grid container direction="column" spacing={2} padding={2}>
              <Grid container item spacing={2}>
                <Grid item display="flex" xs={12} justifyContent="center">
                  <Avatar
                    src={
                      profilePicture
                        ? URLProfilePicture
                        : userData?.profile_picture
                    }
                    sx={{
                      width: theme.spacing(12),
                      height: theme.spacing(12),
                      border: '2px solid grey',
                    }}
                  />
                </Grid>
                <Grid item display="flex" xs={12} justifyContent="center">
                  <Button
                    variant="contained"
                    disabled={!!profilePicture}
                    component="label"
                    endIcon={<PhotoCamera />}
                    disableElevation
                    sx={{ borderRadius: '50px' }}
                  >
                    Foto de Perfil
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        if (e.target.files?.length !== undefined) {
                          setProfilePicture(e.target.files[0])
                          setURLProfilePicture(
                            URL.createObjectURL(e.target.files[0]),
                          )
                        } else {
                          setProfilePicture(null)
                          URL.revokeObjectURL(URLProfilePicture)
                          setURLProfilePicture(userData?.profile_picture || '')
                        }
                      }}
                    />
                  </Button>
                  {!!profilePicture && (
                    <IconButton
                      onClick={() => {
                        URL.revokeObjectURL(profilePicture)
                        setProfilePicture(undefined)
                        setURLProfilePicture('')
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item display="flex" xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="username"
                    label="Nome"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="email"
                    type="email"
                    label="Email"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
                <Grid
                  container
                  item
                  display="flex"
                  spacing={2}
                  xs={12}
                  direction="row"
                >
                  <Grid
                    container
                    item
                    xs={12}
                    display="flex"
                    alignItems="start"
                  >
                    <Grid item xs={6} md={2}>
                      <VSelect
                        fullWidth
                        disabled={!create}
                        name="document_type"
                        size="small"
                        type="number"
                        sx={{
                          borderTopRightRadius: '0px',
                          borderTopLeftRadius: '50px',
                          borderBottomLeftRadius: '50px',
                          borderBottomRightRadius: '0px',
                        }}
                        onChange={(e) => setDocumentType(e.target.value)}
                      >
                        <MenuItem value={1}>CPF</MenuItem>
                        <MenuItem value={2}>CNPJ</MenuItem>
                      </VSelect>
                    </Grid>
                    <Grid item xs={6} md={3.5}>
                      <VTextField
                        fullWidth
                        defaultValue={
                          userData?.document_number
                            ? userData.document_number
                            : ''
                        }
                        disabled={!create && user?.modules[0].profile !== 0}
                        formatData={
                          create
                            ? documentType === 1
                              ? formatToCPF
                              : formatToCNPJ
                            : userData?.document_number && documentType === 1
                            ? formatToCPF
                            : userData?.document_number && documentType === 2
                            ? formatToCNPJ
                            : (e) => e
                        }
                        name="document_number"
                        size="small"
                        label="Documento"
                        inputProps={{ maxLength: documentType === 1 ? 14 : 18 }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6.5}
                      paddingLeft={!mdDown ? 2 : 0}
                      paddingTop={mdDown ? 2 : 0}
                    >
                      <MultiSelect
                        textStyle={{ fieldSet: { borderRadius: '50px' } }}
                        onChange={(modules) =>
                          setModules(modules.map((module) => module.id))
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      justifyContent="center"
                      paddingTop={2}
                    >
                      <FormControlLabel
                        control={<VSwitch name="is_active" size="small" />}
                        label="Status"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      justifyContent="center"
                      paddingTop={2}
                    >
                      <FormControlLabel
                        control={<VSwitch name="hr_manager" size="small" />}
                        label="Gestor RH"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Configuração de ouvidoria */}
                {modules && modules.includes('ombudsman') && (
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center">
                      <Typography variant="h6">OUVIDORIA</Typography>
                    </Grid>
                    <Grid container item spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="profile-label">Perfil</InputLabel>
                          <VSelect
                            fullWidth
                            required
                            size="small"
                            labelId="profile-label"
                            label="Perfil"
                            name="ombudsman_profile"
                            sx={{ borderRadius: '50px' }}
                          >
                            <MenuItem value={1}>Auxiliar</MenuItem>
                            <MenuItem value={2}>Ouvidor</MenuItem>
                            <MenuItem value={3}>Auditor</MenuItem>
                          </VSelect>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <AutoComplete
                          textStyle={{ fieldSet: { borderRadius: '50px' } }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        display="flex"
                        justifyContent="center"
                      >
                        <FormControlLabel
                          control={<VSwitch name="data_manager" size="small" />}
                          label="Gestor de Dados"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        display="flex"
                        justifyContent="center"
                      >
                        <FormControlLabel
                          control={<VSwitch name="esic_manager" size="small" />}
                          label="Gestor eSic"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        display="flex"
                        justifyContent="center"
                      >
                        <FormControlLabel
                          control={<VSwitch name="compliance" size="small" />}
                          label="Denúncias"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {!create && user?.modules[0].profile === 0 && (
                <Grid container item spacing={2}>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          value={changePassword}
                          onChange={(_, check) => setChangePassword(check)}
                        />
                      }
                      label="Alterar senha"
                    />
                  </Grid>
                  {changePassword && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          size="small"
                          label="Senha"
                          type="password"
                          autoComplete="new-password"
                          sx={{
                            fieldSet: {
                              borderRadius: '50px',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          autoComplete="new-password"
                          type="password"
                          value={rePassword}
                          onChange={(e) => setRePassword(e.target.value)}
                          fullWidth
                          size="small"
                          label="Repita a senha"
                          sx={{
                            fieldSet: {
                              borderRadius: '50px',
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
            <Box display="flex" flex={1} justifyContent="center">
              <Button type="submit">
                <Icon>save</Icon>
                Salvar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, useMemo, useRef } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import {
  Table,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material'

import { useDebounce } from '../../../shared/hooks'
import { Environment } from '../../../shared/environment'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import { Footer } from '../../../shared/components/tabela/Footer'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import { FormatPriority } from '../../../shared/utils/format/FormatPriority'
import { FormatProtocolStatus, changePage, colors } from '../../../shared/utils'
import {
  SacProtocol,
  getAllSacProtocols,
} from '../../../shared/services/api/sac-services'
import {
  FerramentasDaListagem,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import { Formulario } from './formulario/Formulario'
import { InfoOutlined } from '@mui/icons-material'

export const ProtocoloSAC: FC = () => {
  const update = useRef(true)

  const { debounce } = useDebounce(1500)
  const { thisModule } = useModuleContext()

  const [rows, setRows] = useState<SacProtocol[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [viewForm, setViewForm] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  const setColor = (deadline: number) => {
    if (deadline < 0) return 6
    if (deadline < 6) return 5

    const result = Math.ceil(Number(thisModule?.deadline) / deadline)

    if (result <= 1) return 1
    if (result >= 5) return 4
    if (result === 2) return 2
    if (result === 3) return 3
    return 4
  }

  useEffect(() => {
    if (thisModule) {
      setIsLoading(true)
      debounce(() => {
        getAllSacProtocols(busca, item, total, String(thisModule.id)).then(
          (result) => {
            update.current = false
            setIsLoading(false)
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              setRows(result.results)
              setTotalCount(result.count)
            }
          },
        )
      })
    }
  }, [busca, item, total, update.current, thisModule?.id])

  return (
    <LayoutBaseDePagina
      title="Protocolos"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarBotaoNovo
          mostrarInputDaBusca
          textoDaBusca={busca}
          aoClicarEmNovo={() => setViewForm(true)}
          aoMudarTextoDaBusca={(text) =>
            setSearchParams(
              {
                busca: text,
                item: '0',
                total,
              },
              { replace: true },
            )
          }
        />
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Identificador</TableCell>
              <TableCell align="center">Prazo</TableCell>
              <TableCell align="center">Agrupamento</TableCell>
              <TableCell align="center">Setor</TableCell>
              <TableCell align="center">Assunto</TableCell>
              <TableCell align="center">Categoria</TableCell>
              <TableCell align="center">Prioridade</TableCell>
              <TableCell align="center">Setor notificado</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <Link
                    to={`/customer-service/${thisModule?.title}/protocols/details/${row.id}`}
                  >
                    <IconButton color="primary">
                      <InfoOutlined />
                    </IconButton>
                  </Link>
                </TableCell>
                <TableCell align="center">{row.identifier}</TableCell>
                <TableCell align="center">
                  <Typography
                    variant="h6"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    sx={{
                      backgroundColor:
                        row.status < 5
                          ? colors[setColor(row.deadline)]
                          : undefined,
                      borderRadius: '50%',
                      width: '38px',
                      height: '38px',
                    }}
                  >
                    {row.status > 4 ? '-' : row.deadline}
                  </Typography>
                </TableCell>
                <TableCell align="center">{row.grouping.name}</TableCell>
                <TableCell align="center">{row.sector.name}</TableCell>
                <TableCell align="center">{row.subject.name}</TableCell>
                <TableCell align="center">{row.category.name}</TableCell>
                <TableCell align="center">
                  {FormatPriority(row.priority)}
                </TableCell>
                <TableCell align="center">
                  {row.sector_notified ? 'Sim' : 'Não'}
                </TableCell>
                <TableCell align="center">
                  {FormatProtocolStatus(row.status)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <Footer
            colSpan={10}
            total={total}
            item={Number(item)}
            loading={isLoading}
            onChangePagination={(_, newPage) =>
              changePage(newPage, Number(total))
            }
            onChangeTotalItems={(e) =>
              setSearchParams(
                {
                  busca,
                  item: '0',
                  total: e.target.value,
                },
                { replace: true },
              )
            }
            totalCount={totalCount}
          />
        </Table>
      </TableContainer>
      {thisModule && (
        <Formulario
          onClose={() => setViewForm(false)}
          show={viewForm}
          externalUpdate={update}
        />
      )}
    </LayoutBaseDePagina>
  )
}

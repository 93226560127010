import { FC, useMemo, useEffect, useState, useRef } from 'react'
import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  LinearProgress,
  MenuItem,
  Select,
  Tooltip,
  Box,
  Pagination,
  Typography,
} from '@mui/material'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDaListagem,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import { useSearchParams } from 'react-router-dom'
import { useDebounce } from '../../../shared/hooks'
import {
  IDefaultReplie,
  getAllDefaultReplies,
} from '../../../shared/services/api'
import usePersistedState from '../../../shared/hooks/usePersistedState'
import { useAuthContext } from '../../../shared/contexts'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'
import { Environment } from '../../../shared/environment'
import { Formulario } from './formulario/Formulario'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const PreRespostas: FC = () => {
  const currentData = useRef<IDefaultReplie>()
  const saved = useRef(true)
  const { user } = useAuthContext()
  const { debounce } = useDebounce(1000)
  const [rows, setRows] = useState<IDefaultReplie[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [institution, setInstitution] = usePersistedState(
    'ADM_SELECTED_INSTITUTION',
    '',
  )

  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)

  const [open, setOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])
  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])
  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  const pagina = Number(item) / Number(total) + 1

  const changePage = (value1: number, value2: number) => {
    const result = value1 * value2 - value2
    return result.toString()
  }

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllDefaultReplies(
        busca,
        item,
        total,
        user?.modules[0].profile === 0 ? String(institution) : '',
      ).then((result) => {
        setIsLoading(false)
        saved.current = false
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setRows(result.results)
          setTotalCount(result.count)
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busca, item, total, saved.current, institution])

  const { moduleTitle } = useModuleContext()

  return (
    <LayoutBaseDePagina
      selectInstitution
      autoCompleteChange={(text) =>
        text !== undefined ? setInstitution(text) : setInstitution('')
      }
      title="Respostas Pré Definidas"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputDaBusca
          mostrarBotaoNovo={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 2 ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          textoDaBusca={busca}
          aoMudarTextoDaBusca={(texto) =>
            setSearchParams({ busca: texto, total, item }, { replace: true })
          }
        />
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Título</TableCell>
              <TableCell align="center" width="500px">
                Texto
              </TableCell>
              <TableCell align="center">Criada em</TableCell>
              <TableCell align="center">Atualizada em</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      currentData.current = row
                      setCreate(false)
                      setViewForm(true)
                    }}
                  >
                    <Icon>info_outlined</Icon>
                  </IconButton>
                </TableCell>
                <TableCell align="center">{row.title}</TableCell>
                <TableCell align="center">
                  <Typography
                    textAlign="justify"
                    variant="body2"
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3,
                    }}
                  >
                    {row.text}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.created_at)}
                </TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.updated_at)}
                </TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 10 || totalCount > Number(total)) && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box display="flex" justifyContent="center">
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / Number(total))}
                      onChange={(_, newPage) => {
                        setSearchParams(
                          {
                            busca,
                            total,
                            item: changePage(newPage, Number(total)),
                          },
                          {
                            replace: true,
                          },
                        )
                      }}
                    />
                    <Tooltip
                      title="Itens por Página"
                      open={open}
                      onMouseEnter={() => setOpen(true)}
                      onMouseDown={() => setOpen(false)}
                      onMouseLeave={() => setOpen(false)}
                    >
                      <Select
                        variant="outlined"
                        sx={{ borderRadius: '15px', height: '30px' }}
                        value={total}
                        onChange={(e) => {
                          setSearchParams(
                            {
                              busca,
                              item: '0',
                              total: e.target.value,
                            },
                            {
                              replace: true,
                            },
                          )
                        }}
                      >
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="40">40</MenuItem>
                        <MenuItem value="50">50</MenuItem>
                      </Select>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <Formulario
        create={create}
        onClose={() => {
          setViewForm(false)
          currentData.current = undefined
        }}
        data={create ? undefined : currentData.current}
        show={viewForm}
        update={saved}
      />
    </LayoutBaseDePagina>
  )
}

/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

export interface IForward {
  id: number
  email: string
  comment: string
  subject: string
  user_id: string
  protocol_id: string
  created_at: string
  updated_at?: string
}

const getAllForward = async (
  protocolId: number,
): Promise<IForward[] | Error> => {
  try {
    const relativeUrl = `/V1/forwards/${protocolId}/all/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao lista encaminhamentos')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const createForward = async (formData: {}): Promise<IForward | Error> => {
  try {
    const relativeUrl = '/V1/forwards/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao carregar resultado')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export { createForward, getAllForward }

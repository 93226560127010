import { useState, useEffect, useMemo } from 'react'

import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useDebounce } from '../../../../shared/hooks'
import {
  getAllGroupings,
  getAllSectors,
  getAllSubjects,
  getAllTypologies,
} from '../../../../shared/services/api'
import { NotificacaoError } from '../../../../shared/components'

type TAutoCompleteOptions = {
  id: number
  label: string
}

interface IAutoAgrupamento {
  onChange: (e: TAutoCompleteOptions | null) => void
}

interface IAutoSetor {
  onChange: (e: TAutoCompleteOptions | null) => void
}

interface IAutoAssunto {
  onChange: (e: TAutoCompleteOptions | null) => void
}

interface IAutoTipologia {
  onChange: (e: TAutoCompleteOptions | null) => void
}

export const AutoCompleteAgrupamento: React.FC<IAutoAgrupamento> = ({
  onChange,
}) => {
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined)

  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  const { debounce } = useDebounce(1000)

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllGroupings('0', '10', search).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar agrupamentos')
        } else {
          setOptions(
            result.results.map((group) => ({
              id: group.id,
              label: group.name,
            })),
          )
        }
      })
    })
  }, [debounce, search])

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null

    const selectedOption = options.find((option) => option.id === selectedId)
    if (!selectedId) return null

    return selectedOption
  }, [selectedId, options])

  return (
    <Autocomplete
      value={autoCompleteSelectedOption}
      noOptionsText="Sem opções"
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      options={options}
      loading={isLoading}
      onInputChange={(_, newValue) => setSearch(newValue)}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.id)
        onChange?.(newValue)
        setSearch('')
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Agrupamento"
          size="small"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

export const AutoCompleteSetor: React.FC<IAutoSetor> = ({ onChange }) => {
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined)

  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  const { debounce } = useDebounce(1000)

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllSectors('0', '10', search, '', '', '1').then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar setores')
        } else {
          setOptions(
            result.results.map((sector) => ({
              id: sector.id,
              label: sector.name,
            })),
          )
        }
      })
    })
  }, [debounce, search])

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null

    const selectedOption = options.find((option) => option.id === selectedId)
    if (!selectedId) return null

    return selectedOption
  }, [selectedId, options])

  return (
    <Autocomplete
      value={autoCompleteSelectedOption}
      disabled={isLoading}
      noOptionsText="Sem opções"
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      options={options}
      loading={isLoading}
      onInputChange={(_, newValue) => setSearch(newValue)}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.id)
        onChange?.(newValue)
        setSearch('')
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Setor"
          size="small"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

export const AutoCompleteAssunto: React.FC<IAutoAssunto> = ({ onChange }) => {
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined)

  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  const { debounce } = useDebounce(1000)

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllSubjects('0', '999999999', search).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar assuntos')
        } else {
          setOptions(
            result.results.map((subject) => ({
              id: Number(subject.id),
              label: subject.name,
            })),
          )
        }
      })
    })
  }, [debounce, search])

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null

    const selectedOption = options.find((option) => option.id === selectedId)
    if (!selectedId) return null

    return selectedOption
  }, [selectedId, options])

  return (
    <Autocomplete
      value={autoCompleteSelectedOption}
      disabled={isLoading}
      noOptionsText="Sem opções"
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      options={options}
      loading={isLoading}
      onInputChange={(_, newValue) => setSearch(newValue)}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.id)
        setSearch('')
        onChange?.(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Assunto"
          size="small"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

export const AutoCompleteTipologia: React.FC<IAutoTipologia> = ({
  onChange,
}) => {
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined)

  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  const { debounce } = useDebounce(1000)

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllTypologies('0', '999999999', search).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar assuntos')
        } else {
          setOptions(
            result.results.map((typology) => ({
              id: Number(typology.id),
              label: typology.name,
            })),
          )
        }
      })
    })
  }, [debounce, search])

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null

    const selectedOption = options.find((option) => option.id === selectedId)
    if (!selectedId) return null

    return selectedOption
  }, [selectedId, options])

  return (
    <Autocomplete
      value={autoCompleteSelectedOption}
      disabled={isLoading}
      noOptionsText="Sem opções"
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      options={options}
      loading={isLoading}
      onInputChange={(_, newValue) => setSearch(newValue)}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.id)
        setSearch('')
        onChange?.(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tipologia"
          size="small"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

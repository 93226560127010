/* eslint-disable @typescript-eslint/ban-types */
import { Api, ExternalApi, TotalCount } from '../../axios-config'
import { SacModuleList } from '../../modules-services/SacServices'

export interface SacCategory {
  id: number
  name: string
  status: boolean
  created_at: string
  updated_at: string
  institution: number
  customer_service: SacModuleList
}

export const getAllSacCategories = async (
  busca = '',
  item = '0',
  total = '10',
  institution = '',
  sac = '',
): Promise<TotalCount<SacCategory> | Error> => {
  try {
    const relativeUrl = '/V1/customer_service/categories/'

    const { data } = await Api.get(relativeUrl, {
      params: {
        search: busca,
        item,
        total,
        institution,
        customer_service: sac,
      },
    })

    if (data) return data
    return new Error('Erro ao carregar instâncias')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const createSacCategory = async (formData: {}): Promise<
  SacCategory | Error
> => {
  try {
    const relativeUrl = '/V1/customer_service/categories/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data
    return new Error('Erro ao criar instâncias')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getSacCategoryById = async (
  id: string | number,
): Promise<SacCategory | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/categories/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const updateSacCategory = async (
  id: string | number,
  formData: {},
): Promise<SacCategory | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/categories/${id}/`

    const { data } = await Api.patch(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao atualizar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getAllSacCategoriesExternal = async (
  customerService: number | string,
  busca = '',
): Promise<SacCategory[] | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/externalusers/access/${customerService}/categories/`

    const { data } = await ExternalApi.get(relativeUrl, {
      params: {
        search: busca,
      },
      headers: {
        Authorization: `Token ${sessionStorage.getItem('EXTERNAL_TOKEN')}`,
      },
    })

    if (data) return data

    return new Error('Erro ao carregar categorias')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  LinearProgress,
  Icon,
  Divider,
  Link,
  TextareaAutosize,
} from '@mui/material'
import { AttachFile, Delete, Send } from '@mui/icons-material'
import {
  createSacReplyExternal,
  getAllSacRepliesExternal,
} from '../../../../../shared/services/api/sac-services'
import {
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../../shared/components'
import { FormatDateTime } from '../../../../../shared/utils'
import { SacReply } from '../../../../../shared/services/api/sac-services/respostas-services'

interface IRequests {
  protocolId?: number
  sendDisabled: boolean
  secondUpdate?: any
  moduleId: string | number | undefined
}

export const Respostas: React.FC<IRequests> = ({
  protocolId,
  secondUpdate,
  sendDisabled,
  moduleId,
}) => {
  const [isLoading, setIsLoading] = useState(true)

  const [message, setMessage] = useState('')
  const [file, setFile] = useState<any>()
  const [update, setUpdate] = useState(false)
  const [replies, setReplies] = useState<SacReply[]>([])

  useEffect(() => {
    setIsLoading(true)
    if (protocolId !== undefined) {
      getAllSacRepliesExternal(protocolId).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          setUpdate(false)
          NotificacaoError('Erro ao carregar respostas')
        } else {
          setUpdate(false)
          secondUpdate.current = false
          setReplies(result)
        }
      })
    }
  }, [protocolId, update, secondUpdate?.current])

  const formData = new FormData()

  const handleSubmit = () => {
    setIsLoading(true)
    formData.append('protocol', String(protocolId))
    formData.append(
      'external_user',
      String(sessionStorage.getItem('EXTERNAL_ID')),
    )
    formData.append('reply', message)
    formData.append('reply_type', '1')
    formData.append('customer_service', String(moduleId))
    formData.append('internal', String(false))
    if (file) {
      formData.append('file', file, file.name)
    }

    createSacReplyExternal(protocolId!, formData).then((result) => {
      setIsLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        setUpdate(true)
        setMessage('')
        secondUpdate.current = true
        formData.delete('protocol')
        formData.delete('user')
        formData.delete('reply')
        formData.delete('reply_type')
        formData.delete('customer_service')
        formData.delete('internal')
        if (file) {
          formData.delete('file')
          setFile(undefined)
        }
        NotificacaoSucesso('Mensagem criada com sucesso')
      }
    })
  }

  return (
    <Box
      maxWidth="100%"
      overflow="hidden"
      gap={2}
      display="flex"
      flexDirection="column"
      paddingBottom={1}
    >
      <Box display="flex" flex="1" flexDirection="column" gap={1} marginTop={1}>
        <Box display="flex" gap={1} flexDirection="column">
          {!!file && (
            <Box
              display="flex"
              flex="1"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Box maxWidth="90%">
                <Typography textOverflow="ellipsis" noWrap>
                  {file.name}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    setFile(undefined)
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          )}
          <Box display="flex" gap={1}>
            <Tooltip title={'Anexar arquivo'}>
              <IconButton
                component="label"
                disabled={sendDisabled}
                onClick={() => document.getElementById('upload-file')}
                sx={{ transform: 'rotate(-45deg)' }}
              >
                <AttachFile />
              </IconButton>
            </Tooltip>
            <TextareaAutosize
              minRows={2}
              maxRows={8}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              aria-label="Resposta"
              placeholder="Escreva aqui um complemento"
              style={{
                width: '90%',
                maxWidth: '90%',
                font: 'message-box',
                padding: 2,
                display: 'flex',
                alignItems: 'center',
                borderRadius: '5px',
                fontVariant: 'historical-forms',
              }}
            />
            <Tooltip title={isLoading ? 'Carregando' : 'Enviar'}>
              <IconButton
                onClick={handleSubmit}
                disabled={sendDisabled || isLoading}
              >
                <Send />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box
        maxHeight="85%"
        overflow="auto"
        borderRadius="10px"
        border="2px solid black"
        padding={1}
      >
        {protocolId ? (
          <Box display="flex" flexDirection="column" gap={1}>
            {replies.length === 0 && !isLoading && (
              <Typography variant="caption" fontSize={16}>
                Nenhuma respostas criada
              </Typography>
            )}
            {replies.map((reply, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                bgcolor="white"
                border="2px solid black"
                borderRadius="10px"
              >
                {reply.file && (
                  <Link
                    sx={{
                      cursor: 'pointer',
                      color: 'white',
                    }}
                    onClick={() => {
                      window.open(reply.file, '_blank', 'noopener')
                    }}
                    variant="h6"
                    underline="hover"
                    paddingX={1}
                    target="_blank"
                    rel="noopener"
                  >
                    Anexo da resposta
                  </Link>
                )}
                <Box display="flex" flexDirection="column">
                  {reply.reply_type === 2 && (
                    <Typography marginLeft={2} variant="h6" color="black">
                      TRANSFERÊNCIA
                    </Typography>
                  )}
                  {reply.reply_type === 3 && (
                    <Typography marginLeft={2} variant="h6" color="black">
                      MUDANÇA DE CATEGORIA
                    </Typography>
                  )}
                  {reply.user && (
                    <Typography marginLeft={2} color="black">
                      - {reply.user.username}
                    </Typography>
                  )}
                  <Box
                    display="flex"
                    gap={1}
                    flexDirection="row"
                    alignItems="center"
                    mt={!reply.user ? 1 : undefined}
                    paddingX={1}
                  >
                    <Tooltip title="Resposta Externa">
                      <Icon sx={{ color: 'black' }}>insert_comment</Icon>
                    </Tooltip>
                    <Divider orientation="vertical" variant="fullWidth" />
                    <Box display="flex" flexDirection="column" gap={1}>
                      {reply.reply.split('\n').map((text) => (
                        <Typography
                          key={index}
                          textAlign="justify"
                          sx={{ color: 'black' }}
                        >
                          {text}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                  <Box display="flex" width="99%" justifyContent="right">
                    <Typography variant="caption" color="black">
                      {FormatDateTime(reply.created_at, 'Não definido')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <LinearProgress variant="indeterminate" />
        )}
      </Box>
      <input
        hidden
        id="upload-file"
        type="file"
        onChange={(e) => {
          if (e.target.files) {
            setFile(e.target.files[0])
          } else setFile(undefined)
        }}
      />
    </Box>
  )
}

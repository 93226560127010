import { FC, useState, useEffect } from 'react'
import { Autocomplete, TextField, Box, CircularProgress } from '@mui/material'
import { useField } from '@unform/core'
import { getAllSacGroupings } from '../../../../shared/services/api/sac-services'
import { useDebounce } from '../../../../shared/hooks'
import { NotificacaoError } from '../../../../shared/components'

type AutocompleteOption = {
  label: string
  value: number
  icon: string
}

interface AutocompleteAgrupamentosProps {
  customerService: string
  institution: string
}

export const AutocompleteAgrupamentos: FC<AutocompleteAgrupamentosProps> = ({
  customerService,
  institution,
}) => {
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField('grouping')
  const { debounce } = useDebounce()
  const [isLoading, setIsLoading] = useState(true)
  const [firstTime, setFirstTime] = useState(true)
  const [selectedOption, setSelectedOption] =
    useState<AutocompleteOption | null>(null)
  const [options, setOptions] = useState<AutocompleteOption[]>([])
  const [value, setvalue] = useState<number | null>(null)
  const [search, setSearch] = useState('')

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllSacGroupings(
        '0',
        '999999999',
        search,
        customerService,
        institution,
      ).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar agrupamentos')
        } else {
          setOptions(
            result.results.map((grouping) => ({
              icon: grouping.icon.icon,
              label: grouping.name,
              value: grouping.id,
            })),
          )
        }
      })
    })
  }, [customerService, debounce, institution, search])

  useEffect(() => {
    if (firstTime && options.length > 0) {
      setvalue(defaultValue)
      setSelectedOption(
        options.find((opt) => opt.value === defaultValue) || null,
      )
      setFirstTime(false)
    }
  }, [defaultValue, firstTime, options])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setvalue(newValue),
    })
  }, [fieldName, registerField, value])

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(
        options.find((opt) => opt.value === defaultValue) || null,
      )
    }
  }, [defaultValue, options])

  return (
    <Autocomplete
      fullWidth
      disablePortal
      openText="Abrir"
      options={options}
      clearText="Limpar"
      closeText="Fechar"
      loading={isLoading}
      inputValue={search}
      value={selectedOption}
      loadingText="Carregando..."
      noOptionsText="Nenhuma opção encontrada"
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      onChange={(_, newValue) => {
        setSelectedOption(newValue)
        setvalue(newValue?.value || null)
        clearError()
      }}
      onInputChange={(_, newValue) => {
        setSearch(newValue)
        clearError()
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img width={60} src={option.icon} alt="icon" />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          required
          {...params}
          size="small"
          error={!!error}
          helperText={error}
          label="Agrupamento"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

import axios from 'axios'
import { Api, ExternalApi, TotalCount } from '../../axios-config'
import { SacProtocol } from '../protocolos-services'
import { SacReply } from '../respostas-services'

export interface SACExternalUser {
  id: number
  name: string
  email?: string
  protocols?: number

  /**
    (1, CPF)
    (2, CNPJ)
   */
  document_type: number
  document_number: string
  number: string

  /**
    (1, 'Masculino'),
    (2, 'Feminino'),
    (3, 'Não Binário'),
    (4, 'Prefiro Não Responder'),
    (5, 'Instituição')
   */
  gender: number
  birth_date?: string

  /**
    (1, 'Não Alfabetizado'),
    (2, 'Ensino Fundamental Incompleto'),
    (3, 'Ensino Fundamental Completo'),
    (4, 'Ensino Médio Incompleto'),
    (5, 'Ensinio Médio Completo'),
    (6, 'Técnico Completo'),
    (7, 'Técnico Incompleto'),
    (8, 'Superior Incompleto'),
    (9, 'Superior Completo'),
    (10, 'Prefiro Não Responder'),
   */
  escolarity: number
  address?: string
  last_sign_in_at?: string
  is_confirmed: boolean
  confirmed_at: string
  sign_in_count: number
  created_at: string
  updated_at: string
}

export const getAllSacExternalUsersAdmin = async (
  search = '',
  item = '0',
  total = '10',
  documentNumber = '',
  documentType = '',
): Promise<TotalCount<SACExternalUser> | Error> => {
  try {
    const relativeUrl = '/V1/customer_service/external_users/'

    const { data } = await Api.get(relativeUrl, {
      params: {
        search,
        item,
        total,
        document_number: documentNumber,
        document_type: documentType,
      },
    })

    if (data) return data

    return new Error('Erro ao carregar usuários externos')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getAllSacExternalUsersMaster = async (
  customerService: number,
  search = '',
  item = '0',
  total = '10',
  documentNumber = '',
  documentType = '',
): Promise<TotalCount<SACExternalUser> | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/${customerService}/external_users/`

    const { data } = await Api.get(relativeUrl, {
      params: {
        search,
        item,
        total,
        document_number: documentNumber,
        document_type: documentType,
      },
    })

    if (data) return data

    return new Error('Erro ao carregar usuários externos')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getSACExternalUser = async (
  id: string | number,
): Promise<SACExternalUser | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/external_users/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar dados')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const createExternalUser = async (
  formData: object,
  external = false,
): Promise<SACExternalUser | Error> => {
  try {
    const relativeUrl = '/V1/external_users/'

    if (external) {
      const cookies = document.cookie.split(';')
      const cookie = String(
        cookies.filter((element) => element.includes('csrftoken')),
      )
      const csrftoken = cookie.split('=')[1]
      const { data } = await axios.post(relativeUrl, formData, {
        headers: {
          'X-CSRFToken': csrftoken,
        },
      })
      if (data) return data
    } else {
      const { data } = await Api.post(relativeUrl, formData)
      if (data) return data
    }

    return new Error('Erro ao criar usuário')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const externalSaclogin = async (
  formData: object,
): Promise<SACExternalUser | Error> => {
  try {
    const relativeUrl = '/V1/external_auth/'

    const cookies = document.cookie.split(';')
    const cookie = String(
      cookies.filter((element) => element.includes('csrftoken')),
    )
    const csrftoken = cookie.split('=')[1]
    const { data } = await axios.post(relativeUrl, formData, {
      headers: {
        'X-CSRFToken': csrftoken,
      },
    })

    if (data) return data

    return new Error('Erro ao efetuar login')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const createSacProtocolExternal = async (
  formData: object,
): Promise<SacProtocol | Error> => {
  try {
    const relativeUrl = '/V1/customer_service/externalusers/access/protocols/'

    const cookies = document.cookie.split(';')
    const cookie = String(
      cookies.filter((element) => element.includes('csrftoken')),
    )
    const csrftoken = cookie.split('=')[1]
    const { data } = await ExternalApi.post(relativeUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Token ${sessionStorage.getItem('EXTERNAL_TOKEN')}`,
        'X-CSRFToken': csrftoken,
      },
    })

    if (data) return data

    return new Error('Erro ao criar protocolo')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getProtocolsExternal = async (
  item = '0',
  total = '10',
  busca = '',
  status = '',
): Promise<TotalCount<SacProtocol> | Error> => {
  try {
    const relativeUrl = '/V1/customer_service/externalusers/access/protocols/'

    const cookies = document.cookie.split(';')
    const cookie = String(
      cookies.filter((element) => element.includes('csrftoken')),
    )
    const csrftoken = cookie.split('=')[1]
    const { data } = await ExternalApi.get(relativeUrl, {
      params: {
        item,
        total,
        status,
        search: busca,
      },
      headers: {
        Authorization: `Token ${sessionStorage.getItem('EXTERNAL_TOKEN')}`,
        'X-CSRFToken': csrftoken,
      },
    })

    if (data) return data

    return new Error('Erro ao listar protocolos')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getSacProtocolByIdExternal = async (
  id: number | string,
): Promise<SacProtocol | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/externalusers/access/protocols/${id}/`

    const cookies = document.cookie.split(';')
    const cookie = String(
      cookies.filter((element) => element.includes('csrftoken')),
    )
    const csrftoken = cookie.split('=')[1]
    const { data } = await ExternalApi.get(relativeUrl, {
      headers: {
        Authorization: `Token ${sessionStorage.getItem('EXTERNAL_TOKEN')}`,
        'X-CSRFToken': csrftoken,
      },
    })

    if (data) return data

    return new Error('Erro ao listar protocolos')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getAllSacRepliesExternal = async (
  id: number | string,
): Promise<SacReply[] | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/externalusers/access/${id}/replies/`

    const { data } = await ExternalApi.get(relativeUrl, {
      headers: {
        Authorization: `Token ${sessionStorage.getItem('EXTERNAL_TOKEN')}`,
      },
    })

    if (data) return data

    return new Error('Erro ao carregar respostas')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const createSacReplyExternal = async (
  id: number | string,
  formData: object,
): Promise<SacReply | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/externalusers/access/${id}/replies/`

    const { data } = await ExternalApi.post(relativeUrl, formData, {
      headers: {
        Authorization: `Token ${sessionStorage.getItem('EXTERNAL_TOKEN')}`,
      },
    })

    if (data) return data

    return new Error('Erro ao carregar respostas')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

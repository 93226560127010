import { FC, useState } from 'react'
import {
  SacPredefinedReply,
  createPredefinedReply,
  updatePredefinedReply,
} from '../../../../shared/services/api/sac-services/respostas-pre-definidas-services'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  FormBoxStyle,
  ModalHeader,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import {
  VForm,
  VSwitch,
  VTextArea,
  VTextField,
  useVForm,
} from '../../../../shared/forms'
import { Cancel, Save } from '@mui/icons-material'
import { IFormData, formValidationSchema } from './validate'
import { ValidationError } from 'yup'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'
import { AutocompleteSac } from '../components/AutocompleteSacs'

interface FormularioProps {
  show: boolean
  create: boolean
  update: () => void
  onClose: () => void
  data?: SacPredefinedReply
  institution: string | number
}

export const Formulario: FC<FormularioProps> = ({
  data,
  show,
  create,
  update,
  onClose,
  institution,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { formRef } = useVForm()
  const { thisModule } = useModuleContext()

  const [isLoading, setisLoading] = useState(false)

  const handleClose = () => {
    formRef.current?.reset()
    onClose()
  }

  const handleSubmit = (formData: IFormData) => {
    setisLoading(true)
    if (thisModule?.profile !== 0 && thisModule && create) {
      formData.customer_service = thisModule.id
    } else if (thisModule?.profile !== 0 && thisModule && data) {
      formData.customer_service = data.customer_service
    }

    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData) => {
        if (create) {
          createPredefinedReply(validatedData).then((result) => {
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              update()
              NotificacaoSucesso('Resposta padrão criada com sucesso')
              handleClose()
            }
          })
        } else if (data) {
          updatePredefinedReply(data.id, validatedData).then((result) => {
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              update()
              NotificacaoSucesso('Resposta atualizada com sucesso')
              handleClose()
            }
          })
        }
      })
      .catch((errors: ValidationError) => {
        const validationErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path] = error.message
          }
        })
        formRef.current?.setErrors(validationErrors)
      })
      .finally(() => setisLoading(false))
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader
          showCloseButton
          onClose={handleClose}
          title={create ? 'Nova resposta padrão' : 'Editar resposta padrão'}
        />
        <Divider />
        {isLoading && <LinearProgress />}

        <VForm
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={!create ? data : undefined}
        >
          <Grid container marginY={1} spacing={2} direction="column">
            <Grid item xs={12}>
              <VTextField
                fullWidth
                size="small"
                name="title"
                label="Título"
                borderTopLeft="50px"
                borderBottomLeft="50px"
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <VTextArea
                required
                name="text"
                minRows={4}
                maxRows={8}
                placeholder="Escreva aqui o texto da resposta"
                ariaLabel="Texto"
                style={{
                  width: '98%',
                  paddingInline: '8px',
                  borderRadius: '15px',
                  fontVariant: 'historical-forms',
                  font: 'message-box',
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <FormControlLabel
                label="Status"
                control={<VSwitch name="status" size="small" />}
              />
            </Grid>

            {thisModule?.profile === 0 && (
              <Grid>
                <AutocompleteSac
                  name="customer_service"
                  institution={institution}
                />
              </Grid>
            )}
          </Grid>

          <Box
            marginTop={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Button
              type="submit"
              disableElevation
              variant="contained"
              startIcon={<Save />}
              disabled={isLoading}
            >
              salvar
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              startIcon={<Cancel />}
            >
              cancelar
            </Button>
          </Box>
        </VForm>
      </Box>
    </Modal>
  )
}

import { Fragment, useEffect } from 'react'
import { Routes, useNavigate, useLocation } from 'react-router-dom'

import { useAuthContext, useDrawerContext } from '../shared/contexts'

import {
  NotificacaoError,
  ciOptions,
  ombudsmanOptions,
  sacOptions,
} from '../shared/components'
import { PublicRoutes } from './PublicRoutes'
import { PrivateRoutes } from './PrivateRoutes'
import { useModuleContext } from '../shared/contexts/ModulesContext'

export const AppRoutes = () => {
  const { user, isAuthenticated } = useAuthContext()
  const { setDrawerOptions } = useDrawerContext()
  const { currentModule, moduleTitle } = useModuleContext()
  const path = useLocation()

  useEffect(() => {
    const thisModule = user?.modules.find(
      (mdl) => mdl.type === currentModule && mdl.title === moduleTitle,
    )
    if (currentModule === 'ci') {
      setDrawerOptions(ciOptions.admin(moduleTitle || 'tickdoc'))
    } else if (currentModule === 'ombudsman') {
      if (thisModule?.profile === 0) {
        setDrawerOptions(ombudsmanOptions.admin(moduleTitle || 'tickdoc'))
      } else if (thisModule?.profile === 2) {
        if (thisModule?.hr_manager) {
          setDrawerOptions(
            ombudsmanOptions.ombudsmanHR(moduleTitle || 'tickdoc'),
          )
        } else {
          setDrawerOptions(ombudsmanOptions.ombudsman(moduleTitle || 'tickdoc'))
        }
      } else if (thisModule?.profile === 3) {
        setDrawerOptions(ombudsmanOptions.auditor(moduleTitle || 'tickdoc'))
      } else {
        if (thisModule?.hr_manager) {
          setDrawerOptions(
            ombudsmanOptions.auxiliarHR(moduleTitle || 'tickdoc'),
          )
        } else {
          setDrawerOptions(ombudsmanOptions.auxiliar(moduleTitle || 'tickdoc'))
        }
      }
    } else if (currentModule === 'customer-service') {
      if (thisModule?.profile === 0) {
        setDrawerOptions(sacOptions.admin(moduleTitle || 'tickdoc'))
      } else if (thisModule?.profile === 1) {
        if (thisModule.hr_manager) {
          setDrawerOptions(sacOptions.auxiliarHR(moduleTitle || 'tickdoc'))
        } else {
          setDrawerOptions(sacOptions.auxiliar(moduleTitle || 'tickdoc'))
        }
      } else if (thisModule?.profile === 2) {
        if (thisModule.hr_manager) {
          setDrawerOptions(sacOptions.masterHR(moduleTitle || 'tickdoc'))
        } else {
          setDrawerOptions(sacOptions.master(moduleTitle || 'tickdoc'))
        }
      }
    }
  }, [path, user, currentModule, moduleTitle, setDrawerOptions])

  const navigate = useNavigate()

  useEffect(() => {
    if (user && isAuthenticated && currentModule && moduleTitle) {
      const thisModule = user.modules.find(
        (mdl) => mdl.type === currentModule && mdl.title === moduleTitle,
      )

      if (!thisModule) {
        NotificacaoError('Sem acesso ao módulo')
        navigate('/admin')
      }
    }
  }, [path, moduleTitle, currentModule, user, isAuthenticated, navigate])

  return (
    <Routes>
      <Fragment>
        {PublicRoutes}
        {PrivateRoutes}
      </Fragment>
    </Routes>
  )
}

import {
  Box,
  Modal,
  Paper,
  Button,
  Divider,
  Tooltip,
  useTheme,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { FormBoxStyle } from '../../../shared/components'
import { FC } from 'react'
import { Check, Close } from '@mui/icons-material'

interface PrivacyPolicyModalProps {
  show: boolean
  onClose(): void
  setAccept(value: boolean): void
}

export const PrivacyPolicyModal: FC<PrivacyPolicyModalProps> = ({
  setAccept,
  onClose,
  show,
}) => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        overflow="auto"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Tooltip title="fechar">
          <IconButton
            sx={{ position: 'absolute', right: 20, top: 20 }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Tooltip>
        <Typography variant="h6" textAlign="center">
          Política de Privicidade
        </Typography>
        <Divider sx={{ paddingBottom: 2 }} />
        <Typography paragraph textAlign="justify">
          A presente Política de Privacidade tem por finalidade demonstrar o
          compromisso da Rede Participar Brasil de Tecnologia, inscrita no CNPJ
          sob o nº 26.749.575/0001-03, com sede na cidade de Fortaleza, no
          estado do Ceará, na Av. Júlio Abreu, nº 160, Sala 308 - Ed. Hot Center
          - Varjota - CEP: 60.160-240, com a privacidade e a proteção dos dados
          que identificam o USUÁRIO individualmente (Pessoa Física e Jurídica) e
          demais dados fornecidos, descrevendo as práticas em relação aos dados
          coletados por meio do Software, de acordo com as legislações em vigor.
          <br />
          <br />
          O Software é comercializado para o uso de nossos CLIENTES para a
          gestão de atendimento aos USUÁRIOS, seja eletrônico ou presencial.
          Para realizar o cadastro no site do Software o USUÁRIO necessitará
          fornecer algumas informações que ficarão armazenadas nos servidores
          contratados pela Rede Participar Brasil de Tecnologia.
          <br />
          <br />
          Portanto, os dados cadastrados no site são fornecidos pelos USUÁRIOS
          aos nossos respectivos CLIENTES, que por força contratual, utilizam o
          site da Rede Participar Brasil de Tecnologia para operar o software,
          que servirá para a gestão dos dados.
          <br />
          <br />
          Como condição para acesso e uso das funcionalidades exclusivas do
          Software, o USUÁRIO declara que fez a leitura completa e atenta das
          regras da presente Política de Privacidade, conferindo assim sua livre
          e inequívoca concordância com os termos aqui estipulados.
          <br />
          <br />A Rede Participar Brasil de Tecnologia não comercializa e/ou
          divulga as informações dos USUÁRIOS com terceiros. Haverá, contudo, o
          compartilhamento com nosso CLIENTE, quando solicitado o acesso para
          seu responsável indicado, nas restritas hipóteses indicadas adiante,
          uma vez que os dados são fornecidos pelos USUÁRIOS diretamente aos
          nossos CLIENTES que utilizam o site da Rede Participar Brasil de
          Tecnologia, que opera o software, apenas como interface para a gestão
          dos seus dados.
        </Typography>
        <Typography variant="h5" sx={{ borderBottom: '1px solid' }}>
          Quais são as informações obtidas
        </Typography>
        <Typography>
          Os dados que coletamos dos usuários são:
          <ul>
            <li>
              Pessoa Física:
              <br />
              - CPF
              <br />
              - Nome
              <br />
              - e-Mail
              <br />
              - Data de Nascimento
              <br />
              - Gênero
              <br />
              - Escolaridade
              <br />
              - Telefone Celular
              <br />
              - Endereço
              <br />
            </li>
            <br />
            <li>
              Pessoa Jurídica:
              <br />
              - CNPJ
              <br />
              - Razão Social
              <br />
              - Nome do Responsável
              <br />
              - e-Mail
              <br />
              - Telefone
              <br />
              - Celular
              <br />
              - Endereço
              <br />
            </li>
          </ul>
        </Typography>
        <Typography variant="h5" sx={{ borderBottom: '1px solid' }}>
          Os dados coletados, em razão do uso do software, são:
        </Typography>
        <Typography>
          <ul>
            - Datas, horários e quantidades de logins no software;
            <br />
            - Endereços de IP;
            <br />
            - Ações de alterações dos cadastros;
            <br />
            - Ações de abertura, complemento e consulta de protocolos.
            <br />
          </ul>
        </Typography>
        <Typography variant="h5" sx={{ borderBottom: '1px solid' }}>
          Qual a finalidade dos dados coletados
        </Typography>
        <Typography textAlign="justify">
          A Rede Participar Brasil de Tecnologia poderá utilizar os dados que
          foram coletados através do software para as seguintes finalidades
          específicas, CUJO CONSENTIMENTO EXPRESSO É DADO NESTA OPORTUNIDADE por
          prazo indeterminado, em atendimento ao art. 7o, I, da Lei n.
          13.709/2018:
        </Typography>
        <Typography>
          <ul>
            <li>
              Abertura de protocolos de atendimento do serviço, por meio do Site
              que alimenta o software, assim como para propiciar a eventual
              resposta adequada à demanda do USUÁRIO dentro do prazo proposto;
            </li>
            <br />
            <li>
              Para os USUÁRIOS de Serviços públicos, as informações são
              necessárias, também, em atendimento aos artigos 10º das leis
              federais nº 12.527/2011 e 13.460/2017;
            </li>
            <br />
            <li>
              Emissão de relatórios anonimizados pelos CLIENTES que fazem uso do
              Software para análise de indicativos e melhoramentos dos serviços,
              através das demandas geradas pelos USUÁRIOS;
            </li>
            <br />
            <li>
              Envio de SMS e e-mails aos USUÁRIOS contendo números de protocolos
              das demandas e/ou informe de movimentações dos mesmos;
            </li>
            <br />
            <li>
              Compartilhamento com autoridades judiciais, administrativas ou
              governamentais competentes, sempre que houver requerimento,
              requisição ou ordem judicial;
            </li>
            <br />
            <li>
              Autopreenchimento dos dados pessoais do USUÁRIO ao tentar realizar
              ação de cadastramento em uma outra instituição ou empresa que
              também utilize o software. Assim, o USUÁRIO poderá acessar o
              software utilizado como canal de atendimento para registrar suas
              manifestações de Pedidos de Informações, Elogios, Reclamações,
              Denúncias, Sugestões, Críticas e Simplifique, sem a necessidade de
              realizar um novo cadastro;
            </li>
            <br />
            <li>
              A base de dados cadastrais formada por meio da coleta de dados no
              site que alimenta o software, por força contratual, é de
              responsabilidade da Rede Participar Brasil de Tecnologia, sendo
              que seu uso, acesso e compartilhamento, quando necessários, serão
              realizados dentro dos limites e propósitos, podendo, neste
              sentido, serem disponibilizados para consulta e backup a cada
              respectivo CLIENTE de onde os USUARIOS solicitaram serviços.
            </li>
          </ul>
        </Typography>
        <Typography variant="h5" sx={{ borderBottom: '1px solid' }}>
          Como serão utilizadas as informações
        </Typography>
        <Typography textAlign="justify">
          Internamente, os dados dos USUÁRIOS serão acessados por grupo restrito
          de profissionais devidamente autorizados pelos CLIENTES ao cumprimento
          das finalidades, respeitando os princípios de finalidade, adequação,
          necessidade e demais princípios previstos no art. 6o, da Lei n.
          13.709/2018, além do compromisso de confidencialidade e preservação
          nos termos desta Política de Privacidade.
        </Typography>
        <Typography variant="h5" sx={{ borderBottom: '1px solid', mt: 2 }}>
          Consulta de Dados
        </Typography>
        <Typography textAlign="justify">
          Os USUÁRIOS podem a qualquer tempo acessar o site gerenciador do
          software e consultar as informações constates em seus cadastros, bem
          como altera-las, e os protocolos de atendimento abertos como
          interessado, como os status e opção de complemento, quando abertos
          ainda estiverem.
        </Typography>
        <Typography variant="h5" sx={{ borderBottom: '1px solid', mt: 2 }}>
          Armazenamento dos Dados e Registros
        </Typography>
        <Typography textAlign="justify">
          Os dados e os registros de atividades coletados serão armazenados em
          ambiente seguro e controlado, nos termos da Lei nº 12.965/14 (“Marco
          Civil da Internet”) e do art. 6o, VII, da Lei n. 13.709/2018,
          observado o estado da técnica disponível. Todavia, considerando que
          nenhum sistema de segurança é infalível, a Rede Participar Brasil de
          Tecnologia se exime de quaisquer responsabilidades por eventuais danos
          e/ou prejuízos decorrentes de invasões do nosso banco de dados, nos
          casos em que não concorrer com dolo ou culpa.
          <br />
          <br />
          Os dados coletados são armazenados em servidores terceirizados na
          nuvem.
          <br />
          <br />
          A permissão para o Backup individualizado da base de dados de cada
          respectivo CLIENTE de onde os USUARIOS solicitaram serviços só é
          liberado e/ou disponibilizado às pessoas identificadas formalmente
          como Controladores de Dados. Assim, para maior segurança, ficando
          registradas todas as ações nesse sentido que realizarem.
          <br />
          <br />
          No caso de cancelamento do contrato para o uso do software pelo
          CLIENTE, a Rede Participar Brasil de Tecnologia fica do direito de
          excluir dos seus servidores, sem qualquer aviso prévio, toda base de
          dados existente relativa à prestação de serviço contratada e
          encerrada.
        </Typography>
        <Typography variant="h5" sx={{ borderBottom: '1px solid', mt: 2 }}>
          Disposições Gerais
        </Typography>
        <Typography textAlign="justify">
          {`A Política de Privacidade adotada pelo site da Rede Participar Brasil
          de Tecnologia, que serve de acesso software, não se aplica a qualquer
          outra plataforma que a ele esteja associado ou que, de alguma forma,
          esteja relacionado por meio de "links" ou de outras formas de
          divulgação e acesso.`}
          <br />
          <br />
          O software contém acesso a links para sites externos cujos conteúdos e
          Políticas de Privacidade não são de responsabilidade da Rede
          Participar Brasil de Tecnologia. Assim, recomenda-se ao USUÁRIO que,
          ao ser redirecionado para outros ambientes externos, consulte sempre
          as respectivas Políticas de Privacidade antes de fornecer seus dados
          ou informações.
          <br />
          <br />
          As respostas dos protocolos realizadas pelos clientes do software
          também podem conter links para sites externos cujos conteúdos e
          Políticas de Privacidade não são de responsabilidade da Rede
          Participar Brasil de Tecnologia. Assim, recomenda-se ao USUÁRIO que,
          ao ser redirecionado para sites externos, consulte sempre as
          respectivas Políticas de Privacidade antes de fornecer seus dados ou
          informações.
          <br />
          <br />
          Cabe ao USUÁRIO o dever de prestar informações corretas e atualizadas.
          A Rede Participar Brasil não é responsável pela precisão, veracidade
          ou falta dela nas informações prestadas pelos USUÁRIOS
          <br />
          <br />
          Caso haja alguma dúvida sobre as condições estabelecidas nesta
          Política de Privacidade ou qualquer documento do software, por favor
          entre em contato por meio dos canais de atendimento da Rede Participar
          Brasil de Tecnologia disponíveis em nosso portal oficial
          (www.redeparticiparbrasil.com.br).
          <br />
          <br />O USUÁRIO reconhece que toda comunicação realizada por e-mail e
          SMS (aos endereços e números por ele informados), aplicativos de
          comunicação instantânea ou qualquer outra forma digital ou virtual
          também são válidas como prova documental, sendo eficazes e suficientes
          para a divulgação de qualquer assunto que se refira aos serviços
          prestados pela Rede Participar Brasil de Tecnologia, através do
          software, bem como às condições de sua prestação, ressalvadas as
          disposições expressamente diversas previstas nesta Política de
          Privacidade.
        </Typography>
        <Typography variant="h5" sx={{ borderBottom: '1px solid', mt: 2 }}>
          Mudanças na Política de Privacidade
        </Typography>
        <Typography textAlign="justify">
          A Rede Participar Brasil de Tecnologia poderá alterar o teor desta
          Política de Privacidade a qualquer momento, com o consentimento do
          USUÁRIO, conforme a finalidade ou necessidade, tal qual para adequação
          e conformidade legal de disposição de lei ou norma que tenha força
          jurídica equivalente, cabendo ao USUÁRIO verificá-la sempre que
          efetuar o acesso ao site.
        </Typography>
        <Typography variant="h5" sx={{ borderBottom: '1px solid', mt: 2 }}>
          Lei Aplicável e Jurisdição
        </Typography>
        <Typography textAlign="justify">
          Esta Política de Privacidade e a relação decorrente das ações aqui
          compreendidas, assim como qualquer disputa que surja em virtude disto
          será regulada exclusivamente pela legislação brasileira.
          <br />
          <br />
          Fica eleito o Foro da Comarca da Capital do Estado do Ceará para
          dirimir qualquer questão envolvendo o presente documento, renunciando
          as partes a qualquer outro, por mais privilegiado que seja ou venha a
          ser.
        </Typography>
        <Typography variant="h5" sx={{ borderBottom: '1px solid', mt: 2 }}>
          Contato
        </Typography>
        <Typography textAlign="justify">
          Qualquer dúvida em relação à nossa política de privacidade pode ser
          esclarecida entrando em contato conosco e solicite contato com nosso
          controlador de dados:
          <ul>
            <li>
              Envie um e-mail para contato@redeparticiparbrasil.com.br ou ligue:
              (85) 3265-4404;
            </li>
            <li>Responsável: Silvia Marta Rubens Bezerra;</li>
            <li>
              Estamos localizados na Av. Júlio Abreu, nº 160, Sala 308 - Ed. Hot
              Center - Varjota - CEP 60.160-240.
            </li>
          </ul>
        </Typography>
        <Divider />
        <Box
          pt={2}
          gap={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title="clique para aceitar">
            <Button
              variant="contained"
              startIcon={<Check />}
              onClick={() => {
                setAccept(true)
                onClose()
              }}
            >
              aceitar termos
            </Button>
          </Tooltip>
          <Tooltip title="clique para recusar">
            <Button
              variant="outlined"
              startIcon={<Close />}
              onClick={() => {
                setAccept(false)
                onClose()
              }}
            >
              recusar
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </Modal>
  )
}

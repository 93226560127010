import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SplashScreen } from '../components/TelaLoading'
import { Api } from '../../../../shared/services/api/axios-config'
import {
  BarChart,
  DarkMode,
  InfoOutlined,
  KeyOff,
  Login,
  Menu,
  PersonAdd,
} from '@mui/icons-material'
import defaultLogo from '../../../../images/default_logo500.png'
import { useAppThemeContext } from '../../../../shared/contexts'
import { SlideMediaSAC } from '../components/Slide'
import { LoginSacModal } from '../components/LoginModal'
import { CadastreSeModal } from '../components/CadastreSeModal'
import { VForm, VTextField } from '../../../../shared/forms'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { externalLogin } from '../../../../shared/services/api/login-services/LoginServices'
import {
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { InfoModal } from '../components/InfoModal'
import { EstatisticasModal } from '../components/EstatisticasModal'

export interface SacData {
  id: number
  status: boolean
  sac_slug: string
  sac_logo: string
  sac_title: string
  sac_responsible: string
  sac_working_hour: string
  sac_address: string
  sac_email: string
  sac_phones: Array<{ title: string; number: string }>
  sac_cellphones: Array<{ title: string; number: string }>
}

export const LoginSAC: FC = () => {
  const { slug } = useParams<'slug'>()
  const { toggleTheme } = useAppThemeContext()

  const navigate = useNavigate()

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const [loginModal, setLoginModal] = useState(false)
  const [infoModal, setInfoModal] = useState(false)
  const [estatisticasModal, setEstatisticasModal] = useState(false)

  const [customerServiceData, setCustomerServiceData] = useState<SacData>()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const [isOpen, setIsOpen] = useState(false)

  const [cadastreSeModal, setCadastreSeModal] = useState(false)

  const handleSubmit = (formData: object) => {
    setIsLoading(true)
    externalLogin(formData)
      .then((res) => {
        if (res instanceof Error) {
          NotificacaoError(res.message)
        } else {
          sessionStorage.setItem('EXTERNAL_ID', res.id.toString())
          sessionStorage.setItem('EXTERNAL_TOKEN', res.token)
          sessionStorage.setItem('EXTERNAL_NAME', res.name)

          NotificacaoSucesso('Logado com sucesso!')
          navigate(`/sac/${slug}/inicio`)
        }
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    setIsLoading(true)
    if (slug) {
      Api.get(`/V1/customer_service/externalusers/access/${slug}/`)
        .then((res) => {
          setErrorMessage('')
          setCustomerServiceData(res.data)
        })
        .catch((err) => {
          setErrorMessage((err as { message: string }).message)
          console.log(err)
        })
        .finally(() => setIsLoading(false))
    }
  }, [slug])

  return isLoading || errorMessage ? (
    <SplashScreen errorMessage={errorMessage} loading={isLoading} />
  ) : (
    <>
      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Box bgcolor={theme.palette.background.paper} height="100%">
          <img
            width="200px"
            src={defaultLogo}
            alt="TickDoc Logo"
            loading="eager"
          />
          <Divider />
          <List>
            <ListItemButton
              onClick={() => {
                setIsOpen(false)
                if (!smDown) {
                  setLoginModal(true)
                }
              }}
            >
              <ListItemIcon>
                <Login />
              </ListItemIcon>
              <ListItemText primary="Login" />
            </ListItemButton>
            <Divider />

            <ListItemButton
              onClick={() => {
                setIsOpen(false)
                setCadastreSeModal(true)
              }}
            >
              <ListItemIcon>
                <PersonAdd />
              </ListItemIcon>
              <ListItemText primary="Cadastre-se" />
            </ListItemButton>
            <Divider />

            <ListItemButton
              onClick={() => {
                setIsOpen(false)
                setInfoModal(true)
              }}
            >
              <ListItemIcon>
                <InfoOutlined />
              </ListItemIcon>
              <ListItemText primary="Informações Gerais" />
            </ListItemButton>
            <Divider />

            <ListItemButton
              onClick={() => {
                setIsOpen(false)
                setEstatisticasModal(true)
              }}
            >
              <ListItemIcon>
                <BarChart />
              </ListItemIcon>
              <ListItemText primary="Dados estatísticos" />
            </ListItemButton>
            <Divider />

            <ListItemButton
              onClick={() => {
                setIsOpen(false)
                toggleTheme()
              }}
            >
              <ListItemIcon>
                <DarkMode />
              </ListItemIcon>
              <ListItemText primary="Alterar tema" />
            </ListItemButton>
            <Divider />
          </List>
        </Box>
      </Drawer>
      <Box display="flex" flexDirection="column" width="100vw" height="100vh">
        <Box
          boxSizing="border-box"
          alignItems="center"
          display="flex"
          width="100vw"
          height="85px"
          component={Paper}
          borderRadius={0}
          px={1}
          gap={1}
        >
          <IconButton onClick={() => setIsOpen(true)}>
            <Menu fontSize="large" />
          </IconButton>
          <Typography textAlign="center" variant="h1" fontSize={24}>
            {customerServiceData?.sac_title}
          </Typography>
        </Box>

        {smDown ? (
          <Box
            display="flex"
            flex="1"
            justifyContent="center"
            alignItems="center"
          >
            <Paper sx={{ padding: 2, gap: 1, boxSizing: 'border-box' }}>
              <img
                width="300px"
                style={{ maxWidth: '100vw' }}
                src={customerServiceData?.sac_logo}
                alt="sac logo"
              />
              <Typography variant="h2" fontSize={16} textAlign="center">
                Faça Log-In
              </Typography>

              <VForm onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <VTextField
                    required
                    name="document_number"
                    label="CPF/CNPJ"
                    size="small"
                    inputProps={{
                      maxLength: 18,
                    }}
                    formatData={formatToCPFOrCNPJ}
                    fullWidth
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                  <VTextField
                    name="password"
                    required
                    label="Senha"
                    type="password"
                    size="small"
                    fullWidth
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    gap={2}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={1}
                    >
                      <Button
                        type="submit"
                        startIcon={<Login />}
                        variant="contained"
                        disableElevation
                      >
                        entrar
                      </Button>
                      <Button
                        variant="outlined"
                        startIcon={<PersonAdd />}
                        onClick={() => setCadastreSeModal(true)}
                      >
                        cadastre-se
                      </Button>
                    </Box>
                    <Button startIcon={<KeyOff />} fullWidth>
                      esqueci a senha
                    </Button>
                  </Box>
                </Box>
              </VForm>
            </Paper>
          </Box>
        ) : (
          <Box overflow="hidden">
            <SlideMediaSAC sacId={customerServiceData?.id} />
          </Box>
        )}
        {customerServiceData && (
          <>
            <LoginSacModal
              onClose={() => setLoginModal(false)}
              show={loginModal}
              customerServiceLogo={customerServiceData.sac_logo}
            />
            <CadastreSeModal
              customerServiceLogo={customerServiceData.sac_logo}
              onClose={() => setCadastreSeModal(false)}
              show={cadastreSeModal}
            />
            <InfoModal
              customerServiceData={customerServiceData}
              onClose={() => setInfoModal(false)}
              show={infoModal}
            />
            <EstatisticasModal
              onClose={() => setEstatisticasModal(false)}
              show={estatisticasModal}
            />
          </>
        )}
      </Box>
    </>
  )
}

import { FC, useEffect, useMemo, useState } from 'react'
import {
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'

import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDaListagem,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import { Footer } from '../../../shared/components/tabela/Footer'
import { Environment } from '../../../shared/environment'
import {
  SACExternalUser,
  getAllSacExternalUsersAdmin,
  getAllSacExternalUsersMaster,
} from '../../../shared/services/api/sac-services'
import { Link, useSearchParams } from 'react-router-dom'
import { useDebounce } from '../../../shared/hooks'
import { FormatDateTime, changePage } from '../../../shared/utils'
import { InfoOutlined } from '@mui/icons-material'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const UsuarioExternoSAC: FC = () => {
  const { debounce } = useDebounce(1000)
  const { thisModule } = useModuleContext()

  const [update, setUpdate] = useState(true)

  const [rows, setRows] = useState<SACExternalUser[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)

  const [searchParams, setSearchParams] = useSearchParams()
  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])
  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])
  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      if (thisModule?.profile === 0) {
        getAllSacExternalUsersAdmin(busca, item, total)
          .then((result) => {
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              setRows(result.results)
              setTotalCount(result.count)
            }
          })
          .finally(() => {
            setUpdate(false)
            setIsLoading(false)
          })
      } else if (thisModule?.profile === 2) {
        getAllSacExternalUsersMaster(thisModule.id, busca, item, total)
          .then((result) => {
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              setRows(result.results)
              setTotalCount(result.count)
            }
          })
          .finally(() => {
            setUpdate(false)
            setIsLoading(false)
          })
      }
    })
  }, [update, busca, item, total, thisModule?.profile])

  return (
    <LayoutBaseDePagina
      title="Usuários externos"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputDaBusca
          textoDaBusca={busca}
          aoMudarTextoDaBusca={(text) =>
            setSearchParams(
              { busca: text, item: '0', total },
              { replace: true },
            )
          }
        />
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            {isLoading && totalCount > 0 && (
              <TableRow>
                <TableCell colSpan={thisModule?.profile === 2 ? 7 : 6}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">E-mail</TableCell>
              <TableCell align="center">Documento</TableCell>
              {thisModule?.profile === 2 && (
                <TableCell align="center">Protocolos</TableCell>
              )}
              <TableCell align="center">Último login em</TableCell>
              <TableCell align="center">Quantidade de acessos</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <Link to={`details/${row.id}`}>
                    <IconButton>
                      <InfoOutlined color="primary" />
                    </IconButton>
                  </Link>
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.document_number}</TableCell>
                {thisModule?.profile === 2 && (
                  <TableCell align="center">{row.protocols || 0}</TableCell>
                )}
                <TableCell align="center">
                  {row.last_sign_in_at
                    ? FormatDateTime(row.last_sign_in_at)
                    : 'Ainda não fez login'}
                </TableCell>
                <TableCell align="center">{row.sign_in_count}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {!isLoading && totalCount === 0 && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <Footer
            colSpan={thisModule?.profile === 0 ? 6 : 7}
            item={Number(item)}
            loading={isLoading}
            onChangePagination={(_, newPage) =>
              setSearchParams(
                { busca, item: changePage(newPage, Number(total)), total },
                { replace: true },
              )
            }
            onChangeTotalItems={(e) =>
              setSearchParams(
                { busca, item: '0', total: e.target.value },
                { replace: true },
              )
            }
            total={total}
            totalCount={totalCount}
          />
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  )
}

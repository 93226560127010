/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket'
import { Environment } from '../environment'
import { useAuthContext } from './AuthContext'
import { useDebounce } from '../hooks'
import { NotificacaoError } from '../components'
import { useModuleContext } from './ModulesContext'

interface IDrawerOptions {
  icon: string
  path: string
  label: string
}

type OnlineUserData = [number, string, string, number]

interface INotifies {
  id: number
  text: string
  status: boolean
  protocol_id: number
  identifier: string
  created_at: string
  instance_type: string
}

interface IDrawerContextData {
  hasError: boolean
  connected: boolean
  recconecting: number
  openNotifies: boolean
  isDrawerOpen: boolean
  onlineUsers: any
  notifies: INotifies[]
  unreadNotifies: number
  toggleDrawerOpen: () => void
  toggleOpenNotifies: () => void
  drawerOptions: IDrawerOptions[]
  setDrawerOptions: (newDrawerOptions: IDrawerOptions[]) => void
}

const DrawerContext = createContext({} as IDrawerContextData)

interface IAppThemeProviderProps {
  children: React.ReactNode
}

export const useDrawerContext = () => {
  return useContext(DrawerContext)
}

export const DrawerProvider: React.FC<IAppThemeProviderProps> = ({
  children,
}) => {
  const { debounce } = useDebounce(1000)

  const { user, isAuthenticated } = useAuthContext()

  const [notifies, setNotifies] = useState<INotifies[]>([])
  const [total, setTotal] = useState(localStorage.getItem('APP_NOTIFIES'))
  const retries = useRef(1)
  const error = useRef(false)

  const onlineUsers = useRef<OnlineUserData[]>([])

  const connected = useRef(false)

  const didUnmount = useRef(false)

  const [openNotifies, setOpenNotifies] = useState(false)

  const notifySound = new Audio('/notification_sound.wav')

  useEffect(() => {
    debounce(() => {
      const unreadNotifies = notifies.filter((notify) => !notify.status)
      if (total === null) {
        setTotal(String(unreadNotifies.length))
      } else if (Number(total) < unreadNotifies.length) {
        setTotal(String(unreadNotifies.length))
        if (
          !window.location.pathname.includes('/ouvidoria/') ||
          !window.location.pathname.includes('/sac/')
        ) {
          notifySound.volume = 0.2
          notifySound.play()
        }
      } else {
        setTotal(String(unreadNotifies.length))
      }
    })
  }, [debounce, notifies, total])

  useEffect(() => {
    if (total !== null) {
      localStorage.setItem('APP_NOTIFIES', total)
    }
  }, [total])

  const onOpen = (e: any) => {
    console.log('Websocket connected successfully')
    connected.current = true
    retries.current = 1
  }

  const onMessage = (e: MessageEvent) => {
    const data = JSON.parse(e.data)
    if (data.action === 'delete') {
      if (data.instance_type === 'delete') {
        setNotifies([])
      } else {
        setNotifies(data.data)
      }
    } else {
      setNotifies((oldValue) => [
        ...oldValue.filter((instance) => instance.id !== data.id),
        data,
      ])
    }
  }

  const onClose = (e: CloseEvent) => {
    console.error('Notifications socket closed unexpectedly')
    connected.current = false
    error.current = true
  }

  const myWebsocket = useWebSocket(
    Environment.URL_WEBSOCKET + '/ws/notifies/' + String(user?.id) + '/',
    {
      onOpen: (e) => onOpen(e),
      onMessage: (e) => onMessage(e),
      onClose: (e) => onClose(e),
      onReconnectStop: (e) => (retries.current = 11),
      onError: () => (retries.current += 1),
      shouldReconnect: (closeEvent) => {
        return didUnmount.current === false
      },
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    },
  )

  const onlineUsersWebsocket = useWebSocket(
    Environment.URL_WEBSOCKET + '/ws/online_users/' + String(user?.id) + '/',
    {
      onOpen: (e) => console.log('openned'),
      onClose: (e) => console.log('closed'),
      onMessage: (e) => {
        const data = JSON.parse(e.data)
        onlineUsers.current = data
        console.log(JSON.parse(e.data))
      },
      onError: (e) => {
        console.error(
          'Websocket disconnected. Online users will not be updated',
        )
      },
      // onReconnectStop: (e) => {
      //   NotificacaoError(
      //     'Usuários Online não estão sendo atualizados. Recarregue a página para atualizar novamente',
      //   )
      // },
      shouldReconnect: (closeEvent) => {
        return didUnmount.current === false
      },
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    },
  )

  const { currentModule, moduleTitle } = useModuleContext()

  useEffect(() => {
    if (isAuthenticated && currentModule && moduleTitle) {
      myWebsocket.getWebSocket()
      onlineUsersWebsocket.getWebSocket()
    } else {
      try {
        myWebsocket.getWebSocket()?.close()
      } catch {
        console.log('websocket already disconnected')
      }
    }
  }, [isAuthenticated])

  const [isDrawerOpen, setIsDrowerOpen] = useState(false)
  const [drawerOptions, setDrawerOptions] = useState<IDrawerOptions[]>([])

  const toggleDrawerOpen = useCallback(() => {
    setIsDrowerOpen((oldDrawerOpen) => !oldDrawerOpen)
  }, [])

  const toggleOpenNotifies = useCallback(() => {
    setOpenNotifies((oldValue) => !oldValue)
  }, [])

  const handleSetDrawerOptions = useCallback(
    (newDrawerOptions: IDrawerOptions[]) => {
      setDrawerOptions(newDrawerOptions)
    },
    [],
  )

  return (
    <DrawerContext.Provider
      value={{
        drawerOptions,
        onlineUsers,
        toggleDrawerOpen,
        notifies,
        hasError: error.current,
        isDrawerOpen,
        openNotifies,
        connected: connected.current,
        recconecting: retries.current,
        toggleOpenNotifies,
        setDrawerOptions: handleSetDrawerOptions,
        unreadNotifies: total !== null ? Number(total) : 0,
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}

import { useState, useEffect, useMemo } from 'react'
import { useField } from '@unform/core'
import { useDebounce } from '../../../../shared/hooks'
import { NotificacaoError } from '../../../../shared/components'
import {
  Autocomplete,
  CircularProgress,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'
import { Api } from '../../../../shared/services/api/axios-config'

type TAutoCompleteOptions = {
  id: number
  label: string
}

interface IAutoCompleteProps {
  isExternalLoading?: boolean
  textStyle?: SxProps<Theme>
  institutionId?: string
  onChange?: (e: number | undefined) => void
  disabled?: boolean
}

export const AutoComplete: React.FC<IAutoCompleteProps> = ({
  disabled = false,
  isExternalLoading = false,
  textStyle = {},
  onChange,
  institutionId,
}) => {
  const [firstTime, setFirstTime] = useState(true)
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField('responsible_ombudsman')

  const [selectedId, setSelectedId] = useState<number | null>(null)
  const { debounce } = useDebounce(1000)
  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newValue) => setSelectedId(newValue),
    })
  }, [registerField, fieldName, selectedId])

  useEffect(() => {
    setFirstTime(false)
    setSelectedId(defaultValue)
  }, [defaultValue, firstTime])

  useEffect(() => {
    setIsLoading(true)
    if (firstTime) {
      setFirstTime(false)
      setSelectedId(defaultValue)
      Api.get(`/V1/users/ombudsmen/${institutionId}/?search=${search}`).then(
        (result) => {
          setIsLoading(false)

          if (result instanceof Error) {
            NotificacaoError('Erro ao Carregar Usuários')
          } else {
            setOptions(
              result.data.map((state: { id: number; username: string }) => ({
                id: state.id,
                label: state.username,
              })),
            )
          }
        },
      )
    } else {
      debounce(() => {
        Api.get(`/V1/users/ombudsmen/${institutionId}/?search=${search}`).then(
          (result) => {
            setIsLoading(false)

            if (result instanceof Error) {
              NotificacaoError('Erro ao Carregar Usuários')
            } else {
              setOptions(
                result.data.map((state: { id: number; username: string }) => ({
                  id: state.id,
                  label: state.username,
                })),
              )
            }
          },
        )
      })
    }
  }, [search, institutionId, firstTime, defaultValue, debounce])

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null

    const selectedOption = options.find((option) => option.id === selectedId)
    if (!selectedId) return null

    return selectedOption
  }, [selectedId, options])

  return (
    <Autocomplete
      value={autoCompleteSelectedOption}
      loading={isLoading}
      sx={{
        zIndex: 0,
      }}
      disabled={disabled}
      openText="Abrir"
      closeText="Fechar"
      noOptionsText="Sem Opções"
      disablePortal
      popupIcon={
        isExternalLoading || isLoading ? (
          <CircularProgress size={20} />
        ) : undefined
      }
      loadingText="Carregando..."
      onInputChange={(_, newValue) => setSearch(newValue)}
      inputValue={search}
      options={options}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.id || null)
        clearError()
        onChange?.(newValue?.id)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={textStyle}
          size="small"
          label="Ouvidor Responsável"
          error={!!error}
          helperText={error}
        />
      )}
    />
  )
}

import { useState, useEffect } from 'react'
import { useField } from '@unform/core'
import { Switch, SwitchProps } from '@mui/material'

type TVSwitchProps = SwitchProps & {
  name: string
}

export const VSwitch: React.FC<TVSwitchProps> = ({ name, ...rest }) => {
  const { fieldName, registerField, defaultValue } = useField(name)

  const [value, setValue] = useState<true | false>(defaultValue || false)

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [fieldName, registerField, value])

  return (
    <Switch
      {...rest}
      sx={rest.sx}
      defaultValue={defaultValue}
      defaultChecked={defaultValue}
      value={value}
      onChange={(_, e) => {
        setValue(e)
        rest.onChange?.(_, e)
      }}
    />
  )
}

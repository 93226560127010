import { FC, ReactNode, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Divider,
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  Button,
} from '@mui/material'

import { useAppThemeContext } from '../contexts'
import { Api } from '../services/api/axios-config'
import { IClientDetail } from '../services/api'
import logo from '../../images/default_logo.png'

import { PowerSettingsNew } from '@mui/icons-material'

interface ILayoutUsuarioExternoProps {
  barraDeFerramentas?: ReactNode
  children: ReactNode
  title?: string
}

export const LayoutusuarioExterno: FC<ILayoutUsuarioExternoProps> = ({
  barraDeFerramentas,
  children,
  title,
}) => {
  const { slug } = useParams<'slug'>()
  const [data, setData] = useState<IClientDetail>()

  useEffect(() => {
    slug
      ? Api.get(`/V1/institution/${slug}/`).then((result) => {
          setData(result.data)
        })
      : setData(undefined)
  }, [slug])

  const navigate = useNavigate()
  const { themeName } = useAppThemeContext()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const handleLogout = () => {
    sessionStorage.removeItem('EXTERNAL_ID')
    sessionStorage.removeItem('EXTERNAL_TOKEN')
    sessionStorage.removeItem('EXTERNAL_NAME')

    navigate(`/ouvidoria/${slug}/login`)
  }

  return (
    <Box
      height="100%"
      width="100%"
      display={'flex'}
      flexDirection="column"
      gap={1}
      overflow="hidden"
    >
      <Box
        padding={1}
        display="flex"
        alignItems="center"
        height={theme.spacing(smDown ? 6 : mdDown ? 8 : 12)}
      >
        <Box marginLeft={2} width="100%" flexDirection="row" display="flex">
          <img
            width={
              smDown
                ? theme.spacing(20)
                : mdDown
                ? theme.spacing(25)
                : theme.spacing(30)
            }
            src={`${data?.logo || logo}`}
            alt="logo"
          />
        </Box>
        <Box display="flex" flex="1" justifyContent="right">
          <Button
            variant="outlined"
            startIcon={<PowerSettingsNew />}
            sx={{ borderRadius: '50px' }}
            onClick={() => handleLogout()}
          >
            Sair
          </Button>
        </Box>
      </Box>

      <Divider />

      {!!title && (
        <Typography
          marginLeft={2}
          variant={smDown ? 'h6' : mdDown ? 'h5' : 'h4'}
          overflow="hidden"
          component="strong"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          color={themeName === 'dark' ? '#f7f6f3' : '#202124'}
        >
          {title}
        </Typography>
      )}
      {barraDeFerramentas && <Box>{barraDeFerramentas}</Box>}

      <Box flex={1} overflow="auto" padding={1}>
        {children}
      </Box>
    </Box>
  )
}

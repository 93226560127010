import { FC, useEffect, useState } from 'react'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useField } from '@unform/core'
import { useDebounce } from '../../../../shared/hooks'
import { getAllTypologies } from '../../../../shared/services/api'
import { NotificacaoError } from '../../../../shared/components'

interface IAutocompleteOptions {
  id: number
  label: string
}

interface AutocompleteTypologiesProps {
  name: string
}

export const AutocompleteTypologies: FC<AutocompleteTypologiesProps> = ({
  name,
}) => {
  const { fieldName, registerField, error, clearError } = useField(name)
  const { debounce } = useDebounce(1000)

  const [search, setSearch] = useState<string>('')
  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<IAutocompleteOptions[]>([])
  const [value, setValue] = useState<number>()

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [fieldName, registerField, value])

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllTypologies('0', '999999999', search, '', '', '').then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar tipologias')
        } else {
          setOptions(
            result.results.map((option) => ({
              id: option.id,
              label: option.name,
            })),
          )
        }
      })
    })
  }, [debounce, search])

  return (
    <Autocomplete
      disablePortal
      options={options}
      loading={isLoading}
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      noOptionsText="Nenhuma opção encontrada"
      inputValue={search}
      onInputChange={(_, text) => {
        setSearch(text)
        clearError()
      }}
      onChange={(_, option) => {
        setValue(option?.id)
        clearError()
      }}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          error={!!error}
          label="Tipologia"
          helperText={error}
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

import {
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, useState } from 'react'
import { FormBoxStyle } from '../../../../../shared/components'
import { StatusModal } from './StatusModal'
import { Cancel, Save } from '@mui/icons-material'
import { SacProtocol } from '../../../../../shared/services/api/sac-services'

interface ConcluirModalProps {
  show: boolean
  onClose(): void
  protocolId: number
  setData(data: SacProtocol): void
}

export const ConcluirModal: FC<ConcluirModalProps> = ({
  onClose,
  show,
  setData,
  protocolId,
}) => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const [selectStatus, setSelectStatus] = useState(false)

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Typography variant="h6" color="#FFFF00" textAlign="center">
          ATENÇÃO
        </Typography>
        <Divider />
        <Box display="flex" margin={2} flexDirection="column">
          <Typography textAlign="center" variant="h6">
            Tem certeza que deseja finalizar esta manifestação?
          </Typography>
          <Typography paragraph textAlign="center">
            Após a finalização, não será possível realizar as seguintes ações:
          </Typography>
          <Typography variant="h6" fontSize={16} textAlign="center">
            - Alteração de tipologia
          </Typography>
          <Typography variant="h6" fontSize={16} textAlign="center">
            - Encaminhamento por email
          </Typography>
          <Typography variant="h6" fontSize={16} textAlign="center">
            - Novas respostas
          </Typography>
          <Typography variant="h6" fontSize={16} textAlign="center">
            - Tranferência de setor
          </Typography>
          <br />
          <Typography textAlign="center">Deseja continuar?</Typography>
        </Box>
        <Box display="flex" width="100%" justifyContent="center" gap={1}>
          <Button
            startIcon={<Save />}
            onClick={() => setSelectStatus(true)}
            disableElevation
            variant="contained"
            sx={{ borderRadius: '50px' }}
          >
            finalizar
          </Button>
          <Button
            startIcon={<Cancel />}
            variant="outlined"
            onClick={onClose}
            sx={{ borderRadius: '50px' }}
          >
            cancelar
          </Button>
        </Box>
        <StatusModal
          show={selectStatus}
          onClose={() => setSelectStatus(false)}
          secondClose={() => onClose()}
          id={protocolId}
          setData={setData}
        />
      </Box>
    </Modal>
  )
}

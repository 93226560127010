import { Divider, Grid, LinearProgress, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
  SacProtocolViews,
  getSacProtocolViews,
} from '../../../../../shared/services/api/sac-services'
import { useParams } from 'react-router-dom'
import { NotificacaoError } from '../../../../../shared/components'
import { FormatDateTime } from '../../../../../shared/utils'

export const Visualizacoes: FC = () => {
  const { id } = useParams<'id'>()

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<SacProtocolViews[]>([])

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getSacProtocolViews(id).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setData(result)
        }
      })
    }
  }, [id])

  return (
    <Grid container spacing={2} direction="column">
      {isLoading && (
        <Grid item>
          <LinearProgress />
        </Grid>
      )}
      {data.map((view, index) => (
        <Grid container item spacing={1} direction="column" key={index}>
          <Divider />
          <Grid item>
            <Typography>{`${view.user} visualizou`}</Typography>
          </Grid>
          <Grid item>
            <Typography>{`às ${FormatDateTime(view.date)}`}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

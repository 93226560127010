import { Box, Typography, useTheme } from '@mui/material'

export function FormatPriority(value: number) {
  const theme = useTheme()

  return (
    <Box
      display="flex"
      borderRadius="15px"
      height={theme.spacing(3)}
      justifyContent="center"
      alignItems="center"
      color="black"
      bgcolor={
        value === 0
          ? '#999999'
          : value === 1
          ? '#55CBCD'
          : value === 2
          ? '#FFFF00'
          : '#EB3324'
      }
    >
      <Typography
        variant="subtitle2"
        component="h6"
        textOverflow="ellipsis"
        noWrap
        color="black"
      >
        {value === 0
          ? 'Sem prioridade'
          : value === 1
          ? 'Baixa'
          : value === 2
          ? 'Média'
          : 'Alta'}
      </Typography>
    </Box>
  )
}

import { admin } from './admin'
import { auditor } from './auditor'
import { auxiliarHR, auxiliar } from './auxiliar'
import { ombudsmanHR, ombudsman } from './ombudsman'

export const ombudsmanOptions = {
  admin,
  auditor,
  auxiliar,
  auxiliarHR,
  ombudsman,
  ombudsmanHR,
}

import { useCallback, useRef } from 'react'
import { FormHandles } from '@unform/core'

export const useVForm = () => {
  const formRef = useRef<FormHandles>(null)

  const alreadySeved = useRef(false)
  const alreadyUpdated = useRef(false)

  const handleSaved = useCallback(() => {
    return alreadySeved.current
  }, [])

  const handleUpdated = useCallback(() => {
    return alreadyUpdated.current
  }, [])

  return {
    formRef,

    saved: handleSaved,
    updated: handleUpdated,

    setSaved: alreadySeved,
    setUpdated: alreadyUpdated,
  }
}

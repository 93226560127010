/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useRef, useState, useMemo, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
} from '@mui/material'

import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDaListagem,
  NotificacaoAlerta,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import { useDebounce } from '../../../shared/hooks'
import { useAuthContext } from '../../../shared/contexts'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import usePersistedState from '../../../shared/hooks/usePersistedState'
import {
  SacCategory,
  getAllSacCategories,
} from '../../../shared/services/api/sac-services'
import { FormatDateTime, FormatStatus, changePage } from '../../../shared/utils'
import { Environment } from '../../../shared/environment'
import { Footer } from '../../../shared/components/tabela/Footer'

import { Formulario } from './formulario/Formulario'
import { InfoOutlined } from '@mui/icons-material'

export const CategoriaSAC: FC = () => {
  const update = useRef(true)

  const { debounce } = useDebounce(1000)
  const { user } = useAuthContext()
  const { moduleTitle, thisModule } = useModuleContext()

  const [isLoading, setIsLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [rows, setRows] = useState<SacCategory[]>([])

  const [viewForm, setViewForm] = useState(false)

  const [institution, setInstitution] = usePersistedState<string>(
    'ADM_SELECTED_INSTITUTION',
    '',
  )

  const [searchParams, setSearchParams] = useSearchParams()
  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])
  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])
  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])
  const customerService = useMemo(() => {
    return searchParams.get('sac') || ''
  }, [searchParams])

  useEffect(() => {
    if (thisModule?.profile !== 0 && thisModule?.id) {
      setSearchParams(
        {
          busca,
          item: '0',
          total: '10',
          sac: String(thisModule.id),
        },
        { replace: true },
      )
    }
  }, [busca, setSearchParams, thisModule])

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllSacCategories(
        busca,
        item,
        total,
        user?.modules[0].profile === 0 && institution
          ? String(institution)
          : String(user?.institution_id),
        customerService,
      ).then((result) => {
        setIsLoading(false)
        update.current = false
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setRows(result.results)
          setTotalCount(result.count)
        }
      })
    })
  }, [busca, item, total, institution, customerService, update.current])

  return (
    <LayoutBaseDePagina
      selectInstitution
      autoCompleteChange={(e) => setInstitution(e ? String(e) : '')}
      title="Categorias"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputDaBusca
          mostrarBotaoNovo
          textoDaBusca={busca}
          aoClicarEmNovo={() => {
            if (user?.modules[0].profile === 0 && !institution) {
              NotificacaoAlerta('Selecione uma instituição')
            } else {
              setViewForm(true)
            }
          }}
          aoMudarTextoDaBusca={(texto) =>
            setSearchParams(
              {
                busca: texto,
                item: '0',
                total,
                sac: customerService,
              },
              { replace: true },
            )
          }
        />
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={50}></TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center">Última atualização</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <Link
                    to={`/customer-service/${moduleTitle}/categories/details/${row.id}`}
                  >
                    <IconButton color="primary">
                      <InfoOutlined />
                    </IconButton>
                  </Link>
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.created_at)}
                </TableCell>
                <TableCell align="center">
                  {row.created_at !== row.updated_at
                    ? FormatDateTime(row.created_at)
                    : 'Não houve atualizações'}
                </TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <Footer
            colSpan={5}
            item={Number(item)}
            loading={isLoading}
            onChangePagination={(_, newValue) =>
              setSearchParams(
                {
                  busca,
                  total,
                  sac: customerService,
                  item: changePage(newValue, Number(total)),
                },
                { replace: true },
              )
            }
            onChangeTotalItems={(e) =>
              setSearchParams(
                {
                  busca,
                  item: '0',
                  total: e.target.value,
                  sac: customerService,
                },
                { replace: true },
              )
            }
            total={total}
            totalCount={totalCount}
          />
        </Table>
      </TableContainer>
      <Formulario
        create
        show={viewForm}
        update={update}
        onClose={() => setViewForm(false)}
        institution={
          user?.modules[0].profile === 0
            ? Number(institution)
            : Number(user?.institution_id)
        }
      />
    </LayoutBaseDePagina>
  )
}

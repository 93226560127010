import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  FerramentasDaListagem,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import { useEffect, useMemo, useState } from 'react'
import { Environment } from '../../../shared/environment'
import {
  SacPredefinedReply,
  getAllpredefinedAnswers,
} from '../../../shared/services/api/sac-services/respostas-pre-definidas-services'
import { FormatDateTime, FormatStatus, changePage } from '../../../shared/utils'
import { Footer } from '../../../shared/components/tabela/Footer'
import { Formulario } from './fomulario/Formulario'
import { InfoOutlined } from '@mui/icons-material'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import { useAuthContext } from '../../../shared/contexts'
import usePersistedState from '../../../shared/hooks/usePersistedState'
import { useSearchParams } from 'react-router-dom'

export const RespostasPreDefinidaSAC = () => {
  const { thisModule } = useModuleContext()
  const { user } = useAuthContext()

  const [institution, setInstitution] = usePersistedState<string>(
    'ADM_SELECTED_INSTITUTION',
    '',
  )

  const [rows, setRows] = useState<SacPredefinedReply[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(0)

  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)
  const [data, setData] = useState<SacPredefinedReply>()

  const [update, setUpdate] = useState(true)

  const [searchParams, setSearchParams] = useSearchParams()
  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])
  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])
  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  useEffect(() => {
    setIsLoading(true)
    if (thisModule) {
      getAllpredefinedAnswers(
        busca,
        item,
        total,
        thisModule?.profile === 0 ? '' : String(thisModule?.id),
        thisModule?.profile === 0 ? institution : String(user?.institution_id),
      )
        .then((result) => {
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            setRows(result.results)
            setTotalCount(result.count)
          }
        })
        .finally(() => {
          setIsLoading(false)
          setUpdate(false)
        })
    }
  }, [
    update,
    busca,
    item,
    total,
    institution,
    thisModule?.id,
    thisModule?.profile,
    user?.institution_id,
  ])

  return (
    <LayoutBaseDePagina
      selectInstitution
      title="Respostas pré-definidas"
      autoCompleteChange={(e) => setInstitution(e ? String(e) : '')}
      barraDeFerramentas={
        <FerramentasDaListagem
          textoDaBusca={busca}
          aoMudarTextoDaBusca={(text) =>
            setSearchParams(
              { busca: text, item: '0', total },
              { replace: true },
            )
          }
          aoClicarEmNovo={() => {
            setCreate(true)
            setData(undefined)
            setViewForm(true)
          }}
        />
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50} />
              <TableCell align="center">Título</TableCell>
              <TableCell align="center">Texto</TableCell>
              <TableCell align="center">Craiada em</TableCell>
              <TableCell align="center">Atualizada em</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setCreate(false)
                      setData(row)
                      setViewForm(true)
                    }}
                  >
                    <InfoOutlined />
                  </IconButton>
                </TableCell>
                <TableCell align="center">{row.title}</TableCell>
                <TableCell align="center">
                  <Typography
                    textAlign="justify"
                    variant="body2"
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3,
                    }}
                  >
                    {row.text}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.created_at)}
                </TableCell>
                <TableCell align="center">
                  {row.created_at !== row.updated_at
                    ? FormatDateTime(row.updated_at)
                    : 'Não houve alterações'}
                </TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {!isLoading && totalCount === 0 && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <Footer
            colSpan={6}
            item={Number(item)}
            loading={isLoading}
            onChangePagination={(_, newPage) =>
              changePage(newPage, Number(total))
            }
            onChangeTotalItems={(e) =>
              setSearchParams(
                { busca, item: '0', total: e.target.value },
                { replace: true },
              )
            }
            total={total}
            totalCount={totalCount}
          />
        </Table>
      </TableContainer>
      {((thisModule?.profile === 0 && institution !== '') ||
        (thisModule?.profile !== 0 && user)) && (
        <Formulario
          show={viewForm}
          update={() => setUpdate(true)}
          create={create}
          data={!create ? data : undefined}
          onClose={() => setViewForm(false)}
          institution={
            thisModule?.profile === 0
              ? institution
              : String(user?.institution_id)
          }
        />
      )}
    </LayoutBaseDePagina>
  )
}

import { Divider, Grid, LinearProgress, Typography } from '@mui/material'
import { FC } from 'react'
import { IGroupingHistory } from '../../../../shared/services/api/histories-services/GroupingHistoryService'
import { FormatDateTime } from '../../../../shared/utils'

interface IGroupingHistoryProps {
  history: IGroupingHistory[] | undefined
  historyLoading: boolean
}

export const GroupingHistory: FC<IGroupingHistoryProps> = ({
  historyLoading = true,
  history,
}) => {
  return (
    <Grid container spacing={2} direction="column">
      {historyLoading && (
        <LinearProgress variant="indeterminate" sx={{ borderRadius: '50px' }} />
      )}

      {!!history && (
        <Grid container item spacing={2} direction="column">
          {history.map((query) => (
            <>
              <Divider variant="middle" />
              {query.type === '~' ? (
                <Grid container item>
                  {query.field === 'name' && (
                    <Grid container item direction="column">
                      <Grid item>
                        <Typography>
                          Nome alterado de ({query.old}) para ({query.new})
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>Alterado por: {query.user}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          Data: {FormatDateTime(query.date)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {query.field === 'icon' && (
                    <Grid container item direction="column">
                      <Grid item>
                        <Typography>
                          Ícone alterado de ({query.old}) para ({query.new})
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>Alterado por: {query.user}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          Data: {FormatDateTime(query.date)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {query.field === 'status' && (
                    <Grid container item direction="column">
                      <Grid item>
                        <Typography>
                          {query.old
                            ? 'Agrupamento desativado'
                            : 'Agrupamento ativado'}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {query.old
                            ? `Desativado por ${query.user}`
                            : `Ativado por ${query.user}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          Data: {FormatDateTime(query.date)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>Agrupamento criado</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Criado por: {query.user || 'Não definido'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Data: {FormatDateTime(query.date)}</Typography>
                  </Grid>
                </Grid>
              )}
            </>
          ))}
        </Grid>
      )}
    </Grid>
  )
}

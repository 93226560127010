import { FC, useEffect, useMemo, useState } from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import {
  NotificacaoError,
  TabPanel,
  VTab,
  VTabs,
  a11yProps,
} from '../../../../shared/components'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Api } from '../../../../shared/services/api/axios-config'
import { Environment } from '../../../../shared/environment'
import { SplashScreen } from '../components/TelaLoading'
import { PowerSettingsNew } from '@mui/icons-material'
import { Perfil } from '../../protocolo/formularios/Perfil'
import { NovoProtocoloSAC } from './components/NovoProtocolo'
import { SacProtocolsExternal } from './components/Protocolos'

interface SacData {
  id: number
  status: boolean
  sac_slug: string
  sac_logo: string
  sac_title: string
  institution: number
}

export const Acesso: FC = () => {
  const navigate = useNavigate()

  const { slug } = useParams<'slug'>()

  const [searchParams, setSearchParams] = useSearchParams()

  const tabParam = useMemo(() => {
    return searchParams.get('tab') || '0'
  }, [searchParams])

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || ''
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || ''
  }, [searchParams])

  const status = useMemo(() => {
    return searchParams.get('status') || ''
  }, [searchParams])

  const [customerServiceData, setCustomerServiceData] = useState<SacData>()
  const [isLoading, setIsLoading] = useState(true)
  const [id] = useState(sessionStorage.getItem('EXTERNAL_ID'))
  const [name] = useState(sessionStorage.getItem('EXTERNAL_NAME'))
  const [token] = useState(sessionStorage.getItem('EXTERNAL_TOKEN'))

  const [tab, setTab] = useState(0)

  const handleChange = (newValue: number) => {
    setSearchParams(
      {
        tab: String(newValue),
        busca,
        status,
        item,
        total,
      },
      { replace: true },
    )
  }

  const handleLogout = () => {
    sessionStorage.clear()
    navigate(`/sac/${slug}/login`)
  }

  useEffect(() => {
    setIsLoading(true)
    if (slug) {
      if (!(id && name && token)) {
        sessionStorage.clear()
        NotificacaoError('Faça login')
        navigate(`/sac/${slug}/login`)
      } else {
        Api.get(`/V1/customer_service/externalusers/access/${slug}/`)
          .then((res) => {
            setCustomerServiceData(res.data)
          })
          .catch(handleLogout)
          .finally(() => setIsLoading(false))
      }
    }
  }, [id, name, token, slug])

  useEffect(() => {
    setTab(Number(tabParam || 0))
  }, [searchParams, tabParam])

  return isLoading ? (
    <SplashScreen errorMessage="" loading={isLoading || !customerServiceData} />
  ) : (
    <Box
      height="100%"
      width="100%"
      display={'flex'}
      flexDirection="column"
      gap={1}
      overflow="hidden"
    >
      <Box
        boxSizing="border-box"
        alignItems="center"
        display="flex"
        width="100vw"
        height="85px"
        component={Paper}
        borderRadius={0}
        px={1}
        gap={1}
      >
        <img
          src={
            process.env.NODE_ENV === 'production'
              ? customerServiceData?.sac_logo
              : `${Environment.URL_BASE}${`${customerServiceData?.sac_logo}`}`
          }
          height="80px"
          alt={customerServiceData?.sac_title}
        />
        <Typography
          textAlign="center"
          variant="h1"
          fontSize={24}
          fontWeight="500"
        >
          {customerServiceData?.sac_title}
        </Typography>
        <Box display="flex" flex="1" justifyContent="end">
          <Button
            variant="outlined"
            startIcon={<PowerSettingsNew />}
            onClick={handleLogout}
          >
            sair
          </Button>
        </Box>
      </Box>

      <Box flex={1} overflow="auto" padding={1}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <VTabs
            value={tab}
            onChange={(_, newValue) => handleChange(newValue)}
            aria-label="tabs-home"
          >
            <VTab
              label="Novo Protocolo"
              {...a11yProps(0)}
              sx={{ width: '33.3%' }}
            />
            <VTab
              wrapped
              label="Acompanhar protocolos"
              {...a11yProps(1)}
              sx={{ width: '33.3%', marginLeft: 'auto', marginRight: 'auto' }}
            />
            <VTab label="Perfil" {...a11yProps(2)} sx={{ width: '33.3%' }} />
          </VTabs>
        </Box>
        <TabPanel value={Number(tab)} index={0}>
          {customerServiceData ? (
            <NovoProtocoloSAC
              customerServiceId={customerServiceData.id}
              institutionId={customerServiceData.institution}
            />
          ) : (
            <SplashScreen errorMessage="" loading />
          )}
        </TabPanel>
        <TabPanel value={Number(tab)} index={1}>
          <SacProtocolsExternal />
        </TabPanel>
        <TabPanel value={Number(tab)} index={2}>
          <Perfil token={token} />
        </TabPanel>
      </Box>
    </Box>
  )
}

import {
  Box,
  Divider,
  Grid,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { IClientDetail } from '../../../shared/services/api'
import { FormBoxStyle } from '../../../shared/components'
import {
  LocalPhone,
  MailOutline,
  PersonPin,
  PinDrop,
  QueryBuilder,
  WhatsApp,
} from '@mui/icons-material'

interface IInformacaoModalProps {
  show: boolean
  institutionData: IClientDetail
  onClose: () => void
}

export const InformacaoModal: React.FC<IInformacaoModalProps> = ({
  institutionData,
  onClose,
  show,
}) => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        overflow="auto"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Typography textAlign="center" variant="h6">
          Informações Gerais
        </Typography>
        <Divider />
        <Box margin={1}>
          <Grid container spacing={2} direction="column">
            <Grid container item spacing={2} direction="row">
              <Grid
                item
                spacing={2}
                xs={12}
                display="flex"
                alignItems="center"
                gap={2}
              >
                <PersonPin fontSize="large" />
                <Typography fontSize="20px">
                  Gestor(a): {institutionData.contact_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} direction="row">
              <Grid
                item
                spacing={2}
                xs={12}
                display="flex"
                alignItems="center"
                gap={2}
              >
                <QueryBuilder fontSize="large" />
                <Typography fontSize="20px">
                  Horário de funcionamento: {institutionData.working_hour}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} direction="row">
              <Grid
                item
                spacing={2}
                xs={12}
                display="flex"
                alignItems="center"
                gap={2}
              >
                <PinDrop fontSize="large" />
                <Typography fontSize="20px">
                  Endereço: {institutionData.address}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} direction="row">
              <Grid
                item
                spacing={2}
                xs={12}
                display="flex"
                alignItems="center"
                gap={2}
              >
                <MailOutline fontSize="large" />
                <Typography fontSize="20px">
                  E-mail: {institutionData.ombudsman_email}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} direction="row">
              <Grid
                item
                spacing={2}
                xs={12}
                display="flex"
                justifyContent="center"
                gap={2}
                direction="column"
              >
                <Box display="flex" gap={2} alignItems="center">
                  <LocalPhone fontSize="large" />
                  <Typography fontSize="20px">Números de telefone:</Typography>
                </Box>
                {institutionData.phones.map((phone, index) => (
                  <Typography gap={1} key={index}>
                    {phone.title}
                    {': '}
                    {phone.number}
                  </Typography>
                ))}
              </Grid>
            </Grid>
            <Grid container item spacing={2} direction="row">
              <Grid
                item
                spacing={2}
                xs={12}
                display="flex"
                justifyContent="center"
                gap={2}
                direction="column"
              >
                <Box display="flex" gap={2} alignItems="center">
                  <WhatsApp fontSize="large" />
                  <Typography fontSize="20px">Números de whatsapp:</Typography>
                </Box>
                {institutionData.cellphones.map((cellphone, index) => (
                  <Typography gap={1} key={index}>
                    {cellphone.title}
                    {': '}
                    {cellphone.number}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  )
}

import {
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC } from 'react'
import { Cancel, Save } from '@mui/icons-material'
import { FormBoxStyle, ModalHeader } from '../../../../shared/components'
import { useVForm } from '../../../../shared/forms'
import { TAutoCompleteOptions } from '../../components/AutoComplete'

interface ModalSummaryProps {
  show: boolean
  onClose: () => void
  protocolId?: number | string
  createProtocol: () => void
  protocolData: any
  sector?: TAutoCompleteOptions
  assunto?: TAutoCompleteOptions
  tipologia?: TAutoCompleteOptions
  description?: string
}

export const ModalSummary: FC<ModalSummaryProps> = ({
  onClose,
  show,
  createProtocol,
  protocolData,
  sector,
  assunto,
  tipologia,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const responseCode = Number(protocolData?.kind_response) ?? 1
  const kindResponse = KIND_RESPONSE[responseCode as keyof typeof KIND_RESPONSE]

  const { formRef } = useVForm()

  const handleClose = () => {
    formRef.current?.reset()
    onClose()
  }

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        overflow="auto"
        maxHeight="85vh"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader showCloseButton title="Resumo" onClose={handleClose} />

        <Divider />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
          }}
        >
          <h2 style={{ margin: 0, padding: 0 }}>Setor</h2>
          <span>{sector?.label}</span>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
          }}
        >
          <h2 style={{ margin: 0, padding: 0 }}>Forma de Resposta</h2>
          <span>{kindResponse}</span>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
          }}
        >
          <h2 style={{ margin: 0, padding: 0 }}>Assunto</h2>
          <span>{assunto?.label}</span>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
          }}
        >
          <h2 style={{ margin: 0, padding: 0 }}>Tipologia</h2>
          <span>{tipologia?.label}</span>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
            marginBottom: 16,
          }}
        >
          <h2 style={{ margin: 0, padding: 0 }}>Descrição</h2>
          <span>{protocolData?.description || 'N/A'}</span>
        </div>

        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          alignItems="center"
          mt={1}
          gap={1}
        >
          <Button
            type="button"
            onClick={createProtocol}
            variant="contained"
            startIcon={<Save />}
            disableElevation
          >
            confirmar
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            startIcon={<Cancel />}
          >
            voltar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

const KIND_RESPONSE = {
  1: 'Email',
  2: 'Telefone',
  3: 'Pessoalmente',
  4: 'Carta',
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react'
import { Grid, Icon, Typography, TypographyProps } from '@mui/material'

interface ILegendaSuamrioProps {
  icon: string
  name: string
  fontSize?: 'inherit' | 'small' | 'medium' | 'large'
  sxIcon?: any
  typgraphyColor?: string
  ItypographyProps?: TypographyProps
}

export const LegendaSumario: FC<ILegendaSuamrioProps> = ({
  icon,
  name,
  fontSize = 'large',
  sxIcon = {},
  typgraphyColor,
  ItypographyProps,
}) => {
  return (
    <Grid container>
      <Grid container item spacing={1} alignItems="center">
        <Grid item alignItems="center">
          <Typography textAlign="center" {...ItypographyProps}>
            <Icon fontSize={fontSize} sx={sxIcon}>
              {icon}
            </Icon>
          </Typography>
        </Grid>
        <Grid item alignItems="center">
          <Typography
            textAlign="center"
            variant="h6"
            noWrap
            textOverflow="ellipsis"
            color={typgraphyColor}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

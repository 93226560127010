/* eslint-disable @typescript-eslint/ban-types */
import { IUserDetail } from '../admin-users-services/AdminusersServices'
import { Api } from '../axios-config'
import { IExternalUsers } from '../usuario-externo-services/UsuarioExternoServices'

export interface IReplie {
  id: number
  reply: string
  file_link: string[]
  file: string
  type: number
  insitution_id: number
  user_id: IUserDetail
  external_user_id: IExternalUsers
  transfer: boolean
  change_typology: boolean
  protocol_id: number
  created_at: string
}

const getAllReplies = async (
  protocolId: number,
  token?: string,
): Promise<IReplie[] | Error> => {
  try {
    const relativeUrl = `/V1/protocols/${protocolId}/replies/`

    const headers = token ? { Authorization: `Token ${token}` } : {}

    const { data } = await Api.get(relativeUrl, { headers })

    if (data) return data

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const createReply = async (
  protocolId: number,
  formData: {},
  token?: string,
): Promise<IReplie | Error> => {
  try {
    const headers = token ? { Authorization: `Token ${token}` } : {}
    const relativeUrl = `/V1/protocols/${protocolId}/replies/`

    const { data } = await Api.post(relativeUrl, formData, { headers })

    if (data) return data
    return new Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getAnonymousReplies = async (formData: {}): Promise<
  IReplie[] | Error
> => {
  try {
    const relativeUrl = `/V1/protocols/anonymous/replies/`

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao carrgar mensagens')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export { getAnonymousReplies, getAllReplies, createReply }

/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from 'react-router-dom'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { LegendaSumario, NotificacaoError } from '../../../shared/components'
import {
  IForward,
  IProtocols,
  getAllForward,
  getProtocolById,
} from '../../../shared/services/api'
import {
  FormatDate,
  FormatDateTime,
  FormatProtocolStatus,
} from '../../../shared/utils'
import {
  CalendarMonth,
  Check,
  Download,
  ExpandMore,
  MoveDown,
  Sell,
  Send,
  Troubleshoot,
} from '@mui/icons-material'
import AccordionDetails from '@mui/material/AccordionDetails'
import { DetalhesSolicitacao } from './components/DadosSolicitacao'
import { DetalhesCidadao } from './components/DetalhesCidadao'
import { ConteudoSolicitacao } from './components/ConteudoManifestacao'
import { IModule, useAuthContext } from '../../../shared/contexts'
import Skeleton from '@mui/material/Skeleton'
import { RespostasManifestacao } from './components/RespostasManifestacao'
import { AnaliseModal } from './Analise'
import { ConcluirModal } from './ConcluirModal'
import { TransferirModal } from './TransferirModal'
import { EncaminharModal } from './EncaminharModal'
import {
  IProtocolHistory,
  IView,
  getProtocolHistorical,
  getViews,
} from '../../../shared/services/api/histories-services/ProtocolHistoryServices'
import { ProrrogarModal } from './ProrrogarModal'
import { PDFModal } from './components/PDFModal'
import { ChangeTypologyModal } from './components/ChangeTypology'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const DetalhesProtocolo: React.FC = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { user } = useAuthContext()
  const { id = 'new' } = useParams<'id'>()
  const [analysis, setAnalysis] = useState(true)

  const navigate = useNavigate()

  const updated = useRef(false)

  const [data, setData] = useState<IProtocols>()
  const [viewData, setViewData] = useState<IView[]>([])
  const [historyData, setHistoryData] = useState<IProtocolHistory[]>([])
  const [viewLoading, setViewLoading] = useState(true)
  const [forwards, setForwards] = useState<IForward[]>([])
  const [historyLoading, setHistoryLoading] = useState(true)

  const [isLoading, setIsLoading] = useState(false)
  const [changeTypologie, setChangeTypologie] = useState(false)
  const [name, setName] = useState('')
  const [concluirModal, setConcluirModal] = useState(false)
  const [transferirModal, setTransferirModal] = useState(false)
  const [encaminharModal, setEncaminharModal] = useState(false)
  const [prorrogarModal, setProrrogarModal] = useState(false)
  const [day, setDay] = useState(1)
  const [pdfModal, setPdfModal] = useState(false)

  /**
   * Domingo: 0
   * Segunda: 1
   * Terça: 2
   * Quarta: 3
   * Quinta: 4
   * Sexta: 5
   * Sábado: 6
   */

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true)

      getProtocolById(id).then((result) => {
        updated.current = false
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError(result.message)
          navigate(-1)
        } else {
          setName(result.identifier)
          setData(result)
          const day = new Date(String(`${result.expires_at} 23:00:00`))
          setDay(day.getDay())
        }
      })

      getAllForward(Number(id)).then((result) => {
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar encaminhamentos')
        } else {
          setForwards(result)
        }
      })
    }
  }, [id, updated.current])

  useEffect(() => {
    getViews(Number(id)).then((result) => {
      setViewLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        setViewData(result)
      }
    })
  }, [id, updated.current])

  useEffect(() => {
    getProtocolHistorical(Number(id)).then((result) => {
      setHistoryLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        setHistoryData(result)
      }
    })
  }, [id, updated.current])

  const [thisModule, setThisModule] = useState<IModule>()

  const { currentModule, moduleTitle } = useModuleContext()

  useEffect(() => {
    setThisModule(
      user?.modules.find(
        (mdl) => mdl.type === currentModule && mdl.title === moduleTitle,
      ),
    )
  }, [user?.modules, currentModule, moduleTitle])

  const close = () => {
    setAnalysis(false)
  }

  return (
    <LayoutBaseDePagina title={`${name}` || 'Detalhes Protocolo'}>
      <Box
        gap={1}
        marginX={1}
        padding={1}
        paddingX={1}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        {AnaliseModal(
          data ? !data?.analysis_started && analysis : false,
          close,
          updated,
        )}
        <Box display="flex" justifyContent="space-between" paddingBottom={1}>
          {data ? (
            <Tooltip title="status">
              <Typography width="100px">
                {FormatProtocolStatus(
                  Number(data?.status),
                  false,
                  theme.spacing(6),
                  '10px',
                  18,
                )}
              </Typography>
            </Tooltip>
          ) : (
            <Skeleton width="100px" height={theme.spacing(6)} />
          )}
          <Box display="flex" gap={1}>
            {!data?.analysis_started && (
              <Tooltip title="Iniciar Análise">
                <IconButton
                  onClick={() => setAnalysis(true)}
                  type="button"
                  disabled={!data}
                  sx={{
                    p: 1,
                    border: '1px solid dodgerblue',
                    width: '45px',
                    height: '45px',
                  }}
                >
                  <Troubleshoot />
                </IconButton>
              </Tooltip>
            )}
            {Number(data?.deadline) > -1 &&
              Number(data?.status) < 4 &&
              Number(data?.deadline) < (day === 0 ? 3 : day === 6 ? 2 : 1) &&
              !xsDown && (
                <Tooltip title="Prorrogar">
                  <IconButton
                    onClick={() => setProrrogarModal(true)}
                    disabled={!data?.analysis_started}
                    type="button"
                    sx={{
                      p: 1,
                      border: '1px solid dodgerblue',
                      width: '45px',
                      height: '45px',
                    }}
                  >
                    <CalendarMonth />
                  </IconButton>
                </Tooltip>
              )}
            {thisModule?.profile === 2 &&
              data?.analysis_started &&
              Number(data?.status) < 5 && (
                <Tooltip title="Concluir">
                  <IconButton
                    onClick={() => setConcluirModal(true)}
                    disabled={!data?.analysis_started}
                    type="button"
                    sx={{
                      p: 1,
                      border: '1px solid dodgerblue',
                      width: '45px',
                      height: '45px',
                    }}
                  >
                    <Check />
                  </IconButton>
                </Tooltip>
              )}
            {thisModule?.profile === 2 && (
              <Tooltip title="Enviar por e-mail">
                <IconButton
                  onClick={() => setEncaminharModal(true)}
                  disabled={!data?.analysis_started}
                  type="button"
                  sx={{
                    p: 1,
                    border: '1px solid dodgerblue',
                    width: '45px',
                    height: '45px',
                  }}
                >
                  <Send />
                </IconButton>
              </Tooltip>
            )}
            {thisModule?.profile === 2 && Number(data?.status) < 5 && (
              <Tooltip title="Transferir setor">
                <IconButton
                  onClick={() => setTransferirModal(true)}
                  disabled={!data?.analysis_started}
                  type="button"
                  sx={{
                    p: 1,
                    border: '1px solid dodgerblue',
                    width: '45px',
                    height: '45px',
                  }}
                >
                  <MoveDown />
                </IconButton>
              </Tooltip>
            )}
            {thisModule?.profile === 2 &&
              Number(data?.status) < 5 &&
              !xsDown && (
                <Tooltip title="alterar tipologia">
                  <IconButton
                    onClick={() => setChangeTypologie(true)}
                    type="button"
                    sx={{
                      p: 1,
                      border: '1px solid dodgerblue',
                      width: '45px',
                      height: '45px',
                    }}
                  >
                    <Sell />
                  </IconButton>
                </Tooltip>
              )}
            <Tooltip title="PDF">
              <IconButton
                type="button"
                onClick={() => setPdfModal(true)}
                sx={{
                  p: 1,
                  border: '1px solid dodgerblue',
                  width: '45px',
                  height: '45px',
                }}
              >
                <Download />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        {!!data && xsDown && (
          <Box display="flex" justifyContent="center" width="100%">
            {Number(data.deadline) > -1 && Number(data.deadline) < 3 && (
              <Button
                variant="outlined"
                onClick={() => setProrrogarModal(true)}
                sx={{ borderRadius: '50px' }}
                fullWidth
                startIcon={<CalendarMonth />}
              >
                Prorrogar
              </Button>
            )}
          </Box>
        )}
        <Divider />
        <div
          style={{
            display: 'grid',
            marginRight: '8px',
            gridTemplateColumns: xsDown
              ? '100%'
              : smDown
              ? '50% 50%'
              : '66.6% 33.4%',
            gridTemplateRows: 'fit-content',
            columnGap: '8px',
          }}
        >
          <Grid container direction="column" mt={1}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Accordion
                  defaultExpanded
                  sx={xsDown ? { maxWidth: '93vw' } : {}}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid container spacing={1} direction="column">
                      <Grid container item>
                        <Grid item display="flex" alignItems="center" gap={1}>
                          <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            border="2px solid dodgerblue"
                            borderRadius="5px"
                          >
                            <Icon fontSize="large" sx={{ color: 'dodgerblue' }}>
                              toc
                            </Icon>
                          </Typography>
                          <Typography variant="h6" color="dodgerblue">
                            Conteúdo da Manifestação
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item spacing={1} direction="row">
                        <Grid item>
                          <Typography>Criado: </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            {FormatDateTime(data?.created_at, 'Indefinido')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Divider orientation="vertical" />
                        </Grid>
                        <Grid item>
                          <Typography>Prazo: </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            {FormatDate(data?.expires_at, 'Indefinido')}
                          </Typography>
                        </Grid>
                        {Number(data?.status) > 4 && (
                          <Grid item>
                            <Divider orientation="vertical" />
                          </Grid>
                        )}
                        {Number(data?.status) > 4 && (
                          <Grid container item spacing={1}>
                            <Grid item>
                              <Typography>Finalizado:</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>
                                {FormatDateTime(
                                  data?.finished_at,
                                  'Indefinido',
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ConteudoSolicitacao protocolData={data} />
                  </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                      container
                      item
                      display="flex"
                      sx={{ alignItems: 'center' }}
                      gap={1}
                    >
                      <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                        border="2px solid dodgerblue"
                        borderRadius="5px"
                      >
                        <Icon fontSize="large" sx={{ color: 'dodgerblue' }}>
                          message_outlined
                        </Icon>
                      </Typography>
                      <Typography variant="h6" color="dodgerblue">
                        Respostas
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingBottom: '0px' }}>
                    <RespostasManifestacao
                      sendDisabled={Number(data?.status) > 4}
                      protocolId={data?.id}
                      secondUpdate={updated}
                      institutionId={data?.institution_id}
                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary id="testing" expandIcon={<ExpandMore />}>
                    <LegendaSumario
                      icon="forward_to_inbox"
                      name="Encaminhamentos"
                      typgraphyColor="dodgerblue"
                      ItypographyProps={{
                        color: 'dodgerblue',
                        border: '2px solid dodgerblue',
                        borderRadius: '5px',
                        sx: { display: 'flex', alignItems: 'center' },
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} direction="column">
                      {forwards.map((forward, index) => (
                        <Grid
                          container
                          item
                          spacing={1}
                          direction="column"
                          key={index}
                        >
                          <Grid item>
                            <Typography>
                              {forward.user_id} encaminhou
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography>
                              Email de destino: {forward.email}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography>
                              Assunto: {forward.subject || 'Sem assunto'}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography>
                              Comentário: {forward.comment || 'Sem comentário'}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography>
                              Data: {FormatDateTime(forward.created_at)}
                            </Typography>
                          </Grid>
                          {index !== forwards.length - 1 && (
                            <Grid item>
                              <Divider />
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <LegendaSumario
                      icon="restore_outlined"
                      name="Histórico"
                      sxIcon={{ color: 'dodgerblue' }}
                      typgraphyColor="dodgerblue"
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {historyLoading && (
                      <Box flex="1" padding={1}>
                        <LinearProgress
                          variant="indeterminate"
                          sx={{ borderRadius: '50px', height: '5px' }}
                        />
                      </Box>
                    )}
                    {!!historyData && (
                      <Grid container spacing={2} direction="column">
                        {historyData.map((query) => (
                          <>
                            <Divider variant="middle" />
                            {query.type === '~' ? (
                              <Grid container item>
                                {query.field === 'grouping_id' && (
                                  <Grid container item direction="column">
                                    <Grid item>
                                      <Typography>
                                        Agrupamento transferido de ({query.old})
                                        para ({query.new})
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography>
                                        Transferido por: {query.user}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography>
                                        Data:{' '}
                                        {FormatDateTime(
                                          query.date,
                                          'Não definido',
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )}
                                {query.field === 'sector_id' && (
                                  <Grid container item direction="column">
                                    <Grid item>
                                      <Typography>
                                        Setor transferido de ({query.old}) para
                                        ({query.new})
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography>
                                        Transferido por: {query.user}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography>
                                        Data:{' '}
                                        {FormatDateTime(
                                          query.date,
                                          'Não definido',
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )}
                                {query.field === 'reply_created' && (
                                  <Grid container item direction="column">
                                    <Grid item>
                                      <Typography>
                                        Nova resposta criada
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      {query.user ? (
                                        <Typography>
                                          Criada por: {query.user}
                                        </Typography>
                                      ) : (
                                        <Typography>
                                          Criada pelo manifestante
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid item>
                                      <Typography>
                                        Data: {FormatDateTime(query.date)}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )}
                                {query.field === 'status' && (
                                  <Grid container item direction="column">
                                    <Grid item>
                                      <Typography>
                                        Status alterado de{' '}
                                        {Number(query.old) === 1
                                          ? 'Atrasado'
                                          : Number(query.old) === 2
                                          ? 'Pendente'
                                          : Number(query.old) === 3
                                          ? 'Trâmite'
                                          : Number(query.old) === 4
                                          ? 'Prorrogado'
                                          : Number(query.old) === 5
                                          ? 'Concluído'
                                          : 'Indeferido'}{' '}
                                        para{' '}
                                        {Number(query.new) === 1
                                          ? 'Atrasado'
                                          : Number(query.new) === 2
                                          ? 'Pendente'
                                          : Number(query.new) === 3
                                          ? 'Trâmite'
                                          : Number(query.new) === 4
                                          ? 'Prorrogado'
                                          : Number(query.new) === 5
                                          ? 'Concluído'
                                          : 'Indeferido'}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )}
                                {query.field === 'priority' && (
                                  <Grid container item direction="column">
                                    <Grid item>
                                      <Typography>
                                        Prioridade alterada de{' '}
                                        {Number(query.old) === 0
                                          ? '(Sem prioridade)'
                                          : Number(query.old) === 1
                                          ? '(Prioridade Baixa)'
                                          : Number(query.old) === 2
                                          ? '(Prioridade Média)'
                                          : '(Prioridade Alta)'}{' '}
                                        para{' '}
                                        {Number(query.new) === 0
                                          ? '(Sem prioridade)'
                                          : Number(query.new) === 1
                                          ? '(Prioridade Baixa)'
                                          : Number(query.new) === 2
                                          ? '(Prioridade Média)'
                                          : '(Prioridade Alta)'}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography>
                                        Alterada por: {query.user}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            ) : (
                              <Grid container item direction="column">
                                {!!query.user && !data?.anonymous ? (
                                  <Grid container item direction="column">
                                    <Grid item>
                                      <Typography>
                                        Manifestação criada via ouvidoria
                                        itinerante
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography>
                                        Usuário responsável: ({query.user})
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography>
                                        Data:{' '}
                                        {FormatDateTime(
                                          data?.created_at,
                                          'Não definido',
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ) : !data?.anonymous ? (
                                  <Grid
                                    container
                                    item
                                    direction="column"
                                    spacing={1}
                                  >
                                    <Grid item>
                                      <Typography>
                                        Manifestação criada pelo cidadão
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography>
                                        Data:{' '}
                                        {FormatDateTime(
                                          data?.created_at,
                                          'Não definido',
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Grid
                                    container
                                    item
                                    direction="column"
                                    spacing={1}
                                  >
                                    <Grid item>
                                      <Typography>
                                        Manifestação anônima criada
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography>
                                        Data: {'  '}
                                        {FormatDateTime(
                                          data?.created_at,
                                          'Não definido',
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            )}
                            {query.field === 'secret' && (
                              <Grid container item direction="column">
                                <Grid item>
                                  <Typography>
                                    Manifestação foi anonimizada
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    Anonimizada por: {query.user}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    Data:{' '}
                                    {FormatDateTime(query.date, 'Não definido')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                            {query.field === 'analysis_started' && (
                              <Grid container item direction="column">
                                <Grid item>
                                  <Typography>Análise iniciada</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    Iniciada por: {query.user}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    Data:{' '}
                                    {FormatDateTime(query.date, 'Não definido')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                            {query.field === 'responsible_ombudsman' && (
                              <Grid container item direction="column">
                                <Grid item>
                                  <Typography>
                                    {!query.old
                                      ? `${query.new} adicionado como ouvidor responsável`
                                      : !query.new
                                      ? 'Ouvidor responsável removido'
                                      : `Ouvidor responsável alterado de (${query.old}) para (${query.new})`}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    {!query.old
                                      ? `Adicionado por ${query.user}`
                                      : !query.new
                                      ? `Removido por ${query.user}`
                                      : `Alterado por ${query.user}`}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    Data:{' '}
                                    {FormatDateTime(query.date, 'Não definido')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                            {query.field === 'pdf_downloaded' && (
                              <Grid container item direction="column">
                                <Grid item>
                                  <Typography>
                                    {query.user} gerou um novo PDF
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    Data:{' '}
                                    {FormatDateTime(query.date, 'Não definido')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </>
                        ))}
                      </Grid>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="column" mt={1}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                      container
                      item
                      display="flex"
                      sx={{ alignItems: 'center' }}
                      gap={1}
                    >
                      <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                        border="2px solid dodgerblue"
                        borderRadius="5px"
                      >
                        <Icon fontSize="large" sx={{ color: 'dodgerblue' }}>
                          data_object_outlined
                        </Icon>
                      </Typography>
                      <Typography variant="h6" color="dodgerblue">
                        Dados da Solicitação
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DetalhesSolicitacao protocolData={data} update={updated} />
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                      container
                      item
                      display="flex"
                      sx={{ alignItems: 'center' }}
                      gap={1}
                    >
                      <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                        border="2px solid dodgerblue"
                        borderRadius="5px"
                      >
                        <Icon fontSize="large" sx={{ color: 'dodgerblue' }}>
                          perm_identity_outlined
                        </Icon>
                      </Typography>
                      <Typography variant="h6" color="dodgerblue">
                        Dados do Cidadão
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DetalhesCidadao protocolData={data} />
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <LegendaSumario
                      icon="visibility_outlined"
                      name="Visualizações"
                      typgraphyColor="dodgerblue"
                      ItypographyProps={{
                        color: 'dodgerblue',
                        border: '2px solid dodgerblue',
                        borderRadius: '5px',
                        sx: { display: 'flex', alignItems: 'center' },
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {viewLoading && (
                      <Box flex="1" padding={1}>
                        <LinearProgress
                          variant="indeterminate"
                          sx={{ borderRadius: '50px', height: '5px' }}
                        />
                      </Box>
                    )}
                    {!!viewData && (
                      <Grid container spacing={2} direction="column">
                        {viewData.map((view) => (
                          <>
                            <Divider variant="middle" />
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>{view.user}</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(view.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
      {isLoading && (
        <LinearProgress
          variant="indeterminate"
          sx={{ borderRadius: '50px', height: '5px' }}
        />
      )}
      {data && (
        <ConcluirModal
          onClose={() => setConcluirModal(false)}
          show={concluirModal}
          protocolData={data}
          update={updated}
        />
      )}
      <TransferirModal
        onClose={() => setTransferirModal(false)}
        show={transferirModal}
        protocolData={data}
        update={updated}
      />
      <EncaminharModal
        onClose={() => setEncaminharModal(false)}
        show={encaminharModal}
        protocolData={data}
        update={updated}
      />
      <ProrrogarModal
        onClose={() => setProrrogarModal(false)}
        show={prorrogarModal}
        protocolId={Number(data?.id)}
        update={updated}
      />
      <PDFModal
        show={pdfModal}
        onClose={() => setPdfModal(false)}
        anonynmous={data?.anonymous || false}
        defaultSecret={data?.secret || false}
      />
      <ChangeTypologyModal
        show={changeTypologie}
        onClose={() => setChangeTypologie(false)}
        update={updated}
        protocolId={data?.id}
        institutionId={data?.institution_id}
      />
    </LayoutBaseDePagina>
  )
}

import { Environment } from '../../../environment'

import axios from 'axios'
import { errorInterceptor, responseInterceptor } from './interceptors'

const Api = axios.create({
  baseURL: Environment.URL_BASE,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

const ExternalApi = axios.create({
  baseURL: Environment.URL_BASE,
  withCredentials: false,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

Api.interceptors.response.use(
  (response) => responseInterceptor(response),
  async (error) => {
    const originalRequest = error.config

    if (error.response) {
      if (
        error.response.status === 401 &&
        error?.response?.data?.code === 'token_not_valid'
      ) {
        try {
          const { data } = await axios.post(
            `${Environment.URL_BASE}/V1/api/token/refresh/`,
            {},
            {
              withCredentials: true,
            },
          )

          if (data) return Api(originalRequest)

          return errorInterceptor(error)
        } catch {
          return errorInterceptor(error)
        }
      }
    }

    return errorInterceptor(error)
  },
)

ExternalApi.interceptors.response.use(
  (response) => responseInterceptor(response),
  (error) => errorInterceptor(error),
)

export interface TotalCount<T> {
  next: string
  count: number
  previous: string
  data: T[]
  results: T[]
}

export { Api, ExternalApi }

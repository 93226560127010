/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from 'react'
import {
  Autocomplete,
  CircularProgress,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'
import { ISectors, ISubjects, ITypologies } from '../../../shared/services/api'
import { NotificacaoError } from '../../../shared/components'
import { useDebounce } from '../../../shared/hooks'
import { useField } from '@unform/core'
import { Api } from '../../../shared/services/api/axios-config'

export type TAutoCompleteOptions = {
  id: number
  label: string
}

interface IAutoCompleteProps {
  isExternalLoading?: boolean
  institutionId: string
  external?: boolean
  grouping_id?: number
  sector_id?: number
  textStyle?: SxProps<Theme>
  onChange?: (e: number) => void
}

interface IAutoSetor {
  groupingId: number | undefined
  institutionId: number | undefined
  external?: boolean
  onChange?: (e: number | undefined) => void
  setSector?: (sector: TAutoCompleteOptions) => void
}

interface IAutoAssunto {
  sectorId: number | undefined
  institutionId: number
  setAssunto?: (sector: TAutoCompleteOptions) => void
}

interface IAutoTipologia {
  institutionId: number
  anonymous?: boolean
  setTipologia?: (sector: TAutoCompleteOptions) => void
}

export const AutoCompleteTipologia: React.FC<IAutoTipologia> = ({
  institutionId,
  anonymous,
  setTipologia,
}) => {
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined)

  const { fieldName, clearError, error, registerField } =
    useField('category_id')

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newValue) => setSelectedId(newValue),
    })
  }, [registerField, fieldName, selectedId])

  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  const { debounce } = useDebounce(1000)

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      Api.get(
        `/V1/externalusers/categories/${institutionId}/?search=${search}&esic=${
          anonymous ? 'false' : ''
        }`,
      )
        .then((result) => {
          setIsLoading(false)
          setOptions(
            result.data.map((subject: ITypologies) => ({
              id: subject.id,
              label: subject.name,
            })),
          )
        })
        .catch(() => {
          setIsLoading(false)
          NotificacaoError('Erro ao carregar tipologias')
        })
    })
  }, [search])

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null

    const selectedOption = options.find((option) => option.id === selectedId)
    if (setTipologia && selectedOption) setTipologia(selectedOption)
    if (!selectedId) return null

    return selectedOption
  }, [selectedId, options])

  return (
    <Autocomplete
      value={autoCompleteSelectedOption}
      noOptionsText="Sem opções"
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      options={options}
      loading={isLoading}
      onInputChange={(_, newValue) => setSearch(newValue)}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.id)
        setSearch('')
        clearError()
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tipologia"
          size="small"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
          error={!!error}
          helperText={error}
          required
        />
      )}
    />
  )
}

export const AutoCompleteAssunto: React.FC<IAutoAssunto> = ({
  sectorId,
  institutionId,
  setAssunto,
}) => {
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined)

  const { fieldName, clearError, error, registerField } = useField('subject_id')

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newValue) => setSelectedId(newValue),
    })
  }, [registerField, fieldName, selectedId])

  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  const { debounce } = useDebounce(1000)

  useEffect(() => {
    setSelectedId(undefined)
  }, [sectorId])

  useEffect(() => {
    if (sectorId === undefined) {
      setIsLoading(false)
      setOptions([])
    } else {
      setIsLoading(true)
      debounce(() => {
        Api.get(`/V1/externalusers/subjects/${institutionId}/${sectorId}/`)
          .then((result) => {
            setIsLoading(false)
            setOptions(
              result.data.map((subject: ISubjects) => ({
                id: subject.id,
                label: subject.name,
              })),
            )
          })
          .catch(() => {
            setIsLoading(false)
            NotificacaoError('Erro ao carregar assuntos')
          })
      })
    }
  }, [search, sectorId])

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null

    const selectedOption = options.find((option) => option.id === selectedId)
    if (setAssunto && selectedOption) setAssunto(selectedOption)
    if (!selectedId) return null

    return selectedOption
  }, [selectedId, options])

  return (
    <Autocomplete
      value={autoCompleteSelectedOption}
      disabled={isLoading}
      noOptionsText={
        sectorId === undefined ? 'Selecione um setor' : 'Sem opções'
      }
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      options={options}
      loading={isLoading}
      onInputChange={(_, newValue) => setSearch(newValue)}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.id)
        setSearch('')
        clearError()
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Assunto"
          size="small"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
          error={!!error}
          helperText={error}
          required
        />
      )}
    />
  )
}

export const AutoCompleteAgrupamento: React.FC<IAutoCompleteProps> = ({
  onChange,
  external = false,
  isExternalLoading = false,
  textStyle = {},
  institutionId = '',
}) => {
  const [firstTime, setFirstTime] = useState(true)
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField('grouping_id')

  const [selectedId, setSelectedId] = useState<number | null>(null)
  const { debounce } = useDebounce(1000)
  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newValue) => setSelectedId(newValue),
    })
  }, [registerField, fieldName, selectedId])

  useEffect(() => {
    setFirstTime(false)
    setSelectedId(defaultValue)
  }, [firstTime])

  useEffect(() => {
    if (Number(institutionId)) {
      setIsLoading(true)
      debounce(() => {
        Api.get(
          `/V1/externalusers/groupings/${institutionId}/?search=${search}&status=${
            external ? 'true' : ''
          }`,
        ).then((result) => {
          setIsLoading(false)
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError('Erro ao Carregar Agrupamentos')
          } else {
            setOptions(
              result.data.map((group: any) => ({
                id: group.id,
                label: group.name,
              })),
            )
          }
        })
      })
    }
  }, [search])

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null

    const selectedOption = options.find((option) => option.id === selectedId)
    if (!selectedId) return null

    return selectedOption
  }, [selectedId, options])

  return (
    <Autocomplete
      value={autoCompleteSelectedOption}
      loading={isLoading}
      disabled={isExternalLoading}
      openText="Abrir"
      closeText="Fechar"
      noOptionsText="Sem Opções"
      disablePortal
      popupIcon={
        isExternalLoading || isLoading ? (
          <CircularProgress size={20} />
        ) : undefined
      }
      loadingText="Carregando..."
      onInputChange={(_, newValue) => setSearch(newValue)}
      inputValue={search}
      options={options}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.id || null)
        clearError()
        onChange?.(Number(newValue?.id))
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={textStyle}
          size="small"
          label="Agrupamento"
          error={!!error}
          helperText={error}
          required
        />
      )}
    />
  )
}

export const AutoCompleteSetor: React.FC<IAutoSetor> = ({
  groupingId,
  institutionId,
  external = false,
  onChange,
  setSector,
}) => {
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined)

  const { fieldName, clearError, error, registerField } = useField('sector_id')

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newValue) => setSelectedId(newValue),
    })
  }, [registerField, fieldName, selectedId])

  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  const { debounce } = useDebounce(1000)

  useEffect(() => {
    setSelectedId(undefined)
  }, [groupingId, institutionId])

  useEffect(() => {
    if (groupingId === undefined || institutionId === undefined) {
      setIsLoading(false)
      setOptions([])
    } else {
      setIsLoading(true)
      debounce(() => {
        Api.get(
          `/V1/externalusers/sectors/${institutionId}/?grouping_id=${groupingId}&search=${search}&status=${
            external ? 'true' : ''
          }`,
        )
          .then((result) => {
            setIsLoading(false)
            setOptions(
              result.data.map((sector: ISectors) => ({
                id: sector.id,
                label: sector.name,
              })),
            )
          })
          .catch(() => {
            setIsLoading(false)
            NotificacaoError('Erro ao carregar setores')
          })
      })
    }
  }, [search, groupingId, institutionId])

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null

    const selectedOption = options.find((option) => option.id === selectedId)
    if (setSector && selectedOption) setSector(selectedOption)

    if (!selectedId) return null

    return selectedOption
  }, [selectedId, options])

  return (
    <Autocomplete
      value={autoCompleteSelectedOption}
      disabled={isLoading}
      noOptionsText={
        groupingId === undefined ? 'Selecione um agrupamento' : 'Sem opções'
      }
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      options={options}
      loading={isLoading}
      onInputChange={(_, newValue) => setSearch(newValue)}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.id)
        onChange?.(newValue?.id)
        setSearch('')
        clearError()
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Setor"
          size="small"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
          error={!!error}
          helperText={error}
          required
        />
      )}
    />
  )
}

import { useParams } from 'react-router-dom'
import {
  Box,
  Button,
  LinearProgress,
  Modal,
  Paper,
  Typography,
} from '@mui/material'

import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../../shared/components'
import {
  SacProtocol,
  startSacProtocolAnalysis,
} from '../../../../../shared/services/api/sac-services'
import { FC, useState } from 'react'
import { Cancel, Save } from '@mui/icons-material'

interface AnaliseModalProps {
  show: boolean
  onClose: () => void
  setData: (data: SacProtocol) => void
}

export const AnaliseModal: FC<AnaliseModalProps> = ({
  onClose,
  setData,
  show,
}) => {
  const { id } = useParams<'id'>()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = () => {
    if (id) {
      setIsLoading(true)
      startSacProtocolAnalysis(id).then((result) => {
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          NotificacaoSucesso('Análise iniciada com sucesso!')
          setData(result)
          onClose()
        }
      })
    }
  }

  return (
    <Modal
      onClose={() => {
        if (!isLoading) {
          onClose()
        }
      }}
      open={show}
      aria-describedby="modal-title"
    >
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        width="280px"
        display="flex"
        flexDirection="column"
        gap={2}
      >
        {isLoading && <LinearProgress />}
        <Box display="flex" flex="1" justifyContent="center">
          <Typography variant="h6" textAlign="center">
            Deseja Iniciar a Análise?
          </Typography>
        </Box>
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <Button
            variant="contained"
            onClick={handleSubmit}
            disableElevation
            startIcon={<Save />}
            disabled={!id || isLoading}
          >
            iniciar
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            startIcon={<Cancel />}
            disabled={isLoading}
          >
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

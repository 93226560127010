import { useEffect, useState, useMemo } from 'react'
import {
  Autocomplete,
  CircularProgress,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'
import { useField } from '@unform/core'
import { NotificacaoError } from '../../../../shared/components'
import { useDebounce } from '../../../../shared/hooks'
import {
  getAllCities,
  getAllStates,
  getAllWorkfields,
} from '../../../../shared/services/api'

type TAutoCompleteOptions = {
  id: number
  label: string
}

interface IAutoCompleteProps {
  isExternalLoading?: boolean
  textStyle?: SxProps<Theme>
  state_id?: string
  onChange?: (e: number | undefined) => void
}

export const AutoCompleteCidades: React.FC<IAutoCompleteProps> = ({
  textStyle,
}) => {
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField('cities')
  const { debounce } = useDebounce(1000)
  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [selectedOptions, setSelectedOptions] = useState<
    TAutoCompleteOptions[]
  >([])
  const [value, setValue] = useState<number[] | null>(defaultValue || null)
  const [firstTime, setFirstTime] = useState(true)

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [registerField, fieldName, value])

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllCities('0', '999999999', search).then((result) => {
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError('Erro ao Carregar Cidades')
        } else {
          setOptions(
            result.results.map((opt) => ({ id: opt.id, label: opt.name })),
          )
        }
      })
    })
  }, [debounce, search])

  useEffect(() => {
    setValue(selectedOptions.map((option) => option.id))
  }, [selectedOptions, firstTime])

  useEffect(() => {
    setFirstTime(false)
    if (defaultValue) {
      setSelectedOptions(
        defaultValue.map((data: [string, number]) => ({
          id: data[1],
          label: data[0],
        })),
      )
    }
  }, [defaultValue, firstTime])

  return (
    <Autocomplete
      fullWidth
      multiple
      value={selectedOptions}
      limitTags={2}
      id="multiple-limit-tags"
      options={options}
      getOptionDisabled={(option) =>
        !!selectedOptions.find((opt) => option.id === opt.id)
      }
      disableCloseOnSelect
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      getOptionLabel={(option) => option.label}
      onChange={(_, value) => setSelectedOptions(value)}
      defaultValue={selectedOptions}
      onInputChange={(_, value) => setSearch(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Cidades"
          size="small"
          sx={textStyle}
          error={!!error}
          helperText={error}
          onKeyDown={error ? clearError : undefined}
        />
      )}
    />
  )
}

export const AutoCompleteEstados: React.FC<IAutoCompleteProps> = ({
  textStyle,
}) => {
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField('states')
  const { debounce } = useDebounce(1000)
  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [selectedOptions, setSelectedOptions] = useState<
    TAutoCompleteOptions[]
  >([])
  const [value, setValue] = useState<number[] | null>(defaultValue || null)
  const [firstTime, setFirstTime] = useState(true)

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [registerField, fieldName, value])

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllStates('0', '999999999', search).then((result) => {
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError('Erro ao Carregar Estados')
        } else {
          setOptions(
            result.results.map((opt) => ({ id: opt.id, label: opt.name })),
          )
        }
      })
    })
  }, [debounce, search])

  useEffect(() => {
    setValue(selectedOptions.map((option) => option.id))
  }, [selectedOptions, firstTime])

  useEffect(() => {
    setFirstTime(false)
    if (defaultValue) {
      setSelectedOptions(
        defaultValue.map((data: [string, number]) => ({
          id: data[1],
          label: data[0],
        })),
      )
    }
  }, [defaultValue, firstTime])

  return (
    <Autocomplete
      fullWidth
      multiple
      value={selectedOptions}
      limitTags={2}
      id="multiple-limit-tags"
      options={options}
      getOptionDisabled={(option) =>
        !!selectedOptions.find((opt) => option.id === opt.id)
      }
      disableCloseOnSelect
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      getOptionLabel={(option) => option.label}
      onChange={(_, value) => setSelectedOptions(value)}
      defaultValue={selectedOptions}
      onInputChange={(_, value) => setSearch(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Estados"
          size="small"
          sx={textStyle}
          error={!!error}
          helperText={error}
          onKeyDown={error ? clearError : undefined}
        />
      )}
    />
  )
}

export const AutoCompleteRamos: React.FC<IAutoCompleteProps> = ({
  isExternalLoading = false,
  textStyle = {},
}) => {
  const [firstTime, setFirstTime] = useState(true)
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField('work_field')
  const [selectedId, setSelectedId] = useState<number | null>(null)
  const { debounce } = useDebounce(1000)
  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newValue) => setSelectedId(newValue),
    })
  }, [registerField, fieldName, selectedId])

  useEffect(() => {
    setFirstTime(false)
    setSelectedId(defaultValue ? defaultValue.id : undefined)
  }, [defaultValue, firstTime])

  useEffect(() => {
    setIsLoading(true)
    if (firstTime) {
      setFirstTime(false)
      setSelectedId(defaultValue ? defaultValue.id : undefined)
      getAllWorkfields(
        '0',
        '500',
        search,
        defaultValue ? defaultValue.id : '',
      ).then((result) => {
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError('Erro ao Carregar Estados')
        } else {
          setOptions(
            result.results.map((state) => ({
              id: state.id,
              label: state.name,
            })),
          )
        }
      })
    } else {
      debounce(() => {
        getAllWorkfields('0', '500', search).then((result) => {
          setIsLoading(false)

          if (result instanceof Error) {
            NotificacaoError('Erro ao Carregar Ramos de Atividades')
          } else {
            setOptions(
              result.results.map((state) => ({
                id: state.id,
                label: state.name,
              })),
            )
          }
        })
      })
    }
  }, [debounce, defaultValue, firstTime, search])

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null

    const selectedOption = options.find((option) => option.id === selectedId)
    if (!selectedId) return null

    return selectedOption
  }, [selectedId, options])

  return (
    <Autocomplete
      value={autoCompleteSelectedOption}
      loading={isLoading}
      disabled={isExternalLoading}
      openText="Abrir"
      closeText="Fechar"
      noOptionsText="Sem Opções"
      disablePortal
      popupIcon={
        isExternalLoading || isLoading ? (
          <CircularProgress size={20} />
        ) : undefined
      }
      loadingText="Carregando..."
      onInputChange={(_, newValue) => setSearch(newValue)}
      inputValue={search}
      options={options}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.id || null)
        clearError()
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={textStyle}
          size="small"
          label="Ramo de Atividades"
          error={!!error}
          helperText={error}
        />
      )}
    />
  )
}

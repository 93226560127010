/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useRef, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Grid,
  Typography,
  LinearProgress,
  Accordion,
  AccordionDetails,
  useTheme,
  ButtonBase,
  AccordionSummary,
} from '@mui/material'

import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDeDetalhes,
  LegendaSumario,
  NotificacaoError,
} from '../../../shared/components'
import {
  SacSubjectList,
  getSacSubjectById,
} from '../../../shared/services/api/sac-services'
import { ExpandMore } from '@mui/icons-material'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import { Formulario } from './formulario/Formulario'

export const DetalhesAssuntoSAC: FC = () => {
  const theme = useTheme()
  const update = useRef(true)
  const navigate = useNavigate()
  const { id } = useParams<'id'>()
  const { moduleTitle } = useModuleContext()

  const [create, setCreate] = useState(true)
  const [viewForm, setViewForm] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<SacSubjectList>()

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getSacSubjectById(id).then((result) => {
        setIsLoading(false)
        update.current = false
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setData(result)
        }
      })
    }
  }, [id, update.current])

  return (
    <LayoutBaseDePagina
      title={data ? data.name : 'carregando...'}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          mostrarBotaoNovo
          mostrarBotaoEditar
          mostrarBotaoVoltar
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          aoClicarEmVoltar={() => navigate(-1)}
        />
      }
    >
      <Box margin={1}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario icon="info_outlined" name="Informações" />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} direction="column">
              {isLoading && (
                <Grid item>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{
                      borderRadius: '50px',
                      height: '5px',
                    }}
                  />
                </Grid>
              )}

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Status: </Typography>
                </Grid>
                <Grid item width="75px">
                  <Typography>{FormatStatus(Boolean(data?.status))}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Nome: </Typography>
                </Grid>
                <Grid item>
                  <Typography>{data?.name}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Setores: </Typography>
                </Grid>
                <Grid
                  item
                  gap={1}
                  display="flex"
                  flex="1"
                  flexWrap="wrap"
                  xs={12}
                >
                  {data?.sectors?.length === 0 && (
                    <Typography>Nenhum setor cadastrado</Typography>
                  )}
                  {data?.sectors?.map((sector) => (
                    <Box
                      key={sector.value}
                      display="flex"
                      component={ButtonBase}
                      borderRadius="15px"
                      maxWidth="120px"
                      height={theme.spacing(3)}
                      justifyContent="center"
                      alignItems="center"
                      color="black"
                      bgcolor={'dodgerblue'}
                      onClick={() =>
                        navigate(
                          `/customer-service/${moduleTitle}/sectors/details/${sector.value}`,
                        )
                      }
                    >
                      <Typography
                        margin={1}
                        variant="subtitle2"
                        component="h6"
                        textOverflow="ellipsis"
                        noWrap
                      >
                        {sector.label}
                      </Typography>
                    </Box>
                  ))}
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Criado em: </Typography>
                </Grid>
                <Grid item>
                  <Typography>{FormatDateTime(data?.created_at)}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Última atualização: </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {data?.created_at !== data?.updated_at
                      ? FormatDateTime(data?.updated_at)
                      : 'Não houve alterações'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      {data && (
        <Formulario
          data={data}
          show={viewForm}
          create={create}
          update={update}
          institution={data.institution}
          onClose={() => setViewForm(false)}
        />
      )}
    </LayoutBaseDePagina>
  )
}

import { Close } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { FC } from 'react'

interface ModalHeaderProps {
  title: string
  onClose?: () => void
  buttonText?: string
  showCloseButton?: boolean
}

export const ModalHeader: FC<ModalHeaderProps> = ({
  title,
  onClose,
  showCloseButton = false,
  buttonText = 'Fechar',
}) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
      <Typography
        variant="h6"
        component="h2"
        id="modal-title"
        textOverflow="ellipsis"
        noWrap
      >
        {title}
      </Typography>

      {showCloseButton && (
        <Tooltip title={buttonText}>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: '2rem',
            }}
          >
            <Close fontSize="large" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}

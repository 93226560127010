/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react'
import { Grid, MenuItem, Tooltip, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { VForm, VSelect } from '../../../../../shared/forms'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import { IModule, useAuthContext } from '../../../../../shared/contexts'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { NoAccounts, NotificationsActive } from '@mui/icons-material'
import { useModuleContext } from '../../../../../shared/contexts/ModulesContext'
import { SacProtocol } from '../../../../../shared/services/api/sac-services'
import { AnonimizarModal } from '../modal'

interface IDetails {
  protocolData?: SacProtocol
  setProtocolData: (data: SacProtocol) => void
  external?: boolean
}

export const DadosSolicitacao: React.FC<IDetails> = ({
  protocolData,
  setProtocolData,
  external = false,
}) => {
  const { user } = useAuthContext()
  const [change, setChange] = useState(false)
  const [notified, setNotified] = useState(true)

  const [secretModal, setSecretModal] = useState(false)

  useEffect(() => {
    if (protocolData) setNotified(protocolData.sector_notified)
    else setNotified(true)
  }, [protocolData])

  const { currentModule, moduleTitle } = useModuleContext()

  const [thisModule, setThisModule] = useState<IModule>()

  useEffect(() => {
    setThisModule(
      user?.modules.find(
        (mdl) => mdl.title === moduleTitle && mdl.type === currentModule,
      ),
    )
  }, [user?.modules, currentModule, moduleTitle])

  return (
    <Grid container spacing={2} direction="column">
      {!protocolData?.secret && thisModule?.profile === 2 && !external && (
        <Grid item xs={12}>
          <Button
            fullWidth
            disabled={!protocolData}
            variant="outlined"
            startIcon={<NoAccounts />}
            sx={{
              borderRadius: '50px',
            }}
            onClick={() => setSecretModal(true)}
          >
            Anonimizar
          </Button>
        </Grid>
      )}
      <Grid container item direction="row" spacing={1} xs={12}>
        {!notified && !external && (
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              sx={{ borderRadius: '50px' }}
              startIcon={<NotificationsActive />}
            >
              Notificar Setor
            </Button>
          </Grid>
        )}
        <Grid item xs={3}>
          <Tooltip title="Dias Pendente">
            <Box
              bgcolor="#FFFF00"
              flex="1"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
            >
              <Typography color="black" textAlign="center">
                {protocolData?.pending_days}
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Dias em Trâmite">
            <Box
              bgcolor="#CBAACB"
              flex="1"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
            >
              <Typography color="black" textAlign="center">
                {protocolData?.analyzing_days}
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Dias em Atraso">
            <Box
              bgcolor="#EB3324"
              flex="1"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
            >
              <Typography color="black" textAlign="center">
                {protocolData?.delayed_days}
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Dias Prorrogado">
            <Box
              bgcolor="#55CBCD"
              flex="1"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
            >
              <Typography color="black" textAlign="center">
                {protocolData?.extended_days}
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>

      {protocolData?.secret && (
        <Grid container item spacing={1}>
          <Grid item>
            <Typography>Manifestação sigilosa</Typography>
          </Grid>
        </Grid>
      )}

      {!external && (
        <Grid container item spacing={1}>
          <Grid item>
            <Typography>Setor notificado: </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {protocolData?.sector_notified ? 'Sim' : 'Não'}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Análise Iniciada: </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {protocolData?.analysis_started ? 'Sim' : 'Não'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Forma de resposta: </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {protocolData?.kind_response === 1
              ? 'E-mail'
              : protocolData?.kind_response === 2
              ? 'Telefone'
              : protocolData?.kind_response === 3
              ? 'Presencialmente'
              : 'Carta'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Agrupamento: </Typography>
        </Grid>
        <Grid item>
          <Typography noWrap textOverflow="ellipsis">
            {protocolData?.grouping ? protocolData?.grouping?.name : ''}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Setor: </Typography>
        </Grid>
        <Grid item>
          <Typography>{protocolData?.sector?.name}</Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Assunto: </Typography>
        </Grid>
        <Grid item>
          <Typography>{protocolData?.subject?.name}</Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Categoria: </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {protocolData?.category ? protocolData?.category.name : ''}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>IP Externo: </Typography>
        </Grid>
        <Grid item>
          {protocolData?.secret ? (
            <>
              {thisModule?.profile === 0 || external ? (
                <Typography>
                  {protocolData?.ip_address || 'Não identificado'}
                </Typography>
              ) : (
                <Typography>Sigilo</Typography>
              )}
            </>
          ) : (
            <Typography>
              {protocolData?.ip_address || 'Não identificado'}
            </Typography>
          )}
        </Grid>
      </Grid>

      {Number(protocolData?.status) > 4 && (
        <Grid container item spacing={1} direction="column">
          <Grid container item spacing={1}>
            <Grid item>
              <Typography>Respondeu a pesquisa de satisfação?</Typography>
            </Grid>
            <Grid item>
              <Typography>
                {protocolData?.replied_survey ? 'Sim' : 'Não'}
              </Typography>
            </Grid>
          </Grid>

          {!protocolData?.replied_survey &&
            protocolData?.external_user?.email && (
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <Tooltip title="enviar e-mail com a pesquisa de satisfação para o manifestante">
                    <Button variant="outlined">reenviar pesquisa</Button>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
        </Grid>
      )}
      <Grid item />

      {!!protocolData && !external ? (
        <>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <VForm
                onSubmit={(formData) => console.log(formData)}
                initialData={protocolData}
              >
                <Box display="flex" gap={2} flexDirection="column">
                  <FormControl fullWidth sx={{ zIndex: 0 }}>
                    <InputLabel id="priority">Prioridade</InputLabel>
                    <VSelect
                      size="small"
                      name="priority"
                      labelId="priority"
                      label="Prioridade"
                      disabled={Number(protocolData.status) > 4}
                      myChange={() => setChange(true)}
                      sx={{
                        borderRadius: '50px',
                      }}
                    >
                      <MenuItem value={0}>Sem Prioridade</MenuItem>
                      <MenuItem value={1}>Baixa</MenuItem>
                      <MenuItem value={2}>Média</MenuItem>
                      <MenuItem value={3}>Alta</MenuItem>
                    </VSelect>
                  </FormControl>
                </Box>
                {change && (
                  <Box display="flex" flex="1" justifyContent="right">
                    <Button type="submit" sx={{ borderRadius: '50px' }}>
                      alterar
                    </Button>
                  </Box>
                )}
              </VForm>
            </Grid>
          </Grid>
        </>
      ) : (
        <>{!external && <Skeleton height="65px" width="100%" />}</>
      )}
      {protocolData && (
        <AnonimizarModal
          onClose={() => setSecretModal(false)}
          show={secretModal}
          setData={setProtocolData}
          protocolId={protocolData?.id}
        />
      )}
    </Grid>
  )
}

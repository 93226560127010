/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, MutableRefObject, useState } from 'react'
import {
  FormBoxStyle,
  ModalHeader,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../../shared/components'
import { VForm, VTextArea, useVForm } from '../../../../../shared/forms'
import {
  SacProtocol,
  transferSacProtocol,
} from '../../../../../shared/services/api/sac-services'
import {
  AutoCompleteAssuntoSAC,
  AutocompleteAgrupamentoSAC,
  AutocompleteSetorSAC,
} from '../autocomplete'
import { Cancel, Save } from '@mui/icons-material'

interface TransferirModalProps {
  show: boolean
  onClose: () => void
  setProtocolData: (data: SacProtocol) => void
  protocolId: number
  updateReplies: MutableRefObject<boolean>
}

export const TransferirModal: FC<TransferirModalProps> = ({
  onClose,
  show,
  protocolId,
  updateReplies,
  setProtocolData,
}) => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const [grouping, setGrouping] = useState<number | undefined>(undefined)
  const [sector, setSector] = useState<number | undefined>(undefined)

  async function handleSubmit(formData: any) {
    setIsLoading(true)
    try {
      const result = await transferSacProtocol(protocolId, formData)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        NotificacaoSucesso('Protocolo transferido com sucesso!')
        setProtocolData(result)
        updateReplies.current = true
        handleClose()
      }
      setIsLoading(false)
    } catch (error) {
      NotificacaoError('Erro ao processar requisição')
      setIsLoading(false)
    }
  }

  function handleClose() {
    formRef.current?.reset()
    setGrouping(undefined)
    setSector(undefined)
    onClose()
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        overflow="auto"
        maxHeight="85%"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader
          showCloseButton
          title="Transferir protocolo"
          onClose={handleClose}
        />

        <Divider />

        {isLoading && <LinearProgress sx={{ mt: 2 }} />}

        <VForm onSubmit={handleSubmit} ref={formRef}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <AutocompleteAgrupamentoSAC
                name="grouping"
                customOnChange={(_, e) => setGrouping(e?.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <AutocompleteSetorSAC
                grouping={grouping}
                name="sector"
                customOnChange={(_, e) => setSector(e?.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <AutoCompleteAssuntoSAC name="subject" sector={sector} />
            </Grid>

            <Grid item xs={12}>
              <VTextArea
                name="justify"
                minRows={4}
                maxRows={8}
                required
                placeholder=" Justifique o motivo da transferência de setor
  * Essa justificativa é visível para o cidadão
                      "
                ariaLabel="Descrição"
                style={{
                  width: '95%',
                  borderRadius: '5px',
                  fontVariant: 'historical-forms',
                  font: 'message-box',
                }}
              />
            </Grid>
          </Grid>

          <Box
            display="flex"
            flex="1"
            mt={2}
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <Button
              disableElevation
              disabled={isLoading}
              type="submit"
              variant="contained"
              startIcon={<Save />}
            >
              salvar
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={handleClose}
              startIcon={<Cancel />}
            >
              cancelar
            </Button>
          </Box>
        </VForm>
      </Box>
    </Modal>
  )
}

import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Scope } from '@unform/core'
import { FC } from 'react'
import { VTextField } from '../../../../shared/forms'
import { formatPhone } from '../../../../shared/utils'
import { AddCircle, RemoveCircle } from '@mui/icons-material'

interface SacPhonesProps {
  isLoading: boolean
  phones: number
  setPhones: (e: number) => void
}

export const SacPhones: FC<SacPhonesProps> = ({
  isLoading,
  phones,
  setPhones,
}) => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid
      container
      item
      spacing={2}
      display="flex"
      alignItems="center"
      direction="column"
    >
      <Box
        display="flex"
        flexDirection="column"
        width={smDown ? '99%' : '66%'}
        borderRadius="10px"
        padding={2}
        gap={1}
        alignItems="center"
      >
        <Typography variant="h4" textAlign="center">
          Telefones Fixos
        </Typography>
        <Grid
          container
          item
          display="flex"
          flexDirection="column"
          justifyContent="center"
          direction="column"
          spacing={1}
        >
          <Grid container item xs={12} justifyContent="center">
            <Scope path="sac_phones[0]">
              <Grid item xs={11} sm={phones === 1 ? 4 : 5}>
                <VTextField
                  fullWidth
                  size="small"
                  name="title"
                  label="Nome"
                  disabled={isLoading}
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                  borderBottomRight="0px"
                  borderTopRight="0px"
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xs={11} sm={7}>
                <VTextField
                  fullWidth
                  size="small"
                  name="number"
                  label="Telefone Fixo"
                  placeholder="(DDD) 1234-5678"
                  disabled={isLoading}
                  borderBottomLeft="0px"
                  borderTopLeft="0px"
                  formatData={formatPhone}
                  inputProps={{ maxLength: 14 }}
                />
              </Grid>
              {phones === 1 && (
                <Grid item xs={1} display="flex" justifyContent="center">
                  <Tooltip title="adcionar telefone">
                    <IconButton onClick={() => setPhones(phones + 1)}>
                      <AddCircle color="success" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Scope>
          </Grid>
          {Number(phones) >= 2 && (
            <Grid container item xs={12} justifyContent="center">
              <Scope path="sac_phones[1]">
                <Grid item xs={11} sm={phones === 2 ? 4 : 5}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="title"
                    label="Nome"
                    disabled={isLoading}
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    borderBottomRight="0px"
                    borderTopRight="0px"
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={11} sm={7}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="number"
                    label="Telefone Fixo"
                    placeholder="(DDD) 1234-5678"
                    disabled={isLoading}
                    borderBottomLeft="0px"
                    borderTopLeft="0px"
                    formatData={formatPhone}
                    inputProps={{ maxLength: 14 }}
                  />
                </Grid>
                {phones === 2 && (
                  <Box display="flex" flexDirection="row">
                    <Tooltip title="adcionar telefone">
                      <IconButton onClick={() => setPhones(phones + 1)}>
                        <AddCircle color="success" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="remover telefone">
                      <IconButton onClick={() => setPhones(phones - 1)}>
                        <RemoveCircle color="error" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Scope>
            </Grid>
          )}
          {Number(phones) >= 3 && (
            <Grid container item xs={12} justifyContent="center">
              <Scope path="sac_phones[2]">
                <Grid item xs={11} sm={phones === 3 ? 4 : 5}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="title"
                    label="Nome"
                    disabled={isLoading}
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    borderBottomRight="0px"
                    borderTopRight="0px"
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={11} sm={7}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="number"
                    label="Telefone Fixo"
                    placeholder="(DDD) 1234-5678"
                    disabled={isLoading}
                    borderBottomLeft="0px"
                    borderTopLeft="0px"
                    formatData={formatPhone}
                    inputProps={{ maxLength: 14 }}
                  />
                </Grid>
                {phones === 3 && (
                  <Box display="flex" flexDirection="row">
                    <Tooltip title="adcionar telefone">
                      <IconButton onClick={() => setPhones(phones + 1)}>
                        <AddCircle color="success" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="remover telefone">
                      <IconButton onClick={() => setPhones(phones - 1)}>
                        <RemoveCircle color="error" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Scope>
            </Grid>
          )}
          {Number(phones) >= 4 && (
            <Grid container item xs={12} justifyContent="center">
              <Scope path="sac_phones[3]">
                <Grid item xs={11} sm={phones === 4 ? 4 : 5}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="title"
                    label="Nome"
                    disabled={isLoading}
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    borderBottomRight="0px"
                    borderTopRight="0px"
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={11} sm={7}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="number"
                    label="Telefone Fixo"
                    placeholder="(DDD) 1234-5678"
                    disabled={isLoading}
                    borderBottomLeft="0px"
                    borderTopLeft="0px"
                    formatData={formatPhone}
                    inputProps={{ maxLength: 14 }}
                  />
                </Grid>
                {phones === 4 && (
                  <Box display="flex" flexDirection="row">
                    <Tooltip title="adcionar telefone">
                      <IconButton onClick={() => setPhones(phones + 1)}>
                        <AddCircle color="success" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="remover telefone">
                      <IconButton onClick={() => setPhones(phones - 1)}>
                        <RemoveCircle color="error" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Scope>
            </Grid>
          )}
          {phones === 5 && (
            <Grid container item xs={12} justifyContent="center">
              <Scope path="sac_phones[4]">
                <Grid item xs={11} sm={phones === 5 ? 4 : 5}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="title"
                    label="Nome"
                    disabled={isLoading}
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    borderBottomRight="0px"
                    borderTopRight="0px"
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={11} sm={7}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="number"
                    label="Telefone Fixo"
                    placeholder="(DDD) 1234-5678"
                    disabled={isLoading}
                    borderBottomLeft="0px"
                    borderTopLeft="0px"
                    formatData={formatPhone}
                    inputProps={{ maxLength: 14 }}
                  />
                </Grid>
                {phones === 5 && (
                  <Grid item xs={1} display="flex" justifyContent="center">
                    <Tooltip title="remover telefone">
                      <IconButton onClick={() => setPhones(phones - 1)}>
                        <RemoveCircle color="error" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Scope>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  )
}

import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDaListagem,
  NotificacaoAlerta,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useDebounce } from '../../../shared/hooks'
import { Link, useSearchParams } from 'react-router-dom'
import { useAuthContext } from '../../../shared/contexts'
import {
  SacGroupingList,
  getAllSacGroupings,
} from '../../../shared/services/api/sac-services'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'
import { Environment } from '../../../shared/environment'
import { Footer } from '../../../shared/components/tabela/Footer'
import usePersistedState from '../../../shared/hooks/usePersistedState'
import { Formulario } from './formulario/Formulario'
import { InfoOutlined } from '@mui/icons-material'

export const AgrupamentoSAC: FC = () => {
  const update = useRef(true)

  const { user } = useAuthContext()
  const { debounce } = useDebounce()
  const { moduleTitle, thisModule } = useModuleContext()

  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState<SacGroupingList[]>([])
  const [searchParams, setSearchParams] = useSearchParams()

  const [institution, setInstitution] = usePersistedState<string>(
    'ADM_SELECTED_INSTITUTION',
    '',
  )

  const [viewForm, setViewForm] = useState(false)

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  const customerService = useMemo(() => {
    return searchParams.get('sac') || ''
  }, [searchParams])

  useEffect(() => {
    if (thisModule?.profile !== 0 && thisModule?.id) {
      setSearchParams(
        {
          busca,
          item: '0',
          total: '10',
          sac: String(thisModule.id),
        },
        { replace: true },
      )
    }
  }, [busca, setSearchParams, thisModule])

  const changePage = (value1: number, value2: number) => {
    const result = value1 * value2 - value2
    return result.toString()
  }

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllSacGroupings(
        item,
        total,
        busca,
        customerService,
        institution && user?.modules[0].profile === 0 ? institution : '',
      ).then((result) => {
        update.current = false
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setRows(result.results)
          setTotalCount(result.count)
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    busca,
    item,
    total,
    customerService,
    institution,
    debounce,
    user?.modules,
    update.current,
  ])

  return (
    <LayoutBaseDePagina
      title="Agrupamentos"
      selectInstitution
      autoCompleteChange={(e) => setInstitution(e ? String(e) : '')}
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputDaBusca
          textoDaBusca={busca}
          aoClicarEmNovo={() => {
            if (user?.modules[0].profile === 0 && !institution) {
              NotificacaoAlerta('Selecione uma instituição')
            } else {
              setViewForm(true)
            }
          }}
          aoMudarTextoDaBusca={(value) =>
            setSearchParams(
              {
                busca: value,
                item: '0',
                total,
                sac: customerService,
              },
              { replace: true },
            )
          }
        />
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Ícone</TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center">Última atualização</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <Link
                    to={`/customer-service/${moduleTitle}/groupings/details/${row.id}`}
                  >
                    <IconButton color="primary">
                      <InfoOutlined />
                    </IconButton>
                  </Link>
                </TableCell>
                <TableCell align="center">
                  <img
                    alt="ícone"
                    loading="lazy"
                    width={50}
                    src={
                      process.env.NODE_ENV === 'development'
                        ? `${Environment.URL_BASE}${row.icon.icon}`
                        : row.icon.icon
                    }
                  />
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.created_at)}
                </TableCell>
                <TableCell align="center">
                  {row.created_at !== row.updated_at
                    ? FormatDateTime(row.updated_at)
                    : 'Não houve atualizações'}
                </TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <Footer
            colSpan={6}
            total={total}
            item={Number(item)}
            loading={isLoading}
            totalCount={totalCount}
            onChangePagination={(_, newPage) => {
              setSearchParams(
                {
                  busca,
                  total,
                  sac: customerService,
                  item: changePage(newPage, Number(total)),
                },
                { replace: true },
              )
            }}
            onChangeTotalItems={(e) => {
              setSearchParams(
                {
                  busca,
                  item: '0',
                  sac: customerService,
                  total: e.target.value,
                },
                { replace: true },
              )
            }}
          />
        </Table>
      </TableContainer>
      <Formulario
        create
        show={viewForm}
        update={update}
        institution={
          user?.modules[0].profile === 0
            ? Number(institution)
            : Number(user?.institution_id)
        }
        onClose={() => setViewForm(false)}
      />
    </LayoutBaseDePagina>
  )
}

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Icon,
  Typography,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { VForm, VTextField } from '../../shared/forms'
import { NotificacaoPromise } from '../../shared/components'
import axios from 'axios'
import { Environment } from '../../shared/environment'
import { useEffect, useState } from 'react'

import defaultLogo from '../../images/default_logo.png'

export const ResetSenha: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (formData: { email: string }) => {
    setIsLoading(true)
    const response = axios.post(
      `${Environment.URL_BASE}/V1/api/users/reset_password/`,
      formData,
      {
        headers: {
          'X-CSRFToken': document.cookie.split('=')[1],
        },
      },
    )
    NotificacaoPromise(
      response,
      'Enviando Requisição',
      'Em Instantes, Você Receberá um Email para Alteração de Senha',
      'Erro ao Processar Requisição. Tente Novamente | Caso o Error Persista, contate um Administrador',
    )
    setIsLoading(false)
  }

  useEffect(() => {
    document.title = 'Esqueci a senha | TickDoc'
  }, [])

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Helmet>
        <link
          rel="canonical"
          href={`${Environment.FRONTEND_URL}/reset_password`}
        />
      </Helmet>
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column" gap={2} width={300}>
            <img src={defaultLogo} alt="defaultLogo" />
            <Typography variant="h4">Esqueceu Sua Senha?</Typography>
            <Typography>
              Digite seu email e enviaremos um link para redefinição
            </Typography>
            <VForm onSubmit={handleSubmit}>
              <Grid
                container
                display="flex"
                justifyContent="center"
                spacing={1}
                paddingX={2}
              >
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="email@example.com"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    endIcon={<Icon>send</Icon>}
                    disabled={isLoading}
                  >
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </VForm>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

import { FC, MutableRefObject, useEffect, useState } from 'react'
import {
  Modal,
  Box,
  Paper,
  useTheme,
  useMediaQuery,
  Grid,
  Divider,
  LinearProgress,
  IconButton,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
  Button,
  FormControlLabel,
} from '@mui/material'

import {
  SacModule,
  createSac,
  updateSacById,
} from '../../../../shared/services/api/modules-services/SacServices'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoPromise,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { ModalHeader } from '../../../../shared/components/modal/Header'
import {
  VForm,
  VSelect,
  VSwitch,
  VTextField,
  useVForm,
} from '../../../../shared/forms'
import { IFormData, formValidationSchema } from './validate'
import { ValidationError } from 'yup'
import { Cancel, Delete, Photo, Save } from '@mui/icons-material'
import { formatBRLCustom } from '../../../../shared/utils'
import { SacPhones } from '../components/Phones'
import { SacCellPhones } from '../components/CellPhones'

interface FormularioProps {
  show: boolean
  onClose(): void
  create?: boolean
  data?: SacModule
  institution: number
  update: MutableRefObject<boolean>
}

export const Formulario: FC<FormularioProps> = ({
  onClose,
  show,
  institution,
  create,
  data,
  update,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const [image, setImage] = useState<File>()
  const [imageURL, setImageURL] = useState('')
  const [hasSms, setHasSms] = useState(false)
  const [phones, setPhones] = useState(1)
  const [cellPhones, setCellPhones] = useState(1)

  const formLogo = new FormData()

  const handleSubmit = (formData: IFormData) => {
    if (!image && create) {
      NotificacaoError('Insira uma logo')
      return
    } else if (image) {
      formLogo.append('sac_logo', image, image.name)
    }
    setIsLoading(true)
    if (create) {
      formData.institution = institution
      formData.sac_price = Number(
        String(formData.sac_price).replace(/[^0-9]/gi, ''),
      )
    } else {
      if (data) {
        formData.id = data.id
        formData.institution = data.institution
      }
    }

    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData) => {
        if (create) {
          createSac(validatedData).then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              NotificacaoSucesso('Módulo criado com sucesso')
              NotificacaoPromise(
                updateSacById(result.id, formLogo),
                'Enviando logo',
                'Logo enviada com sucesso!',
                'Erro ao enviar logo',
              )
              update.current = true
              handleClose()
            }
          })
        } else {
          if (data) {
            updateSacById(data.id, validatedData).then((result) => {
              setIsLoading(false)
              if (result instanceof Error) {
                NotificacaoError(result.message)
              } else {
                NotificacaoSucesso('Módulo atualizado com sucesso')
                if (image) {
                  NotificacaoPromise(
                    updateSacById(result.id, formLogo),
                    'Atualizando logo',
                    'Logo atualizada com sucesso!',
                    'Erro ao atualizar logo',
                  )
                }
                update.current = true
                handleClose()
              }
            })
          }
        }
      })
      .catch((errors: ValidationError) => {
        setIsLoading(false)
        const validationErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validationErrors[error.path] = error.message
        })
        formRef.current?.setErrors(validationErrors)
      })
  }

  const handleClose = () => {
    URL.revokeObjectURL(imageURL)
    formRef.current?.reset()
    setImage(undefined)
    setIsLoading(false)
    setImageURL('')
    setHasSms(false)
    onClose()
  }

  useEffect(() => {
    if (!create && data) {
      setHasSms(data.sac_activate_sms)
      setPhones(data.sac_phones.length > 0 ? data.sac_phones.length : 1)
      setCellPhones(
        data.sac_cellphones.length > 0 ? data.sac_cellphones.length : 1,
      )
    }
  }, [create, data])

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <input
          hidden
          type="file"
          id="logo-input"
          accept="image/*"
          onChange={(e) => {
            if (e.target.files?.length) {
              if (!e.target.files[0].type.includes('image')) {
                NotificacaoError(
                  'Tipo de arquivo não suportado. Insira apenas arquivos de imagem',
                )
                return
              } else if (e.target.files[0].size > 6164480) {
                NotificacaoError(
                  'Tamanho máximo excedido. Máximo permitid: 5MB',
                )
                return
              }
              setImage(e.target.files[0])
              setImageURL(URL.createObjectURL(e.target.files[0]))
            } else {
              URL.revokeObjectURL(imageURL)
              setImage(undefined)
              setImageURL('')
            }
          }}
        />
        <ModalHeader
          showCloseButton
          onClose={handleClose}
          title={create ? 'Novo S.A.C' : 'Editar de S.A.C'}
        />
        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{
              height: '5px',
              borderRadius: '50px',
              mt: 1,
            }}
          />
        )}
        <Box margin={1}>
          <VForm
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={
              !create
                ? data
                : {
                    sac_limit_days: 10,
                    sac_extend_days: 10,
                    sac_plan: 2,
                    sac_separator: 'SAC',
                  }
            }
          >
            <Grid container spacing={2} direction="column">
              <Grid
                container
                item
                spacing={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {(image || !create) && (
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={image ? imageURL : create ? '' : data?.sac_logo}
                      width="300px"
                      alt="sac_logo"
                    />
                  </Grid>
                )}
                <Grid item>
                  <Tooltip title="Inserir logo">
                    <Button
                      disableElevation
                      variant="contained"
                      startIcon={<Photo />}
                      onClick={() =>
                        document.getElementById('logo-input')?.click()
                      }
                    >
                      logo
                    </Button>
                  </Tooltip>
                </Grid>
                {image && (
                  <Grid item>
                    <Tooltip title="Remover logo">
                      <IconButton
                        onClick={() => {
                          URL.revokeObjectURL(imageURL)
                          setImage(undefined)
                          setImageURL('')
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <VTextField
                    required
                    fullWidth
                    size="small"
                    label="Título"
                    name="sac_title"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    required
                    fullWidth
                    size="small"
                    label="Slug"
                    lowercase
                    name="sac_slug"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    fullWidth
                    size="small"
                    label="E-mail"
                    name="sac_email"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="plan-label">Plano</InputLabel>
                    <VSelect
                      fullWidth
                      required
                      label="Plano"
                      labelId="plan-label"
                      size="small"
                      name="sac_plan"
                      sx={{
                        borderRadius: '50px',
                      }}
                    >
                      <MenuItem value={1}>Gratuito</MenuItem>
                      <MenuItem value={2}>Pago</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <VTextField
                    required
                    fullWidth
                    size="small"
                    label="Prazo"
                    type="number"
                    borderTopLeft="50px"
                    name="sac_limit_days"
                    borderBottomLeft="50px"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <VTextField
                    required
                    fullWidth
                    size="small"
                    type="number"
                    label="Prorrogação"
                    borderTopLeft="50px"
                    name="sac_extend_days"
                    borderBottomLeft="50px"
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    size="small"
                    label="Endereço"
                    name="sac_address"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    fullWidth
                    size="small"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                    name="sac_working_hour"
                    label="Horário de atendimento"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    fullWidth
                    size="small"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                    name="sac_responsible"
                    label="Responsável"
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} md={4}>
                  <VTextField
                    required
                    fullWidth
                    size="small"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                    name="sac_prefix"
                    upercase
                    inputProps={{
                      maxLength: 10,
                    }}
                    label="Prefixo"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <VTextField
                    required
                    fullWidth
                    size="small"
                    upercase
                    inputProps={{
                      maxLength: 10,
                    }}
                    label="Separador"
                    name="sac_separator"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <VTextField
                    required
                    fullWidth
                    size="small"
                    label="Preço"
                    name="sac_price"
                    formatData={formatBRLCustom}
                    borderTopLeft="50px"
                    inputProps={{
                      maxLength: 13,
                    }}
                    borderBottomLeft="50px"
                  />
                </Grid>
              </Grid>

              <SacPhones
                phones={phones}
                isLoading={isLoading}
                setPhones={setPhones}
              />

              <SacCellPhones
                isLoading={isLoading}
                cellPhones={cellPhones}
                setCellPhones={setCellPhones}
              />

              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    required
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Expira em"
                    type="date"
                    borderTopLeft="50px"
                    name="sac_expires_at"
                    borderBottomLeft="50px"
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      label="Ativar SMS"
                      control={
                        <VSwitch
                          size="small"
                          name="sac_activate_sms"
                          onChange={(_, e) => setHasSms(e)}
                        />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <VTextField
                      disabled={!hasSms}
                      name="sac_sms_quantity"
                      fullWidth
                      label="Quantidade de SMS"
                      required={hasSms}
                      borderTopLeft="50px"
                      borderBottomLeft="50px"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <FormControlLabel
                    label="Status"
                    control={<VSwitch name="status" size="small" />}
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2} direction="column"></Grid>
            </Grid>
            <Box display="flex" justifyContent="center" gap={1} mt={1}>
              <Button
                variant="contained"
                type="submit"
                startIcon={<Save />}
                disableElevation
              >
                salvar
              </Button>
              <Button
                variant="outlined"
                startIcon={<Cancel />}
                onClick={handleClose}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

import { FC, useEffect, useState } from 'react'
import {
  Box,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableFooter,
  Divider,
} from '@mui/material'
import { BarChart } from '../../../reports/BarChart'

interface IResultSurvey {
  title: string
  total: number
  replied: number
  pontuation: number
  not_replied: number
}

interface SurveyTableProps {
  result: { [key: string]: IResultSurvey }
  type: number
}

export const SurveyTable: FC<SurveyTableProps> = ({ type, result }) => {
  const [surveys, setSurveys] = useState<IResultSurvey[]>([])
  const [columns, setColumns] = useState<string[]>([])

  useEffect(() => {
    if (type === 22) {
      setColumns(['Título', 'Pontuação média', 'Responderam', 'Não respondeu'])
      setSurveys([])
      Object.keys(result).forEach((title) => {
        const value: IResultSurvey = result[title]
        setSurveys((oldValue) => [...oldValue, value])
      })
    }
    console.log(columns)
  }, [columns, result, type])

  return (
    <TableContainer
      id="table-container"
      component={Paper}
      variant="outlined"
      sx={{
        m: 1,
        color: 'dodgerblue',
        border: '1px solid',
        borderRadius: '15px',
        overflow: 'auto',
      }}
    >
      <Box
        height="400px"
        display="flex"
        width="100%"
        maxWidth="98%"
        justifyContent="center"
        alignItems="center"
        id="barchart-graphic"
      >
        <BarChart
          data={surveys}
          legend={`Pesquisa de satisfação`}
          indexBy="title"
          keys={[
            'Nota 1',
            'Nota 2',
            'Nota 3',
            'Nota 4',
            'Nota 5',
            'Nota 6',
            'Nota 7',
            'Nota 8',
            'Nota 9',
            'Nota 10',
          ]}
        />
      </Box>
      <Divider />
      <Table id="table" sx={{ mt: 2 }}>
        <TableHead id="table-head">
          <TableRow id="table-row">
            {columns.map((column, index) => (
              <TableCell key={index} align="center" id="table-cell-center">
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody id="table-body">
          {surveys.map((survey, index) => (
            <TableRow key={index}>
              <TableCell align="center" width="500px">
                {survey.title}
              </TableCell>
              <TableCell align="center">
                Nota {survey.pontuation / survey.replied}
              </TableCell>
              <TableCell align="center">{`${survey.replied} usuários`}</TableCell>
              <TableCell align="center">{`${survey.not_replied} usuários`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter id="table-footer">
          <TableRow>
            <TableCell colSpan={4} align="center">
              * OBSERVAÇÃO: Manifestações anônimas não possuem pesquisa de
              satisfação *
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

export interface IUserList {
  id: number
  username: string
  email: string
  last_login: string
  created_at: string
  is_active: boolean
}

export interface IUserDetail {
  id?: number
  username: string
  email: string
  document_number: string
  document_type: string
  is_staff: boolean
  institution_id: number
  ombudsman_profile: string
  data_manager: boolean
  is_active: boolean
  is_confirmed: boolean
  confirmed_at: string
  confirmation_token: string
  confirmation_token_sent_at: string
  updated_at: string
  created_at: string
  last_login: string
  sign_in_count: string
  profile_picture: string
  birth_date: string
  modules: []
  esic_manager: boolean
  hr_manager: boolean
  esic_auxiliary: boolean
}

export type IUserTotalCount = {
  data: IUserList[]
  count: number
  next: string
  previous: string
  results: IUserList[]
}

const getAllAdmins = async (
  item = '0',
  total = '10',
  filter = '',
): Promise<IUserTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/adminusers/?total=${total}&item=${item}&search=${filter}`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return {
        data,
        results: data.results,
        count: data.count,
        next: data.next,
        previous: data.previous,
      }
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getAdminById = async (id: number): Promise<IUserDetail | Error> => {
  try {
    const relativeUrl = `/V1/adminusers/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      data.password = ''
      return data
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao listar registro',
    )
  }
}

const createAdmin = async (formData: {}): Promise<string | Error> => {
  try {
    const relativeUrl = `/V1/adminusers/`

    const { data } = await Api.post<IUserDetail>(relativeUrl, formData)

    if (data) return data.id ? data.id.toString() : data.username

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const updateAdminById = async (
  id: number,
  formData: {},
): Promise<IUserDetail | Error> => {
  try {
    const relativeUrl = `/V1/adminusers/${id}/`

    const { data } = await Api.patch<IUserDetail>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

export { getAllAdmins, getAdminById, createAdmin, updateAdminById }

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useDebounce } from '../../../../shared/hooks'
import {
  IStatesInstitutions,
  getStatesWithInstitution,
} from '../../../../shared/services/api'
import { NotificacaoError } from '../../../../shared/components'

interface ISelecionarEstadoProps {
  selectedState: number | undefined
  setSelectedState(id: number): void
}

export const SelecionarEstado: FC<ISelecionarEstadoProps> = ({
  selectedState,
  setSelectedState,
}) => {
  const { debounce } = useDebounce(1000, false)

  const theme = useTheme()
  const ssDown = useMediaQuery(theme.breakpoints.down(480))

  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [states, setStates] = useState<IStatesInstitutions[]>([])

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getStatesWithInstitution(search).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError('Erro ao listar estados')
        } else {
          setStates(result)
        }
      })
    })
  }, [debounce, search])

  return (
    <Box>
      <TextField
        margin="dense"
        fullWidth
        label="Buscar Estado..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Typography>
        Estado Selecionado:{' '}
        {states?.find((element) => element.id === selectedState)?.name ||
          'Selecione Um Estado'}
      </Typography>
      {isLoading && (
        <Grid item>
          <LinearProgress
            variant="indeterminate"
            sx={{ height: '5px', borderRadius: '50px' }}
          />
        </Grid>
      )}
      <Grid container item spacing={3} marginTop={1}>
        {states?.map((state) => (
          <Grid
            key={state.id}
            item
            xs={ssDown ? 6 : 4}
            sm={4}
            md={4}
            lg={3}
            xl={2}
          >
            <Card
              sx={{
                minHeight: '200px',
                width: '100%',
                ':hover': {
                  border: '1px solid dodgerblue',
                  position: 'relative',
                  top: '-20px',
                  animation: 'go-back 1s infinite alternate',
                },
              }}
              component={Button}
              elevation={10}
              onClick={() => setSelectedState(state.id)}
            >
              <CardContent>
                <Box
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img width="100%" src={`${state.flag}`} alt="flag" />
                  <Typography variant="h6">{`${state.name} - ${state.initials}`}</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

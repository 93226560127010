import {
  Box,
  Modal,
  useTheme,
  useMediaQuery,
  Paper,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Grid,
  Button,
  LinearProgress,
} from '@mui/material'
import { FC, useState } from 'react'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { Cancel, Check, Help } from '@mui/icons-material'
import { VForm, VTextArea, useVForm } from '../../../../shared/forms'
import { AutocompleteTypologies } from './AutocompleteTypologies'
import { Schema, ValidationError } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'
import { changeTypology } from '../../../../shared/services/api'

interface ChangeTypologyModalProps {
  show: boolean
  onClose(): void
  protocolId?: number
  institutionId?: number
  update: React.MutableRefObject<boolean>
}

interface IFormData {
  institution_id: number
  protocol_id: number
  typology: number
  justify: string
}

const formValidationSchema: Schema<IFormData> = yup.object().shape({
  institution_id: yup.number().required().positive(),
  protocol_id: yup.number().required().positive(),
  typology: yup.number().required().positive(),
  justify: yup.string().required().min(6),
})

export const ChangeTypologyModal: FC<ChangeTypologyModalProps> = ({
  show,
  update,
  onClose,
  protocolId,
  institutionId,
}) => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    formRef.current?.reset()
    onClose()
  }

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)
    formData.institution_id = Number(institutionId)
    formData.protocol_id = Number(protocolId)
    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData) => {
        changeTypology(Number(protocolId), validatedData).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            NotificacaoSucesso('Tipologia alterada com sucesso')
            update.current = true
            handleClose()
          }
        })
      })
      .catch((errors: ValidationError) => {
        setIsLoading(false)
        const validationErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validationErrors[error.path] = error.message
        })
        formRef.current?.setErrors(validationErrors)
      })
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Box margin={1} width="100%">
          {isLoading && <LinearProgress />}
          <Box
            display="flex"
            flex="1"
            position="relative"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h6" component="h2" textAlign="center">
              Alterar Tipologia
            </Typography>
            <Tooltip title="entenda a mudança de tipologia">
              <IconButton
                sx={{
                  position: 'absolute',
                  right: 2,
                  mb: 2,
                }}
              >
                <Help />
              </IconButton>
            </Tooltip>
          </Box>
          <Divider />
          <VForm onSubmit={handleSubmit} ref={formRef}>
            <Grid container spacing={2} direction="column" my={2}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <VTextArea
                    required
                    maxRows={8}
                    minRows={4}
                    name="justify"
                    ariaLabel="Justificativa"
                    placeholder="  - Justifique a alteração de tipologia"
                    style={{
                      width: '100%',
                      borderRadius: '5px',
                      fontVariant: 'historical-forms',
                      font: 'message-box',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <AutocompleteTypologies name="typology" />
                </Grid>
              </Grid>
            </Grid>
            <Box
              display="flex"
              flex="1"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Button
                type="submit"
                variant="contained"
                disableElevation
                startIcon={<Check />}
              >
                confirmar
              </Button>
              <Button
                variant="outlined"
                startIcon={<Cancel />}
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC } from 'react'
import { Environment } from '../../../../../shared/environment'
import { Search } from '@mui/icons-material'
import { SacGroupingExternal } from '../../../../../shared/services/api/sac-services'

interface SelecionarAgrupamentoProps {
  search: string
  isLoading: boolean
  handleNext: () => void
  setSearch: (e: string) => void
  groupings: SacGroupingExternal[]
  selectedGroup: number | undefined
  setSelectedGroup: (e: number | undefined) => void
}

export const SelecionarAgrupamento: FC<SelecionarAgrupamentoProps> = ({
  search,
  groupings,
  isLoading,
  setSearch,
  handleNext,
  selectedGroup,
  setSelectedGroup,
}) => {
  const theme = useTheme()
  const ssDown = useMediaQuery(theme.breakpoints.down(480))
  const lg2Down = useMediaQuery(theme.breakpoints.down(1600))

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Search sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField
          fullWidth
          margin="dense"
          label="Buscar Agrupamento..."
          value={search}
          variant="standard"
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Typography paddingTop={1}>
        {selectedGroup !== undefined
          ? `Agrupamento Selecionado: ${groupings?.find(
              (group) => group.id === selectedGroup,
            )?.name}`
          : 'Selecione um agrupamento'}
      </Typography>
      {isLoading && (
        <Grid item>
          <LinearProgress
            variant="indeterminate"
            sx={{ height: '5px', borderRadius: '50px' }}
          />
        </Grid>
      )}
      <Grid container item spacing={3} marginTop={1}>
        {groupings?.map((group) => (
          <Grid
            key={group.id}
            item
            xs={ssDown ? 6 : 3}
            md={2}
            lg={lg2Down ? 2 : 1}
          >
            <Card
              sx={
                selectedGroup === group.id
                  ? {
                      maxHeight: '200px',
                      width: '100%',
                      border: '1px solid dodgerblue',
                      position: 'relative',
                      top: '-20px',
                      animation: 'go-back 1s infinite alternate',
                    }
                  : {
                      maxHeight: '200px',
                      width: '100%',
                      ':hover': {
                        border: '1px solid dodgerblue',
                        position: 'relative',
                        top: '-20px',
                        animation: 'go-back 1s infinite alternate',
                      },
                    }
              }
              component={Button}
              elevation={10}
              onClick={() => {
                if (selectedGroup === group.id) {
                  handleNext()
                } else {
                  setSelectedGroup(group.id)
                }
              }}
            >
              <CardContent>
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                  gap={1}
                >
                  <img
                    width="70%"
                    src={
                      process.env.NODE_ENV === 'production'
                        ? group.icon.icon
                        : `${Environment.URL_BASE}${group.icon.icon}`
                    }
                    alt="icon"
                  />
                  <Typography
                    variant="caption"
                    fontSize={15}
                    flexWrap="nowrap"
                    textOverflow="ellipsis"
                  >
                    {group.name}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

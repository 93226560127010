import { Box, Typography } from '@mui/material'
import { FC } from 'react'

import defaultLogo from '../../../../images/default_logo.webp'
import { CloudOff } from '@mui/icons-material'

interface SplashScreenProps {
  loading: boolean
  errorMessage: string
}

export const SplashScreen: FC<SplashScreenProps> = ({
  errorMessage,
  loading,
}) => {
  return (
    <Box
      display="flex"
      height="100vh"
      width="100vw"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap={1}
    >
      <img
        loading="eager"
        src={defaultLogo}
        alt="Logo TickDoc"
        style={{ maxWidth: '100vw' }}
        width="300px"
        height="auto"
      />
      {loading ? (
        <Typography color="dodgerblue">Carregando...</Typography>
      ) : (
        <Typography
          textAlign="center"
          display="flex"
          gap={1}
          variant="h6"
          fontSize={20}
          alignItems="center"
        >
          <CloudOff />
          {errorMessage}
        </Typography>
      )}
    </Box>
  )
}

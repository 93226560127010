/* eslint-disable @typescript-eslint/ban-types */
import { IUserDetail } from '../admin-users-services/AdminusersServices'
import { IGroupings } from '../agrupamentos-service/AgrupamentosService'
import { Api } from '../axios-config'

export interface ISectors {
  id: number
  icon: string
  name: string
  status: boolean
  created_at: string
  updated_at: string
  grouping_id: string
  module_kind: number
  institution_id: number
}

export interface IRSectors {
  id: number
  icon: string
  name: string
  status: boolean
  created_at: string
  updated_at: string
  grouping_id: IGroupings
  module_kind: number
  institution_id: number
}

export type ISectorsTotalCount = {
  data: ISectors[]
  count: number
  next: string
  previous: string
  results: ISectors[]
}

const getAllSectors = async (
  item = '0',
  total = '10',
  filter = '',
  sectorId = '',
  institutionId = '',
  moduleKind = '',
  groupingId = '',
): Promise<ISectorsTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/sectors/?total=${total}&item=${item}&search=${filter}&id=${sectorId}&institution_id=${institutionId}&module_kind=${moduleKind}&grouping_id=${groupingId}`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return {
        data,
        results: data.results,
        count: data.count,
        next: data.next,
        previous: data.previous,
      }
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getSectorById = async (id: number): Promise<IRSectors | Error> => {
  try {
    const relativeUrl = `/V1/sectors/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return data
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao listar registro',
    )
  }
}

const createSector = async (formData: {}): Promise<string | Error> => {
  try {
    const relativeUrl = '/V1/sectors/'

    const { data } = await Api.post<ISectors>(relativeUrl, formData)

    if (data) return data.id ? data.id.toString() : data.name

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const updateSectorById = async (
  id: number,
  formData: {},
): Promise<ISectors | Error> => {
  try {
    const relativeUrl = `/V1/sectors/${id}/`

    const { data } = await Api.patch<ISectors>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

const getUsersInSector = async (
  sectorId: number,
): Promise<IUserDetail[] | Error> => {
  try {
    const relativeUrl = `/V1/sectors/${sectorId}/users/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar usuários')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export {
  createSector,
  getAllSectors,
  getSectorById,
  getUsersInSector,
  updateSectorById,
}

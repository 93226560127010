/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  LinearProgress,
  Box,
  Pagination,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material'

import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDaListagem,
  InfoButton,
  NotificacaoAlerta,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import {
  SacModule,
  getAllSacs,
} from '../../../shared/services/api/modules-services/SacServices'
import { useSearchParams } from 'react-router-dom'
import usePersistedState from '../../../shared/hooks/usePersistedState'
import { FormatDate, FormatStatus, changePage } from '../../../shared/utils'
import { useDebounce } from '../../../shared/hooks'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import { Environment } from '../../../shared/environment'

import { Formulario } from './formulario/Formulario'

export const ModulosSac: FC = () => {
  const [open, setOpen] = useState(false)
  const { moduleTitle } = useModuleContext()
  const { debounce } = useDebounce(1500)
  const [isLoading, setIsLoading] = useState(true)
  const [viewForm, setViewForm] = useState(false)

  const update = useRef(true)
  const [rows, setRows] = useState<SacModule[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [institution, setInstitution] = usePersistedState<string>(
    'ADM_SELECTED_INSTITUTION',
    '',
  )
  const [totalCount, setTotalCount] = useState(0)

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  const pagina = Number(item) / Number(total) + 1

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllSacs(busca, total, item, institution || '').then((result) => {
        update.current = false
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setRows(result.results)
          setTotalCount(result.count)
        }
      })
    })
  }, [busca, item, total, institution, update.current])

  return (
    <LayoutBaseDePagina
      title="Módulos S.A.C"
      selectInstitution
      autoCompleteChange={(newValue) => {
        setSearchParams({
          busca,
          item: '0',
          total,
          instituicao: newValue ? String(newValue) : '',
        })
        setInstitution(newValue ? String(newValue) : '')
      }}
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputDaBusca
          textoDaBusca={busca}
          aoClicarEmNovo={() => {
            if (!institution) {
              NotificacaoAlerta('Selecione uma instituição')
            } else {
              setViewForm(true)
            }
          }}
          aoMudarTextoDaBusca={(newValue) =>
            setSearchParams({
              busca: newValue,
              item: '0',
              total,
              instituicao: institution || '',
            })
          }
        />
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Título</TableCell>
              <TableCell align="center">Slug</TableCell>
              <TableCell align="center">Expira em</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <InfoButton
                    path={`/customer-service/${moduleTitle}/modules/details/${row.id}`}
                  />
                </TableCell>
                <TableCell align="center">{row.sac_title}</TableCell>
                <TableCell align="center">{row.sac_slug}</TableCell>
                <TableCell align="center">
                  {FormatDate(row.sac_expires_at, 'Não definido')}
                </TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {!isLoading && totalCount === 0 && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={10}>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{ height: '5px', borderRadius: '50px' }}
                  />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 10 || totalCount > Number(total)) && (
              <TableRow>
                <TableCell colSpan={10}>
                  <Box display="flex" justifyContent="center">
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / Number(total))}
                      onChange={(_, newPage) => {
                        setSearchParams(
                          {
                            busca,
                            total,
                            instituicao: institution,
                            item: changePage(newPage, Number(total)),
                          },
                          {
                            replace: true,
                          },
                        )
                      }}
                    />
                    <Tooltip
                      title="Itens por Página"
                      open={open}
                      onMouseEnter={() => setOpen(true)}
                      onMouseDown={() => setOpen(false)}
                      onMouseLeave={() => setOpen(false)}
                    >
                      <Select
                        variant="outlined"
                        sx={{ borderRadius: '15px', height: '30px' }}
                        value={total}
                        onChange={(e) => {
                          setSearchParams(
                            {
                              busca,
                              item: '0',
                              total: e.target.value,
                              instituicao: institution,
                            },
                            {
                              replace: true,
                            },
                          )
                        }}
                      >
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="40">40</MenuItem>
                        <MenuItem value="50">50</MenuItem>
                      </Select>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      {institution && (
        <Formulario
          create
          institution={Number(institution)}
          show={viewForm}
          update={update}
          onClose={() => setViewForm(false)}
        />
      )}
    </LayoutBaseDePagina>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import {
  ListItemText,
  ListItemIcon,
  IconButton,
  Divider,
  Box,
  Toolbar,
  List,
  CssBaseline,
  ListItem,
  ListItemButton,
  Typography,
  styled,
  useTheme,
  Theme,
  CSSObject,
  Icon,
  Tooltip,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { IClientDetail } from '../../../shared/services/api'

import { LoginModal } from '../login/Modal'
import { CadastreSeModal } from '../login/CadastreSe'
import { AnonimoModal } from '../protocolo/formularios/AnonimoModal'
import { LegislacaoModal } from '../login/LegislacaoModal'
import { InformacaoModal } from '../login/InformacaoModal'
import { DadosEstatisticos } from '../login/DadosEstatisticos'
import {
  useAppThemeContext,
  useExternalAuthContext,
} from '../../../shared/contexts'

import defaultLogo from '../../../images/default_logo.png'
import { Helmet } from 'react-helmet'
import { Environment } from '../../../shared/environment'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.background.paper,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

interface ILoginLayoutProps {
  children?: React.ReactNode
  institutionData: IClientDetail
}

interface IMenuItem {
  label: string
  icon: string
  onClick?: (e?: any) => void
}

export const LoginLayout: React.FC<ILoginLayoutProps> = ({
  children,
  institutionData,
}) => {
  const theme = useTheme()
  const { toggleTheme, themeName } = useAppThemeContext()
  const { menuOpen, toggleMenuOpen } = useExternalAuthContext()

  const [loginModal, setLoginModal] = useState(false)
  const [infoModal, setInfoModal] = useState(false)
  const [legislationModal, setLegislatioModal] = useState(false)
  const [graphicModal, setGraphicModal] = useState(false)
  const [anonimousModal, setAnonimousModal] = useState(false)
  const [registerModal, setRegisterModal] = useState(false)
  const [color, setColor] = useState('')

  const items = [
    {
      label: 'Informações Gerais',
      icon: 'info',
      onClick: () => setInfoModal(true),
    },
    {
      label: 'Login',
      icon: 'login',
      onClick: () => setLoginModal(true),
    },
    {
      label: 'Cadastre-se',
      icon: 'person_add_alt_1',
      onClick: () => setRegisterModal(true),
    },
    {
      label: 'Legislação',
      icon: 'gavel',
      onClick: () => setLegislatioModal(true),
    },
    {
      label: 'Dados Estatísticos',
      icon: 'pie_chart',
      onClick: () => setGraphicModal(true),
    },
    {
      label: 'Manifestação Anônima',
      icon: 'no_accounts',
      onClick: () => setAnonimousModal(true),
    },
    {
      label: 'Alterar Tema',
      icon: 'dark_mode',
      onClick: toggleTheme,
    },
  ]

  const [menuItems, setMenuItems] = useState<IMenuItem[]>([])

  useEffect(() => {
    if (institutionData.anonymous) {
      setMenuItems(items)
    } else {
      setMenuItems(
        items.filter((item) => item.label !== 'Manifestação Anônima'),
      )
    }

    if (institutionData.client_type === 2) {
      setMenuItems((oldValue) =>
        oldValue.filter((item) => item.label !== 'Legislação'),
      )
    }

    if (!institutionData.general_informations) {
      setMenuItems((oldValue) =>
        oldValue.filter((item) => item.label !== 'Informações Gerais'),
      )
    }

    if (!institutionData.reports) {
      setMenuItems((oldValue) =>
        oldValue.filter((item) => item.label !== 'Dados Estatísticos'),
      )
    }
  }, [institutionData])

  const handleDrawerOpen = () => {
    toggleMenuOpen()
  }

  const handleDrawerClose = () => {
    toggleMenuOpen()
  }

  useEffect(() => {
    if (themeName === 'dark') setColor('white')
    else setColor('black')
  }, [themeName])

  useEffect(() => {
    document.title = `${institutionData.slug} | ${institutionData.ombudsman_title}`
  }, [])

  return (
    <Box sx={{ display: 'flex' }} overflow="hidden">
      <Helmet>
        <link
          rel="canonical"
          href={`${Environment.FRONTEND_URL}/ouvidoria/${institutionData.slug}`}
        />
        <meta
          property="og:title"
          content={`${institutionData.slug} | ${institutionData.ombudsman_title}`}
        />
        <meta property="og:image" content={institutionData.logo} />
        <meta property="og:image:alt" content={institutionData.slug} />
        <meta
          property="og:type"
          content={`image/${
            institutionData.logo.split('.')[
              institutionData.logo.split('.').length - 1
            ]
          }`}
        />
      </Helmet>
      <CssBaseline />
      <AppBar position="fixed" open={menuOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(menuOpen && { display: 'none' }),
            }}
          >
            <MenuIcon sx={{ color }} />
          </IconButton>
          <Box display="flex" justifyContent="center" width="100%">
            <Typography variant="h6" noWrap textAlign="center" sx={{ color }}>
              {institutionData.ombudsman_title}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={menuOpen}>
        <DrawerHeader>
          <img src={defaultLogo} width="80%" alt="logo" />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <>
              <ListItem
                key={item.label}
                disablePadding
                sx={{ display: 'block' }}
              >
                <ListItemButton
                  onClick={item?.onClick}
                  sx={{
                    minHeight: 48,
                    justifyContent: menuOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: menuOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip title={item.label} placement="right">
                      <Icon>{item.icon}</Icon>
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    sx={{ opacity: menuOpen ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <Divider variant="middle" />
            </>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }} overflow="hidden">
        <DrawerHeader />
        <Box display="flex" flex="1 1">
          {children}
        </Box>
      </Box>
      <LoginModal
        show={loginModal}
        onClose={() => setLoginModal(false)}
        institutionData={institutionData}
      />
      <CadastreSeModal
        show={registerModal}
        onClose={() => setRegisterModal(false)}
        institutionData={institutionData}
      />
      <AnonimoModal
        show={anonimousModal}
        onClose={() => setAnonimousModal(false)}
        institutionData={institutionData}
      />
      <LegislacaoModal
        show={legislationModal}
        onClose={() => setLegislatioModal(false)}
        institutionData={institutionData}
      />
      <InformacaoModal
        show={infoModal}
        onClose={() => setInfoModal(false)}
        institutionData={institutionData}
      />
      <DadosEstatisticos
        show={graphicModal}
        onClose={() => setGraphicModal(false)}
        institutionData={institutionData}
      />
    </Box>
  )
}

import { useState, useEffect } from 'react'
import { useField } from '@unform/core'
import {
  FormControl,
  Theme,
  useTheme,
  InputLabel,
  Box,
  OutlinedInput,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Chip,
} from '@mui/material'

interface IOptions {
  label: string
  value: string | number
}

type TVMultiSelectProps = SelectProps & {
  name: string
  options: IOptions[]
  labelField?: string
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

export const VMultipleSelect: React.FC<TVMultiSelectProps> = ({
  name,
  options,
  labelField,
  ...rest
}) => {
  const theme = useTheme()

  const { fieldName, registerField, defaultValue } = useField(name)

  const [optionValue, setOptionValue] = useState<string[]>(defaultValue || [])

  const handleChange = (event: SelectChangeEvent<typeof optionValue>) => {
    const {
      target: { value },
    } = event
    setOptionValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => optionValue,
      setValue: (e) => {
        const {
          target: { value },
        } = e
        setOptionValue(typeof value === 'string' ? value.split(',') : value)
      },
    })
  }, [registerField, fieldName, optionValue])

  return (
    <FormControl fullWidth>
      <InputLabel shrink id="label-field">
        {labelField}
      </InputLabel>
      <Select
        size={rest.size}
        sx={rest.sx}
        label={labelField}
        labelId="label-field"
        multiple
        value={optionValue}
        onChange={(e, _) => {
          handleChange(e)
          rest.onChange?.(e, _)
        }}
        input={<OutlinedInput id="select-multiple-chip" label="Módulos" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value, index) =>
              options.map((option) =>
                option.value === value ? (
                  <Chip key={index} label={option.label} />
                ) : (
                  <></>
                ),
              ),
            )}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={getStyles(option.label, optionValue, theme)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

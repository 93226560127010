/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, FC } from 'react'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  LinearProgress,
  Modal,
  Paper,
} from '@mui/material'

import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { VForm, useVForm, VTextField, VSwitch } from '../../../../shared/forms'
import {
  ITypologies,
  createTypology,
  updateTypologyById,
} from '../../../../shared/services/api'

import { useAuthContext } from '../../../../shared/contexts'
import { ModalHeader } from '../../../../shared/components/modal/Header'

interface IFormTipologiaProps {
  onClose: () => void
  data?: ITypologies
  create: boolean
  show?: boolean
  update: React.MutableRefObject<boolean>
}

export const FormTipologia: FC<IFormTipologiaProps> = ({
  create,
  onClose,
  update,
  data,
  show = false,
}) => {
  const { user } = useAuthContext()

  const [formTitle, setFormTitle] = useState('')
  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (formData: any) => {
    setIsLoading(true)

    if (create) {
      if (user?.modules[0].profile === 0) {
        if (!localStorage.getItem('ADM_SELECTED_INSTITUTION')) {
          NotificacaoError(
            'Selecione Uma Instituição Antes de Criar uma Nova instância',
          )
          setIsLoading(false)
          return onClose()
        }
        formData.institution_id = Number(
          localStorage.getItem('ADM_SELECTED_INSTITUTION'),
        )
      } else {
        formData.institution_id = Number(user?.institution_id)
      }
    } else {
      formData.institution_id = Number(data?.institution_id)
    }

    create
      ? createTypology(formData).then((result) => {
          console.log(formData)
          setIsLoading(false)

          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            update.current = true
            onClose()
            NotificacaoSucesso('Tipologia Criada com Sucesso')
          }
        })
      : updateTypologyById(Number(data?.id), formData).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            update.current = true
            onClose()
            NotificacaoSucesso('Usuário Atualizado com Sucesso')
          }
        })
  }

  useEffect(() => {
    if (!create && data !== undefined)
      setFormTitle(`Editar Tipologia ${data.name}`)
    else setFormTitle('Nova Tipologia')
  }, [create, data, show])

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        display="flex"
        overflow="auto"
        maxHeight="85%"
        minWidth="280px"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        flexDirection="column"
        justifyContent="center"
      >
        <ModalHeader onClose={onClose} showCloseButton title={formTitle} />

        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{
              height: '5px',
              borderRadius: '50px',
              mt: 1,
            }}
          />
        )}
        <Box margin={1}>
          <VForm
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={create ? undefined : data}
          >
            <Grid container spacing={2}>
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="name"
                    label="Nome"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <FormControlLabel
                    control={<VSwitch name="status" />}
                    label="Status"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box
              display="flex"
              flex={1}
              justifyContent="center"
              marginTop={2}
              gap={1}
            >
              <Button
                variant="contained"
                disableElevation
                sx={{ borderRadius: '50px' }}
                type="submit"
              >
                <Icon>save</Icon>
                Salvar
              </Button>
              <Button
                sx={{ borderRadius: '50px' }}
                onClick={() => {
                  formRef.current?.reset()
                  onClose()
                }}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

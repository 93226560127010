/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

export interface IDefaultReplie {
  id: number
  text: string
  title: string
  status: boolean
  created_at: string
  updated_at: string
  institution: number
}

export type IDefaultReplyTotalCount = {
  data: IDefaultReplie[]
  count: number
  next: string
  previous: string
  results: IDefaultReplie[]
}

const getAllDefaultReplies = async (
  busca: string,
  item: string,
  total: string,
  institution: string,
): Promise<IDefaultReplyTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/default_replies/?search=${busca}&item=${item}&total=${total}&institution=${institution}`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao listar dados')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getDefaultReplyById = async (
  id: number,
): Promise<IDefaultReplie | Error> => {
  try {
    const relativeUrl = `/V1/default_replies/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const updateDefaultReplyById = async (
  id: number,
  formData: {},
): Promise<IDefaultReplie | Error> => {
  try {
    const relativeUrl = `/V1/default_replies/${id}/`

    const { data } = await Api.patch(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao atualizar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const createDefaultReply = async (formData: {}): Promise<
  IDefaultReplie | Error
> => {
  try {
    const relativeUrl = '/V1/default_replies/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao criar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export {
  createDefaultReply,
  getDefaultReplyById,
  getAllDefaultReplies,
  updateDefaultReplyById,
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Maybe, Schema } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'

export interface IFormData {
  secret: boolean
  sector_id: number
  latitude?: Maybe<number>
  longitude?: Maybe<number>
  subject_id: number
  category_id: number
  grouping_id: number
  description: string
  kind_response: number
  institution_id: number
  file1?: any
  file2?: any
  file3?: any
  file4?: any
  file5?: any
}

function testFileSize(file: any) {
  if (file) return file && file.size <= 5242880
  return true
}

const formValidationSchema: Schema<IFormData> = yup.object().shape({
  secret: yup.boolean().default(false),
  latitude: yup.number().notRequired(),
  longitude: yup.number().notRequired(),
  sector_id: yup.number().required().positive(),
  subject_id: yup.number().required().positive(),
  category_id: yup.number().required().positive(),
  grouping_id: yup.number().required().positive(),
  institution_id: yup.number().required().positive(),
  kind_response: yup.number().required().min(1).max(4),
  description: yup.string().required().min(6).max(6000),
  file1: yup
    .mixed()
    .test(
      'file-size-test',
      'Tamanho de arquivo superior ao permitido',
      (value) => testFileSize(value),
    )
    .notRequired(),
  file2: yup
    .mixed()
    .test(
      'file-size-test',
      'Tamanho de arquivo superior ao permitido',
      (value) => testFileSize(value),
    )
    .notRequired(),
  file3: yup
    .mixed()
    .test(
      'file-size-test',
      'Tamanho de arquivo superior ao permitido',
      (value) => testFileSize(value),
    )
    .notRequired(),
  file4: yup
    .mixed()
    .test(
      'file-size-test',
      'Tamanho de arquivo superior ao permitido',
      (value) => testFileSize(value),
    )
    .notRequired(),
  file5: yup
    .mixed()
    .test(
      'file-size-test',
      'Tamanho de arquivo superior ao permitido',
      (value) => testFileSize(value),
    )
    .notRequired(),
})

export { formValidationSchema }

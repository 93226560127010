import { useEffect, useState } from 'react'
import {
  Box,
  Divider,
  Link,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  IClientDetail,
  ILegislation,
  externalGetLegislations,
} from '../../../shared/services/api'
import {
  FormBoxStyle,
  NotificacaoError,
  TabPanel,
  VTab,
  VTabs,
  a11yProps,
} from '../../../shared/components'

interface ILegislacaoModalProps {
  show: boolean
  institutionData: IClientDetail
  onClose: () => void
}

export const LegislacaoModal: React.FC<ILegislacaoModalProps> = ({
  show,
  institutionData,
  onClose,
}) => {
  const [tab, setTab] = useState(0)
  const [data, setData] = useState<ILegislation[]>([])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const [estadual, setEstadual] = useState<ILegislation[]>([])
  const [municipal, setMunicipal] = useState<ILegislation[]>([])

  useEffect(() => {
    if (institutionData) {
      externalGetLegislations(Number(institutionData.id)).then((result) => {
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setData(result)
        }
      })
    }
  }, [institutionData])

  useEffect(() => {
    setEstadual(data.filter((leg) => leg.type === 1))
    setMunicipal(data.filter((leg) => leg.type === 2))
  }, [data])

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        overflow="auto"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Typography variant="h6" fontSize={25} textAlign="center">
          Legislação
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <VTabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <VTab label="Federal" {...a11yProps(0)} />
            {estadual.length > 0 && <VTab label="Estadual" {...a11yProps(1)} />}
            {municipal.length > 0 && (
              <VTab
                label="Municipal"
                {...a11yProps(estadual.length > 0 ? 2 : 1)}
              />
            )}
          </VTabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <Box display="flex" flex="1" flexDirection="column" gap={2}>
            <Typography variant="h6" color="dodgerblue">
              <Link
                href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12527.htm"
                underline="hover"
                target="_blank"
                rel="noopener"
              >
                Lei Nº 12.527/2011
              </Link>
            </Typography>
            <Typography paragraph textAlign="justify">
              A lei de Acesso à Informação regulamentou o direito constitucional
              de acesso às informações públicas. Essa norma entrou em vigor em
              16 de maio de 2012 e criou mecanismos que possibilitam, a qualquer
              pessoa, física ou jurídica, sem necessidade de apresentar motivo,
              o recebimento de informações públicas dos órgãos e entidades. A
              Lei vale para os três Poderes da União, Estados, Distrito Federal
              e Municípios, inclusive aos Tribunais de Conta e Ministério
              Público. Entidades privadas sem fins lucrativos também são
              obrigadas a dar publicidade a informações referentes ao
              recebimento e à destinação dos recursos públicos por elas
              recebidos.
            </Typography>

            <Divider sx={{ marginBottom: 2 }} />

            <Typography variant="h6" color="dodgerblue">
              <Link
                href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2017/lei/L13460.htm"
                underline="hover"
                target="_blank"
                rel="noopener"
              >
                Lei Nº 13.460/2017
              </Link>
            </Typography>
            <Typography paragraph textAlign="justify">
              A lei estabeleceu normas básicas para a participação, proteção e
              defesa do usuário dos serviços públicos prestados direta ou
              indiretamente pela administração pública. A norma foi sancionada
              em 26 de junho de 2017. A Lei, redigida com a contribuição do
              Ministério da Transparência e Controladoria-Geral da União (CGU),
              prevê também as atribuições e deveres das ouvidorias públicas,
              como forma de tratamento das demandas encaminhadas pelos usuários
              (cidadão).
            </Typography>
          </Box>
        </TabPanel>
        {estadual.length > 0 && (
          <TabPanel value={tab} index={1}>
            {estadual.map((rule, index) => (
              <Box
                display="flex"
                flex="1"
                flexDirection="column"
                gap={2}
                key={index}
              >
                <Typography variant="h6" color="dodgerblue">
                  <Link
                    href={rule.link}
                    underline="hover"
                    target="_blank"
                    rel="noopener"
                  >
                    {rule.title}
                  </Link>
                </Typography>
                <Typography paragraph textAlign="justify">
                  {rule.text}
                </Typography>
                {estadual.length - 1 !== index && (
                  <Divider sx={{ marginBottom: 2 }} />
                )}
              </Box>
            ))}
          </TabPanel>
        )}
        {municipal.length > 0 && (
          <TabPanel value={tab} index={estadual.length > 0 ? 2 : 1}>
            {municipal.map((rule, index) => (
              <Box
                display="flex"
                flex="1"
                flexDirection="column"
                gap={2}
                key={index}
              >
                <Typography variant="h6" color="dodgerblue">
                  <Link
                    href={rule.link}
                    underline="hover"
                    target="_blank"
                    rel="noopener"
                  >
                    {rule.title}
                  </Link>
                </Typography>
                <Typography paragraph textAlign="justify">
                  {rule.text}
                </Typography>
                {municipal.length - 1 !== index && (
                  <Divider sx={{ marginBottom: 2 }} />
                )}
              </Box>
            ))}
          </TabPanel>
        )}
      </Box>
    </Modal>
  )
}

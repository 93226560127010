import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

interface IExternalAuthContextData {
  toggleMenuOpen: () => void
  isAuthenticated: boolean
  menuOpen: boolean
  user: {
    name: string
    token: string
    id: number
  }
}

const ExternalAuthContext = createContext({} as IExternalAuthContextData)

const LOCAL_STORAGE_KEY__EXTERNAL_TOKEN = 'EXTERNAL_TOKEN'
const LOCAL_STORAGE_KEY__EXTERNAL_NAME = 'EXTERNAL_NAME'
const LOCAL_STORAGE_KEY__EXTERNAL_ID = 'EXTERNAL_ID'

interface IExternalAuthProviderProps {
  children: React.ReactNode
}

export const ExternalAuthProvider: React.FC<IExternalAuthProviderProps> = ({
  children,
}) => {
  const [userToken, setUserToken] = useState<string>()
  const [userName, setUserName] = useState<string>()
  const [userId, setUserId] = useState<number>()

  const [menuOpen, setMenuOpen] = useState(false)

  const handleMenuOpen = useCallback(() => {
    setMenuOpen((oldValue) => !oldValue)
  }, [])

  useEffect(() => {
    const userToken = sessionStorage.getItem(LOCAL_STORAGE_KEY__EXTERNAL_TOKEN)
    const userName = sessionStorage.getItem(LOCAL_STORAGE_KEY__EXTERNAL_NAME)
    const userId = sessionStorage.getItem(LOCAL_STORAGE_KEY__EXTERNAL_ID)

    if (userToken) setUserToken(userToken)
    else setUserToken(undefined)

    if (userName) setUserName(userName)
    else setUserName(undefined)

    if (userId) setUserId(Number(userId))
    else setUserId(undefined)
  }, [])

  const isAuthenticated = useMemo(
    () => !!userToken && !!userName,
    [userToken, userName],
  )

  return (
    <ExternalAuthContext.Provider
      value={{
        toggleMenuOpen: handleMenuOpen,
        menuOpen,
        isAuthenticated,
        user: {
          name: String(userName),
          token: String(userToken),
          id: Number(userId),
        },
      }}
    >
      {children}
    </ExternalAuthContext.Provider>
  )
}

export const useExternalAuthContext = () => useContext(ExternalAuthContext)

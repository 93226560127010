import { FC, ReactNode } from 'react'
import {
  Modal as MUIModal,
  Box,
  Paper,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { FormBoxStyle } from '../form-box-style/FormBosXtyle'

interface ModalProps {
  onClose: () => void
  show: boolean
  children: ReactNode
}

export const Modal: FC<ModalProps> = ({ onClose, show, children }) => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <MUIModal
      onClose={() => {
        onClose()
      }}
      open={show}
      aria-describedby="modal-title"
    >
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        {children}
      </Box>
    </MUIModal>
  )
}

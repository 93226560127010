/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Autocomplete, TextField } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useDebounce } from '../../../../../shared/hooks'
import { useField } from '@unform/core'
import { getAllSacCategories } from '../../../../../shared/services/api/sac-services'
import { useAuthContext } from '../../../../../shared/contexts'
import { useModuleContext } from '../../../../../shared/contexts/ModulesContext'
import { NotificacaoError } from '../../../../../shared/components'

type AutocompleteOption = {
  value: number
  label: string
}

interface AutocompleteCategoriaSACProps {
  name: string
}

export const AutocompleteCategoriaSAC: FC<AutocompleteCategoriaSACProps> = ({
  name,
}) => {
  const { debounce } = useDebounce(1000)
  const { user } = useAuthContext()
  const { thisModule } = useModuleContext()
  const { fieldName, registerField, error, clearError } = useField(name)

  const [options, setOptions] = useState<AutocompleteOption[]>([])
  const [value, setValue] = useState<number | null>(null)

  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllSacCategories(
        search,
        '0',
        '999999999',
        String(user!.institution_id),
        String(thisModule!.id),
      ).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar categorias')
        } else {
          setOptions(
            result.results.map((category) => ({
              label: category.name,
              value: category.id,
            })),
          )
        }
      })
    })
  }, [debounce, search, thisModule, user])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [fieldName, value, registerField])

  return (
    <Autocomplete
      options={options}
      loading={isLoading}
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      noOptionsText="Nenhuma categoria encontrada"
      inputValue={search}
      onChange={(_, newValue) => {
        setValue(newValue?.value || null)
        setSearch('')
        clearError()
      }}
      onInputChange={(_, newValue) => {
        setSearch(newValue)
        clearError()
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          fullWidth
          error={!!error}
          helperText={error}
          size="small"
          label="Categoria"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

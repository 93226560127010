import { Box, Button, IconButton, Tooltip } from '@mui/material'
import { FC, Fragment, useEffect, useState } from 'react'
import {
  SacSlide,
  getSacSlides,
} from '../../../../shared/services/api/sac-services'
import { NotificacaoError } from '../../../../shared/components'
import { useDebounce } from '../../../../shared/hooks'
import {
  ArrowBackIos,
  ArrowForwardIos,
  Launch,
  PlayArrow,
} from '@mui/icons-material'

interface SlideSACProps {
  sacId?: number | string
}

export const SlideMediaSAC: FC<SlideSACProps> = ({ sacId }) => {
  const { debounce } = useDebounce(5000, false)

  const [slides, setSlides] = useState<SacSlide[]>([])
  const [currentSlide, setCurrentSlide] = useState(0)

  const [autoPlay, setAutoPlay] = useState(true)

  const forwardSlide = () => {
    setAutoPlay(false)
    setCurrentSlide((oldvalue) => {
      if (oldvalue === slides.length - 1) {
        return 0
      } else {
        return oldvalue + 1
      }
    })
  }

  const backSlide = () => {
    setAutoPlay(false)
    setCurrentSlide((oldvalue) => {
      if (oldvalue === 0) {
        return slides.length - 1
      } else {
        return oldvalue - 1
      }
    })
  }

  useEffect(() => {
    if (sacId) {
      getSacSlides(sacId).then((result) => {
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setSlides(result)
        }
      })
    }
  }, [sacId])

  useEffect(() => {
    debounce(() => {
      if (autoPlay && slides.length > 1) {
        setCurrentSlide((oldvalue) => {
          if (oldvalue + 1 === slides.length) {
            return 0
          }
          return oldvalue + 1
        })
      }
    })
  }, [currentSlide, autoPlay, slides.length])

  return (
    <Box
      display="flex"
      alignItems="center"
      overflow="hidden"
      position="relative"
      left={`-${currentSlide * 100}vw`}
      width={`${slides.length * 100}vw`}
      sx={{
        transition: 'left .7s ease-in-out, width .25s linear',
      }}
    >
      {slides.length > 1 && (
        <Fragment>
          <Tooltip title="Slide anterior">
            <IconButton
              onClick={backSlide}
              sx={{ position: 'fixed', top: '50%', left: '8px', zIndex: 10 }}
            >
              <ArrowBackIos />
            </IconButton>
          </Tooltip>
          <Tooltip title="Slide seguinte">
            <IconButton
              onClick={forwardSlide}
              sx={{
                position: 'fixed',
                top: '50%',
                left: 'calc(100vw - 48px)',
                zIndex: 10,
              }}
            >
              <ArrowForwardIos />
            </IconButton>
          </Tooltip>
        </Fragment>
      )}
      {!autoPlay && (
        <Tooltip title="Slide automático">
          <IconButton
            onClick={() => setAutoPlay(true)}
            sx={{
              position: 'fixed',
              left: 'calc(100vw - 48px)',
              top: '90px',
              zIndex: 10,
            }}
          >
            <PlayArrow />
          </IconButton>
        </Tooltip>
      )}
      {slides.map((slide) => (
        <Box
          key={slide.id}
          display="inline-block"
          maxHeight="calc(100vh - 85px)"
          width="100vw"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="calc(100vh - 85px)"
            position="relative"
          >
            <img
              loading="eager"
              alt={slide.title}
              src={slide.image}
              style={{
                maxHeight: 'calc(100vh - 85px)',
                maxWidth: '100%',
              }}
            />
            {slide.link && (
              <Button
                onClick={() => window.open(slide.link, '_blank')}
                startIcon={<Launch />}
                variant="contained"
                sx={{
                  bottom: '10px',
                  position: 'absolute',
                  bgcolor: slide.button_color,
                  color: slide.button_text_color,
                  ':hover': { bgcolor: slide.button_color },
                }}
              >
                {slide.button_text}
              </Button>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

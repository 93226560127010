import { FC, useState } from 'react'
import {
  Box,
  Select,
  Tooltip,
  MenuItem,
  TableRow,
  TableCell,
  Pagination,
  TableFooter,
  LinearProgress,
  SelectChangeEvent,
} from '@mui/material'

interface FooterProps {
  loading: boolean
  colSpan: number
  totalCount: number
  total: string
  item: number
  onChangePagination(e: React.ChangeEvent<unknown>, newPage: number): void
  onChangeTotalItems(e: SelectChangeEvent<string>): void
}

export const Footer: FC<FooterProps> = ({
  total,
  colSpan,
  loading,
  totalCount,
  item,
  onChangePagination,
  onChangeTotalItems,
}) => {
  const pagina = item / Number(total) + 1

  const [open, setOpen] = useState(false)

  return (
    <TableFooter>
      {loading && (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <LinearProgress
              variant="indeterminate"
              sx={{ height: '5px', borderRadius: '50px' }}
            />
          </TableCell>
        </TableRow>
      )}
      {(totalCount > 10 || totalCount > Number(total)) && (
        <TableRow>
          <TableCell colSpan={10}>
            <Box display="flex" justifyContent="center">
              <Pagination
                page={pagina}
                count={Math.ceil(totalCount / Number(total))}
                onChange={onChangePagination}
              />
              <Tooltip
                title="Itens por Página"
                open={open}
                onMouseEnter={() => setOpen(true)}
                onMouseDown={() => setOpen(false)}
                onMouseLeave={() => setOpen(false)}
              >
                <Select
                  variant="outlined"
                  sx={{ borderRadius: '15px', height: '30px' }}
                  value={total}
                  onChange={onChangeTotalItems}
                >
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="20">20</MenuItem>
                  <MenuItem value="30">30</MenuItem>
                  <MenuItem value="40">40</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                </Select>
              </Tooltip>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </TableFooter>
  )
}

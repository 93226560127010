import { blue, green, red } from '@mui/material/colors'
import { createTheme } from '@mui/material'

export const DarkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: blue[600],
      dark: blue[800],
      light: blue[500],
      contrastText: '#ffffff',
    },
    secondary: {
      main: green[600],
      dark: green[800],
      light: green[500],
      contrastText: red[700],
    },
    background: {
      default: '#202124',
      paper: '#303134',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          height: '5px',
        },
      },
    },
  },
  typography: {
    // body1: {
    //     fontSize: 20
    // },
    // subtitle1: {
    //     fontSize: 20
    // },
    // caption: {
    //     fontSize: 15
    // },
    allVariants: {
      fontFamily: 'League Spartan, sans-serif',
      color: 'white',
      // fontSize: 18
    },
  },
})

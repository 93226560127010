import { FC, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'

import { LayoutBaseDePagina } from '../../../shared/layouts'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

import defaultLogo from '../../../images/default_logo.png'
import { People } from '@mui/icons-material'

export const SacDashboard: FC = () => {
  const { thisModule } = useModuleContext()

  const [protocols, setProtocols] = useState<any[]>([])
  const [protocolsLoading, setProtocolsLoading] = useState(true)

  const [users, setUsers] = useState<number>()
  const [usersLoading, setUsersLoading] = useState(true)

  return (
    <LayoutBaseDePagina title="Dashboard">
      {!thisModule && (
        <Box
          display="flex"
          flex="1 1"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <img src={defaultLogo} alt="tickdoc_logo" width={320} />
          <Box display="flex" gap={1}>
            <Typography>Carregando dados</Typography>
            <CircularProgress size={25} />
          </Box>
        </Box>
      )}

      {thisModule?.profile === 0 ? (
        <Box>
          <Typography>ADM Dashboard</Typography>
        </Box>
      ) : (
        <Box>
          <Typography>Users Dashboard</Typography>
          <Grid spacing={2} direction="column" mr={2}>
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Card>
                  <CardContent sx={{ display: 'flex' }}>
                    <Box display="flex" alignItems="center">
                      <People fontSize="large" />
                    </Box>
                    <Box
                      display="flex"
                      flex="1"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Typography variant="h6">Usuários</Typography>
                      {usersLoading ? (
                        <CircularProgress size="20px" />
                      ) : (
                        <Typography variant="h6">{users}</Typography>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card>
                  <CardContent sx={{ display: 'flex' }}>
                    <Box display="flex" alignItems="center">
                      <People fontSize="large" />
                    </Box>
                    <Box
                      display="flex"
                      flex="1"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Typography variant="h6">Usuários</Typography>
                      {usersLoading ? (
                        <CircularProgress size="20px" />
                      ) : (
                        <Typography variant="h6">{users}</Typography>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card>
                  <CardContent sx={{ display: 'flex' }}>
                    <Box display="flex" alignItems="center">
                      <People fontSize="large" />
                    </Box>
                    <Box
                      display="flex"
                      flex="1"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Typography variant="h6">Usuários</Typography>
                      {usersLoading ? (
                        <CircularProgress size="20px" />
                      ) : (
                        <Typography variant="h6">{users}</Typography>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </LayoutBaseDePagina>
  )
}

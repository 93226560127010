/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

interface ISector {
  id: number
  name: string
}

export interface ISubjects {
  id: string
  name: string
  status: boolean
  created_at: string
  updated_at: string
  sectors: ISector[]
  institution_id: number
}

export type ISubjectsTotalCount = {
  data: ISubjects[]
  count: number
  next: string
  previous: string
  results: ISubjects[]
}

const getAllSubjects = async (
  item = '0',
  total = '10',
  filter = '',
  subjectId = '',
  institutionId = '',
  status = '',
): Promise<ISubjectsTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/subjects/`

    const { data } = await Api.get(relativeUrl, {
      params: {
        item,
        total,
        status,
        id: subjectId,
        search: filter,
        institution_id: institutionId,
      },
    })

    if (data) {
      return {
        data,
        results: data.results,
        count: data.count,
        next: data.next,
        previous: data.previous,
      }
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getSubjectById = async (id: number): Promise<ISubjects | Error> => {
  try {
    const relativeUrl = `/V1/subjects/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return data
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao listar registro',
    )
  }
}

const createSubject = async (formData: {}): Promise<string | Error> => {
  try {
    const relativeUrl = '/V1/subjects/'

    const { data } = await Api.post<ISubjects>(relativeUrl, formData)

    if (data) return data.id ? data.id.toString() : data.name

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const updateSubjectById = async (
  id: number,
  formData: {},
): Promise<ISubjects | Error> => {
  try {
    const relativeUrl = `/V1/subjects/${id}/`

    const { data } = await Api.patch<ISubjects>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

export { getAllSubjects, getSubjectById, createSubject, updateSubjectById }

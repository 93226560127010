/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import {
  Box,
  Grid,
  Modal,
  Paper,
  Button,
  Divider,
  useTheme,
  IconButton,
  Typography,
  useMediaQuery,
  LinearProgress,
  FormControlLabel,
} from '@mui/material'
import { useColor } from 'react-color-palette'

import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'
import { VForm, VSwitch, VTextField, useVForm } from '../../../shared/forms'
import {
  ISlide,
  createSlide,
  updateSlideById,
} from '../../../shared/services/api'

import { Delete, PhotoCamera } from '@mui/icons-material'
import { useAuthContext } from '../../../shared/contexts'
import { CustomSketchPicker } from './components/SketchPicker'
import { ModalHeader } from '../../../shared/components/modal/Header'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

interface IFormularioProps {
  show: boolean
  onClose: () => void
  create: boolean
  slideData?: ISlide
  update: any
}

export const Formulario: React.FC<IFormularioProps> = ({
  show,
  onClose,
  create,
  slideData,
  update,
}) => {
  const { formRef } = useVForm()
  const { user } = useAuthContext()
  const { ombudsmanModule } = useModuleContext()

  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const [image, setImage] = useState<any>(undefined)
  const [imageURL, setImageURL] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectColor, setSelectColor] = useState(false)

  const canUpdate =
    ombudsmanModule?.profile === 2 || ombudsmanModule?.hr_manager
  const canNotUpdate = !canUpdate

  const imageData = new FormData()

  const [textColor, setTextColor] = useColor(
    create ? '#000000' : slideData !== undefined ? slideData.text_color : '',
  )
  const [textHover, setTextHover] = useColor(
    create
      ? '#000000'
      : slideData !== undefined
      ? slideData.text_color_hover
      : '',
  )
  const [buttonColor, setButtonColor] = useColor(
    create ? '#1e90ff' : slideData !== undefined ? slideData.button_color : '',
  )
  const [buttonHover, setButtonHover] = useColor(
    create
      ? '#005596'
      : slideData !== undefined
      ? slideData.button_color_hover
      : '',
  )

  const [colorProps, setColorProps] = useState({
    setColorRef: setTextColor,
    type: 'button',
  })

  const handleSubmit = (formData: any) => {
    setIsLoading(true)
    formData.button_color = buttonColor.hex
    formData.button_color_hover = buttonHover.hex
    formData.text_color = textColor.hex
    formData.text_color_hover = textHover.hex

    if (create) {
      if (user?.modules[0].profile === 0) {
        if (!localStorage.getItem('ADM_SELECTED_INSTITUTION')) {
          NotificacaoError(
            'Selecione Uma Instituição Antes de Criar uma Nova instância',
          )
          setIsLoading(false)
          return onClose()
        }
        formData.institution_id = Number(
          localStorage.getItem('ADM_SELECTED_INSTITUTION'),
        )
      } else {
        formData.institution_id = Number(user?.institution_id)
      }
    } else {
      formData.institution_id = Number(slideData?.institution_id)
    }

    if (create) {
      Object.keys(formData).forEach((data: any) => {
        imageData.append(data, formData[data])
      })
      if (!image) return NotificacaoError('Insira uma imagem')
      else {
        imageData.append('image', image, image.name)
        createSlide(imageData).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            update.current = true
            NotificacaoSucesso('Slide cadastrado com sucesso!')
            formRef.current?.reset()
            setImage(undefined)
            URL.revokeObjectURL(imageURL)
            setImageURL('')
            update.current = true
            onClose()
          }
        })
      }
    } else {
      Object.keys(formData).forEach((data: any) => {
        imageData.append(data, formData[data])
      })
      if (image) {
        imageData.append('image', image, image.name)
      }
      updateSlideById(Number(slideData?.id), imageData).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          update.current = true
          NotificacaoSucesso('Slide atualizado com sucesso!')
          formRef.current?.reset()
          setImage(undefined)
          URL.revokeObjectURL(imageURL)
          setImageURL('')
          onClose()
        }
      })
    }
  }

  const handleClose = () => {
    formRef.current?.reset()
    setImage(undefined)
    URL.revokeObjectURL(imageURL)
    setImageURL('')
    onClose()
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={FormBoxStyle}
        overflow="auto"
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader
          onClose={handleClose}
          showCloseButton
          title={create ? 'Novo Slide' : `Slide ${slideData?.name}`}
        />
        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{ borderRadius: '50px', height: '5px', mt: 1 }}
          />
        )}
        <Box margin={1}>
          <VForm
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={create ? undefined : slideData}
          >
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={1}>
                <Grid item display="flex" xs={12} justifyContent="center">
                  <img
                    src={
                      create
                        ? imageURL
                        : imageURL !== ''
                        ? imageURL
                        : slideData?.image
                    }
                    height="200px"
                    alt="image"
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  xs={12}
                  justifyContent="center"
                  gap={1}
                >
                  <Button
                    variant="contained"
                    disabled={!!image || canNotUpdate}
                    component="label"
                    endIcon={<PhotoCamera />}
                    disableElevation
                    sx={{ borderRadius: '50px' }}
                  >
                    Imagem
                    <input
                      hidden
                      disabled={canNotUpdate}
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        if (e.target.files) {
                          setImageURL(URL.createObjectURL(e.target.files[0]))
                          setImage(e.target.files[0])
                        } else {
                          setImageURL('')
                          setImage(undefined)
                          URL.revokeObjectURL(imageURL)
                        }
                      }}
                    />
                  </Button>
                  {!!image && (
                    <IconButton
                      onClick={() => {
                        URL.revokeObjectURL(imageURL)
                        setImage(undefined)
                        setImageURL('')
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    disabled={canNotUpdate}
                    required
                    size="small"
                    name="name"
                    label="Nome"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    disabled={canNotUpdate}
                    size="small"
                    name="link"
                    label="link"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid
                  item
                  display="flex"
                  xs={12}
                  justifyContent="center"
                  gap={1}
                >
                  <FormControlLabel
                    disabled={canNotUpdate}
                    control={<VSwitch name="status" />}
                    label="Status"
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2} alignItems="center">
                <Grid
                  container
                  item
                  xs={6}
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Cor do botões:</Typography>
                  </Grid>
                  <Grid item>
                    <Box
                      disabled={canNotUpdate}
                      bgcolor={buttonColor.hex}
                      width="15px"
                      height="15px"
                      component={Button}
                      onClick={() => {
                        setColorProps({
                          setColorRef: setButtonColor,
                          type: 'button',
                        })
                        setSelectColor(true)
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Botão ao passar o mouse:</Typography>
                  </Grid>
                  <Grid item>
                    <Box
                      disabled={canNotUpdate}
                      bgcolor={buttonHover.hex}
                      width="15px"
                      height="15px"
                      component={Button}
                      onClick={() => {
                        setColorProps({
                          setColorRef: setButtonHover,
                          type: 'buttonHover',
                        })
                        setSelectColor(true)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item spacing={2} alignItems="center">
                <Grid
                  container
                  item
                  xs={6}
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography>Cor do texto:</Typography>
                  </Grid>
                  <Grid item>
                    <Box
                      disabled={canNotUpdate}
                      bgcolor={textColor.hex}
                      width="15px"
                      height="15px"
                      component={Button}
                      onClick={() => {
                        setColorProps({
                          setColorRef: setTextColor,
                          type: 'text',
                        })
                        setSelectColor(true)
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography>Texto ao passar o mouse:</Typography>
                  </Grid>
                  <Grid item>
                    <Box
                      disabled={canNotUpdate}
                      bgcolor={textHover.hex}
                      width="15px"
                      height="15px"
                      component={Button}
                      onClick={() => {
                        setColorProps({
                          setColorRef: setTextHover,
                          type: 'textHover',
                        })
                        setSelectColor(true)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {canUpdate && (
              <Box
                display="flex"
                width="100%"
                justifyContent="right"
                gap={1}
                paddingTop={2}
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ borderRadius: '50px' }}
                  disableElevation
                >
                  Enviar
                </Button>
                <Button
                  sx={{ borderRadius: '50px' }}
                  onClick={() => {
                    setImage(undefined)
                    setImageURL('')
                    formRef.current?.reset()
                    onClose()
                  }}
                >
                  cancelar
                </Button>
              </Box>
            )}
          </VForm>
        </Box>
        <CustomSketchPicker
          show={selectColor}
          onClose={() => {
            setSelectColor(false)
          }}
          {...colorProps}
          slideData={slideData}
        />
      </Box>
    </Modal>
  )
}

import { useField } from '@unform/core'
import { ChangeEvent, InputHTMLAttributes, useEffect, useState } from 'react'

interface VColorPickerProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  customOnChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const VColorPicker = (props: VColorPickerProps) => {
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(props.name)

  const [value, setValue] = useState(defaultValue || '')

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [fieldName, value, registerField])

  return (
    <>
      <input
        type="color"
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          clearError()
          props.customOnChange?.(e)
        }}
        {...props}
      />
      {error && <span style={{ fontSize: '15px', color: 'red' }}>{error}</span>}
    </>
  )
}

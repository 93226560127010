import { Divider, Grid, LinearProgress, Typography } from '@mui/material'
import { FC } from 'react'
import { ITypologyHistory } from '../../../../shared/services/api/histories-services/TypologiesHistoryServices'
import { FormatDateTime } from '../../../../shared/utils'

interface ITypologyHistoryProps {
  historyLoading: boolean
  history: ITypologyHistory[]
}

export const TypologyHistory: FC<ITypologyHistoryProps> = ({
  historyLoading,
  history,
}) => {
  return (
    <Grid container spacing={2} direction="column">
      {historyLoading && (
        <Grid item>
          <LinearProgress
            variant="indeterminate"
            sx={{
              borderRadius: '50px',
              height: '5px',
            }}
          />
        </Grid>
      )}

      {!!history && (
        <Grid container item spacing={2} direction="column">
          {history.map((query) => (
            <>
              <Divider variant="middle" />
              {query.type === '~' ? (
                <Grid container item>
                  {query.field === 'name' && (
                    <Grid container item direction="column">
                      <Grid item>
                        <Typography>
                          Nome alterado de ({query.old}) para ({query.new})
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          Alterado por: {query.user || 'Não definido'}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          Data: {FormatDateTime(query.date)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {query.field === 'status' && (
                    <Grid container item direction="column">
                      <Grid item>
                        <Typography>
                          {query.old
                            ? 'Tipologia desativada'
                            : 'Tipologia ativada'}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {query.old
                            ? `Desativada por ${query.user}`
                            : `Ativada por ${query.user}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          Data: {FormatDateTime(query.date)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>Tipologia criada</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Criado por: {query.user || 'Não definido'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Data: {FormatDateTime(query.date)}</Typography>
                  </Grid>
                </Grid>
              )}
            </>
          ))}
        </Grid>
      )}
    </Grid>
  )
}

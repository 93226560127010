// Global Imports
import { FC } from 'react'
import { Divider, Grid, LinearProgress, Typography } from '@mui/material'

// Local Imports
import {
  ISectorsInSubject,
  ISubjectHistory,
} from '../../../../shared/services/api/histories-services'
import { FormatDateTime } from '../../../../shared/utils'

interface ISubjectHistoryProps {
  history: ISubjectHistory[]
  historyLoading: boolean
}

interface ISectorsSubjectHistoryProps {
  history: ISectorsInSubject[]
  historyLoading: boolean
}

export const SubjectHistory: FC<ISubjectHistoryProps> = ({
  history,
  historyLoading,
}) => {
  return (
    <Grid container spacing={2} direction="column">
      {historyLoading && (
        <Grid item>
          <LinearProgress
            variant="indeterminate"
            sx={{
              borderRadius: '50px',
              height: '5px',
            }}
          />
        </Grid>
      )}

      {!!history && (
        <Grid container item spacing={2} direction="column">
          {history.map((query) => (
            <>
              <Divider variant="middle" />
              {query.type === '~' ? (
                <Grid container item>
                  {query.field === 'name' && (
                    <Grid container item direction="column">
                      <Grid item>
                        <Typography>
                          Nome alterado de ({query.old}) para ({query.new})
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>Alterado por: {query.user}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          Data: {FormatDateTime(query.date)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {query.field === 'status' && (
                    <Grid container item direction="column">
                      <Grid item>
                        <Typography>
                          {query.old ? 'Assunto desativado' : 'Assunto ativado'}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {query.old
                            ? `Desativado por ${query.user}`
                            : `Ativado por ${query.user}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          Data: {FormatDateTime(query.date)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid container item direction="column">
                  <Grid item>
                    <Typography>Assunto criado</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Criado por: {query.user || 'Não definido'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Data: {FormatDateTime(query.date)}</Typography>
                  </Grid>
                </Grid>
              )}
            </>
          ))}
        </Grid>
      )}
    </Grid>
  )
}

export const SectorsSubjectHistory: FC<ISectorsSubjectHistoryProps> = ({
  history,
  historyLoading,
}) => {
  return (
    <Grid container direction="column" spacing={2}>
      {historyLoading && (
        <Grid item>
          <LinearProgress
            variant="indeterminate"
            sx={{
              borderRadius: '50px',
              height: '5px',
            }}
          />
        </Grid>
      )}
      {history.map((query, index) => (
        <Grid container item spacing={1} direction="column" key={index}>
          <Grid item>
            <Typography>
              {query.type === '+'
                ? `Setor ${query.name} adicionado`
                : `Setor ${query.name} removido`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {query.type === '+'
                ? `Adcionado por: ${query.user}`
                : `Removido por: ${query.user}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>Data: {FormatDateTime(query.date)}</Typography>
          </Grid>
          {history.length !== index + 1 && <Divider sx={{ marginTop: 2 }} />}
        </Grid>
      ))}
    </Grid>
  )
}

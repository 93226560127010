import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useDebounce } from '../../../../shared/hooks'
import { getAllSacSectors } from '../../../../shared/services/api/sac-services'
import { NotificacaoError } from '../../../../shared/components'
import { useField } from '@unform/core'

type AutocompleteOption = {
  id: number
  label: string
}

interface AutocompleteSacSectorsProps {
  customerService: string | number
  name: string
}

export const AutocompleteSacSectors: FC<AutocompleteSacSectorsProps> = ({
  customerService,
  name,
}) => {
  const { debounce } = useDebounce(1000)
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField(name)

  const [firstTime, setFirstTime] = useState(true)
  const [options, setOptions] = useState<AutocompleteOption[]>([])
  const [value, setValue] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<AutocompleteOption[]>(
    [],
  )

  useEffect(() => {
    if (defaultValue && options.length && firstTime) {
      setSelectedOptions(
        options.filter((option) => defaultValue.includes(option.id)),
      )

      setFirstTime(false)
    }
  }, [defaultValue, options])

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllSacSectors(search, '0', '999999999', '', String(customerService))
        .then((result) => {
          if (result instanceof Error) {
            NotificacaoError('Erro ao carregar setores')
          } else {
            setOptions(
              result.results.map((sector) => ({
                id: sector.id,
                label: sector.name,
              })),
            )
          }
        })
        .finally(() => setIsLoading(false))
    })
  }, [search, customerService])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [fieldName, registerField, value])

  return (
    <Autocomplete
      multiple
      value={selectedOptions}
      onChange={(_, newValue) => {
        setSelectedOptions(newValue)
        setValue(newValue.map((option) => option.id))
        clearError()
      }}
      limitTags={1}
      loading={isLoading}
      options={options}
      inputValue={search}
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      noOptionsText="Nenhum setor encontrado"
      onInputChange={(_, newValue) => setSearch(newValue)}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Setores"
          error={!!error}
          helperText={error}
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

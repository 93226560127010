/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useDebounce } from '../../../../../shared/hooks'
import { getAllSacGroupings } from '../../../../../shared/services/api/sac-services'
import { NotificacaoError } from '../../../../../shared/components'
import { useField } from '@unform/core'
import { useModuleContext } from '../../../../../shared/contexts/ModulesContext'

type AutocompleteOption = {
  value: number
  label: string
}

interface AutocompleteAgrupamentoSACProps {
  name: string
  customOnChange(e: any, value: AutocompleteOption | null): void
}

export const AutocompleteAgrupamentoSAC: FC<
  AutocompleteAgrupamentoSACProps
> = ({ name, customOnChange }) => {
  const { debounce } = useDebounce(1000)
  const { fieldName, registerField, error, clearError } = useField(name)
  const { thisModule } = useModuleContext()

  const [options, setOptions] = useState<AutocompleteOption[]>([])
  const [selectedOption, setSelectedOption] =
    useState<AutocompleteOption | null>(null)
  const [value, setValue] = useState<number | null>(null)

  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  useEffect(() => {
    setIsLoading(true)
    if (thisModule?.id) {
      debounce(() => {
        getAllSacGroupings(
          '0',
          '999999999',
          search,
          String(thisModule.id),
        ).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError('Erro ao carregar agrupamentos')
          } else {
            setOptions(
              result.results.map((result) => ({
                label: result.name,
                value: result.id,
              })),
            )
          }
        })
      })
    }
  }, [search, thisModule?.id])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [value, fieldName, registerField])

  return (
    <Autocomplete
      fullWidth
      loading={isLoading}
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      noOptionsText="Nenhum agrupamento encontrado"
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      size="small"
      disablePortal
      options={options}
      value={selectedOption}
      onChange={(_, newValue) => {
        setSelectedOption(newValue)
        setValue(newValue?.value || null)
        customOnChange(_, newValue)
        setSearch('')
        clearError()
      }}
      inputValue={search}
      onInputChange={(_, newValue) => {
        setSearch(newValue)
        clearError()
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          fullWidth
          error={!!error}
          helperText={error}
          size="small"
          label="Agrupamento"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

import { Fragment } from 'react'
import { useAuthContext } from '../../contexts'
import { BrowserRouter, Routes } from 'react-router-dom'
import { PublicRoutes } from '../../../routes/PublicRoutes'
import { RedirectPrivateRoutes } from '../../../routes/PrivateRoutes'

interface ILoginProps {
  children: React.ReactNode
}
export const Login: React.FC<ILoginProps> = ({ children }) => {
  const { user } = useAuthContext()

  if (user) return <>{children}</>

  return (
    <BrowserRouter>
      <Routes>
        <Fragment>
          {PublicRoutes}
          {RedirectPrivateRoutes}
        </Fragment>
      </Routes>
    </BrowserRouter>
  )
}

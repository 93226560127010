/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import {
  FerramentasDeDetalhes,
  LegendaSumario,
  NotificacaoError,
} from '../../../shared/components'
import {
  IRSectors,
  IUserDetail,
  IUserInSector,
  getSectorById,
  getUsersInSector,
  getUsersInSectorHistory,
} from '../../../shared/services/api'
import { LayoutBaseDePagina } from '../../../shared/layouts'

import { FormSetores } from './formulario/Formulário'
import { ExpandMore } from '@mui/icons-material'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'
import {
  ISectorHistory,
  getSectorHistory,
} from '../../../shared/services/api/histories-services/SectorHistoryServices'
import { useAuthContext } from '../../../shared/contexts'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const DetalhesSetores: React.FC = () => {
  const navigate = useNavigate()
  const { moduleTitle } = useModuleContext()

  const updated = useRef(false)
  const { user } = useAuthContext()

  const { id = 'new' } = useParams<'id'>()

  const [data, setData] = useState<IRSectors>()
  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)

  const [usersHistory, setUsersHistory] = useState<IUserInSector[]>([])
  const [usersHistoryLoading, setUsersHistoryLoading] = useState(true)

  const [history, setHistory] = useState<ISectorHistory[]>([])
  const [historyLoading, setHistoryLoading] = useState(true)

  const [users, setUsers] = useState<IUserDetail[]>([])
  const [usersLoading, setUsersLoading] = useState(true)

  const [isLoading, setIsLoading] = useState(true)
  const [name, setName] = useState('')

  useEffect(() => {
    if (id !== 'new') {
      updated.current = false
      setIsLoading(true)

      getSectorById(Number(id)).then((result) => {
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError(result.message)
          navigate(-1)
        } else {
          setName(result.name)
          setData(result)
        }
      })

      getUsersInSector(Number(id)).then((result) => {
        setUsersLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setUsers(result)
        }
      })

      getSectorHistory(Number(id)).then((result) => {
        setHistoryLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setHistory(result)
        }
      })

      getUsersInSectorHistory(Number(id)).then((result) => {
        setUsersHistoryLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setUsersHistory(result)
        }
      })
    }
  }, [id, updated.current])

  return (
    <LayoutBaseDePagina
      title={`Setor ${name}` || 'Detalhes Setor'}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          textoBotaoNovo="Novo"
          mostrarBotaoNovo={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.hr_manager ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          mostrarBotaoEditar={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.hr_manager ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
          aoClicarEmVoltar={() => navigate(-1)}
          mostrarBotaoApagar={false}
        />
      }
    >
      <Box
        gap={1}
        marginX={1}
        padding={1}
        paddingX={1}
        display="flex"
        flexDirection="column"
      >
        {isLoading && (
          <Box>
            <LinearProgress
              variant="indeterminate"
              sx={{
                borderRadius: '50px',
                height: '5px',
              }}
            />
          </Box>
        )}
        <Box
          gap={1}
          marginX={1}
          padding={1}
          paddingX={1}
          display="flex"
          flexDirection="column"
        >
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <LegendaSumario icon="info_outlined" name="Informações" />
            </AccordionSummary>
            <AccordionDetails>
              <Box flex="1">
                <Grid container direction="column" spacing={2}>
                  <Grid container item spacing={1}>
                    <Grid item>
                      <Typography>Status: </Typography>
                    </Grid>
                    <Grid item width="70px">
                      {FormatStatus(data?.status || false)}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1}>
                    <Grid item>
                      <Typography>Nome: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{data?.name}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container item spacing={1}>
                    <Grid item>
                      <Typography>Agrupamento: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {data?.grouping_id?.name || 'indefinido'}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item spacing={1}>
                    <Grid item>
                      <Typography>Criado em: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {FormatDateTime(data?.created_at)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <LegendaSumario icon="group_add" name="Usuários cadastrados" />
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer
                component={Paper}
                variant="outlined"
                sx={{
                  m: 1,
                  width: 'auto',
                  color: 'dodgerblue',
                  border: '1px solid',
                  borderRadius: '15px',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">Nome</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Perfil</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell align="center">
                          <IconButton
                            color="primary"
                            onClick={() =>
                              navigate(
                                `/ombudsman/${moduleTitle}/sectorsusers/detail/${user.id}`,
                              )
                            }
                          >
                            <Icon>info_outlined</Icon>
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">{user.username}</TableCell>
                        <TableCell align="center">{user.email}</TableCell>
                        <TableCell align="center">
                          {user.ombudsman_profile === '1'
                            ? 'Auxiliar'
                            : 'Ouvidor'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                  {users.length === 0 && !usersLoading && (
                    <caption>Nenhum usuário ativo no setor</caption>
                  )}

                  <TableFooter>
                    {usersLoading && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <LinearProgress
                            variant="indeterminate"
                            sx={{ borderRadius: '50px', height: '5px' }}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableFooter>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <LegendaSumario icon="restore" name="Histórico" />
            </AccordionSummary>
            <AccordionDetails>
              <Box margin={1}>
                <Grid container spacing={2} direction="column">
                  {history.map((query, index) => (
                    <Grid
                      container
                      item
                      spacing={1}
                      direction="column"
                      key={index}
                    >
                      {historyLoading && <LinearProgress sx={{ mt: 2 }} />}
                      {query.type === '+' ? (
                        <>
                          <Grid item>
                            <Typography>Setor criado</Typography>
                          </Grid>
                          <Grid item>
                            <Typography>
                              Criado por: {query.user || 'Não definido'}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography>
                              Data: {FormatDateTime(query.date)}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <>
                          {query.field === 'grouping_id' && (
                            <Grid container item spacing={1} direction="column">
                              <Grid item>
                                <Typography>
                                  Agrupamento alterado de {query.old} para{' '}
                                  {query.new}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data: {FormatDateTime(query.date)}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'name' && (
                            <Grid container item spacing={1} direction="column">
                              <Grid item>
                                <Typography>
                                  Nome alterado de {query.old} para {query.new}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data: {FormatDateTime(query.date)}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'status' && (
                            <Grid container item spacing={1} direction="column">
                              <Grid item>
                                <Typography>
                                  {query.old
                                    ? 'Setor desativado'
                                    : 'Setor ativado'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  {query.old
                                    ? `Desativado por: ${query.user}`
                                    : `Ativado por: ${query.user}`}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data: {FormatDateTime(query.date)}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </>
                      )}
                      {history.length !== index + 1 && (
                        <Divider sx={{ marginTop: 2 }} />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <LegendaSumario
                icon="manage_accounts"
                name="Hisórico de usuários"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Box flex="1">
                <Grid container direction="column" spacing={2}>
                  {usersHistory.map((query, index) => (
                    <Grid
                      container
                      item
                      spacing={1}
                      direction="column"
                      key={index}
                    >
                      {usersHistoryLoading && <LinearProgress sx={{ mt: 2 }} />}
                      <Grid item>
                        <Typography>
                          {query.type === '+'
                            ? `Usuário ${query.username} adicionado`
                            : `Usuário ${query.username} removido`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {query.type === '+'
                            ? `Adcionado por: ${query.user}`
                            : `Removido por: ${query.user}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          Data: {FormatDateTime(query.date)}
                        </Typography>
                      </Grid>
                      {usersHistory.length !== index + 1 && (
                        <Divider sx={{ marginTop: 2 }} />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <FormSetores
        show={viewForm}
        create={create}
        update={updated}
        data={!create ? data : undefined}
        onClose={() => setViewForm(false)}
      />
    </LayoutBaseDePagina>
  )
}

// {FormSetores(
//   handleViewForm,
//   viewForm,
//   updated,
//   create,
//   !create ? data : undefined
// )}

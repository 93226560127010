/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  IProtocolsList,
  getAllExternals,
  getDashboardData,
} from '../../../shared/services/api'
import { NotificacaoAlerta, NotificacaoError } from '../../../shared/components'
import { Link, useNavigate } from 'react-router-dom'
import { ExpandMore } from '@mui/icons-material'
import { FormatPriority } from '../../../shared/utils/format/FormatPriority'
import { FormatProtocolStatus } from '../../../shared/utils'
import { useAuthContext } from '../../../shared/contexts'
import { AdmDashboard } from '../../administradores/dashboard/Dashboard'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const OmbudsmanDashboard = () => {
  const navigate = useNavigate()

  const { user } = useAuthContext()
  const { moduleTitle } = useModuleContext()

  if (user?.modules[0].profile === 0) {
    return <AdmDashboard />
  }

  const [usersLoading, setUsersLoading] = useState(true)
  const [usersTotal, setUsersTotal] = useState(0)

  const [total, setTotal] = useState(0)
  const [totalLoading, setTotalLoading] = useState(true)

  const [delayed, setDelayed] = useState(0)
  const [delayedLoading, setDelayedLoading] = useState(true)
  const [delayedData, setDelayedData] = useState<IProtocolsList[]>([])

  const [pending, setPending] = useState(0)
  const [pendingLoading, setPendingLoading] = useState(true)

  const [extended, setExtended] = useState(0)
  const [extendedLoading, setExtendedLoading] = useState(true)
  const [extendedData, setExtendedData] = useState<IProtocolsList[]>([])

  const [rejected, setRejected] = useState(0)
  const [rejectedLoading, setRejectedLoading] = useState(true)

  const [analyzing, setAnalyzing] = useState(0)
  const [analyzingLoading, setAnalyzingLoading] = useState(true)

  const [completed, setCompleted] = useState(0)
  const [completedLoading, setCompletedLoading] = useState(true)

  const [priority, setPriority] = useState(0)
  const [priorityLoading, setPriorityLoading] = useState(true)

  const [urgentData, setUrgentData] = useState<IProtocolsList[]>([])

  useEffect(() => {
    if (sessionStorage.getItem('APP_LOGGED') === 'true') {
      toast.info('Você já está logado')
      sessionStorage.clear()
    }

    getAllExternals().then((result) => {
      setUsersLoading(false)
      if (result instanceof Error) {
        NotificacaoError('Erro ao carregar usuários')
      } else {
        setUsersTotal(result.count)
      }
    })

    if (user?.modules[0].profile !== 0) {
      getDashboardData().then((result) => {
        setTotalLoading(false)
        setDelayedLoading(false)
        setPendingLoading(false)
        setExtendedLoading(false)
        setRejectedLoading(false)
        setPriorityLoading(false)
        setAnalyzingLoading(false)
        setCompletedLoading(false)
        if (result instanceof Error) {
          toast.warn('Erro ao carregar manifestações')
          NotificacaoError(result.message)
        } else {
          setTotal(result.total)
          setDelayed(result.delayed)
          setPending(result.pending)
          setExtended(result.extended)
          setRejected(result.rejected)
          setPriority(result.priority)
          setAnalyzing(result.analyzing)
          setCompleted(result.completed)
          setUrgentData(result.urgent_data)
          setDelayedData(result.delayed_data)
          setExtendedData(result.extended_data)
        }
      })
    }
  }, [user?.modules])

  return (
    <LayoutBaseDePagina title="Dashboard">
      <Grid container spacing={2} paddingRight={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              borderRadius: '10px',
              width: '100%',
              justifyContent: 'left',
            }}
            elevation={5}
            component={Button}
            onClick={() => {
              const currentModule = user?.modules.find(
                (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
              )
              if (
                currentModule?.profile === 2 ||
                currentModule?.profile === 0
              ) {
                navigate(`/ombudsman/${moduleTitle}/externalusers`)
              } else {
                NotificacaoAlerta('Sem acesso aos usuários externos')
              }
            }}
          >
            <CardContent sx={{ width: '100%' }}>
              <Box display="flex" alignItems="center" gap={1} width="100%">
                <Icon fontSize="large">account_circle</Icon>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="h6">Usuários</Typography>
                  {usersLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="h6" fontSize="30px">
                      {usersTotal}
                    </Typography>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              borderRadius: '10px',
              width: '100%',
              justifyContent: 'left',
            }}
            elevation={5}
            component={Button}
            onClick={() => navigate(`/ombudsman/${moduleTitle}/protocols`)}
          >
            <CardContent sx={{ width: '100%' }}>
              <Box display="flex" alignItems="center" gap={1} width="100%">
                <Icon fontSize="large">confirmation_number</Icon>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="h6">Manifestações</Typography>
                  {totalLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="h6" fontSize="30px">
                      {total}
                    </Typography>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              borderRadius: '10px',
              width: '100%',
              justifyContent: 'left',
            }}
            elevation={5}
            component={Button}
            onClick={() =>
              navigate(`/ombudsman/${moduleTitle}/protocols?status=1`)
            }
          >
            <CardContent sx={{ width: '100%' }}>
              <Box display="flex" alignItems="center" gap={1} width="100%">
                <Icon fontSize="large">warning</Icon>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="h6">Atrasadas</Typography>
                  {delayedLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="h6" fontSize="30px">
                      {delayed}
                    </Typography>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              borderRadius: '10px',
              width: '100%',
              justifyContent: 'left',
            }}
            elevation={5}
            component={Button}
            onClick={() =>
              navigate(`/ombudsman/${moduleTitle}/protocols?status=2`)
            }
          >
            <CardContent sx={{ width: '100%' }}>
              <Box display="flex" alignItems="center" gap={1} width="100%">
                <Icon fontSize="large">access_time</Icon>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="h6">Pendentes</Typography>
                  {pendingLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="h6" fontSize="30px">
                      {pending}
                    </Typography>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              borderRadius: '10px',
              width: '100%',
              justifyContent: 'left',
            }}
            elevation={5}
            component={Button}
            onClick={() =>
              navigate(`/ombudsman/${moduleTitle}/protocols?status=3`)
            }
          >
            <CardContent sx={{ width: '100%' }}>
              <Box display="flex" alignItems="center" gap={1} width="100%">
                <Icon fontSize="large">remove_red_eye</Icon>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="h6">Trâmite</Typography>
                  {analyzingLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="h6" fontSize="30px">
                      {analyzing}
                    </Typography>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              borderRadius: '10px',
              width: '100%',
              justifyContent: 'left',
            }}
            elevation={5}
            component={Button}
            onClick={() =>
              navigate(`/ombudsman/${moduleTitle}/protocols?status=4`)
            }
          >
            <CardContent sx={{ width: '100%' }}>
              <Box display="flex" alignItems="center" gap={1} width="100%">
                <Icon fontSize="large">event_outlined</Icon>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="h6">Prorrogadas</Typography>
                  {extendedLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="h6" fontSize="30px">
                      {extended}
                    </Typography>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              borderRadius: '10px',
              width: '100%',
              justifyContent: 'left',
            }}
            elevation={5}
            component={Button}
            onClick={() =>
              navigate(`/ombudsman/${moduleTitle}/protocols?status=6`)
            }
          >
            <CardContent sx={{ width: '100%' }}>
              <Box display="flex" alignItems="center" width="100%" gap={1}>
                <Icon fontSize="large">block_outlined</Icon>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="h6">Indeferidas</Typography>
                  {rejectedLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="h6" fontSize="30px">
                      {rejected}
                    </Typography>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              borderRadius: '10px',
              width: '100%',
              justifyContent: 'left',
            }}
            elevation={5}
            component={Button}
            onClick={() =>
              navigate(`/ombudsman/${moduleTitle}/protocols?status=5`)
            }
          >
            <CardContent sx={{ width: '100%' }}>
              <Box display="flex" alignItems="center" width="100%" gap={1}>
                <Icon fontSize="large">done_all_outlined</Icon>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="h6">Concluídas</Typography>
                  {completedLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="h6" fontSize="30px">
                      {completed}
                    </Typography>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card
            sx={{
              borderRadius: '10px',
              width: '100%',
              justifyContent: 'left',
            }}
            elevation={5}
            component={Button}
            onClick={() =>
              navigate(`/ombudsman/${moduleTitle}/protocols?prioridade=3`)
            }
          >
            <CardContent sx={{ width: '100%' }}>
              <Box display="flex" alignItems="center" width="100%" gap={1}>
                <Icon fontSize="large">priority_high_outlined</Icon>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="h6">Alta Prioridade</Typography>
                  {priorityLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="h6" fontSize="30px">
                      {priority}
                    </Typography>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid container item spacing={2} paddingBottom={2}>
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6" color="#DEC72F">
                  Urgentes
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer
                  component={Paper}
                  variant="outlined"
                  sx={{
                    m: 1,
                    width: 'auto',
                    color: 'dodgerblue',
                    border: '1px solid',
                    borderRadius: '15px',
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" width={50}></TableCell>
                        <TableCell align="center">Identificador</TableCell>
                        <TableCell align="center">Prazo</TableCell>
                        <TableCell align="center">Agrupamento</TableCell>
                        <TableCell align="center">Setor</TableCell>
                        <TableCell align="center">Assunto</TableCell>
                        <TableCell align="center">Tipologia</TableCell>
                        <TableCell align="center">Prioridade</TableCell>
                        <TableCell align="center">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {urgentData.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            borderLeft: `5px solid ${
                              row.status === 1
                                ? '#EB3324'
                                : row.status === 2
                                ? '#FFFF00'
                                : row.status === 3
                                ? '#CBAACB'
                                : row.status === 4
                                ? '#55CBCD'
                                : row.status === 5
                                ? '#8bdaa7'
                                : '#999999'
                            }`,
                          }}
                        >
                          <TableCell align="center">
                            <Link
                              to={`/ombudsman/${moduleTitle}/protocols/detail/${row.id}`}
                            >
                              <IconButton color="primary">
                                <Icon>info_outlined</Icon>
                              </IconButton>
                            </Link>
                          </TableCell>
                          <TableCell align="center">{row.identifier}</TableCell>
                          <TableCell align="center">{row.deadline}</TableCell>
                          <TableCell align="center">
                            {row.grouping_id
                              ? row.grouping_id.name
                              : 'Indefinido'}
                          </TableCell>
                          <TableCell align="center">
                            {row.sector_id ? row.sector_id.name : 'Indefinido'}
                          </TableCell>
                          <TableCell align="center">
                            {row.subject_id
                              ? row.subject_id.name
                              : 'Indefinido'}
                          </TableCell>
                          <TableCell align="center">
                            {row.category_id
                              ? row.category_id.name
                              : 'Indefinido'}
                          </TableCell>
                          <TableCell align="center">
                            {FormatPriority(Number(row.priority))}
                          </TableCell>
                          <TableCell>
                            {FormatProtocolStatus(row.status)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                    {urgentData.length === 0 && !totalLoading && (
                      <caption>Nenhuma manifestação urgente</caption>
                    )}

                    <TableFooter>
                      {totalLoading && (
                        <TableRow>
                          <TableCell colSpan={9}>
                            <LinearProgress
                              variant="indeterminate"
                              sx={{ borderRadius: '50px', height: '5px' }}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableFooter>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6" color="#CF6D68">
                  Atrasadas
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingBottom: 2 }}>
                <TableContainer
                  component={Paper}
                  variant="outlined"
                  sx={{
                    m: 1,
                    width: 'auto',
                    color: 'dodgerblue',
                    border: '1px solid',
                    borderRadius: '15px',
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" width={50}></TableCell>
                        <TableCell align="center">Identificador</TableCell>
                        <TableCell align="center">Prazo</TableCell>
                        <TableCell align="center">Agrupamento</TableCell>
                        <TableCell align="center">Setor</TableCell>
                        <TableCell align="center">Assunto</TableCell>
                        <TableCell align="center">Tipologia</TableCell>
                        <TableCell align="center">Prioridade</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {delayedData.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            borderLeft: '5px solid #EB3324',
                          }}
                        >
                          <TableCell align="center">
                            <Link
                              to={`/ombudsman/${moduleTitle}/protocols/detail/${row.id}`}
                            >
                              <IconButton color="primary">
                                <Icon>info_outlined</Icon>
                              </IconButton>
                            </Link>
                          </TableCell>
                          <TableCell align="center">{row.identifier}</TableCell>
                          <TableCell align="center">{row.deadline}</TableCell>
                          <TableCell align="center">
                            {row.grouping_id
                              ? row.grouping_id.name
                              : 'Indefinido'}
                          </TableCell>
                          <TableCell align="center">
                            {row.sector_id ? row.sector_id.name : 'Indefinido'}
                          </TableCell>
                          <TableCell align="center">
                            {row.subject_id
                              ? row.subject_id.name
                              : 'Indefinido'}
                          </TableCell>
                          <TableCell align="center">
                            {row.category_id
                              ? row.category_id.name
                              : 'Indefinido'}
                          </TableCell>
                          <TableCell align="center">
                            {FormatPriority(Number(row.priority))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                    {delayed === 0 && !delayedLoading && (
                      <caption>Nenhuma manifestação atrasada</caption>
                    )}

                    <TableFooter>
                      {delayedLoading && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <LinearProgress
                              variant="indeterminate"
                              sx={{ borderRadius: '50px', height: '5px' }}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableFooter>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6" color="#55CBCD">
                  Prorrogadas
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer
                  component={Paper}
                  variant="outlined"
                  sx={{
                    m: 1,
                    width: 'auto',
                    color: 'dodgerblue',
                    border: '1px solid',
                    borderRadius: '15px',
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" width={50}></TableCell>
                        <TableCell align="center">Identificador</TableCell>
                        <TableCell align="center">Prazo</TableCell>
                        <TableCell align="center">Agrupamento</TableCell>
                        <TableCell align="center">Setor</TableCell>
                        <TableCell align="center">Assunto</TableCell>
                        <TableCell align="center">Tipologia</TableCell>
                        <TableCell align="center">Prioridade</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {extendedData.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            borderLeft: '5px solid #55CBCD',
                          }}
                        >
                          <TableCell align="center">
                            <Link
                              to={`/ombudsman/${moduleTitle}/protocols/detail/${row.id}`}
                            >
                              <IconButton color="primary">
                                <Icon>info_outlined</Icon>
                              </IconButton>
                            </Link>
                          </TableCell>
                          <TableCell align="center">{row.identifier}</TableCell>
                          <TableCell align="center">{row.deadline}</TableCell>
                          <TableCell align="center">
                            {row.grouping_id
                              ? row.grouping_id.name
                              : 'Indefinido'}
                          </TableCell>
                          <TableCell align="center">
                            {row.sector_id ? row.sector_id.name : 'Indefinido'}
                          </TableCell>
                          <TableCell align="center">
                            {row.subject_id
                              ? row.subject_id.name
                              : 'Indefinido'}
                          </TableCell>
                          <TableCell align="center">
                            {row.category_id
                              ? row.category_id.name
                              : 'Indefinido'}
                          </TableCell>
                          <TableCell align="center">
                            {FormatPriority(Number(row.priority))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                    {extended === 0 && !extendedLoading && (
                      <caption>Nenhuma manifestação prorrogada</caption>
                    )}

                    <TableFooter>
                      {extendedLoading && (
                        <TableCell colSpan={8}>
                          <LinearProgress
                            variant="indeterminate"
                            sx={{ borderRadius: '50px', height: '5px' }}
                          />
                        </TableCell>
                      )}
                    </TableFooter>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </LayoutBaseDePagina>
  )
}

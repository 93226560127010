/* eslint-disable @typescript-eslint/ban-types */
import { Api, ExternalApi } from '../axios-config'

export interface IQuestion {
  id: number
  title: string
  status: boolean
  created_at: string
  updated_at: string
  introduction?: string
  institution_id: number
}

export type IQuestionsTotalCount = {
  data: IQuestion[]
  count: number
  next: string
  previous: string
  results: IQuestion[]
}

const getAllQuestions = async (
  item = '0',
  total = '10',
  filter = '',
  stateId = '',
  institutionId = '',
): Promise<IQuestionsTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/questions/?total=${total}&item=${item}&search=${filter}&id=${stateId}&institution_id=${institutionId}`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return {
        data,
        results: data.results,
        count: data.count,
        next: data.next,
        previous: data.previous,
      }
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getQuestionById = async (id: number): Promise<IQuestion | Error> => {
  try {
    const relativeUrl = `/V1/questions/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return data
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao listar registro',
    )
  }
}

const createQuestion = async (formData: {}): Promise<string | Error> => {
  try {
    const relativeUrl = `/V1/questions/`

    const { data } = await Api.post<IQuestion>(relativeUrl, formData)

    if (data) return data.id ? data.id.toString() : data.title

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const updateQuestionById = async (
  id: number,
  formData: {},
): Promise<IQuestion | Error> => {
  try {
    const relativeUrl = `/V1/questions/${id}/`

    const { data } = await Api.patch<IQuestion>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

const getSurvey = async (identifier: string): Promise<IQuestion[] | Error> => {
  try {
    const relativeUrl = `/V1/externalusers/questions/`

    const { data } = await ExternalApi.post(
      relativeUrl,
      {
        identifier,
      },
      {
        headers: {
          Authorization: `Token ${sessionStorage.getItem('EXTERNAL_TOKEN')}`,
        },
      },
    )

    if (data) return data

    return new Error('Erro ao carregar pesquisa de satisfação')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

interface IReplySurvey {
  detail: string
}

const replySurvey = async (formData: {}): Promise<IReplySurvey | Error> => {
  try {
    const relativeUrl = `/V1/externalusers/answers/create/`

    const { data } = await ExternalApi.post(relativeUrl, formData, {
      headers: {
        Authorization: `Token ${sessionStorage.getItem('EXTERNAL_TOKEN')}`,
      },
    })

    if (data) return data

    return new Error('Erro ao enviar respostas da pesquisa de satisfação')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export {
  getSurvey,
  replySurvey,
  createQuestion,
  getAllQuestions,
  getQuestionById,
  updateQuestionById,
}

/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, useRef } from 'react'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import { useNavigate, useParams } from 'react-router-dom'
import {
  SacProtocol,
  getSacProtocolById,
} from '../../../shared/services/api/sac-services'
import { LegendaSumario, NotificacaoError } from '../../../shared/components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  FormatDate,
  FormatDateTime,
  FormatProtocolStatus,
} from '../../../shared/utils'
import {
  CalendarMonth,
  Check,
  Download,
  ExpandMore,
  MoveDown,
  Sell,
  Send,
  Troubleshoot,
} from '@mui/icons-material'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import { ConteudoSolicitacao } from '../../ombudsman/protocolos/components/ConteudoManifestacao'
import {
  RespostasProtocolo,
  DadosSolicitacao,
  Visualizacoes,
  Encaminhamentos,
  DadosCidadaoSac,
} from './components/display-data'
import { AnaliseModal } from './components/modal/AnaliseModal'
import { ActionButton } from '../../../shared/components/action-button/ActionButton'
import {
  ConcluirModal,
  EncaminharModal,
  TransferirModal,
} from './components/modal'
import { AlterarCatgoriaModal } from './components/modal/AlterarCategoriaModal'
import { DownloadPdfModal } from './components/modal/DownloadPdfModal'
import { ProrrogarModal } from './components/modal/ProrrogarModal'

export const DetalhesProtocoloSAC: FC = () => {
  const update = useRef(true)
  const updateForwards = useRef(true)
  const updateReplies = useRef(true)
  const navigate = useNavigate()
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const { id } = useParams<'id'>()
  const { thisModule } = useModuleContext()

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<SacProtocol>()

  const [day, setDay] = useState(1)
  const [analysis, setAnalysis] = useState(true)
  const [pdfModal, setPdfModal] = useState(false)
  const [concluirModal, setConcluirModal] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [prorrogarModal, setProrrogarModal] = useState(false)
  const [changeTypologie, setChangeTypologie] = useState(false)
  const [encaminharModal, setEncaminharModal] = useState(false)
  const [transferirModal, setTransferirModal] = useState(false)

  useEffect(() => {
    if (id) {
      getSacProtocolById(id).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
          navigate(-1)
        } else {
          setData(result)
          const day = new Date(String(`${result.expires_at} 23:00:00`))
          setDay(day.getDay())
        }
      })
    }
  }, [id, update.current])

  return (
    <LayoutBaseDePagina
      title={
        data ? data.identifier : <Skeleton width={'320px'} height={'5rem'} />
      }
    >
      <Box display="flex" flex="1 1" margin={1} flexDirection="column">
        <Box display="flex" flex="1" justifyContent="space-between">
          {data ? (
            <Tooltip title="status">
              <Typography width="100px">
                {FormatProtocolStatus(
                  Number(data?.status),
                  false,
                  theme.spacing(6),
                  '10px',
                  18,
                )}
              </Typography>
            </Tooltip>
          ) : (
            <Skeleton width={100} height={48} />
          )}

          {data ? (
            <Box display="flex" gap={1}>
              {!data.analysis_started && (
                <ActionButton
                  icon={<Troubleshoot />}
                  onClick={() => setAnalysis(true)}
                  title="Iniciar análise"
                />
              )}
              {data.deadline > -1 &&
                Number(data.status) < 4 &&
                data.deadline < (day === 0 ? 3 : day === 6 ? 2 : 1) &&
                !xsDown && (
                  <ActionButton
                    icon={<CalendarMonth />}
                    onClick={() => setProrrogarModal(true)}
                    title="Prorrogar"
                  />
                )}
              {thisModule?.profile === 2 &&
                data.analysis_started &&
                data.status < 5 && (
                  <ActionButton
                    icon={<Check />}
                    onClick={() => setConcluirModal(true)}
                    title="Concluir"
                  />
                )}
              {thisModule?.profile === 2 && data.analysis_started && (
                <ActionButton
                  icon={<Send />}
                  onClick={() => setEncaminharModal(true)}
                  title="Enviar por e-mail"
                />
              )}
              {thisModule?.profile === 2 &&
                data.status < 5 &&
                data.analysis_started && (
                  <ActionButton
                    icon={<MoveDown />}
                    onClick={() => setTransferirModal(true)}
                    title="Transferir (agrupamento - setor - assunto)"
                  />
                )}
              {thisModule?.profile === 2 &&
                data.analysis_started &&
                data.status < 5 &&
                !xsDown && (
                  <ActionButton
                    icon={<Sell />}
                    onClick={() => setChangeTypologie(true)}
                    title="Alterar categoria"
                  />
                )}
              <ActionButton
                icon={<Download />}
                title="Download PDF"
                onClick={() => setPdfModal(true)}
              />
            </Box>
          ) : (
            <Box display="flex" gap={1}>
              <Skeleton
                id="concluir-button-skeleton"
                width="45px"
                height="45px"
                sx={{ borderRadius: '50px' }}
              />
              <Skeleton
                id="concluir-button-skeleton"
                width="45px"
                height="45px"
                sx={{ borderRadius: '50px' }}
              />
              <Skeleton
                id="concluir-button-skeleton"
                width="45px"
                height="45px"
                sx={{ borderRadius: '50px' }}
              />
              <Skeleton
                id="concluir-button-skeleton"
                width="45px"
                height="45px"
                sx={{ borderRadius: '50px' }}
              />
            </Box>
          )}
        </Box>
        {data && xsDown && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex="1"
            flexDirection="column"
            gap={2}
            mt={2}
          >
            {data.deadline > -1 &&
              Number(data.status) < 4 &&
              data.deadline < (day === 0 ? 3 : day === 6 ? 2 : 1) && (
                <Button
                  variant="outlined"
                  onClick={() => setProrrogarModal(true)}
                  fullWidth
                  startIcon={<CalendarMonth />}
                >
                  prorrogar
                </Button>
              )}
            {thisModule?.profile === 2 &&
              data.analysis_started &&
              data.status < 5 && (
                <Button
                  variant="outlined"
                  onClick={() => setChangeTypologie(true)}
                  fullWidth
                  startIcon={<Sell />}
                >
                  Alterar categoria
                </Button>
              )}
          </Box>
        )}
        <Divider sx={{ mt: 1 }} />

        {isLoading && <LinearProgress sx={{ mt: 2 }} />}

        <div
          style={{
            display: 'grid',
            marginRight: '8px',
            gridTemplateColumns: xsDown
              ? '100%'
              : smDown
              ? '50% 50%'
              : '66.6% 33.4%',
            gridTemplateRows: 'fit-content',
            columnGap: '8px',
          }}
        >
          {/* First Column */}
          <Grid container direction="column" mt={1}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                {/* Informações */}
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid container spacing={1} direction="column">
                      <Grid container item spacing={1}>
                        <Grid item xs={12}>
                          <LegendaSumario
                            icon="toc"
                            name="Informações do protocolo"
                          />
                        </Grid>
                        <Grid container item spacing={1} direction="row">
                          <Grid item>
                            <Typography>Criado: </Typography>
                          </Grid>
                          <Grid item>
                            <Typography>
                              {FormatDateTime(data?.created_at, 'Indefinido')}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Divider orientation="vertical" />
                          </Grid>
                          <Grid item>
                            <Typography>Prazo: </Typography>
                          </Grid>
                          <Grid item>
                            <Typography>
                              {FormatDate(data?.expires_at, 'Indefinido')}
                            </Typography>
                          </Grid>
                          {Number(data?.status) > 4 && (
                            <Grid item>
                              <Divider orientation="vertical" />
                            </Grid>
                          )}
                          {Number(data?.status) > 4 && (
                            <Grid container item spacing={1}>
                              <Grid item>
                                <Typography>Finalizado:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  {FormatDateTime(
                                    data?.finished_at,
                                    'Indefinido',
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ConteudoSolicitacao protocolData={data} />
                  </AccordionDetails>
                </Accordion>

                {/* Respostas */}
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <LegendaSumario icon="message_outlined" name="Respostas" />
                  </AccordionSummary>
                  <AccordionDetails>
                    <RespostasProtocolo
                      sendDisabled={data ? data.status > 4 : true}
                      protocolId={data?.id}
                      secondUpdate={updateReplies}
                      secret={data?.secret}
                    />
                  </AccordionDetails>
                </Accordion>

                {/* Encaminhamentos */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <LegendaSumario
                      icon="forward_to_inbox"
                      name="Encaminhamentos"
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Encaminhamentos update={updateForwards} />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>

          {/* Second Column */}
          <Grid container direction="column" mt={1}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid container spacing={1} direction="column">
                      <Grid container item spacing={1}>
                        <Grid item xs={12}>
                          <LegendaSumario icon="toc" name="Dados" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DadosSolicitacao
                      protocolData={data}
                      setProtocolData={setData}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <LegendaSumario icon="person" name="Cidadão" />
                  </AccordionSummary>
                  <AccordionDetails>
                    <DadosCidadaoSac protocolData={data} />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <LegendaSumario
                      icon="visibility_outlined"
                      name="Visualizações"
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Visualizacoes />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
      {data && (
        <>
          <DownloadPdfModal
            onClose={() => setPdfModal(false)}
            protocolSecret={data.secret}
            protocolId={data.id}
            show={pdfModal}
          />
          <AnaliseModal
            show={
              analysis && !data.analysis_started && thisModule?.profile !== 3
            }
            onClose={() => setAnalysis(false)}
            setData={setData}
          />
          <EncaminharModal
            onClose={() => setEncaminharModal(false)}
            update={updateForwards}
            show={encaminharModal}
            protocolId={data.id}
          />

          {thisModule?.profile === 2 && (
            <>
              <ConcluirModal
                onClose={() => setConcluirModal(false)}
                protocolId={data.id}
                show={concluirModal}
                setData={setData}
              />
              <TransferirModal
                onClose={() => setTransferirModal(false)}
                updateReplies={updateReplies}
                setProtocolData={setData}
                show={transferirModal}
                protocolId={data.id}
              />
              <AlterarCatgoriaModal
                onClose={() => setChangeTypologie(false)}
                updateReplies={updateReplies}
                setProtocolData={setData}
                show={changeTypologie}
                protocolId={data.id}
              />
              <ProrrogarModal
                onClose={() => setProrrogarModal(false)}
                setprotocolData={setData}
                show={prorrogarModal}
                protocolId={data.id}
              />
            </>
          )}
        </>
      )}
    </LayoutBaseDePagina>
  )
}

import { Schema } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'

export interface IFormData {
  name: string
  icon: number
  status: boolean
  institution: number
  customer_service: number
}

const formValidationSchema: Schema<IFormData> = yup.object().shape({
  status: yup.boolean().default(true),
  name: yup.string().required().min(3),
  icon: yup.number().required().positive(),
  institution: yup.number().required().positive(),
  customer_service: yup.number().required().positive(),
})

export { formValidationSchema }

import { Api } from '../axios-config'

export interface ILoginParameters {
  username: string
  password: string
}

export interface IUserData {
  token: string
  ombudsman_profile: string
  ci_profile: string
  user_id: number
  username: string
  is_active: boolean
  user_modules: [key: string]
  is_superuser: boolean
  is_confirmed: boolean
  institution_id: number
  profile_picture: string
  institution_logo: string
  institution_modules: [key: string]
}

export interface IExternalUserData {
  token: string
  name: string
  id: number
}

const loginAuth = async (
  formData: ILoginParameters,
): Promise<IUserData | Error> => {
  try {
    const relativeUrl = '/V1/auth'

    const { data } = await Api.post<IUserData>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao fazer login')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao fazer login',
    )
  }
}

const externalLogin = async (
  formData: object,
): Promise<IExternalUserData | Error> => {
  try {
    const relativeUrl = '/V1/external_auth/'

    const { data } = await Api.post<IExternalUserData>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao fazer login')
  } catch (error) {
    console.error(error)
    return Error((error as { message: string }).message)
  }
}

export { loginAuth, externalLogin }

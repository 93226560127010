import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { IProtocols } from '../../../../shared/services/api'
import { IModule, useAuthContext } from '../../../../shared/contexts'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'

interface IDetails {
  protocolData?: IProtocols
}

export const DetalhesCidadao: React.FC<IDetails> = ({ protocolData }) => {
  const { user } = useAuthContext()
  const { currentModule, moduleTitle } = useModuleContext()

  const [thisModule, setThisModule] = React.useState<IModule>()

  React.useEffect(() => {
    setThisModule(
      user?.modules.find(
        (mdl) => mdl.type === currentModule && mdl.title === moduleTitle,
      ),
    )
  }, [user?.modules, currentModule, moduleTitle])

  return (
    <Box display="flex">
      {protocolData?.anonymous ? (
        <Typography variant="h6" textAlign="center">
          Manifestação Anônima
        </Typography>
      ) : protocolData?.secret ? (
        <Box display="flex">
          {thisModule?.profile === 2 ? (
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Nome:</Typography>
                </Grid>
                <Grid item>
                  <Typography>{protocolData.external_user_id?.name}</Typography>
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Email:</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {protocolData.external_user_id?.email
                      ? protocolData.external_user_id?.email
                      : 'Email não cadastrado'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Gênero:</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {protocolData.external_user_id?.gender === 1
                      ? 'Masculino'
                      : protocolData.external_user_id?.gender === 2
                      ? 'Feminino'
                      : 'Não informado'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Escolaridade:</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {protocolData.external_user_id?.escolarity === 1
                      ? 'Não alfabetizado'
                      : protocolData.external_user_id?.escolarity === 2
                      ? 'Fundamental incompleto'
                      : protocolData.external_user_id?.escolarity === 3
                      ? 'Fundamental completo'
                      : protocolData.external_user_id?.escolarity === 4
                      ? 'Ensino médio incompleto'
                      : protocolData.external_user_id?.escolarity === 5
                      ? 'Ensino médio completo'
                      : protocolData.external_user_id?.escolarity === 6
                      ? 'Técnico incompleto'
                      : protocolData.external_user_id?.escolarity === 7
                      ? 'Técnico completo'
                      : protocolData.external_user_id?.escolarity === 8
                      ? 'Superior incompleto'
                      : protocolData.external_user_id?.escolarity === 9
                      ? 'Superior completo'
                      : 'Não informado'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="h6" textAlign="center">
              Manifestação Sigilosa
            </Typography>
          )}
        </Box>
      ) : protocolData ? (
        <Grid container spacing={2} direction="column">
          <Grid container item spacing={1}>
            <Grid item>
              <Typography>Nome:</Typography>
            </Grid>
            <Grid item>
              <Typography>{protocolData.external_user_id?.name}</Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item>
              <Typography>Email:</Typography>
            </Grid>
            <Grid item>
              <Typography>
                {protocolData.external_user_id?.email
                  ? protocolData.external_user_id?.email
                  : 'Email não cadastrado'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item>
              <Typography>Gênero:</Typography>
            </Grid>
            <Grid item>
              <Typography>
                {protocolData.external_user_id?.gender === 1
                  ? 'Masculino'
                  : protocolData.external_user_id?.gender === 2
                  ? 'Feminino'
                  : 'Não informado'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item>
              <Typography>Escolaridade:</Typography>
            </Grid>
            <Grid item>
              <Typography>
                {protocolData.external_user_id?.escolarity === 1
                  ? 'Não alfabetizado'
                  : protocolData.external_user_id?.escolarity === 2
                  ? 'Fundamental incompleto'
                  : protocolData.external_user_id?.escolarity === 3
                  ? 'Fundamental completo'
                  : protocolData.external_user_id?.escolarity === 4
                  ? 'Ensino médio incompleto'
                  : protocolData.external_user_id?.escolarity === 5
                  ? 'Ensino médio completo'
                  : protocolData.external_user_id?.escolarity === 6
                  ? 'Técnico incompleto'
                  : protocolData.external_user_id?.escolarity === 7
                  ? 'Técnico completo'
                  : protocolData.external_user_id?.escolarity === 8
                  ? 'Superior incompleto'
                  : protocolData.external_user_id?.escolarity === 9
                  ? 'Superior completo'
                  : 'Não informado'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Typography>Não foi possível identificar o usuário</Typography>
      )}
    </Box>
  )
}

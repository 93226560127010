import { Grid, LinearProgress, Typography } from '@mui/material'
import { FC } from 'react'
import { SacProtocol } from '../../../../../shared/services/api/sac-services'
import { Link } from 'react-router-dom'
import { useModuleContext } from '../../../../../shared/contexts/ModulesContext'

interface DadosCidadaoSacProps {
  protocolData: SacProtocol | undefined
}

export const DadosCidadaoSac: FC<DadosCidadaoSacProps> = ({ protocolData }) => {
  const { thisModule } = useModuleContext()

  return (
    <Grid container spacing={2} direction="column">
      {!protocolData && <LinearProgress />}
      <Grid container item spacing={2}>
        <Grid item>
          {thisModule?.profile === 2 && (
            <Link
              to={`/customer-service/${thisModule.title}/externalusers/details/${protocolData?.external_user.id}`}
            >
              <Typography>Acessar perfil</Typography>
            </Link>
          )}
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Nome:</Typography>
        </Grid>
        <Grid item>
          <Typography>{protocolData?.external_user.name}</Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>E-mail:</Typography>
        </Grid>
        <Grid item>
          <Typography>{protocolData?.external_user.email}</Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Documento:</Typography>
        </Grid>
        <Grid item>
          <Typography>{protocolData?.external_user.document_number}</Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Número:</Typography>
        </Grid>
        <Grid item>
          <Typography>{protocolData?.external_user.number}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

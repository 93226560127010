import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'
import { FC, MutableRefObject } from 'react'
import { VForm, VTextArea, useVForm } from '../../../shared/forms'
import { extendProtocol } from '../../../shared/services/api'

interface IProrrogarModal {
  show: boolean
  onClose: () => void
  update: MutableRefObject<boolean>
  protocolId: number
}

interface IFormdata {
  protocol_id: number
  justify: string
}

export const ProrrogarModal: FC<IProrrogarModal> = ({
  show,
  protocolId,
  onClose,
  update,
}) => {
  const { formRef } = useVForm()

  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const handleSubmit = (formData: IFormdata) => {
    formData.protocol_id = protocolId

    extendProtocol(protocolId, formData).then((result) => {
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        NotificacaoSucesso('Manifestação Prorrogada com sucesso')
        formRef.current?.reset()
        update.current = true
        onClose()
      }
    })
  }

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Box display="flex" width="100%" flexDirection="column">
          <Typography variant="h6" textAlign="center">
            Prorrogar
          </Typography>
          <Divider />
          <Box margin={2}>
            <VForm onSubmit={handleSubmit}>
              <Grid container direction="column" spacing={2}>
                <Grid container item>
                  <Grid item display="flex" xs={12} justifyContent="center">
                    <VTextArea
                      name="justify"
                      minRows={4}
                      maxRows={8}
                      required
                      placeholder=" Justifique o motivo da prorrogação de prazo
  * Essa justificativa é visível para o manifestante
                      "
                      ariaLabel="Descrição"
                      style={{
                        width: '95%',
                        borderRadius: '5px',
                        fontVariant: 'historical-forms',
                        font: 'message-box',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Box
                display="flex"
                flex="1"
                gap={1}
                justifyContent="center"
                marginTop={2}
              >
                <Button
                  variant="contained"
                  disableElevation
                  type="submit"
                  sx={{ borderRadius: '50px' }}
                >
                  confirmar
                </Button>
                <Button
                  sx={{ borderRadius: '50px' }}
                  onClick={() => {
                    formRef.current?.reset()
                    onClose()
                  }}
                >
                  cancelar
                </Button>
              </Box>
            </VForm>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

import { FC, MutableRefObject, useState } from 'react'
import {
  Box,
  Modal,
  Paper,
  useTheme,
  Grid,
  useMediaQuery,
  FormControlLabel,
  Button,
  LinearProgress,
} from '@mui/material'

import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { ModalHeader } from '../../../../shared/components/modal/Header'
import {
  SacGroupingList,
  createSacGrouping,
  updateSacGroupingById,
} from '../../../../shared/services/api/sac-services'
import { VForm, VSwitch, VTextField, useVForm } from '../../../../shared/forms'
import { AutocompleteIcons } from '../components/AutocompleteIcons'
import { Cancel, Save } from '@mui/icons-material'
import { IFormData, formValidationSchema } from './validate'
import { ValidationError } from 'yup'
import { useAuthContext } from '../../../../shared/contexts'
import { AutocompleteSac } from '../components/AutocompleteSacs'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'

interface FormularioProps {
  show: boolean
  create: boolean
  onClose(): void
  institution: number
  data?: SacGroupingList
  update: MutableRefObject<boolean>
}

export const Formulario: FC<FormularioProps> = ({
  data,
  show,
  create,
  update,
  onClose,
  institution,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { user } = useAuthContext()
  const { formRef } = useVForm()
  const { thisModule } = useModuleContext()

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    formRef.current?.reset()
    onClose()
  }

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)
    if (create) {
      formData.institution = institution
      if (thisModule?.profile !== 0 && thisModule) {
        formData.customer_service = thisModule.id
      }
    } else if (data) {
      formData.institution = data.institution
      formData.customer_service = data.customer_service.id
    }

    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        if (create) {
          createSacGrouping(formData).then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              update.current = true
              NotificacaoSucesso('Agrupamento criado com sucesso!')
              handleClose()
            }
          })
        } else {
          if (data) {
            updateSacGroupingById(data?.id, formData).then((result) => {
              setIsLoading(false)
              if (result instanceof Error) {
                NotificacaoError(result.message)
              } else {
                update.current = true
                NotificacaoSucesso('Agrupamento atualizado com sucesso!')
                handleClose()
              }
            })
          }
        }
      })
      .catch((errors: ValidationError) => {
        setIsLoading(false)
        const validationErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validationErrors[error.path] = error.message
        })
        formRef.current?.setErrors(validationErrors)
      })
  }

  return (
    <Modal onClose={handleClose} open={show}>
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader
          showCloseButton
          onClose={handleClose}
          title={create ? 'Novo agrupamento' : 'Editar agrupamento'}
        />
        {isLoading && <LinearProgress sx={{ mt: 2 }} />}
        <Box margin={1}>
          <VForm
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={
              create
                ? undefined
                : {
                    ...data,
                    icon: data?.icon.id,
                    customer_service: data?.customer_service.id,
                  }
            }
          >
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    required
                    fullWidth
                    name="name"
                    size="small"
                    label="Nome"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutocompleteIcons name="icon" />
                </Grid>
              </Grid>

              {user?.modules[0].profile === 0 && (
                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <AutocompleteSac
                      institution={institution}
                      name="customer_service"
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container item>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormControlLabel
                    label="Status"
                    control={<VSwitch name="status" size="small" />}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box
              display="flex"
              flex="1"
              mt={2}
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Button
                disableElevation
                variant="contained"
                startIcon={<Save />}
                type="submit"
              >
                salvar
              </Button>
              <Button
                variant="outlined"
                startIcon={<Cancel />}
                onClick={handleClose}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

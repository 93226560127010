interface MenuOption {
  label: string
  icon: string
  path: string
}

export const admin = (title: string): MenuOption[] => {
  return [
    {
      icon: 'leaderboard',
      label: 'Dashboard',
      path: `/customer-service/${title}/dashboard`,
    },
    {
      icon: 'apartment',
      label: 'Clientes',
      path: `/customer-service/${title}/clients`,
    },
    {
      icon: 'maps_ugc',
      label: 'Módulos S.A.C',
      path: `/customer-service/${title}/modules`,
    },
    {
      label: 'Administradores',
      icon: 'manage_accounts',
      path: `/customer-service/${title}/managers`,
    },
    {
      label: 'Agrupamentos',
      icon: 'apps',
      path: `/customer-service/${title}/groupings`,
    },
    {
      label: 'Setores',
      icon: 'safety_divider',
      path: `/customer-service/${title}/sectors`,
    },
    {
      label: 'Assuntos',
      icon: 'subject',
      path: `/customer-service/${title}/subjects`,
    },
    {
      label: 'Categorias',
      icon: 'sell',
      path: `/customer-service/${title}/categories`,
    },
    {
      label: 'Perfis S.A.C',
      icon: 'group',
      path: `/customer-service/${title}/users`,
    },
    {
      label: 'Usuários Externos',
      icon: 'contacts',
      path: `/customer-service/${title}/externalusers`,
    },
    {
      label: 'Perguntas',
      icon: 'question_answer',
      path: `/customer-service/${title}/questions`,
    },
    {
      label: 'Slides',
      icon: 'ondemand_video',
      path: `/customer-service/${title}/slides`,
    },
    {
      label: 'Respostas Pré Definidas',
      icon: 'wrap_text',
      path: `/customer-service/${title}/default_replies`,
    },
    {
      label: 'Ícones',
      icon: 'category',
      path: `/customer-service/${title}/icons`,
    },
    {
      label: 'Ramo de Atividades',
      icon: 'lan',
      path: `/customer-service/${title}/workfields`,
    },
  ]
}

import { FC } from 'react'
import {
  TableContainer as MUITableContainer,
  TableContainerProps,
  Paper,
} from '@mui/material'

export const TableContainer: FC<TableContainerProps> = ({ ...rest }) => {
  return (
    <MUITableContainer
      {...rest}
      component={Paper}
      variant="outlined"
      sx={{
        m: 1,
        width: 'auto',
        color: 'dodgerblue',
        border: '1px solid',
        borderRadius: '15px',
      }}
    />
  )
}

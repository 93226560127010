import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Scope } from '@unform/core'
import { FC } from 'react'
import { VTextField } from '../../../../shared/forms'
import { formatPhone } from '../../../../shared/utils'
import { AddCircle, RemoveCircle } from '@mui/icons-material'

interface SacCellPhonesProps {
  isLoading: boolean
  cellPhones: number
  setCellPhones: (e: number) => void
}

export const SacCellPhones: FC<SacCellPhonesProps> = ({
  isLoading,
  cellPhones,
  setCellPhones,
}) => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid
      container
      item
      spacing={2}
      display="flex"
      alignItems="center"
      direction="column"
    >
      <Box
        display="flex"
        flexDirection="column"
        width={smDown ? '99%' : '66%'}
        borderRadius="10px"
        padding={2}
        gap={1}
        alignItems="center"
      >
        <Typography variant="h4" textAlign="center">
          Celulars Fixos
        </Typography>
        <Grid
          container
          item
          display="flex"
          flexDirection="column"
          justifyContent="center"
          direction="column"
          spacing={1}
        >
          <Grid container item xs={12} justifyContent="center">
            <Scope path="sac_cellphones[0]">
              <Grid item xs={11} sm={cellPhones === 1 ? 4 : 5}>
                <VTextField
                  fullWidth
                  size="small"
                  name="title"
                  label="Nome"
                  disabled={isLoading}
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                  borderBottomRight="0px"
                  borderTopRight="0px"
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xs={11} sm={7}>
                <VTextField
                  fullWidth
                  size="small"
                  name="number"
                  label="Celular"
                  placeholder="(DDD) 91234-5678"
                  disabled={isLoading}
                  borderBottomLeft="0px"
                  borderTopLeft="0px"
                  formatData={formatPhone}
                  inputProps={{ maxLength: 14 }}
                />
              </Grid>
              {cellPhones === 1 && (
                <Grid item xs={1} display="flex" justifyContent="center">
                  <Tooltip title="adcionar celular">
                    <IconButton onClick={() => setCellPhones(cellPhones + 1)}>
                      <AddCircle color="success" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Scope>
          </Grid>
          {Number(cellPhones) >= 2 && (
            <Grid container item xs={12} justifyContent="center">
              <Scope path="sac_cellphones[1]">
                <Grid item xs={11} sm={cellPhones === 2 ? 4 : 5}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="title"
                    label="Nome"
                    disabled={isLoading}
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    borderBottomRight="0px"
                    borderTopRight="0px"
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={11} sm={7}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="number"
                    label="Celular"
                    placeholder="(DDD) 91234-5678"
                    disabled={isLoading}
                    borderBottomLeft="0px"
                    borderTopLeft="0px"
                    formatData={formatPhone}
                    inputProps={{ maxLength: 14 }}
                  />
                </Grid>
                {cellPhones === 2 && (
                  <Box display="flex" flexDirection="row">
                    <Tooltip title="adcionar celular">
                      <IconButton onClick={() => setCellPhones(cellPhones + 1)}>
                        <AddCircle color="success" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="remover celular">
                      <IconButton onClick={() => setCellPhones(cellPhones - 1)}>
                        <RemoveCircle color="error" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Scope>
            </Grid>
          )}
          {Number(cellPhones) >= 3 && (
            <Grid container item xs={12} justifyContent="center">
              <Scope path="sac_cellphones[2]">
                <Grid item xs={11} sm={cellPhones === 3 ? 4 : 5}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="title"
                    label="Nome"
                    disabled={isLoading}
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    borderBottomRight="0px"
                    borderTopRight="0px"
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={11} sm={7}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="number"
                    label="Celular"
                    placeholder="(DDD) 91234-5678"
                    disabled={isLoading}
                    borderBottomLeft="0px"
                    borderTopLeft="0px"
                    formatData={formatPhone}
                    inputProps={{ maxLength: 14 }}
                  />
                </Grid>
                {cellPhones === 3 && (
                  <Box display="flex" flexDirection="row">
                    <Tooltip title="adcionar celular">
                      <IconButton onClick={() => setCellPhones(cellPhones + 1)}>
                        <AddCircle color="success" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="remover celular">
                      <IconButton onClick={() => setCellPhones(cellPhones - 1)}>
                        <RemoveCircle color="error" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Scope>
            </Grid>
          )}
          {Number(cellPhones) >= 4 && (
            <Grid container item xs={12} justifyContent="center">
              <Scope path="sac_cellphones[3]">
                <Grid item xs={11} sm={cellPhones === 4 ? 4 : 5}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="title"
                    label="Nome"
                    disabled={isLoading}
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    borderBottomRight="0px"
                    borderTopRight="0px"
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={11} sm={7}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="number"
                    label="Celular"
                    placeholder="(DDD) 91234-5678"
                    disabled={isLoading}
                    borderBottomLeft="0px"
                    borderTopLeft="0px"
                    formatData={formatPhone}
                    inputProps={{ maxLength: 14 }}
                  />
                </Grid>
                {cellPhones === 4 && (
                  <Box display="flex" flexDirection="row">
                    <Tooltip title="adcionar celular">
                      <IconButton onClick={() => setCellPhones(cellPhones + 1)}>
                        <AddCircle color="success" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="remover celular">
                      <IconButton onClick={() => setCellPhones(cellPhones - 1)}>
                        <RemoveCircle color="error" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Scope>
            </Grid>
          )}
          {cellPhones === 5 && (
            <Grid container item xs={12} justifyContent="center">
              <Scope path="sac_cellphones[4]">
                <Grid item xs={11} sm={cellPhones === 5 ? 4 : 5}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="title"
                    label="Nome"
                    disabled={isLoading}
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    borderBottomRight="0px"
                    borderTopRight="0px"
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={11} sm={7}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="number"
                    label="Celular"
                    placeholder="(DDD) 91234-5678"
                    disabled={isLoading}
                    borderBottomLeft="0px"
                    borderTopLeft="0px"
                    formatData={formatPhone}
                    inputProps={{ maxLength: 14 }}
                  />
                </Grid>
                {cellPhones === 5 && (
                  <Grid item xs={1} display="flex" justifyContent="center">
                    <Tooltip title="remover celular">
                      <IconButton onClick={() => setCellPhones(cellPhones - 1)}>
                        <RemoveCircle color="error" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Scope>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  )
}

import { useState, useMemo, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  Icon,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDaListagem,
  NotificacaoError,
} from '../../../shared/components'
import { useDebounce } from '../../../shared/hooks'
import {
  ISlide,
  getAllSlides,
} from '../../../shared/services/api/slides-services/SlidesServices'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'
import { Formulario } from './Formulario'
import { IModule, useAuthContext } from '../../../shared/contexts'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const Slides: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [institution, setInstitution] = useState('')
  const [viewForm, setViewForm] = useState(false)
  const [rows, setRows] = useState<ISlide[]>([])
  const [create, setCreate] = useState(true)
  const [edit, setEdit] = useState<ISlide>()

  const [searchParams, setSearchParams] = useSearchParams()

  const { debounce } = useDebounce(1200)
  const { user } = useAuthContext()
  const isAdmin = () => {
    return user?.modules[0].profile === 0
  }

  const update = useRef(false)

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const toggleSaved = (e: number | undefined) => {
    setInstitution(e ? String(e) : '')
  }

  const handleForm = (create: boolean) => {
    setCreate(create)
    if (viewForm) setViewForm(false)
    else setViewForm(true)
  }

  useEffect(() => {
    setIsLoading(true)
    if (isAdmin())
      setInstitution(localStorage.getItem('ADM_SELECTED_INSTITUTION') || '')
    else setInstitution('')

    debounce(() => {
      getAllSlides(institution, busca).then((result) => {
        update.current = false
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setRows(result)
        }
      })
    })
  }, [busca, institution, debounce, update.current])

  const [thisModule, setThisModule] = useState<IModule>()

  const { currentModule, moduleTitle } = useModuleContext()

  useEffect(() => {
    setThisModule(
      user?.modules.find(
        (mdl) => mdl.type === currentModule && mdl.title === moduleTitle,
      ),
    )
  }, [user?.modules, currentModule, moduleTitle])

  return (
    <LayoutBaseDePagina
      selectInstitution
      title="Slides"
      autoCompleteChange={toggleSaved}
      filter={
        <FerramentasDaListagem
          mostrarInputDaBusca
          textoBotaoNovo="Novo"
          mostrarBotaoNovo={thisModule?.hr_manager || thisModule?.profile === 0}
          aoClicarEmNovo={() => handleForm(true)}
          textoDaBusca={busca}
          aoMudarTextoDaBusca={(texto) =>
            setSearchParams({ busca: texto }, { replace: true })
          }
        />
      }
    >
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{
          m: 1,
          width: 'auto',
          color: 'dodgerblue',
          border: '1px solid',
          borderRadius: '15px',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Imagem</TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setEdit(row)
                      handleForm(false)
                    }}
                  >
                    <Icon>info_outlined</Icon>
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  {<img src={row.image} height={90} alt="slide" />}
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.created_at, 'Indefinido')}
                </TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={5}>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{ height: '5px', borderRadius: '50px' }}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <Formulario
        onClose={() => setViewForm(false)}
        show={viewForm}
        create={create}
        slideData={edit}
        update={update}
      />
    </LayoutBaseDePagina>
  )
}

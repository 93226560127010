/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import { FC, Fragment } from 'react'
import { ISectorUserDetail } from '../../../../shared/services/api'
import { FormatDateTime, FormatStatus } from '../../../../shared/utils'
import { useNavigate } from 'react-router-dom'
import { ShowData } from '../../../../shared/components'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'

interface IDisplayDataProps {
  data: ISectorUserDetail | undefined
  handleReSendMail: () => void
}

export const DisplayData: FC<IDisplayDataProps> = ({
  data,
  handleReSendMail,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const { moduleTitle } = useModuleContext()

  return (
    <Grid container direction="column" spacing={2}>
      {!data?.is_confirmed && (
        <Grid container item spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              sx={{ borderRadius: '50px' }}
              onClick={handleReSendMail}
            >
              Reenviar email de confirmação
            </Button>
          </Grid>
        </Grid>
      )}

      <ShowData
        field="Status"
        value={FormatStatus(Boolean(data?.is_active))}
        valueGridProps={{ width: '70px' }}
      />

      <ShowData
        field="Confirmado"
        value={FormatStatus(data?.is_confirmed || false, 'Sim', 'Não')}
        valueGridProps={{ width: '50px' }}
      />

      <ShowData field="Nome" value={data?.username} />

      <ShowData
        field="Perfil"
        value={
          data?.ombudsman_profile === 1
            ? 'Auxiliar'
            : data?.ombudsman_profile === 2
            ? 'Ouvidor'
            : 'Auditor'
        }
      />

      <ShowData
        field="Gestor RH"
        value={FormatStatus(Boolean(data?.hr_manager), 'Sim', 'Não')}
        valueGridProps={{ width: '50px' }}
      />

      <ShowData
        field="Gestor de dados"
        value={FormatStatus(Boolean(data?.data_manager), 'Sim', 'Não')}
        valueGridProps={{ width: '50px' }}
      />

      <ShowData
        field="Setores"
        valueGridProps={{
          sx: {
            display: 'flex',
            gap: 1,
          },
        }}
        useTypography={false}
        value={
          <Fragment>
            {data?.sectors?.length === 0 && (
              <Typography>Nenhum setor cadastrado</Typography>
            )}
            {data?.sectors?.map((sector: any, index: number) => (
              <Box
                key={index}
                display="flex"
                component={ButtonBase}
                borderRadius="15px"
                maxWidth="120px"
                height={theme.spacing(3)}
                justifyContent="center"
                alignItems="center"
                color="black"
                bgcolor={'dodgerblue'}
                onClick={() =>
                  navigate(
                    `/ombudsman/${moduleTitle}/sectors/detail/${sector[1]}`,
                  )
                }
              >
                <Typography
                  margin={1}
                  variant="subtitle2"
                  component="h6"
                  textOverflow="ellipsis"
                  noWrap
                >
                  {sector[0]}
                </Typography>
              </Box>
            ))}
          </Fragment>
        }
      />

      <ShowData field="Email" value={data?.email} />

      <ShowData field="Último Login" value={FormatDateTime(data?.last_login)} />

      <ShowData field="Documento" value={data?.document_number} />

      <ShowData field="Quantidade de logins" value={data?.sign_in_count} />
    </Grid>
  )
}

import { FC } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FormBoxStyle, LegendaSumario } from '../../../../../shared/components'
import { IProtocols } from '../../../../../shared/services/api'
import { FormatProtocolStatus } from '../../../../../shared/utils'
import { ModalHeader } from '../../../../../shared/components/modal/Header'
import { Cancel, ExpandMore } from '@mui/icons-material'
import { AnonymousReplies } from './AnonymousReplies'

interface IModalViewProps {
  show: boolean
  onClose: () => void
  protocolData: IProtocols | undefined
}

export const ModalView: FC<IModalViewProps> = ({
  onClose,
  show,
  protocolData,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  return (
    <Modal open={show} aria-describedby="modal-title">
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader
          onClose={onClose}
          title={protocolData?.identifier || ''}
          buttonText="Fechar"
        />
        <Divider variant="middle" />
        <Box margin={1}>
          <Box display="flex" flex="1" justifyContent="center">
            <Tooltip title="status">
              <Typography width="100px">
                {FormatProtocolStatus(
                  Number(protocolData?.status),
                  false,
                  theme.spacing(6),
                  '10px',
                  18,
                )}
              </Typography>
            </Tooltip>
          </Box>
          <Box marginTop={1}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <LegendaSumario
                  fontSize={xsDown ? 'small' : undefined}
                  icon="text_snippet"
                  name="Dados da manifestação"
                />
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} direction="column">
                  <Grid
                    item
                    container
                    spacing={1}
                    display="flex"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6">Agrupamento: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{protocolData?.grouping_id?.name}</Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    spacing={1}
                    display="flex"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6">Setor: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{protocolData?.sector_id?.name}</Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    spacing={1}
                    display="flex"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6">Assunto: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{protocolData?.subject_id?.name}</Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    spacing={1}
                    display="flex"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6">Tipologia: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{protocolData?.category_id?.name}</Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    spacing={1}
                    display="flex"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6">Tipologia: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{protocolData?.category_id?.name}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Descrição:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography paragraph>
                        {protocolData?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <LegendaSumario
                  fontSize={xsDown ? 'small' : undefined}
                  icon="chat"
                  name="Respostas"
                />
              </AccordionSummary>
              <AccordionDetails>
                <AnonymousReplies protocolData={protocolData} />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <Box display="flex" flex="1" justifyContent="center" marginTop={2}>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ borderRadius: '50px' }}
            startIcon={<Cancel />}
          >
            fechar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

import { Api } from '../axios-config'

export interface ISubjectHistory {
  field: string
  type: '+' | '~'
  date: string
  user?: string
  old?: string
  new?: string
}

export interface ISectorsInSubject {
  name: string
  date: string
  user: number
  type: '+' | '-'
  sector_id: string
}

const getSubjectHistory = async (
  subjectId: number,
): Promise<ISubjectHistory[] | Error> => {
  try {
    const relativeUrl = `/V1/subjects/${subjectId}/historical/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar histórico')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getSectorsSubjectHistory = async (
  subjectId: number,
): Promise<ISectorsInSubject[] | Error> => {
  try {
    const relativeUrl = `/V1/subjects/${subjectId}/historical/sectors/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar histórico')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export { getSubjectHistory, getSectorsSubjectHistory }

export function FormatDate(value: string | undefined, nullMessage: string) {
  let date = ''
  let day = ''
  let month = ''
  let year = ''

  if (value) {
    date = value.split('T')[0]
    day = date.split('-')[2]
    month = date.split('-')[1]
    year = date.split('-')[0]

    return String(`${day}/${month}/${year}`)
  } else return nullMessage
}

export function FormatReportDateGraphic(value: string | undefined) {
  let handledDate = ''

  let initial = ''
  let initialMonth = ''
  let initialYear = ''

  if (value) {
    initial = value.split(' à ')[0]

    initialMonth = initial.split('-')[1]
    initialYear = initial.split('-')[0]

    handledDate = `${initialMonth}/${initialYear}`

    return String(handledDate)
  }
}

export function FormatReportDate(value: string | undefined) {
  let handledDate = ''

  let initial = ''
  let initialDay = ''
  let initialMonth = ''
  let initialYear = ''

  let final = ''
  let finalDay = ''
  let finalMonth = ''
  let finalYear = ''

  if (value) {
    initial = value.split(' à ')[0]
    final = value.split(' à ')[1]

    initialDay = initial.split('-')[2]
    initialMonth = initial.split('-')[1]
    initialYear = initial.split('-')[0]

    finalDay = final.split('-')[2]
    finalMonth = final.split('-')[1]
    finalYear = final.split('-')[0]

    if (initial !== final) {
      handledDate = `${initialDay}/${initialMonth}/${initialYear} à ${finalDay}/${finalMonth}/${finalYear}`
    } else {
      handledDate = `${initialDay}/${initialMonth}/${initialYear}`
    }

    return String(handledDate)
  }
}

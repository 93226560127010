import {
  Box,
  Card,
  Divider,
  LinearProgress,
  Modal,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useAuthContext } from '../../../../../shared/contexts'
import {
  FormBoxStyle,
  NotificacaoError,
} from '../../../../../shared/components'
import { useDebounce } from '../../../../../shared/hooks'
import {
  SacPredefinedReply,
  getAllpredefinedAnswers,
} from '../../../../../shared/services/api/sac-services'
import { useModuleContext } from '../../../../../shared/contexts/ModulesContext'

type TAutocompleteOptions = SacPredefinedReply
interface IAutocompleteReplies {
  show: boolean
  onClose(): void
  setText(text: string): void
}

export const RespostasPreDefinidasModal: FC<IAutocompleteReplies> = ({
  onClose,
  setText,
  show,
}) => {
  const { user } = useAuthContext()
  const { debounce } = useDebounce(1000)
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const [selected, setSelected] = useState<SacPredefinedReply>()

  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<TAutocompleteOptions[]>([])
  const [busca, setBusca] = useState('')

  const { thisModule } = useModuleContext()

  useEffect(() => {
    if (show) {
      setIsLoading(true)
      debounce(() => {
        getAllpredefinedAnswers(
          busca,
          '0',
          '999999999',
          String(thisModule?.id),
          '',
          'true',
        ).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            setOptions(result.results)
          }
        })
      })
    }
  }, [debounce, user?.institution_id, show, busca])

  const handleSubmit = (text: string) => {
    setText(text)
    setSelected(undefined)
    onClose()
  }

  return (
    <Modal
      onClose={() => {
        onClose()
      }}
      open={show}
      aria-describedby="modal-title"
    >
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Typography variant="h6" textAlign="center">
          Respostas Pré Definidas
        </Typography>
        <Divider />
        {isLoading && <LinearProgress sx={{ marginTop: 2 }} />}
        <Box display="flex" gap={2} flexDirection="column" marginTop={2}>
          <TextField
            fullWidth
            size="small"
            label="Buscar"
            value={busca}
            onChange={(e) => setBusca(e.target.value)}
            placeholder="Busque por título ou corpo da resposta"
            sx={{
              fieldSet: {
                borderRadius: '50px',
              },
            }}
          />
          {options.map((option, index) => (
            <Card
              key={index}
              elevation={option.id === selected?.id ? 10 : 1}
              onClick={() =>
                option.id === selected?.id
                  ? handleSubmit(option.text)
                  : setSelected(option)
              }
              sx={{
                border:
                  option.id === selected?.id
                    ? '1px solid dodgerblue'
                    : '1px dashed #8dbdeb',
                borderRadius: '20px',
                cursor: 'pointer',
                paddingX: 2,
                paddingY: 1,
              }}
            >
              <Box display="flex" gap={1} flexDirection="column">
                <Typography
                  variant="h6"
                  textOverflow="ellipsis"
                  sx={{ userSelect: 'none' }}
                >
                  {option.title}
                </Typography>
                <Typography
                  paragraph
                  sx={{
                    WebkitBoxOrient: 'vertical',
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitLineClamp: 3,
                    userSelect: 'none',
                  }}
                >
                  {option.text}
                </Typography>
              </Box>
            </Card>
          ))}
        </Box>
      </Box>
    </Modal>
  )
}

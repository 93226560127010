/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

export interface ITypologies {
  id: number
  name: string
  status: boolean
  created_at: string
  updated_at: string
  esic: boolean
  compliance: boolean
  institution_id: number
}

export type ITypologiesTotalCount = {
  data: ITypologies[]
  count: number
  next: string
  previous: string
  results: ITypologies[]
}

const getAllTypologies = async (
  item = '0',
  total = '10',
  filter = '',
  typologyId = '',
  institutionId = '',
  status = '',
): Promise<ITypologiesTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/categories/?total=${total}&item=${item}&search=${filter}&id=${typologyId}&institution_id=${institutionId}&status=${status}`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return {
        data,
        results: data.results,
        count: data.count,
        next: data.next,
        previous: data.previous,
      }
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getTypologyById = async (id: number): Promise<ITypologies | Error> => {
  try {
    const relativeUrl = `/V1/categories/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return data
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao listar registro',
    )
  }
}

const createTypology = async (formData: {}): Promise<string | Error> => {
  try {
    const relativeUrl = '/V1/categories/'

    const { data } = await Api.post<ITypologies>(relativeUrl, formData)

    if (data) return data.id ? data.id.toString() : data.name

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const updateTypologyById = async (
  id: number,
  formData: {},
): Promise<ITypologies | Error> => {
  try {
    const relativeUrl = `/V1/categories/${id}/`

    const { data } = await Api.patch<ITypologies>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

export { getAllTypologies, getTypologyById, createTypology, updateTypologyById }

import { InfoOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

interface InfoButtonProps {
  path: string
}

export const InfoButton: FC<InfoButtonProps> = ({ path }) => {
  const navigate = useNavigate()

  return (
    <IconButton color="primary" onClick={() => navigate(path)}>
      <InfoOutlined />
    </IconButton>
  )
}

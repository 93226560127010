import { FC, useState, useRef, useEffect, useMemo } from 'react'

import {
  FerramentasDaListagem,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import usePersistedState from '../../../shared/hooks/usePersistedState'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ISubjects, getAllSubjects } from '../../../shared/services/api'
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  TableBody,
  IconButton,
  Icon,
  Box,
  ButtonBase,
  Typography,
  TableFooter,
  LinearProgress,
  Pagination,
  Tooltip,
  Select,
  MenuItem,
} from '@mui/material'
import { useDebounce } from '../../../shared/hooks'
import { FormAssuntos } from './formulario/Formulário'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'
import { Environment } from '../../../shared/environment'
import { useAuthContext } from '../../../shared/contexts'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const Assuntos: FC = () => {
  const updated = useRef(true)
  const navigate = useNavigate()
  const { moduleTitle } = useModuleContext()
  const theme = useTheme()
  const { debounce } = useDebounce(1000)
  const { user } = useAuthContext()

  const [viewForm, setViewForm] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [institution, setInstitution] = usePersistedState<string>(
    'ADM_SELECTED_INSTITUTION',
    '',
  )
  const [rows, setRows] = useState<ISubjects[]>([])
  const [totalCount, setTotalCount] = useState(0)

  const [searchParams, setSearchParams] = useSearchParams()

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || ''
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  const pagina = Number(item) / Number(total) + 1

  const changePage = (value1: number, value2: number) => {
    const result = value1 * value2 - value2
    return result.toString()
  }

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllSubjects(item, total, busca, '', institution).then((result) => {
        setIsLoading(false)
        updated.current = false
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setRows(result.results)
          setTotalCount(result.count)
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busca, item, total, institution, updated.current])

  return (
    <LayoutBaseDePagina
      selectInstitution
      autoCompleteChange={(e: number | undefined) =>
        setInstitution(e !== undefined ? String(e) : '')
      }
      title="Assuntos"
      filter={
        <FerramentasDaListagem
          mostrarInputDaBusca
          mostrarBotaoNovo={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.hr_manager ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          aoClicarEmNovo={() => setViewForm(true)}
          textoDaBusca={busca}
          aoMudarTextoDaBusca={(texto) => {
            setSearchParams(
              { busca: texto, item: '0', total },
              { replace: true },
            )
          }}
        />
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Setores</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center">Última Atualização</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      navigate(
                        `/ombudsman/${moduleTitle}/subjects/detail/${row.id}`,
                      )
                    }
                  >
                    <Icon>info_outlined</Icon>
                  </IconButton>
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center" sx={{ maxWidth: '260px' }}>
                  {row.sectors.length > 0 ? (
                    <Box
                      flexDirection="row"
                      overflow="auto"
                      maxHeight="75px"
                      flexWrap="wrap"
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      gap={1}
                    >
                      {row.sectors.map((sector, sectorIndex) => (
                        <Box
                          key={sectorIndex}
                          display="flex"
                          component={ButtonBase}
                          borderRadius="15px"
                          maxWidth="120px"
                          height={theme.spacing(3)}
                          justifyContent="center"
                          alignItems="center"
                          color="black"
                          bgcolor={'dodgerblue'}
                          onClick={() =>
                            navigate(
                              `/ombudsman/${moduleTitle}/sectors/detail/${sector.id}`,
                            )
                          }
                        >
                          <Typography
                            margin={1}
                            variant="subtitle2"
                            component="h6"
                            textOverflow="ellipsis"
                            noWrap
                          >
                            {sector.name}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Typography variant="caption">
                      nenhum setor cadastrado
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.created_at)}
                </TableCell>
                <TableCell align="center">
                  {row.updated_at !== row.created_at
                    ? FormatDateTime(row.updated_at)
                    : 'Não houve alterações'}
                </TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{ height: '5px', borderRadius: '50px' }}
                  />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 10 || totalCount > Number(total)) && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box display="flex" justifyContent="center">
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / Number(total))}
                      onChange={(_, newPage) => {
                        setSearchParams(
                          {
                            busca,
                            total,
                            item: changePage(newPage, Number(total)),
                          },
                          { replace: true },
                        )
                      }}
                    />
                    <Tooltip title="Itens por Página">
                      <Select
                        variant="outlined"
                        sx={{ borderRadius: '15px', height: '30px' }}
                        value={total}
                        onChange={(e) => {
                          setSearchParams(
                            { busca, total: e.target.value, item: '0' },
                            { replace: true },
                          )
                        }}
                      >
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="40">40</MenuItem>
                        <MenuItem value="50">50</MenuItem>
                      </Select>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <FormAssuntos
        create
        onClose={() => setViewForm(false)}
        update={updated}
        show={viewForm}
      />
    </LayoutBaseDePagina>
  )
}

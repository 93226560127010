import { Grid, Typography, TextareaAutosize, Link } from '@mui/material'

import { IProtocols } from '../../../../shared/services/api'
import { SacProtocol } from '../../../../shared/services/api/sac-services'

interface IDetails {
  protocolData?: IProtocols | SacProtocol
}

export const ConteudoSolicitacao: React.FC<IDetails> = ({ protocolData }) => {
  return (
    <Grid container spacing={2} direction="column">
      {!!protocolData?.file1 && (
        <Grid container item spacing={1} direction="column">
          <Grid item>
            <Typography>Anexo(s):</Typography>
          </Grid>
          <Grid item direction="column" gap={1}>
            <Typography textOverflow="ellipsis" noWrap>
              <Link
                href={protocolData.file1}
                underline="hover"
                target="_blank"
                rel="noopener"
              >
                {
                  protocolData.file1.split('/')[
                    protocolData.file1.split('/').length - 1
                  ]
                }
              </Link>
            </Typography>
            {!!protocolData.file2 && (
              <Typography textOverflow="ellipsis" noWrap>
                <Link
                  href={protocolData.file2}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {
                    protocolData.file2.split('/')[
                      protocolData.file2.split('/').length - 1
                    ]
                  }
                </Link>
              </Typography>
            )}
            {!!protocolData.file3 && (
              <Typography textOverflow="ellipsis" noWrap>
                <Link
                  href={protocolData.file3}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {
                    protocolData.file3.split('/')[
                      protocolData.file3.split('/').length - 1
                    ]
                  }
                </Link>
              </Typography>
            )}
            {!!protocolData.file4 && (
              <Typography textOverflow="ellipsis" noWrap>
                <Link
                  href={protocolData.file4}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {
                    protocolData.file4.split('/')[
                      protocolData.file4.split('/').length - 1
                    ]
                  }
                </Link>
              </Typography>
            )}
            {!!protocolData.file5 && (
              <Typography textOverflow="ellipsis" noWrap>
                <Link
                  href={protocolData.file5}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {
                    protocolData.file5.split('/')[
                      protocolData.file5.split('/').length - 1
                    ]
                  }
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>
      )}

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Descrição: </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextareaAutosize
            placeholder="Descrição da Manifestação"
            value={protocolData?.description}
            maxRows={8}
            minRows={4}
            style={{
              fontVariant: 'historical-forms',
              borderRadius: '5px',
              font: 'message-box',
              width: '100%',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import {
  NotificacaoError,
  TableContainer,
} from '../../../../../shared/components'
import {
  SacProtocol,
  getProtocolsExternal,
} from '../../../../../shared/services/api/sac-services'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { useDebounce } from '../../../../../shared/hooks'
import { Footer } from '../../../../../shared/components/tabela/Footer'
import { FormatProtocolStatus, changePage } from '../../../../../shared/utils'
import { InfoOutlined } from '@mui/icons-material'
import { Environment } from '../../../../../shared/environment'

export const SacProtocolsExternal: FC = () => {
  const { debounce } = useDebounce(1000)
  const { slug } = useParams<'slug'>()

  const [protocols, setProtocols] = useState<SacProtocol[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(0)

  const [searchParams, setSearchParams] = useSearchParams()
  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])
  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])
  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])
  const status = useMemo(() => {
    return searchParams.get('status') || ''
  }, [searchParams])

  useEffect(() => {
    if (slug) {
      setIsLoading(true)
      debounce(() => {
        getProtocolsExternal(item, total, busca, status)
          .then((result) => {
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              setProtocols(result.results)
              setTotalCount(result.count)
            }
          })
          .finally(() => setIsLoading(false))
      })
    }
  }, [busca, item, total, status, slug])

  return (
    <Box display="flex" flex="1" justifyContent="center" flexDirection="column">
      <Box
        component={Paper}
        display="flex"
        flex="1"
        marginX={1}
        border="1px solid dodgerblue"
        minHeight="80px"
        alignItems="center"
        paddingX={1}
        sx={{
          borderRadius: '15px',
        }}
      >
        <Grid container>
          <Grid item xs={8}>
            <TextField
              fullWidth
              size="small"
              label="Protocolo"
              sx={{
                fieldSet: {
                  borderTopLeftRadius: '50px',
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                  borderBottomLeftRadius: '50px',
                },
              }}
              value={busca}
              onChange={(e) =>
                setSearchParams(
                  {
                    tab: '1',
                    busca: e.target.value || '',
                    status,
                    item: '0',
                    total,
                  },
                  { replace: true },
                )
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                fullWidth
                size="small"
                label="Status"
                labelId="status-label"
                sx={{
                  borderTopLeftRadius: '0px',
                  borderTopRightRadius: '50px',
                  borderBottomLeftRadius: '0px',
                  borderBottomRightRadius: '50px',
                }}
                defaultValue={0}
                value={status === '' ? 0 : Number(status)}
                onChange={(e) => {
                  setSearchParams(
                    {
                      tab: '1',
                      busca,
                      status: String(
                        e.target.value !== 0 ? e.target.value : '',
                      ),
                      item: '0',
                      total,
                    },
                    { replace: true },
                  )
                }}
              >
                <MenuItem value={0}>Todos</MenuItem>
                <MenuItem value={1}>Atrasadas</MenuItem>
                <MenuItem value={2}>Pendentes</MenuItem>
                <MenuItem value={3}>Trâmites</MenuItem>
                <MenuItem value={4}>Prorrogadas</MenuItem>
                <MenuItem value={5}>Concluídas</MenuItem>
                <MenuItem value={6}>Indeferidas</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={8}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Protocolo</TableCell>
              <TableCell align="center">Prazo</TableCell>
              <TableCell align="center">Agrupamento</TableCell>
              <TableCell align="center">Setor</TableCell>
              <TableCell align="center">Assunto</TableCell>
              <TableCell align="center">Categoria</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {protocols.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  borderLeft: `5px solid ${
                    row.status === 1
                      ? '#EB3324'
                      : row.status === 2
                      ? '#FFFF00'
                      : row.status === 3
                      ? '#CBAACB'
                      : row.status === 4
                      ? '#55CBCD'
                      : row.status === 5
                      ? '#8bdaa7'
                      : '#999999'
                  }`,
                }}
              >
                <TableCell align="center">
                  <Link
                    to={`/sac/${slug}/inicio/protocolos/detalhes/${row.id}`}
                  >
                    <IconButton color="primary">
                      <InfoOutlined />
                    </IconButton>
                  </Link>
                </TableCell>
                <TableCell>{row.identifier}</TableCell>
                <TableCell>{row.deadline}</TableCell>
                <TableCell align="center">{row.grouping.name}</TableCell>
                <TableCell align="center">{row.sector.name}</TableCell>
                <TableCell align="center">{row.subject.name}</TableCell>
                <TableCell align="center">{row.category.name}</TableCell>
                <TableCell align="center">
                  {FormatProtocolStatus(row.status)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {!isLoading && totalCount === 0 && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <Footer
            colSpan={8}
            total={total}
            item={Number(item)}
            loading={isLoading}
            totalCount={totalCount}
            onChangePagination={(e, newPage) =>
              setSearchParams(
                {
                  busca,
                  total,
                  status,
                  tab: '1',
                  item: changePage(newPage, Number(total)),
                },
                { replace: true },
              )
            }
            onChangeTotalItems={(e) =>
              setSearchParams(
                { busca, total: e.target.value, item: '0', status, tab: '1' },
                { replace: true },
              )
            }
          />
        </Table>
      </TableContainer>
    </Box>
  )
}

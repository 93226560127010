import {
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Box } from '@mui/system'
import {
  IModule,
  useAppThemeContext,
  useAuthContext,
  useDrawerContext,
} from '../../contexts'
import {
  Link,
  useLocation,
  useMatch,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom'
import { useEffect, useState } from 'react'
import { SwitchTheme } from '../switchTema/SwitchTema'
import { Api } from '../../services/api/axios-config'
import NotifiesDropdown from '../../layouts/components/Dropdown'
import {
  Notifications,
  NotificationsActive,
  NotificationsOff,
} from '@mui/icons-material'
import { NotifiesBadge } from '../badge/Badge'
import { ProfilePicture } from '../profile-picture/ProfilePicture'
import { useModuleContext } from '../../contexts/ModulesContext'

interface ILIstItemLinkProps {
  to: string
  icon: string
  label: string
  onClick: (() => void) | undefined
}

const ListItemLink: React.FC<ILIstItemLinkProps> = ({
  to,
  icon,
  label,
  onClick,
}) => {
  const resolvedPath = useResolvedPath(to)

  const match = useMatch({ path: resolvedPath.pathname, end: false })

  const handleClick = () => {
    onClick?.()
  }

  return (
    <Link to={to} style={{ textDecoration: 'none', color: 'unset' }}>
      <ListItemButton
        selected={!!match}
        onClick={handleClick}
        sx={{ zIndex: 0 }}
      >
        <ListItemIcon>
          <Icon>{icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </Link>
  )
}

interface IDrawerProvider {
  children: React.ReactNode
}

export const MenuLateral: React.FC<IDrawerProvider> = ({ children }) => {
  const { user, logout } = useAuthContext()
  const { currentModule, moduleTitle } = useModuleContext()

  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    isDrawerOpen,
    toggleDrawerOpen,
    drawerOptions,
    openNotifies,
    toggleOpenNotifies,
    unreadNotifies,
    onlineUsers,
  } = useDrawerContext()
  const { toggleTheme, themeName } = useAppThemeContext()

  const [themeValue] = useState(themeName === 'dark')

  const [alertBell, setAlertBell] = useState(false)
  const [disabledPaths, setDisabledPaths] = useState(true)
  const [total, setTotal] = useState(0)

  const path = useLocation()

  useEffect(() => {
    if (unreadNotifies === 0) {
      setAlertBell(false)
    } else if (unreadNotifies > total) {
      setAlertBell(true)
      setTotal(unreadNotifies)
    } else {
      setTotal(unreadNotifies)
    }
  }, [total, unreadNotifies])

  const handleLogout = () => {
    Api.post('/V1/api/logout/')
      .then(() => {
        logout(true)
      })
      .catch((error) => {
        console.error(error)
        logout(true)
      })
  }

  useEffect(() => {
    if (
      path.pathname.startsWith('/admin') ||
      path.pathname.startsWith('/ouvidoria/') ||
      path.pathname.startsWith('/sac/') ||
      path.pathname.startsWith('/reset_password') ||
      path.pathname.startsWith('/users/confirmation') ||
      path.pathname.startsWith('/confirm_email') ||
      path.pathname.startsWith('/external_users/confirmation') ||
      path.pathname.startsWith('/pagina_404')
    ) {
      setDisabledPaths(true)
    } else {
      setDisabledPaths(false)
    }
  }, [path])

  const closeDrawerNotifies = () => {
    toggleOpenNotifies()
    setTimeout(function () {
      toggleDrawerOpen()
    }, 260)
  }

  const navigate = useNavigate()

  const [thisModule, setThisModule] = useState<IModule>()

  useEffect(() => {
    setThisModule(
      user?.modules.find(
        (mdl) => mdl.type === currentModule && mdl.title === moduleTitle,
      ),
    )
  }, [user?.modules, currentModule, moduleTitle])

  return (
    <>
      <Drawer
        id="drawer"
        open={disabledPaths ? false : isDrawerOpen}
        variant={smDown || disabledPaths ? 'temporary' : 'permanent'}
        onClose={openNotifies ? closeDrawerNotifies : toggleDrawerOpen}
      >
        <Box
          width={theme.spacing(28)}
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Box
            width="100%"
            height={theme.spacing(30)}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              flexDirection={'column'}
              alignItems="center"
              display="flex"
              width="100%"
            >
              {thisModule?.profile !== 1 && (
                <Tooltip
                  title="Usuários Online (clique para ver mais)"
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    navigate(
                      `/${currentModule}/${moduleTitle}/${
                        currentModule === 'ombudsman' ? 'sectorsusers' : 'users'
                      }/?online=true`,
                    )
                  }
                >
                  <Box
                    display="flex"
                    gap={1}
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Box
                      width="8px"
                      height="8px"
                      borderRadius="50px"
                      bgcolor="greenyellow"
                    />
                    <Typography>{`online: ${onlineUsers.current.length}`}</Typography>
                  </Box>
                </Tooltip>
              )}
              <ProfilePicture
                sx={{
                  marginTop: theme.spacing(2),
                  height: theme.spacing(12),
                  width: theme.spacing(12),
                  border: '2px solid gray',
                  zIndex: -1,
                }}
                src={user?.profile_picture}
              />
              <Box
                marginTop={1}
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                display="flex"
                width="100%"
              >
                <Typography variant="subtitle1" component="center">
                  {user?.username}
                </Typography>
                <Typography variant="caption">
                  {currentModule === 'ombudsman'
                    ? thisModule?.profile === 0
                      ? 'administrador(a)'
                      : thisModule?.profile === 1
                      ? 'auxiliar'
                      : thisModule?.profile === 2
                      ? 'ouvidor(a)'
                      : 'auditor(a)'
                    : thisModule?.profile === 0
                    ? 'administrador(a)'
                    : thisModule?.profile === 1
                    ? 'auxiliar'
                    : thisModule?.profile === 2
                    ? 'gestor(a) master'
                    : 'auditor(a)'}
                </Typography>
                <SwitchTheme
                  onChange={toggleTheme}
                  defaultChecked={themeValue}
                />
              </Box>
            </Box>
            {xsDown && (
              <Box position="absolute" top="5px" left="165px">
                <Tooltip
                  title={
                    openNotifies ? 'Fechar notificações' : 'Abrir notificações'
                  }
                >
                  <IconButton size="large" onClick={() => toggleOpenNotifies()}>
                    <NotifiesBadge
                      badgeContent={unreadNotifies}
                      color="primary"
                      max={99}
                    >
                      {openNotifies ? (
                        <NotificationsOff color="action" />
                      ) : alertBell ? (
                        <NotificationsActive color="action" />
                      ) : (
                        <Notifications color="action" />
                      )}
                    </NotifiesBadge>
                  </IconButton>
                </Tooltip>

                <NotifiesDropdown open={openNotifies && isDrawerOpen} />
              </Box>
            )}
          </Box>

          <Divider />
          <Box display="flex" flexDirection="column" flex={1}>
            <Box flex={1}>
              <List component="nav">
                {drawerOptions.map((drawerOption) => (
                  <ListItemLink
                    key={drawerOption.path}
                    to={drawerOption.path}
                    icon={drawerOption.icon}
                    label={drawerOption.label}
                    onClick={smDown ? toggleDrawerOpen : undefined}
                  />
                ))}
              </List>
            </Box>
            <Divider />
            <Box>
              <List component="nav">
                <ListItemButton onClick={handleLogout}>
                  <ListItemIcon>
                    <Icon>logout</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Sair" />
                </ListItemButton>
              </List>
            </Box>
          </Box>
        </Box>
      </Drawer>

      <Box
        height="100vh"
        marginLeft={smDown || disabledPaths ? 0 : theme.spacing(30)}
      >
        {children}
      </Box>
    </>
  )
}

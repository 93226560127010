/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createRef, useEffect, useState } from 'react'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  LinearProgress,
  Icon,
  Divider,
  Link,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextareaAutosize,
} from '@mui/material'
import {
  AddComment,
  AttachFile,
  Close,
  Delete,
  Send,
  UploadFile,
} from '@mui/icons-material'
import {
  IReplie,
  createReply,
  getAllReplies,
} from '../../../../shared/services/api'
import { IModule, useAuthContext } from '../../../../shared/contexts'
import {
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { FormatDateTime } from '../../../../shared/utils'
import { toast } from 'react-toastify'
import { AutocompleteReplies } from './AutoCompleteRespostas'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'

interface IRequests {
  protocolId?: number
  institutionId?: number
  external?: boolean
  externalUser?: any
  secret?: boolean
  sendDisabled: boolean
  secondUpdate?: any
}

export const RespostasManifestacao: React.FC<IRequests> = ({
  protocolId,
  institutionId,
  external = false,
  secondUpdate,
  sendDisabled,
  externalUser,
  secret = true,
}) => {
  const chatRef = createRef<HTMLDivElement>()
  const { user } = useAuthContext()
  const { currentModule, moduleTitle } = useModuleContext()

  const [isLoading, setIsLoading] = useState(true)

  const [kindResponse, setKindResponse] = useState<number>(external ? 2 : 1)
  const [message, setMessage] = useState<string>()
  const [file, setFile] = useState<any>()
  const [update, setUpdate] = useState(false)
  const [replies, setReplies] = useState<IReplie[]>([])

  const [thisModule, setThisModule] = useState<IModule>()

  useEffect(() => {
    setThisModule(
      user?.modules.find(
        (mdl) => mdl.type === currentModule && mdl.title === moduleTitle,
      ),
    )
  }, [user?.modules, currentModule, moduleTitle])

  useEffect(() => {
    setIsLoading(true)
    const token = sessionStorage.getItem('EXTERNAL_TOKEN') || undefined
    if (protocolId !== undefined) {
      getAllReplies(protocolId, external ? token : undefined).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          setUpdate(false)
          NotificacaoError('Erro ao carregar respostas')
        } else {
          setUpdate(false)
          setReplies(result)
        }
      })
    }
  }, [protocolId, update, secondUpdate?.current])

  const formData = new FormData()
  const [viewAction, setViewAction] = useState(false)
  const [viewDReplies, setViewDReplies] = useState(false)

  const handleSubmit = () => {
    toast.info('Enviando mensagem. Aguarde')
    setIsLoading(true)
    // Set FormData
    if (message) formData.append('reply', message)
    if (file) formData.append('file', file, file.name)
    if (external) formData.append('external_user_id', externalUser.id)
    else formData.append('user_id', String(user?.id))
    formData.append('type', String(kindResponse))
    formData.append('institution_id', String(institutionId))
    formData.append('protocol_id', String(protocolId))
    const token = sessionStorage.getItem('EXTERNAL_TOKEN') || undefined
    createReply(
      Number(protocolId),
      formData,
      external ? token : undefined,
    ).then((result) => {
      setIsLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        NotificacaoSucesso('Resposta criada com sucesso')
        setFile(undefined)
        setKindResponse(1)
        setMessage('')
        setUpdate(true)
      }
    })
  }

  return (
    <Box
      maxWidth="100%"
      overflow="hidden"
      gap={2}
      display="flex"
      flexDirection="column"
      paddingBottom={1}
    >
      <Box display="flex" flex="1" flexDirection="column" gap={1} marginTop={1}>
        {!external && (
          <FormControl fullWidth>
            <InputLabel id="kind_response">Tipo de Resposta</InputLabel>
            <Select
              size="small"
              label="Tipo de Resposta"
              labelId="kind_response"
              defaultValue={0}
              value={kindResponse}
              onChange={(e) => setKindResponse(Number(e.target.value))}
            >
              <MenuItem value={1}>Interna</MenuItem>
              <MenuItem value={2} disabled={thisModule?.profile !== 2}>
                Externa
              </MenuItem>
            </Select>
          </FormControl>
        )}
        <Box display="flex" gap={1} flexDirection="column">
          {!!file && (
            <Box
              display="flex"
              flex="1"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Box maxWidth="90%">
                <Typography textOverflow="ellipsis" noWrap>
                  {file.name}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    setFile(undefined)
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          )}
          <Box display="flex" gap={1}>
            <Tooltip title={'Mais opções'}>
              <IconButton
                component="label"
                disabled={sendDisabled}
                onClick={() => setViewAction(true)}
                sx={{ transform: 'rotate(-45deg)' }}
              >
                <AttachFile />
              </IconButton>
            </Tooltip>
            <TextareaAutosize
              minRows={2}
              maxRows={8}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              aria-label={external ? 'Complemento' : 'Resposta'}
              placeholder={
                external
                  ? 'Escreva aqui um complemento'
                  : 'Escreva aqui uma resposta'
              }
              style={{
                width: '90%',
                maxWidth: '90%',
                font: 'message-box',
                padding: 2,
                display: 'flex',
                alignItems: 'center',
                borderRadius: '5px',
                fontVariant: 'historical-forms',
              }}
            />
            <Tooltip title={isLoading ? 'Carregando' : 'Enviar'}>
              <IconButton
                onClick={handleSubmit}
                disabled={sendDisabled || isLoading}
              >
                <Send />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box
        maxHeight="85%"
        overflow="auto"
        ref={chatRef}
        borderRadius="10px"
        border="2px solid black"
        padding={1}
      >
        {protocolId ? (
          <Box display="flex" flexDirection="column" gap={1}>
            {replies.map((reply, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                bgcolor={reply.type === 2 ? 'grey' : 'white'}
                border="2px solid black"
                borderRadius="10px"
                sx={{
                  ':focus': {
                    border: '1px solid dodgerblue',
                  },
                }}
              >
                {reply.file && (
                  <Link
                    sx={{
                      cursor: 'pointer',
                      color: reply.type === 1 ? 'black' : 'white',
                    }}
                    onClick={() => {
                      window.open(reply.file, '_blank', 'noopener')
                    }}
                    variant="h6"
                    underline="hover"
                    paddingX={1}
                    target="_blank"
                    rel="noopener"
                  >
                    Anexo da resposta
                  </Link>
                )}
                {reply.file_link.map((lnk, lnkIndex) => (
                  <Link
                    key={lnkIndex}
                    sx={{
                      cursor: 'pointer',
                      color: reply.type === 1 ? 'black' : 'white',
                    }}
                    onClick={() => {
                      window.open(lnk, '_blank', 'noopener')
                    }}
                    variant="h6"
                    underline="hover"
                    paddingX={1}
                    target="_blank"
                    rel="noopener"
                  >
                    Anexo da resposta
                  </Link>
                ))}
                <Box display="flex" flexDirection="column">
                  {reply.transfer && (
                    <Typography marginLeft={2} variant="h6" color="white">
                      TRANSFERÊNCIA DE SETOR
                    </Typography>
                  )}
                  {reply.change_typology && (
                    <Typography marginLeft={2} variant="h6" color="white">
                      MUDANÇA DE TIPOLOGIA
                    </Typography>
                  )}
                  {external ? (
                    <Box display="flex" flex="1" flexDirection="column">
                      <Typography marginLeft={2}>
                        {reply.user_id ? `- ${reply.user_id.username}` : ''}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography
                      marginLeft={2}
                      color={reply.type === 1 ? 'black' : 'white'}
                    >
                      -{' '}
                      {reply.user_id
                        ? reply.user_id.username
                        : reply.external_user_id
                        ? secret
                          ? 'Manifestante | Sigilo'
                          : `${reply.external_user_id.name} | Manifestante`
                        : 'Manifestante'}
                    </Typography>
                  )}
                  <Box
                    display="flex"
                    gap={1}
                    flexDirection="row"
                    alignItems="center"
                    paddingX={1}
                  >
                    <Tooltip
                      title={
                        reply.type === 1
                          ? 'Resposta Interna'
                          : 'Resposta Externa'
                      }
                    >
                      <Icon
                        sx={{ color: reply.type === 1 ? 'black' : 'white' }}
                      >
                        {reply.type === 1 ? 'https' : 'insert_comment'}
                      </Icon>
                    </Tooltip>
                    <Divider orientation="vertical" variant="fullWidth" />
                    <Box display="flex" flexDirection="column" gap={1}>
                      {reply.reply.split('\n').map((text) => (
                        <Typography
                          key={index}
                          textAlign="justify"
                          sx={{ color: reply.type === 1 ? 'black' : 'white' }}
                        >
                          {text}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                  <Box display="flex" width="99%" justifyContent="right">
                    <Typography variant="caption">
                      {FormatDateTime(reply.created_at, 'Não definido')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <LinearProgress variant="indeterminate" />
        )}
      </Box>
      {!external && (
        <AutocompleteReplies
          onClose={() => setViewDReplies(false)}
          show={viewDReplies}
          setText={setMessage}
        />
      )}
      <Dialog
        open={viewAction}
        onClose={() => setViewAction(false)}
        PaperProps={{ sx: { borderRadius: '20px' } }}
      >
        <DialogTitle>
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Selecione uma ação</Typography>
            <IconButton onClick={() => setViewAction(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          {!external && (
            <ListItem>
              <ListItemButton
                onClick={() => {
                  setViewDReplies(true)
                  setViewAction(false)
                }}
              >
                <ListItemIcon>
                  <AddComment />
                </ListItemIcon>
                <ListItemText primary="Inserir resposta pré-definida" />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem>
            <ListItemButton
              onClick={() => {
                document.getElementById('upload-file')?.click()
                setViewAction(false)
              }}
            >
              <ListItemIcon>
                <UploadFile />
              </ListItemIcon>
              <ListItemText primary="Anexar arquivo" />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
      {!file && (
        <input
          hidden
          id="upload-file"
          type="file"
          onChange={(e) => {
            if (e.target.files) {
              if (
                !(
                  e.target.files[0].type.includes('image') ||
                  e.target.files[0].type.includes('pdf')
                )
              ) {
                NotificacaoError('Tipo de arquivo não suportado')
                toast.info('Insira arquivos PDF ou imagens PNG, JPG ou JPEG')
              } else {
                setFile(e.target.files[0])
              }
            } else setFile(undefined)
          }}
        />
      )}
    </Box>
  )
}

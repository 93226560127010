import { Environment } from '../../environment'

import {
  Button,
  Icon,
  TextField,
  useTheme,
  styled,
  Box,
  Paper,
} from '@mui/material'

const SearchBox = styled(TextField)(() => ({
  '& fieldset': {
    borderRadius: '50px',
  },
}))

interface ISelecionarInstituicaoProps {
  textoDaBusca?: string
  mostrarInputDaBusca?: boolean
  aoMudarTextoDaBusca?: (novoTexto: string) => void
  textoBotaoNovo?: string
  mostrarBotaoNovo?: boolean
  aoClicarEmNovo?: () => void
}

export const FerramentasDaListagem: React.FC<ISelecionarInstituicaoProps> = ({
  textoDaBusca = '',
  mostrarInputDaBusca = false,
  aoMudarTextoDaBusca,
  textoBotaoNovo = 'Novo',
  mostrarBotaoNovo = true,
  aoClicarEmNovo,
}) => {
  const theme = useTheme()

  return (
    <Box
      gap={1}
      marginX={1}
      padding={1}
      paddingX={1}
      display="flex"
      alignItems="center"
      height={theme.spacing(5)}
      component={Paper}
      borderRadius="15px"
      border="1px solid dodgerblue"
    >
      {mostrarInputDaBusca && (
        <SearchBox
          style={{ borderRadius: '50px' }}
          variant="outlined"
          size="small"
          value={textoDaBusca}
          onChange={(e) => aoMudarTextoDaBusca?.(e.target.value)}
          placeholder={Environment.INPUT_DE_BUSCA}
        />
      )}

      <Box flex={1} display="flex" justifyContent="end">
        {mostrarBotaoNovo && (
          <Button
            sx={{ borderRadius: '50px' }}
            variant="contained"
            color="primary"
            disableElevation
            onClick={aoClicarEmNovo}
            startIcon={<Icon>add</Icon>}
          >
            {textoBotaoNovo}
          </Button>
        )}
      </Box>
    </Box>
  )
}

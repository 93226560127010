import { Box, Typography, useTheme } from '@mui/material'

export function FormatStatus(
  value: boolean,
  textoAtivo = 'Ativo',
  textoInativo = 'Inativo',
) {
  const theme = useTheme()

  return (
    <Box
      display="flex"
      borderRadius="15px"
      height={theme.spacing(3)}
      justifyContent="center"
      alignItems="center"
      color="black"
      bgcolor={value ? '#8bdaa7' : '#Da8b8b'}
    >
      <Typography variant="subtitle2" component="h6">
        {value ? textoAtivo : textoInativo}
      </Typography>
    </Box>
  )
}

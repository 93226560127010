/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import {
  VForm,
  VTextArea,
  VTextField,
  useVForm,
} from '../../../../../shared/forms'
import {
  AutoCompleteAgrupamento,
  AutoCompleteAssunto,
  AutoCompleteSetor,
  AutoCompleteTipologia,
} from '../../../components/AutoComplete'
import { IClientDetail, IProtocols } from '../../../../../shared/services/api'
import { Api } from '../../../../../shared/services/api/axios-config'
import {
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../../shared/components'
import { ResultModal } from '../../ResultModal'

interface INovoAnonimoProps {
  institutionData: IClientDetail
  onClose: () => void
}

export const NovoAnonimo: FC<INovoAnonimoProps> = ({
  institutionData,
  onClose,
}) => {
  const { formRef } = useVForm()

  const [group, setGroup] = useState<number>()
  const [sector, setSector] = useState<number>()
  const [result, setResult] = useState<IProtocols>()

  const [modalSucesso, setModalSucesso] = useState(false)

  const handleSubmit = (formData: any) => {
    formData.institution_id = institutionData?.id
    formData.anonymous = true
    formData.user_history_type = 2

    Api.post('/V1/anonymous/', formData)
      .then((result) => {
        setResult(result.data)
        setModalSucesso(true)
        NotificacaoSucesso('Manifestação Aberta com Sucesso')
        setGroup(undefined)
        setSector(undefined)
        formRef.current?.setData({})
      })
      .catch((error) => {
        NotificacaoError(error.message)
      })
  }

  return (
    <Box margin={1}>
      <VForm onSubmit={handleSubmit} ref={formRef}>
        <Grid container direction="column" spacing={2}>
          <Grid container item spacing={1}>
            <Grid item xs={12} sm={6}>
              <AutoCompleteAgrupamento
                external
                institutionId={String(institutionData?.id)}
                textStyle={{
                  fieldSet: {
                    borderRadius: '50px',
                  },
                }}
                onChange={(e) => setGroup(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutoCompleteSetor
                external
                institutionId={Number(institutionData?.id)}
                groupingId={group}
                onChange={(e) => setSector(e)}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={12} sm={6}>
              <AutoCompleteAssunto
                sectorId={sector}
                institutionId={Number(institutionData?.id)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutoCompleteTipologia
                anonymous
                institutionId={Number(institutionData?.id)}
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <VTextField
                fullWidth
                size="small"
                name="relative_protocol"
                label="Manifestação anterior relacionada"
                borderBottomLeft="50px"
                borderTopLeft="50px"
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item display="flex" xs={12} justifyContent="center">
              <VTextArea
                name="description"
                minRows={4}
                required
                maxRows={8}
                placeholder="Descreva aqui sua manifestação"
                ariaLabel="Descrição"
                style={{
                  width: '80%',
                  borderRadius: '5px',
                  fontVariant: 'historical-forms',
                  font: 'message-box',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box
          display="flex"
          width="100%"
          justifyContent="right"
          alignItems="center"
          gap={1}
          marginTop={2}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{ borderRadius: '50px' }}
            disableElevation
          >
            enviar
          </Button>
          <Button onClick={onClose} sx={{ borderRadius: '50px' }}>
            fechar
          </Button>
        </Box>
      </VForm>
      <ResultModal
        onClose={() => setModalSucesso(false)}
        show={modalSucesso}
        result={result}
        handleClose={onClose}
      />
    </Box>
  )
}

import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { FormBoxStyle } from '../../../shared/components'
import { Environment } from '../../../shared/environment'

interface IDownloadModalProps {
  year: number
  show: boolean
  graphic: number
  onClose: () => void
  institutionId: number
}

export const DownloadModal: FC<IDownloadModalProps> = ({
  onClose,
  show,
  graphic,
  year,
  institutionId,
}) => {
  const [type, setType] = useState('csv')
  const [labelGraphic, setLabelGraphic] = useState('')

  useEffect(() => {
    if (graphic === 1) {
      setLabelGraphic('situation')
    } else if (graphic === 2) {
      setLabelGraphic('gender')
    } else if (graphic === 3) {
      setLabelGraphic('escolarity')
    }
  }, [graphic])

  const handleSubmit = () => {
    window.open(
      `${Environment.URL_BASE}/V1/externalusers/${labelGraphic}_data/${institutionId}/${year}/${type}/`,
      '_blank',
      'noopener',
    )
  }

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        width="300px"
        maxHeight="85%"
        borderRadius="20px"
        flexDirection="column"
      >
        <Typography variant="h6" textAlign="center">
          Download
        </Typography>
        <Divider />
        <Box margin={1}>
          <FormControl fullWidth>
            <InputLabel id="type-label">Tipo de arquivo</InputLabel>
            <Select
              fullWidth
              required
              value={type}
              label="Tipo de arquivo"
              labelId="type-label"
              onChange={(e) => setType(e.target.value)}
              size="small"
            >
              <MenuItem value="txt">Arquivo de texto</MenuItem>
              <MenuItem value="csv">CSV</MenuItem>
              <MenuItem value="xlsx">XLSX</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Button onClick={handleSubmit}>Download</Button>
      </Box>
    </Modal>
  )
}

import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  Typography,
  TextField,
  Button,
} from '@mui/material'

import {
  FerramentasDaListagem,
  NotificacaoAlerta,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import usePersistedState from '../../../shared/hooks/usePersistedState'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import { FilterAlt, FilterAltOff, InfoOutlined } from '@mui/icons-material'
import {
  SacSlide,
  getAllSacSlides,
} from '../../../shared/services/api/sac-services'
import { Environment } from '../../../shared/environment'
import { Footer } from '../../../shared/components/tabela/Footer'
import { useSearchParams } from 'react-router-dom'
import { FormatDateTime, FormatStatus, changePage } from '../../../shared/utils'
import { handleSetSearchParams } from '../../../shared/utils/search-params/setSearchParams'
import { useDebounce } from '../../../shared/hooks'
import { Formulario } from './formulario/Formulario'
import { useAuthContext } from '../../../shared/contexts'

export const SlideSAC = () => {
  const { thisModule } = useModuleContext()
  const { user } = useAuthContext()

  const [institution, setInstitution] = usePersistedState<string>(
    'ADM_SELECTED_INSTITUTION',
    '',
  )
  const { debounce } = useDebounce(1000)
  const [update, setUpdate] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState<SacSlide[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [data, setData] = useState<SacSlide>()
  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)

  const [searchParams, setSearchParams] = useSearchParams()
  const [busca, setBusca] = useState(searchParams.get('busca') || '')
  const [item, setItem] = useState(searchParams.get('item') || '0')
  const [total, setTotal] = useState(searchParams.get('total') || '10')
  const [status, setStatus] = useState(searchParams.get('status') || '')
  const [link, setLink] = useState(searchParams.get('link') || '')

  useEffect(() => {
    setIsLoading(true)
    getAllSacSlides(
      busca,
      item,
      total,
      thisModule?.profile === 0 || !thisModule?.profile
        ? ''
        : String(thisModule?.id),
      institution,
      status,
      link,
    ).then((result) => {
      setIsLoading(false)
      setUpdate(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        setRows(result.results)
        setTotalCount(result.count)
      }
    })
  }, [searchParams, institution, thisModule?.id, update])

  return (
    <LayoutBaseDePagina
      selectInstitution
      autoCompleteChange={(e) => setInstitution(e ? String(e) : '')}
      title="Slides"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputDaBusca
          textoDaBusca={busca}
          aoClicarEmNovo={() => {
            if (thisModule?.profile === 0 && !institution) {
              NotificacaoAlerta('Selecione uma instituição')
            } else {
              setCreate(true)
              setData(undefined)
              setViewForm(true)
            }
          }}
          aoMudarTextoDaBusca={(newValue) => {
            setIsLoading(true)
            setBusca(newValue)
            setItem('0')
            handleSetSearchParams(
              searchParams,
              ['busca', 'item'],
              [newValue, '0'],
              setSearchParams,
              debounce,
            )
          }}
        />
      }
    >
      <Box display="flex" padding={1} flex={1}>
        <Accordion
          sx={{ width: '100%', border: '1px solid dodgerblue' }}
          style={{ borderRadius: '15px' }}
        >
          <AccordionSummary>
            <Grid container>
              <Grid container item spacing={1} alignItems="center">
                <Grid item alignItems="center">
                  <Typography textAlign="center">
                    <FilterAlt />
                  </Typography>
                </Grid>
                <Grid item alignItems="center">
                  <Typography textAlign="center">Filtros</Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl size="small" fullWidth>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      label="Status"
                      labelId="status-label"
                      value={status}
                      onChange={(e) => {
                        setIsLoading(true)
                        setStatus(e.target.value)
                        setItem('0')
                        handleSetSearchParams(
                          searchParams,
                          ['status', 'item'],
                          [e.target.value, item],
                          setSearchParams,
                          debounce,
                        )
                      }}
                      sx={{
                        borderRadius: '50px',
                      }}
                    >
                      <MenuItem value="" hidden></MenuItem>
                      <MenuItem value="true">Ativo</MenuItem>
                      <MenuItem value="false">Intivo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="link"
                    value={link}
                    onChange={(e) => {
                      setIsLoading(true)
                      setLink(e.target.value)
                      setItem('0')
                      handleSetSearchParams(
                        searchParams,
                        ['link', 'item'],
                        [e.target.value, item],
                        setSearchParams,
                        debounce,
                      )
                    }}
                    sx={{
                      fieldSet: {
                        borderRadius: '50px',
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="end">
                <Button
                  variant="outlined"
                  startIcon={<FilterAltOff />}
                  onClick={() => {
                    setBusca('')
                    setItem('0')
                    setLink('')
                    setStatus('')
                    handleSetSearchParams(
                      searchParams,
                      ['busca', 'item', 'link', 'status'],
                      ['', '0', '', ''],
                      setSearchParams,
                      debounce,
                    )
                  }}
                >
                  Limpar filtro
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Imagem</TableCell>
              <TableCell align="center">Título</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (
                        thisModule?.profile === 0 &&
                        institution === 'undefined'
                      ) {
                        NotificacaoAlerta('Selecione uma instituição')
                      } else {
                        setCreate(false)
                        setData(row)
                        setViewForm(true)
                      }
                    }}
                  >
                    <InfoOutlined />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  {<img src={row.image} width={120} alt="slide_image" />}
                </TableCell>
                <TableCell align="center">{row.title}</TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.created_at)}
                </TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {!isLoading && totalCount === 0 && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <Footer
            colSpan={5}
            total={total}
            totalCount={totalCount}
            item={Number(item)}
            loading={isLoading}
            onChangePagination={(_, newPage) => {
              setItem(changePage(newPage, Number(total)))
              handleSetSearchParams(
                searchParams,
                ['item'],
                [changePage(newPage, Number(total))],
                setSearchParams,
                debounce,
              )
            }}
            onChangeTotalItems={(e) => {
              setIsLoading(true)
              setTotal(e.target.value)
              setItem('0')
              handleSetSearchParams(
                searchParams,
                ['total', 'item'],
                [e.target.value, item],
                setSearchParams,
                debounce,
              )
            }}
          />
        </Table>
      </TableContainer>
      {((thisModule?.profile === 0 && institution) ||
        (thisModule && user && thisModule?.profile > 0)) && (
        <Formulario
          create={create}
          institution={
            thisModule.profile === 0
              ? institution
              : user
              ? user.institution_id
              : ''
          }
          setUpdate={() => setUpdate(true)}
          onClose={() => setViewForm(false)}
          show={viewForm}
          data={data}
        />
      )}
    </LayoutBaseDePagina>
  )
}

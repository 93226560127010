/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react'
import { Scope } from '@unform/core'
import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { AddCircle, RemoveCircle } from '@mui/icons-material'

import { useAuthContext } from '../../../shared/contexts'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import { VForm, VTextField, useVForm } from '../../../shared/forms'
import { getClientById, updateClientById } from '../../../shared/services/api'
import {
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'
import { formatCellPhone, formatPhone } from '../../../shared/utils'

interface IPhones {
  title: string
  number: string
}

interface IFormData {
  id?: number
  address: string
  phones: IPhones[]
  working_hour: string
  cellphones: IPhones[]
  ombudsman_title: string
}

export const EditarInstituicao: React.FC = () => {
  const theme = useTheme()

  const { user } = useAuthContext()
  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(true)
  const [phones, setPhones] = useState(1)
  const [cellPhones, setCellPhones] = useState(1)
  const [update, setUpdate] = useState(true)
  const [data, setData] = useState<any>()

  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)
    formData.phones = formData.phones.filter((_, index) => index <= phones - 1)
    formData.cellphones = formData.cellphones.filter(
      (_, index) => index <= cellPhones - 1,
    )
    updateClientById(Number(user?.institution_id), formData).then((result) => {
      setIsLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        NotificacaoSucesso('Instituição Atualizada com Sucesso')
        setUpdate(true)
      }
    })
  }

  useEffect(() => {
    setUpdate(false)
    getClientById(Number(user?.institution_id)).then((result) => {
      setIsLoading(false)
      if (result instanceof Error) {
        NotificacaoError('Erro ao Carregar Dados da Instituição')
      } else {
        setPhones(result.phones.length)
        setCellPhones(result.cellphones.length)
        setData(result)
        formRef.current?.setData(result)
      }
    })
  }, [formRef, update, user?.institution_id])

  return (
    <LayoutBaseDePagina title="Editar Instituição">
      <Box
        component={Paper}
        padding={2}
        margin={2}
        gap={2}
        display="flex"
        flexDirection="column"
        border="1px solid dodgerblue"
        borderRadius="20px"
      >
        {isLoading && (
          <Box>
            <LinearProgress
              variant="indeterminate"
              sx={{ height: '5px', borderRadius: '50px' }}
            />
          </Box>
        )}
        <VForm ref={formRef} onSubmit={handleSubmit} initialData={data}>
          <Grid container display="flex" direction="column" spacing={2}>
            <Grid container item justifyContent="center">
              <Grid item xs={12} sm={8}>
                <VTextField
                  fullWidth
                  size="small"
                  name="ombudsman_title"
                  label="Título da Ouvidoria"
                  disabled={isLoading}
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                />
              </Grid>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item xs={12} sm={8}>
                <VTextField
                  fullWidth
                  required
                  size="small"
                  name="contact_name"
                  label="Ouvidor Responsável"
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                />
              </Grid>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item xs={12} sm={8}>
                <VTextField
                  fullWidth
                  size="small"
                  name="address"
                  label="Endereço"
                  disabled={isLoading}
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                />
              </Grid>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item xs={12} sm={8}>
                <VTextField
                  fullWidth
                  size="small"
                  name="working_hour"
                  label="Horário de Funcionamento"
                  disabled={isLoading}
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              xs={12}
              direction="column"
              alignItems="center"
            >
              <Box
                display="flex"
                flexDirection="column"
                width={smDown ? '99%' : '66%'}
                borderRadius="10px"
                padding={2}
                gap={1}
              >
                <Typography variant="h4">Telefones Fixos</Typography>
                <Grid
                  container
                  item
                  justifyContent="center"
                  direction="column"
                  spacing={1}
                >
                  <Grid container item xs={12} justifyContent="center">
                    <Scope path="phones[0]">
                      <Grid item xs={11} sm={phones === 1 ? 4 : 5}>
                        <VTextField
                          fullWidth
                          required
                          size="small"
                          name="title"
                          label="Nome"
                          disabled={isLoading}
                          borderBottomLeft="50px"
                          borderTopLeft="50px"
                          borderBottomRight="0px"
                          borderTopRight="0px"
                          inputProps={{ maxLength: 10 }}
                        />
                      </Grid>
                      <Grid item xs={11} sm={7}>
                        <VTextField
                          fullWidth
                          required
                          size="small"
                          name="number"
                          label="Telfone Fixo"
                          placeholder="(DDD) 1234-5678"
                          disabled={isLoading}
                          borderBottomLeft="0px"
                          borderTopLeft="0px"
                          formatData={formatPhone}
                          inputProps={{ maxLength: 14 }}
                        />
                      </Grid>
                      {phones === 1 && (
                        <Grid
                          item
                          xs={1}
                          display="flex"
                          justifyContent="center"
                        >
                          <Tooltip title="adcionar telefone">
                            <IconButton onClick={() => setPhones(phones + 1)}>
                              <AddCircle color="success" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Scope>
                  </Grid>
                  {phones >= 2 && (
                    <Grid container item xs={12} justifyContent="center">
                      <Scope path="phones[1]">
                        <Grid item xs={11} sm={phones === 2 ? 4 : 5}>
                          <VTextField
                            fullWidth
                            required
                            size="small"
                            name="title"
                            label="Nome"
                            disabled={isLoading}
                            borderBottomLeft="50px"
                            borderTopLeft="50px"
                            borderBottomRight="0px"
                            borderTopRight="0px"
                            inputProps={{ maxLength: 10 }}
                          />
                        </Grid>
                        <Grid item xs={11} sm={7}>
                          <VTextField
                            fullWidth
                            required
                            size="small"
                            name="number"
                            label="Telfone Fixo"
                            placeholder="(DDD) 1234-5678"
                            disabled={isLoading}
                            borderBottomLeft="0px"
                            borderTopLeft="0px"
                            formatData={formatPhone}
                            inputProps={{ maxLength: 14 }}
                          />
                        </Grid>
                        {phones === 2 && (
                          <Box display="flex" flexDirection="row">
                            <Tooltip title="adcionar telefone">
                              <IconButton onClick={() => setPhones(phones + 1)}>
                                <AddCircle color="success" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="remover telefone">
                              <IconButton onClick={() => setPhones(phones - 1)}>
                                <RemoveCircle color="error" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Scope>
                    </Grid>
                  )}
                  {phones >= 3 && (
                    <Grid container item xs={12} justifyContent="center">
                      <Scope path="phones[2]">
                        <Grid item xs={11} sm={phones === 3 ? 4 : 5}>
                          <VTextField
                            fullWidth
                            required
                            size="small"
                            name="title"
                            label="Nome"
                            disabled={isLoading}
                            borderBottomLeft="50px"
                            borderTopLeft="50px"
                            borderBottomRight="0px"
                            borderTopRight="0px"
                            inputProps={{ maxLength: 10 }}
                          />
                        </Grid>
                        <Grid item xs={11} sm={7}>
                          <VTextField
                            fullWidth
                            required
                            size="small"
                            name="number"
                            label="Telfone Fixo"
                            placeholder="(DDD) 1234-5678"
                            disabled={isLoading}
                            borderBottomLeft="0px"
                            borderTopLeft="0px"
                            formatData={formatPhone}
                            inputProps={{ maxLength: 14 }}
                          />
                        </Grid>
                        {phones === 3 && (
                          <Box display="flex" flexDirection="row">
                            <Tooltip title="adcionar telefone">
                              <IconButton onClick={() => setPhones(phones + 1)}>
                                <AddCircle color="success" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="remover telefone">
                              <IconButton onClick={() => setPhones(phones - 1)}>
                                <RemoveCircle color="error" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Scope>
                    </Grid>
                  )}
                  {phones >= 4 && (
                    <Grid container item xs={12} justifyContent="center">
                      <Scope path="phones[3]">
                        <Grid item xs={11} sm={phones === 4 ? 4 : 5}>
                          <VTextField
                            fullWidth
                            required
                            size="small"
                            name="title"
                            label="Nome"
                            disabled={isLoading}
                            borderBottomLeft="50px"
                            borderTopLeft="50px"
                            borderBottomRight="0px"
                            borderTopRight="0px"
                            inputProps={{ maxLength: 10 }}
                          />
                        </Grid>
                        <Grid item xs={11} sm={7}>
                          <VTextField
                            fullWidth
                            required
                            size="small"
                            name="number"
                            label="Telfone Fixo"
                            placeholder="(DDD) 1234-5678"
                            disabled={isLoading}
                            borderBottomLeft="0px"
                            borderTopLeft="0px"
                            formatData={formatPhone}
                            inputProps={{ maxLength: 14 }}
                          />
                        </Grid>
                        {phones === 4 && (
                          <Box display="flex" flexDirection="row">
                            <Tooltip title="adcionar telefone">
                              <IconButton onClick={() => setPhones(phones + 1)}>
                                <AddCircle color="success" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="remover telefone">
                              <IconButton onClick={() => setPhones(phones - 1)}>
                                <RemoveCircle color="error" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Scope>
                    </Grid>
                  )}
                  {phones === 5 && (
                    <Grid container item xs={12} justifyContent="center">
                      <Scope path="phones[4]">
                        <Grid item xs={11} sm={phones === 5 ? 4 : 5}>
                          <VTextField
                            fullWidth
                            required
                            size="small"
                            name="title"
                            label="Nome"
                            disabled={isLoading}
                            borderBottomLeft="50px"
                            borderTopLeft="50px"
                            borderBottomRight="0px"
                            borderTopRight="0px"
                            inputProps={{ maxLength: 10 }}
                          />
                        </Grid>
                        <Grid item xs={11} sm={7}>
                          <VTextField
                            fullWidth
                            required
                            size="small"
                            name="number"
                            label="Telfone Fixo"
                            placeholder="(DDD) 1234-5678"
                            disabled={isLoading}
                            borderBottomLeft="0px"
                            borderTopLeft="0px"
                            formatData={formatPhone}
                            inputProps={{ maxLength: 14 }}
                          />
                        </Grid>
                        {phones === 5 && (
                          <Grid
                            item
                            xs={1}
                            display="flex"
                            justifyContent="center"
                          >
                            <Tooltip title="remover telefone">
                              <IconButton onClick={() => setPhones(phones - 1)}>
                                <RemoveCircle color="error" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Scope>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>

            <Grid
              container
              item
              justifyContent="center"
              direction="column"
              spacing={1}
              alignItems="center"
            >
              <Box
                display="flex"
                flexDirection="column"
                width={smDown ? '99%' : '66%'}
                borderRadius="10px"
                padding={2}
                gap={1}
              >
                <Typography variant="h4">Números de WhatsApp</Typography>
                <Grid container item xs={12} justifyContent="center">
                  <Scope path="cellphones[0]">
                    <Grid item xs={11} sm={cellPhones === 1 ? 4 : 5}>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="title"
                        label="Nome"
                        disabled={isLoading}
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                        borderBottomRight="0px"
                        borderTopRight="0px"
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                    <Grid item xs={11} sm={7}>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="number"
                        label="Número"
                        placeholder="(DDD) 9 1234-5678"
                        disabled={isLoading}
                        borderBottomLeft="0px"
                        borderTopLeft="0px"
                        inputProps={{ maxLength: 15 }}
                        formatData={formatCellPhone}
                      />
                    </Grid>
                    {cellPhones === 1 && (
                      <Grid item xs={1} display="flex" justifyContent="center">
                        <Tooltip title="adcionar celular">
                          <IconButton
                            onClick={() => setCellPhones(cellPhones + 1)}
                          >
                            <AddCircle color="success" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Scope>
                </Grid>
                {cellPhones >= 2 && (
                  <Grid container item xs={12} justifyContent="center">
                    <Scope path="cellphones[1]">
                      <Grid item xs={11} sm={cellPhones === 2 ? 4 : 5}>
                        <VTextField
                          fullWidth
                          required
                          size="small"
                          name="title"
                          label="Nome"
                          disabled={isLoading}
                          borderBottomLeft="50px"
                          borderTopLeft="50px"
                          borderBottomRight="0px"
                          borderTopRight="0px"
                          inputProps={{ maxLength: 10 }}
                        />
                      </Grid>
                      <Grid item xs={11} sm={7}>
                        <VTextField
                          fullWidth
                          required
                          size="small"
                          name="number"
                          label="Número"
                          placeholder="(DDD) 9 1234-5678"
                          disabled={isLoading}
                          borderBottomLeft="0px"
                          borderTopLeft="0px"
                          inputProps={{ maxLength: 15 }}
                          formatData={formatCellPhone}
                        />
                      </Grid>
                      {cellPhones === 2 && (
                        <Box display="flex" flexDirection="row">
                          <Tooltip title="adcionar celular">
                            <IconButton
                              onClick={() => setCellPhones(cellPhones + 1)}
                            >
                              <AddCircle color="success" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="remover celular">
                            <IconButton
                              onClick={() => setCellPhones(cellPhones - 1)}
                            >
                              <RemoveCircle color="error" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </Scope>
                  </Grid>
                )}
                {cellPhones >= 3 && (
                  <Grid container item xs={12} justifyContent="center">
                    <Scope path="cellphones[2]">
                      <Grid item xs={11} sm={cellPhones === 3 ? 4 : 5}>
                        <VTextField
                          fullWidth
                          required
                          size="small"
                          name="title"
                          label="Nome"
                          disabled={isLoading}
                          borderBottomLeft="50px"
                          borderTopLeft="50px"
                          borderBottomRight="0px"
                          borderTopRight="0px"
                          inputProps={{ maxLength: 10 }}
                        />
                      </Grid>
                      <Grid item xs={11} sm={7}>
                        <VTextField
                          fullWidth
                          required
                          size="small"
                          name="number"
                          label="Número"
                          placeholder="(DDD) 9 1234-5678"
                          disabled={isLoading}
                          borderBottomLeft="0px"
                          borderTopLeft="0px"
                          inputProps={{ maxLength: 15 }}
                          formatData={formatCellPhone}
                        />
                      </Grid>
                      {cellPhones === 3 && (
                        <Box display="flex" flexDirection="row">
                          <Tooltip title="adcionar celular">
                            <IconButton
                              onClick={() => setCellPhones(cellPhones + 1)}
                            >
                              <AddCircle color="success" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="remover celular">
                            <IconButton
                              onClick={() => setCellPhones(cellPhones - 1)}
                            >
                              <RemoveCircle color="error" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </Scope>
                  </Grid>
                )}
                {cellPhones >= 4 && (
                  <Grid container item xs={12} justifyContent="center">
                    <Scope path="cellphones[3]">
                      <Grid item xs={11} sm={cellPhones === 4 ? 4 : 5}>
                        <VTextField
                          fullWidth
                          required
                          size="small"
                          name="title"
                          label="Nome"
                          disabled={isLoading}
                          borderBottomLeft="50px"
                          borderTopLeft="50px"
                          borderBottomRight="0px"
                          borderTopRight="0px"
                          inputProps={{ maxLength: 10 }}
                        />
                      </Grid>
                      <Grid item xs={11} sm={7}>
                        <VTextField
                          fullWidth
                          required
                          size="small"
                          name="number"
                          label="Número"
                          placeholder="(DDD) 9 1234-5678"
                          disabled={isLoading}
                          borderBottomLeft="0px"
                          borderTopLeft="0px"
                          inputProps={{ maxLength: 15 }}
                          formatData={formatCellPhone}
                        />
                      </Grid>
                      {cellPhones === 4 && (
                        <Box display="flex" flexDirection="row">
                          <Tooltip title="adcionar celular">
                            <IconButton
                              onClick={() => setCellPhones(cellPhones + 1)}
                            >
                              <AddCircle color="success" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="remover celular">
                            <IconButton
                              onClick={() => setCellPhones(cellPhones - 1)}
                            >
                              <RemoveCircle color="error" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </Scope>
                  </Grid>
                )}
                {cellPhones === 5 && (
                  <Grid container item xs={12} justifyContent="center">
                    <Scope path="cellphones[4]">
                      <Grid item xs={11} sm={cellPhones === 5 ? 4 : 5}>
                        <VTextField
                          fullWidth
                          required
                          size="small"
                          name="title"
                          label="Nome"
                          disabled={isLoading}
                          borderBottomLeft="50px"
                          borderTopLeft="50px"
                          borderBottomRight="0px"
                          borderTopRight="0px"
                          inputProps={{ maxLength: 10 }}
                        />
                      </Grid>
                      <Grid item xs={11} sm={7}>
                        <VTextField
                          fullWidth
                          required
                          size="small"
                          name="number"
                          label="Número"
                          placeholder="(DDD) 9 1234-5678"
                          disabled={isLoading}
                          borderBottomLeft="0px"
                          borderTopLeft="0px"
                          inputProps={{ maxLength: 15 }}
                          formatData={formatCellPhone}
                        />
                      </Grid>
                      {cellPhones === 5 && (
                        <Grid
                          item
                          xs={1}
                          display="flex"
                          justifyContent="center"
                        >
                          <Tooltip title="remover celular">
                            <IconButton
                              onClick={() => setCellPhones(cellPhones - 1)}
                            >
                              <RemoveCircle color="error" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Scope>
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box justifyContent="center" display="flex">
            <Button type="submit" disabled={isLoading}>
              Atualizar
            </Button>
          </Box>
        </VForm>
      </Box>
    </LayoutBaseDePagina>
  )
}

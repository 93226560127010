/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Modal,
  Paper,
  Button,
  useTheme,
  FormControl,
  useMediaQuery,
  LinearProgress,
  InputLabel,
  FormControlLabel,
  IconButton,
  Avatar,
  Tooltip,
  MenuItem,
  Divider,
  Typography,
  Switch,
  TextField,
} from '@mui/material'

import {
  UserSacProfile,
  createSacProfile,
  updateSacProfile,
} from '../../../../shared/services/api/sac-services'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoPromise,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { ModalHeader } from '../../../../shared/components/modal/Header'
import { CameraAlt, Cancel, Delete, Save } from '@mui/icons-material'
import {
  VForm,
  VSelect,
  VSwitch,
  VTextField,
  useVForm,
} from '../../../../shared/forms'
import { formatToCNPJ, formatToCPF } from 'brazilian-values'
import { AutocompleteSac } from '../components/AutocompleteSacs'
import { Scope } from '@unform/core'
import { VHiddenInput } from '../../../../shared/forms/HidenInput'
import { formValidationSchema } from './validate'
import { ValidationError } from 'yup'
import { updateInternalById } from '../../../../shared/services/api'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'
import { AutocompleteSacSectors } from '../components/AutocompleteSacSectors'

interface FormularioProps {
  show: boolean
  create: boolean
  onClose(): void
  data?: UserSacProfile
  institution: string | number
  update: React.MutableRefObject<boolean>
}

export const Formulario: React.FC<FormularioProps> = ({
  data,
  show,
  create,
  update,
  onClose,
  institution,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { formRef } = useVForm()
  const { thisModule } = useModuleContext()

  const [changePassword, setChangePassword] = useState(false)
  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [modules, setmodules] = useState<
    Array<{ label: string; value: number }>
  >([])
  const [image, setImage] = React.useState<any>()
  const [imageURL, setImageURL] = React.useState('')
  const [documentType, setDocumentType] = React.useState(1)
  const form = new FormData()

  useEffect(() => {
    if (!create && data) {
      setDocumentType(data.document_type)
      setmodules(
        data.sac_modules.map((sac) => ({
          label: sac.customer_service.sac_slug,
          value: sac.customer_service.id,
        })),
      )
    }
  }, [data, create, show])

  function handleClose() {
    setChangePassword(false)
    setPassword('')
    setRePassword('')
    form.delete('image')
    setImage(undefined)
    setDocumentType(1)
    setImageURL('')
    setmodules([])
    onClose()
  }

  function handleSubmit(formData: any) {
    setIsLoading(true)
    formData.institution_id = Number(institution)

    if (changePassword && password !== rePassword) {
      return NotificacaoError('As senhas não coincidem')
    } else if (changePassword && password === rePassword) {
      formData.password = password
    }

    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData) => {
        if (create) {
          createSacProfile(validatedData).then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              update.current = true
              NotificacaoSucesso('Perfil criado com successo')
              if (image) {
                form.append('image', image, image.name)
                NotificacaoPromise(
                  updateInternalById(result.id, form),
                  'Enviando foto de perfil',
                  'Foto de perfil enviada com sucesso!',
                  'Erro ao enviar foto de perfil',
                )
              }
            }
            handleClose()
          })
        } else {
          if (data) {
            updateSacProfile(data.id, formData).then((result) => {
              setIsLoading(false)
              if (result instanceof Error) {
                NotificacaoError(result.message)
              } else {
                update.current = true
                NotificacaoSucesso('Perfil atualizado com successo')
                if (image) {
                  form.append('image', image, image.name)
                  NotificacaoPromise(
                    updateInternalById(result.id, form),
                    'Atualizando foto de perfil',
                    'Foto de perfil atualizada com sucesso!',
                    'Erro ao atualizar foto de perfil',
                  )
                }
                handleClose()
              }
            })
          }
        }
      })
      .catch((errors: ValidationError) => {
        const validationErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validationErrors[error.path] = error.message
        })
        formRef.current?.setErrors(validationErrors)
        setIsLoading(false)
      })
  }

  return (
    <Modal onClose={handleClose} open={show}>
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <input
          hidden
          id="image-input"
          accept="image/*"
          type="file"
          multiple={false}
          onChange={(e) => {
            if (e.target.files?.length) {
              const file = e.target.files[0]
              if (imageURL !== '') {
                URL.revokeObjectURL(imageURL)
              }
              setImageURL(() => URL.createObjectURL(file))
              setImage(file)
            }
          }}
        />
        <ModalHeader
          showCloseButton
          title={create ? 'Novo perfil' : 'Editar perfil'}
          onClose={handleClose}
        />
        <Divider />
        {isLoading && <LinearProgress sx={{ mt: 2 }} />}
        <Box margin={1}>
          <VForm
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={
              create
                ? {
                    document_type: 1,
                  }
                : {
                    ...data,
                    sacs: data?.sac_modules.map(
                      (customerService) =>
                        customerService.customer_service.sac_slug,
                    ),
                  }
            }
          >
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={2}>
                <Grid
                  item
                  xs={12}
                  gap={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  <Tooltip title="Foto de perfil">
                    <Avatar
                      onClick={() =>
                        document.getElementById('image-input')?.click()
                      }
                      src={
                        create
                          ? imageURL
                          : image
                          ? imageURL
                          : data?.profile_picture
                      }
                      sx={{
                        width: theme.spacing(12),
                        height: theme.spacing(12),
                        border: '2px solid grey',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                  <Box
                    display="flex"
                    flex="1"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                  >
                    <Button
                      disableElevation
                      variant="contained"
                      startIcon={<CameraAlt />}
                      onClick={() =>
                        document.getElementById('image-input')?.click()
                      }
                    >
                      foto de perfil
                    </Button>
                    {image && (
                      <Tooltip title="Remover foto">
                        <IconButton
                          onClick={() => {
                            URL.revokeObjectURL(imageURL)
                            setImage(() => undefined)
                            setImageURL(() => '')
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <VTextField
                    required
                    fullWidth
                    size="small"
                    name="username"
                    label="Nome"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <VTextField
                    required
                    fullWidth
                    size="small"
                    name="email"
                    label="E-mail"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid container item xs={12} sm={6}>
                  <Grid item xs={6} md={4} lg={3}>
                    <VSelect
                      fullWidth
                      sx={{
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                      }}
                      size="small"
                      name="document_type"
                      myChange={(e) => setDocumentType(e)}
                    >
                      <MenuItem value={1}>CPF</MenuItem>
                      <MenuItem value={2}>CNPJ</MenuItem>
                    </VSelect>
                  </Grid>
                  <Grid item md={8} lg={9} xs={6}>
                    <VTextField
                      fullWidth
                      size="small"
                      borderBottomLeft="0px"
                      borderBottomRight="50px"
                      borderTopLeft="0px"
                      inputProps={{
                        maxLength: documentType === 1 ? 14 : 18,
                      }}
                      formatData={
                        documentType === 1 ? formatToCPF : formatToCNPJ
                      }
                      borderTopRight="50px"
                      name="document_number"
                      label="Documento"
                    />
                  </Grid>
                </Grid>

                <Grid container item spacing={2} xs={12} sm={6}>
                  <Grid item xs={12}>
                    <AutocompleteSac
                      institution={institution}
                      setModules={(e) => setmodules(e)}
                    />
                  </Grid>
                </Grid>

                <Grid container item spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormControlLabel
                      label="Gestor de Dados"
                      control={<VSwitch name="data_manager" size="small" />}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormControlLabel
                      label="Status"
                      control={<VSwitch name="is_active" size="small" />}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {modules.map((mdl, index) => (
                <Scope path={`sac_modules[${index}]`} key={index}>
                  <VHiddenInput name="sac_slug" value={mdl.label} />
                  <Divider sx={{ mt: 2 }} />
                  <Typography variant="h6" textAlign="center">
                    {mdl.label}
                  </Typography>
                  <Grid container item spacing={2} direction="column">
                    <Grid
                      container
                      item
                      spacing={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <FormControlLabel
                          label="Status"
                          control={<VSwitch name="status" size="small" />}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <FormControlLabel
                          label="Gestor RH do módulo"
                          control={<VSwitch name="hr_manager" size="small" />}
                        />
                      </Grid>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth size="small">
                            <InputLabel id={`label-${mdl.value}`}>
                              Perfil
                            </InputLabel>
                            <VSelect
                              required
                              size="small"
                              name="profile"
                              label="Perfil"
                              labelId={`label-${mdl.value}`}
                              sx={{
                                borderRadius: '50px',
                              }}
                            >
                              <MenuItem value={1}>Auxiliar</MenuItem>
                              <MenuItem value={2}>Master</MenuItem>
                              <MenuItem value={3}>Auditor</MenuItem>
                            </VSelect>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <AutocompleteSacSectors
                            customerService={mdl.value}
                            name="sectors"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Scope>
              ))}
              {!create && thisModule?.profile === 0 && (
                <Grid container item spacing={2} direction="column">
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          value={changePassword}
                          onChange={(_, check) => setChangePassword(check)}
                          size="small"
                        />
                      }
                      label="Alterar senha"
                    />
                  </Grid>
                  {changePassword && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          type="password"
                          autoComplete="new-password"
                          label="Senha"
                          size="small"
                          sx={{
                            fieldSet: {
                              borderRadius: '50px',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          value={rePassword}
                          type="password"
                          autoComplete="new-password"
                          onChange={(e) => setRePassword(e.target.value)}
                          fullWidth
                          required
                          label="Reinsira a senha"
                          size="small"
                          sx={{
                            fieldSet: {
                              borderRadius: '50px',
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
            <Box
              display="flex"
              flex="1"
              justifyContent="center"
              alignItems="center"
              gap={1}
              mt={2}
            >
              <Button
                type="submit"
                disableElevation
                variant="contained"
                startIcon={<Save />}
              >
                salvar
              </Button>
              <Button
                disableElevation
                variant="outlined"
                onClick={handleClose}
                startIcon={<Cancel />}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

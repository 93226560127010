import { MutableRefObject, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'
import {
  ILegislation,
  createLegislation,
  updateLegislationById,
} from '../../../shared/services/api'
import {
  VForm,
  VSelect,
  VSwitch,
  VTextArea,
  VTextField,
  useVForm,
} from '../../../shared/forms'
import { useAuthContext } from '../../../shared/contexts'
import { ModalHeader } from '../../../shared/components/modal/Header'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

interface IFormulario {
  show: boolean
  onClose: () => void
  create: boolean
  data: ILegislation
  update: MutableRefObject<boolean>
}

export const Formulario: React.FC<IFormulario> = ({
  show,
  onClose,
  create,
  data,
  update,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { user } = useAuthContext()
  const { formRef } = useVForm()
  const { ombudsmanModule, moduleTitle } = useModuleContext()

  const [formTitle, setFormTitle] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const canUpdate =
    ombudsmanModule?.profile === 2 || ombudsmanModule?.hr_manager
  const canNotUpdate = !canUpdate

  const handleSubmit = (formData: ILegislation) => {
    setIsLoading(true)

    if (create) {
      const currentModule = user?.modules.find(
        (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
      )
      if (currentModule?.profile === 0) {
        if (!localStorage.getItem('ADM_SELECTED_INSTITUTION')) {
          NotificacaoError(
            'Selecione Uma Instituição Antes de Criar uma Nova instância',
          )
          setIsLoading(false)
          return onClose()
        }
        formData.institution_id = Number(
          localStorage.getItem('ADM_SELECTED_INSTITUTION'),
        )
      } else {
        formData.institution_id = Number(user?.institution_id)
      }
    } else {
      formData.institution_id = Number(data?.institution_id)
    }

    create
      ? createLegislation(formData).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            NotificacaoSucesso('Legislação cadastrada com sucesso!')
            update.current = true
            onClose()
          }
        })
      : updateLegislationById(Number(data.id), formData).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            NotificacaoSucesso('Legislação atualizada com sucesso!')
            update.current = true
            onClose()
          }
        })
  }

  useEffect(() => {
    if (!create && data !== undefined)
      setFormTitle(`Legislação ${data.type === 1 ? 'Estadual' : 'Municipal'}`)
    else setFormTitle('Nova Legislação')
    setIsLoading(false)
  }, [create, data, show])

  return (
    <Modal onClose={onClose} open={show} aria-describedby="modal-title">
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader onClose={onClose} showCloseButton title={formTitle} />
        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{ borderRadius: '50px', height: '5px', mt: 1 }}
          />
        )}
        <Box margin={1} paddingTop={2}>
          <VForm
            ref={formRef}
            onSubmit={(formData) => handleSubmit(formData)}
            initialData={create ? { type: 2 } : data}
          >
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    disabled={canNotUpdate}
                    required
                    size="small"
                    label="Título da lei"
                    name="title"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl disabled={canNotUpdate} fullWidth>
                    <InputLabel id="type-label">Tipo</InputLabel>
                    <VSelect
                      fullWidth
                      required
                      size="small"
                      name="type"
                      label="Tipo"
                      labelId="type-label"
                      sx={{
                        borderRadius: '50px',
                      }}
                    >
                      <MenuItem value={1}>Estadual</MenuItem>
                      <MenuItem value={2}>Municipal</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    fullWidth
                    disabled={canNotUpdate}
                    required
                    size="small"
                    name="link"
                    label="Link"
                    placeholder="https://link.com.br/legislacao"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <VTextArea
                    name="text"
                    disabled={canNotUpdate}
                    minRows={8}
                    maxRows={12}
                    placeholder="Adcione uma pequena descrição"
                    ariaLabel="Descrição"
                    style={{
                      width: '100%',
                      borderRadius: '5px',
                      fontVariant: 'historical-forms',
                      font: 'message-box',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <FormControlLabel
                    disabled={true}
                    control={<VSwitch name="status" />}
                    label="Status"
                  />
                </Grid>
              </Grid>
            </Grid>
            {canUpdate && (
              <Box display="flex" justifyContent="right" gap={1}>
                <Button
                  disableElevation
                  type="submit"
                  variant="contained"
                  sx={{ borderRadius: '50px' }}
                >
                  enviar
                </Button>
                <Button
                  onClick={() => {
                    formRef.current?.reset()
                    onClose()
                  }}
                  sx={{ borderRadius: '50px' }}
                >
                  cancelar
                </Button>
              </Box>
            )}
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

import { FC, useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
} from '@mui/material'

import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  SacGroupingList,
  getSacGroupingById,
} from '../../../shared/services/api/sac-services'
import {
  FerramentasDeDetalhes,
  LegendaSumario,
  NotificacaoError,
} from '../../../shared/components'
import { Formulario } from './formulario/Formulario'
import { ExpandMore } from '@mui/icons-material'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'

export const DetalhesAgrupamentoSAC: FC = () => {
  const update = useRef(true)
  const navigate = useNavigate()
  const { id } = useParams<'id'>()
  const [data, setData] = useState<SacGroupingList>()
  const [viewForm, setViewForm] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [create, setCreate] = useState(true)

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getSacGroupingById(id).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
          navigate(-1)
        } else {
          update.current = false
          setData(result)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate, update.current])

  return (
    <LayoutBaseDePagina
      title={data ? data.name : 'carregando agrupemanto...'}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          mostrarBotaoNovo
          mostrarBotaoEditar
          mostrarBotaoVoltar
          aoClicarEmVoltar={() => navigate(-1)}
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
        />
      }
    >
      <Box margin={1}>
        {isLoading && <LinearProgress />}

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario icon="info_outlined" name="Informações" />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Status: </Typography>
                </Grid>
                <Grid item width="75px">
                  {FormatStatus(Boolean(data?.status))}
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Nome: </Typography>
                </Grid>
                <Grid item>
                  <Typography>{data?.name}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Ícone: </Typography>
                </Grid>
                <Grid item>
                  <Typography>{data?.icon.name}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Criado em: </Typography>
                </Grid>
                <Grid item>
                  <Typography>{FormatDateTime(data?.created_at)}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Atualizado em: </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {data?.created_at === data?.updated_at
                      ? 'Não houve alterações'
                      : FormatDateTime(data?.updated_at)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      {data && (
        <Formulario
          show={viewForm}
          create={create}
          update={update}
          institution={data.institution}
          data={create ? undefined : data}
          onClose={() => setViewForm(false)}
        />
      )}
    </LayoutBaseDePagina>
  )
}

/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

export interface IStates {
  id: number
  flag: string
  name: string
  initials: string
  created_at: string
  updated_at: string
}

export interface IStatesInstitutions {
  id: number
  flag: string
  name: string
  initials: string
}

export type IStatesdTotalCount = {
  data: IStates[]
  count: number
  next: string
  previous: string
  results: IStates[]
}

const getAllStates = async (
  item = '0',
  total = '10',
  filter = '',
  stateId = '',
): Promise<IStatesdTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/states/?total=${total}&item=${item}&search=${filter}&id=${stateId}`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return {
        data,
        results: data.results,
        count: data.count,
        next: data.next,
        previous: data.previous,
      }
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getStateById = async (id: number): Promise<IStates | Error> => {
  try {
    const relativeUrl = `/V1/states/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return data
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao listar registro',
    )
  }
}

const createState = async (formData: {}): Promise<string | Error> => {
  try {
    const relativeUrl = `/V1/states/`

    const { data } = await Api.post<IStates>(relativeUrl, formData)

    if (data) return data.id ? data.id.toString() : data.name

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const updateStateById = async (
  id: number,
  formData: {},
): Promise<IStates | Error> => {
  try {
    const relativeUrl = `/V1/states/${id}/`

    const { data } = await Api.patch<IStates>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

const getStatesWithInstitution = async (
  filter = '',
): Promise<IStatesInstitutions[] | Error> => {
  try {
    const relativeUrl = `/V1/externalusers/states/?search=${filter}`

    const { data } = await Api.get<IStatesInstitutions[]>(relativeUrl)

    if (data) return data

    return Error('Erro ao listar Estados')
  } catch (error) {
    console.log(error)
    return Error((error as { message: string }).message)
  }
}

export {
  getAllStates,
  getStateById,
  createState,
  updateStateById,
  getStatesWithInstitution,
}

import { FC } from 'react'
import { Modal, ModalHeader } from '../../../../shared/components'
import { SacData } from '../login/Login'
import { Box, Grid, Typography } from '@mui/material'
import {
  LocalPhone,
  MailOutline,
  PersonPin,
  PinDrop,
  QueryBuilder,
  WhatsApp,
} from '@mui/icons-material'

interface InfoModalProps {
  onClose: () => void
  show: boolean
  customerServiceData: SacData
}

export const InfoModal: FC<InfoModalProps> = ({
  onClose,
  show,
  customerServiceData,
}) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <Modal onClose={handleClose} show={show}>
      <ModalHeader
        title="Informações Gerais"
        onClose={handleClose}
        showCloseButton
      />

      <Box margin={1}>
        <Grid container spacing={2} direction="column">
          <Grid container item spacing={2} direction="row">
            <Grid
              item
              spacing={2}
              xs={12}
              display="flex"
              alignItems="center"
              gap={2}
            >
              <PersonPin fontSize="large" />
              <Typography fontSize="20px">
                Gestor(a): {customerServiceData.sac_responsible}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={2} direction="row">
            <Grid
              item
              spacing={2}
              xs={12}
              display="flex"
              alignItems="center"
              gap={2}
            >
              <QueryBuilder fontSize="large" />
              <Typography fontSize="20px">
                Horário de funcionamento: {customerServiceData.sac_working_hour}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={2} direction="row">
            <Grid
              item
              spacing={2}
              xs={12}
              display="flex"
              alignItems="center"
              gap={2}
            >
              <PinDrop fontSize="large" />
              <Typography fontSize="20px">
                Endereço: {customerServiceData.sac_address}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={2} direction="row">
            <Grid
              item
              spacing={2}
              xs={12}
              display="flex"
              alignItems="center"
              gap={2}
            >
              <MailOutline fontSize="large" />
              <Typography fontSize="20px">
                E-mail: {customerServiceData.sac_email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={2} direction="row">
            <Grid
              item
              spacing={2}
              xs={12}
              display="flex"
              justifyContent="center"
              gap={2}
              direction="column"
            >
              <Box display="flex" gap={2} alignItems="center">
                <LocalPhone fontSize="large" />
                <Typography fontSize="20px">Números de telefone:</Typography>
              </Box>
              {customerServiceData.sac_phones.map((phone, index) => (
                <Typography gap={1} key={index}>
                  {phone.title}
                  {': '}
                  {phone.number}
                </Typography>
              ))}
            </Grid>
          </Grid>
          <Grid container item spacing={2} direction="row">
            <Grid
              item
              spacing={2}
              xs={12}
              display="flex"
              justifyContent="center"
              gap={2}
              direction="column"
            >
              <Box display="flex" gap={2} alignItems="center">
                <WhatsApp fontSize="large" />
                <Typography fontSize="20px">Números de whatsapp:</Typography>
              </Box>
              {customerServiceData.sac_cellphones.map((cellphone, index) => (
                <Typography gap={1} key={index}>
                  {cellphone.title}
                  {': '}
                  {cellphone.number}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Grid,
  Modal,
  Paper,
  Button,
  Divider,
  MenuItem,
  useTheme,
  InputLabel,
  Typography,
  FormControl,
  useMediaQuery,
  LinearProgress,
} from '@mui/material'
import { FC, MutableRefObject, useEffect, useState } from 'react'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { VForm, VSelect, VTextField, useVForm } from '../../../../shared/forms'
import {
  IInstitutionsMigrated,
  createInstitutionMigration,
} from '../../../../shared/services/api'
import { Api } from '../../../../shared/services/api/axios-config'

interface IFormularioModalProps {
  show: boolean
  create: boolean
  onClose: () => void
  data?: IInstitutionsMigrated
  update: MutableRefObject<boolean>
}

export const FormularioModal: FC<IFormularioModalProps> = ({
  data,
  show,
  create,
  update,
  onClose,
}) => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const { formRef } = useVForm()

  const [loading, setLoading] = useState(true)
  const [title, setTitle] = useState('')

  const handleSubmit = (formData: any) => {
    createInstitutionMigration(formData).then((result) => {
      setLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        update.current = true
        formRef.current?.setData(result)
        NotificacaoSucesso('Relação de migração criada com sucesso')
        onClose()
      }
    })
  }

  useEffect(() => {
    if (create) setTitle('Nova migração de instituição')
    else setTitle('Atualizar migração de instituição')
    setLoading(false)
  }, [create])

  const myForm = new FormData()
  const [fileUsers, setFileUsers] = useState<any>()
  const [fileSectors, setFileSectors] = useState<any>()
  const [fileGrouping, setFileGrouping] = useState<any>()
  const [fileSubjects, setFileSubjects] = useState<any>()
  const [fileProtocols, setFileProtocols] = useState<any>()
  const [fileCategories, setFileCategories] = useState<any>()
  const [fileUserSectors, setFileUserSectors] = useState<any>()
  const [fileExternalUsers, setFileExternalUsers] = useState<any>()
  const [fileSubjectSectors, setFileSubjectSectors] = useState<any>()
  const [fileReplies, setFilesReplies] = useState<any>()
  const [fileUpdateReplies, setFileUpdateReplies] = useState<any>()
  const [fileUpdateProtocols, setFileUpdateProtocols] = useState<any>()

  return (
    <Modal
      open={show}
      onClose={() => {
        onClose()
        formRef.current?.reset()
      }}
    >
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        overflow="auto"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(35)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Typography textAlign="center" variant="h6">
          {title}
        </Typography>
        <Divider />
        <Box margin={2}>
          {loading && (
            <Box marginBottom={2}>
              <LinearProgress variant="indeterminate" />
            </Box>
          )}
          <VForm
            ref={formRef}
            onSubmit={(formData) => handleSubmit(formData)}
            initialData={create ? undefined : data}
          >
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="old_id"
                    type="number"
                    label="Antigo ID"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
                <Grid item xs={6}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="new_id"
                    type="number"
                    label="Novo ID"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="related-app-label">
                      App Relacionado
                    </InputLabel>
                    <VSelect
                      fullWidth
                      required
                      size="small"
                      name="related_app"
                      label="App Relacionado"
                      labelId="related-app-label"
                      sx={{ borderRadius: '50px' }}
                    >
                      <MenuItem value={1}>Participar</MenuItem>
                      <MenuItem value={2}>CI Digital</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Box
              display="flex"
              marginTop={2}
              gap={1}
              flex="1"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant="contained"
                disableElevation
                type="submit"
                disabled={loading}
              >
                enviar
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  formRef.current?.reset()
                  onClose()
                }}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>

        {/* Agrupamentos */}
        <Box>
          <input
            type="file"
            onChange={(e) =>
              setFileGrouping(e.target.files ? e.target.files[0] : undefined)
            }
          />
          <Button
            onClick={() => {
              myForm.append('group_backup', fileGrouping, 'group_backup.xlsx')
              Api.post('/V1/migrations/groupings/', myForm)
                .then(() => NotificacaoSucesso('Sucesso'))
                .catch((error) => NotificacaoError(error.message))
            }}
          >
            Enviar Agrupamentos
          </Button>
        </Box>

        {/* Setores */}
        <Box>
          <input
            type="file"
            onChange={(e) =>
              setFileSectors(e.target.files ? e.target.files[0] : undefined)
            }
          />
          <Button
            onClick={() => {
              myForm.append(
                'sectors_backup',
                fileSectors,
                'sectors_backup.xlsx',
              )
              myForm.append('related_app', '2')
              Api.post('/V1/migrations/sectors/', myForm)
                .then(() => NotificacaoSucesso('Sucesso'))
                .catch((error) => NotificacaoError(error.message))
            }}
          >
            Enviar Setores
          </Button>
        </Box>

        {/* Assuntos */}
        <Box>
          <input
            type="file"
            onChange={(e) =>
              setFileSubjects(e.target.files ? e.target.files[0] : undefined)
            }
          />
          <Button
            onClick={() => {
              myForm.append(
                'subjects_backup',
                fileSubjects,
                'subjects_backup.xlsx',
              )
              myForm.append('related_app', '2')
              Api.post('/V1/migrations/subjects/', myForm)
                .then(() => NotificacaoSucesso('Sucesso'))
                .catch((error) => NotificacaoError(error.message))
            }}
          >
            Enviar Assuntos
          </Button>
        </Box>

        {/* Assunto -> Setores */}
        <Box>
          <input
            type="file"
            onChange={(e) =>
              setFileSubjectSectors(
                e.target.files ? e.target.files[0] : undefined,
              )
            }
          />
          <Button
            onClick={() => {
              myForm.append(
                'sectors_subject_backup',
                fileSubjectSectors,
                'sectors_subject_backup.xlsx',
              )
              Api.post('/V1/migrations/subjects/sectors/', myForm)
                .then(() => NotificacaoSucesso('Sucesso'))
                .catch((error) => NotificacaoError(error.message))
            }}
          >
            {'Enviar Relação Assunto -> Setores'}
          </Button>
        </Box>

        {/* Categorias/Tipologias */}
        <Box>
          <input
            type="file"
            onChange={(e) =>
              setFileCategories(e.target.files ? e.target.files[0] : undefined)
            }
          />
          <Button
            onClick={() => {
              myForm.append(
                'categories_backup',
                fileCategories,
                'categories_backup.xlsx',
              )
              myForm.append('related_app', '2')
              Api.post('/V1/migrations/categories/', myForm)
                .then(() => NotificacaoSucesso('Sucesso'))
                .catch((error) => NotificacaoError(error.message))
            }}
          >
            categorias/tipologias
          </Button>
        </Box>

        {/* Usuário Externo */}
        <Box>
          <input
            type="file"
            onChange={(e) =>
              setFileExternalUsers(
                e.target.files ? e.target.files[0] : undefined,
              )
            }
          />
          <Button
            onClick={() => {
              myForm.append(
                'externalusers_nackup',
                fileExternalUsers,
                'externalusers_nackup.xlsx',
              )
              myForm.append('related_app', '2')
              Api.post('/V1/mogrations/externalusers/', myForm)
                .then(() => NotificacaoSucesso('Sucesso'))
                .catch((error) => NotificacaoError(error.message))
            }}
          >
            usuarios externos
          </Button>
        </Box>

        {/* Protocols */}
        <Box>
          <input
            type="file"
            onChange={(e) =>
              setFileProtocols(e.target.files ? e.target.files[0] : undefined)
            }
          />
          <Button
            onClick={() => {
              myForm.append(
                'protocols_backup',
                fileProtocols,
                'protocols_backup.xlsx',
              )
              myForm.append('related_app', '2')
              Api.post('/V1/migrations/protocols/', myForm)
                .then(() => NotificacaoSucesso('Sucesso'))
                .catch((error) => NotificacaoError(error.message))
            }}
          >
            protocolos
          </Button>
        </Box>

        {/* Usuários */}
        <Box>
          <input
            type="file"
            onChange={(e) =>
              setFileUsers(e.target.files ? e.target.files[0] : undefined)
            }
          />
          <Button
            onClick={() => {
              myForm.append('users_backup', fileUsers, 'users_backup.xlsx')
              myForm.append('related_app', '2')
              Api.post('/V1/migrations/users/', myForm)
                .then(() => NotificacaoSucesso('Sucesso'))
                .catch((error) => NotificacaoError(error.message))
            }}
          >
            usuários
          </Button>
        </Box>

        {/* Usuário -> Setores */}
        <Box>
          <input
            type="file"
            onChange={(e) =>
              setFileUserSectors(e.target.files ? e.target.files[0] : undefined)
            }
          />
          <Button
            onClick={() => {
              myForm.append(
                'user_sectors_backup',
                fileUserSectors,
                'user_sectors.xlsx',
              )
              myForm.append('related_app', '2')
              Api.post('/V1/migrations/users/sectors/', myForm)
                .then(() => NotificacaoSucesso('Sucesso'))
                .catch((error) => NotificacaoError(error.message))
            }}
          >
            {'usuários -> setores'}
          </Button>
        </Box>

        {/* Respostas */}
        <Box>
          <input
            type="file"
            onChange={(e) =>
              setFilesReplies(e.target.files ? e.target.files[0] : undefined)
            }
          />
          <Button
            onClick={() => {
              myForm.append('replies_backup', fileReplies, 'replies.xlsx')
              myForm.append('related_app', '2')
              Api.post('/V1/migrations/protocols/replies/', myForm)
                .then(() => NotificacaoSucesso('Sucesso'))
                .catch((error) => NotificacaoError(error.message))
            }}
          >
            respostas
          </Button>
        </Box>

        {/* Atualizar Respostas */}
        <Box>
          <input
            type="file"
            onChange={(e) =>
              setFileUpdateReplies(
                e.target.files ? e.target.files[0] : undefined,
              )
            }
          />
          <Button
            onClick={() => {
              myForm.append(
                'update_replies',
                fileUpdateReplies,
                'update_replies.xlsx',
              )
              myForm.append('related_app', '2')
              Api.post('/V1/migrations/protocols/update_replies/', myForm)
                .then(() => NotificacaoSucesso('Sucesso'))
                .catch((error) => NotificacaoError(error.message))
            }}
          >
            atualizar respostas
          </Button>
        </Box>

        {/* Atualizar Protocolos */}
        <Box>
          <input
            type="file"
            onChange={(e) =>
              setFileUpdateProtocols(
                e.target.files ? e.target.files[0] : undefined,
              )
            }
          />
          <Button
            onClick={() => {
              myForm.append(
                'protocols_backup',
                fileUpdateProtocols,
                'protocols_backup.xlsx',
              )
              myForm.append('related_app', '2')
              Api.post('/V1/migrations/protocols/update_protocols/', myForm)
                .then(() => NotificacaoSucesso('Sucesso'))
                .catch((error) => NotificacaoError(error.message))
            }}
          >
            atualizar protocolos
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

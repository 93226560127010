import { Api } from '../axios-config'

export interface IGroupingHistory {
  field: string
  type: '+' | '~'
  date: string
  user?: string
  old?: string
  new?: string
}

const getGroupingHistory = async (
  groupId: number,
): Promise<IGroupingHistory[] | Error> => {
  try {
    const relativeUrl = `/V1/groupings/${groupId}/historical/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar histórico')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export { getGroupingHistory }

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useEffect, useRef } from 'react'
import {
  ModalHeader,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Switch,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { VForm, VSelect, VTextField, useVForm } from '../../../../shared/forms'
import {
  IExternalUsers,
  getSelf,
  updateSelf,
} from '../../../../shared/services/api'
import { formatToCNPJ, formatToCPF } from 'brazilian-values'
import { useExternalAuthContext } from '../../../../shared/contexts'
import { formatCellPhone } from '../../../../shared/utils'
import { Cancel, Save } from '@mui/icons-material'

export const Perfil: FC<{ token?: string | null }> = ({ token }) => {
  const { user } = useExternalAuthContext()

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const update = useRef(true)

  const [data, setData] = useState<IExternalUsers>()

  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(true)
  const [changePassword, setChangePassword] = useState(false)
  const [documentType, setDocumentType] = useState<number>(1)

  const updateData = new FormData()

  const handleSubmit = (formData: any) => {
    setIsLoading(true)
    updateData.append('name', formData.name)
    updateData.append('email', formData.email)
    updateData.append('gender', formData.gender)
    updateData.append('escolarity', formData.escolarity)
    updateData.append('number', formData.number)

    if (changePassword) {
      updateData.append('password', formData.password)
    }

    updateSelf(user.token, updateData).then((result) => {
      setIsLoading(false)
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        update.current = true
        NotificacaoSucesso('Perfil atualizado com sucesso')
      }
    })
  }

  useEffect(() => {
    setIsLoading(true)
    if (token) {
      getSelf(token).then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setData(result)
          formRef.current?.setData(result)
        }
      })
    }
  }, [update.current, token])

  return (
    <Box display="flex" flex="1 1" justifyContent="center">
      <Box
        component={Paper}
        borderRadius="20px"
        width={
          xsDown
            ? '90vw'
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader title="Editar perfil" />

        <Box my={1} px={1}>
          <VForm onSubmit={handleSubmit} initialData={data} ref={formRef}>
            <Grid container direction="column" spacing={2}>
              {isLoading && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}
              <Grid container item>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="name"
                    label="Nome"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    size="small"
                    name="email"
                    label="Email"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                display="flex"
                direction="row"
                alignItems="center"
              >
                <Grid item xs={6} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id="type-label">Tipo</InputLabel>
                    <VSelect
                      fullWidth
                      required
                      size="small"
                      label="Tipo"
                      disabled
                      labelId="type-label"
                      name="document_type"
                      useDefaultValue
                      myChange={(e) => setDocumentType(e)}
                      sx={{
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                      }}
                    >
                      <MenuItem value={1}>CPF</MenuItem>
                      <MenuItem value={2}>CNPJ</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3.5}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="document_number"
                    disabled
                    label="Documento"
                    formatData={documentType === 1 ? formatToCPF : formatToCNPJ}
                    inputProps={{
                      maxLength: documentType === 1 ? 14 : 18,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6.5}
                  paddingLeft={!mdDown ? 2 : 0}
                  paddingTop={mdDown ? 2 : 0}
                >
                  <VTextField
                    fullWidth
                    size="small"
                    type="date"
                    disabled
                    name="birth_date"
                    label="Data de Nascimento"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="gender-label">Gênero</InputLabel>
                    <VSelect
                      fullWidth
                      required
                      size="small"
                      name="gender"
                      label="Gênero"
                      labelId="gender-label"
                      useDefaultValue
                      sx={{
                        borderRadius: '50px',
                      }}
                    >
                      <MenuItem value={1}>Masculino</MenuItem>
                      <MenuItem value={2}>Feminino</MenuItem>
                      <MenuItem value={3}>Não Binário</MenuItem>
                      <MenuItem value={4}>Prefiro Não Responder</MenuItem>
                      <MenuItem value={5}>Instituição</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="school-label">Escolaridade</InputLabel>
                    <VSelect
                      fullWidth
                      size="small"
                      name="escolarity"
                      label="Escolaridade"
                      labelId="school-label"
                      useDefaultValue
                      sx={{
                        borderRadius: '50px',
                      }}
                    >
                      <MenuItem value={1}>Não Alfabetizado</MenuItem>
                      <MenuItem value={2}>
                        Ensino Fundamental Incompleto
                      </MenuItem>
                      <MenuItem value={3}>Ensino Fundamental Completo</MenuItem>
                      <MenuItem value={4}>Ensino Médio incompleto</MenuItem>
                      <MenuItem value={5}>Ensino Médio Completo</MenuItem>
                      <MenuItem value={6}>Técnico Incompleto</MenuItem>
                      <MenuItem value={7}>Técnico Completo</MenuItem>
                      <MenuItem value={8}>Superior Incompleto</MenuItem>
                      <MenuItem value={9}>Superior Completo</MenuItem>
                      <MenuItem value={10}>Prefiro Não Informar</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="number"
                    label="Celular"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                    placeholder="(DDD) 99999-99999"
                    formatData={formatCellPhone}
                    inputProps={{
                      maxLength: 15,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2} direction="column">
                <Grid item xs={12} display="flex" justifyContent="center">
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        value={changePassword}
                        onChange={(_, e) => setChangePassword(e)}
                      />
                    }
                    label="Alterar Senha?"
                  />
                </Grid>
                {changePassword && (
                  <Grid container item spacing={2} direction="column">
                    <Grid item xs={12}>
                      <VTextField
                        autoComplete="new-password"
                        fullWidth
                        required
                        size="small"
                        type="password"
                        name="password"
                        label="Senha"
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        type="password"
                        name="re_password"
                        label="Repita a Senha"
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {isLoading && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}
            </Grid>
            <Box
              display="flex"
              flex="1"
              justifyContent="center"
              alignItems="center"
              mt={2}
              gap={1}
            >
              <Button
                type="submit"
                variant="contained"
                startIcon={<Save />}
                disabled={isLoading}
              >
                salvar
              </Button>
              <Button
                variant="outlined"
                startIcon={<Cancel />}
                onClick={() => {
                  formRef.current?.setData(data || {})
                }}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Box>
  )
}

/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'
import { IStates } from '../estados-services/EstadosServices'

export interface ICities {
  id: number
  name: string
  state_id: IStates
  flag?: string
  created_at: string
  updated_at: string
}

export type ICitiesTotalCount = {
  data: ICities[]
  count: number
  next: string
  previous: string
  results: ICities[]
}

export interface ICitiesInstitutions {
  id: number
  name: string
  flag?: string
  state_id: number
}

const getAllCities = async (
  item = '0',
  total = '10',
  filter = '',
  cityId = '',
  stateId = '',
): Promise<ICitiesTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/cities/?total=${total}&item=${item}&search=${filter}&id=${cityId}&state_id=${stateId}`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return {
        data,
        results: data.results,
        count: data.count,
        next: data.next,
        previous: data.previous,
      }
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getCityById = async (id: number): Promise<ICities | Error> => {
  try {
    const relativeUrl = `/V1/cities/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return data
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao listar registro',
    )
  }
}

const createCity = async (formData: {}): Promise<string | Error> => {
  try {
    const relativeUrl = `/V1/cities/`

    const { data } = await Api.post<ICities>(relativeUrl, formData)

    if (data) return data.id ? data.id.toString() : data.name

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const updateCityById = async (
  id: number,
  formData: {},
): Promise<ICities | Error> => {
  try {
    const relativeUrl = `/V1/cities/${id}/`

    const { data } = await Api.patch<ICities>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

const getCitiesWithInstitution = async (
  state: number,
  filter = '',
): Promise<ICitiesInstitutions[] | Error> => {
  try {
    const relativeUrl = `/V1/externalusers/cities/${state}/?search=${filter}`

    const { data } = await Api.get<ICitiesInstitutions[]>(relativeUrl)

    if (data) return data

    return Error('Erro ao listar Estados')
  } catch (error) {
    console.log(error)
    return Error((error as { message: string }).message)
  }
}

export {
  getAllCities,
  getCityById,
  createCity,
  updateCityById,
  getCitiesWithInstitution,
}

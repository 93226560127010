/* eslint-disable @typescript-eslint/no-explicit-any */
// Global Imports
import { FC } from 'react'
import {
  Box,
  ButtonBase,
  Grid,
  LinearProgress,
  Typography,
  useTheme,
} from '@mui/material'

// Local Imports
import { FormatDateTime, FormatStatus } from '../../../../shared/utils'
import { ISubjects } from '../../../../shared/services/api'
import { useNavigate } from 'react-router-dom'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'

interface IDisplayDataProps {
  isLoading: boolean
  data: ISubjects | undefined
}

export const DisplayData: FC<IDisplayDataProps> = ({ isLoading, data }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const { moduleTitle } = useModuleContext()

  return (
    <Grid container spacing={2} direction="column">
      {isLoading && (
        <Grid item>
          <LinearProgress
            variant="indeterminate"
            sx={{
              borderRadius: '50px',
              height: '5px',
            }}
          />
        </Grid>
      )}

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Status: </Typography>
        </Grid>
        <Grid item width="75px">
          <Typography>{FormatStatus(Boolean(data?.status))}</Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Nome: </Typography>
        </Grid>
        <Grid item>
          <Typography>{data?.name}</Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Setores: </Typography>
        </Grid>
        <Grid item gap={1} display="flex" flex="1" flexWrap="wrap" xs={12}>
          {data?.sectors?.length === 0 && (
            <Typography>Nenhum setor cadastrado</Typography>
          )}
          {data?.sectors?.map((sector: any, index: number) => (
            <Box
              key={index}
              display="flex"
              component={ButtonBase}
              borderRadius="15px"
              maxWidth="120px"
              height={theme.spacing(3)}
              justifyContent="center"
              alignItems="center"
              color="black"
              bgcolor={'dodgerblue'}
              onClick={() =>
                navigate(
                  `/ombudsman/${moduleTitle}/sectors/detail/${sector[1]}`,
                )
              }
            >
              <Typography
                margin={1}
                variant="subtitle2"
                component="h6"
                textOverflow="ellipsis"
                noWrap
              >
                {sector[0]}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Criado em: </Typography>
        </Grid>
        <Grid item>
          <Typography>{FormatDateTime(data?.created_at)}</Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Última atualização: </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {data?.created_at !== data?.updated_at
              ? FormatDateTime(data?.updated_at)
              : 'Não houve alterações'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

import { NavigateOptions, URLSearchParamsInit } from 'react-router-dom'
import { getSearchParams } from './getSearchParams'

export function handleSetSearchParams(
  searchParams: URLSearchParams,
  params: string[],
  values: string[],
  setSearchParams: (
    nextInit?:
      | URLSearchParamsInit
      | ((prev: URLSearchParams) => URLSearchParamsInit)
      | undefined,
    navigateOpts?: NavigateOptions | undefined,
  ) => void,
  debounce: (e: () => void) => void,
) {
  debounce(() => {
    const currentParams: { [key: string]: string } =
      getSearchParams(searchParams)

    params.map((param, index) => {
      return (currentParams[param] = values[index])
    })

    setSearchParams(currentParams, { replace: true })
  })
}

import { Maybe, Schema } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'
import { isCNPJ, isCPF } from 'brazilian-values'

export interface IFormData {
  email: string
  username: string
  document_type: number
  institution_id: number
  document_number: string
  password?: Maybe<string>
}

export const formValidationSchema: Schema<IFormData> = yup.object({
  password: yup.string().notRequired(),
  institution_id: yup.number().positive().required(),
  document_type: yup.number().min(1).max(2).required(),
  document_number: yup
    .string()
    .required()
    .when('document_type', {
      is: 1,
      then: (schema) => schema.test('test-cpf', 'CPF Inválido', isCPF),
    })
    .when('document_type', {
      is: 2,
      then: (schema) => schema.test('test-cnpj', 'CNPJ Inválido', isCNPJ),
    }),
  email: yup.string().email().required(),
  username: yup.string().min(3).required(),
})

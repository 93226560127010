/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

type Response<T> = [T, Dispatch<SetStateAction<T>>]

function usePersistedState<T>(
  key: string,
  initialState: any,
  useParse = false,
): Response<T> {
  const [state, setState] = useState(() => {
    const storageValue = localStorage.getItem(key)

    if (storageValue) {
      if (storageValue === 'undefined') {
        return useParse ? JSON.parse('[]') : storageValue
      }
      return useParse ? JSON.parse(storageValue) : storageValue
    } else {
      return initialState
    }
  })

  useEffect(() => {
    localStorage.setItem(key, useParse ? JSON.stringify(state) : state)
  }, [key, state, useParse])

  return [state, setState]
}

export default usePersistedState

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Icon,
  Typography,
} from '@mui/material'
import { VForm, VTextField } from '../../shared/forms'
import { Api } from '../../shared/services/api/axios-config'
import { NotificacaoError, NotificacaoSucesso } from '../../shared/components'
import axios from 'axios'
import { Environment } from '../../shared/environment'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormHandles } from '@unform/core'

import defaultLogo from '../../images/default_logo.png'
import { Helmet } from 'react-helmet'

export const AlterarSenha: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [altered, setAltered] = useState(false)
  const [user, setUser] = useState(false)

  const formRef = useRef<FormHandles>(null)

  const { token } = useParams<'token'>()

  const handleSubmit = async (formData: {
    password: string
    re_password: string
  }) => {
    setIsLoading(true)
    if (formData.password === formData.re_password) {
      await axios
        .post(
          `${Environment.URL_BASE}/V1/api/users/reset_password/${token}`,
          formData,
          {
            headers: {
              'X-CSRFToken': document.cookie.split('=')[1],
            },
          },
        )
        .then(() => {
          setIsLoading(false)
          NotificacaoSucesso('Senha Alterada com Sucesso')
          setAltered(true)
        })
        .catch((error) => {
          setIsLoading(false)
          console.error(error)
          NotificacaoError(error.response.data.detail)
        })
    } else {
      formRef.current?.setFieldError(
        're_password',
        'As Senhas Não Estão Iguais',
      )
      setIsLoading(false)
    }
  }

  useEffect(() => {
    Api.post('/V1/users/reset_token/', { token })
      .then(() => {
        setUser(true)
      })
      .catch((error) => NotificacaoError(error.message))
  }, [token])

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Helmet>
        <link
          rel="canonical"
          href={`${Environment.FRONTEND_URL}/reset_password`}
        />
      </Helmet>
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column" gap={2} width={300}>
            <img src={defaultLogo} alt="defaultLogo" />
            {user ? (
              <>
                <Typography variant="h4">Insira sua Nova Senha</Typography>
                <Typography>
                  Mantenha a sua senha guardada em um lugar seguro. De
                  preferência, que apenas você tenha acesso
                </Typography>
                <VForm ref={formRef} onSubmit={handleSubmit}>
                  <Grid
                    container
                    display="flex"
                    justifyContent="center"
                    spacing={2}
                    paddingX={2}
                  >
                    <Grid item xs={12}>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="password"
                        type="password"
                        label="Senha"
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="re_password"
                        type="password"
                        label="Repita a Senha"
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        endIcon={<Icon>send</Icon>}
                        disabled={isLoading || altered}
                      >
                        Enviar
                      </Button>
                      {altered && (
                        <Button
                          onClick={() => window.location.replace('/admin')}
                        >
                          ir para login
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </VForm>
              </>
            ) : (
              <>
                <Typography textAlign="center" variant="h6">
                  Nenhum usuário encontrado
                </Typography>
                <Button
                  onClick={() => window.location.replace('/admin')}
                  variant="contained"
                  sx={{ borderRadius: '50px' }}
                >
                  ir para login
                </Button>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

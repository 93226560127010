export const auditor = (title: string) => {
  return [
    {
      label: 'Dashboard',
      icon: 'leaderboard',
      path: `/ombudsman/${title}/dashboard`,
    },
    {
      label: 'Relatórios',
      icon: 'assessment',
      path: `/ombudsman/${title}/reports`,
    },
    {
      label: 'Agrupamentos',
      icon: 'apps',
      path: `/ombudsman/${title}/groupings`,
    },
    {
      label: 'Setores',
      icon: 'safety_divider',
      path: `/ombudsman/${title}/sectors`,
    },
    {
      label: 'Tipologia',
      icon: 'sell',
      path: `/ombudsman/${title}/typologies`,
    },
    {
      label: 'Assuntos',
      icon: 'subject',
      path: `/ombudsman/${title}/subjects`,
    },
    {
      label: 'Usuários Internos',
      icon: 'group_add',
      path: `/ombudsman/${title}/sectorsusers`,
    },
    {
      label: 'Perguntas',
      icon: 'question_answer',
      path: `/ombudsman/${title}/questions`,
    },
    {
      label: 'Slides',
      icon: 'ondemand_video',
      path: `/ombudsman/${title}/slides`,
    },
    {
      label: 'Legislações',
      icon: 'gavel',
      path: `/ombudsman/${title}/legilsations`,
    },
    {
      label: 'Respostas Pré Definidas',
      icon: 'wrap_text',
      path: `/ombudsman/${title}/default_replies`,
    },
  ]
}

import { FC, useMemo, useState, useEffect, useRef } from 'react'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDaListagem,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import { IGroupings, getAllGroupings } from '../../../shared/services/api'
import usePersistedState from '../../../shared/hooks/usePersistedState'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDebounce } from '../../../shared/hooks'
import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'
import { Environment } from '../../../shared/environment'
import { FormAgrupamento } from './formulario/Formulário'
import { useAuthContext } from '../../../shared/contexts'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import { Footer } from '../../../shared/components/tabela/Footer'

export const Agrupamentos: FC = () => {
  const updated = useRef(false)
  const navigate = useNavigate()

  const { debounce } = useDebounce(1000)
  const { user } = useAuthContext()
  const { moduleTitle } = useModuleContext()

  const [viewForm, setViewForm] = useState(false)

  const [institution, setInstitution] = usePersistedState<string>(
    'ADM_SELECTED_INSTITUTION',
    '',
  )
  const [totalCount, setTotalCount] = useState(0)
  const [rows, setRows] = useState<IGroupings[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [searchParams, setSearchParams] = useSearchParams()

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || ''
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  const changePage = (value1: number, value2: number) => {
    const result = value1 * value2 - value2
    return result.toString()
  }

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllGroupings(item, total, busca, '', institution, '1').then(
        (result) => {
          updated.current = false
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            setRows(result.results)
            setTotalCount(result.count)
          }
        },
      )
    })
  }, [busca, item, total, institution, updated.current])

  return (
    <LayoutBaseDePagina
      selectInstitution
      autoCompleteChange={(e: number | undefined) =>
        setInstitution(e !== undefined ? String(e) : '')
      }
      title="Agrupamentos"
      filter={
        <FerramentasDaListagem
          mostrarInputDaBusca
          mostrarBotaoNovo={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.hr_manager ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          aoClicarEmNovo={() => setViewForm(true)}
          textoDaBusca={busca}
          aoMudarTextoDaBusca={(texto) => {
            setSearchParams(
              { busca: texto, item: '0', total },
              { replace: true },
            )
          }}
        />
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Ícone</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      navigate(
                        `/ombudsman/${moduleTitle}/groupings/detail/${row.id}`,
                      )
                    }
                  >
                    <Icon>info_outlined</Icon>
                  </IconButton>
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">
                  {row.icon ? (
                    <img src={row.icon} width={50} alt="icon" />
                  ) : (
                    'Vazio'
                  )}
                </TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.created_at, 'Ainda Não Logou')}
                </TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <Footer
            colSpan={5}
            item={Number(item)}
            loading={isLoading}
            onChangePagination={(_, newPage) =>
              setSearchParams(
                {
                  busca,
                  item: changePage(newPage, Number(total)),
                  total,
                },
                { replace: true },
              )
            }
            onChangeTotalItems={(e) =>
              setSearchParams(
                {
                  busca,
                  item: '0',
                  total: e.target.value,
                },
                { replace: true },
              )
            }
            total={total}
            totalCount={totalCount}
          />
        </Table>
      </TableContainer>
      <FormAgrupamento
        create
        onClose={() => setViewForm(false)}
        show={viewForm}
        update={updated}
      />
    </LayoutBaseDePagina>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from 'react-router-dom'
import { LayoutusuarioExterno } from '../../../shared/layouts'
import {
  Box,
  Grid,
  Icon,
  Button,
  Divider,
  Tooltip,
  useTheme,
  Accordion,
  Typography,
  useMediaQuery,
  LinearProgress,
  AccordionSummary,
  IconButton,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import {
  FerramentasDeDetalhes,
  NotificacaoError,
} from '../../../shared/components'
import { IProtocols, getUserProtocolById } from '../../../shared/services/api'
import {
  FormatDate,
  FormatDateTime,
  FormatProtocolStatus,
} from '../../../shared/utils'
import {
  AddReaction,
  CalendarMonth,
  ExpandMore,
  InfoOutlined,
  Replay,
} from '@mui/icons-material'
import AccordionDetails from '@mui/material/AccordionDetails'
import { DetalhesSolicitacao } from '../../ombudsman/protocolos/components/DadosSolicitacao'
import { ConteudoSolicitacao } from '../../ombudsman/protocolos/components/ConteudoManifestacao'
import { useExternalAuthContext } from '../../../shared/contexts'
import Skeleton from '@mui/material/Skeleton'
import { RespostasManifestacao } from '../../ombudsman/protocolos/components/RespostasManifestacao'
import { PesquisaModal } from '../components/Pesquisa'
import { InfoAbrirRecurso } from './InfoAbrirRecurso'
import { ModalRecurso } from './ModalRecurso'

export const DetalhesProtocoloExterno: React.FC = () => {
  const theme = useTheme()

  const { id = 'new' } = useParams<'id'>()
  const { slug } = useParams<'slug'>()
  const { user } = useExternalAuthContext()

  const navigate = useNavigate()

  const updated = useRef(false)

  const [data, setData] = useState<IProtocols>()

  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [showInfoRecurso, setShowInfoRecurso] = useState(false)
  const [modalRecurso, setModalRecurso] = useState(false)
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const [satisfactionSurvey, setSatisfactionSurvey] = useState(true)

  /**
   * Domingo: 0
   * Segunda: 1
   * Terça: 2
   * Quarta: 3
   * Quinta: 4
   * Sexta: 5
   * Sábado: 6
   */

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true)

      if (user.id) {
        getUserProtocolById(id, user.id, user.token).then((result) => {
          updated.current = false
          setIsLoading(false)

          if (result instanceof Error) {
            NotificacaoError(result.message)
            navigate(`/ouvidoria/${slug}/novoprotocolo`)
          } else {
            setName(result.identifier)
            setData(result)
          }
        })
      }
    }
  }, [id, updated.current, user.id])

  useEffect(() => {
    if (Number(data?.status) < 5) {
      setSatisfactionSurvey(false)
    }
  }, [data])

  const ReplySurveyButton = [
    <Button
      key={1}
      disableElevation
      variant="contained"
      startIcon={<AddReaction />}
      onClick={() => setSatisfactionSurvey(true)}
    >
      <Typography
        variant="button"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        pesquisa de satisfação
      </Typography>
    </Button>,
    <Button
      sx={{
        display:
          smDown ||
          (data?.status && data.status <= 4) ||
          (data?.days_on_appeal && data.days_on_appeal > 0)
            ? 'none'
            : undefined,
      }}
      key={2}
      variant="outlined"
      startIcon={<Replay />}
      onClick={() => setModalRecurso(true)}
    >
      recurso
    </Button>,
  ]

  return (
    <LayoutusuarioExterno
      title={name}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          aoClicarEmVoltar={() => {
            navigate(`/ouvidoria/${slug}/novoprotocolo?tab=1`)
          }}
          aoClicarEmNovo={() => {
            navigate(`/ouvidoria/${slug}/novoprotocolo?tab=0`)
          }}
          mostrarBotaoApagar={false}
          mostrarBotaoEditar={false}
          otherButtons={
            !data?.replied_survey && Number(data?.status) > 4
              ? ReplySurveyButton
              : undefined
          }
        />
      }
    >
      <Box
        gap={1}
        marginX={1}
        padding={1}
        paddingX={1}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Box display="flex" justifyContent="space-between" paddingBottom={1}>
          {data ? (
            <Tooltip title="status">
              <Typography width="100px">
                {FormatProtocolStatus(
                  Number(data?.status),
                  false,
                  theme.spacing(6),
                  '10px',
                  18,
                )}
              </Typography>
            </Tooltip>
          ) : (
            <Skeleton width="100px" height={theme.spacing(6)} />
          )}
        </Box>
        {data?.status &&
          data.status > 4 &&
          data.days_on_appeal === 0 &&
          smDown && (
            <Box
              display="flex"
              flex="1"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Button
                fullWidth
                variant="contained"
                startIcon={<Replay />}
                onClick={() => setModalRecurso(true)}
              >
                abrir recurso
              </Button>
              <Tooltip title="Informações sobre o recurso">
                <IconButton onClick={() => setShowInfoRecurso(true)}>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        {!!data && xsDown && (
          <Box display="flex" justifyContent="center" width="100%">
            {Number(data.deadline) > -1 && Number(data.deadline) < 3 && (
              <Button
                variant="outlined"
                sx={{ borderRadius: '50px' }}
                fullWidth
                startIcon={<CalendarMonth />}
              >
                Prorrogar
              </Button>
            )}
          </Box>
        )}
        <Divider />
        <Grid container spacing={2}>
          <Grid container item xs={12} sm={6} md={8} direction="column">
            <Grid item xs={12} sm={6} md={8}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Grid container spacing={1} direction="column">
                    <Grid container item>
                      <Grid item display="flex" alignItems="center" gap={1}>
                        <Typography
                          sx={{ display: 'flex', alignItems: 'center' }}
                          border="2px solid dodgerblue"
                          borderRadius="5px"
                        >
                          <Icon fontSize="large" sx={{ color: 'dodgerblue' }}>
                            toc
                          </Icon>
                        </Typography>
                        <Typography variant="h6" color="dodgerblue">
                          Conteúdo da Manifestação
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item spacing={1} direction="row">
                      <Grid item>
                        <Typography>Criado: </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {FormatDateTime(data?.created_at, 'Indefinido')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Divider orientation="vertical" />
                      </Grid>
                      <Grid item>
                        <Typography>Prazo: </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {FormatDate(data?.expires_at, 'Indefinido')}
                        </Typography>
                      </Grid>
                      {Number(data?.status) > 4 && (
                        <Grid item>
                          <Divider orientation="vertical" />
                        </Grid>
                      )}
                      {Number(data?.status) > 4 && (
                        <Grid container item spacing={1}>
                          <Grid item>
                            <Typography>Finalizado:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography>
                              {FormatDateTime(data?.finished_at, 'Indefinido')}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <ConteudoSolicitacao protocolData={data} />
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6" color="burlywood">
                    Respostas
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingBottom: '0px' }}>
                  <RespostasManifestacao
                    external
                    sendDisabled={Number(data?.status) > 4}
                    externalUser={user}
                    protocolId={data?.id}
                    institutionId={data?.institution_id}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6} md={4} direction="column">
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6" color="burlywood">
                    Dados da Solicitação
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DetalhesSolicitacao
                    protocolData={data}
                    external
                    update={useRef(true)}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {isLoading && (
        <LinearProgress
          variant="indeterminate"
          sx={{ borderRadius: '50px', height: '5px' }}
        />
      )}
      <PesquisaModal
        show={
          satisfactionSurvey &&
          Number(data?.status) > 4 &&
          !data?.replied_survey
        }
        onClose={() => setSatisfactionSurvey(false)}
        protocolData={data}
        update={updated}
      />
      <ModalRecurso
        onClose={() => setModalRecurso(false)}
        show={modalRecurso}
        protocolId={id}
      />
      <InfoAbrirRecurso
        onClose={() => setShowInfoRecurso(false)}
        show={showInfoRecurso}
      />
    </LayoutusuarioExterno>
  )
}

import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'
import { IProtocols, createForward } from '../../../shared/services/api'
import { VForm, VTextArea, VTextField, useVForm } from '../../../shared/forms'
import { useAuthContext } from '../../../shared/contexts'
import { MutableRefObject, useState } from 'react'

interface IEncaminharModalProps {
  show: boolean
  onClose: () => void
  protocolData: IProtocols | undefined
  update: MutableRefObject<boolean>
}

export const EncaminharModal: React.FC<IEncaminharModalProps> = ({
  show,
  onClose,
  protocolData,
  update,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuthContext()

  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const { formRef } = useVForm()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (formData: any) => {
    setIsLoading(true)
    formData.protocol_id = protocolData?.id
    formData.user_id = user?.id
    createForward(formData)
      .then((result) => {
        setIsLoading(false)
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          update.current = true
          NotificacaoSucesso('Envio feito com sucesso')
          formRef.current?.reset()
          onClose()
        }
      })
      .catch((error) => {
        setIsLoading(false)
        NotificacaoError(error.message)
      })
  }

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Typography variant="h6" textAlign="center">
          Enviar por e-mail
        </Typography>
        <Divider />
        {isLoading && <LinearProgress sx={{ mt: 2 }} />}
        <Box margin={1} paddingTop={2}>
          <VForm onSubmit={handleSubmit} ref={formRef}>
            <Grid container spacing={2} direction="column">
              <Grid container item>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="email"
                    type="email"
                    label="Email"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="subject"
                    label="Assunto"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item display="flex" xs={12} justifyContent="center">
                  <VTextArea
                    name="comment"
                    minRows={4}
                    maxRows={8}
                    placeholder="Comentário"
                    ariaLabel="Descrição"
                    style={{
                      width: '100%',
                      borderRadius: '5px',
                      fontVariant: 'historical-forms',
                      font: 'message-box',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box
              display="flex"
              flex="1"
              gap={1}
              justifyContent="right"
              paddingTop={2}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{ borderRadius: '50px' }}
                disableElevation
              >
                Encaminhar
              </Button>
              <Button
                onClick={() => {
                  formRef.current?.reset()
                  onClose()
                }}
                sx={{ borderRadius: '50px' }}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}

import { useEffect, useState, useMemo } from 'react'
import {
  Autocomplete,
  CircularProgress,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'

import { NotificacaoError } from '../components'
import { useDebounce } from '../hooks'
import axios from 'axios'
import { Environment } from '../environment'

type TAutoCompleteOptions = {
  id: number
  label: string
}

interface IAutoCompleteProps {
  isExternalLoading?: boolean
  textStyle?: SxProps<Theme>
  state_id?: string
  onChange?: (e: number | undefined) => void
}

let valueTest: number | undefined = 0

const AutoComplete: React.FC<IAutoCompleteProps> = ({
  isExternalLoading = false,
  textStyle = {},
  onChange,
}) => {
  const [selectedId, setSelectedId] = useState<number | null>(null)
  const { debounce } = useDebounce(1000)
  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  useEffect(() => {
    const currentId = localStorage.getItem('ADM_SELECTED_INSTITUTION')
    setSelectedId(currentId ? Number(currentId) : 0)
  }, [])

  useEffect(() => {
    setIsLoading(true)

    debounce(() => {
      axios
        .get(
          `${Environment.URL_BASE}/V1/admin/institutions/select/?search=${search}`,
        )
        .then((result) => {
          setIsLoading(false)

          if (result instanceof Error) {
            NotificacaoError('Erro ao Carregar Instituições')
          } else {
            setOptions(
              result.data.map((state: { name: string; id: number }) => ({
                id: state.id,
                label: state.name,
              })),
            )
          }
        })
    })
  }, [debounce, search])

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null

    const selectedOption = options.find((option) => option.id === selectedId)
    if (!selectedId) return null

    return selectedOption
  }, [selectedId, options])

  return (
    <Autocomplete
      value={autoCompleteSelectedOption}
      loading={isLoading}
      disabled={isExternalLoading}
      openText="Abrir"
      closeText="Fechar"
      noOptionsText="Sem Opções"
      disablePortal
      popupIcon={
        isExternalLoading || isLoading ? (
          <CircularProgress size={20} />
        ) : undefined
      }
      loadingText="Carregando..."
      onInputChange={(_, newValue) => setSearch(newValue)}
      inputValue={search}
      options={options}
      onChange={(_, newValue) => {
        onChange?.(newValue?.id)
        setSelectedId(newValue?.id || null)
        valueTest = newValue?.id
        newValue?.id !== undefined
          ? localStorage.setItem(
              'ADM_SELECTED_INSTITUTION',
              String(newValue?.id),
            )
          : localStorage.removeItem('ADM_SELECTED_INSTITUTION')
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={textStyle}
          size="small"
          label="Selecione Uma Instituição"
        />
      )}
    />
  )
}

export { AutoComplete, valueTest }

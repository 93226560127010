import { admin } from './admin'
import { master, masterHR } from './master'
import { auxiliar, auxiliarHR } from './auxiliar'

export const sacOptions = {
  admin,
  master,
  masterHR,
  auxiliar,
  auxiliarHR,
}

import { FC, useEffect, useState } from 'react'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  SACExternalUser,
  SacProtocol,
  getAllProtocolsPerUser,
  getSACExternalUser,
} from '../../../shared/services/api/sac-services'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  FerramentasDeDetalhes,
  LegendaSumario,
  NotificacaoError,
  TableContainer,
} from '../../../shared/components'
import { ExpandMore, InfoOutlined } from '@mui/icons-material'
import { Link, useParams } from 'react-router-dom'
import { FormatDate, FormatProtocolStatus, colors } from '../../../shared/utils'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import { Environment } from '../../../shared/environment'
import { FormatPriority } from '../../../shared/utils/format/FormatPriority'

export const DetalhesUsuarioExternoSAC: FC = () => {
  const { id } = useParams<'id'>()
  const { thisModule } = useModuleContext()

  const [protocolsLoading, setProtocolsLoading] = useState(true)
  const [protocols, setProtocols] = useState<SacProtocol[]>([])
  const [updateProtocols, setUpdateProtocols] = useState(true)

  const [isLoading, setIsLoading] = useState(true)
  const [update, setUpdate] = useState(true)
  const [data, setData] = useState<SACExternalUser>()

  const setColor = (deadline: number) => {
    if (deadline < 0) return 6
    if (deadline < 6) return 5

    const result = Math.ceil(Number(thisModule?.deadline) / deadline)

    if (result <= 1) return 1
    if (result >= 5) return 4
    if (result === 2) return 2
    if (result === 3) return 3
    return 4
  }

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getSACExternalUser(id)
        .then((result) => {
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            setData(result)
          }
        })
        .finally(() => {
          setUpdate(false)
          setIsLoading(false)
        })
    }
  }, [id, update])

  useEffect(() => {
    if (id && thisModule?.profile === 2) {
      getAllProtocolsPerUser(thisModule.id, id)
        .then((result) => {
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            setProtocols(result.results)
          }
        })
        .finally(() => {
          setProtocolsLoading(false)
          setUpdateProtocols(false)
        })
    }
  }, [updateProtocols, thisModule?.profile, id])

  return (
    <LayoutBaseDePagina
      title={!data ? 'Carregando...' : data?.name}
      barraDeFerramentas={<FerramentasDeDetalhes />}
    >
      <Box margin={1}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario icon="info_outlined" name="Informações" />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} direction="column">
              {isLoading && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography>Nome: {data?.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  E-mail: {data?.email || 'Não cadastrado'}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>Celular: {data?.number}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  Escolaridade:{' '}
                  {data?.escolarity === 1
                    ? 'Não alfabetizado'
                    : data?.escolarity === 2
                    ? 'Ensino Fundamental Incompleto'
                    : data?.escolarity === 3
                    ? 'Ensino Fundamental Completo'
                    : data?.escolarity === 4
                    ? 'Ensino Médio Incompleto'
                    : data?.escolarity === 5
                    ? 'Ensino Médio Completo'
                    : data?.escolarity === 6
                    ? 'Técnico Incompleto'
                    : data?.escolarity === 7
                    ? 'Técnico Completo'
                    : data?.escolarity === 8
                    ? 'Superior Incompleto'
                    : data?.escolarity === 9
                    ? 'Superior Completo'
                    : 'Prefiro Não Informar'}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>Documento: {data?.document_number}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  Data de nascimento:{' '}
                  {FormatDate(data?.birth_date, 'Não informado')}
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {thisModule?.profile === 2 && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <LegendaSumario icon="confirmation_number" name="Protocolos" />
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableHead>
                    {protocolsLoading && protocols.length > 0 && (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <LinearProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell align="center" width={50}></TableCell>
                      <TableCell align="center">Identificador</TableCell>
                      <TableCell align="center">Prazo</TableCell>
                      <TableCell align="center">Agrupamento</TableCell>
                      <TableCell align="center">Setor</TableCell>
                      <TableCell align="center">Assunto</TableCell>
                      <TableCell align="center">Setor Notificado</TableCell>
                      <TableCell align="center">Prioridade</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {protocols.map((protocol) => (
                      <TableRow key={protocol.id}>
                        <TableCell align="center">
                          <Link
                            to={`/customer-service/${thisModule.title}/protocols/details/${protocol.id}`}
                          >
                            <IconButton color="primary">
                              <InfoOutlined />
                            </IconButton>
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                          {protocol.identifier}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            variant="h6"
                            textAlign="center"
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                            sx={{
                              backgroundColor:
                                protocol.status < 5
                                  ? colors[setColor(protocol.deadline)]
                                  : undefined,
                              borderRadius: '50%',
                              width: '38px',
                              height: '38px',
                            }}
                          >
                            {protocol.status > 4 ? '-' : protocol.deadline}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {protocol.grouping.name}
                        </TableCell>
                        <TableCell align="center">
                          {protocol.sector.name}
                        </TableCell>
                        <TableCell align="center">
                          {protocol.subject.name}
                        </TableCell>
                        <TableCell align="center">
                          {protocol.sector_notified ? 'Sim' : 'Não'}
                        </TableCell>
                        <TableCell align="center">
                          {FormatPriority(protocol.priority)}
                        </TableCell>
                        <TableCell align="center">
                          {FormatProtocolStatus(protocol.status)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                  {!protocolsLoading && protocols.length === 0 && (
                    <caption>{Environment.LISTAGEM_VAZIA}</caption>
                  )}

                  {protocolsLoading && (
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={9}>
                          <LinearProgress />
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </LayoutBaseDePagina>
  )
}

import { useNavigate, useParams } from 'react-router-dom'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDeDetalhes,
  LegendaSumario,
  NotificacaoError,
} from '../../../shared/components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import { FormUsuarioExterno } from './formulario/Formulario'
import {
  IExternalUsers,
  IProtocolsList,
  getExternalById,
  getExternalUserProtocols,
} from '../../../shared/services/api'
import { ExpandMore } from '@mui/icons-material'
import { DisplayData } from './components/DisplayData'
import { ProtocolsTable } from './components/ProtocolsTable'
import { useAuthContext } from '../../../shared/contexts'

export const DetalhesUsuarioExterno: React.FC = () => {
  const navigate = useNavigate()

  const updated = useRef(false)

  const { id = 'new' } = useParams<'id'>()
  const { user } = useAuthContext()

  const [data, setData] = useState<IExternalUsers>()
  const [isLoading, setIsLoading] = useState(false)

  const [protocols, setProtocols] = useState<IProtocolsList[]>([])

  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)

  const [name, setName] = useState('')

  const handleViewForm = () => {
    setViewForm(!viewForm)
    setCreate(true)
  }

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true)
      updated.current = false

      Promise.all([
        getExternalById(Number(id)),
        getExternalUserProtocols('0', '999999999', id),
      ]).then((values) => {
        setIsLoading(false)

        if (values[0] instanceof Error) {
          NotificacaoError(values[0].message)
          navigate(-1)
        } else {
          setName(String(values[0].name))
          setData(values[0])
        }

        if (values[1] instanceof Error) {
          NotificacaoError(values[1].message)
        } else {
          setProtocols(protocols)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate, protocols, updated.current])

  return (
    <LayoutBaseDePagina
      title={`Usuário ${name}` || 'Detalhes Usuário Externo'}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          textoBotaoNovo="Novo"
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
          aoClicarEmVoltar={() => navigate(-1)}
          mostrarBotaoApagar={false}
        />
      }
    >
      <Box margin={1}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario icon="info_outlined" name="Informações" />
          </AccordionSummary>
          <AccordionDetails>
            <DisplayData data={data} isLoading={isLoading} />
          </AccordionDetails>
        </Accordion>

        {user?.modules[0].profile !== 0 && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <LegendaSumario icon="confirmation_number" name="Manifestações" />
            </AccordionSummary>
            <AccordionDetails>
              <ProtocolsTable />
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      <FormUsuarioExterno
        create={create}
        show={viewForm}
        update={updated}
        onClose={handleViewForm}
        data={!create ? data : undefined}
      />
    </LayoutBaseDePagina>
  )
}

import { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Paper,
  LinearProgress,
  IconButton,
  Icon,
  Box,
  Pagination,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material'

import { useDebounce } from '../../../shared/hooks'
import { FormularioAdmin } from './formulario/Formulário'
import { Environment } from '../../../shared/environment'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import { getAllAdmins, IUserList } from '../../../shared/services/api'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'
import {
  FerramentasDaListagem,
  NotificacaoError,
} from '../../../shared/components'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const Administradores: React.FC = () => {
  const { moduleTitle } = useModuleContext()
  const navigate = useNavigate()

  const saved = useRef(false)

  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState<IUserList[]>([])
  const [totalCount, setTotalCount] = useState(0)

  const [searchParams, setSearchParams] = useSearchParams()

  const { debounce } = useDebounce(1200)

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])

  const pagina = Number(item) / Number(total) + 1

  const changePage = (value1: number, value2: number) => {
    const result = value1 * value2 - value2
    return result.toString()
  }

  const [viewForm, setViewForm] = useState(false)

  const handleClose = () => {
    if (viewForm) setViewForm(false)
    else setViewForm(true)
  }

  useEffect(() => {
    setIsLoading(true)

    debounce(() => {
      getAllAdmins(item, total, busca).then((result) => {
        saved.current = false
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setRows(result.results)
          setTotalCount(result.count)
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, total, busca, saved.current])

  return (
    <LayoutBaseDePagina
      title="Administradores"
      filter={
        <FerramentasDaListagem
          mostrarInputDaBusca
          textoBotaoNovo="Novo"
          aoClicarEmNovo={handleClose}
          textoDaBusca={busca}
          aoMudarTextoDaBusca={(texto) =>
            setSearchParams(
              { busca: texto, item: '0', total },
              { replace: true },
            )
          }
        />
      }
    >
      {FormularioAdmin(handleClose, viewForm, saved, true)}
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{
          m: 1,
          width: 'auto',
          color: 'dodgerblue',
          border: '1px solid',
          borderRadius: '15px',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Último Login</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      navigate(
                        `/ombudsman/${moduleTitle}/managers/detail/${row.id}`,
                      )
                    }
                  >
                    <Icon>info_outlined</Icon>
                  </IconButton>
                </TableCell>
                <TableCell align="center">{row.username}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.last_login, 'Ainda Não Logou')}
                </TableCell>
                <TableCell align="center">
                  {FormatStatus(row.is_active)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={5}>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{ height: '5px', borderRadius: '50px' }}
                  />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 10 || totalCount > Number(total)) && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box display="flex" justifyContent="center">
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / Number(total))}
                      onChange={(_, newPage) => {
                        setSearchParams(
                          {
                            busca,
                            total,
                            item: changePage(newPage, Number(total)),
                          },
                          { replace: true },
                        )
                      }}
                    />
                    <Tooltip title="Itens por Página">
                      <Select
                        variant="outlined"
                        sx={{ borderRadius: '15px', height: '30px' }}
                        value={total}
                        onChange={(e) => {
                          setSearchParams(
                            { busca, total: e.target.value, item: '0' },
                            { replace: true },
                          )
                        }}
                      >
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="40">40</MenuItem>
                        <MenuItem value="50">50</MenuItem>
                      </Select>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  )
}

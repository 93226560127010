/* eslint-disable @typescript-eslint/ban-types */
import { Api, ExternalApi } from '../../axios-config'
import { IIcons } from '../../icones-services/IconesServices'

interface SacModuleList {
  id: number
  slug: string
  status: boolean
  sac_title: string
}

interface IconList {
  id: number
  icon: string
  name: string
  status: boolean
}

export interface SacGroupingList {
  customer_service: SacModuleList
  institution: number
  created_at: string
  updated_at: string
  status: boolean
  icon: IconList
  name: string
  id: number
}

export interface SacGrouping {
  customer_service: number
  institution: number
  created_at: string
  updated_at: string
  status: boolean
  icon: number
  name: string
  id: number
}

export interface SacGroupingExternal {
  customer_service: number
  institution: number
  created_at: string
  updated_at: string
  status: boolean
  icon: IIcons
  name: string
  id: number
}

interface TotalCount<T> {
  next: string
  count: number
  previous: string
  data: T[]
  results: T[]
}

export const getAllSacGroupings = async (
  item = '0',
  total = '10',
  busca = '',
  customerService = '',
  institution = '',
): Promise<TotalCount<SacGroupingList> | Error> => {
  try {
    const relativeUrl = '/V1/customer_service/groupings/'

    const { data } = await Api.get(relativeUrl, {
      params: {
        item,
        total,
        busca,
        customer_service: customerService,
        institution,
      },
    })

    if (data) return data
    return new Error('Erro ao listar agrupamentos')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const createSacGrouping = async (formData: {}): Promise<
  SacGrouping | Error
> => {
  try {
    const relativeUrl = '/V1/customer_service/groupings/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getSacGroupingById = async (
  id: number | string,
): Promise<SacGroupingList | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/groupings/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar instânica')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const updateSacGroupingById = async (
  id: number | string,
  formData: {},
): Promise<SacGrouping | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/groupings/${id}/`

    const { data } = await Api.patch(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao atualizar instânica')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getAllSacgroupingsExternal = async (
  slug: string,
  busca = '',
): Promise<SacGroupingExternal[] | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/externalusers/access/${slug}/groupings/`

    const { data } = await ExternalApi.get(relativeUrl, {
      params: {
        search: busca,
      },
      headers: {
        Authorization: `Token ${sessionStorage.getItem('EXTERNAL_TOKEN')}`,
      },
    })

    if (data) return data

    return new Error('Erro ao carregar agrupamentos')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

/* eslint-disable @typescript-eslint/ban-types */
import { IUserDetail } from '../../admin-users-services/AdminusersServices'
import { Api } from '../../axios-config'
import { IExternalUsers } from '../../usuario-externo-services/UsuarioExternoServices'

export interface SacReply {
  file: string
  reply: string
  protocol: number
  user?: IUserDetail
  internal: boolean
  created_at: string
  reply_type: number
  customer_service: number
  external_user?: IExternalUsers
}

export const getAllSacReplies = async (
  protocolId: number,
): Promise<SacReply[] | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/protocols/${protocolId}/replies/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar respostas')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const createSacReply = async (
  protocolId: number,
  formData: {},
): Promise<SacReply | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/protocols/${protocolId}/replies/`

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao criar resposta')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

import { Maybe, Schema } from 'yup'
import { yup } from '../../../../shared/forms/TraducoesYup'

export interface SacPhone {
  title: string
  number: string
}

export interface IFormData {
  id?: number
  status: boolean
  sac_plan: number
  sac_slug: string
  sac_title: string
  sac_email?: string
  sac_prefix: string
  institution: number
  sac_address?: Maybe<string>
  sac_separator: string
  sac_limit_days: number
  sac_expires_at: string
  sac_extend_days: number
  sac_responsible?: Maybe<string>
  sac_activate_sms: boolean
  sac_working_hour?: Maybe<string>
  sac_sms_quantity?: number
  sac_price: string | number
  sac_phones?: SacPhone[]
  sac_cellphones?: SacPhone[]
}

const formValidationSchema: Schema<IFormData> = yup.object().shape({
  status: yup.boolean().default(true),
  sac_phones: yup.array().default([]),
  sac_expires_at: yup.string().required(),
  id: yup.number().default(undefined),
  sac_cellphones: yup.array().default([]),
  sac_email: yup.string().default(undefined).email(),
  sac_price: yup.number().required(),
  sac_activate_sms: yup.boolean().default(true),
  institution: yup.number().positive().required(),
  sac_plan: yup.number().required().min(1).max(2),
  sac_slug: yup.string().required().min(3).max(255),
  sac_limit_days: yup.number().required().positive(),
  sac_prefix: yup.string().required().max(10).min(2),
  sac_title: yup.string().required().max(255).min(3),
  sac_extend_days: yup.number().required().positive(),
  sac_responsible: yup.string().notRequired().max(255),
  sac_separator: yup.string().min(2).max(10).required(),
  sac_sms_quantity: yup.number().default(0),
  sac_address: yup.string().notRequired().max(255),
  sac_working_hour: yup.string().notRequired().max(255),
})

export { formValidationSchema }

export const auxiliar = (title: string) => {
  return [
    {
      label: 'Dashboard',
      icon: 'leaderboard',
      path: `/customer-service/${title}/dashboard`,
    },
    {
      label: 'Protocolos',
      icon: 'confirmation_number',
      path: `/customer-service/${title}/protocols`,
    },
  ]
}

export const auxiliarHR = (title: string) => {
  return [
    {
      label: 'Dashboard',
      icon: 'leaderboard',
      path: `/customer-service/${title}/dashboard`,
    },
    {
      label: 'Protocolos',
      icon: 'confirmation_number',
      path: `/customer-service/${title}/protocols`,
    },
    {
      label: 'Agrupamentos',
      icon: 'apps',
      path: `/customer-service/${title}/groupings`,
    },
    {
      label: 'Setores',
      icon: 'safety_divider',
      path: `/customer-service/${title}/sectors`,
    },
    {
      label: 'Assuntos',
      icon: 'subject',
      path: `/customer-service/${title}/subjects`,
    },
    {
      label: 'Categorias',
      icon: 'sell',
      path: `/customer-service/${title}/categories`,
    },
    {
      label: 'Perfis S.A.C',
      icon: 'group',
      path: `/customer-service/${title}/users`,
    },
  ]
}

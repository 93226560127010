import { useEffect, useState, useMemo } from 'react'
import {
  Autocomplete,
  Box,
  CircularProgress,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'
import { getAllIcons } from '../../../../shared/services/api'
import { NotificacaoError } from '../../../../shared/components'
import { useDebounce } from '../../../../shared/hooks'
import { useField } from '@unform/core'

type TAutoCompleteOptions = {
  id: number
  label: string
  icon: string
}

interface IAutoCompleteProps {
  isExternalLoading?: boolean
  textStyle?: SxProps<Theme>
}

export const AutoCompleteIcons: React.FC<IAutoCompleteProps> = ({
  isExternalLoading = false,
  textStyle = {},
}) => {
  const [firstTime, setFirstTime] = useState(true)
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField('icon')

  const [selectedId, setSelectedId] = useState<number | null>(null)
  const { debounce } = useDebounce(1000)
  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newValue) => setSelectedId(newValue),
    })
  }, [registerField, fieldName, selectedId])

  useEffect(() => {
    setFirstTime(false)
    setSelectedId(defaultValue)
  }, [defaultValue, firstTime])

  useEffect(() => {
    setIsLoading(true)
    if (firstTime) {
      setFirstTime(false)
      setSelectedId(defaultValue)
      getAllIcons('0', '500', search, defaultValue).then((result) => {
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError('Erro ao Carregar Estados')
        } else {
          setOptions(
            result.results.map((state) => ({
              id: state.id,
              label: state.name,
              icon: state.icon,
            })),
          )
        }
      })
    } else {
      debounce(() => {
        getAllIcons('0', '500', search).then((result) => {
          setIsLoading(false)

          if (result instanceof Error) {
            NotificacaoError('Erro ao Carregar Estados')
          } else {
            setOptions(
              result.results.map((state) => ({
                id: state.id,
                label: state.name,
                icon: state.icon,
              })),
            )
          }
        })
      })
    }
  }, [debounce, defaultValue, firstTime, search])

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null

    const selectedOption = options.find((option) => option.id === selectedId)
    if (!selectedId) return null

    return selectedOption
  }, [selectedId, options])

  return (
    <Autocomplete
      value={autoCompleteSelectedOption}
      loading={isLoading}
      disabled={isExternalLoading}
      openText="Abrir"
      closeText="Fechar"
      noOptionsText="Sem Opções"
      disablePortal
      popupIcon={
        isExternalLoading || isLoading ? (
          <CircularProgress size={20} />
        ) : undefined
      }
      loadingText="Carregando..."
      onInputChange={(_, newValue) => setSearch(newValue)}
      inputValue={search}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.id || null)
        clearError()
      }}
      options={options}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img loading="lazy" width={35} src={option.icon} alt="image" />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          required
          {...params}
          sx={textStyle}
          size="small"
          label="Ícone"
          error={!!error}
          helperText={error}
        />
      )}
    />
  )
}

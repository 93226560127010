import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Box,
  LinearProgress,
  Typography,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Divider,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

import { FormCliente } from './formulario/Formulário'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  LegendaSumario,
  NotificacaoError,
  FerramentasDeDetalhes,
} from '../../../shared/components'
import { IClientDetail, getClientById } from '../../../shared/services/api'
import { FormatDateTime } from '../../../shared/utils'
import {
  IClientHistory,
  getClientHistory,
} from '../../../shared/services/api/histories-services/ClientsHistory'

export const DetalhesCliente: React.FC = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const navigate = useNavigate()
  const { id = 'new' } = useParams<'id'>()

  const updated = useRef(false)

  const [userData, setUserData] = useState<IClientDetail>()
  const [history, setHistory] = useState<IClientHistory[]>([])
  const [historyLoading, setHistoryLoading] = useState(true)
  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)

  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')

  const handleViewForm = () => {
    setViewForm(!viewForm)
    setCreate(true)
  }

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true)
      getClientHistory(id).then((result) => {
        setHistoryLoading(false)
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar histórico')
        } else {
          setHistory(result)
        }
      })
      getClientById(Number(id)).then((result) => {
        updated.current = false
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError(result.message)
          navigate(-1)
        } else {
          setName(result.name)
          setUserData(result)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, updated.current])

  return (
    <LayoutBaseDePagina
      title={`Detalhes do Cliente ${name}` || 'Detalhes Cliente'}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          textoBotaoNovo="Novo"
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
          aoClicarEmVoltar={() => navigate('/ombudsman/clients')}
          mostrarBotaoApagar={false}
        />
      }
    >
      <Box
        gap={1}
        marginX={1}
        padding={1}
        paddingX={1}
        display="flex"
        flexDirection="column"
      >
        {FormCliente(
          handleViewForm,
          viewForm,
          updated,
          create,
          !create ? userData : undefined,
        )}
        {isLoading && (
          <Box flex="1">
            <LinearProgress
              variant="indeterminate"
              sx={{ borderRadius: '50px', height: '5px' }}
            />
          </Box>
        )}
        <Box
          display="flex"
          flex="1"
          height={theme.spacing(smDown ? 6 : mdDown ? 8 : 12)}
          alignItems="center"
          flexDirection="column"
        >
          <img
            src={userData?.logo}
            alt="client_logo"
            width={
              smDown
                ? theme.spacing(20)
                : mdDown
                ? theme.spacing(25)
                : theme.spacing(30)
            }
          />
        </Box>
        <Box
          gap={1}
          marginX={1}
          padding={1}
          paddingX={1}
          display="flex"
          flexDirection="column"
        >
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <LegendaSumario icon="restore" name="Histórico" />
            </AccordionSummary>
            <AccordionDetails>
              {historyLoading && (
                <Box flex="1" padding={1}>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{ borderRadius: '50px', height: '5px' }}
                  />
                </Box>
              )}
              {!!history && (
                <Grid container spacing={2} direction="column">
                  {history.map((query) => (
                    <>
                      <Divider variant="middle" />
                      {query.type === '~' ? (
                        <Grid container item>
                          {query.field === 'name' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Nome alterado de ({query.old}) para (
                                  {query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'stastu' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  {query.old
                                    ? `Instituição desativada por ${
                                        query.user || 'Não definido'
                                      }`
                                    : `Instituição desativada por ${
                                        query.user || 'Não definido'
                                      }`}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'cnpj' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  CNPJ alterado de {query.old} para {query.new}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'ombudsman_email' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Email da ouvidoria alterado de ({query.old})
                                  para ({query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'slug' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Slug alterado de ({query.old}) para (
                                  {query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'prefix' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Prefixo alterado de ({query.old}) para (
                                  {query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'logo' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>Logo alterada</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterada por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'work_field' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Ramo de atividade alterado de ({query.old})
                                  para {query.new}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'ombudsman_price' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Valor do módulo de ouvidoria alterado de (
                                  {query.old}) para ({query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'ci_price' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Valor do módulo de comunicação interna
                                  alterado de ({query.old}) para ({query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'client_type' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Cliente alterado de{' '}
                                  {Number(query.old) === 1
                                    ? 'Púbilco para Privado'
                                    : 'Priivado para Público'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'ombudsman_plan' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Plano do módulo de ouvidoria alterado de{' '}
                                  {Number(query.old) === 1
                                    ? 'Gratuito para Pago'
                                    : 'Pago para Gratuito'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'ci_plan' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Plano do módulo de comunicação interna
                                  alterado de{' '}
                                  {Number(query.old) === 1
                                    ? 'Gratuito para Pago'
                                    : 'Pago para Gratuito'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'ombudsman_expires_at' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Data de expiração da ouvidoria alterada de{' '}
                                  {query.old} para ({query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'ci_expires_at' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Data de expiração da comunicação interna
                                  alterada de {query.old} para ({query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'contact_name' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Nome do ouvidor alterado de ({query.old}) para
                                  ({query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'address' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Endereço alterado de ({query.old}) para (
                                  {query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'modules' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Módulos da Instituição alterados de (
                                  {query.old}) para {query.new}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'coverage' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Abrangência alterada de (
                                  {Number(query.old) === 1
                                    ? 'Nacional'
                                    : Number(query.old) === 2
                                    ? 'Estadual'
                                    : 'Municipal'}
                                  ) para (
                                  {Number(query.new) === 1
                                    ? 'Nacional'
                                    : Number(query.new) === 2
                                    ? 'Estadual'
                                    : 'Municipal'}
                                  )
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'anonymous' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  {query.old
                                    ? 'Permissão para manifestação anônima desativada'
                                    : 'Permissão para manifestação anônima ativada'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'compliance' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  {query.old
                                    ? 'Denúncia desativada'
                                    : 'Denúncia ativada'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'states' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Estados da Instituição alterados
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'cities' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Cidades da instituição alteradas
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'ombudsman_title' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Título da ouvidoria alterado de ({query.old})
                                  para ({query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'ombudsman_limit_days' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Limite de dias para manifestações na ouvidoria
                                  alterado de ({query.old}) para ({query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'ci_limit_days' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Limite de dias para tickets na comunicação
                                  interna alterado de ({query.old}) para (
                                  {query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'ci_extended_days' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Quantidade de dias de prorrogação na
                                  comunicação interna alterado de ({query.old})
                                  para ({query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'working_hour' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Horário de atividade alterado de ({query.old})
                                  para ({query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'phones' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Números de telefones alterados
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'cellphones' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Números de celulares alterados
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'notify_sectors' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Ouvidoria automática{' '}
                                  {query.old ? 'desativada' : 'ativada'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {query.field === 'sms_quantity' && (
                            <Grid container item direction="column">
                              <Grid item>
                                <Typography>
                                  Quantidade de SMS alterada de ({query.old})
                                  para ({query.new})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Alterado por: {query.user || 'Não definido'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Data:{' '}
                                  {FormatDateTime(query.date, 'Não definido')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      ) : (
                        <Grid container item direction="column">
                          <Grid item>
                            <Typography>
                              Instituição cadastrada por{' '}
                              {query.user || 'Não definido'}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography>
                              Data: {FormatDateTime(query.date, 'Não definido')}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </LayoutBaseDePagina>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from 'react-router-dom'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDeDetalhes,
  LegendaSumario,
  NotificacaoSucesso,
  NotificacaoError,
} from '../../../shared/components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  LinearProgress,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import {
  IInternalUserHistory,
  ISectorInUser,
  ISectorUserDetail,
  confirmUser,
  getInternaUserHistory,
  getInternalById,
  getSectorsInUserHistory,
  resendConfirmationMail,
} from '../../../shared/services/api'
import { FormUsuarioInterno } from './formulario/Formulário'
import { ExpandMore } from '@mui/icons-material'
import { SectorsUserHistory, UserHistory } from './components/UserHistories'
import { DisplayData } from './components/DisplayData'
import { useAuthContext } from '../../../shared/contexts'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'

export const DetalhesUsuarioInterno: React.FC = () => {
  const navigate = useNavigate()
  const { id = 'new' } = useParams<'id'>()
  const { user } = useAuthContext()
  const updated = useRef(false)

  const [loadingConfirmation, setLoadingConfirmation] = useState(false)

  const [userData, setUserData] = useState<ISectorUserDetail>()
  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)

  const [historyData, setHistoryData] = useState<IInternalUserHistory[]>([])
  const [historyLoading, setHistoryLoading] = useState(false)

  const [sectorHistoryData, setSectorHistoryData] = useState<ISectorInUser[]>(
    [],
  )
  const [sectorHistoryLoading, setSectorHistoryLoading] = useState(true)

  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')

  const handleViewForm = () => {
    setViewForm(!viewForm)
    setCreate(true)
  }

  const handleReSendMail = () => {
    resendConfirmationMail({ user_id: userData?.id }).then((result) => {
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        NotificacaoSucesso('Email reenviado com sucesso')
      }
    })
  }

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true)

      getInternalById(Number(id)).then((result) => {
        updated.current = false
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError(result.message)
          navigate(-1)
        } else {
          setName(result.username)
          setUserData(result)
        }
      })
    }
  }, [id, navigate, updated.current])

  useEffect(() => {
    setHistoryLoading(true)
    getInternaUserHistory(Number(id))
      .then((result) => {
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar histórico')
        } else {
          setHistoryData(result)
        }
      })
      .finally(() => setHistoryLoading(false))
  }, [id, updated.current])

  useEffect(() => {
    setSectorHistoryLoading(true)
    getSectorsInUserHistory(Number(id))
      .then((result) => {
        if (result instanceof Error) {
          NotificacaoError('Erro ao carregar histórico de setores')
        } else {
          setSectorHistoryData(result)
        }
      })
      .finally(() => setSectorHistoryLoading(false))
  }, [id, updated.current])

  const { moduleTitle } = useModuleContext()

  return (
    <LayoutBaseDePagina
      title={`Detalhes do Usuário ${name}` || 'Detalhes Usuário Administrador'}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          textoBotaoNovo="Novo"
          mostrarBotaoNovo={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.hr_manager ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          mostrarBotaoEditar={
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.hr_manager ||
            user?.modules.find(
              (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
            )?.profile === 0
          }
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
          aoClicarEmVoltar={() => navigate(-1)}
          mostrarBotaoApagar={false}
        />
      }
    >
      <Box
        gap={1}
        marginX={1}
        padding={1}
        paddingX={1}
        display="flex"
        flexDirection="column"
      >
        {FormUsuarioInterno(
          handleViewForm,
          viewForm,
          updated,
          create,
          !create ? userData : undefined,
        )}
        {isLoading && (
          <Box flex="1">
            <LinearProgress
              variant="indeterminate"
              sx={{ borderRadius: '50px', height: '5px' }}
            />
          </Box>
        )}
        <Box
          gap={1}
          marginX={1}
          padding={1}
          paddingX={1}
          display="flex"
          flexDirection="column"
        >
          {userData &&
            !userData.is_confirmed &&
            user?.modules[0].profile === 0 && (
              <Button
                disabled={loadingConfirmation}
                variant="contained"
                onClick={() => {
                  setLoadingConfirmation(true)
                  confirmUser(Number(userData.id))
                    .then((res) => {
                      if (res instanceof Error) {
                        NotificacaoError('Erro ao confirmar usuário')
                      } else {
                        NotificacaoSucesso('Usuário confirmado com sucesso')
                        setUserData(res)
                      }
                    })
                    .finally(() => setLoadingConfirmation(false))
                }}
              >
                confirmar usuário
              </Button>
            )}
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <LegendaSumario icon="info_outlined" name="Informações" />
            </AccordionSummary>
            <AccordionDetails>
              <DisplayData
                data={userData}
                handleReSendMail={handleReSendMail}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <LegendaSumario icon="restore" name="Histórico" />
            </AccordionSummary>
            <AccordionDetails>
              <UserHistory
                history={historyData}
                historyLoading={historyLoading}
                createdAt={userData?.created_at || ''}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <LegendaSumario name="Histórico de setores" icon="work_history" />
            </AccordionSummary>
            <AccordionDetails>
              <SectorsUserHistory
                history={sectorHistoryData}
                historyLoading={sectorHistoryLoading}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </LayoutBaseDePagina>
  )
}

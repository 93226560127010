import { Api } from '../axios-config'

export interface ITypologyHistory {
  field: string
  type: '+' | '~'
  date: string
  user?: string
  old?: string
  new?: string
}

const getTypologyHistory = async (
  typologyId: number,
): Promise<ITypologyHistory[] | Error> => {
  try {
    const relativeUrl = `/V1/categories/${typologyId}/historical/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar histórico')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export { getTypologyHistory }

export function getSearchParams(searchParams: URLSearchParams) {
  const currentParams: { [key: string]: string } = {}
  searchParams
    .toString()
    .split('&')
    .map((param) => {
      const key = param.split('=')[0]
      let value = ''
      try {
        value = param.split('=')[1]
      } catch (error) {
        value = ''
      }
      if (!key || !value) return currentParams
      return (currentParams[key] = value)
    })

  return currentParams
}

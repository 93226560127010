import { FC, useMemo, useState, useEffect } from 'react'
import {
  Box,
  Icon,
  IconButton,
  LinearProgress,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  IProtocolsList,
  getExternalUserProtocols,
} from '../../../../shared/services/api'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { FormatPriority } from '../../../../shared/utils/format/FormatPriority'
import { FormatProtocolStatus, colors } from '../../../../shared/utils'
import { Environment } from '../../../../shared/environment'
import { IModule, useAuthContext } from '../../../../shared/contexts'
import { useDebounce } from '../../../../shared/hooks'
import { NotificacaoError } from '../../../../shared/components'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'

export const ProtocolsTable: FC = () => {
  const { debounce } = useDebounce(1200)
  const { user } = useAuthContext()

  const [rows, setRows] = useState<IProtocolsList[]>([])

  const navigate = useNavigate()

  const { id } = useParams<'id'>()

  const [searchParams, setSearchParams] = useSearchParams()
  const [newWindow, setNewWindow] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [open, setOpen] = useState(false)

  const selected = useMemo(() => {
    return searchParams.get('selecionado') || ''
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])

  const changePage = (value1: number, value2: number) => {
    const result = value1 * value2 - value2
    return result.toString()
  }

  const { moduleTitle, currentModule } = useModuleContext()

  const pagina = Number(item) / Number(total) + 1

  const [thisModule, setThisModule] = useState<IModule>()

  useEffect(() => {
    setThisModule(
      user?.modules.find(
        (mdl) => mdl.type === currentModule && mdl.title === moduleTitle,
      ),
    )
  }, [user?.modules, currentModule, moduleTitle])

  const setColor = (deadline: number) => {
    if (deadline < 0) return 6
    if (deadline < 6) return 5

    const result = Math.ceil(Number(thisModule?.deadline) / deadline)

    if (result <= 1) return 1
    if (result >= 5) return 4
    if (result === 2) return 2
    if (result === 3) return 3
    return 4
  }

  useEffect(() => {
    setIsLoading(true)

    debounce(() => {
      getExternalUserProtocols(item, total, id).then((result) => {
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setRows(result.results)
          setTotalCount(result.count)
        }
      })
    })
  }, [item, total, id, debounce])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addEventListener('keydown', function (e: any) {
    if (e.ctrlKey) setNewWindow(true)
  })

  addEventListener('keyup', function () {
    if (newWindow) setNewWindow(false)
  })

  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{
        m: 1,
        width: 'auto',
        color: 'dodgerblue',
        border: '1px solid',
        borderRadius: '15px',
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" width={50}></TableCell>
            <TableCell align="center">Identificador</TableCell>
            <TableCell align="center">Prazo</TableCell>
            <TableCell align="center">Agrupamento</TableCell>
            <TableCell align="center">Setor</TableCell>
            <TableCell align="center">Assunto</TableCell>
            <TableCell align="center">Tipologia</TableCell>
            <TableCell align="center">Prioridade</TableCell>
            <TableCell align="center">Setor Notificado</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              selected={selected === String(row.id)}
              key={row.id}
              sx={{
                borderLeft: `5px solid ${
                  row.status === 1
                    ? '#EB3324'
                    : row.status === 2
                    ? '#FFFF00'
                    : row.status === 3
                    ? '#CBAACB'
                    : row.status === 4
                    ? '#55CBCD'
                    : row.status === 5
                    ? '#8bdaa7'
                    : '#999999'
                }`,
              }}
            >
              <TableCell align="center">
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (newWindow) {
                      window.open(
                        `/ombudsman/protocols/detail/${row.id}`,
                        '_blank',
                        'noopener',
                      )
                      setSearchParams(
                        {
                          item,
                          total,
                          selecionado: String(row.id),
                        },
                        { replace: true },
                      )
                    } else {
                      navigate(
                        `/ombudsman/${moduleTitle}/protocols/detail/${row.id}`,
                      )
                    }
                  }}
                >
                  <Icon>info_outlined</Icon>
                </IconButton>
              </TableCell>
              <TableCell align="center">{row.identifier}</TableCell>
              <TableCell align="center">
                <Typography
                  variant="h6"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  sx={{
                    backgroundColor:
                      row.status < 5
                        ? colors[setColor(row.deadline)]
                        : undefined,
                    borderRadius: '50%',
                    width: '38px',
                    height: '38px',
                  }}
                >
                  {row.status > 4 ? '-' : row.deadline}
                </Typography>
              </TableCell>
              <TableCell align="center">
                {row.grouping_id ? row.grouping_id.name : 'Indefinido'}
              </TableCell>
              <TableCell align="center">
                {row.sector_id ? row.sector_id.name : 'Indefinido'}
              </TableCell>
              <TableCell align="center">
                {row.subject_id ? row.subject_id.name : 'Indefinido'}
              </TableCell>
              <TableCell align="center">
                {row.category_id ? row.category_id.name : 'Indefinido'}
              </TableCell>
              <TableCell align="center">
                {FormatPriority(Number(row.priority))}
              </TableCell>
              <TableCell align="center">
                {row.notified_sector ? 'Sim' : 'Não'}
              </TableCell>
              <TableCell align="center">
                {FormatProtocolStatus(row.status)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        {totalCount === 0 && !isLoading && (
          <caption>{Environment.LISTAGEM_VAZIA}</caption>
        )}

        <TableFooter>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={10}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          )}
          {(totalCount > 10 || totalCount > Number(total)) && (
            <TableRow>
              <TableCell colSpan={9}>
                <Box display="flex" justifyContent="center">
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Number(total))}
                    onChange={(_, newPage) => {
                      setSearchParams(
                        {
                          total,
                          item: changePage(newPage, Number(total)),
                        },
                        {
                          replace: true,
                        },
                      )
                    }}
                  />
                  <Tooltip
                    title="Itens por Página"
                    open={open}
                    onMouseEnter={() => setOpen(true)}
                    onMouseDown={() => setOpen(false)}
                    onMouseLeave={() => setOpen(false)}
                  >
                    <Select
                      variant="outlined"
                      sx={{ borderRadius: '15px', height: '30px' }}
                      value={total}
                      onChange={(e) => {
                        setSearchParams(
                          {
                            item: '0',
                            total: e.target.value,
                          },
                          {
                            replace: true,
                          },
                        )
                      }}
                    >
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="20">20</MenuItem>
                      <MenuItem value="30">30</MenuItem>
                      <MenuItem value="40">40</MenuItem>
                      <MenuItem value="50">50</MenuItem>
                    </Select>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

import { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useDebounce } from '../../../../shared/hooks'
import { getAllSectors } from '../../../../shared/services/api'
import { NotificacaoError } from '../../../../shared/components'
import { CircularProgress, SxProps, Theme } from '@mui/material'
import { useField } from '@unform/core'
import { useAuthContext } from '../../../../shared/contexts'

type TAutoCompleteOptions = {
  id: number
  label: string
}

interface IAutoComplet {
  externalIsLoading?: boolean
  textStyle?: SxProps<Theme>
}

export const AutoComplete: React.FC<IAutoComplet> = ({ textStyle }) => {
  const { user } = useAuthContext()
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField('sectors')
  const { debounce } = useDebounce(1000)
  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [selectedOptions, setSelectedOptions] = useState<
    TAutoCompleteOptions[]
  >([])
  const [value, setValue] = useState<number[] | null>(defaultValue || null)
  const [firstTime, setFirstTime] = useState(true)
  const [institution, setInstitution] = useState('')

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [registerField, fieldName, value])

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllSectors('0', '100', search, '', institution).then((result) => {
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError('Erro ao Carregar Setores')
        } else {
          setOptions(
            result.results.map((opt) => ({ id: opt.id, label: opt.name })),
          )
        }
      })
    })
  }, [search, institution, debounce])

  useEffect(() => {
    setValue(selectedOptions.map((option) => option.id))
  }, [selectedOptions, firstTime])

  useEffect(() => {
    if (user?.modules[0].profile === 0)
      setInstitution(localStorage.getItem('ADM_SELECTED_INSTITUTION') || '')
    else setInstitution(String(user?.institution_id))
    setFirstTime(false)
    if (defaultValue) {
      setSelectedOptions(
        defaultValue.map((data: [string, number]) => ({
          id: data[1],
          label: data[0],
        })),
      )
    }
  }, [defaultValue, firstTime, user?.institution_id, user?.modules])

  return (
    <Autocomplete
      fullWidth
      multiple
      value={selectedOptions}
      limitTags={2}
      id="multiple-limit-tags"
      options={options}
      loadingText="Carregando..."
      noOptionsText="Nenhuma Opção Disponível"
      loading={institution === ''}
      getOptionDisabled={(option) =>
        !!selectedOptions.find((opt) => option.id === opt.id)
      }
      disableCloseOnSelect
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      getOptionLabel={(option) => option.label}
      onChange={(_, value) => setSelectedOptions(value)}
      defaultValue={selectedOptions}
      onInputChange={(_, value) => setSearch(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Setores"
          size="small"
          sx={textStyle}
          error={!!error}
          helperText={error}
          onKeyDown={error ? clearError : undefined}
        />
      )}
    />
  )
}

import { FC, useEffect, useState } from 'react'
import { Autocomplete, TextField, CircularProgress } from '@mui/material'
import { getAllSacSectors } from '../../../../shared/services/api/sac-services'
import { useDebounce } from '../../../../shared/hooks'
import { NotificacaoError } from '../../../../shared/components'
import { useField } from '@unform/core'

type AutocompleteOtpion = {
  label: string
  value: number
  grouping: string
}

interface AutocompleteSetoresProps {
  institution: string | number
}

export const AutocompleteSetores: FC<AutocompleteSetoresProps> = ({
  institution,
}) => {
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField('sectors')
  const { debounce } = useDebounce()

  const [firstTime, setFirstTime] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<Array<number>>([])
  const [options, setOptions] = useState<Array<AutocompleteOtpion>>([])
  const [selectedValues, setSelectedValues] = useState<
    Array<AutocompleteOtpion>
  >([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    setIsLoading(true)
    debounce(() => {
      getAllSacSectors(search, '0', '999999999', String(institution)).then(
        (result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError('Erro ao carregar setores')
          } else {
            setOptions(
              result.results.map((sector) => ({
                label: sector.name,
                value: sector.id,
                grouping: sector.grouping.name,
              })),
            )
          }
        },
      )
    })
  }, [search, institution, debounce])

  useEffect(() => {
    if (firstTime && defaultValue) {
      setSelectedValues(defaultValue)
      setValues(defaultValue.map((opt: AutocompleteOtpion) => opt.value))
      setFirstTime(false)
    }
  }, [options, defaultValue, firstTime])

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => values,
      setValue: (_, newValue) => setValues(newValue),
    })
  }, [fieldName, registerField, values])

  return (
    <Autocomplete
      multiple
      fullWidth
      limitTags={1}
      disableCloseOnSelect
      groupBy={(option) => option.grouping}
      openText="Abrir"
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      noOptionsText="Nenhuma opção encontrada"
      isOptionEqualToValue={(opt, value) => opt.value === value.value}
      options={options}
      loading={isLoading}
      value={selectedValues}
      inputValue={search}
      onInputChange={(_, newValue) => {
        setSearch(newValue)
        clearError()
      }}
      onChange={(_, newValue) => {
        setSelectedValues(newValue)
        setValues(newValue.map((sector) => sector.value))
        clearError()
      }}
      popupIcon={isLoading ? <CircularProgress size={20} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Setores"
          error={!!error}
          helperText={error}
          size="small"
          sx={{
            fieldSet: {
              borderRadius: '50px',
            },
          }}
        />
      )}
    />
  )
}

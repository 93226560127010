import { Api, TotalCount } from '../../axios-config'

export interface SacPredefinedReply {
  id: number
  text: string
  title: string
  status: boolean
  created_at: string
  updated_at: string
  customer_service: number
}

export const getAllpredefinedAnswers = async (
  search = '',
  item = '0',
  total = '10',
  customerService = '',
  institution = '',
  status = '',
): Promise<TotalCount<SacPredefinedReply> | Error> => {
  try {
    const relativeUrl = '/V1/customer_service/predefined_replies/'

    const { data } = await Api.get(relativeUrl, {
      params: {
        search,
        item,
        total,
        customer_service: customerService,
        customer_service__institution: institution,
        status,
      },
    })

    if (data) return data

    return new Error('Erro ao carregar respostas')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const createPredefinedReply = async (
  formData: object,
): Promise<SacPredefinedReply | Error> => {
  try {
    const relativeUrl = '/V1/customer_service/predefined_replies/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const updatePredefinedReply = async (
  id: number,
  formData: object,
): Promise<SacPredefinedReply | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/predefined_replies/${id}/`

    const { data } = await Api.patch(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

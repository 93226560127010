/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

export interface ILegislation {
  id?: number
  type: number
  text: string
  link: string
  title: string
  status: boolean
  institution_id: number
}

const getAllLegislations = async (
  institutionId = '',
  filter = '',
): Promise<ILegislation[] | Error> => {
  try {
    const relativeUrl = `/V1/legislations/?search=${filter}&institution_id=${institutionId}`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getLegislationById = async (
  id: number,
): Promise<ILegislation | Error> => {
  try {
    const relativeUrl = `/V1/legislations/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return data
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao listar registro',
    )
  }
}

const createLegislation = async (formData: {}): Promise<
  ILegislation | Error
> => {
  try {
    const relativeUrl = '/V1/legislations/'

    const { data } = await Api.post<ILegislation>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const updateLegislationById = async (
  id: number,
  formData: {},
): Promise<ILegislation | Error> => {
  try {
    const relativeUrl = `/V1/legislations/${id}/`

    const { data } = await Api.patch<ILegislation>(relativeUrl, formData)

    if (data) return data

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao atualizar registro',
    )
  }
}

const externalGetLegislations = async (
  institutionId: number,
): Promise<ILegislation[] | Error> => {
  try {
    const relativeUrl = `/V1/externalusers/legislations/${institutionId}/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export {
  createLegislation,
  getAllLegislations,
  getLegislationById,
  updateLegislationById,
  externalGetLegislations,
}

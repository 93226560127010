import {
  Box,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC } from 'react'
import { FormBoxStyle, ModalHeader } from '../../../shared/components'

interface InfoAbrirRecursoProps {
  show: boolean
  onClose: () => void
}

export const InfoAbrirRecurso: FC<InfoAbrirRecursoProps> = ({
  onClose,
  show,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        overflow="auto"
        maxHeight="85vh"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader showCloseButton title="Abrir Recurso" onClose={onClose} />

        <Typography>Informações aqui</Typography>
      </Box>
    </Modal>
  )
}

import { FC, MutableRefObject, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Cancel, Save } from '@mui/icons-material'
import { Maybe, Schema, ValidationError } from 'yup'

import { yup } from '../../../../../shared/forms/TraducoesYup'
import { useAuthContext } from '../../../../../shared/contexts'
import { createSacForward } from '../../../../../shared/services/api/sac-services'
import {
  VForm,
  VTextArea,
  VTextField,
  useVForm,
} from '../../../../../shared/forms'
import {
  FormBoxStyle,
  ModalHeader,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../../shared/components'

interface TransferirModalProps {
  show: boolean
  onClose: () => void
  protocolId: number
  update: MutableRefObject<boolean>
}

interface IFormData {
  user: number
  subject: string
  comment?: Maybe<string>
  protocol: number
  email_address: string
}

const formValidationSchema: Schema<IFormData> = yup.object().shape({
  comment: yup
    .string()
    .notRequired()
    .max(1000, 'Tamanho máximo de 1000 caracteres'),
  email_address: yup
    .string()
    .required('E-mail é obrigatório')
    .email('Formato de e-mail inválido'),
  protocol: yup.number().required().positive(),
  subject: yup
    .string()
    .required()
    .max(255, 'Quantidade máximo de 255 caracteres'),
  user: yup.number().required().positive(),
})

export const EncaminharModal: FC<TransferirModalProps> = ({
  onClose,
  show,
  protocolId,
  update,
}) => {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const { user } = useAuthContext()
  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    formRef.current?.reset()
    onClose()
  }

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)
    if (user) {
      formData.user = user.id
    }
    formData.protocol = protocolId

    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then(async (validatedDate) => {
        const result = await createSacForward(protocolId, validatedDate)

        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          update.current = true
          NotificacaoSucesso('Enviado com sucesso!')
        }
        handleClose()
        setIsLoading(false)
      })
      .catch((errors: ValidationError) => {
        const validationError: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validationError[error.path] = error.message
        })
        console.log(validationError)
        formRef.current?.setErrors(validationError)
        setIsLoading(false)
      })
  }

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85%"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader
          title="Encaminhar protocolo via e-mail"
          showCloseButton
          onClose={handleClose}
        />
        <Divider />
        {isLoading && <LinearProgress sx={{ mt: 2 }} />}

        <VForm onSubmit={handleSubmit}>
          <Grid container spacing={2} direction="column" mt={1}>
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={6}>
                <VTextField
                  fullWidth
                  required
                  size="small"
                  name="email_address"
                  label="Destinatário"
                  placeholder="email@example.com"
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <VTextField
                  fullWidth
                  required
                  size="small"
                  name="subject"
                  label="Assunto"
                  borderBottomLeft="50px"
                  borderTopLeft="50px"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <VTextArea
                name="comment"
                minRows={4}
                maxRows={8}
                placeholder="Comentário *não obrigatório*"
                ariaLabel="Descrição"
                style={{
                  width: '95%',
                  borderRadius: '5px',
                  fontVariant: 'historical-forms',
                  font: 'message-box',
                }}
              />
            </Grid>
          </Grid>
          <Box display="flex" flex="1" justifyContent="center" gap={1} mt={2}>
            <Button
              variant="contained"
              type="submit"
              disableElevation
              startIcon={<Save />}
            >
              salvar
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              startIcon={<Cancel />}
            >
              cancelar
            </Button>
          </Box>
        </VForm>
      </Box>
    </Modal>
  )
}

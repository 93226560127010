import { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Icon,
} from '@mui/material'

import { useDebounce } from '../../../shared/hooks'
import { Environment } from '../../../shared/environment'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import { CriarIcone } from './formulario/Formulário'
import { FormatDateTime, FormatStatus, changePage } from '../../../shared/utils'
import { getAllIcons, IIcons } from '../../../shared/services/api'
import {
  FerramentasDaListagem,
  NotificacaoError,
} from '../../../shared/components'
import { useModuleContext } from '../../../shared/contexts/ModulesContext'
import { Footer } from '../../../shared/components/tabela/Footer'

export const Icones: React.FC = () => {
  const { moduleTitle } = useModuleContext()
  const navigate = useNavigate()
  const saved = useRef(false)
  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState<IIcons[]>([])
  const [totalCount, setTotalCount] = useState(0)

  const [searchParams, setSearchParams] = useSearchParams()

  const { debounce } = useDebounce(1200)

  const busca = useMemo(() => {
    return searchParams.get('busca') || ''
  }, [searchParams])

  const total = useMemo(() => {
    return searchParams.get('total') || '10'
  }, [searchParams])

  const item = useMemo(() => {
    return searchParams.get('item') || '0'
  }, [searchParams])

  const [viewForm, setViewForm] = useState(false)

  const handleClose = () => {
    if (viewForm) setViewForm(false)
    else setViewForm(true)
  }

  useEffect(() => {
    setIsLoading(true)

    debounce(() => {
      getAllIcons(item, '5000', busca).then((result) => {
        saved.current = false
        setIsLoading(false)

        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          setRows(result.results)
          setTotalCount(result.count)
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, total, busca, saved.current])

  return (
    <LayoutBaseDePagina
      title="Ícones"
      filter={
        <FerramentasDaListagem
          mostrarInputDaBusca
          textoBotaoNovo="Novo"
          aoClicarEmNovo={handleClose}
          textoDaBusca={busca}
          aoMudarTextoDaBusca={(texto) =>
            setSearchParams(
              { busca: texto, item: '0', total },
              { replace: true },
            )
          }
        />
      }
    >
      {CriarIcone(handleClose, viewForm, saved, true)}
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{
          m: 1,
          width: 'auto',
          color: 'dodgerblue',
          border: '1px solid',
          borderRadius: '15px',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={50}></TableCell>
              <TableCell align="center">Ícone</TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      navigate(
                        `/ombudsman/${moduleTitle}/icons/detail/${row.id}`,
                      )
                    }
                  >
                    <Icon>info_outlined</Icon>
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  {<img src={row.icon} width="70px" alt="icon" />}
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">
                  {FormatDateTime(row.created_at, 'Ainda Não Logou')}
                </TableCell>
                <TableCell align="center">{FormatStatus(row.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <Footer
            colSpan={5}
            item={Number(item)}
            loading={isLoading}
            onChangePagination={(_, newPage) =>
              setSearchParams(
                {
                  busca,
                  item: changePage(newPage, Number(total)),
                  total,
                },
                { replace: true },
              )
            }
            onChangeTotalItems={(e) =>
              setSearchParams(
                {
                  busca,
                  item: '0',
                  total: e.target.value,
                },
                { replace: true },
              )
            }
            total={total}
            totalCount={totalCount}
          />
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  )
}

/* eslint-disable react/jsx-key */
import { FC, Fragment, ReactNode, useEffect, useState } from 'react'
import { IconButton, Typography } from '@mui/material'

interface ISurveyButtonProps {
  onClick?: () => void
  children: ReactNode
  color: string
  hover: string
}

export const SurveyButton: FC<ISurveyButtonProps> = ({
  children,
  onClick,
  color,
  hover,
}) => {
  return (
    <IconButton
      sx={{
        width: '50px',
        height: '50px',
        bgcolor: color,
        border: `1px solid ${hover}`,
        transition: 'colort .5 linear',
        '&:hover': {
          bgcolor: hover,
        },
      }}
      onClick={() => onClick?.()}
      aria-label="delete"
    >
      <Typography variant="h6">{children}</Typography>
    </IconButton>
  )
}

const SurveyButtonsFragment = (questionId?: number) => {
  const [selected, setSelected] = useState<number>()

  const noneColor = (value: number | undefined) =>
    selected === value || selected === undefined

  useEffect(() => {
    if (selected !== undefined && questionId !== undefined) {
      console.log('clique')
    }
  }, [selected, questionId])

  return {
    fragment: [
      <SurveyButton
        color={noneColor(1) ? '#b42828' : ''}
        hover="#6b0d29"
        onClick={() => setSelected(1)}
      >
        1
      </SurveyButton>,
      <SurveyButton
        color={selected === undefined || selected === 2 ? '#db5858' : ''}
        hover="#a84141"
        onClick={() => setSelected(2)}
      >
        2
      </SurveyButton>,
      <SurveyButton
        color={selected === undefined || selected === 3 ? '#f7988b' : ''}
        hover="#bb6b61"
        onClick={() => setSelected(3)}
      >
        3
      </SurveyButton>,
      <SurveyButton
        color={selected === undefined || selected === 4 ? '#f7c88b' : ''}
        hover="#c29e6e"
        onClick={() => setSelected(4)}
      >
        4
      </SurveyButton>,
      <SurveyButton
        color={selected === undefined || selected === 5 ? '#f7e98b' : ''}
        hover="#c3b76f"
        onClick={() => setSelected(5)}
      >
        5
      </SurveyButton>,
      <SurveyButton
        color={selected === undefined || selected === 6 ? '#e3f78b' : ''}
        hover="#c6d874"
        onClick={() => setSelected(6)}
      >
        6
      </SurveyButton>,
      <SurveyButton
        color={selected === undefined || selected === 7 ? '#caf78b' : ''}
        hover="#92b363"
        onClick={() => setSelected(7)}
      >
        7
      </SurveyButton>,
      <SurveyButton
        color={selected === undefined || selected === 8 ? '#b1f78b' : ''}
        hover="#71a058"
        onClick={() => setSelected(8)}
      >
        8
      </SurveyButton>,
      <SurveyButton
        color={selected === undefined || selected === 9 ? '#8bf78d' : ''}
        hover="#37bb5a"
        onClick={() => setSelected(9)}
      >
        9
      </SurveyButton>,
      <SurveyButton
        color={selected === undefined || selected === 10 ? '#45d5c0' : ''}
        hover="#23b09d"
        onClick={() => setSelected(10)}
      >
        10
      </SurveyButton>,
    ],
    selected,
  }
}

interface IButtonFragment {
  questionId: number
  externalOnClick?: (questionId: number, value: number) => void
}

export const SurveyButtonsWraper = () => {
  const [selectedOptions] = useState<
    { question_id: number; selected: number }[]
  >([])

  const ButtonFragment: FC<IButtonFragment> = ({
    questionId,
    externalOnClick,
  }) => {
    const { fragment, selected } = SurveyButtonsFragment(questionId)

    useEffect(() => {
      if (selected !== undefined && questionId !== undefined) {
        externalOnClick?.(questionId, selected)
      }
    }, [externalOnClick, questionId, selected])

    return <Fragment>{fragment}</Fragment>
  }

  return {
    ButtonFragment,
    values: selectedOptions,
  }
}

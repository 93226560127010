import { FC, useState, useEffect, memo } from 'react'
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useAuthContext, useDrawerContext } from '../../contexts'
import { TypographyDrop } from './TypographyDrop'
import { useNavigate } from 'react-router-dom'
import { clearAllRead, markAsRead } from '../../services/api'
import { Close } from '@mui/icons-material'
import { useModuleContext } from '../../contexts/ModulesContext'

interface INotifiesDropdownProps {
  open: boolean
}

interface INotifies {
  id: number
  instance_type: string
  text: string
  status: boolean
  protocol_id: number
  identifier: string
  created_at: string
}

const formatDateNotifies = (value: string) => {
  const date = value.split(' ')[0]
  const time = value.split(' ')[1]

  const day = date.split('-')[2]
  const month = date.split('-')[1]
  const year = date.split('-')[0]

  const minute = time.split(':')[1]
  const hour = time.split(':')[0]

  return String(`${day}/${month}/${year} às ${hour}:${minute}`)
}

const NotifiesDropdown: FC<INotifiesDropdownProps> = ({ open }) => {
  const navigate = useNavigate()

  const theme = useTheme()

  const [openText, setOpenText] = useState(false)

  const { user } = useAuthContext()
  const { moduleTitle } = useModuleContext()
  const {
    notifies,
    connected,
    recconecting,
    hasError,
    toggleOpenNotifies,
    toggleDrawerOpen,
    isDrawerOpen,
  } = useDrawerContext()

  const delayToggleNotifies = () => {
    setTimeout(function () {
      toggleOpenNotifies()
    }, 100)
  }

  const [read, setRead] = useState<INotifies[]>(
    notifies.filter((notify) => notify.status),
  )
  const [unread, setUnread] = useState<INotifies[]>(
    notifies.filter((notify) => !notify.status),
  )

  useEffect(() => {
    const sortNotifies = notifies.sort(function (a, b) {
      return a.created_at < b.created_at
        ? 1
        : a.created_at > b.created_at
        ? -1
        : 0
    })
    setRead(sortNotifies.filter((notify) => notify.status))
    setUnread(sortNotifies.filter((notify) => !notify.status))
  }, [notifies])

  const xsDown = useMediaQuery(theme.breakpoints.down(480))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const [border, setBorder] = useState(false)

  const mainContainer = document.getElementById('container')
  mainContainer?.addEventListener('transitionend', function () {
    if (!open) {
      setBorder(false)
    } else {
      setBorder(true)
    }
  })

  return (
    <Box
      id="container"
      paddingRight={1}
      width={xsDown ? '280px' : '350px'}
      right={smDown ? '5%' : undefined}
      zIndex={1500}
      marginRight={xsDown ? '20px' : smDown ? '20px' : mdDown ? '20px' : '50px'}
      sx={{
        transition: 'all 0.2s linear',
        backgroundColor: 'rgba(0, 0, 0, .87)',
      }}
      borderRadius="15px"
      maxHeight="500px"
      height={open ? '500px' : '0px'}
      position="fixed"
      display="flex"
      border={open || border ? '2px solid grey' : undefined}
      marginTop={xsDown ? '20px' : '95px'}
      gap={1}
      flexDirection="column"
    >
      {!connected && !hasError && (
        <Typography
          gap={1}
          variant="caption"
          margin={1}
          color="dodgerblue"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          display={open && border ? 'flex' : 'none'}
        >
          conectando notificações em tempo real
          <CircularProgress size={15} sx={{ marginX: 1 }} />
        </Typography>
      )}
      {!connected && hasError && recconecting <= 10 && (
        <Typography
          gap={1}
          variant="caption"
          margin={1}
          color="yellow"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          display={open && border ? 'flex' : 'none'}
        >
          reconectando notificações ({recconecting}/10)
          <CircularProgress size={15} sx={{ marginX: 1 }} />
        </Typography>
      )}
      {!connected && recconecting > 10 && (
        <Typography
          gap={1}
          variant="caption"
          margin={1}
          color="red"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          display={open && border ? 'flex' : 'none'}
        >
          notificações em tempo real desconectadas. atualize a página para
          tentar novamente.
        </Typography>
      )}
      <Box
        display="flex"
        flexDirection="column"
        overflow="auto"
        sx={{ overflowX: 'hidden' }}
      >
        <Box
          margin={1}
          flex="1 1"
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Box
            display="flex"
            flex="1"
            justifyContent="space-between"
            alignItems="center"
          >
            <TypographyDrop variant="h6">NÃO VISTAS</TypographyDrop>
            <Tooltip title="Fechar notificações" open={openText}>
              <IconButton
                onMouseEnter={() => setOpenText(true)}
                onMouseLeave={() => setOpenText(false)}
                onClick={() => {
                  setOpenText(false)
                  delayToggleNotifies()
                }}
                sx={{
                  display: border && open ? 'flex' : 'none',
                  color: 'white',
                  right: '-5px',
                  top: '2px',
                }}
              >
                <Close />
              </IconButton>
            </Tooltip>
          </Box>
          {unread.length === 0 && (
            <TypographyDrop variant="caption">
              nenhuma notificação não lida
            </TypographyDrop>
          )}
          {unread.map((notify, index) => (
            <Box
              key={index}
              id={`${String(notify.id)}-button`}
              display="flex"
              minHeight="80px"
              flexDirection="column"
              component={ButtonBase}
              width={xsDown ? '250px' : '320px'}
              onClick={() => {
                toggleOpenNotifies()
                if (isDrawerOpen) toggleDrawerOpen()
                navigate(
                  `/ombudsman/${moduleTitle}/protocols/detail/${notify.protocol_id}`,
                )
              }}
              sx={{
                padding: 1,
                alignItems: 'center',
                borderRadius: '15px',
                justifyContent: 'center',
                border: `1px solid ${
                  notify.instance_type.toUpperCase() === 'NOVA MANIFESTAÇÃO'
                    ? '#FFFF00'
                    : notify.instance_type.toUpperCase() === 'RECURSO INSERIDO'
                    ? '#52493a'
                    : notify.instance_type.toUpperCase() ===
                      'MANIFESTAÇÃO EM TRÂMITE'
                    ? '#CBAACB'
                    : notify.instance_type.toUpperCase() ===
                      'MANIFESTAÇÃO PRORROGADA'
                    ? '#55CBCD'
                    : notify.instance_type.toUpperCase() ===
                      'MANIFESTAÇÃO CONCLUÍDA'
                    ? '#8bdaa7'
                    : notify.instance_type.toUpperCase() ===
                      'MANIFESTAÇÃO ATRASADA'
                    ? '#EB3324'
                    : notify.instance_type.toUpperCase() ===
                      'MANIFESTAÇÃO INDEFERIDA'
                    ? '#85827e'
                    : notify.instance_type.toUpperCase() === 'ANÁLISE INICIADA'
                    ? '#f7a541'
                    : notify.instance_type.toUpperCase() ===
                      'TRANSFERÊNCIA DE SETOR'
                    ? '#4f364c'
                    : '#eb7072'
                }`,
                backgroundColor: 'black',
                borderLeft: `5px solid ${
                  notify.instance_type.toUpperCase() === 'NOVA MANIFESTAÇÃO'
                    ? '#FFFF00'
                    : notify.instance_type.toUpperCase() === 'RECURSO INSERIDO'
                    ? '#52493a'
                    : notify.instance_type.toUpperCase() ===
                      'MANIFESTAÇÃO EM TRÂMITE'
                    ? '#CBAACB'
                    : notify.instance_type.toUpperCase() ===
                      'MANIFESTAÇÃO PRORROGADA'
                    ? '#55CBCD'
                    : notify.instance_type.toUpperCase() ===
                      'MANIFESTAÇÃO CONCLUÍDA'
                    ? '#8bdaa7'
                    : notify.instance_type.toUpperCase() ===
                      'MANIFESTAÇÃO ATRASADA'
                    ? '#EB3324'
                    : notify.instance_type.toUpperCase() ===
                      'MANIFESTAÇÃO INDEFERIDA'
                    ? '#85827e'
                    : notify.instance_type.toUpperCase() === 'ANÁLISE INICIADA'
                    ? '#f7a541'
                    : notify.instance_type.toUpperCase() ===
                      'TRANSFERÊNCIA DE SETOR'
                    ? '#4f364c'
                    : '#eb7072'
                }`,
                transition: 'all 0.15s linear',
                ':hover': {
                  backgroundColor: `${
                    notify.instance_type.toUpperCase() === 'NOVA MANIFESTAÇÃO'
                      ? '#D4BB68'
                      : notify.instance_type.toUpperCase() ===
                        'RECURSO INSERIDO'
                      ? '#7f440a'
                      : notify.instance_type.toUpperCase() ===
                        'MANIFESTAÇÃO EM TRÂMITE'
                      ? '#723373'
                      : notify.instance_type.toUpperCase() ===
                        'MANIFESTAÇÃO PRORROGADA'
                      ? '#619FB3'
                      : notify.instance_type.toUpperCase() ===
                        'MANIFESTAÇÃO CONCLUÍDA'
                      ? '#4CA67A'
                      : notify.instance_type.toUpperCase() ===
                        'MANIFESTAÇÃO ATRASADA'
                      ? '#EB3324'
                      : notify.instance_type.toUpperCase() ===
                        'MANIFESTAÇÃO INDEFERIDA'
                      ? '#636363'
                      : notify.instance_type.toUpperCase() ===
                        'ANÁLISE INICIADA'
                      ? '#b88000'
                      : notify.instance_type.toUpperCase() ===
                        'TRANSFERÊNCIA DE SETOR'
                      ? '#5e405f'
                      : '#a85163'
                  }`,
                },
              }}
            >
              <TypographyDrop textAlign="center">
                {notify.instance_type.toUpperCase()}
              </TypographyDrop>
              <TypographyDrop>{notify.identifier}</TypographyDrop>
              <TypographyDrop variant="caption">
                {notify.text.toLowerCase()}
              </TypographyDrop>
              <Box
                width="100%"
                marginTop={2}
                display="flex"
                alignItems="center"
                justifyContent="right"
              >
                <TypographyDrop textAlign="right" variant="caption">
                  {formatDateNotifies(notify.created_at)}
                </TypographyDrop>
              </Box>
            </Box>
          ))}
          <Divider sx={{ background: 'white' }} />
          <TypographyDrop variant="h6">VISUALIZADAS</TypographyDrop>
          {read.length === 0 && (
            <TypographyDrop variant="caption">
              nenhuma notificação visualizada
            </TypographyDrop>
          )}
          {read.map((notify, index) => (
            <Box
              key={index}
              display="flex"
              minHeight="80px"
              flexDirection="column"
              component={ButtonBase}
              width={xsDown ? '250px' : '320px'}
              onClick={() => {
                toggleOpenNotifies()
                if (isDrawerOpen) toggleDrawerOpen()
                navigate(
                  `/ombudsman/${moduleTitle}/protocols/detail/${notify.protocol_id}`,
                )
              }}
              sx={{
                padding: 1,
                alignItems: 'center',
                borderRadius: '15px',
                backgroundColor: 'grey',
                justifyContent: 'center',
                border: '1px solid dodgerblue',
                transition: 'all 0.15s linear',
                borderLeft: '5px solid dodgerblue',
                ':hover': {
                  backgroundColor: 'dodgerblue',
                },
              }}
            >
              <TypographyDrop textAlign="center">
                {notify.instance_type.toUpperCase()}
              </TypographyDrop>
              <TypographyDrop>{notify.identifier}</TypographyDrop>
              <TypographyDrop variant="caption">{notify.text}</TypographyDrop>
              <Box
                width="100%"
                marginTop={2}
                display="flex"
                justifyContent="right"
              >
                <TypographyDrop textAlign="right" variant="caption">
                  {formatDateNotifies(notify.created_at)}
                </TypographyDrop>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Divider sx={{ display: open ? undefined : 'none' }} />
      <Box
        marginX={1}
        width="100%"
        display="flex"
        marginBottom={1}
        alignItems="center"
        gap={xsDown ? 1 : undefined}
        justifyContent={
          read.length > 0 && unread.length > 0 ? 'space-between' : 'center'
        }
        height={xsDown ? '55px' : '30px'}
      >
        {unread.length > 0 && (
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              markAsRead(Number(user?.id))
            }}
            sx={{
              borderRadius: '50px',
              textTransform: 'lowercase',
              display: open ? 'flex' : 'none',
            }}
          >
            marcar todas como lidas
          </Button>
        )}
        {unread.length > 0 && read.length > 0 && (
          <Divider orientation="vertical" />
        )}
        {read.length > 0 && (
          <Button
            size="small"
            variant="outlined"
            onClick={() => clearAllRead(Number(user?.id))}
            sx={{
              marginRight: 1,
              borderRadius: '50px',
              textTransform: 'lowercase',
              display: open ? 'flex' : 'none',
            }}
          >
            limpar visualizadas
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default memo(NotifiesDropdown)

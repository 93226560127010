import { Api, TotalCount } from '../../axios-config'

export interface SacSlide {
  id: number
  customer_service: number
  title: string
  link?: string
  image: string
  button_color: string
  button_text: string
  button_text_color: string
  status: boolean
  created_at: string
  updated_at: string
}

export const getAllSacSlides = async (
  busca = '',
  item = '0',
  total = '10',
  sac = '',
  instituicao = '',
  status = '',
  link = '',
): Promise<TotalCount<SacSlide> | Error> => {
  try {
    const relativeUrl = '/V1/customer_service/slides/'

    const { data } = await Api.get(relativeUrl, {
      params: {
        search: busca,
        item,
        total,
        customer_service: sac,
        customer_service__institution: instituicao,
        status,
        link,
      },
    })

    if (data) return data

    return new Error('Erro ao carregar slides')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const createSacSlide = async (
  formData: object,
): Promise<SacSlide | Error> => {
  try {
    const relativeUrl = '/V1/customer_service/slides/'

    const { data } = await Api.post(relativeUrl, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    if (data) return data

    return new Error('Erro ao criar slide')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const updateSacSlide = async (
  id: number | string,
  formData: object,
): Promise<SacSlide | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/slides/${id}/`

    const { data } = await Api.patch(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao atualizar slide')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export const getSacSlides = async (
  sacId: number | string,
): Promise<SacSlide[] | Error> => {
  try {
    const relativeUrl = `/V1/customer_service/externalusers/access/${sacId}/slides/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar slides')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}
